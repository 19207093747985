import { Component, Input, OnInit } from '@angular/core';
import { Block, BlockStyle, InputField } from 'src/app/models/block.model';
import { AppService } from 'src/app/services/app.service';
import { BlockService } from 'src/app/services/block.service';

@Component({
  selector: 'app-zw-westnetz',
  templateUrl: './zw-westnetz.component.html',
  styleUrls: ['./zw-westnetz.component.scss'],
})
export class ZwWestnetzComponent implements OnInit {
  @Input() block: Block;
  @Input() blocks: Block[];
  @Input() style: BlockStyle;
  @Input() blockIndex: number;
  @Input() mobileView: boolean;

  inputWestnetzInputIndex: number;
  submitBtnHover: boolean = false;

  constructor(
    private appService: AppService,
    private blockService: BlockService
  ) {}

  ngOnInit(): void {}

  selectWestnetzElement(
    input: InputField | null,
    inputIndex: number,
    type: string
  ) {
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentWestnetzPanelIndex: inputIndex,
        },
      },
      false
    );
  }

  focusWestnetzInputIn(index: number) {
    this.inputWestnetzInputIndex = index;
  }

  focusWestnetzInputOut() {
    this.inputWestnetzInputIndex = -1;
  }

  getWestnetzInputStyle(index: number, inputIndex: number) {
    return this.blockService.getFormInputStyle(
      this.blocks,
      index,
      inputIndex,
      this.inputWestnetzInputIndex
    );
  }

  getWestnetzSubmitButtonStyle(index: number) {
    return this.blockService.getFormSubmitButtonStyle(
      this.blocks,
      index,
      this.submitBtnHover
    );
  }

  checkIfRequiredFieldsPresent() {
    let requiredFieldsPresent = true;
    this.block.content.inputs.forEach((input: any) => {
      if (input.required && input.value == null) {
        requiredFieldsPresent = false;
      }
    });
    return requiredFieldsPresent;
  }

  over() {
    this.submitBtnHover = true;
  }

  out() {
    this.submitBtnHover = false;
  }
}
