<div class="flex flex-col">
  <label for="comment" class="block text-sm font-medium text-gray-700">{{
    title
  }}</label>
  <span class="relative z-0 inline-flex rounded-md">
    <button
      (click)="setBold()"
      type="button"
      class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
    >
      <i class="bi bi-type-bold cursor-pointer text-lg"></i>
    </button>
    <button
      (click)="setItalic()"
      type="button"
      class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
    >
      <i class="bi bi-type-italic cursor-pointer text-lg"></i>
    </button>
    <button
      (click)="setUnderline()"
      type="button"
      class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
    >
      <i class="bi bi-type-underline cursor-pointer text-lg"></i>
    </button>
  </span>
</div>
