<div
  class="flex items-center justify-center min-h-screen p-5 bg-gray-100 min-w-screen"
>
  <div
    class="max-w-xl p-8 text-center border text-kl-grey bg-white shadow-2xl lg:max-w-3xl rounded-3xl lg:p-12"
  >
    <h3 class="text-2xl" i18n="@@verifyEmail_verifyEmailHeadline_label">
      Bestätige Deine E-Mail-Adresse!
    </h3>
    <div class="flex justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-24 h-24 text-kl-green"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
        />
      </svg>
    </div>

    <p i18n="@@verifyEmail_descriptionText_label">
      Es ist erforderlich, dass Du Deine E-Mail-Adresse bestätigst, bevor Du
      diese Webseite nutzen kannst.
    </p>
    <p i18n="@@verifyEmail_linkSendToMailAddress_label">
      Der Bestätigungs-Link wurde an diese E-Mail-Adresse gesendet:
    </p>
    <p>{{ user?.email }}</p>

    <div class="mt-4">
      <button
        i18n="@@verifyEmail_sendLinkAgain_button"
        (click)="resendEmailVerification()"
        class="px-2 py-2 text-white hover:bg-purple-800 bg-kl-purple rounded"
      >
        Bestätigungs-Link erneut senden
      </button>
    </div>

    <p
      class="mt-4 underline text-kl-purple cursor-pointer"
      routerLink="/login"
      i18n="@@verifyEmail_navigateToLogin_link"
    >
      Zurück zum Login
    </p>
  </div>
</div>
