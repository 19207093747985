<section aria-labelledby="payment-details-heading" *ngIf="currentProject">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 sm:p-6">
      <div>
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
          i18n="@@funnelSettingsGeneral_headline_label"
        >
          Allgemein
        </h2>
      </div>

      <div class="my-8 grid grid-cols-4 gap-6">
        <div class="col-span-8 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsGeneral_funnelName_label"
            >Name</label
          >
          <input
            type="text"
            [(ngModel)]="currentProject.name"
            placeholder="Funnelname"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
        <div class="col-span-8 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsGeneral_status_label"
            >Status</label
          >
          <div class="flex gap-x-4 items-center">
            <app-toggle
              [isEnabled]="currentProject.status"
              (onToggle)="setStatus($event)"
            ></app-toggle>
            <p
              class="block text-sm font-medium text-gray-500"
              *ngIf="currentProject.status"
              i18n="@@funnelSettingsGeneral_statusLive_label"
            >
              Live
            </p>
            <p
              class="block text-sm font-medium text-gray-500"
              *ngIf="!currentProject.status"
              i18n="@@funnelSettingsGeneral_statusEdit_label"
            >
              In Bearbeitung
            </p>
          </div>
        </div>
      </div>

      <div class="my-8 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="last-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsGeneral_funnelLink_label"
            >Link zum Funnel</label
          >
          <div class="flex items-center mb-4">
            <input
              type="text"
              [value]="appUrl + currentProject.id"
              disabled
              readonly
              class="mt-1 cursor-not-allowed bg-gray-200 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            />
            <a
              class="ml-4"
              (click)="copyToClipboard(appUrl + currentProject.id)"
            >
              <i class="bi bi-clipboard cursor-pointer"></i>
            </a>
            <a class="ml-4" [href]="appUrl + currentProject.id" target="_blank">
              <i class="bi bi-box-arrow-up-right cursor-pointer"></i>
            </a>
          </div>
        </div>

        <!--   <div class="col-span-4 sm:col-span-2">
          <label
            for="last-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsGeneral_previewLink_label"
            >Vorschau-Link</label
          >

          <div class="flex items-center mb-4">
            <input
              type="text"
              [value]="appUrl + currentProject.id + '?preview=true'"
              disabled
              readonly
              class="mt-1 cursor-not-allowed bg-gray-200 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            />
            <a
              class="ml-4"
              (click)="
                copyToClipboard(appUrl + currentProject.id + '?preview=true')
              "
            >
              <i class="bi bi-clipboard cursor-pointer"></i>
            </a>
            <a
              class="ml-4"
              [href]="appUrl + currentProject.id + '?preview=true'"
              target="_blank"
            >
              <i class="bi bi-box-arrow-up-right cursor-pointer"></i>
            </a>
          </div>
        </div> -->
      </div>

      <div class="my-8 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsGeneral_qrCode_label"
            >QR Code zum Funnel</label
          >
          <ngx-qrcode
            [elementType]="elementType"
            [errorCorrectionLevel]="correctionLevel"
            [value]="appUrl + currentProject.id"
            cssClass="bshadow"
          ></ngx-qrcode>
        </div>

        <div class="col-span-4 sm:col-span-2"></div>
      </div>

      <div class="my-8 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <div class="relative flex items-start">
            <div class="flex items-center h-5">
              <input
                id="Fortschrittsbalken"
                aria-describedby="comments-description"
                name="progress"
                type="checkbox"
                [(ngModel)]="currentProject.progressbar.available"
                class="h-4 w-4 text-kl-purple border-gray-300 rounded"
              />
            </div>
            <div class="ml-3 text-sm">
              <label
                for="progress"
                class="font-medium text-gray-700"
                i18n="@@funnelSettingsGeneral_showProgressBar_label"
                >Fortschrittsbalken anzeigen</label
              >
              <p
                id="comments-description"
                class="text-gray-400"
                i18n="@@funnelSettingsGeneral_showProgressBarDescription_label"
              >
                Es wird am unteren Bildschirmrand ein Fortschrittsbalken im
                Funnel angezeigt.
              </p>
            </div>
          </div>
        </div>

        <div
          class="col-span-4 sm:col-span-2"
          *ngIf="currentProject.progressbar.available"
        >
          <label
            for="last-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsGeneral_progressBarColor_label"
            >Farbe des Fortschrittsbalkens</label
          >

          <div class="flex items-center mb-4">
            <input
              type="color"
              id="favcolor"
              name="favcolor"
              (blur)="saveProgressbarColor()"
              [value]="currentProject.progressbar.color"
            />
            <button (click)="resetColor()">
              <i class="ml-2 bi bi-x"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="my-8 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <div class="relative flex items-start">
            <div class="flex items-center h-5">
              <input
                id="backbutton"
                aria-describedby="backbutton"
                name="progress"
                type="checkbox"
                [(ngModel)]="currentProject.isBackButton"
                class="h-4 w-4 text-kl-purple border-gray-300 rounded"
              />
            </div>
            <div class="ml-3 text-sm">
              <label
                for="progress"
                class="font-medium text-gray-700"
                i18n="@@funnelSettingsGeneral_showProgressBar_label"
                >Zurück-Button anzeigen</label
              >
              <p
                id="backbutton"
                class="text-gray-400"
                i18n="@@funnelSettingsGeneral_showProgressBarDescription_label"
              >
                Es wird unter einem Block, der auf die nächste Seite verweist,
                ein Zurück-Button angezeigt.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="relative p-2"
        [ngClass]="{
          ' bg-gray-300 rounded-lg cursor-not-allowed': !entriesEnabled,
          'opacity-100': entriesEnabled
        }"
      >
        <i
          class="bi bi-lock-fill text-kl-purple text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-50"
          *ngIf="!entriesEnabled"
        ></i>
        <button
          *ngIf="!entriesEnabled"
          class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-14 cursor-pointer z-50"
          (click)="openPaymentPage()"
          i18n="@@funnelSettingsGeneral_upgradeNow_label"
        >
          Jetzt upgraden
        </button>
        <div class="my-8 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <div
              class="relative flex items-start"
              [ngClass]="{
                'opacity-20 z-0': !entriesEnabled,
                'opacity-100': entriesEnabled
              }"
            >
              <div class="flex items-center h-5">
                <input
                  [disabled]="!entriesEnabled"
                  id="comments"
                  aria-describedby="comments-description"
                  name="mailNotification"
                  type="checkbox"
                  [(ngModel)]="currentProject.notification"
                  class="h-4 w-4 text-kl-purple border-gray-300 rounded"
                />
              </div>
              <div class="ml-3 text-sm">
                <label
                  for="mailNotification"
                  class="font-medium text-gray-700"
                  i18n="@@funnelSettingsGeneral_activateMailNotification_label"
                  >E-Mail-Benachrichtigung aktivieren</label
                >
              </div>
            </div>
          </div>
        </div>

        <div
          class="my-8 grid grid-cols-0 gap-6"
          [ngClass]="{
            'opacity-20 z-0': !entriesEnabled,
            'opacity-100': entriesEnabled
          }"
          *ngIf="currentProject.notification"
        >
          <div class="col-span-12 sm:col-span-2">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsGeneral_mailNotificationDescription_label"
              >Benachrichtigung bei neuen Leads</label
            >
            <p
              id="comments-description"
              class="text-sm text-gray-400"
              i18n="@@funnelSettingsGeneral_mailNotificationDescription2_label"
            >
              An welche E-Mail-Adresse sollen Benachrichtigungen gesendet
              werden?
            </p>
            <div class="flex mt-1">
              <input
                [disabled]="!entriesEnabled"
                type="text"
                placeholder="E-Mail Adresse"
                [(ngModel)]="notificationMail"
                (keydown.enter)="addEmail()"
                placeholder="E-Mail"
                class="block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
              <button
                (click)="addEmail()"
                class="ml-4 bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900"
                i18n="@@funnelSettingsGeneral_addUser_button"
              >
                E-Mail-Adresse hinzufügen
              </button>
            </div>
          </div>
        </div>

        <div
          *ngIf="
            currentProject.notificationMails.length > 0 &&
            currentProject.notification
          "
          class="my-8 grid grid-cols-0 gap-6"
          [ngClass]="{
            'opacity-20 z-0': !entriesEnabled,
            'opacity-100': entriesEnabled
          }"
        >
          <div class="col-span-12 sm:col-span-2">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsGeneral_mailAddresses_label"
              >E-Mail-Adressen</label
            >
            <p
              style="color: red"
              *ngIf="wrongEmail"
              i18n="@@funnelSettingsGeneral_mailNotValid_label"
            >
              E-Mail ist ungültig
            </p>
            <div
              class="flex justify-start mt-2 items-center"
              *ngFor="
                let mail of currentProject.notificationMails;
                let i = index
              "
            >
              <div
                (click)="removeMail(i)"
                class="cursor-pointer text-sm flex items-center gap-x-1 border border-slate-500 hover:bg-slate-300 rounded-lg p-1 bg-slate-200"
              >
                <p>{{ mail }}</p>

                <button>
                  <i class="bi bi-dash-circle ml-4"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="my-8 grid grid-cols-0 gap-6"
          *ngIf="currentProject.notification"
          [ngClass]="{
            'opacity-20 z-0': !entriesEnabled,
            'opacity-100': entriesEnabled
          }"
        >
          <div class="col-span-12 sm:col-span-2">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsGeneral_mailNotificationTextHeadline_label"
              >Benachrichtigungstext
            </label>
            <p
              id="comments-description"
              class="text-sm text-gray-400"
              i18n="
                @@funnelSettingsGeneral_mailNotificationTextInstruction_label"
            >
              Der Text wird an die E-Mail-Adresse gesendet.
            </p>
            <div class="flex mt-2">
              <button
                (click)="editMailText()"
                class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900"
                i18n="@@funnelSettingsGeneral_mailNotificationEdit_button"
              >
                Text bearbeiten
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 text-right sm:px-6">
        <button
          (click)="saveProject()"
          class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900"
          i18n="@@funnelSettingsGeneral_save_button"
        >
          Speichern
        </button>
      </div>
    </div>
  </div>
</section>
