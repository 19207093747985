import {
  ApplicationRef,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './views/login/login.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';

import { AppService } from './services/app.service';
import { ProjectService } from './services/project.service';
import { LanguageService } from './services/lang.service';
import { RegisterComponent } from './views/register/register.component';
import { FunnelListComponent } from './views/funnel-list/funnel-list.component';
import { FunnelEditorComponent } from './views/funnel-editor/funnel-editor.component';
import { PagesSidebarComponent } from './components/left-sidebar/pages-sidebar/pages-sidebar.component';
import { EditorComponent } from './components/editor/editor.component';
import { DetailsSidebarComponent } from './components/details-sidebar/details-sidebar.component';
import { TextModalComponent } from './components/modals/text-modal/text-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogModalComponent } from './components/modals/dialog-modal/dialog-modal.component';
import { LeftSidebarComponent } from './components/left-sidebar/left-sidebar.component';
import { PageDetailsSidebarComponent } from './components/left-sidebar/page-details-sidebar/page-details-sidebar.component';
import { PageDesignSidebarComponent } from './components/left-sidebar/page-design-sidebar/page-design-sidebar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InfoBarComponent } from './components/info-bar/info-bar.component';
import { FunnelSettingsComponent } from './views/funnel-settings/funnel-settings.component';
import { FunnelStatisticComponent } from './views/funnel-statistic/funnel-statistic.component';
import { MatTableModule } from '@angular/material/table';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { EmailTextDialogComponent } from './components/modals/email-text-dialog/email-text-dialog.component';
import { MatMenuModule } from '@angular/material/menu';

import { Router } from '@angular/router';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { PrivacyDialogComponent } from './components/privacy/privacy-dialog.component';
import { FunnelAppComponent } from './views/funnel-app/funnel-app.component';
import { FacebookPixelEventTrackerService } from './services/facebook.service';
import { FirestoreDatePipe } from './pipes/firestore-date.pipe';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { LocalStorageService } from './services/local-storage.service';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { BlockService } from './services/block.service';
import { HttpClientModule } from '@angular/common/http';
import { InfoModalComponent } from './components/modals/info-modal /info-modal .component';
import { createCustomElement } from '@angular/elements';
import { APP_BASE_HREF } from '@angular/common';
import { PasswordResetComponent } from './views/password-reset/password-reset.component';
import { RenameProjectDialogComponent } from './components/modals/rename-project-dialog/rename-project-dialog.component';
import { MediaLibraryService } from './services/media-library.service';
import { MediaLibraryModalComponent } from './components/modals/media-library-modal/media-library-modal.component';
import { VideoPipe } from './pipes/video.pipe';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { VerifyEmailComponent } from './views/verify-email/verify-email.component';
import { PageFlowComponent } from './views/page-flow/page-flow.component';
import { UserSettingsComponent } from './views/user-settings/user-settings.component';
import { NewEditorComponent } from './views/new-editor/new-editor.component';
import { NewLoginComponent } from './views/new-login/new-login.component';
import { NewRegisterComponent } from './views/new-register/new-register.component';
import { NewFunnelsComponent } from './views/new-funnels/new-funnels.component';
import { NewFunnelsSettingComponent } from './views/new-funnels-setting/new-funnels-setting.component';
import { NewFunnelLeadsComponent } from './views/new-funnel-leads/new-funnel-leads.component';
import { NewFunnelFlowComponent } from './views/new-funnel-flow/new-funnel-flow.component';
import { NewFunnelTemplateComponent } from './components/new-funnel-template/new-funnel-template.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PageNotFoundComponent } from './views/not-found/not-found.component';
import { NewFunnelShopComponent } from './views/new-funnel-shop/new-funnel-shop.component';
import { IconLibraryComponent } from './components/modals/icon-library/icon-library.component';
import { DomainService } from './services/domain.service';
import { AddDomainModalComponent } from './components/modals/add-domain-modal/add-domain-modal.component';
import { TemplateService } from './services/template.service';
import { FunnelTemplatesModalComponent } from './components/modals/funnel-templates-modal/funnel-templates-modal.component';
import { SettingsNavigationComponent } from './components/funnel-settings/settings-navigation/settings-navigation.component';
import { SettingsGeneralComponent } from './components/funnel-settings/settings-general/settings-general.component';
import { SettingsMetaComponent } from './components/funnel-settings/settings-meta/settings-meta.component';
import { SettingsTrackingComponent } from './components/funnel-settings/settings-tracking/settings-tracking.component';
import { SettingsDomainsComponent } from './components/funnel-settings/settings-domains/settings-domains.component';
import { SettingsEmbedComponent } from './components/funnel-settings/settings-embed/settings-embed.component';
import { SettingsPrivacyComponent } from './components/funnel-settings/settings-privacy/settings-privacy.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SettingsInvitationComponent } from './components/funnel-settings/settings-invitation/settings-invitation.component';
import { LeadsNavigationComponent } from './components/funnel-leads/leads-navigation/leads-navigation.component';
import { LeadsChartsComponent } from './components/funnel-leads/leads-charts/leads-charts.component';
import { LeadsTableComponent } from './components/funnel-leads/leads-table/leads-table.component';
import { LeadsPartialEntriesComponent } from './components/funnel-leads/leads-partial-entries/leads-partial-entries.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { LeadsSlideOverComponent } from './components/funnel-leads/leads-slide-over/leads-slide-over.component';
import { SidebarComponent } from './components/funnel-editor/sidebar-components/sidebar/sidebar.component';
import { PagesBarComponent } from './components/funnel-editor/pages-bar/pages-bar.component';
import { FunnelDesignerComponent } from './components/funnel-editor/funnel-designer/funnel-designer.component';
import { SidebarDetailComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/sidebar-detail.component';
import { PictureComponent } from './blocks/picture/picture.component';
import { ButtonComponent } from './blocks/button/button.component';
import { QuizComponent } from './blocks/quiz/quiz.component';
import { FormComponent } from './blocks/form/form.component';
import { TextfieldComponent } from './blocks/textfield/textfield.component';
import { FakeloaderComponent } from './blocks/fakeloader/fakeloader.component';
import { SelectionComponent } from './blocks/selection/selection.component';
import { VideoComponent } from './blocks/video/video.component';
import { SocialmediaComponent } from './blocks/socialmedia/socialmedia.component';
import { SliderComponent } from './blocks/slider/slider.component';
import { DesignMenuComponent } from './components/funnel-editor/design-menu/design-menu.component';
import { BlocksMenuComponent } from './components/funnel-editor/blocks-menu/blocks-menu.component';
import { HeaderBlockComponent } from './blocks/header/header.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { DropdownComponent } from './components/shared/dropdown/dropdown.component';
import { TextInputComponent } from './components/shared/text-input/text-input.component';
import { TextareaInputComponent } from './components/shared/textarea-input/textarea-input.component';
import { DetailsContentComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-content.component';
import { DetailsBlocksComponent } from './components/funnel-editor/sidebar-components/sidebar-detail//details-blocks/details-blocks.component';
import { DetailsPageComponent } from './components/funnel-editor/sidebar-components/sidebar-detail//details-page/details-page.component';
import { MarginComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/margin/margin.component';
import { RangeSliderComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/range-slider/range-slider.component';
import { DetailsMediathekComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-mediathek/details-mediathek.component';
import { DetailsUnsplashComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-unsplash/details-unsplash.component';
import { ToggleComponent } from './components/shared/toggle/toggle.component';
import { DetailsSelectionItemsComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-selection-items/details-selection-items.component';
import { DetailsQuizItemsComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-quiz-items/details-quiz-items.component';
import { DetailsQuizItemComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-quiz-item/details-quiz-item.component';
import { DetailsIconLibraryComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-icon-library/details-icon-library.component';
import { DetailsFormItemsComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-form-items/details-form-items.component';
import { DetailsFormItemComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-form-item/details-form-item.component';
import { ColorPickerComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/color-picker/color-picker.component';
import { AlignmentComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/alignment/alignment.component';
import { TextFormatComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/text-format/text-format.component';
import { FontSelectionComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/font-selection/font-selection.component';
import { NgxSmoothDnDModule } from 'ngx-smooth-dnd';
import { NgxStripeModule } from 'ngx-stripe';
import { StripeDataService } from './services/stripe-data.service';
import { PricingTableComponent } from './components/pricing-table/pricing-table.component';
import { PaymentCardComponent } from './components/payment-card/payment-card.component';
import { AdminDashboardComponent } from './views/admin-dashboard/admin-dashboard.component';
import { FunnelNamePipe } from './pipes/funnel-name.pipe';
import { BillingComponent } from './components/billing/billing.component';
import { TierService } from './services/tier.service';
import { BulletpointsComponent } from './blocks/bulletpoints/bulletpoints.component';
import { UploadComponent } from './blocks/upload/upload.component';
import { DetailsBulletPointsItemComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-bulletpoints-item/details-bulletpoints-item.component';
import { DetailsBulletpointsItemsComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-bulletpoints-items/details-bulletpoints-items.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SettingsIntegrationsComponent } from './components/funnel-settings/settings-integrations/settings-integrations.component';
import { SettingsWordingComponent } from './components/funnel-settings/settings-wording/settings-wording.component';
import { OnboardingComponent } from './components/modals/onboarding/onboarding.component';
import { ContactComponent } from './views/contact/contact.component';
import { UserDashboardComponent } from './views/user-dashboard/user-dashboard.component';
import { UserDashboardDataComponent } from './components/user-dashboard/user-dashboard-data/user-dashboard-data.component';
import { UserDashboardNavigationComponent } from './components/user-dashboard/user-dashboard-navigation/user-dashboard-navigation.component';
import { UserDashboardFunnelsComponent } from './components/user-dashboard/user-dashboard-funnels/user-dashboard-funnels.component';
import { UserDashboardPaymentComponent } from './components/user-dashboard/user-dashboard-payment/user-dashboard-payment.component';
import { UserDashboardInvoicesComponent } from './components/user-dashboard/user-dashboard-invoices/user-dashboard-invoices.component';
import { MonthlyImpressionsService } from './services/monthly-impressions.service';
import { SettingsLeadNotificationComponent } from './components/funnel-settings/settings-lead-notification/settings-lead-notification.component';
import { ZwWestnetzComponent } from './blocks/zw-westnetz/zw-westnetz.component';
import { DetailsZwWestnetzItemsComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-zw-westnetz-items/details-zw-westnetz-items.component';
import { DetailsZwWestnetzItemComponent } from './components/funnel-editor/sidebar-components/sidebar-detail/details-content/details-zw-westnetz-item/details-zw-westnetz-item.component';
import { FunnelPresetsComponent } from './components/funnel-presets/funnel-presets.component';
import { SelectionQuizComponent } from './blocks/selectionQuiz/selectionQuiz.component';

export function initializeApp(projectService: ProjectService) {
  return () => projectService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    RegisterComponent,
    FunnelListComponent,
    FunnelEditorComponent,
    PagesSidebarComponent,
    EditorComponent,
    DetailsSidebarComponent,
    TextModalComponent,
    DialogModalComponent,
    LeftSidebarComponent,
    PageDetailsSidebarComponent,
    PageDesignSidebarComponent,
    InfoBarComponent,
    RenameProjectDialogComponent,
    FunnelSettingsComponent,
    FunnelStatisticComponent,
    EmailTextDialogComponent,
    DeleteDialogComponent,
    PrivacyDialogComponent,
    FunnelAppComponent,
    FirestoreDatePipe,
    InfoModalComponent,
    PasswordResetComponent,
    MediaLibraryModalComponent,
    VideoPipe,
    CookieConsentComponent,
    VerifyEmailComponent,
    PageFlowComponent,
    UserSettingsComponent,
    NewEditorComponent,
    NewLoginComponent,
    NewRegisterComponent,
    NewFunnelsComponent,
    NewFunnelsSettingComponent,
    NewFunnelLeadsComponent,
    NewFunnelFlowComponent,
    NewFunnelTemplateComponent,
    ClickOutsideDirective,
    PageNotFoundComponent,
    NewFunnelShopComponent,
    IconLibraryComponent,
    AddDomainModalComponent,
    FunnelTemplatesModalComponent,
    SettingsNavigationComponent,
    SettingsGeneralComponent,
    SettingsMetaComponent,
    SettingsTrackingComponent,
    SettingsDomainsComponent,
    SettingsEmbedComponent,
    SettingsPrivacyComponent,
    NotificationComponent,
    SettingsInvitationComponent,
    LeadsNavigationComponent,
    LeadsChartsComponent,
    LeadsTableComponent,
    LeadsPartialEntriesComponent,
    LeadsSlideOverComponent,
    SidebarComponent,
    PagesBarComponent,
    FunnelDesignerComponent,
    SidebarDetailComponent,
    PictureComponent,
    ButtonComponent,
    QuizComponent,
    SelectionQuizComponent,
    FormComponent,
    TextfieldComponent,
    FakeloaderComponent,
    SelectionComponent,
    VideoComponent,
    SocialmediaComponent,
    SliderComponent,
    DesignMenuComponent,
    BlocksMenuComponent,
    HeaderBlockComponent,
    DropdownComponent,
    RangeSliderComponent,
    TextInputComponent,
    TextareaInputComponent,
    DetailsContentComponent,
    DetailsBlocksComponent,
    DetailsPageComponent,
    MarginComponent,
    DetailsMediathekComponent,
    DetailsUnsplashComponent,
    ToggleComponent,
    DetailsSelectionItemsComponent,
    DetailsQuizItemsComponent,
    DetailsQuizItemComponent,
    DetailsIconLibraryComponent,
    DetailsFormItemsComponent,
    DetailsFormItemComponent,
    ColorPickerComponent,
    AlignmentComponent,
    TextFormatComponent,
    FontSelectionComponent,
    PricingTableComponent,
    PaymentCardComponent,
    AdminDashboardComponent,
    FunnelNamePipe,
    BillingComponent,
    BulletpointsComponent,
    UploadComponent,
    DetailsBulletPointsItemComponent,
    DetailsBulletpointsItemsComponent,
    SettingsIntegrationsComponent,
    SettingsWordingComponent,
    OnboardingComponent,
    ContactComponent,
    UserDashboardComponent,
    UserDashboardDataComponent,
    UserDashboardNavigationComponent,
    UserDashboardFunnelsComponent,
    UserDashboardPaymentComponent,
    UserDashboardInvoicesComponent,
    SettingsLeadNotificationComponent,
    ZwWestnetzComponent,
    DetailsZwWestnetzItemsComponent,
    DetailsZwWestnetzItemComponent,
    FunnelPresetsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    DragDropModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatSelectModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatInputModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatMenuModule,
    MatDatepickerModule,
    NgxStripeModule.forRoot(
      'pk_test_51KvztvL8SlH6dpQ2pL7OQcisrgvT5vrKXcL0cfLk5bC3KdRtD2KrzqxJDHWApeu0oaSHn4cwPguEvtpO8vSUxVcP00QN90p84E'
    ), // Test/Live key
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    NgxQRCodeModule,
    HttpClientModule,
    MatNativeDateModule,
    ColorSketchModule,
    NgxSmoothDnDModule,
    NgxDropzoneModule,
  ],
  exports: [HeaderComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ProjectService],
      multi: true,
    },
    AuthService,
    UserService,
    AppService,
    ProjectService,
    LanguageService,
    FacebookPixelEventTrackerService,
    GoogleAnalyticsService,
    LocalStorageService,
    BlockService,
    MediaLibraryService,
    DomainService,
    TemplateService,
    StripeDataService,
    TierService,
    MonthlyImpressionsService,
    /*     {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }, */
  ], 
  bootstrap: [AppComponent],
  /* bootstrap: [],  ===> For Widget, remove AppComponent from Array  */
  entryComponents: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector, appRef: ApplicationRef) {}
  ngDoBootstrap() {
    const el = createCustomElement(FunnelAppComponent, {
      injector: this.injector,
    });
    customElements.define('funneltool-widget', el);
  }
}
