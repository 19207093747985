<div class="w-full h-full bg-white flex items-center justify-center">
  <div
    *ngIf="step == 0"
    class="w-full flex flex-col mx-auto rounded items-center justify-between py-16 shadow-lg bg-kl-purple px-10 h-full text-gray-600"
  >
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-4xl text-white mb-6">Willkommen 👋</h2>
    </div>
    <img
      src="./assets/01-funneluebersicht.png"
      class="object-contain h-72 w-auto mb-6 rounded-lg"
    />
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-xl text-white mb-3">Funnelübersicht</h2>
      <p class="text-sm leading-tight h-auto text-white">
        Erstelle hier deinen ersten Funnel und oder nutze unsere Vorlagen.
      </p>
    </div>
    <div class="flex justify-center mt-6">
      <div>
        <button
          (click)="next()"
          class="bg-white hover:bg-gray-200 text-kl-purple font-bold py-2 px-4 rounded-full focus:border-0 focus:outline-none"
        >
          <span class="text-kl-purple"> Weiter </span>
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="step == 1"
    class="w-full flex flex-col mx-auto rounded items-center justify-between py-16 shadow-lg bg-kl-purple px-10 h-full text-gray-600"
  >
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-4xl text-white mb-6">Willkommen 👋</h2>
    </div>
    <img
      src="./assets/02-editor.png"
      class="object-contain h-72 w-auto mb-6 rounded-lg"
    />
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-xl text-white mb-3">Editor</h2>
      <p class="text-sm leading-tight h-auto text-white">
        Bearbeite deinen Funnel indem du das Design, die Texte und die Blöcke
        der Seiten bearbeitest.
      </p>
    </div>
    <div class="flex justify-center mt-6">
      <div>
        <button
          (click)="next()"
          class="bg-white hover:bg-gray-200 text-kl-purple font-bold py-2 px-4 rounded-full focus:border-0 focus:outline-none"
        >
          <span class="text-kl-purple"> Weiter </span>
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="step == 2"
    class="w-full flex flex-col mx-auto rounded items-center justify-between py-16 shadow-lg bg-kl-purple px-10 h-full text-gray-600"
  >
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-4xl text-white mb-6">Willkommen 👋</h2>
    </div>
    <img
      src="./assets/03-bloecke.png"
      class="object-contain h-72 w-auto mb-6 rounded-lg"
    />
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-xl text-white mb-3">Blöcke</h2>
      <p class="text-sm leading-tight h-auto text-white">
        Klick auf die Blöcke um sie auf die Seite zu setzen.
      </p>
    </div>
    <div class="flex justify-center mt-6">
      <div>
        <button
          (click)="next()"
          class="bg-white hover:bg-gray-200 text-kl-purple font-bold py-2 px-4 rounded-full focus:border-0 focus:outline-none"
        >
          <span class="text-kl-purple"> Weiter </span>
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="step == 3"
    class="w-full flex flex-col mx-auto rounded items-center justify-between py-16 shadow-lg bg-kl-purple px-10 h-full text-gray-600"
  >
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-4xl text-white mb-6">Willkommen 👋</h2>
    </div>
    <img
      src="./assets/04-einstellungen.png"
      class="object-contain h-72 w-auto mb-6 rounded-lg"
    />
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-xl text-white mb-3">Einstellungen</h2>
      <p class="text-sm leading-tight h-auto text-white">
        Passe die Einstellungen deines Funnels an.
      </p>
    </div>
    <div class="flex justify-center mt-6">
      <div>
        <button
          (click)="next()"
          class="bg-white hover:bg-gray-200 text-kl-purple font-bold py-2 px-4 rounded-full focus:border-0 focus:outline-none"
        >
          <span class="text-kl-purple"> Weiter </span>
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="step == 4"
    class="w-full flex flex-col mx-auto rounded items-center justify-between py-16 shadow-lg bg-kl-purple px-10 h-full text-gray-600"
  >
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-4xl text-white mb-6">Willkommen 👋</h2>
    </div>
    <img
      src="./assets/05-leads.png"
      class="object-contain h-72 w-auto mb-6 rounded-lg"
    />
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-xl text-white mb-3">Leads</h2>
      <p class="text-sm leading-tight h-auto text-white">
        Hier werden alle Leads angezeigt die sich in deinem Funnel eingetragen
        haben.
      </p>
    </div>
    <div class="flex justify-center mt-6">
      <div>
        <button
          (click)="next()"
          class="bg-white hover:bg-gray-200 text-kl-purple font-bold py-2 px-4 rounded-full focus:border-0 focus:outline-none"
        >
          <span class="text-kl-purple"> Weiter </span>
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="step == 5"
    class="w-full flex flex-col mx-auto rounded items-center justify-between py-16 shadow-lg bg-kl-purple px-10 h-full text-gray-600"
  >
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-4xl text-white mb-6">Willkommen 👋</h2>
    </div>
    <img
      src="./assets/06-funnel.png"
      class="object-contain h-72 w-auto mb-6 rounded-lg"
    />
    <div class="w-full overflow-hidden text-center select-none">
      <h2 class="font-bold text-xl text-white mb-3">Dein Funnel</h2>
      <p class="text-sm leading-tight h-auto text-white">
        Dein Funnel ist fertig. Klick auf den Button um deinen Funnel zu öffnen
        und zu testen.
      </p>
    </div>
    <div class="flex justify-center mt-6">
      <div>
        <button
          (click)="next()"
          class="bg-white hover:bg-gray-200 text-kl-purple font-bold py-2 px-4 rounded-full focus:border-0 focus:outline-none"
        >
          <span class="text-kl-purple"> Weiter </span>
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="step == 6"
    class="w-full flex flex-col mx-auto rounded items-center justify-between py-16 shadow-lg bg-kl-purple px-10 h-full text-gray-600"
  >
    <img src="./assets/celebrate.png" class="object-contain h-72 w-auto mb-6" />
    <div class="w-full overflow-hidden text-center select-none">
      <div class="w-full overflow-hidden text-center select-none">
        <div class="mb-4 flex flex-col justify-center items-center">
          <label class="block mb-1 text-white text-lg font-bold" for="name"
            >Fast fertig...</label
          >
          <label class="block mb-1 text-white" for="name"
            >Wie ist dein Name? (Pflichtfeld)</label
          >
          <input
            id="text"
            type="text"
            name="text"
            placeholder="Max Mustermann"
            [(ngModel)]="name"
            class="py-2 px-3 border placeholder:text-gray-300 border-gray-300 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block md:w-1/3 w-full"
          />

          <label class="block mb-1 text-white mt-4" for="name">
            Wie ist der Name deines Unternehmens? (Pflichtfeld)
          </label>
          <input
            id="text"
            type="text"
            name="text"
            placeholder="Mustermann GmbH"
            [(ngModel)]="companyName"
            class="py-2 px-3 border placeholder:text-gray-300 border-gray-300 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block md:w-1/3 w-full"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <div>
        <button
          (click)="close()"
          class="bg-white hover:bg-gray-200 text-kl-purple font-bold py-2 px-4 rounded-full focus:border-0 focus:outline-none"
        >
          <span class="text-kl-purple"> Fertig </span>
        </button>
      </div>
    </div>
  </div>
</div>
