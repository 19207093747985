import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { User } from '@sentry/angular';
import { NotificationType } from 'src/app/enums/notification-type';
import { AppService } from 'src/app/services/app.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  message: string = '';
  user: User;
  showLoader: boolean = false;
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  sendMessage() {
    this.showLoader = true;
    const messageObj = {
      message: this.message,
      user: this.user,
    };

    this.http
      .post(environment.backendUrl + '/notification/contact', messageObj)
      .subscribe((res) => {
        this.showLoader = false;
        console.log(res);
        this.appService.showNotification(
          'Nachricht wurde erfolgreich versendet',
          'Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.',
          NotificationType.SUCCESS,
          5000
        );
        this.message = '';
      });
  }
}
