<div class="flex flex-col justify-between mb-6">
  <div>
    <p class="text-sm text-gray-500">Hintergrundfarbe</p>
    <div class="flex justify-between">
      <input
        type="color"
        id="favcolor"
        name="favcolor"
        (blur)="setBackgroundColor($event)"
        [value]="
          currentProject.pages[currentPageIndex].style.containerBackgroundColor
        "
      />
      <button
        color="warn"
        (click)="
          currentProject.pages[
            currentPageIndex
          ].style.containerBackgroundColor = null
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </button>
    </div>
  </div>
</div>

<div class="flex justify-between">
  <button
    color="accent"
    (click)="setStyleToAllPages(currentProject.pages[currentPageIndex])"
    class="btn btn-link text-sm"
  >
    Auf alle Seiten anwenden
  </button>
</div>

<button
  (click)="saveUserFunnel()"
  type="button"
  class="mt-4 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 w-full"
>
  Als Vorlage speichern
</button>

<button
  *ngIf="user.displayName === 'klickleadAdmin'"
  (click)="saveFunnelTemplate()"
  type="button"
  class="mt-4 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 w-full"
>
  Als Standardvorlage speichern
</button>
