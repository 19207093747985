<div
  class="flex flex-col"
  *ngIf="projectService.currentProject"
  style="height: calc(100vh - 40px)"
>
  <div class="flex" style="height: calc(100vh - 40px)">
    <app-left-sidebar
      class="w-1/5 bg-gray-50 overflow-y-auto"
    ></app-left-sidebar>

    <div class="w-3/5 flex justify-center">
      <app-editor style="height: calc(100%)" class="h-full w-full"></app-editor>
    </div>
    <app-details-sidebar class="w-1/5 bg-gray-50"></app-details-sidebar>
  </div>
</div>
