<section aria-labelledby="payment-details-heading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 sm:p-6">
      <div>
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
          i18n="@@funnelSettingsMeta_healine_label"
        >
          Metadaten
        </h2>
      </div>
      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-12 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            >Favicon</label
          >
          <div class="flex mt-2" *ngIf="currentProject.favicon">
            <img
              [src]="currentProject.favicon"
              class="w-10 h-10 rounded-full"
            />
            <button
              (click)="removeFavicon()"
              class="ml-10 bg-red-400 border border-transparent rounded-md shadow-sm py-1 px-4 inline-flex items-center justify-center text-sm font-medium text-white hover:bg-gray-900"
            >
              Entfernen
            </button>
          </div>
          <ngx-dropzone
            (change)="uploadImage($event)"
            accept="image/*"
            class="my-6"
          >
            <ngx-dropzone-label>
              <div class="flex flex-col">
                <i
                  *ngIf="!uploadLoader"
                  class="bi bi-cloud-arrow-up text-2xl mr-2"
                ></i>
                <i
                  *ngIf="uploadLoader"
                  class="bi bi-arrow-clockwise text-2xl animate-spin"
                ></i>

                <span *ngIf="!uploadLoader" class="block text-grey"
                  >Bilder hochladen</span
                >
              </div>
            </ngx-dropzone-label>
            <!--   -->
          </ngx-dropzone>
        </div>
      </div>
      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-12 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsMeta_websiteTitle_label"
            >Seitentitel</label
          >
          <input
            type="text"
            rows="3"
            [(ngModel)]="currentProject.pageTitle"
            placeholder="Seitentitel"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
      </div>
      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-12 sm:col-span-2">
          <label
            for="last-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsMeta_websiteDescription_label"
            >Seitenbeschreibung</label
          >
          <textarea
            type="text"
            rows="3"
            [(ngModel)]="currentProject.pageDescription"
            placeholder="Seitenbeschreibung"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          ></textarea>
        </div>
      </div>
      <div class="px-4 py-3 mt-6 text-right sm:px-6">
        <button
          (click)="saveProject()"
          class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900"
          i18n="@@funnelSettingsMeta_save_button"
        >
          Speichern
        </button>
      </div>
    </div>
  </div>
</section>
