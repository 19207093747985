import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeDataService {
  constructor(private http: HttpClient) {}

  createCustomer(data: any) {
    return this.http.post(
      `${environment.backendUrl}/stripe/create-customer`,
      data
    );
  }

  createTaxId(data: any) {
    return this.http.post(
      `${environment.backendUrl}/stripe/create-tax-id`,
      data
    );
  }

  startSubscription(data: any) {
    return this.http.post(
      `${environment.backendUrl}/stripe/create-subscription`,
      data
    );
  }

  loadStripePricings(productId: string) {
    return this.http.get(
      `${environment.backendUrl}/stripe/pricings/${productId}`
    );
  }

  // load invoices of customer by customerId
  loadInvoices(customerId: string) {
    return this.http
      .get(`${environment.backendUrl}/stripe/invoices/${customerId}`)
      .toPromise();
  }

  // create customer portal session for customer
  createCustomerPortalSession(customerId: string) {
    console.log(customerId);
    return this.http
      .post(`${environment.backendUrl}/stripe/customer-portal/session`, {
        customerId,
      })
      .toPromise();
  }

  // update subscription of customer by customerId and priceId
  updateSubscription(customerId: string, priceId: string) {
    return this.http
      .post(`${environment.backendUrl}/stripe/update-subscription`, {
        customerId,
        priceId,
      })
      .toPromise();
  }

  // cancel existing subscription of a customer
  cancelSubscription(customerId: string) {
    console.log(customerId);
    return this.http
      .post(`${environment.backendUrl}/stripe/cancel-subscription`, {
        customerId: customerId,
      })
      .toPromise();
  }

  updateReceiptEmail(customerId: string, email: string) {
    return this.http
      .post(`http://localhost:5000/stripe/update-receipt-email`, {
        customerId,
        email,
      })
      .toPromise();
  }
}
