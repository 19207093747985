<div class="block">
  <div class="border-b border-gray-200">
    <nav class="-mb-px flex space-x-8 bg-gray-200" aria-label="Tabs">
      <a
        (click)="setActiveTab(0)"
        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
        [ngClass]="{
          'border-indigo-500 text-indigo-600': activeTabIndex === 0
        }"
      >
        Benutzer
      </a>

      <a
        (click)="setActiveTab(1)"
        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
        [ngClass]="{
          'border-indigo-500 text-indigo-600': activeTabIndex === 1
        }"
      >
        Funnels ({{ user.funnelIds.length }})
      </a>

      <a
        (click)="setActiveTab(2)"
        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
        [ngClass]="{
          'border-indigo-500 text-indigo-600': activeTabIndex === 2
        }"
      >
        Zahlungsdaten
      </a>
      <a
        (click)="setActiveTab(3)"
        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
        [ngClass]="{
          'border-indigo-500 text-indigo-600': activeTabIndex === 3
        }"
      >
        Rechnungen
      </a>
    </nav>
  </div>
</div>
