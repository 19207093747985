import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RoutesRecognized,
} from '@angular/router';
import {
  Project,
  PageAnswers,
  AnswerSubmission,
  Page,
} from 'src/app/models/project.model';
import { Block, InputField, QuizBlock } from 'src/app/models/block.model';
import { filter, pairwise } from 'rxjs/operators';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UserService } from 'src/app/services/user.service';
import { LanguageService } from 'src/app/services/lang.service';
import { ProjectService } from 'src/app/services/project.service';
import { DialogModalComponent } from 'src/app/components/modals/dialog-modal/dialog-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { Subscription } from 'rxjs';
import { State } from 'src/app/models/state.model';
import { isUserInTrialAllowed } from 'src/app/trialCheck';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-funnel-statistic',
  templateUrl: './funnel-statistic.component.html',
  styleUrls: ['./funnel-statistic.component.scss'],
})
export class FunnelStatisticComponent {
  statisticData: any[] = [];

  projectId: string;

  displayedColumns: string[] = [
    'Nr.',
    this.langService.getWording('funnelStatistics', 'tableDate'),
  ];
  displayedColumnsPartial: string[] = [
    'Nr.',
    this.langService.getWording('funnelStatistics', 'tableDate'),
  ];

  dataSource: MatTableDataSource<any>;
  dataSourcePartial: MatTableDataSource<any>;
  convRate: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  form: any = { pageId: null, form: null };

  showRightSideBar: boolean = false;
  detailData: any = null;
  formData: any = null;

  answerSubmissions: AnswerSubmission[] = [];
  settingsTabsIndex = 0;
  allLeads: any[] = [];
  allLeadsPartial: any[] = [];

  state$: Subscription;
  impressions: any;

  navigationIndex: number = 0;
  adminEdit: string;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    public langService: LanguageService,
    private projectService: ProjectService,
    private appService: AppService
  ) {
    this.projectId = this.route.snapshot.params.id;
    this.adminEdit = this.route.snapshot.queryParamMap.get('adminEdit') || '';

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        const isAdmin = this.checkIfEmailIncludesAdminMailaddress(user);
        if (this.adminEdit !== 'true' && isAdmin) {
           if (!user.funnelIds.includes(this.projectId)) {
            this.router.navigate(['/funnels']);
          }
        }

        if (!isUserInTrialAllowed(user)) {
          this.router.navigateByUrl('/user-settings?payment=true');
        }
      }
    });

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource<any>();
    this.dataSourcePartial = new MatTableDataSource<any>();
  }

  checkIfEmailIncludesAdminMailaddress(user: User) {
    if (
      user.email.includes('@zewotherm.de') ||
      user.email.includes('@zw-systems.de') ||
      user.email.includes('@klicklead.de')
    ) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    const projectId = this.route.snapshot.paramMap.get('id') || '';

    const project = await this.projectService.getProjectById(projectId);
    if (project) {
      this.appService.stateSubject.next({
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentProject: project,
        },
      });
    }

    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.navigationIndex = state.funnelLeads.navigationIndex;
      }
    });

    this.dataSource.sort = this.sort;

    this.projectService.currentProject =
      await this.projectService.getProjectById(this.projectId);
    //this.answerSubmissions = await this.projectService.getLeads(this.projectId);
    const loadedLeads = await this.projectService.getLeads(this.projectId);

    this.impressions = await this.projectService.getProjectListById(
      this.projectId
    );

    let pages = [];
    // iterate through impressions (is a object) and check the key (pageId) and push the value (impressions) into the pages array
    for (const [key, value] of Object.entries(this.impressions)) {
      // check if key is a pageId from project.pages
      if (project.pages.find((p:any) => p.id === key)) {
        pages.push({ pageId: key, impressions: value });
      }
    }

    // sort pages by project.pages order
    pages = pages.sort((a:any, b:any) => {
      const aIndex = project.pages.findIndex((p:any) => p.id === a.pageId);
      const bIndex = project.pages.findIndex((p:any) => p.id === b.pageId);
      return aIndex - bIndex;
    });
    

    console.log(pages);

    console.log(this.impressions);

    if (!this.projectService.currentProject) return;
    this.projectService.currentProject.pages.forEach((p: Page) => {
      this.displayedColumnsPartial.push(p.name);
      p.blocks.forEach((block: Block) => {
        if (block.type === 'form') {
          this.form.form = block.content['inputs'];
          this.form.pageId = p.id.toString();
        }
      });
      this.statisticData.push({ name: p.name, id: p.id.toString(), value: 0 });
    });

    if (this.form.form !== null) {
      this.form.form.forEach((inp: InputField) => {
        this.displayedColumns.push(inp.text);
      });
    }

    this.displayedColumns.push('Aktion');

    let data: any[] = [];
    let dataPartial: any[] = [];

    if (typeof loadedLeads !== 'undefined') {
      loadedLeads.forEach((as: AnswerSubmission) => {
        as.submissions.forEach((sub: PageAnswers) => {
          if (Array.isArray(sub.answer)) {
            let subArray = [];
            sub.answer.forEach((inp: InputField) => {
              if (inp.value !== null) {
                subArray.push({ [inp.text]: inp.value });
              }
            });

            if (subArray.length !== 0) {
              let object = {};
              subArray.push({ answerSubmission: as });
              subArray.forEach(function (element: any, index: number) {
                Object.assign(object, element);
              });
              // Object.assign(object, { nr: data.length + 1 })
              let dateString = this.langService.getWording(
                'funnelStatistics',
                'tableDate'
              );

              Object.assign(object, {
                [dateString.toString()]: as.date,
              });

              data.push(object);
            }
          }

          let index = this.statisticData.findIndex(
            (sd) => sd.id === sub.pageId
          );

          if (index !== -1) {
            this.statisticData[index].value++;
          }
        });
      });
    }

    loadedLeads.forEach((element: AnswerSubmission, index: number) => {
      let answerObj = {};
      // if (element.submissions.length !== this.projectService.currentProject.pages.length) {
      element.submissions.forEach((sub: any, index: number) => {
        if (!this.projectService.currentProject) return;
        let page = this.projectService.currentProject.pages.find(
          (p) => p.id.toString() === sub.pageId.toString()
        );

        if (typeof page !== 'undefined') {
          if (Array.isArray(sub.answer)) {
            let formData: any[] = [];
            sub.answer.forEach((inp: InputField) => {
              if (inp.value !== null) {
                formData.push(inp.text + ': ' + inp.value);
              }
            });
            Object.assign(answerObj, { [page.name]: formData.join() });
          } else {
            let answer: string = '';

            if (sub.answer !== null) {
              answer = sub.answer.answerText
                ? sub.answer.answerText
                : sub.answer;
            }

            Object.assign(answerObj, {
              [page.name]: sub.answer !== null ? answer : '-',
            });
          }
        } else {
          if (Array.isArray(sub.answer)) {
            let formData: any[] = [];
            sub.answer.forEach((inp: InputField) => {
              if (inp.value !== null) {
                formData.push(inp.text + ': ' + inp.value);
              }
            });
            Object.assign(answerObj, {
              ['gelöschte Seite' + index]: formData.join(),
            });
          } else {
            let answer: string = '';

            if (sub.answer !== null) {
              answer = sub.answer.answerText
                ? sub.answer.answerText
                : sub.answer;
            }
            Object.assign(answerObj, {
              ['gelöschte Seite' + index]: sub.answer !== null ? answer : '-',
            });
          }
        }
      });
      let dateString = this.langService.getWording(
        'funnelStatistics',
        'tableDate'
      );

      Object.assign(answerObj, {
        [dateString.toString()]: element.date,
      });

      dataPartial.push(answerObj);
      //  }
    });

    this.allLeads = data;
    this.allLeadsPartial = dataPartial;

    this.allLeadsPartial.sort(function (a, b) {
      const bDate: any = b.Datum as Object;
      const aDate: any = a.Datum as Object;
      return (
        new Date(aDate.seconds * 1000).getTime() -
        new Date(bDate.seconds * 1000).getTime()
      );
    });

    this.allLeads.sort(function (a, b) {
      const bDate: any = b.Datum as Object;
      const aDate: any = a.Datum as Object;
      return (
        new Date(aDate.seconds * 1000).getTime() -
        new Date(bDate.seconds * 1000).getTime()
      );
    });

    this.dataSource.data = data;
    this.dataSourcePartial.data = dataPartial;
    this.getConvertRate();
  }

  openLead(el: any) {
    console.log(el);

    this.showRightSideBar = true;
    let detail = JSON.parse(JSON.stringify(el));
    let formData = null;
    let deleteIndex = -1;
    detail.answerSubmission.submissions.forEach(
      (element: any, index: number) => {
        if (!this.projectService.currentProject) return;
        let page = this.projectService.currentProject.pages.find(
          (p) => p.id.toString() === element.pageId
        );
        if (typeof page !== 'undefined') {
          element.pageName = page.name;
        } else {
          element.pageName = 'gelöschte Seite';
        }

        if (Array.isArray(element.answer)) {
          formData = element.answer;
          deleteIndex = index;
        }
      }
    );

    if (deleteIndex !== -1) {
      detail.answerSubmission.submissions.splice(deleteIndex, 1);
    }
    this.formData = formData;
    this.detailData = detail;
  }

  async resetStatistic() {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: {
        buttonText: 'Zurücksetzen',
        text: 'Wollen Sie die Statistik (Leads, Alle Einträge) wirklich zurücksetzen?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (!this.projectService.currentProject) return;
        /*   await this.projectService.deleteAnswerSubmissions(
          this.projectService.currentProject.id
        ); */
        this.answerSubmissions = [];
        this.allLeads = [];
        this.allLeadsPartial = [];
        this.statisticData = [];
      }
    });
  }

  deleteLead(index: number) {
    let ds = JSON.parse(JSON.stringify(this.dataSource.data));
    ds.splice(index, 1);
    this.dataSource.data = ds;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.data = this.allLeads;

    this.dataSource.data = this.dataSource.data.filter(
      (data) =>
        JSON.stringify(data)
          .toLowerCase()
          .indexOf(filterValue.toLowerCase()) !== -1
    );
  }

  applyPartialFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSourcePartial.data = this.allLeadsPartial;

    this.dataSourcePartial.data = this.dataSourcePartial.data.filter(
      (data) =>
        JSON.stringify(data)
          .toLowerCase()
          .indexOf(filterValue.toLowerCase()) !== -1
    );
  }

  getConvertRate() {
    if (
      typeof this.answerSubmissions !== 'undefined' ||
      this.allLeads.length !== 0
    ) {
      if (this.answerSubmissions.length !== 0) {
        let returnVal = (
          (this.allLeads.length * 100) /
          this.allLeadsPartial.length
        ).toFixed(2);

        if (returnVal === 'NaN') {
          this.convRate = 0;
        } else {
          this.convRate = returnVal;
        }
      }
    }
  }

  toDateTime(secs: any) {
    var t = new Date(); // Epoch
    t.setSeconds(secs);
    return t;
  }

  navigateBack() {
    this.location.back();
  }

  openDashboard() {
    this.router.navigate(['/funnels']);
  }

  openFunnel() {
    this.router.navigate(['funnel-editor/' + this.projectId]);
  }

  exportToCsv() {
    let filename = new Date().toDateString();

    if (!this.allLeads.length) {
      return;
    }

    var csvContent = 'data:text/csv;charset=utf-8,';
    let arrayData = JSON.parse(JSON.stringify(this.allLeads));
    arrayData.forEach(function (item: any) {
      delete item.answerSubmission;
    });
    // headers
    csvContent += this.objectToCSVRow(Object.keys(arrayData[0]));

    let that = this;
    arrayData.forEach(function (item: any) {
      csvContent += that.objectToCSVRow(item);
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'leads.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  objectToCSVRow(dataObject: any) {
    var dataArray = new Array();
    for (var o in dataObject) {
      if (Object.prototype.toString.call(dataObject[o]) === '[object Object]') {
        /* for (var m in dataObject[o].submissions) {

          var innerValue = dataObject[o].submissions[m] === null ? '' : dataObject[o].submissions[m].toString();
          var result = innerValue.replace(/"/g, '""');
          result = '"' + result + '"';
        } */
      } else {
        var innerValue = dataObject[o] === null ? '' : dataObject[o].toString();
        var result = innerValue.replace(/"/g, '""');
        result = '"' + result + '"';
      }
      dataArray.push(result);
    }
    return dataArray.join(' ') + '\r\n';
  }

  isDisabled(feature: any) {
    /*  if(! this.userService.userData)return
    const isEnabled = this.tierService.getRights(
      feature,
      this.userService.userData.tier
    ); */

    return true;
  }
}
