import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NotificationType } from 'src/app/enums/notification-type';
import { CustomDomain, DomainId, Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { DomainService } from 'src/app/services/domain.service';
import { ProjectService } from 'src/app/services/project.service';
import { TierService } from 'src/app/services/tier.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { AddDomainModalComponent } from '../../modals/add-domain-modal/add-domain-modal.component';
import { InfoModalComponent } from '../../modals/info-modal /info-modal .component';

@Component({
  selector: 'app-settings-domains',
  templateUrl: './settings-domains.component.html',
  styleUrls: ['./settings-domains.component.scss'],
})
export class SettingsDomainsComponent implements OnInit {
  currentProject: Project;
  projectId: string;

  customDomains: CustomDomain[] = [];
  customDomainsOfUser: CustomDomain[] = [];
  maxCustomDomains: number = 0;
  domainIds: DomainId[] = [];
  user: User;
  constructor(
    private projectService: ProjectService,
    private appService: AppService,
    private dialog: MatDialog,
    private domainService: DomainService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private tierService: TierService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
      }
    });

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
        this.getCustomDomainRights();
      }
    });

    this.projectId = this.route.snapshot.params.id;
    await this.loadDomains();
  }

  getCustomDomainRights() {
    if (this.user.tier) {
      this.maxCustomDomains = this.tierService.getRights(
        'customDomain',
        this.user.tier!
      );
      console.log(this.maxCustomDomains);
    }
  }

  async saveProject() {
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: true,
        notificationType: NotificationType.SUCCESS,
        notificationMessage: 'Erfolgreich gespeichert',
        notificationDescription:
          'Die Änderungen wurden erfolgreich gespeichert.',
      },
    });
    await this.projectService.saveCurrentProject();

    await this.appService.delay(5000);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: false,
      },
    });
  }

  openAddDomainModal() {
    const dialogRef = this.dialog.open(AddDomainModalComponent, {
      width: '550px',
      height: '450px',
      data: {
        customDomainsOfUser: this.customDomainsOfUser.length,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.loadDomains();
    });
  }

  async loadDomains() {
    this.customDomains = await this.domainService.getCustomDomainsOfFunnel(
      this.projectId
    );
    this.domainIds = await this.domainService.getDomainIdOfFunnel(
      this.projectId
    );

    this.customDomainsOfUser = await this.domainService.getDomainsOfUser(
      this.user.id
    );
  }

  async deleteCustomDomain(customDomain: CustomDomain, index: number) {
    await this.domainService.deleteCustomDomain(customDomain.id);
    this.customDomains.splice(index, 1);

    const customDomainURL = new URL(customDomain.domain);

    let link = `https://api.vercel.com/v8/projects/prj_MudunYJ1NG3wSRRSpdIJ7mHsjDN0/domains/${customDomainURL.host}?teamId=team_b5hpvvNtFK82xKc6Rv6HkrqE`;

    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      Authorization: `Bearer ${environment.vercelToken}`,
    });
    this.http.delete(link, { headers: headers }).subscribe(
      (data) => {
        console.log(data);
      },
      (error) => {
        console.log('Oooops!', error);
      }
    );
  }

  async deleteDomainId(domain: DomainId, index: number) {
    await this.domainService.deleteDomainId(domain.id);
    this.domainIds.splice(index, 1);
  }
}
