<div
  class="flex"
  [ngClass]="{
    'justify-start items-start text-left': block.style?.alignment === 'start',
    'justify-center items-center text-center':
      block.style?.alignment === 'center',
    'justify-end items-end text-right': block.style?.alignment === 'end'
  }"
>
  <div
    *ngIf="block.content.type === 'spinner'"
    class="border-2 border-solid rounded-full animate-spin border-t-transparent"
    [ngStyle]="style"
  ></div>
</div>
