import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Answer, Block } from 'src/app/models/block.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-design-menu',
  templateUrl: './design-menu.component.html',
  styleUrls: ['./design-menu.component.scss'],
})
export class DesignMenuComponent implements OnInit {
  @Input() doc: Document;
  @Input() selectedElement: Block;
  @Output() setClickOutsideEvent = new EventEmitter();
  state$: Subscription;

  showColorSketch: boolean = false;
  fontSize: number = 12;
  showColor: boolean = false;
  constructor(private appService: AppService) {}

  ngOnInit(): void {}

  setBold() {
    /* this.setBoldEvent.emit(); */
    this.selectedElement.style!.fontWeight =
      this.selectedElement.style?.fontWeight === 'bold' ? 'normal' : 'bold';
    this.doc.execCommand('bold', false, 'asd');
    this.setClickOutsideEvent.emit();
    this.appService.setShouldSave();
  }

  setItalic() {
    this.selectedElement.style!.fontStyle =
      this.selectedElement.style!.fontStyle === 'italic' ? 'normal' : 'italic';
    this.doc.execCommand('italic', false, undefined);
    this.setClickOutsideEvent.emit();
    this.appService.setShouldSave();
  }

  setUnderline() {
    this.selectedElement.style!.textDecoration =
      this.selectedElement.style!.textDecoration === 'underline'
        ? 'none'
        : 'underline';
    this.doc.execCommand('underline', false, undefined);
    this.setClickOutsideEvent.emit();
    this.appService.setShouldSave();
  }

  handleChangeComplete(color: any) {
    console.log(color);
  }

  toggleColorSketch() {
    this.showColorSketch = !this.showColorSketch;
  }

  isVisible(element: string) {
    switch (element) {
      case 'bold':
      case 'italic':
      case 'underline': {
        const allowedBlocks = ['header', 'button', 'selection', 'quiz'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'fontsize': {
        const allowedBlocks = [
          'header',
          'button',
          'selection',
          'quiz',
          'textfield',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'alignment': {
        const allowedBlocks = [
          'header',
          'button',
          'picture',
          'socialmedia',
          'fakeloader',
          'textfield',
          'quiz',
          'selection',
          'bulletpoints',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'color': {
        const allowedBlocks = [
          'header',
          'button',
          'form',
          'textfield',
          'selection',
          'slider',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      default:
        return false;
    }
  }

  alignElement(alignment: any) {
    if (!this.selectedElement?.style) return;
    switch (alignment) {
      case 'left-item':
        this.selectedElement.style.contentAlignment = 'start';
        console.log(this.selectedElement.style);
        break;
      case 'center-item':
        this.selectedElement.style.contentAlignment = 'center';
        break;
      case 'right-item':
        this.selectedElement.style.contentAlignment = 'end';
        break;
      default:
        this.selectedElement.style.contentAlignment = 'center';
        break;
    }

    const alignBlockElements = [
      'header',
      'socialmedia',
      'form',
      'picture',
      'fakeloader',
      'quiz',
      'upload',
    ];

    if (alignBlockElements.includes(this.selectedElement.type)) {
      switch (alignment) {
        case 'left-item':
          this.selectedElement.style.alignment = 'start';
          console.log(this.selectedElement.style);
          break;
        case 'center-item':
          this.selectedElement.style.alignment = 'center';
          break;
        case 'right-item':
          this.selectedElement.style.alignment = 'end';
          break;
        default:
          this.selectedElement.style.alignment = 'center';
          break;
      }
    }

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  decreaseFontSize() {
    if (this.selectedElement?.type === 'quiz') {
      this.selectedElement.content.answers.forEach((answer: Answer) => {
        if (
          answer.style &&
          answer.style.fontSize &&
          answer.style.fontSize > 0
        ) {
          answer.style.fontSize = answer.style?.fontSize - 1;
        }
      });
    }

    if (this.selectedElement !== null && this.selectedElement.style) {
      this.selectedElement.style.fontSize = this.selectedElement.style?.fontSize
        ? this.selectedElement.style?.fontSize - 1
        : 10;
    }

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  increaseFontSize() {
    if (this.selectedElement?.type === 'quiz') {
      this.selectedElement.content.answers.forEach((answer: Answer) => {
        if (
          answer.style &&
          answer.style.fontSize &&
          answer.style.fontSize > 0
        ) {
          answer.style.fontSize = answer.style?.fontSize + 1;
        }
      });
    }

    if (this.selectedElement !== null && this.selectedElement.style) {
      this.selectedElement.style.fontSize = this.selectedElement.style?.fontSize
        ? this.selectedElement.style?.fontSize + 1
        : 10;
    }

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  /*   setColor(color: any) {
    this.showColor = false;
  }
 */
  setColor(event: any) {
    this.showColor = false;

    if (!this.selectedElement?.style) return;
    this.selectedElement.style.fontColor = event.target.value;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  openColorPicker() {
    if (!this.isVisible('color')) return;
    this.showColor = true;

    const colorPicker = document.getElementById('colorPicker');
    colorPicker?.focus();
    colorPicker?.click();
  }

  close() {
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              currentElementIndex: -1,
              selectedElement: null,
            },
          },
        },
      },
      false
    );
  }
}
