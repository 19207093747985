import { style } from '@angular/animations';
import {
  CdkDragDrop,
  copyArrayItem,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { pipe, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  switchMap,
  take,
} from 'rxjs/operators';
import {
  Answer,
  Block,
  InputField,
  SelectAnswer,
} from 'src/app/models/block.model';
import { Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';
import { BlockService } from 'src/app/services/block.service';
import { ProjectService } from 'src/app/services/project.service';
import { uuidv4 } from 'src/app/services/uuid';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  html: any;
  htmlChanged: Subject<any> = new Subject<any>();

  state$: Subscription;

  isHoverIndex: number | null;
  selectedElement: Block | null | any = null;
  rightTabIndex: number = 0;
  showBlocks: boolean = false;
  currentElementIndex: number | null;
  currentPanelIndex: number = -1;
  currentSelectionIndex: number = -1;
  currentFormPanelIndex: number = -1;
  projectId: any;
  currentPageIndex: number = 0;

  currentProject: Project;
  submitBtnHover: boolean = false;

  inputFormInputIndex: number = -1;
  inputIndex: number = -1;
  mobileView: boolean = true;

  undoLimit: number = 10;
  showUndo: boolean = false;
  showRedo: boolean = false;

  projectRedoArray: Project[] = [];
  projectUndoArray: Project[] = [];
  projectCurrentArray: Project[] = [];
  initialUndo: boolean = true;
  initialProject: Project;
  isSaved: boolean = true;

  constructor(
    private appService: AppService,
    public projectService: ProjectService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private blockService: BlockService
  ) {
    this.projectId = this.route.snapshot.paramMap.get('id');

    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
      }
    });

    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        /*  this.currentPageIndex = state.editor.currentPageIndex;
        this.selectedElement = state.editor.selectedElement;
        this.html = state.editor.html;
        this.currentElementIndex = state.editor.currentElementIndex;
        this.rightTabIndex = state.editor.rightTabIndex; */
        this.currentFormPanelIndex = state.editor.currentFormPanelIndex;
        this.currentSelectionIndex = state.editor.currentSelectionIndex;
        /*     this.projectRedoArray = state.history.projectRedoArray;
        this.projectUndoArray = state.history.projectUndoArray;
        this.projectCurrentArray = state.history.projectCurrentArray;
        this.showUndo = state.history.showUndo;
        this.showRedo = state.history.showRedo;
        this.initialUndo = state.history.initialUndo;
        this.isSaved = state.history.isSaved; */
      }
    });

    this.htmlChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(async (test) => {
          this.isSaved = true;
          console.log(test);
        })
      )
      .subscribe((html) => {
        this.isSaved = true;
        this.setHistory();
      });
  }

  trackByFn(i: number, items: Block) {
    return items.id; //returning the index itself for avoiding ngFor to change focus after ngModelChange
  }

  changed(event: any) {
    this.isSaved = false;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,

          html: this.html,
        },
        /* history: {
          ...this.appService.state.history,
          isSaved: this.isSaved,
        }, */
      },
      false
    );
    this.htmlChanged.next(event);
  }

  async ngOnInit() {
    if (!this.currentProject) return;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentPageIndex: 0,
          html: this.currentProject.pages[0].blocks,
        },
      },
      false
    );

    this.initialProject = JSON.parse(JSON.stringify(this.currentProject));
    if (!this.initialProject) return;
    if (this.projectUndoArray.length == 0) {
      this.projectUndoArray.push(this.initialProject);
    }
  }

  setHistory() {
    this.projectRedoArray = [];
    this.showRedo = true;

    if (this.projectUndoArray.length == 0) {
      this.projectUndoArray.push(this.initialProject);
    }

    if (this.projectCurrentArray.length !== 0) {
      const cur_pro_pop = JSON.parse(
        JSON.stringify(this.projectCurrentArray.pop())
      );
      if (!cur_pro_pop) return;
      this.projectUndoArray.push(cur_pro_pop);
    } else {
      const cur_pro_pop = JSON.parse(JSON.stringify(this.currentProject));
      if (!cur_pro_pop) return;

      this.projectUndoArray.push(cur_pro_pop);
    }

    const cur_pro = JSON.parse(JSON.stringify(this.currentProject));
    let test = JSON.parse(JSON.stringify(cur_pro));
    this.projectCurrentArray.push(test);
    if (this.projectUndoArray.length == 0) {
      this.showUndo = false;
    } else {
      this.showUndo = true;
    }
    // }

    this.initialUndo = true;

    //this.projectCurrentArray[0].pages[this.currentPageIndex].blocks = this.html;
    this.projectService.currentProjectSubject.next(this.projectCurrentArray[0]);
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          html: this.projectService.currentProjectSubject.getValue().pages[
            this.currentPageIndex
          ].blocks,
        },
        /*   history: {
          ...this.appService.state.history,
          projectRedoArray: this.projectRedoArray,
          projectUndoArray: this.projectUndoArray,
          projectCurrentArray: this.projectCurrentArray,
          showUndo: this.showUndo,
          showRedo: this.showRedo,
          initialUndo: this.initialUndo,
          isSaved: true,
        }, */
      },
      true
    );
  }

  undo() {
    if (this.projectUndoArray.length !== 0) {
      const cur_pro = JSON.parse(
        JSON.stringify(this.projectCurrentArray.pop())
      );
      if (!cur_pro) return;
      this.projectRedoArray.push(cur_pro);

      let undo_pro = JSON.parse(JSON.stringify(this.projectUndoArray.pop()));
      if (this.initialUndo) {
        undo_pro = JSON.parse(JSON.stringify(this.projectUndoArray.pop()));
      }
      if (!undo_pro) return;
      this.projectCurrentArray.push(undo_pro);

      if (this.projectUndoArray.length == 0) {
        this.showUndo = false;
      }

      this.showRedo = true;
    }
    this.initialUndo = false;

    this.projectService.currentProjectSubject.next(this.projectCurrentArray[0]);

    /*   if (this.currentElementIndex === null) return;
    this.selectedElement =
      this.projectCurrentArray[0].pages[this.currentPageIndex].blocks[
        this.currentElementIndex
      ]; */
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          html: this.projectService.currentProjectSubject.getValue().pages[
            this.currentPageIndex
          ].blocks,
        },
        /*  history: {
          ...this.appService.state.history,
          projectRedoArray: this.projectRedoArray,
          projectUndoArray: this.projectUndoArray,
          projectCurrentArray: this.projectCurrentArray,
          showUndo: this.showUndo,
          showRedo: this.showRedo,
          initialUndo: this.initialUndo,
        }, */
      },
      true
    );
  }

  redo() {
    if (this.projectRedoArray.length != 0) {
      const cur_pro = this.projectCurrentArray.pop();
      if (!cur_pro) return;
      this.projectUndoArray.push(cur_pro);

      const redo_pro = this.projectRedoArray.pop();
      if (!redo_pro) return;
      this.projectCurrentArray.push(redo_pro);

      if (this.projectRedoArray.length == 0) {
        this.showRedo = false;
      }
    }

    if (this.projectUndoArray.length > 0) {
      this.showUndo = true;
    } else {
      this.showUndo = false;
    }

    this.projectService.currentProjectSubject.next(this.projectCurrentArray[0]);

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,

          html: this.currentProject.pages[this.currentPageIndex].blocks,
        },
        /*    history: {
          ...this.appService.state.history,
          projectRedoArray: this.projectRedoArray,
          projectUndoArray: this.projectUndoArray,
          projectCurrentArray: this.projectCurrentArray,
        }, */
      },
      true
    );
  }

  updateAndSave() {
    this.saveProject();

    /*  this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentPageIndex: this.currentPageIndex,
          html: this.currentProject.pages[this.currentPageIndex].blocks,
        },
      },
      true
    ); */
  }

  mouseEnter(data: any, index: any) {
    this.html[index].isHoverDisplay = data;
  }

  mouseLeave(data: any, index: any) {
    this.html[index].isHoverDisplay = data;
  }

  hoverListItem(index: number | null) {
    this.isHoverIndex = index;
  }

  getSocialMediaIcon(type: string) {
    switch (type) {
      case 'facebook':
        return 'bi bi-facebook';
      case 'twitter':
        return 'bi bi-twitter';
      case 'instagram':
        return 'bi bi-instagram';
      case 'youtube':
        return 'bi bi-youtube';
      case 'linkedin':
        return 'bi bi-linkedin';
      case 'pinterest':
        return 'bi bi-pinterest';
      case 'vimeo':
        return 'bi bi-vimeo';
      case 'github':
        return 'bi bi-github';
      case 'dribbble':
        return 'bi bi-dribbble';

      default:
        return '';
    }
  }

  getSocialMediaStyle(index: number, type: string) {
    let style = this.getStyle(index);

    switch (type) {
      case 'facebook':
        style = { ...style, color: '#3b5998' };
        break;
      case 'twitter':
        style = { ...style, color: '#00aced' };
        break;
      case 'instagram':
        style = { ...style, color: '#bc2a8d' };
        break;
      case 'youtube':
        style = { ...style, color: '#bb0000' };
        break;
      case 'linkedin':
        style = { ...style, color: '#007bb6' };
        break;
      case 'pinterest':
        style = { ...style, color: '#cb2027' };
        break;
      case 'vimeo':
        style = { ...style, color: '#1ab7ea' };
        break;
      case 'github':
        style = { ...style, color: '#000000' };
        break;
      case 'dribbble':
        style = { ...style, color: '#ea4c89' };
        break;

      default:
        style.fontColor = '#000000';
        break;
    }
    return style;
  }

  selectElement(item: Block, elementIndex: number) {
    this.currentElementIndex = elementIndex;
    this.selectedElement =
      this.html[this.currentElementIndex ? this.currentElementIndex : 0];

    if (!this.selectedElement.style) return;
    this.selectedElement.style.fontColor
      ? this.selectedElement.style.fontColor
      : '#000000';
    this.selectedElement.style.backgroundColor
      ? this.selectedElement.style.backgroundColor
      : '#000000';

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          currentElementIndex: this.currentElementIndex,
          showBlocks: true,
          rightTabIndex: 1,
        },
      },
      false
    );
    console.log(this.selectedElement);
  }

  selectAnswerButton(index: number) {
    this.currentPanelIndex = index;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentPanelIndex: this.currentPanelIndex,
        },
      },
      false
    );
  }

  selectAnswer(index: number) {
    this.currentSelectionIndex = index;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentSelectionIndex: this.currentSelectionIndex,
        },
      },
      false
    );
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getVideoUrl(url: string) {
    const videoType = this.getVideoProviderType(url);
    let videoLink = '';
    switch (videoType) {
      case 'youtube':
        const youtubeId = this.getYoutubeId(url);
        videoLink = 'https://www.youtube-nocookie.com/embed/' + youtubeId;
        break;
      case 'vimeo':
        var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
        var match = url.match(regExp);
        if (!match) return;

        videoLink = 'https://player.vimeo.com/video/' + match[2];

        break;
      default:
        break;
    }

    return this.getSantizeUrl(videoLink);
  }

  getVideoProviderType(url: string) {
    // - Supported YouTube URL formats:
    //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
    //   - http://youtu.be/My2FRPA3Gf8
    //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
    // - Supported Vimeo URL formats:
    //   - http://vimeo.com/25451551
    //   - http://player.vimeo.com/video/25451551
    // - Also supports relative URLs:
    //   - //player.vimeo.com/video/25451551

    let type: string = '';
    if (url.indexOf('youtube.com') > 0 || url.indexOf('youtu.be') > 0) {
      type = 'youtube';
    }
    if (url.indexOf('vimeo.com') > 0 || url.indexOf('player.vimeo.com') > 0) {
      type = 'vimeo';
    }

    return type;
  }

  getYoutubeId(url: string) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  getSelectionStyle(i: number, preview?: boolean) {
    return this.blockService.getSelectionStyle(this.html, i, preview);
  }

  getQuizButtonStyle(answerIndex: number, i: number) {
    return this.blockService.getQuizButtonStyle(this.html, answerIndex, i);
  }

  getQuizTextStyle(answerIndex: number, i: number) {
    return this.blockService.getQuizTextStyle(this.html, answerIndex, i);
  }

  getQuizIconStyle(answerIndex: number, i: number) {
    return this.blockService.getQuizIconStyle(this.html, answerIndex, i);
  }

  getFormSubmitButtonStyle(index: number) {
    return this.blockService.getFormSubmitButtonStyle(
      this.html,
      index,
      this.submitBtnHover
    );
  }

  getFormPrivacyCheckStyle(index: any) {
    return this.blockService.getFormPrivacyCheckStyle(this.html, index);
  }

  getBackgroundColor(block?: Block) {
    return this.blockService.getBackgroundColor(
      this.currentProject,
      this.currentPageIndex,
      block
    );
  }

  getBulletpointStyle(index: number) {
    let style: any = this.blockService.getBulletpointStyle(
      this.html,
      index,
      'text'
    );

    return style;
  }

  getBulletpointIconStyle(index: number) {
    let style: any = this.blockService.getBulletpointStyle(
      this.html,
      index,
      'icon'
    );
    return style;
  }

  getStyle(index: number, id?: any) {
    let style: any = this.blockService.getStyle(
      this.html,
      index,
      this.inputIndex
    );
    /*  if (id) {
      let element = document.getElementById(id);
      if (!element) return;
      style = { ...style, height: this.autoGrowTextZone(element) };
    } */
    return style;
  }

  autoGrowTextZone(e: any) {
    e.style.height = '0px';
    e.style.height = e.scrollHeight + 25 + 'px';
  }

  getAlignment(index: number) {
    let currentBlock: Block = this.html[index];
    return currentBlock.style?.alignment;
  }

  showDialog(header: string, description: string) {
    this.appService.showDialog(header, description);
  }

  drop(event: CdkDragDrop<any[]>): void {
    let element: Block = event.previousContainer.data[event.previousIndex];
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if (this.validateElementsOnPage(event.container.data, element.type)) {
        this.showDialog(
          'Validierung',
          'Dieser Block kann nur 1 mal pro Seite benutzt werden'
        );
        return;
      }
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.html[event.currentIndex].id = uuidv4();
      this.selectedElement = this.html[event.currentIndex];
      this.rightTabIndex = 0;
    }

    this.setHeaderToFirstElement();
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          rightTabIndex: this.rightTabIndex,
          html: this.html,
          showBlocks: false,
        },
      },
      true
    );
    this.saveProject();
  }

  setHeaderToFirstElement() {
    this.html.forEach((element: Block, index: number) => {
      if (index !== 0) {
        element.isHeader = false;
      }
    });
  }

  validateElementsOnPage(page: Block[], elementType: string) {
    const allowedMultipleElements = [
      'header',
      'picture',
      'button',
      'video',
      'socialmedia',
      'bulletpoints',
    ];
    page = page.filter(
      (element: any) => !allowedMultipleElements.includes(element.type)
    );
    let block = page.filter((element: any) => element.type === elementType);

    if (block.length >= 1) {
      return true;
    }
    return false;
  }

  selectFormElement(
    input: InputField | null,
    inputIndex: number,
    type: string
  ) {
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentFormPanelIndex: inputIndex,
        },
      },
      false
    );
  }

  copyElement(index: any) {
    this.selectedElement = null;
    let copyBlock: Block = JSON.parse(JSON.stringify(this.html[index])); //Object.assign({}, this.html[index]);
    copyBlock.id = uuidv4();
    this.html.splice(index + 1, 0, copyBlock);

    this.selectedElement = this.html.filter(
      (el: any) => copyBlock.id === el.id
    )[0];

    this.projectService.currentProjectSubject.next(this.currentProject);

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: null,
          rightTabIndex: this.rightTabIndex,
          html: this.html,
        },
      },
      true
    );
    this.changed('copy');
  }

  getFontCSS(index: number) {
    return this.blockService.getFontCSS(this.html, index);
  }

  over() {
    this.submitBtnHover = true;
  }

  out() {
    this.submitBtnHover = false;
  }

  focusIn(index: number) {
    this.inputIndex = index;
  }

  focusOut(index: number) {
    this.inputIndex = -1;
  }

  focusFormInputIn(index: number) {
    this.inputFormInputIndex = index;
  }

  focusFormInputOut() {
    this.inputFormInputIndex = -1;
  }

  getFormInputStyle(index: number, inputIndex: number) {
    return this.blockService.getFormInputStyle(
      this.html,
      index,
      inputIndex,
      this.inputFormInputIndex
    );
  }

  removeElement(index: any) {
    this.rightTabIndex = 0;
    this.html.splice(index, 1);
    if (!this.currentProject) return;

    this.currentProject.pages[this.currentPageIndex].blocks = this.html;
    this.projectService.currentProjectSubject.next(this.currentProject);
    /*    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: null,
          rightTabIndex: this.rightTabIndex,
          html: this.html,
        },
      },
      true
    ); */

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              currentElementIndex: -1,
              selectedElement: null,
              
            },
          },
        },
      },
      false
    );
    this.changed('remove');
  }

  getProgress(type: any) {
    return this.blockService.getProgress(
      this.currentProject,
      this.currentPageIndex,
      type
    );
  }

  toggleBlocks() {
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: null,
          rightTabIndex: 0,
          showBlocks: true,
        },
      },
      false
    );
  }

  saveProject() {
    this.changed('save');
  }
}
