import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from 'src/app/enums/notification-type';
import { Project } from 'src/app/models/project.model';
import { AppService } from 'src/app/services/app.service';
import { ProjectService } from 'src/app/services/project.service';
import { EmailTextDialogComponent } from '../../modals/email-text-dialog/email-text-dialog.component';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings-lead-notification',
  templateUrl: './settings-lead-notification.component.html',
  styleUrls: ['./settings-lead-notification.component.scss'],
})
export class SettingsLeadNotificationComponent implements OnInit {
  currentProject: Project;
  tabIndex: number = 0;
  testMailAddress: string = '';
  constructor(
    private projectService: ProjectService,
    private appService: AppService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
        this.initLeadNotification();
      }
    });
  }

  setTabIndex(index: number) {
    this.tabIndex = index;
  }

  async sendTestMail() {
    let mailText = this.currentProject.leadNotification?.text.replace(
      '[LEAD]',
      'Max Mustermann, 12345 Musterstadt, 01234/56789'
    );
    mailText = mailText.replace(
      '[ANSWERS]',
      ['Antwort 1: ABC', 'Antwort 2: DEF', 'Antwort 3: XYZ'].join('\r\n')
    );
    mailText = mailText.replace(/\n/g, '<br>');

    const pass = crypto.AES.encrypt(
      this.currentProject.leadNotification?.smtpPassword,
      environment.cryptoPass
    );

    this.appService.showNotification(
      'E-Mail erfolgreich versendet',
      'Die E-Mail wurde erfolgreich an das angegebene E-Mail-Adresse versendet.',
      NotificationType.SUCCESS,
      5000
    );
    const response: any = await this.projectService.sendLeadNotification({
      testMailAddress: this.testMailAddress,
      smtpUser: this.currentProject.leadNotification?.smtpUsername,
      smtpPassword: pass.toString(),
      smtpHost: this.currentProject.leadNotification?.smtpHost,
      smtpPort: this.currentProject.leadNotification?.smtpPort ?? 0,
      secure: this.currentProject.leadNotification?.secure,
      fromEmail: this.currentProject.leadNotification?.fromEmail,
      subject: this.currentProject.leadNotification?.subject,
      text: mailText,
    });

    console.log(response);
  }

  async openTextEditor() {
    const dialogRef = this.dialog.open(EmailTextDialogComponent, {
      data: {
        name: this.currentProject.leadNotification.text,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
      maxWidth: '70vw',
      maxHeight: '80vh',
      minHeight: '50vh',
      panelClass: ['dialog-row-based'],
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.currentProject.leadNotification.text = result;
      }
    });
  }

  initLeadNotification() {
    if (this.currentProject.leadNotification) {
      return;
    }
    this.currentProject.leadNotification = {
      status: false,
      fromEmail: '',
      smtpHost: '',
      smtpPort: 0,
      subject: '',
      text: '',
      smtpPassword: '',
      smtpUsername: '',
      secure: false,
    };
  }

  toggleLeadNotification(active: boolean) {
    this.currentProject.leadNotification.status = active;
  }

  async saveProject() {
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: true,
        notificationType: NotificationType.SUCCESS,
        notificationMessage: 'Erfolgreich gespeichert',
        notificationDescription:
          'Die Änderungen wurden erfolgreich gespeichert.',
      },
    });
    await this.projectService.saveCurrentProject();

    await this.appService.delay(5000);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: false,
      },
    });
  }
}
