import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss'],
})
export class UserDashboardComponent implements OnInit {
  users: User[] = [];
  allUsers: User[] = [];
  userLast30Days: User[] = [];
  userInTrial: User[] = [];
  usersWithPayment: User[] = [];
  openUserIndex: number = -1;
  tabIndex: number = 0;
  showDropdown: boolean = false;
  selectedDropdown: string = '';
  searchString: string = '';
  constructor(private userService: UserService, private router: Router) {}

  async ngOnInit() {
    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.checkIfEmailIncludesAdminMailaddress(user);
      }
    });

    this.users = await this.userService.getUsers();
    // sort users by register registerDate descending
    this.users.sort((a, b) => {
      if (a.registerDate && b.registerDate) {
        return (
          b.registerDate.toDate().getTime() - a.registerDate.toDate().getTime()
        );
      } else {
        return 0;
      }
    });
    console.log(this.users);

    this.allUsers = this.users;
    this.userLast30Days = this.users.filter((user) => {
      if (user.registerDate) {
        const returnVal =
          user.registerDate.toDate() >
          new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

        return returnVal;
      } else {
        return false;
      }
    });
    this.userInTrial = this.users.filter(
      (user) => user.tier === 'trial' || !user.tier
    );
    this.usersWithPayment = this.users.filter(
      (user) => user.tier !== 'trial' && user.tier
    );
  }

  // check if user email address contains one of the specific strings  to identify admin users
  checkIfEmailIncludesAdminMailaddress(user: User) {
    if (
      user.email.includes('@zewotherm.de') ||
      user.email.includes('@zw-systems.de') ||
      user.email.includes('@klicklead.de') ||
      user.email.includes('eugen.gette@web.de') ||
      user.email.includes('meilon.de') ||
      user.email.includes('dolon.de')
    ) {
    } else {
      this.router.navigate(['/funnels']);
    }
  }

  openUser(index: number) {
    if (this.openUserIndex === index) {
      this.openUserIndex = -1;
    } else {
      this.openUserIndex = index;
    }
    this.tabIndex = 0;
  }

  // filter users by filter and by search string
  toggleDropdown(filter: string) {
    this.selectedDropdown = filter;
    if (filter !== '') {
      this.searchUser();
      this.filterUser();
    } else {
      this.users = this.allUsers;
      this.searchString = '';
    }
    this.showDropdown = !this.showDropdown;
  }

  filterUser() {
    this.users = this.users.filter((user) => {
      if (user.tier) {
        return user.tier.includes(this.selectedDropdown);
      }
      return false;
    });
  }

  searchUser() {
    this.users = this.allUsers.filter(
      (user) =>
        user.displayName
          .toLocaleLowerCase()
          .includes(this.searchString.toLocaleLowerCase()) ||
        user.email
          .toLocaleLowerCase()
          .includes(this.searchString.toLocaleLowerCase()) ||
        user.id.includes(this.searchString) ||
        user.funnelIds.includes(this.searchString) ||
        this.checkIfTierIsEnabled(user, this.searchString)
    );
  }

  checkIfTierIsEnabled(user: User, searchString: string) {
    if (!user.tier) {
      user.tier = 'trial';
    }
    return user.tier.includes(searchString);
  }
}
