<div class="h-full flex flex-col">
  <header class="w-full border-kl-grey">
    <div class="relative z-10 flex-shrink-0 h-14 bg-white shadow-sm flex">
      <div class="flex flex-col justify-center items-center ml-6 mr-4 p-3">
        <img class="h-8 w-auto" src="./assets/kl.png" alt="Workflow" />
      </div>
      <button
        type="button"
        class="border-r border-gray-200 px-4 text-kl-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
      >
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: outline/menu-alt-2 -->
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>
      <div class="flex-1 flex justify-between px-4 sm:px-6">
        <div class="flex w-full justify-between items-center"></div>
        <div class="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
          <!-- Profile dropdown -->
          <div class="relative flex-shrink-0">
            <div>
              <button
                type="button"
                class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span class="sr-only">Open user menu</span>
                <img
                  class="h-8 w-8 rounded-full"
                  src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                  alt=""
                />
              </button>
            </div>

            <!--
                  Dropdown menu, show/hide based on menu state.
  
                  Entering: "transition ease-out duration-100"
                    From: "transform opacity-0 scale-95"
                    To: "transform opacity-100 scale-100"
                  Leaving: "transition ease-in duration-75"
                    From: "transform opacity-100 scale-100"
                    To: "transform opacity-0 scale-95"
                -->
            <div
              class="opacity-0 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
            >
              <!-- Active: "bg-gray-200", Not Active: "" -->
              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-0"
                >Your Profile</a
              >

              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                >Sign out</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="flex flex-col">
    <div class="md:flex md:items-center md:justify-between p-10">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-kl-grey sm:text-3xl sm:truncate"
        >
          Meine Funnels
        </h2>
        <div class="flex gap-10 mt-6">
          <span
            class="w-1/4 cursor-pointer inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
          >
            <input
              type="text"
              placeholder="Suche"
              class="bg-transparent border-transparent focus:border-transparent focus:ring-0 border-none w-full"
            />
            <i class="ml-2 bi bi-search"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="w-full flex gap-10 px-10 py-1">
      <!-- component -->
      <div
        (click)="openTemplate()"
        class="w-1/5 bg-kl-purple hover:bg-kl-grey shadow-md rounded-md px-6 py-8 my-6 cursor-pointer"
      >
        <div class="flex flex-col justify-center items-center h-full">
          <div class="block text-white hover:underline">
            <i class="bi bi-plus-circle text-4xl"></i>
          </div>
          <h3 class="mt-4 text-xl text-white font-bold">Funnel erstellen</h3>
        </div>
      </div>
      <div
        class="w-1/5 bg-white shadow-md rounded-md px-6 py-8 my-6 cursor-pointer"
      >
        <div class="sm:flex sm:justify-between">
          <div class="flex items-start w-full">
            <div class="flex flex-col items-center w-full">
              <img src="./assets/preview.png" class="w-1/2 h-auto" />
              <div class="flex justify-between items-center w-full">
                <h3 class="text-2xl text-kl-grey font-bold">PV-Funnel</h3>
                <a class="block text-kl-purple hover:underline" href="#"
                  ><i class="bi bi-three-dots text-xl"></i
                ></a>
              </div>
              <div class="flex justify-between w-full mt-1">
                <span class="text-gray-400 text-xs"
                  >erstellt am 21.01.2022</span
                >
                <span class="text-gray-400 text-xs"
                  >aktualisiert am 21.01.2022</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center mt-6">
          <div>
            <h4 class="text-gray-600 text-sm">Aufrufe</h4>
            <span class="mt-2 text-xl font-medium text-kl-grey">127274</span>
          </div>
          <div>
            <h4 class="text-gray-600 text-sm">Leads</h4>
            <span class="mt-2 text-xl font-medium text-kl-grey">12171</span>
          </div>
          <div>
            <h4 class="text-gray-600 text-sm">Conversion</h4>
            <span class="mt-2 text-xl font-medium text-kl-grey">35%</span>
          </div>
        </div>
        <div class="flex justify-end">
          <a class="block mt-6 text-kl-purple hover:underline" href="#"
            ><i class="bi bi-arrow-right-circle-fill text-4xl"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
