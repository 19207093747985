<div class="flex flex-col h-full justify-between">
  <div class="flex flex-col space-y-6">
    <h1><i class="bi bi-lock-fill text-3xl mr-4"></i> {{ data.header }}</h1>
    <div class="flex flex-col justify-center items-center">
      <p>{{ data.description }}</p>
    </div>
    <div class="flex justify-center">
      <button
        [mat-dialog-close]="true"
        cdkFocusInitial
        class="text-white bg-kl-purple hover:bg-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3"
      >
        {{ data.button ? data.button : "Verstanden" }}
      </button>
    </div>
  </div>
</div>
