import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {
  @Input() title: string;
  @Input() type: string = 'text';
  @Input() value: any;
  @Input() placeholder: string = '';
  @Output() valueChange = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  changed(event: any) {
    this.value = event.target.value;
    this.valueChange.emit(this.value);
  }
}
