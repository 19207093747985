export enum BlockType {
  BUTTON = 'button',
  HEADER = 'header',
  PICTURE = 'picture',
  QUIZ = 'quiz',
  FORM = 'form',
  TEXTFIELD = 'textfield',
  FAKELOADER = 'fakeloader',
  SELECTION = 'selection',
  SELECTIONQUIZ = 'selectionquiz',
  VIDEO = 'video',
  SOCIALMEDIA = 'socialmedia',
  BULLETPOINTS = 'bulletpoints',
  UPLOAD = 'upload',
  ZW_WESTNETZ = 'zw_westnetz',
}
