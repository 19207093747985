import { Component, Input, OnInit } from '@angular/core';
import { Block } from 'src/app/models/block.model';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  @Input() block: Block;
  @Input() index: number;
  @Input() mobileView: boolean;

  constructor() {}

  ngOnInit(): void {}
}
