import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationType } from 'src/app/enums/notification-type';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { ProjectService } from 'src/app/services/project.service';
import { TierService } from 'src/app/services/tier.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-settings-integrations',
  templateUrl: './settings-integrations.component.html',
  styleUrls: ['./settings-integrations.component.scss'],
})
export class SettingsIntegrationsComponent implements OnInit {
  currentProject: Project | null = null;
  user: User;
  webhookEnabled: boolean = false;
  constructor(
    private projectService: ProjectService,
    private appService: AppService,
    private tierService: TierService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
      }
    });

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
        this.getWebhookEnabledRights();
      }
    });
  }

  getWebhookEnabledRights() {
    if (this.user.tier) {
      this.webhookEnabled = this.tierService.getRights(
        'webhook',
        this.user.tier!
      );
      console.log(this.webhookEnabled);
    }
  }

  openPaymentPage() {
    this.router.navigateByUrl('/user-settings?payment=true');
  }

  async saveProject() {
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.appService.showNotification(
      'Erfolgreich gespeichert',
      'Die Änderungen wurden erfolgreich gespeichert.',
      NotificationType.SUCCESS,
      3000
    );

    await this.projectService.saveCurrentProject();
  }
}
