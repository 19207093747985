import {
  CdkDragDrop,
  copyArrayItem,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { starterBlock } from 'src/app/blocks/starterBlock';
import { Block } from 'src/app/models/block.model';
import { Page, Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';
import { BlockService } from 'src/app/services/block.service';
import { LanguageService } from 'src/app/services/lang.service';
import { ProjectService } from 'src/app/services/project.service';
import { uuidv4 } from 'src/app/services/uuid';
import { DialogModalComponent } from '../../modals/dialog-modal/dialog-modal.component';
import { TextModalComponent } from '../../modals/text-modal/text-modal.component';

@Component({
  selector: 'app-pages-sidebar',
  templateUrl: './pages-sidebar.component.html',
  styleUrls: ['./pages-sidebar.component.scss'],
})
export class PagesSidebarComponent implements OnInit, OnDestroy {
  currentProject$: Subscription;
  state$: Subscription;

  currentProject: Project;
  leftTabIndex: number = 0;

  currentPageIndex: number = 0;
  selectedElement: any;
  html: any;
  rightTabIndex: number;
  showPageBlocks: boolean = false;

  pageDropIds: string[] = [];

  projectRedoArray: Project[] = [];
  projectUndoArray: Project[] = [];
  projectCurrentArray: Project[] = [];
  initialUndo: boolean = true;
  initialProject: Project;
  initialSet: boolean = false;

  showUndo: boolean = false;
  showRedo: boolean = false;
  constructor(
    private projectService: ProjectService,
    public dialog: MatDialog,
    private appService: AppService,
    private cdRef: ChangeDetectorRef,
    private langService: LanguageService,
    private blockService: BlockService
  ) {
    this.currentProject$ = this.projectService.currentProjectSubject.subscribe(
      (project: Project) => {
        if (project) {
          this.currentProject = project;
          this.initCurrentProject();

          this.currentProject.pages.forEach((page: Page) => {
            this.pageDropIds.push(page.id);
          });
        }
      }
    );

    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
       /*  this.selectedElement = state.editor.selectedElement;
        this.html = state.editor.html;
        this.rightTabIndex = state.editor.rightTabIndex;
        this.currentPageIndex = state.editor.currentPageIndex;
        this.projectRedoArray = state.history.projectRedoArray;
        this.projectUndoArray = state.history.projectUndoArray;
        this.projectCurrentArray = state.history.projectCurrentArray;
        this.showUndo = state.history.showUndo;
        this.showRedo = state.history.showRedo;
        this.initialUndo = state.history.initialUndo; */
      }
    });
  }

  ngOnInit(): void {}

  initCurrentProject() {
    if (!this.initialSet) {
      this.initialProject = JSON.parse(JSON.stringify(this.currentProject));
      if (!this.initialProject) return;
      if (this.projectUndoArray.length == 0) {
        this.projectUndoArray.push(this.initialProject);
      }
      this.initialSet = true;
    }
  }

  selectPage(index: number) {
    this.html = this.currentProject.pages[index].blocks;
    this.currentPageIndex = index;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentPageIndex: index,
          selectedElement: undefined,
          currentElementIndex: null,
          html: this.html,
          rightTabIndex: 0,
        },
      },
      false
    );
  }

  setHistory() {
    this.projectRedoArray = [];
    this.showRedo = true;

    if (this.projectUndoArray.length == 0) {
      this.projectUndoArray.push(this.initialProject);
    }

    if (this.projectCurrentArray.length !== 0) {
      const cur_pro_pop = JSON.parse(
        JSON.stringify(this.projectCurrentArray.pop())
      );
      if (!cur_pro_pop) return;
      this.projectUndoArray.push(cur_pro_pop);
    } else {
      const cur_pro_pop = JSON.parse(JSON.stringify(this.currentProject));
      if (!cur_pro_pop) return;

      this.projectUndoArray.push(cur_pro_pop);
    }

    const cur_pro = JSON.parse(JSON.stringify(this.currentProject));
    let test = JSON.parse(JSON.stringify(cur_pro));
    this.projectCurrentArray.push(test);
    if (this.projectUndoArray.length == 0) {
      this.showUndo = false;
    } else {
      this.showUndo = true;
    }
    // }

    this.initialUndo = true;

    this.projectService.currentProjectSubject.next(this.projectCurrentArray[0]);
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          html: this.projectService.currentProjectSubject.getValue().pages[
            this.currentPageIndex
          ].blocks,
        },
       /*  history: {
          ...this.appService.state.history,
          projectRedoArray: this.projectRedoArray,
          projectUndoArray: this.projectUndoArray,
          projectCurrentArray: this.projectCurrentArray,
          showUndo: this.showUndo,
          showRedo: this.showRedo,
          initialUndo: this.initialUndo,
        }, */
      },
      true
    );
  }

  togglePageBlock() {
    this.showPageBlocks = !this.showPageBlocks;
  }

  getBlockName(type: string) {
    return this.blockService.getBlockName(type);
  }

  getBlockIcon(icon: string) {
    return this.blockService.getBlockIcon(icon);
  }

  editPageName(index: any) {
    let editPage = this.currentProject.pages[index];
    const dialogRef = this.dialog.open(TextModalComponent, {
      width: '350px',
      data: { name: editPage.name },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined') {
        editPage.name = result;
        this.currentProject.pages[index] = editPage;
        this.setHistory();
        this.projectService.currentProjectSubject.next(this.currentProject);

        //this.projectService.saveCurrentProject();
      }
    });
  }
  dropElement(event: CdkDragDrop<any[]>, pageIndex: number) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    let index = this.currentProject.pages.findIndex((page: Page) => {
      return page.id === this.currentProject.pages[pageIndex].id;
    });

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentPageIndex: index,
        },
      },
      false
    );
    this.projectService.currentProjectSubject.next(this.currentProject);
    this.setHistory();

    //this.projectService.saveCurrentProject();
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.currentProject.pages,
      event.previousIndex,
      event.currentIndex
    );

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentPageIndex: event.currentIndex,
        },
      },
      false
    );
    this.projectService.currentProjectSubject.next(this.currentProject);
    this.setHistory();
    //this.projectService.saveCurrentProject();
  }

  movePageDown(page: Page) {
    let index = this.currentProject.pages.findIndex((e) => e.id == page.id);
    if (index !== -1 && index < this.currentProject.pages.length - 1) {
      let el = this.currentProject.pages[index];
      this.currentProject.pages[index] = this.currentProject.pages[index + 1];
      this.currentProject.pages[index + 1] = el;
      this.projectService.currentProjectSubject.next(this.currentProject);
      this.setHistory();

      //      this.projectService.saveCurrentProject();
    }
  }

  movePageUp(page: Page) {
    let index = this.currentProject.pages.findIndex((e) => e.id == page.id);
    if (index > 0) {
      let el = this.currentProject.pages[index];
      this.currentProject.pages[index] = this.currentProject.pages[index - 1];
      this.currentProject.pages[index - 1] = el;
      this.projectService.currentProjectSubject.next(this.currentProject);
      this.setHistory();

      //this.projectService.saveCurrentProject();
    }
  }

  copyPage(index: any) {
    let copyPage: Page = JSON.parse(
      JSON.stringify(this.currentProject.pages[index])
    );
    copyPage.id = uuidv4();
    copyPage.name = 'Kopie von ' + copyPage.name;
    this.currentProject.pages.splice(index + 1, 0, copyPage);
    this.projectService.currentProjectSubject.next(this.currentProject);
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentPageIndex: index + 1,
          selectedElement: null,
          html: this.currentProject.pages[this.currentProject.pages.length - 1]
            .blocks,
        },
      },
      true
    );
    this.setHistory();
  }

  deletePage(index: any) {
    if (this.currentProject.pages.length > 1) {
      const dialogRef = this.dialog.open(DialogModalComponent, {
        width: '350px',
        data: {
          buttonText: 'Entfernen',
          text: 'Durch das Entfernen von Seiten, können bereits erfasste Leads und Einträge gelöscht werden.',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && this.currentProject) {
          this.currentProject.pages.splice(index, 1);
          index = index === 0 ? 0 : index - 1;
          this.selectPage(index);
          this.projectService.currentProjectSubject.next(this.currentProject);
          this.setHistory();

          //this.projectService.saveCurrentProject();
        }
      });
    } else {
      this.setHistory();

      this.html = [];
      //this.projectService.saveCurrentProject();
    }
  }

  addPage() {
    let page: Page = {
      id: uuidv4(),
      blocks: JSON.parse(JSON.stringify(starterBlock)),
      name: 'Seite ' + (this.currentProject.pages.length + 1),
      style: { containerBackgroundColor: '#FFFFFF' },
    };
    this.currentProject.pages.push(page);
    this.projectService.currentProjectSubject.next(this.currentProject);
    this.currentPageIndex = this.currentProject.pages.length - 1;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentPageIndex: this.currentPageIndex,
          selectedElement: null,
          rightTabIndex: 0,
          html: this.currentProject.pages[this.currentProject.pages.length - 1]
            .blocks,
        },
      },
      true
    );

    this.setHeader();

    this.setHistory();
  }

  setHeader() {
    if (this.currentProject.pages[0].blocks[0].isHeader) {
      let element: Block = this.currentProject.pages[0].blocks[0];
      this.html = [
        element,
        ...this.currentProject.pages[this.currentProject.pages.length - 1]
          .blocks,
      ];
      this.currentProject.pages[this.currentProject.pages.length - 1].blocks =
        this.html;
      this.projectService.currentProjectSubject.next(this.currentProject);
    }

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,

          html: this.html,
        },
      },
      true
    );
  }

  setLeftTabIndex(index: number) {
    this.leftTabIndex = index;
  }

  ngOnDestroy() {}
}
