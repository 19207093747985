<div
  class="flex justify-start items-center cursor-pointer mb-2"
  (click)="goBack()"
>
  <i class="bi bi-chevron-left cursor-pointer"></i>
  <p
    class="ml-2 text-sm font-medium text-kl-grey hover:text-gray-500 truncate"
    i18n="@@funnelSidebarDetailQuizItem_headline_label"
  >
    Auswahl
  </p>
</div>

<div class="border-b border-gray-200">
  <nav class="-mb-px flex" aria-label="Tabs">
    <a
      (click)="setTabIndex(0)"
      class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
      [ngClass]="{
        'border-kl-purple text-kl-purple': tabIndex === 0,
        'text-gray-500 hover:text-gray-700 hover:border-gray-300':
          tabIndex !== 0
      }"
      i18n="@@funnelSidebarDetailQuizItem_edit_label"
      >Bearbeiten
    </a>
    <a
      *ngIf="answer.type === 'picture' && answerType === 'answer'"
      (click)="setTabIndex(1)"
      class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
      [ngClass]="{
        'border-kl-purple text-kl-purple': tabIndex === 1,
        'text-gray-500 hover:text-gray-700 hover:border-gray-300':
          tabIndex !== 1
      }"
      i18n="@@funnelSidebarDetailQuizItem_pictures_label"
      >Bilder
    </a>
    <a
      *ngIf="answer.type === 'picture' && answerType === 'answer'"
      (click)="setTabIndex(2)"
      class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
      [ngClass]="{
        'border-kl-purple text-kl-purple': tabIndex === 2,
        'text-gray-500 hover:text-gray-700 hover:border-gray-300':
          tabIndex !== 2
      }"
      i18n="@@funnelSidebarDetailQuizItem_unsplash_label"
      >Bilddatenbank
    </a>
    <a
      *ngIf="answer.type === 'icon' && answerType === 'answer'"
      (click)="setTabIndex(3)"
      class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
      [ngClass]="{
        'border-kl-purple text-kl-purple': tabIndex === 3,
        'text-gray-500 hover:text-gray-700 hover:border-gray-300':
          tabIndex !== 3
      }"
      i18n="@@funnelSidebarDetailQuizItem_icon_label"
      >Iconauswahl
    </a>
    <a
      (click)="setTabIndex(4)"
      class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
      [ngClass]="{
        'border-kl-purple text-kl-purple': tabIndex === 4,
        'text-gray-500 hover:text-gray-700 hover:border-gray-300':
          tabIndex !== 4
      }"
      i18n="@@funnelSidebarDetailQuizItem_design_label"
    >
      Design
    </a>
  </nav>
</div>

<div *ngIf="tabIndex === 0 && answerType === 'answer'">
  <div class="my-6" *ngIf="answer.answerPicture && answer.type === 'picture'">
    <label
      for="comment"
      class="block text-sm font-medium text-gray-700 mb-1"
      i18n="@@funnelSidebarDetailQuizItem_selectedImage_label"
      >Ausgewähltes Bild</label
    >
    <div
      class="relative flex flex-col items-center justify-between p-2 w-1/4 h-auto group cursor-pointer border rounded-lg"
    >
      <div
        class="absolute top-0 right-0 rounded-full flex justify-center items-center w-4 h-4 bg-red-400 text-white cursor-pointer"
        (click)="unsetImage()"
      >
        <i class="bi bi-x w-auto h-auto"></i>
      </div>
      <img
        [src]="answer.answerPicture"
        class="w-full h-auto object-contain rounded-lg"
      />
    </div>
  </div>

  <div class="my-6">
    <div>
      <label
        for="email"
        class="block text-sm font-medium text-gray-700"
        i18n="@@funnelSidebarDetailQuizItem_pictureOrIcon_label"
        >Bild oder Icon</label
      >
      <div class="mt-1">
        <span class="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-200 focus:z-10 focus:outline-none"
            [ngClass]="{
              'bg-gray-200': answer.type === 'picture',
              'bg-white': answer.type === 'icon'
            }"
            (click)="toggleType('picture')"
            i18n="@@funnelSidebarDetailQuizItem_picture_button"
          >
            Bild
          </button>
          <button
            [ngClass]="{
              'bg-gray-200': answer.type === 'icon',
              'bg-white': answer.type === 'picture'
            }"
            type="button"
            class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-200 focus:z-10 focus:outline-none"
            (click)="toggleType('icon')"
            i18n="@@funnelSidebarDetailQuizItem_icon_button"
          >
            Icon
          </button>
        </span>
      </div>
    </div>
  </div>

  <div class="my-6 flex justify-between">
    <div
      contenteditable="true"
      rows="4"
      [innerHTML]="answer.answerText"
      (blur)="updateItem($event)"
      class="shadow-sm border h-32 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md p-2"
    ></div>
  </div>

  <div class="my-6">
    <app-dropdown
      [headline]="'Klickaktion'"
      [items]="actions"
      [selectedItem]="getActionTypeString()"
      (onChange)="setAnswerAction($event)"
    ></app-dropdown>
  </div>

  <div class="my-6" *ngIf="answer.action?.type === 'connectedPage'">
    <app-dropdown
      [type]="'quizItem'"
      [headline]="'Seite auswählen'"
      [items]="pages"
      [selectedItem]="answer.action?.connectedPage"
      (onChange)="setConnectedPage($event)"
    ></app-dropdown>
  </div>

  <div class="my-6" *ngIf="answer.action?.type === 'link'">
    <app-text-input
      class="w-full mr-4"
      [value]="answer.action?.link"
      (valueChange)="updateLink($event)"
      [placeholder]="'Link eingeben'"
      [title]="'Ausgehender Link'"
    ></app-text-input>
  </div>

  <div class="flex justify-end my-6">
    <button
      (click)="removeItem()"
      type="button"
      class="my-6 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-800 focus:outline-none"
      i18n="@@funnelSidebarDetailQuizItem_delete_button"
    >
      Entfernen
    </button>
  </div>
</div>

<div class="my-6" *ngIf="tabIndex === 1 && answerType === 'answer'">
  <app-details-mediathek
    (onSelectImage)="setImage($event)"
  ></app-details-mediathek>
</div>

<div *ngIf="tabIndex === 2 && answerType === 'answer'">
  <app-details-unsplash
    (onSelectImage)="setImage($event)"
  ></app-details-unsplash>
</div>

<div *ngIf="tabIndex === 3 && answerType === 'answer'" class="overflow-auto">
  <app-details-icon-library
    (selectedIconEvent)="setIcon($event)"
  ></app-details-icon-library>
</div>

<div
  *ngIf="tabIndex === 4 && answer && answerType === 'answer'"
  style="height: calc(80vh)"
>
  <div class="w-full">
    <div class="my-6">
      <app-margin
        [selectedElement]="answer"
        [title]="'Abstände (innen)'"
        [type]="'answer'"
        (onMarginChange)="setMargin($event)"
      ></app-margin>
    </div>
  </div>

  <div class="my-6">
    <app-color-picker
      [title]="'Textfarbe'"
      [color]="answer.style?.fontColor ? answer.style?.fontColor : '#000000'"
      (onColorChange)="setFontColor($event)"
    ></app-color-picker>
  </div>

  <div class="my-6">
    <app-color-picker
      [title]="'Quiz Card Hintergrundfarbe'"
      [color]="
        answer.style?.quizButtonColor
          ? answer.style?.quizButtonColor
          : '#000000'
      "
      (onColorChange)="setQuizCardBackgroundColor($event)"
    ></app-color-picker>
  </div>

  <div class="my-6">
    <app-color-picker
      [title]="'Hintergrundfarbe'"
      [color]="
        answer.style?.backgroundColor
          ? answer.style?.backgroundColor
          : '#000000'
      "
      (onColorChange)="setBackgroundColor($event)"
    ></app-color-picker>
  </div>

  <div class="my-6">
    <app-range-slider
      [value]="answer.style?.fontSize ? answer.style?.fontSize : 16"
      [title]="'Schriftgröße'"
      (valueChange)="setFontSize($event)"
    ></app-range-slider>
  </div>

  <div class="my-6" *ngIf="answer.type === 'icon'">
    <app-range-slider
      [value]="answer.style?.iconSize ? answer.style?.iconSize : 16"
      [title]="'Icon Größe'"
      (valueChange)="setIconSize($event)"
    ></app-range-slider>
  </div>

  <div class="my-6">
    <button
      (click)="setStyleToAllAnswers()"
      type="button"
      class="my-6 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-kl-purple hover:bg-purple-800 focus:outline-none"
      i18n="@@funnelSidebarDetailQuizItem_applyToAllAnswers_button"
    >
      Auf alle Antworten anwenden
    </button>
  </div>
</div>

<div
  *ngIf="tabIndex === 4 && answer && answerType === 'button'"
  style="height: calc(80vh)"
>
  <div class="w-full">
    <div class="my-6">
      <app-margin
        [selectedElement]="answer"
        [title]="'Abstände (innen)'"
        [type]="'block'"
        (onMarginChange)="setMargin($event)"
      ></app-margin>
    </div>
  </div>

  <div class="my-6">
    <app-color-picker
      [title]="'Textfarbe'"
      [color]="answer.style?.fontColor ? answer.style?.fontColor : '#000000'"
      (onColorChange)="setFontColor($event)"
    ></app-color-picker>
  </div>

  <div class="my-6">
    <app-color-picker
      [title]="'Button Hintergrundfarbe'"
      [color]="
        answer.style?.quizButtonColor
          ? answer.style?.quizButtonColor
          : '#000000'
      "
      (onColorChange)="setQuizCardBackgroundColor($event)"
    ></app-color-picker>
  </div>

  <div class="my-6">
    <app-range-slider
      [value]="answer.style?.fontSize ? answer.style?.fontSize : 16"
      [title]="'Schriftgröße'"
      (valueChange)="setFontSize($event)"
    ></app-range-slider>
  </div>

  <div class="my-6" *ngIf="answer.type === 'icon'">
    <app-range-slider
      [value]="answer.style?.iconSize ? answer.style?.iconSize : 16"
      [title]="'Icon Größe'"
      (valueChange)="setIconSize($event)"
    ></app-range-slider>
  </div>
</div>

<div
  *ngIf="tabIndex === 0 && answerType === 'button'"
  style="height: calc(80vh)"
>
  <div class="my-6 flex justify-between">
    <div
      contenteditable="true"
      rows="4"
      [innerHTML]="answer.text"
      (blur)="updateItem($event)"
      class="shadow-sm border h-32 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md p-2"
    ></div>
  </div>

  <div class="my-6 z-50">
    <app-dropdown
      [headline]="'Klickaktion'"
      [items]="actions"
      [selectedItem]="getActionTypeString()"
      (onChange)="setAnswerAction($event)"
    ></app-dropdown>
  </div>

  <div class="my-6 z-50" *ngIf="answer.action?.type === 'connectedPage'">
    <app-dropdown
      [type]="'quizItem'"
      [headline]="'Seite auswählen'"
      [items]="pages"
      [selectedItem]="answer.action?.connectedPage"
      (onChange)="setConnectedPage($event)"
    ></app-dropdown>
  </div>

  <div class="my-6" *ngIf="answer.action?.type === 'link'">
    <app-text-input
      class="w-full mr-4"
      [value]="answer.action?.link"
      (valueChange)="updateLink($event)"
      [placeholder]="'Link eingeben'"
      [title]="'Ausgehender Link'"
    ></app-text-input>
  </div>
</div>
