<div class="relative h-screen flex overflow-auto">
  <div
    class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-white overflow-auto"
  >
    <div
      class="w-3/5 h-full hidden md:flex flex-auto items-center justify-center p-10 overflow-auto bg-kl-purple text-white bg-no-repeat bg-cover relative"
      style="background-image: url('./assets/funnels.png')"
    >
      <div
        class="absolute bg-gradient-to-b from-kl-purple to-kl-purple opacity-95 inset-0 z-0"
      ></div>
      <div class="w-full max-w-md z-10">
        <div class="sm:text-4xl xl:text-5xl font-bold leading-tight mb-6"></div>
        <div class="sm:text-sm xl:text-md text-gray-200 font-normal"></div>
      </div>
      <!---remove custom style-->
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <div
      class="overflow-auto md:flex md:items-center md:justify-center md:h-full w-full md:w-2/5 p-8 md:p-10 lg:p-14 sm:rounded-lg md:rounded-none bg-white"
    >
      <div class="max-w-md w-full space-y-8">
        <div class="text-center">
          <div class="flex justify-center">
            <img src="./assets/klicklead-logo.svg" class="h-10 w-auto" />
          </div>
          <p
            class="text-3xl font-bold mt-12 text-kl-grey"
            i18n="@@passwordReset_passwordResetHeadline_label"
          >
            Passwort zurücksetzen
          </p>
        </div>

        <form class="mt-8 space-y-6" [formGroup]="form" (ngSubmit)="submit()">
          <input type="hidden" name="remember" value="true" />
          <div class="relative">
            <div class="absolute right-3 mt-8">
              <svg
                *ngIf="isEmailValid"
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>

              <i
                *ngIf="!isEmailValid && isEmailValid !== null"
                class="bi bi-x-circle text-red-500"
              ></i>
            </div>
            <label
              i18n="@@passwordReset_email_label"
              class="ml-3 text-sm font-bold text-gray-700 tracking-wide"
              >E-Mail</label
            >
            <input
              class="w-full text-base px-4 py-2 border border-gray-300 focus:outline-none rounded-lg focus:border-kl-purple"
              type="email"
              (blur)="checkIsEmailValid()"
              placeholder="mail@gmail.com"
              formControlName="email"
            />
          </div>

          <button
            i18n="@@passwordReset_passwordReset_button"
            type="submit"
            class="w-full flex justify-center bg-kl-purple hover:bg-purple-800 text-white p-4 rounded-lg tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in"
          >
            Passwort zurücksetzen
          </button>
          <p
            *ngIf="errors"
            class="mt-4 text-center rounded-lg border border-red-400 text-black p-2"
          >
            {{ errorText }}
          </p>
          <p
            *ngIf="success"
            class="mt-4 text-center rounded-lg border border-green-400 text-black p-2"
          >
            {{ successText }}
          </p>
        </form>

        <p
          class="flex flex-col items-center justify-center mt-10 text-center text-md text-gray-500"
        >
          <span i18n="@@passwordReset_noAccountYet_label"
            >Du hast schon ein Account?</span
          >
          <a
            i18n="@@passwordReset_navigateToLogin_link"
            routerLink="/login"
            class="text-kl-purple hover:text-purple-800 no-underline hover:underline cursor-pointer transition ease-in duration-300"
            >Zum Login</a
          >
        </p>
      </div>
    </div>
  </div>
</div>
