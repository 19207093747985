import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FolderImage, MediaImage } from '../models/media-image.model';

@Injectable()
export class MediaLibraryService {
  db: any;

  constructor(
    private firestore: AngularFirestore,
    private afStorage: AngularFireStorage
  ) {
    this.db = this.firestore.firestore;
  }

  async getImages(projectId: string) {
    const snapshot = await this.db
      .collection('media')
      .where('projectId', '==', projectId)
      .get();
    let images: MediaImage[] = [];
    snapshot.forEach((as: any) => {
      images.push(as.data());
    });
    return images;
  }

  // where parentId is null
  async getImagesOfUser(userId: string) {
    const snapshot = await this.db
      .collection('media')
      .where('userId', '==', userId)
      .where('parentId', '==', '')
      .get();
    let images: MediaImage[] = [];
    snapshot.forEach((as: any) => {
      images.push(as.data());
    });
    console.log(images);
    images = images.filter((image: any) => image.parentId === '');

    return images;
  }

  async getImagesOfFolder(parentId: string) {
    const snapshot = await this.db
      .collection('media')
      .where('parentId', '==', parentId)
      .get();
    let images: FolderImage[] = [];
    snapshot.forEach((as: any) => {
      images.push(as.data());
    });
    return images;
  }

  async getFolder(folderId: string) {
    return await this.db
      .collection('media')
      .doc(folderId)
      .get()
      .then((folder: any) => {
        return folder.data();
      });
  }

  async setImageOfFolder(image: FolderImage) {
    return this.db
      .collection('media')
      .doc(image.id)
      .set({ ...image });
  }

  async setImage(image: MediaImage) {
    return this.db
      .collection('media')
      .doc(image.id)
      .set({ ...image });
  }

  async deleteImage(imageId: string) {
    await this.db.collection('media').doc(imageId).delete();
  }

  async uploadImageStorage(userId: any, imageId: string, image: any) {
    return await this.afStorage.upload(
      '/media/' + userId + '/' + imageId,
      image
    );
  }

  async uploadFaviconStorage(projectId: any, imageId: string, image: any) {
    return await this.afStorage.upload(
      '/favicon/' + projectId + '/' + imageId,
      image
    );
  }

  async uploadBlockStorage(projectId: any, imageId: string, image: any) {
    return await this.afStorage.upload(
      '/upload-block/' + projectId + '/' + imageId,
      image
    );
  }

  deleteImageStorage(image: MediaImage) {
    this.afStorage.ref('/media/' + image.projectId + '/' + image.id).delete();
  }
}
