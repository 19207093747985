import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MediaImage } from 'src/app/models/media-image.model';
import { CustomDomain, DomainId, Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { DomainService } from 'src/app/services/domain.service';
import { MediaLibraryService } from 'src/app/services/media-library.service';
import { ProjectService } from 'src/app/services/project.service';
import { TierService } from 'src/app/services/tier.service';
import { UserService } from 'src/app/services/user.service';
import { uuidv4 } from 'src/app/services/uuid';
import { environment } from 'src/environments/environment';
import { DialogModalComponent } from '../dialog-modal/dialog-modal.component';

@Component({
  selector: 'app-add-domain-modal',
  templateUrl: './add-domain-modal.component.html',
  styleUrls: ['./add-domain-modal.component.scss'],
})
export class AddDomainModalComponent implements OnInit {
  currentProject$: Subscription;

  currentProject: Project;

  currentStep: number = 0;
  customDomain: string = '';
  domainId: string = '';

  domainType: string = '';
  showDomainAvailabilityError: boolean = false;
  showCustomDomainWrongURL: boolean = false;
  showCustomDomainAvailabilityError: boolean = false;
  domainIdEnabled: boolean = false;
  maxCustomDomains: number = 0;
  customDomainsOfUser: number = 0;
  user: User;
  constructor(
    public dialogRef: MatDialogRef<AddDomainModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private domainService: DomainService,
    private http: HttpClient,
    private tierService: TierService,
    private userService: UserService,
    private router: Router
  ) {
    this.currentProject$ = this.projectService.currentProjectSubject.subscribe(
      (project: Project) => {
        if (project) {
          this.currentProject = project;
        }
      }
    );
    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
        this.getDomainIdEnabled();
        this.getCustomDomainRights();
      }
    });
    this.customDomainsOfUser = this.data.customDomainsOfUser;
  }

  ngOnInit() {}

  getDomainIdEnabled() {
    if (this.user.tier) {
      this.domainIdEnabled = this.tierService.getRights(
        'hostingIdEnabled',
        this.user.tier!
      );
      console.log(this.domainIdEnabled);
    }
  }

  

  getCustomDomainRights() {
    if (this.user.tier) {
      this.maxCustomDomains = this.tierService.getRights(
        'customDomain',
        this.user.tier!
      );
      console.log(this.maxCustomDomains);
    }
  }

  openPaymentPage() {
    this.router.navigateByUrl('/user-settings?payment=true');
  }

  close(): void {
    this.dialogRef.close();
  }

  previousStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  async nextStep() {
    switch (this.currentStep) {
      case 0:
        this.currentStep++;
        break;
      case 1:
        if (this.domainType === 'id') {
          const isAvailable: boolean = await this.checkDomainIdAvailability();

          if (isAvailable) {
            this.addDomainId();
          } else {
            return;
          }
        } else {
          const isUrl = this.isValidUrl(this.customDomain);
          if (!isUrl) {
            this.showCustomDomainWrongURL = true;
            return;
          }
          const isAvailable: any = await this.checkCustomDomainAvailability();

          if (isAvailable) {
            await this.addCustomDomain();
            await this.addVercelCustomDomain();
          } else {
            return;
          }
        }
        this.currentStep++;
        break;
      case 2:
        this.currentStep++;
        break;
      case 3:
        break;

      default:
        break;
    }
  }

  isValidUrl(url: string) {
    try {
      new URL(url);
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  }

  async addDomainId() {
    this.currentProject.urlShort = this.domainId.toLowerCase();
    this.projectService.currentProjectSubject.next(
      this.projectService.currentProject
    );

    await this.projectService.saveCurrentProject(this.currentProject);

    const domain: DomainId = {
      id: this.domainId.toLowerCase(),
      createdAt: new Date(),
      projectId: this.currentProject.id,
      userId: this.user.id,
    };
    await this.domainService.setDomainId(domain);
  }

  async checkCustomDomainAvailability() {
    this.showCustomDomainAvailabilityError = false;
    const customDomainURL = new URL(this.customDomain.toLowerCase());
    const domain: CustomDomain = await this.domainService.getCustomDomain(
      customDomainURL.host
    );

    if (!domain) {
      return true;
    }

    this.showCustomDomainAvailabilityError = true;
    return false;
  }

  async checkDomainIdAvailability() {
    this.showDomainAvailabilityError = false;

    const domain: DomainId = await this.domainService.getDomainId(
      this.domainId.toLowerCase()
    );

    if (!domain) {
      return true;
    }

    this.showDomainAvailabilityError = true;
    return false;
  }

  async addCustomDomain() {
    const customDomainURL = new URL(this.customDomain.toLowerCase());

    const domain: CustomDomain = {
      id: customDomainURL.host,
      createdAt: new Date(),
      domain: this.customDomain.toLowerCase(),
      projectId: this.currentProject.id,
      userId: this.user.id,
    };
    await this.domainService.setCustomDomain(domain);
  }

  async addVercelCustomDomain() {
    const customDomainURL = new URL(this.customDomain.toLowerCase());

    let link =
      'https://api.vercel.com/v8/projects/prj_MudunYJ1NG3wSRRSpdIJ7mHsjDN0/domains?teamId=team_b5hpvvNtFK82xKc6Rv6HkrqE';
    let myData = JSON.stringify({
      name: customDomainURL.host,
    });
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      Authorization: `Bearer ${environment.vercelToken}`,
    });
    return this.http.post(link, myData, { headers: headers }).toPromise();
  }

  checkVisibility() {
    switch (this.currentStep) {
      case 0:
        if (this.domainType === '') {
          return false;
        }
        break;
      case 1:
        if (this.domainType === 'id') {
          if (this.domainId === '') {
            return false;
          }
        } else {
          if (this.customDomain === '') {
            return false;
          }
        }
        break;
      case 2:
        break;
      case 3:
        break;

      default:
        break;
    }
    return true;
  }
}
