import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogModalComponent } from 'src/app/components/modals/dialog-modal/dialog-modal.component';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  loadStripe,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { StripeDataService } from 'src/app/services/stripe-data.service';
import { AppService } from 'src/app/services/app.service';
import { Project } from 'src/app/models/project.model';
import { isUserInTrialAllowed } from 'src/app/trialCheck';
import { MailMarketingService } from 'src/app/services/mail-marketing.service';
@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  user: User;
  activeTab: number = 0;
  showPricingTable: boolean = false;
  showPaymentInfo: boolean = false;
  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private appService: AppService,
    private mailMarketingService: MailMarketingService
  ) {
    this.projectService.currentProject = <Project>{};

    this.userService.userDataSubject.subscribe((user) => {
      if (user !== null) {
        this.user = user;
        this.showPaymentInfo = !isUserInTrialAllowed(this.user);
        if (this.showPaymentInfo) {
          this.mailMarketingService
            .untagSubscriber(this.user.email, 8632095)
            .then(() => {
              console.log('untagged');
              this.mailMarketingService
                .tagSubscriber(this.user.email, 8636705)
                .then(() => {
                  console.log('tagged');
                });
            });
        }
      }
    });

    this.route.queryParams.subscribe((params) => {
      params['payment'] ? (this.activeTab = 1) : (this.activeTab = 0);
    });
  }

  ngOnInit(): void {}

  setActiveTab(index: number) {
    this.activeTab = index;
  }

  async saveUser() {
    const save = await this.userService.setUser(this.user, true);
    this.snackBar.open('Ihre Daten wurden gespeichert', 'Okay', {
      duration: 5000,
    });
  }

  onClosePricingTable() {
    this.showPricingTable = false;
  }

  async deleteAccount() {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: {
        buttonText: 'Entfernen',
        text: 'Wollen Sie Ihr Konto und all Ihre Funnels wirklich entfernen?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.userService.deleteUser(this.user.id);
        for (let projectId of this.user.funnelIds) {
          const isSharedFunnel: User[] =
            await this.userService.getUsersByFunnelId(projectId);

          if (isSharedFunnel.length === 1) {
            await this.projectService.deleteProject(projectId);
          }
        }
        await this.mailMarketingService.deleteSubscriber(this.user.email);
        await this.authService.deleteUser();
        this.router.navigate(['/']);
      }
    });
  }
}
