<div
  class="flex justify-start items-center cursor-pointer mb-2"
  (click)="goBack()"
>
  <i class="bi bi-chevron-left cursor-pointer"></i>
  <p
    class="ml-2 text-sm font-medium text-kl-grey hover:text-gray-500 truncate"
    i18n="@@funnelSidebarDetailFormItem_headline_label"
  >
    Formular
  </p>
</div>

<div class="border-b border-gray-200">
  <nav class="-mb-px flex" aria-label="Tabs">
    <a
      (click)="setTabIndex(0)"
      class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
      [ngClass]="{
        'border-kl-purple text-kl-purple': tabIndex === 0,
        'text-gray-500 hover:text-gray-700 hover:border-gray-300':
          tabIndex !== 0
      }"
      i18n="@@funnelSidebarDetailFormItem_edit_label"
      >Bearbeiten
    </a>

    <a
      *ngIf="inputField?.isIcon"
      (click)="setTabIndex(1)"
      class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
      [ngClass]="{
        'border-kl-purple text-kl-purple': tabIndex === 1,
        'text-gray-500 hover:text-gray-700 hover:border-gray-300':
          tabIndex !== 1
      }"
      i18n="@@funnelSidebarDetailFormItem_icon_label"
      >Iconauswahl
    </a>
    <!--   <a
      (click)="setTabIndex(2)"
      class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
      [ngClass]="{
        'border-kl-purple text-kl-purple': tabIndex === 2,
        'text-gray-500 hover:text-gray-700 hover:border-gray-300':
          tabIndex !== 2
      }"
    >
      Design
    </a> -->
  </nav>
</div>

<div *ngIf="tabIndex === 0">
  <div class="my-6">
    <app-text-input
      [title]="'Text'"
      (valueChange)="setPlaceholder($event)"
      [value]="inputField?.text"
    ></app-text-input>
  </div>

  <div class="my-6" *ngIf="inputFieldType !== null">
    <app-text-input
      [title]="'Datenschutz URL'"
      (valueChange)="setPrivacyLink($event)"
      [value]="inputField?.link"
    ></app-text-input>
  </div>

  <div class="my-6" *ngIf="inputFieldType === null">
    <app-dropdown
      [headline]="'Typ'"
      [items]="inputFieldTypes"
      [selectedItem]="inputField.type"
      (onChange)="setInputFieldType($event)"
    ></app-dropdown>
  </div>

  <div class="my-6" *ngIf="inputFieldType === null">
    <app-toggle
      [title]="'Pflichfeld'"
      [isEnabled]="inputField.required ? true : false"
      (onToggle)="setInputFieldRequired($event)"
    ></app-toggle>
  </div>

  <div class="my-6" *ngIf="inputFieldType === null">
    <app-toggle
      [title]="'Icon'"
      [isEnabled]="inputField.isIcon ? true : false"
      (onToggle)="setInputFieldIsIcon($event)"
    ></app-toggle>
  </div>

  <div class="flex justify-end my-6" *ngIf="inputFieldType === null">
    <button
      (click)="removeItem()"
      type="button"
      class="my-6 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-800 focus:outline-none"
      i18n="@@funnelSidebarDetailFormItem_delete_label"
    >
      Entfernen
    </button>
  </div>
</div>

<div *ngIf="tabIndex === 1" class="overflow-auto">
  <app-details-icon-library
    (selectedIconEvent)="setIcon($event)"
  ></app-details-icon-library>
</div>
