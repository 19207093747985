<div class="flex flex-col h-full justify-between">
  <div class="flex flex-col space-y-6">
    <h1>{{ data.headline }}</h1>
    <div>
      {{ data.text }}
    </div>
    <div class="mt-4 flex justify-between">
      <button (click)="onNoClick()" class="text-kl-purple text-sm font-bold">
        Abbrechen
      </button>

      <button
        [mat-dialog-close]="true"
        cdkFocusInitial
        class="text-white bg-kl-purple hover:bg-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3"
      >
        {{ data.buttonText }}
      </button>
    </div>
  </div>
</div>
