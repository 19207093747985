import { User } from './models/user.model';

export const isUserInTrialAllowed = (user: User): boolean => {
  const trialInDays = 14;
  const endDate = addDaysToDate(user.registerDate.toDate(), trialInDays);
  const diff = differenceInDays(user.registerDate.toDate(), endDate);

  console.log(diff);
  if (checkIfCancelDateIsPassed(user)) {
    return false;
  }

  if (diff <= 1 && !user.stripeCustomerId) {
    return false;
  } else {
    return true;
  }
};

export const addDaysToDate = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const checkIfCancelDateIsPassed = (user: User): boolean => {
  if (!user.cancelAt) return false;
  const cancelDate = new Date(user.cancelAt * 1000);
  const now = new Date();
  if (cancelDate) {
    if (cancelDate < now) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const differenceInDays = (startDate: Date, endDate: Date) => {
  const diff = endDate.getTime() - startDate.getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24));
};
