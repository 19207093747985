import { Component, Input, OnInit } from '@angular/core';
import { Answer, BulletPoint, QuizBlock } from 'src/app/models/block.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-details-bulletpoints-items',
  templateUrl: './details-bulletpoints-items.component.html',
  styleUrls: ['./details-bulletpoints-items.component.scss'],
})
export class DetailsBulletpointsItemsComponent implements OnInit {
  @Input() selectedBulletpointItem: BulletPoint | null = null;
  selectedBulletpointItemIndex: number;
  @Input() items: BulletPoint[] = [];
  constructor(private appService: AppService) {}

  ngOnInit(): void {}

  openItem(bulletpoint: BulletPoint, index: number) {
    this.selectedBulletpointItem = bulletpoint;
    this.selectedBulletpointItemIndex = index;
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            selectedBulletpoint: bulletpoint,
          },
        },
      },
    });
  }

  addItem() {
    let bulletpoint: BulletPoint = {
      text: 'Bulletpoint ' + (this.items.length + 1),
      icon: 'bi bi-star-fill',
    };

    this.items.push(bulletpoint);
    this.appService.setShouldSave(true);
  }

  removeItem(index: number) {
    this.items.splice(index, 1);
    this.selectedBulletpointItem = null;
    this.appService.setShouldSave(true);
  }

  moveUp(index: number) {
    if (index > 0) {
      let item = this.items[index];
      this.items.splice(index, 1);
      this.items.splice(index - 1, 0, item);
    }
    this.appService.setShouldSave(true);
  }

  moveDown(index: number) {
    if (index < this.items.length - 1) {
      let item = this.items[index];
      this.items.splice(index, 1);
      this.items.splice(index + 1, 0, item);
    }
    this.appService.setShouldSave(true);
  }
}
