<div class="w-full flex justify-between gap-x-2 mb-6 items-center">
  <div class="flex">
    <p i18n="@@billing_billingHeadline_label" class="text-gray-500">
      Preispaket:
    </p>
    <p class="ml-7 font-bold text-black capitalize">
      {{ user.tier ? user.tier : "Trial" }}
    </p>
    <p
      *ngIf="user.tier !== 'trial'"
      class="ml-7 font-bold text-black capitalize"
    >
      ({{
        user.paymentInterval === "monthly"
          ? "monatliche Abrechnung"
          : "jährliche Abrechnung"
      }})
    </p>
  </div>
  <button
    i18n="@@billing_changePricingPlan_button"
    (click)="showPricingTable()"
    type="button"
    class="inline-flex items-center px-6 py-1.5 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-kl-purple hover:bg-purple-800 mr-12"
  >
    <i class="bi bi-arrow-up-circle-fill text-lg mr-2"></i> Paket ändern
  </button>
</div>

<div class="w-full flex justify-between gap-x-2 mb-6 items-center">
  <div class="flex">
    <p i18n="@@billing_billingHeadline_label" class="text-gray-500">
      Rechnungs- Adresse:
    </p>
    <input
      type="text"
      [(ngModel)]="user.receiptEmail"
      (blur)="updateUser()"
      placeholder="max@mustermann.de"
      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
    />
  </div>
</div>

<div class="w-full flex gap-x-2 my-12">
  <p class="text-gray-500" i18n="@@billing_invoices_label">Rechnungen:</p>
  <div class="flex flex-col w-full">
    <div
      *ngIf="loading"
      class="min-w-full bg-gray-200 animate-pulse h-96"
    ></div>
    <table
      class="min-w-full divide-y divide-gray-300"
      *ngIf="invoices.length > 0"
    >
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            i18n="@@billing_invoiceTableDate_label"
          >
            Datum
          </th>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            i18n="@@billing_invoiceTableAmount_label"
          >
            Betrag
          </th>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            i18n="@@billing_invoiceTableStatus_label"
          >
            Status
          </th>
          <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr *ngFor="let invoice of invoices">
          <td
            class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
          >
            <a
              [href]="invoice.hosted_invoice_url"
              target="_blank"
              class="text-black text-sm"
            >
              {{ invoice.created * 1000 | date: "dd-MM-yyyy HH:mm" }}</a
            >
          </td>
          <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <a
              [href]="invoice.hosted_invoice_url"
              target="_blank"
              class="text-sm text-gray-500"
            >
              {{ invoice.total / 100 }} {{ invoice.currency | uppercase }}
            </a>
          </td>
          <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <a
              i18n="@@billing_invoiceTablePaidStatus_label"
              [href]="invoice.hosted_invoice_url"
              target="_blank"
              *ngIf="invoice.status === 'paid'"
              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
            >
              Bezahlt
            </a>
            <a
              i18n="@@billing_invoiceTableNotPaidStatus_label"
              [href]="invoice.hosted_invoice_url"
              target="_blank"
              *ngIf="invoice.status !== 'paid'"
              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
            >
              Nicht bezahlt
            </a>
          </td>
          <td
            class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
          >
            <a
              [href]="invoice.hosted_invoice_url"
              target="_blank"
              class="text-black text-sm"
            >
              <i class="bi bi-box-arrow-up-right cursor-pointer"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="w-full flex justify-between gap-x-2 mb-6 items-center">
  <div class="flex">
    <p class="text-gray-500" i18n="@@billing_cancelPlan_label">
      Paket kündigen:
    </p>
  </div>
  <button
    i18n="@@billing_cancelPlan_button"
    (click)="cancelSubscription()"
    type="button"
    class="inline-flex items-center px-6 py-1.5 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-800 mr-12"
  >
    kündigen
  </button>
</div>
