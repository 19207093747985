<div class="flex flex-col justify-between h-full p-4 bg-gray-100">
  <div class="flex flex-col h-full">
    <div class="flex items-center justify-between w-full">
      <h1 class="pl-4 text-2xl font-bold">Mediathek</h1>

      <div class="flex justify-end">
        <button (click)="newFolder()">
          <label
            class="text-kl-grey bg-white hover:bg-gray-200 border font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3 cursor-pointer flex items-center space-x-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-folder"
              viewBox="0 0 16 16"
            >
              <path
                d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"
              />
            </svg>
            <p>Ordner hinzufügen</p>
          </label>
        </button>
        <button>
          <label
            id="getFileLabel"
            for="getFile"
            class="text-white bg-kl-purple hover:bg-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3 cursor-pointer flex items-center space-x-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-card-image"
              viewBox="0 0 16 16"
            >
              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              <path
                d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"
              />
            </svg>
            <p>Bild hochladen</p>
          </label>

          <input
            type="file"
            id="getFile"
            multiple="multiple"
            class="hidden"
            (change)="uploadImage($event)"
            accept="image/*"
          />
        </button>
      </div>
    </div>

    <nav class="flex pt-4 pl-4" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-4">
        <li class="cursor-pointer" (click)="openRootFolder()">
          <div class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="flex-shrink-0 w-5 h-5 mr-4 text-gray-400"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>

            <svg
              *ngIf="folderStack.length > 0"
              class="flex-shrink-0 w-5 h-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </li>
        <li
          *ngFor="let folder of folderStack; let i = index; let isLast = last"
        >
          <div class="flex items-center">
            <a
              (click)="openFolder(folder, i)"
              class="mr-4 text-sm font-medium text-gray-500 cursor-pointer hover:text-gray-700"
              [ngClass]="{ underline: isLast }"
              >{{ folder.name }}</a
            >
            <svg
              *ngIf="!isLast"
              class="flex-shrink-0 w-5 h-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </li>
      </ol>
    </nav>

    <div
      class="flex flex-wrap h-full p-4 m-2 overflow-y-auto bg-white rounded-lg"
    >
      <div class="flex" *ngIf="loader">
        <div
          *ngFor="let skeleton of [1, 2, 3, 4, 5, 6]"
          class="flex flex-col items-center justify-center w-40 h-32 mx-4 my-2 shadow-lg cursor-pointer rounded-xl animate-pulse"
        ></div>
      </div>
      <div class="flex flex-wrap" *ngIf="!loader">
        <div
          (click)="folderBack()"
          *ngIf="currentFolder"
          class="flex items-center justify-center object-cover w-40 h-32 p-3 mr-4 border rounded-lg cursor-pointer hover:bg-slate-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="w-8 h-8 bi bi-arrow-90deg-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"
            />
          </svg>
        </div>

        <div
          cdkDropList
          [cdkDropListData]="images"
          (cdkDropListDropped)="drop($event)"
          class="relative flex flex-wrap gap-3"
          *ngIf="!loader"
          #dropList
        >
          <div
            *ngFor="let image of images; let i = index"
            cdkDrag
            [cdkDragData]="image"
            [attr.data-item-id]="image.id"
            class="relative flex flex-wrap w-40 h-32 border rounded-lg cursor-pointer group border-3 hover:bg-slate-100"
            [ngClass]="{
              'bg-slate-50': image.type === 'folder',
              'border-kl-purple': selectedImageIndex === i
            }"
            [cdkDragBoundary]="dropList"
          >
            <div
              class="flex flex-col items-center justify-center w-full h-full"
            >
              <div
                class="absolute flex items-center justify-center w-4 h-4 text-white bg-red-400 rounded-full cursor-pointer top-2 right-2"
                (click)="deleteImage(image)"
              >
                <i class="w-auto h-auto bi bi-x"></i>
              </div>
              <div
                *ngIf="image.parentId !== ''"
                class="absolute flex items-center justify-center w-4 h-4 rounded-full cursor-pointer bottom-2 right-2"
                (click)="moveFolderUp(image)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-folder-symlink transform scaleX(-1)"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m11.798 8.271-3.182 1.97c-.27.166-.616-.036-.616-.372V9.1s-2.571-.3-4 2.4c.571-4.8 3.143-4.8 4-4.8v-.769c0-.336.346-.538.616-.371l3.182 1.969c.27.166.27.576 0 .742"
                  />
                  <path
                    d="m.5 3 .04.87a2 2 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2m.694 2.09A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09l-.636 7a1 1 0 0 1-.996.91H2.826a1 1 0 0 1-.995-.91zM6.172 2a1 1 0 0 1 .707.293L7.586 3H2.19q-.362.002-.683.12L1.5 2.98a1 1 0 0 1 1-.98z"
                  />
                </svg>
              </div>
              <img
                *ngIf="image.type === 'image'"
                (click)="selectImage(i)"
                [src]="image.url"
                class="object-contain w-full h-full rounded-lg"
              />
              <div
                *ngIf="image.type === 'folder'"
                (click)="openFolder(image)"
                class="flex flex-col items-center justify-center w-full h-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="w-8 h-8 mb-2 text-gray-400 bi bi-folder"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"
                  />
                </svg>
                <p class="text-sm">{{ image.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex items-center justify-end my-2">
    <button (click)="onNoClick()" class="text-sm font-bold">abbrechen</button>

    <button
      [disabled]="selectedImageIndex === -1"
      (click)="returnSelection()"
      class="ml-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3"
      [ngClass]="{
        'bg-gray-300 text-gray-500 cursor-not-allowed':
          selectedImageIndex === -1,
        '      bg-kl-purple      text-white hover:bg-purple-600 ':
          selectedImageIndex !== -1
      }"
    >
      auswählen
    </button>
  </div>
</div>
