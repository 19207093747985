<!-- This example requires Tailwind CSS v2.0+ -->
<div class="h-full overflow-auto">
  <div>
    <div class="flex justify-between items-center px-12 mb-6">
      <h2
        class="text-2xl font-bold leading-7 text-kl-grey sm:text-3xl sm:truncate"
        i18n="@@funnelLeadsChats_headline_label"
      >
        Statistik
      </h2>
      <div class="flex gap-4 items-center">
        <div class="flex gap-2 items-center">
          <div>
            <div class="relative rounded-md shadow-sm">
              <input
                type="text"
                [matDatepicker]="pickerFrom"
                name="account-number"
                id="account-number"
                [(ngModel)]="fromDate"
                (click)="pickerFrom.open()"
                (dateChange)="setFilterDate($event, 'from')"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-6 sm:text-sm border-gray-300 rounded-md cursor-pointer"
                placeholder="00-00-0000"
              />
              <div
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
                <i class="bi bi-calendar"></i>
              </div>
            </div>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </div>
          <p>-</p>
          <div>
            <div class="relative rounded-md shadow-sm">
              <input
                type="text"
                name="account-number"
                id="account-number"
                [matDatepicker]="pickerTo"
                [(ngModel)]="toDate"
                [min]="fromDate"
                (dateChange)="setFilterDate($event, 'to')"
                (click)="pickerTo.open()"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-6 sm:text-sm border-gray-300 rounded-md cursor-pointer"
                placeholder="00-00-0000"
              />
              <div
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
                <i class="bi bi-calendar"></i>
              </div>
            </div>
            <mat-datepicker #pickerTo></mat-datepicker>
          </div>
        </div>
        <div class="relative">
          <button
            (click)="toggleMenu()"
            type="button"
            class="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
            id="menu-button"
            aria-expanded="false"
            aria-haspopup="true"
          >
            {{ currentMenuSelection }}
            <!-- Heroicon name: solid/chevron-down -->
            <svg
              class="ml-2 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>

          <div
            *ngIf="isMenuOpen"
            class="focus:outline-none z-50 absolute right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1"
            style="z-index: 9999"
          >
            <div class="py-1" role="none">
              <a
                *ngFor="let menuItem of menuItems"
                (click)="selectMenuItem(menuItem)"
                class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
                >{{ menuItem }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 px-12">
      <div
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute bg-indigo-500 rounded-md p-3">
            <i class="bi bi-bar-chart text-2xl text-white"></i>
          </div>
          <p
            class="ml-16 text-sm font-medium text-gray-500 truncate"
            i18n="@@funnelLeadsChats_allImpressions_label"
          >
            Alle Aufrufe
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ impressionCount }}
          </p>
          <p
            class="ml-2 flex items-baseline text-sm font-semibold text-green-600"
          >
            <!-- Heroicon name: solid/arrow-sm-up -->
            <!--  <svg
              class="self-center flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg> -->
            <!--  <span class="sr-only"> Increased by </span>
            122 -->
          </p>

          <!--           <p class="text-sm text-gray-400 ml-2">seit gestern</p>
 -->
          <!--   <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Alle anzeigen
              </a>
            </div>
          </div> -->
        </dd>
      </div>

      <div
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute bg-indigo-500 rounded-md p-3">
            <i class="bi bi-person-check text-white text-2xl"></i>
          </div>
          <p
            class="ml-16 text-sm font-medium text-gray-500 truncate"
            i18n="@@funnelLeadsChats_leads_label"
          >
            Leads
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ leadCount }}
          </p>
          <p
            class="ml-2 flex items-baseline text-sm font-semibold text-green-600"
          >
            <!-- Heroicon name: solid/arrow-sm-up -->
            <!--  <svg
              class="self-center flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg> -->
            <!-- <span class="sr-only"> Increased by </span>
            5.4% -->
          </p>
          <!--           <p class="text-sm text-gray-400 ml-2">seit gestern</p>
 -->
          <!--  <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a
                (click)="navigateToAllLeads()"
                class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
              >
                Alle anzeigen</a
              >
            </div>
          </div> -->
        </dd>
      </div>

      <div
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute bg-indigo-500 rounded-md p-3">
            <i class="bi bi-percent text-2xl text-white"></i>
          </div>
          <p
            class="ml-16 text-sm font-medium text-gray-500 truncate"
            i18n="@@funnelLeadsChats_conversionRate_label"
          >
            Conversion Rate
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">{{ convRate }}%</p>
          <p
            class="ml-2 flex items-baseline text-sm font-semibold text-red-600"
          >
            <!-- Heroicon name: solid/arrow-sm-down -->
            <!--   <svg
              class="self-center flex-shrink-0 h-5 w-5 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg> -->
            <!--  <span class="sr-only"> Decreased by </span>
            3.2% -->
          </p>
          <!--           <p class="text-sm text-gray-400 ml-2">seit gestern</p>
 -->
          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-6 sm:px-6">
            <div class="text-sm"></div>
          </div>
        </dd>
      </div>
    </dl>
  </div>
  <div class="flex flex-col mt-14 w-full px-12">
    <div
      class="flex flex-col bg-white rounded-t-lg items-center shadow-t-lg p-10 z-50"
      style="height: 600px"
    >
      <ngx-charts-bar-vertical
        style="height: 300px"
        *ngIf="statisticData.length > 0"
        [results]="statisticData"
        [xAxisLabel]="langService.getWording('funnelStatistics', 'pages')"
        [yAxisLabel]="langService.getWording('funnelStatistics', 'pageviews')"
        [legend]="false"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [xAxis]="true"
        [yAxis]="true"
        [gradient]="true"
      >
      </ngx-charts-bar-vertical>
    </div>
    <div
      class="flex flex-col bg-white rounded-b-lg items-center shadow-b-lg h-16 z-0"
    ></div>
    <div class="flex justify-end mt-6 py-4">
      <button
        (click)="resetStatistic()"
        class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
        i18n="@@funnelLeadsPartialEntries_loadMore_button"
      >
        Statistik löschen
      </button>
    </div>
  </div>
</div>
