<header class="w-full border-kl-grey" *ngIf="showHeader">
  <div
    class="relative z-10 flex justify-between w-full bg-white shadow-sm h-14"
  >
    <div class="flex">
      <div class="flex flex-col items-center justify-center p-3 ml-2 mr-4">
        <img
          class="w-auto h-5 cursor-pointer"
          src="./assets/klicklead-logo.svg"
          routerLink="/"
        />
      </div>
      <nav *ngIf="currentProject.id" class="flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div>
              <a
                routerLink="/"
                class="cursor-pointer text-kl-grey hover:text-gray-700"
              >
                <!-- Heroicon name: solid/home -->
                <svg
                  class="flex-shrink-0 w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  />
                </svg>
                <span class="sr-only">Home</span>
              </a>
            </div>
          </li>

          <li>
            <div class="flex items-center">
              <!-- Heroicon name: solid/chevron-right -->
              <svg
                class="flex-shrink-0 w-5 h-5 text-kl-grey"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
              <div
                (click)="renameFunnel()"
                class="ml-4 text-sm font-medium cursor-pointer text-kl-grey hover:text-gray-700"
              >
                {{ currentProject.name }}
              </div>
            </div>
          </li>
        </ol>
      </nav>
    </div>
    <div *ngIf="currentProject.id" class="flex items-center gap-8">
      <div
        [routerLink]="'/funnel-editor/' + currentProject.id"
        [queryParams]="{ adminEdit: adminEdit !== 'true' ? '' : 'true' }"
        class="flex flex-col items-center cursor-pointer"
      >
        <i class="text-lg bi bi-pencil-fill"></i>
        <p
          class="text-xs"
          [ngClass]="{
            'border-b-2 border-kl-purple ': router.url.includes('editor')
          }"
          i18n="@@header_funnelEditor_label"
        >
          Editor
        </p>
      </div>

      <a
        [routerLink]="'/funnel-presets/' + currentProject.id"
        class="flex flex-col items-center cursor-pointer"
      >
        <i class="text-lg bi bi-palette2"></i>
        <p
          class="text-xs"
          [ngClass]="{
            ' border-b-2 border-kl-purple ': router.url.includes('presets')
          }"
          i18n="@@header_funnelFlow_label"
        >
          Style-Vorgaben
        </p>
      </a>
      <a
        [routerLink]="'/funnel-flow/' + currentProject.id"
        class="flex flex-col items-center cursor-pointer"
      >
        <i class="text-lg bi bi-diagram-3-fill"></i>
        <p
          class="text-xs"
          [ngClass]="{
            ' border-b-2 border-kl-purple ': router.url.includes('flow')
          }"
          i18n="@@header_funnelFlow_label"
        >
          Funnel-Flow
        </p>
      </a>
      <div
        [routerLink]="'/funnel-leads/' + currentProject.id"
        [queryParams]="{ adminEdit: adminEdit !== 'true' ? '' : 'true' }"
        class="flex flex-col items-center cursor-pointer"
      >
        <i class="text-lg bi bi-people-fill"></i>
        <p
          class="text-xs"
          [ngClass]="{
            ' border-b-2 border-kl-purple ': router.url.includes('leads')
          }"
          i18n="@@header_funnelLeads_label"
        >
          Leads
        </p>
      </div>
      <div
        [routerLink]="'/funnel-settings/' + currentProject.id"
        [queryParams]="{ adminEdit: adminEdit !== 'true' ? '' : 'true' }"
        class="flex flex-col items-center cursor-pointer"
      >
        <i class="text-lg bi bi-gear-fill"></i>
        <p
          class="text-xs"
          [ngClass]="{
            ' border-b-2 border-kl-purple ':
              router.url.includes('funnel-settings')
          }"
          i18n="@@header_funnelSettings_label"
        >
          Einstellungen
        </p>
      </div>
    </div>
    <div class="flex">
      <div *ngIf="currentProject.id" class="flex items-center">
        <div type="button" class="w-32 mr-4 text-sm text-gray-400">
          {{ !isSaved ? "gespeichert" : "wird gespeichert..." }}
        </div>
        <!--  <a
          [href]="appUrl + currentProject.id"
          target="_blank"
          type="button"
          class="h-8 flex justify-center text-sm items-center px-2.5 py-1 text-kl-grey rounded-full bg-kl-green hover:bg-green-400"
        >
          Funnel öffnen
        </a> --><!-- This example requires Tailwind CSS v2.0+ -->
        <span class="relative z-0 inline-flex rounded-md shadow-sm">
          <a
            *ngIf="currentProject.status"
            [href]="appUrl + currentProject.id"
            target="_blank"
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-gray-300 rounded-l-md bg-kl-purple hover:bg-purple-600 focus:z-10 focus:outline-none"
            i18n="@@header_openFunnel_label"
          >
            Funnel öffnen
          </a>
          <a
            *ngIf="!currentProject.status"
            (click)="toggleStatus()"
            target="_blank"
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-gray-300 rounded cursor-pointer bg-kl-purple hover:bg-purple-600 focus:z-10 focus:outline-none"
            i18n="@@header_activateFunnel_label"
          >
            Funnel aktivieren
          </a>

          <span class="relative block -ml-px" *ngIf="currentProject.status">
            <button
              (click)="toggleStatusDropdown()"
              type="button"
              class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-white border border-gray-300 rounded-r-md bg-kl-purple hover:bg-purple-600 focus:z-10 focus:outline-none"
              id="option-menu-button"
              aria-expanded="true"
              aria-haspopup="true"
              (clickOutside)="showStatusDropdown = false"
            >
              <!-- Heroicon name: solid/chevron-down -->
              <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>

            <div
              class="absolute right-0 w-56 mt-2 -mr-1 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="option-menu-button"
              tabindex="-1"
              *ngIf="showStatusDropdown"
            >
              <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <a
                  *ngIf="!currentProject.status"
                  (click)="toggleStatus()"
                  class="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  role="menuitem"
                  tabindex="-1"
                  id="option-menu-item-0"
                  i18n="@@header_funnelActivate_label"
                >
                  Funnel aktivieren
                </a>

                <a
                  *ngIf="currentProject.status"
                  (click)="toggleStatus()"
                  class="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  role="menuitem"
                  tabindex="-1"
                  id="option-menu-item-1"
                  i18n="@@header_funnelDeactivate_label"
                >
                  Funnel deaktivieren
                </a>
              </div>
            </div>
          </span>
        </span>
      </div>
      <div class="flex justify-between px-4 sm:px-6">
        <div class="flex items-center justify-between w-full"></div>
        <div class="flex items-center ml-2 space-x-4 sm:ml-6 sm:space-x-6">
          <!-- Profile dropdown -->
          <div class="relative flex-shrink-0">
            <div>
              <button
                [matMenuTriggerFor]="menu"
                type="button"
                class="flex text-sm bg-white rounded-full focus:outline-none"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <div
                  class="flex items-center justify-center w-10 h-10 text-white rounded-full bg-kl-grey"
                >
                  <p class="text-sm">{{ nameInitials }}</p>
                </div>
              </button>

              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/funnels">
                  <i class="mr-2 text-lg bi bi-list"></i>
                  <span i18n="@@header_userDropdownFunnels_label"
                    >Funnelübersicht</span
                  >
                </button>
                <!--   <button mat-menu-item routerLink="/redesign/funnel-marketplace">
                  <i class="mr-2 text-lg bi bi-shop"></i>
                  <span>Funnel-Marktplatz</span>
                </button> -->
                <button mat-menu-item (click)="openAccountSettings()">
                  <i class="mr-2 text-lg bi bi-gear"></i>
                  <span i18n="@@header_userDropdownAccount_label">Account</span>
                </button>
                <button mat-menu-item (click)="openContact()">
                  <i class="mr-2 bi bi-bug"></i>
                  <span i18n="@@header_userDropdownBugReport_label"
                    >Bug melden</span
                  >
                </button>
                <button mat-menu-item (click)="onLogout()">
                  <i class="mr-2 text-lg bi bi-box-arrow-left"></i>
                  <span i18n="@@header_userDropdownLogout_label">Logout</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
