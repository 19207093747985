import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { blockList } from 'src/app/blocks/blockList';
import { Block } from 'src/app/models/block.model';
import { BlockService } from 'src/app/services/block.service';
import { uuidv4 } from 'src/app/services/uuid';

@Component({
  selector: 'app-blocks-menu',
  templateUrl: './blocks-menu.component.html',
  styleUrls: ['./blocks-menu.component.scss'],
})
export class BlocksMenuComponent implements OnInit {
  readonly blockList: Block[] = blockList;
  @Output() onAddElement: EventEmitter<Block> = new EventEmitter();
  constructor(private blockService: BlockService) {}

  ngOnInit(): void {}

  getBlockName(type: string) {
    return this.blockService.getBlockName(type);
  }

  getBlockIcon(icon: string) {
    return this.blockService.getBlockIcon(icon);
  }

  addElement(element: Block) {
    let newBlock: Block = JSON.parse(JSON.stringify(element));
    newBlock.id = uuidv4();
    this.onAddElement.emit(newBlock);
  }
}
