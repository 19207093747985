<!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="!selectedFormItem">
  <div class="flow-root my-6">
    <ul role="list" class="-my-5 divide-y divide-gray-200">
      <li
        class="py-4 cursor-pointer group"
        *ngFor="let item of items; let i = index"
      >
        <div class="flex flex-col">
          <div class="flex-1 min-w-0 mb-2 cursor-pointer">
            <div class="flex justify-between items-center">
              <div class="flex space-x-8">
                <p
                  (click)="openItem(item, i)"
                  class="text-sm font-medium text-gray-900 truncate"
                >
                  {{ item.text }}
                </p>
                <div class="flex space-x-2">
                  <i
                    (click)="moveUp(i)"
                    class="bi bi-chevron-up opacity-0 group-hover:opacity-100 cursor-pointer"
                  ></i>
                  <i
                    (click)="moveDown(i)"
                    class="bi bi-chevron-down opacity-0 group-hover:opacity-100 cursor-pointer"
                  ></i>
                </div>
              </div>

              <i
                (click)="openItem(item, i)"
                class="bi bi-chevron-right cursor-pointer mr-2"
              ></i>
            </div>
          </div>
        </div>
      </li>
      <li class="py-4 cursor-pointer" (click)="openPrivacy()">
        <div class="flex flex-col">
          <div class="flex-1 min-w-0 mb-2 cursor-pointer">
            <div class="flex justify-between items-center">
              <p
                class="text-sm font-medium text-gray-900 truncate"
                i18n="@@funnelSidebarDetailFormItems_privacy_label"
              >
                Datenschutzerklärung
              </p>

              <i class="bi bi-chevron-right cursor-pointer mr-2"></i>
            </div>
          </div>
        </div>
      </li>
      <li class="py-4 cursor-pointer" (click)="openButton()">
        <div class="flex flex-col">
          <div class="flex-1 min-w-0 mb-2 cursor-pointer">
            <div class="flex justify-between items-center">
              <p
                class="text-sm font-medium text-gray-900 truncate"
                i18n="@@funnelSidebarDetailFormItems_button_label"
              >
                Formularbutton
              </p>

              <i class="bi bi-chevron-right cursor-pointer mr-2"></i>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="my-6">
    <a
      (click)="addItem()"
      class="w-full cursor-pointer flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      i18n="@@funnelSidebarDetailFormItems_add_label"
    >
      <i class="mr-1 bi bi-plus-circle"></i> hinzufügen
    </a>
  </div>

  <div
    class="my-6 flex flex-col"
    *ngIf="project?.custobarUrl && project?.custobarSecret"
  >
    <div class="text-sm flex items-center space-x-2 py-2 font-semibold">
      <p>Custobar-Integration</p>
      <a href="https://www.custobar.com/docs/api/" target="_blank">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-question-circle-fill text-kl-purple"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"
          />
        </svg>
      </a>
    </div>
    <div class="my-2">
      <app-text-input
        [title]="'Type'"
        (valueChange)="setCustobarType($event)"
        [value]="project.custobarType"
      ></app-text-input>
    </div>
    <div class="my-2">
      <app-text-input
        [title]="'Company Specific Field'"
        (valueChange)="setCustobarCompanySpecificField($event)"
        [value]="project.custobarCompanySpecificField"
      ></app-text-input>
    </div>
    <div class="my-2">
      <app-text-input
        [title]="'Company Specific Field Value'"
        (valueChange)="setCustobarCompanySpecificFieldValue($event)"
        [value]="project.custobarCompanySpecificFieldValue"
      ></app-text-input>
    </div>
  </div>
</div>
<app-details-form-item
  *ngIf="selectedFormItem"
  [inputField]="selectedFormItem"
  [inputFieldIndex]="selectedFormItemIndex"
  [inputFieldType]="selectedFormItemType"
  (onShowForm)="showForm()"
  (onRemoveItem)="removeItem()"
></app-details-form-item>
