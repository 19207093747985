import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, concat, forkJoin, of } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { LanguageService } from 'src/app/services/lang.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  errors: boolean = false;
  errorText: string;
  pass: boolean = false;
  isEmailValid: boolean | null = null;
  currentUser: User;
  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private projectService: ProjectService,
    public langService: LanguageService,
    private appService: AppService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.projectService.currentProject = <Project>{};

    this.combineAuthSubjectWithUserSubject();

    this.googleAnalyticsService.loadGoogleAnalyticsScript();

  }

  combineAuthSubjectWithUserSubject() {
    this.authService.userAuthSubject
      .pipe(
        flatMap((auth) => {
          console.log(auth);

          if (auth.emailVerified) {
            return this.userService.userDataSubject;
          } else {
            return of(null);
          }
        })
      )
      .subscribe((user) => {
        if (user) {
          console.log(user);
          this.router.navigate(['/funnels']);
        }
      });
  }

  ngOnInit() {
    this.appService.showHeaderSubject.next(false);
  }

  submit() {
    if (this.form.valid) {
      this.authService
        .signInWithEmail({
          email: this.form.value.email,
          password: this.form.value.password,
        })
        .then((u) => {
          this.googleAnalyticsService.eventEmitter(
            'Login',
            'Login',
            'klicklead.de'
          );
          this.router.navigate(['/funnels']);
        })
        .catch((e) => {
          console.log(e);

          this.errorText = 'Login fehlgeschlagen';
          this.errors = true;
        });
    }
  }

  validateEmail = (email: string) => {
    return !!email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  checkIsEmailValid() {
    this.isEmailValid = this.validateEmail(this.form.value.email);
  }

  goToPasswordReset() {
    this.router.navigate(['/password-reset']);
  }

  goToRegister() {
    this.router.navigate(['/register']);
  }

  async loginWithGoogle() {
    this.authService.signInWithGoogle().then(async (u) => {
      let user;
      if (u.user) {
        user = await this.userService.getUserById(u.user.uid);
      }
      if (!user && u.user) {
        const registeredUser: User = {
          displayName: u.user.displayName ? u.user.displayName : '',
          email: u.user.email ? u.user.email : '',
          id: u.user.uid,
          funnelIds: [],

          language: this.langService.getCurrentLanguage(),
          registerDate: new Date(),
          showOnboarding: false,
          receiptEmail: u.user.email ? u.user.email : '',
        };
        //this.userService.userData = registeredUser;
        //this.authService.sendEmailVerification()
        this.userService.setUserDataSubject(registeredUser);

        await this.userService.setUser(registeredUser);

        this.googleAnalyticsService.eventEmitter(
          'Registration',
          'Sign_up',
          'klicklead.de'
        );
      }
      this.router.navigate(['/funnels']);
    });
  }

  resetPassword() {
    /*   const dialogRef = this.dialog.open(ResetEmailDialogComponent, {
      width: '350px',
      data: { name: this.form.value.email }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (typeof result !== 'undefined') {
        await this.authService.sendPasswordResetEmail(result)
        this.alertService.showToaster(this.langService.getWording('login', 'resetPasswordToaster'));
      }
    }) */
  }
}
