import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { starterBlock } from 'src/app/blocks/starterBlock';
import { Page, Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { TierService } from 'src/app/services/tier.service';
import { UserService } from 'src/app/services/user.service';
import { uuidv4 } from 'src/app/services/uuid';
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
import { DialogModalComponent } from '../../modals/dialog-modal/dialog-modal.component';
import { TextModalComponent } from '../../modals/text-modal/text-modal.component';

@Component({
  selector: 'app-pages-bar',
  templateUrl: './pages-bar.component.html',
  styleUrls: ['./pages-bar.component.scss'],
})
export class PagesBarComponent implements OnInit {
  state$: Subscription;
  isPageBarOpen: boolean = false;
  pages: Page[] = [];
  currentPageIndex: number = 0;
  currentProject: Project;
  user: User;
  maxPageCount: number = 0;
  constructor(
    private appService: AppService,
    private dialog: MatDialog,
    private tierService: TierService,
    private userService: UserService,
    private router: Router
  ) {
    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.isPageBarOpen = state.editor.layout.pageBar.isPagesBarOpen;
        this.pages = state.editor.currentProject.pages;
        this.currentPageIndex = state.editor.layout.pageBar.currentPageIndex;
        this.currentProject = state.editor.currentProject;
      }
    });
  }
  ngOnInit(): void {
    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }
  getPageCountRights() {
    if (this.user.tier) {
      this.maxPageCount = this.tierService.getRights(
        'maxPages',
        this.user.tier!
      );
      console.log(this.maxPageCount);
      if (this.pages.length >= this.maxPageCount) {
        const dialogRef = this.dialog.open(DialogModalComponent, {
          data: {
            headline: 'Maximale Anzahl erreicht',
            text: `Du hast die maximale Anzahl von Seiten erreicht. Um weitere Seiten zu erstellen, musst du zur nächsten Preispaket upgraden.`,
            buttonText: 'Ok',
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.router.navigateByUrl('/user-settings?payment=true');
          }
        });
        return false;
      }
    }
    return true;
  }

  togglePageBar() {
    const pageBarState: boolean = !this.isPageBarOpen;
    this.appService.showPageBar(pageBarState);
  }

  selectPage(index: number) {
    console.log(index);
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          pageBar: {
            ...this.appService.state.editor.layout.pageBar,
            currentPageIndex: index,
          },
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            detailSidebarIndex: 3,
            selectedAnswer: null,
            selectedInputField: null,
          },
          designer: {
            ...this.appService.state.editor.layout.designer,
            currentElementIndex: -1,
            selectedElement: null,
          },
          isBlocksOpen: false,
        },
      },
    });
  }

  addPage() {
    if (!this.getPageCountRights()) return;

    const presets = this.currentProject.designPresets!;

    let page: Page = {
      id: uuidv4(),
      blocks: [],

      name: 'Seite ' + (this.pages.length + 1),
      style: {
        backgroundColor: presets.backgroundColor || '#ffffff',
        fontFamily: presets.fontFamily || 'Arial',
        fontColor: presets.fontColor || '#000000',
        fontSize: presets.fontSize || 16,
        alignment: presets.alignment || 'center',
        contentAlignment: presets.contentAlignment || 'center',
      },
    };
    this.currentProject.pages.push(page);

    const newPageIndex = this.pages.length - 1;
    this.currentProject.pages[newPageIndex].style.containerBackgroundColor =
      presets.backgroundColor || '#ffffff';

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentProject: this.currentProject,
          layout: {
            ...this.appService.state.editor.layout,
            designer: { currentElementIndex: -1, selectedElement: null },
            sideDetail: {
              isDetailSidebarOpen: false,
              detailSidebarIndex: 0,
              selectedAnswer: null,
              selectedAnswerIndex: -1,
              selectedInputField: null,
              selectedInputFieldIndex: -1,
              selectedBulletpoint: null,
              selectedBulletpointIndex: -1,
            },
            isBlocksOpen: false,
            isDesignMenuOpen: false,
            pageBar: {
              ...this.appService.state.editor.layout.pageBar,
              currentPageIndex: newPageIndex,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  editPageName(index: any) {
    let editPage = this.currentProject.pages[index];
    const dialogRef = this.dialog.open(TextModalComponent, {
      width: '350px',
      data: { name: editPage.name },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined') {
        editPage.name = result;
        this.currentProject.pages[index] = editPage;
        this.appService.setShouldSave();
      }
    });
  }

  copyPage(index: any) {
    let copyPage: Page = JSON.parse(
      JSON.stringify(this.currentProject.pages[index])
    );
    copyPage.id = uuidv4();
    copyPage.name = 'Kopie von ' + copyPage.name;
    this.currentProject.pages.splice(index + 1, 0, copyPage);

    this.appService.setShouldSave();

    //this.setHistory();
  }

  deletePage(index: any) {
    if (this.currentProject.pages.length > 1) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        width: '350px',
        data: {
          buttonText: 'Entfernen',
          text: 'Durch das Entfernen von Seiten, können bereits erfasste Leads und Einträge gelöscht werden.',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && this.currentProject) {
          this.currentProject.pages.splice(index, 1);
          index = index === 0 ? 0 : index - 1;
          this.selectPage(index);
          this.appService.setShouldSave();

          // this.projectService.currentProjectSubject.next(this.currentProject);
          // this.setHistory();

          //this.projectService.saveCurrentProject();
        }
      });
    } else {
      //this.setHistory();
      //this.html = [];
      //this.projectService.saveCurrentProject();
    }
  }

  dropElement(event: CdkDragDrop<any[]>, pageIndex?: number) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          pageBar: {
            ...this.appService.state.editor.layout.pageBar,
            currentPageIndex: event.currentIndex,
          },
        },
      },
    });
    this.appService.setShouldSave();
  }
}
