import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page, Project } from 'src/app/models/project.model';
import { Block, Answer, BlockAction } from 'src/app/models/block.model';
import { ProjectService } from 'src/app/services/project.service';
import { BlockActionType } from 'src/app/enums/block-action-type';
import { uuidv4 } from 'src/app/services/uuid';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { TierService } from 'src/app/services/tier.service';
import { EMPTY, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
declare var Drawflow: any;

@Component({
  selector: 'app-page-flow',
  templateUrl: './page-flow.component.html',
  styleUrls: ['./page-flow.component.scss'],
})
export class PageFlowComponent implements OnInit {
  projectId: string;

  mobile_last_move: any;
  mobile_item_selec: any;
  transform: any;
  lock: any;
  unlock: any;
  editor: any;
  currentProject: Project;
  dataToImport: any;
  linkPosX: number = 400;
  user: User;
  branchingEnabled: boolean = false;
  constructor(
    public projectService: ProjectService,
    private route: ActivatedRoute,
    private userService: UserService,
    private tierService: TierService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.projectId = this.route.snapshot.params.id;
  }

  ngOnInit() {
    from(this.projectService.getProjectById(this.projectId))
      .pipe(
        switchMap((project) => {
          this.currentProject = project;
          return from(this.loadScript());
        }),
        catchError((error) => {
          console.error('Error loading project or script:', error);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.drawFlow();
      });
  }

  loadScript(): Promise<void> {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src =
        'https://cdn.jsdelivr.net/gh/jerosoler/Drawflow/dist/drawflow.min.js';
      script.onload = () => resolve();
      document.head.appendChild(script);
    });
  }

  getBranchingRights() {
    if (this.user.tier) {
      this.branchingEnabled = this.tierService.getRights(
        'branchingEnabled',
        this.user.tier!
      );
      console.log(this.branchingEnabled);
    }
  }

  openPaymentPage() {
    this.router.navigateByUrl('/user-settings?payment=true');
  }

  /*  loadScript() {
    const head = document.getElementsByTagName('head')[0];

    const style3 = document.createElement('script');

    style3.src =
      'https://cdn.jsdelivr.net/gh/jerosoler/Drawflow/dist/drawflow.min.js';
    head.appendChild(style3);

    style3.onload = () => {
      this.drawFlow();
    };
  } */

  drawFlow(): void {
    setTimeout(() => {
      var id = document.getElementById('drawflow');
      this.editor = new Drawflow(id);

      this.editor.reroute = true;
      this.editor.useuuid = true;

      this.dataToImport = {
        drawflow: {
          Home: {
            data: {},
          },
        },
      };

      let posX = -250;
      this.currentProject.pages.forEach((page, index) => {
        posX += 280;
        const node = this.createNode(page, index, posX, 50);

        this.dataToImport.drawflow.Home.data = {
          ...this.dataToImport.drawflow.Home.data,
          ...node,
        };
      });

      this.setConnectionsBetweenNodes(this.dataToImport.drawflow.Home.data);

      let that = this;

      // wait 1 second before loading
      setTimeout(() => {
        that.editor.start();
        that.editor.import(that.dataToImport);
      }, 2000);

      var transform = '';

      // Events!
      this.editor.on('nodeCreated', function (id: any) {
        console.log('Node created ' + id);
      });

      this.editor.on('nodeRemoved', function (id: any) {
        console.log('Node removed ' + id);
      });

      this.editor.on('nodeSelected', (id: any) => {
        console.log('Node selected ' + id);
        console.log(this.dataToImport.drawflow.Home.data[id]);
      });

      this.editor.on('moduleCreated', function (id: any) {
        console.log('Module Created ' + name);
      });

      this.editor.on('moduleChanged', function (id: any) {
        console.log('Module Changed ' + name);
      });

      this.editor.on('connectionCreated', function (connection: any) {
        console.log(connection);
        const nodeInfo = that.editor.getNodeFromId(connection.output_id);
        if (nodeInfo.outputs[connection.output_class].connections.length > 1) {
          const removeConnectionInfo =
            nodeInfo.outputs[connection.output_class].connections[0];
          that.editor.removeSingleConnection(
            connection.output_id,
            removeConnectionInfo.node,
            connection.output_class,
            removeConnectionInfo.output
          );
        }
        that.createConnection(connection);
      });

      this.editor.on('connectionRemoved', function (connection: any) {
        console.log(connection);
        that.createConnection(connection, true);
      });

      this.editor.on('mouseMove', function (position: any) {});

      this.editor.on('nodeMoved', function (id: any) {
        console.log(
          'Node moved ',
          that.editor.drawflow.drawflow.Home.data[id].pos_x +
            ' ' +
            that.editor.drawflow.drawflow.Home.data[id].pos_y
        );

        const page = that.currentProject.pages.find((page) => page.id === id);
        if (page) {
          page.drawflow = page.drawflow || { pos_x: 0, pos_y: 0 };
          page.drawflow.pos_x =
            that.editor.drawflow.drawflow.Home.data[id].pos_x;
          page.drawflow.pos_y =
            that.editor.drawflow.drawflow.Home.data[id].pos_y;
          that.projectService.currentProjectSubject.next(that.currentProject);
          that.projectService.saveProject(that.currentProject);
        }
        {
          const page = that.currentProject.pages.find((page) =>
            id.includes(page.id)
          );
          const blockIndex = id.split('_')[1];
          const blockActionIndex = id.split('_')[2];

          let block = page?.blocks[blockIndex];
          const position = {
            pos_x: that.editor.drawflow.drawflow.Home.data[id].pos_x,
            pos_y: that.editor.drawflow.drawflow.Home.data[id].pos_y,
          };
          if (block) {
            block = that.setDrawflowPositionOfBlockAction(
              block,
              blockActionIndex,
              position
            );
            that.projectService.currentProjectSubject.next(that.currentProject);
            that.projectService.saveProject(that.currentProject);
          }
          //page?.blocks[blockIndex].actions[blockActionIndex].drawflow = {}
        }
      });

      this.editor.on('zoom', function (zoom: any) {
        console.log('Zoom level ' + zoom);
      });

      this.editor.on('translate', function (position: any) {});

      this.editor.on('addReroute', function (id: any) {
        console.log('Reroute added ' + id);
      });

      this.editor.on('removeReroute', function (id: any) {
        console.log('Reroute removed ' + id);
      });

      /* DRAG EVENT */

      /* Mouse and Touch Actions */

      var elements = document.getElementsByClassName('drag-drawflow');
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('touchend', this.drop, false);
        elements[i].addEventListener('touchmove', this.positionMobile, false);
        elements[i].addEventListener('touchstart', this.drag, false);
      }

      var mobile_item_selec = '';
      var mobile_last_move = null;
      this.cdRef.detectChanges();
    }, 100);
  }

  createConnection(connection: any, setNextPage = false) {
    let page = this.getPageById(connection.output_id);
    let pageIndex = this.getPageIndexById(connection.output_id);
    if (!page) return;
    const blockActionIndex = this.getBlockActionIndex(page);
    const blockAction = this.getBlockAction(page);
    const outputIndex = parseInt(connection.output_class.split('_')[1]);
    if (this.currentProject.pages[pageIndex].blocks[blockActionIndex]) {
      let page = this.currentProject.pages[pageIndex];

      if (blockActionIndex === -1) return;

      page = this.setBlockActionOfPage(
        page,
        blockActionIndex,
        outputIndex,
        connection,
        setNextPage
      );

      this.currentProject.pages[pageIndex] = page;
      this.projectService.currentProjectSubject.next(this.currentProject);
      this.projectService.saveProject(this.currentProject);
    }
  }

  setDrawflowPositionOfBlockAction(
    block: Block,
    blockActionIndex: number,
    connection: any
  ) {
    switch (block.type) {
      case 'quiz':
        {
          let answer: Answer = block.content.answers[blockActionIndex];
          console.log(block);

          if (answer.action) {
            answer.action.drawflow = answer.action?.drawflow || {
              pos_x: 0,
              pos_y: 0,
            };

            answer.action.drawflow.pos_x = connection.pos_x;
            answer.action.drawflow.pos_y = connection.pos_y;
          }
        }
        break;
      case 'form':
        {
          let form = block.content.submitButton.action;

          form.drawflow = form.drawflow || {
            pos_x: 0,
            pos_y: 0,
          };

          form.drawflow.pos_x = connection.pos_x;
          form.drawflow.pos_y = connection.pos_y;
        }
        break;
      case 'button':
        {
          let button = block.action;
          console.log(block);

          if (button && button.drawflow) {
            button.drawflow = button.drawflow || {
              pos_x: 0,
              pos_y: 0,
            };

            button.drawflow.pos_x = connection.pos_x;
            button.drawflow.pos_y = connection.pos_y;
          }
        }
        break;

      case 'fakeloader':
        {
          let fakeloader = block.action;
          console.log(block);

          if (fakeloader && fakeloader.drawflow) {
            fakeloader.drawflow = fakeloader.drawflow || {
              pos_x: 0,
              pos_y: 0,
            };

            fakeloader.drawflow.pos_x = connection.pos_x;
            fakeloader.drawflow.pos_y = connection.pos_y;
          }
        }
        break;
      default:
        break;
    }

    return block;
  }

  setBlockActionOfPage(
    page: Page,
    blockActionIndex: number,
    outputIndex: number,
    connection: any,
    setNextPage: boolean
  ) {
    let block = page.blocks[blockActionIndex];
    switch (block.type) {
      case 'quiz':
        {
          let quiz = block.content.answers[outputIndex - 1];
          console.log(block);

          if (quiz.action) {
            quiz.action.type = setNextPage
              ? BlockActionType.NEXTPAGE
              : BlockActionType.CONNECTEDPAGE;
            quiz.action.connectedPage = connection.input_id;
          }
        }
        break;
      case 'form':
        {
          let form = block.content.submitButton.action;

          form.type = setNextPage
            ? BlockActionType.NEXTPAGE
            : BlockActionType.CONNECTEDPAGE;
          form.connectedPage = connection.input_id;
        }
        break;
      case 'button':
        {
          let button = block.action;
          console.log(block);

          if (button) {
            button.type = setNextPage
              ? BlockActionType.NEXTPAGE
              : BlockActionType.CONNECTEDPAGE;
            button.connectedPage = connection.input_id;
          }
        }
        break;

      case 'fakeloader':
        {
          let fakeloader = block.action;
          console.log(block);

          if (fakeloader) {
            fakeloader.type = setNextPage
              ? BlockActionType.NEXTPAGE
              : BlockActionType.CONNECTEDPAGE;
            fakeloader.connectedPage = connection.input_id;
          }
        }
        break;
      default:
        break;
    }

    return page;
  }

  createNode(page: Page, index: number, posX: number, posY: number) {
    const nodeId = page.id;
    let nodeInput = {};

    nodeInput = this.createNodeInput(page);

    const nodeOutput = this.createNodeOutput(page);
    const answers = this.getAnswers(page);

    const node = {
      [nodeId]: {
        id: nodeId,
        name: page.name,
        data: {},
        class: 'welcome',
        html:
          ' <div class="flex flex-col"> <div class="bg-gray-100 p-1  -mt-1.5 text-center odd:bg-white even:bg-gray-100">' +
          (index + 1) +
          '. ' +
          page.name +
          ' </div>  <div class="text-left ml-14">' +
          answers.join('<br> ') +
          '</div> </div>  ',
        typenode: false,
        inputs: nodeInput,
        outputs: nodeOutput,
        pos_x: page.drawflow ? page.drawflow.pos_x : posX,
        pos_y: page.drawflow ? page.drawflow.pos_y : posY,
      },
    };

    return node;
  }

  createNodeInput(page: Page) {
    const nodeInput = {
      input_1: {
        connections: [],
      },
    };

    return nodeInput;
  }

  createNodeOutput(page: Page) {
    let outputs = {};
    page.blocks.forEach((block: Block, blockIndex: number) => {
      const outputAnswer = this.createOutputAnswers(block);
      outputs = outputAnswer;
    });

    return outputs;
  }

  createOutputAnswers(block: Block) {
    let outputs = {};

    switch (block.type) {
      case 'quiz':
        {
          block.content.answers.forEach(
            (answer: Answer, answerIndex: number) => {
              const outputName = 'output_' + (answerIndex + 1);
              outputs = {
                ...outputs,
                [outputName]: {
                  connections: [],
                },
              };
            }
          );
        }
        break;

      case 'button':
        {
          const outputName = 'output_' + 1;
          outputs = {
            ...outputs,
            [outputName]: {
              connections: [],
            },
          };
        }
        break;
      case 'fakeloader':
        {
          const outputName = 'output_' + 1;
          outputs = {
            ...outputs,
            [outputName]: {
              connections: [],
            },
          };
        }
        break;
      case 'form':
        {
          const outputName = 'output_' + 1;
          outputs = {
            ...outputs,
            [outputName]: {
              connections: [],
            },
          };
        }

        break;
      default:
        break;
    }

    return outputs;
  }

  createOutputAnswerText(block: Block) {
    let outputs: string[] = [];

    switch (block.type) {
      case 'quiz':
        {
          block.content.answers.forEach(
            (answer: Answer, answerIndex: number) => {
              if (answer.answerText) {
                outputs.push(answer.answerText);
              }
            }
          );
        }
        break;

      case 'button':
        {
          outputs.push(block.content);
        }
        break;
      case 'fakeloader':
        {
          outputs.push('Ladebalken');
        }
        break;
      case 'form':
        {
          outputs.push('Formular');
        }

        break;
      default:
        break;
    }

    return outputs;
  }

  getBlockActionIndex(page: Page) {
    let blockActionIndex = -1;
    page.blocks.forEach((block: Block, blockIndex: number) => {
      switch (block.type) {
        case 'button':
        case 'fakeloader':
        case 'form':
        case 'quiz':
          {
            blockActionIndex = blockIndex;
          }
          break;

        default:
          break;
      }
    });
    return blockActionIndex;
  }

  getBlockAction(page: Page) {
    let blockAction: BlockAction[] = [];
    page.blocks.forEach((block: Block, blockIndex: number) => {
      switch (block.type) {
        case 'button':
          {
            const action = block.action ? block.action : null;
            if (action) {
              blockAction?.push(action);
            }
          }
          break;
        case 'fakeloader':
          {
            const action = block.action ? block.action : null;
            if (action) {
              blockAction?.push(action);
            }
          }
          break;
        case 'form':
          const action = block.content.submitButton.action
            ? block.content.submitButton.action
            : null;
          if (action) {
            blockAction?.push(action);
          }

          break;
        case 'quiz':
          block.content.answers.forEach((answer: Answer) => {
            if (answer.action) {
              blockAction?.push(answer.action);
            }
          });
          break;
        default:
          break;
      }
    });
    return blockAction;
  }

  getAnswers(page: Page) {
    let answers: string[] = [];
    page.blocks.forEach((block: Block, blockIndex: number) => {
      const outputAnswer = this.createOutputAnswerText(block);
      answers = outputAnswer;
    });

    return answers;
  }

  setConnectionsBetweenNodes(data: any) {
    for (let key in data) {
      let value = data[key];
      //console.log(key, value);
      const page = this.getPageById(key);
      if (page) {
        const blockAction = this.getBlockAction(page);
        blockAction.forEach((action: BlockAction, blockActionIndex: number) => {
          switch (action.type) {
            case 'nextPage':
              {
                const nextPageIndex: number =
                  this.getPageIndexById(page.id) + 1;
                const nextPage: Page = this.currentProject.pages[nextPageIndex];
                if (!nextPage) return;

                const outputName = 'output_' + (blockActionIndex + 1);

                value.outputs = {
                  ...value.outputs,
                  [outputName]: {
                    connections: [{ node: nextPage.id, output: 'input_1' }],
                  },
                };

                let inputNode = this.getNodeById(nextPage.id);
                inputNode.inputs.input_1.connections.push({
                  node: page.id,
                  input: outputName,
                });
              }

              break;
            case 'connectedPage':
              {
                if (!action.connectedPage) return;
                const nextPageIndex: number = this.getPageIndexById(
                  action.connectedPage
                );
                const nextPage: Page = this.currentProject.pages[nextPageIndex];

                const outputName = 'output_' + (blockActionIndex + 1);

                value.outputs = {
                  ...value.outputs,
                  [outputName]: {
                    connections: [{ node: nextPage.id, output: 'input_1' }],
                  },
                };

                let inputNode = this.getNodeById(nextPage.id);

                inputNode.inputs = inputNode.inputs.input_1
                  ? inputNode.inputs
                  : { input_1: { connections: [] } };

                inputNode.inputs = {
                  ...inputNode.inputs,
                  input_1: {
                    ...inputNode.inputs.input_1,
                    connections: [
                      ...inputNode.inputs.input_1.connections,
                      {
                        node: page.id,
                        input: outputName,
                      },
                    ],
                  },
                };
              }

              break;
            case 'link':
              {
                const outputName = 'output_' + (blockActionIndex + 1);
                const blockIndex = this.getBlockActionIndex(page);
                const id = page.id + '_' + blockIndex + '_' + blockActionIndex;
                this.linkPosX += 300;

                action.drawflow = action.drawflow || {
                  pos_x: this.linkPosX,
                  pos_y: 700,
                };

                const linkNode = {
                  [id]: {
                    id: id,
                    name: 'Link',
                    data: {},
                    class: 'welcome',
                    html:
                      ' <div class="flex flex-col"> <div class="bg-blue-400 text-white p-1  -mt-1.5 text-center odd:bg-white even:bg-blue-100">Ausgehender Link </div>  <div class="bg-blue-100 text-left text-center"> ' +
                      action.link +
                      '</div> </div>  ',
                    typenode: false,
                    inputs: {
                      input_1: {
                        connections: [{ node: value.id, input: outputName }],
                      },
                    },
                    outputs: {},
                    pos_x: action.drawflow?.pos_x,
                    pos_y: action.drawflow?.pos_y,
                  },
                };

                data = {
                  ...data,
                  ...linkNode,
                };

                value.outputs = {
                  ...value.outputs,
                  [outputName]: {
                    connections: [{ node: linkNode[id].id, output: 'input_1' }],
                  },
                };
              }

              break;
            default:
              break;
          }
        });
      }
    }

    this.dataToImport.drawflow.Home.data = data;
  }

  getPageById(id: string) {
    return this.currentProject.pages.find((page: Page) => page.id === id);
  }

  getPageIndexById(id: string) {
    return this.currentProject.pages.findIndex((page: Page) => page.id === id);
  }

  getNodeById(id: string) {
    return this.dataToImport.drawflow.Home.data[id];
  }

  positionMobile(ev: any) {
    this.mobile_last_move = ev;
  }

  allowDrop(ev: any) {
    ev.preventDefault();
  }

  drag(ev: any) {
    if (ev.type === 'touchstart') {
      this.mobile_item_selec = ev.target
        .closest('.drag-drawflow')
        .getAttribute('data-node');
    } else {
      ev.dataTransfer.setData('node', ev.target.getAttribute('data-node'));
    }
  }

  drop(ev: any) {
    if (ev.type === 'touchend') {
      var parentdrawflow = document.elementFromPoint(
        this.mobile_last_move.touches[0].clientX,
        this.mobile_last_move.touches[0].clientY
      );
      if (parentdrawflow !== null) {
        parentdrawflow.closest('#drawflow');
        this.addNodeToDrawFlow(
          this.mobile_item_selec,
          this.mobile_last_move.touches[0].clientX,
          this.mobile_last_move.touches[0].clientY
        );
      }
      this.mobile_item_selec = '';
    } else {
      ev.preventDefault();
      var data = ev.dataTransfer.getData('node');
      this.addNodeToDrawFlow(data, ev.clientX, ev.clientY);
    }
  }

  addNodeToDrawFlow(name: any, pos_x: any, pos_y: any) {
    if (this.editor.this.editor_mode === 'fixed') {
      return;
    }
    pos_x =
      pos_x *
        (this.editor.precanvas.clientWidth /
          (this.editor.precanvas.clientWidth * this.editor.zoom)) -
      this.editor.precanvas.getBoundingClientRect().x *
        (this.editor.precanvas.clientWidth /
          (this.editor.precanvas.clientWidth * this.editor.zoom));
    pos_y =
      pos_y *
        (this.editor.precanvas.clientHeight /
          (this.editor.precanvas.clientHeight * this.editor.zoom)) -
      this.editor.precanvas.getBoundingClientRect().y *
        (this.editor.precanvas.clientHeight /
          (this.editor.precanvas.clientHeight * this.editor.zoom));
  }

  showpopup(e: any) {
    e.target.closest('.drawflow-node').style.zIndex = '9999';
    e.target.children[0].style.display = 'block';

    this.transform = this.editor.precanvas.style.transform;
    this.editor.precanvas.style.transform = '';
    this.editor.precanvas.style.left = this.editor.canvas_x + 'px';
    this.editor.precanvas.style.top = this.editor.canvas_y + 'px';
    console.log(this.transform);

    this.editor.this.editor_mode = 'fixed';
  }

  closemodal(e: any) {
    e.target.closest('.drawflow-node').style.zIndex = '2';
    e.target.parentElement.parentElement.style.display = 'none';
    this.editor.precanvas.style.transform = this.transform;
    this.editor.precanvas.style.left = '0px';
    this.editor.precanvas.style.top = '0px';
    this.editor.this.editor_mode = 'edit';
  }

  changeModule(event: any) {
    var all = document.querySelectorAll('.menu ul li');
    for (var i = 0; i < all.length; i++) {
      all[i].classList.remove('selected');
    }
    event.target.classList.add('selected');
  }

  changeMode(option: any) {
    //console.log(lock.id);
    if (option == 'lock') {
      this.lock.style.display = 'none';
      this.unlock.style.display = 'block';
    } else {
      this.lock.style.display = 'block';
      this.unlock.style.display = 'none';
    }
  }

  zoom_in() {
    this.editor.zoom_in();
  }

  zoom_out() {
    this.editor.zoom_out();
  }

  zoom_reset() {
    this.editor.zoom_reset();
  }
}
