<section aria-labelledby="payment-details-heading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="bg-gray-100 py-6 px-4 sm:p-6">
      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            >Stripe Customer ID
          </label>
          <input
            type="text"
            [(ngModel)]="user.stripeCustomerId"
            [ngModelOptions]="{ standalone: true }"
            class="text-gray-900 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>

        <div class="col-span-4 sm:col-span-2">
          <div>
            <label
              for="location"
              class="block text-sm font-medium text-gray-700"
              >Preisstufe</label
            >
            <select
              id="location"
              name="location"
              [(ngModel)]="user.tier"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="trial">Trial</option>
              <option value="basic">Basic</option>
              <option value="professional">Professional</option>
              <option value="premium">Premium</option>
            </select>
          </div>
        </div>
      </div>

      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            >Stripe Price Id</label
          >
          <input
            type="text"
            [(ngModel)]="user.priceId"
            [ngModelOptions]="{ standalone: true }"
            class="text-gray-900 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>

        <div class="col-span-4 sm:col-span-2 flex items-end pb-2">
          <div>
            <label
              for="location"
              class="block text-sm font-medium text-gray-700"
              >Bezahlintervall</label
            >
            <select
              id="location"
              name="location"
              [(ngModel)]="user.paymentInterval"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="monthly">Monatlich</option>
              <option value="yearly">Jährlich</option>
            </select>
          </div>
        </div>
      </div>

      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="last-name"
            class="block text-sm font-medium text-gray-700"
          >
            Registriert seit
          </label>
          <!--  <div
          class="w-full bg-gray-200 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
        >
          {{ user.registerDate | firestoreDate }}
        </div> -->
          <!--    <input
          type="date"
          [(ngModel)]="user.registerDate"
          (input)="test()"
          [ngModelOptions]="{ standalone: true }"
          class="text-gray-900 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
        /> -->
          <mat-form-field>
            <input
              matInput
              [matDatepicker]="pickerDOB"
              [(ngModel)]="registerDate"
              (dateInput)="addEvent('input', $event)"
              (dateChange)="addEvent('change', $event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerDOB"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerDOB></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="px-4 py-3 bg-gray-100 text-right sm:px-6">
      <button
        (click)="saveUser()"
        class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
      >
        Speichern
      </button>
    </div>
  </div>
</section>
