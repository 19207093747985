<div class="my-6">
  <label
    for="email"
    class="block text-sm font-medium text-gray-700"
    i18n="@@funnelSidebarDetailUnsplash_search_label"
    >Suchen</label
  >

  <div class="mt-1 relative flex items-center">
    <input
      type="text"
      (keydown.enter)="search()"
      [(ngModel)]="searchString"
      placeholder="z.B.: Google"
      class="shadow-sm block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
    />
    <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
      <kbd
        (click)="search()"
        class="cursor-pointer inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400"
      >
        Enter <i class="ml-1 bi bi-arrow-return-left"></i>
      </kbd>
    </div>
  </div>
</div>
<div
  *ngIf="unsplashImagesLoader"
  class="flex items-center flex-wrap gap-4 overflow-auto"
>
  <div
    class="flex flex-col items-center justify-between p-2 w-1/4 h-auto group cursor-pointer border rounded-lg"
    *ngFor="let image of [1, 2, 3, 4, 5, 6, 7, 8]"
  >
    <img
      class="w-20 h-32 object-contain rounded-lg bg-gray-300 animate-pulse"
    />
  </div>
</div>
<div class="flex items-center flex-wrap gap-4 overflow-auto">
  <div
    class="flex flex-col items-center justify-between p-2 w-1/4 h-auto group cursor-pointer"
    *ngFor="let image of unsplashImages"
    (click)="selectPicture(image.urls.regular, image.links.download_location)"
  >
    <img
      [src]="image.urls.thumb"
      class="w-full h-auto object-contain rounded-lg"
    />

    <div class="text-xs text-gray-400 mt-1">
      Photo by
      <a
        [href]="
          'https://unsplash.com/@' +
          image.user.username +
          '?utm_source=KlickLead&utm_medium=referral'
        "
        >{{ image.user.name }}</a
      >
      on
      <a href="https://unsplash.com/?utm_source=KlickLead&utm_medium=referral"
        >Unsplash</a
      >
    </div>
  </div>
</div>
