import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Block } from 'src/app/models/block.model';
import { Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  state$: Subscription;
  currentSidebarIndex: number = 0;
  isSidebarDetailOpen: boolean = false;
  menuItems: any[] = [
    { name: 'Blöcke', icon: 'bi bi-boxes text-xl' },
    { name: 'Details', icon: 'bi bi-bullseye text-xl' },
    /*     { name: 'Mediathek', icon: 'bi bi-images text-xl' },
     { name: 'Theme', icon: 'bi bi-palette text-xl' }, */
    { name: 'Seiteninfo', icon: 'bi bi-file-text-fill text-xl' },
  ];

  showUndo: boolean = false;
  showRedo: boolean = false;
  projectRedoArray: Project[] = [];
  projectUndoArray: Project[] = [];
  projectCurrentArray: Project[] = [];
  constructor(private appService: AppService) {
    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.currentSidebarIndex =
          state.editor.layout.sideDetail.detailSidebarIndex;
        this.showUndo = state.editor.history.showUndo;
        this.showRedo = state.editor.history.showRedo;
        this.projectUndoArray = state.editor.history.projectUndoArray;
        this.projectRedoArray = state.editor.history.projectRedoArray;
        this.projectCurrentArray = state.editor.history.projectCurrentArray;
      }
    });
  }
  ngOnInit(): void {}

  selectSideBarIndex(menuIndex: number) {
    this.appService.showDetailSidebar(true);

    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            detailSidebarIndex: menuIndex,
            selectedAnswer: null,
            selectedInputField: null,
          },
        },
      },
    });
  }

  redo() {
    if (this.projectRedoArray.length != 0) {
      const cur_pro = this.projectCurrentArray.pop();
      if (!cur_pro) return;
      this.projectUndoArray.push(cur_pro);

      const redo_pro = this.projectRedoArray.pop();
      if (!redo_pro) return;
      this.projectCurrentArray.push(redo_pro);

      if (this.projectRedoArray.length == 0) {
        this.appService.state.editor.history.showRedo = false;
      }
    }

    if (this.appService.state.editor.history.projectUndoArray.length > 0) {
      this.appService.state.editor.history.showUndo = true;
    } else {
      this.appService.state.editor.history.showUndo = false;
    }

    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        currentProject:
          this.appService.state.editor.history.projectCurrentArray[0],
        history: {
          ...this.appService.state.editor.history,
          showRedo: this.appService.state.editor.history.showRedo,
          showUndo: this.appService.state.editor.history.showUndo,
          projectUndoArray: this.projectUndoArray,
          projectRedoArray: this.projectRedoArray,
          projectCurrentArray: this.projectCurrentArray,
        },
      },
    });
    this.appService.setShouldSave(false);
  }

  undo() {
    if (this.projectUndoArray.length !== 0) {
      const cur_pro = JSON.parse(
        JSON.stringify(this.projectCurrentArray.pop())
      );
      if (!cur_pro) return;

      this.projectRedoArray.push(cur_pro);

      let undo_pro = JSON.parse(JSON.stringify(this.projectUndoArray.pop()));
      if (this.appService.state.editor.history.initialUndo) {
        undo_pro = JSON.parse(JSON.stringify(this.projectUndoArray.pop()));
      }
      if (!undo_pro) return;
      this.projectCurrentArray.push(undo_pro);

      if (this.projectUndoArray.length == 0) {
        this.appService.state.editor.history.showUndo = false;
      }

      this.appService.state.editor.history.showRedo = true;
    }
    this.appService.state.editor.history.initialUndo = false;

    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        currentProject:
          this.appService.state.editor.history.projectCurrentArray[0],
        history: {
          ...this.appService.state.editor.history,
          initialUndo: false,
          showRedo: this.appService.state.editor.history.showRedo,
          showUndo: this.appService.state.editor.history.showUndo,
          projectUndoArray: this.projectUndoArray,
          projectRedoArray: this.projectRedoArray,
          projectCurrentArray: this.projectCurrentArray,
        },
      },
    });
    this.appService.setShouldSave(false);
  }
}
