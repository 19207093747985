import { BlockActionType } from '../enums/block-action-type';
import { BlockType } from '../enums/block-type';
import { Block } from '../models/block.model';
import { FunnelTemplate } from '../models/funnel-template.model';
import { uuidv4 } from '../services/uuid';

export const starterBlock: Block[] = [
  {
    id: uuidv4(),
    content: 'https://zw-systems.de/img/about-us.dc653aab.jpg',
    type: BlockType.PICTURE,
    style: {
      width: 500,
      alignment: 'center',
      marginTop: 3,
    },
  },
  {
    id: uuidv4(),
    content: 'Deine Überschrift',
    type: BlockType.HEADER,
    style: {
      fontSize: 25,
      alignment: 'center',
      marginTop: 22,
    },
  },
  {
    id: uuidv4(),
    content: 'Kleine Überschrift',
    type: BlockType.HEADER,
    style: {
      fontSize: 16,
      alignment: 'center',
      marginTop: 1,
      fontWeight: 'bold',
      fontStyle: '',
      textDecoration: '',
    },
  },
/*   {
    id: uuidv4(),
    content: {
      answers: [
        {
          answerText: 'Antwort' + ' 1',
          type: 'picture',
          answerPicture: 'https://zw-systems.de/img/about-us.dc653aab.jpg',
          action: {
            type: BlockActionType.NEXTPAGE,
          },
          style: {
            fontColor: '#ffffff',
            backgroundColor: '#4797ff',
            fontSize: 19,
          },
        },
        {
          answerText: 'Antwort' + ' 2',
          type: 'picture',
          answerPicture: 'https://zw-systems.de/img/about-us.dc653aab.jpg',
          action: {
            type: BlockActionType.NEXTPAGE,
          },
          style: {
            fontColor: '#ffffff',
            backgroundColor: '#4797ff',
            fontSize: 19,
          },
        },
        {
          answerText: 'Antwort' + ' 3',
          type: 'picture',
          answerPicture: 'https://zw-systems.de/img/about-us.dc653aab.jpg',
          action: {
            type: BlockActionType.NEXTPAGE,
          },
          style: {
            fontColor: '#ffffff',
            backgroundColor: '#4797ff',
            fontSize: 19,
          },
        },
        {
          answerText: 'Antwort' + ' 4',
          type: 'picture',
          answerPicture: 'https://zw-systems.de/img/about-us.dc653aab.jpg',
          action: {
            type: BlockActionType.NEXTPAGE,
          },
          style: {
            fontColor: '#ffffff',
            backgroundColor: '#4797ff',
            fontSize: 19,
          },
        },
      ],
    },
    type: BlockType.QUIZ,
    style: {
      fontSize: 17,
      alignment: 'center',
    },
  }, */
];

export const starterTemplate: FunnelTemplate = {
  id: uuidv4(),
  name: 'Leere Vorlage',
  updatedAt: new Date(),
  catergoryId: '',
  createdAt: new Date(),
  template: [
    {
      id: uuidv4(),
      style: { containerBackgroundColor: '#FFFFFF' },
      name: 'Seite 1',
      blocks: [],
    },
  ],
};
