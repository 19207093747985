import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import * as firebase from 'firebase/app';
import { DialogModalComponent } from '../../modals/dialog-modal/dialog-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from 'src/app/services/project.service';
import { MailMarketingService } from 'src/app/services/mail-marketing.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-dashboard-data',
  templateUrl: './user-dashboard-data.component.html',
  styleUrls: ['./user-dashboard-data.component.scss'],
})
export class UserDashboardDataComponent implements OnInit {
  @Input() user: User;
  registerDate: Date;
  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private mailMarketingService: MailMarketingService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.registerDate = this.user.registerDate.toDate();
  }

  async saveUser() {
    const save = await this.userService.setUser(this.user, false);
    this.snackBar.open('Ihre Daten wurden gespeichert', 'Okay', {
      duration: 5000,
    });
  }

  addEvent(type: string, event: any) {
    this.user.registerDate = firebase.default.firestore.Timestamp.fromDate(
      event.value
    );
    this.registerDate = event.value;
    console.log(this.user.registerDate);
  }

  async deleteAccount() {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: {
        buttonText: 'Löschen',
        text: 'Wollen Sie Ihr Konto und all Ihre Funnels wirklich löschen?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        await this.userService.deleteUser(this.user.id);
        for (let projectId of this.user.funnelIds) {
          const isSharedFunnel: User[] =
            await this.userService.getUsersByFunnelId(projectId);

          if (isSharedFunnel.length === 1) {
            await this.projectService.deleteProject(projectId);
          }
        }
        await this.mailMarketingService
          .deleteSubscriber(this.user.email)
          .catch((err) => {
            console.log(err);
          });
        await this.authService.deleteUser();
        window.location.reload();
      }
    });
  }
}
