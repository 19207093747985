<div class="flex justify-between">
  <div
    class="w-1/3 h-screen p-16 mb-10 space-y-6 overflow-y-scroll bg-white shadow-lg"
  >
    <h2 class="mb-4 text-2xl font-semibold">Style-Vorgaben</h2>
    <p class="mb-2 text-sm text-gray-600">
      Hier kannst du die Style-Vorgaben für deine Seiten festlegen. <br>Diese
      Vorgaben werden auf alle Seiten, die du erstellst, angewendet.
    </p>

    <div class="mb-4">
      <app-font-selection
        [title]="'Schriftart'"
        [selectedFont]="presets?.fontFamily"
        (onSelectFont)="setFontFamily($event)"
      ></app-font-selection>
    </div>

    <div class="flex flex-col mb-4 space-y-6">
      <div class="flex flex-col">
        <app-color-picker
          [title]="'Hintergrundfarbe'"
          [color]="presets?.backgroundColor ? presets?.backgroundColor : '-'"
          (onColorChange)="setBackgroundColor($event)"
        ></app-color-picker>
      </div>
      <div class="flex flex-col">
        <app-color-picker
          [title]="'Textfarbe'"
          [color]="presets?.fontColor ? presets?.fontColor : '-'"
          (onColorChange)="setFontColor($event)"
        ></app-color-picker>
      </div>
    </div>

    <div class="my-6">
      <app-range-slider
        [value]="presets?.fontSize ? presets?.fontSize : 16"
        [title]="'Schriftgröße'"
        (valueChange)="changed($event, 'fontSize')"
      ></app-range-slider>
    </div>

    <!--   <div class="my-6">
      <app-alignment
        [title]="'Block Ausrichtung'"
        [alignment]="presets?.alignment ? presets?.alignment : 'left'"
        (onAlignmentChange)="setAlignment($event)"
      ></app-alignment>
    </div> -->

    <div class="my-6">
      <app-alignment
        [title]="'Text Ausrichtung'"
        [alignment]="
          presets?.contentAlignment ? presets?.contentAlignment : 'left'
        "
        (onAlignmentChange)="setContentAlignment($event)"
      ></app-alignment>
    </div>

    <div class="flex flex-col mb-4 space-y-6">
      <div class="flex flex-col">
        <app-color-picker
          [title]="'Quiz Antwort Hintergrundfarbe'"
          [color]="
            presets?.quizAnswerBackgroundColor
              ? presets?.quizAnswerBackgroundColor
              : '-'
          "
          (onColorChange)="setQuizAnswerBackgroundColor($event)"
        ></app-color-picker>
      </div>
      <div class="flex flex-col">
        <app-color-picker
          [title]="'Quiz Antwort Textfarbe'"
          [color]="
            presets?.quizAnswerFontColor ? presets?.quizAnswerFontColor : '-'
          "
          (onColorChange)="setQuizAnswerFontColor($event)"
        ></app-color-picker>
      </div>
    </div>
  </div>

  <div class="flex flex-col items-center justify-center w-2/3 h-screen">
    <p class="mb-4 -mt-20 text-lg text-gray-500">Vorschau</p>
    <link [href]="getFontCSS()" rel="stylesheet" />

    <div
      class="flex flex-col w-auto p-3 py-10 border border-gray-400 rounded-lg"
      [ngStyle]="{
        'font-family': presets?.fontFamily,
        'background-color': presets?.backgroundColor,
        color: presets?.fontColor
      }"
    >
      <div
        class="z-50 flex justify-start w-3/5 group hover:border-blue-400 ng-star-inserted"
      >
        <div
          class="flex flex-col items-center justify-center h-full text-center transform rounded-t-lg w-96"
        >
          <div
            [ngStyle]="{
              'font-size.px': presets?.fontSize,
              'text-align': presets?.contentAlignment
            }"
            class="px-4 w-96 ng-star-inserted"
            contenteditable="true"
          >
            Deine Überschrift
          </div>
        </div>
      </div>
      <div
        _ngcontent-drh-c331=""
        class="flex flex-wrap justify-center pb-10 w-96"
        style="font-size: 17px"
      >
        <div _ngcontent-drh-c331="" class="mx-3 my-3 ng-star-inserted">
          <div
            _ngcontent-drh-c331=""
            class="flex flex-col items-center justify-between w-40 h-full border border-gray-200 rounded shadow-xl"
            ng-reflect-ng-class="[object Object]"
            ng-reflect-ng-style="[object Object]"
          >
            <div
              _ngcontent-drh-c331=""
              class="relative flex flex-col items-center justify-between w-full"
            >
              <img
                _ngcontent-drh-c331=""
                class="object-cover w-full h-24 rounded-t ng-star-inserted"
                src="./assets/placeholder.jpg"
                ng-reflect-ng-style="[object Object]"
              />
            </div>
            <div
              _ngcontent-drh-c331=""
              class="flex items-start justify-center w-full h-full p-2 rounded-b"
              [ngStyle]="{
                'background-color': presets?.quizAnswerBackgroundColor,
                color: presets?.quizAnswerFontColor
              }"
            >
              <div
                _ngcontent-drh-c331=""
                contenteditable="true"
                class="w-full px-2 text-center bg-transparent border-transparent focus:border-transparent focus:ring-0"
              >
                Antwort 1
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-drh-c331="" class="mx-3 my-3 ng-star-inserted">
          <div
            _ngcontent-drh-c331=""
            class="flex flex-col items-center justify-between w-40 h-full border border-gray-200 rounded shadow-xl"
          >
            <div
              _ngcontent-drh-c331=""
              class="relative flex flex-col items-center justify-between w-full"
            >
              <img
                _ngcontent-drh-c331=""
                class="object-cover w-full h-24 rounded-t ng-star-inserted"
                src="./assets/placeholder.jpg"
              />
            </div>
            <div
              _ngcontent-drh-c331=""
              class="flex items-start justify-center w-full h-full p-2 rounded-b"
              [ngStyle]="{
                'background-color': presets?.quizAnswerBackgroundColor,
                color: presets?.quizAnswerFontColor
              }"
            >
              <div
                _ngcontent-drh-c331=""
                contenteditable="true"
                class="w-full px-2 text-center bg-transparent border-transparent focus:border-transparent focus:ring-0"
              >
                Antwort 2
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-drh-c331="" class="mx-3 my-3 ng-star-inserted">
          <div
            _ngcontent-drh-c331=""
            class="flex flex-col items-center justify-between w-40 h-full border border-gray-200 rounded shadow-xl"
          >
            <div
              _ngcontent-drh-c331=""
              class="relative flex flex-col items-center justify-between w-full"
            >
              <img
                _ngcontent-drh-c331=""
                class="object-cover w-full h-24 rounded-t ng-star-inserted"
                src="./assets/placeholder.jpg"
              />
            </div>
            <div
              _ngcontent-drh-c331=""
              class="flex items-start justify-center w-full h-full p-2 rounded-b"
              [ngStyle]="{
                'background-color': presets?.quizAnswerBackgroundColor,
                color: presets?.quizAnswerFontColor
              }"
            >
              <div
                _ngcontent-drh-c331=""
                contenteditable="true"
                class="w-full px-2 text-center bg-transparent border-transparent focus:border-transparent focus:ring-0"
              >
                Antwort 3
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-drh-c331="" class="mx-3 my-3 ng-star-inserted">
          <div
            _ngcontent-drh-c331=""
            class="flex flex-col items-center justify-between w-40 h-full border border-gray-200 rounded shadow-xl"
          >
            <div
              _ngcontent-drh-c331=""
              class="relative flex flex-col items-center justify-between w-full"
            >
              <img
                _ngcontent-drh-c331=""
                class="object-cover w-full h-24 rounded-t ng-star-inserted"
                src="./assets/placeholder.jpg"
              />
            </div>
            <div
              _ngcontent-drh-c331=""
              class="flex items-start justify-center w-full h-full p-2 rounded-b"
              [ngStyle]="{
                'background-color': presets?.quizAnswerBackgroundColor,
                color: presets?.quizAnswerFontColor
              }"
            >
              <div
                _ngcontent-drh-c331=""
                contenteditable="true"
                class="w-full px-2 text-center bg-transparent border-transparent focus:border-transparent focus:ring-0"
              >
                Antwort 4
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="z-auto flex justify-start w-full group hover:border-blue-400 ng-star-inserted"
      >
        <div class="w-32"></div>
      </div>
    </div>
  </div>
</div>
