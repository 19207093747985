<div class="h-full flex flex-col">
  <div class="flex h-full">
    <!-- Narrow sidebar -->

    <!-- Content area -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <!-- Main content -->
      <div class="flex-1 flex items-stretch overflow-hidden">
        <main class="flex-1 overflow-y-auto bg-gray-100 py-6 px-12 relative">
          <h2
            class="text-2xl font-bold leading-7 text-kl-grey sm:text-3xl sm:truncate mb-6"
            i18n="@@funnelSettings_funnelSettingsHeadline_label"
          >
            Funnel-Einstellungen
          </h2>
          <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <app-settings-navigation
              class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2"
            ></app-settings-navigation>

            <!-- Payment details -->
            <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 h-full mb-10">
              <app-settings-general
                *ngIf="navigationIndex === 0"
              ></app-settings-general>
              <app-settings-meta
                *ngIf="navigationIndex === 1"
              ></app-settings-meta>
              <app-settings-invitation
                *ngIf="navigationIndex === 2"
              ></app-settings-invitation>
              <app-settings-tracking
                *ngIf="navigationIndex === 3"
              ></app-settings-tracking>
              <app-settings-domains
                *ngIf="navigationIndex === 4"
              ></app-settings-domains>
              <app-settings-embed
                *ngIf="navigationIndex === 5"
              ></app-settings-embed>
              <app-settings-integrations
                *ngIf="navigationIndex === 6"
              ></app-settings-integrations>
              <app-settings-privacy
                *ngIf="navigationIndex === 7"
              ></app-settings-privacy>
              <app-settings-wording
                *ngIf="navigationIndex === 8"
              ></app-settings-wording>
              <app-settings-lead-notification
                *ngIf="navigationIndex === 9"
              ></app-settings-lead-notification>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</div>
