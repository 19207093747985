import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FolderImage, MediaImage } from 'src/app/models/media-image.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { MediaLibraryService } from 'src/app/services/media-library.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { uuidv4 } from 'src/app/services/uuid';
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
import { DialogModalComponent } from '../dialog-modal/dialog-modal.component';
import { RenameProjectDialogComponent } from '../rename-project-dialog/rename-project-dialog.component';

@Component({
  selector: 'app-media-library-modal',
  templateUrl: './media-library-modal.component.html',
  styleUrls: ['./media-library-modal.component.scss'],
})
export class MediaLibraryModalComponent implements OnInit {
  selectedImageIndex: number = -1;
  currentProject$: Subscription;

  currentProject: Project;
  images: any[] = [];
  loader: boolean = false;
  currentFolder: any = null;
  user!: User;
  isDragging: boolean = false;

  projectId: string;

  folderStack: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<MediaLibraryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private mediaLibraryService: MediaLibraryService,
    private userService: UserService
  ) {
    this.currentProject$ = this.projectService.currentProjectSubject.subscribe(
      (project: Project) => {
        if (project) {
          this.currentProject = project;
        }
      }
    );

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
        this.loadUserImages();
      }
    });

    this.projectId = data.projectId;
  }

  ngOnInit() {
    this.loadUserImages();
  }

  selectPicture(image: MediaImage) {
    //this.onSelectImage.emit(image.url);
  }

  async openFolder(
    folder: FolderImage | null,
    index: number = -1,
    stack: boolean = true
  ) {
    this.currentFolder = folder;
    this.images = await this.mediaLibraryService.getImagesOfFolder(
      this.currentFolder.id
    );
    console.log(this.images);

    this.images.sort((a, b) => {
      if (a.type === 'folder' && b.type === 'image') {
        return -1;
      } else if (a.type === 'image' && b.type === 'folder') {
        return 1;
      } else {
        return 0;
      }
    });

    if (stack) {
      if (index !== -1) {
        this.folderStack.splice(index + 1, this.folderStack.length - index);
      } else {
        this.folderStack.push(folder);
      }
    }
  }

  openRootFolder() {
    this.currentFolder = null;
    this.folderStack = [];
    this.loadUserImages();
  }

  onDragStarted() {
    this.isDragging = true;
  }

  onDragEnded() {
    this.isDragging = false;
  }

  async drop(event: CdkDragDrop<FolderImage[]>) {
    this.isDragging = false;
    const draggedItem = event.item.data as FolderImage;
    const targetItem = this.findDropTarget(event);

    if (targetItem && this.isMovingIntoFolder(draggedItem, targetItem)) {
      await this.moveItemIntoFolder(draggedItem, targetItem);
    } else {
      moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    }

    await this.refreshCurrentFolder();
  }

  async moveItemIntoFolder(item: FolderImage, targetFolder: FolderImage) {
    const oldParentId = item.parentId;
    item.parentId = targetFolder.id;

    // Update the item in the database
    await this.mediaLibraryService.setImageOfFolder(item);

    // Remove the item from the current view
    this.images = this.images.filter((i) => i.id !== item.id);
  }

  async refreshCurrentFolder() {
    if (this.currentFolder) {
      // Reload the current folder
      const updatedFolder = await this.mediaLibraryService.getFolder(
        this.currentFolder.id
      );
      this.currentFolder = updatedFolder;
      this.images = await this.mediaLibraryService.getImagesOfFolder(
        this.currentFolder.id
      );
    } else {
      await this.loadUserImages();
    }
    this.sortImages();
  }

  // Neue Methode zum Laden der Benutzerbilder
  async loadUserImages() {
    this.images = await this.mediaLibraryService.getImagesOfUser(this.user.id);
    console.log(this.images);
    this.sortImages();
  }

  findDropTarget(event: CdkDragDrop<FolderImage[]>): FolderImage | null {
    // Finde das nächstgelegene Folder-Element
    const dropPoint = event.dropPoint;
    const elements = document.elementsFromPoint(dropPoint.x, dropPoint.y);

    for (const element of elements) {
      const itemId = element.getAttribute('data-item-id');
      if (itemId) {
        const targetItem = this.images.find((item) => item.id === itemId);
        if (targetItem && targetItem.type === 'folder') {
          return targetItem;
        }
      }
    }

    return null;
  }

  isMovingIntoFolder(
    draggedItem: FolderImage,
    targetItem: FolderImage | null
  ): boolean {
    return (
      targetItem !== null &&
      targetItem.type === 'folder' &&
      draggedItem.id !== targetItem.id
    );
  }

  sortImages() {
    this.images.sort((a, b) => {
      if (a.type === 'folder' && b.type === 'image') {
        return -1;
      } else if (a.type === 'image' && b.type === 'folder') {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
  }

  async loadImages() {
    this.loader = true;
    if (!this.isDragging) {
      this.images = await this.mediaLibraryService.getImages(
        this.currentProject.id
      );

      // sort images by type
      this.images.sort((a, b) => {
        if (a.type === 'folder' && b.type === 'image') {
          return -1;
        } else if (a.type === 'image' && b.type === 'folder') {
          return 1;
        } else {
          return 0;
        }
      });
    }

    this.loader = false;
  }

  async moveFolderUp(item: FolderImage) {
    if (!this.currentFolder) {
      console.log('Already at root level');
      return;
    }

    // Move the item to the parent folder
    item.parentId = this.currentFolder.parentId;

    // Update the item in the database
    await this.mediaLibraryService.setImageOfFolder(item);

    // Remove the item from the current view
    this.images = this.images.filter((i) => i.id !== item.id);

    // Refresh the current folder view
    await this.refreshCurrentFolder();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectImage(index: number) {
    this.selectedImageIndex = index;
    console.log(this.selectedImageIndex);
  }

  returnSelection() {
    this.dialogRef.close(this.images[this.selectedImageIndex].url);
  }

  async folderBack() {
    if (this.currentFolder !== null) {
      if (this.currentFolder.parentId === '') {
        this.loadUserImages();
        this.folderStack = [];

        this.currentFolder = null;
      } else {
        const folder = await this.mediaLibraryService.getFolder(
          this.currentFolder.parentId
        );
        this.folderStack.pop();
        this.openFolder(folder, -1, false);
      }
    } else {
      this.currentFolder = null;

      if (this.currentFolder.parentId === '') {
      }

      this.loadUserImages();
    }
  }

  async newFolder() {
    let folder: FolderImage = {
      id: uuidv4(),
      createdDate: new Date(),
      projectId: this.projectId,
      url: '',
      name: 'Neuer Ordner',
      parentId: '',
      type: 'folder',
      userId: this.user.id,
    };

    const dialogRef = this.dialog.open(RenameProjectDialogComponent, {
      width: '550px',
      data: {
        header: 'Neuer Ordner',
        label: 'Geben Sie einen Namen für den Ordner ein',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        folder.name = result;
        if (this.currentFolder !== null) {
          folder.parentId = this.currentFolder.id;
          folder.userId = '';
          await this.mediaLibraryService.setImageOfFolder(folder);
          this.openFolder(this.currentFolder);
        } else {
          await this.mediaLibraryService.setImageOfFolder(folder);
          this.loadUserImages();
        }
      }
    });
  }

  /*   async uploadImage(event: any) {
    this.loader = true;
    for (let i = 0; i < event.target.files.length; i++) {
      let image: MediaImage = {
        id: uuidv4(),
        createdDate: new Date(),
        projectId: this.currentProject.id,
        url: '',
      };

      const picture = await this.mediaLibraryService.uploadImageStorage(
        this.currentProject.id,
        image.id,
        event.target.files[i]
      );

      image.url = await picture.ref.getDownloadURL();

      await this.mediaLibraryService.setImage(image);
      this.images.push(image);
    }

    this.images.sort((a, b) => {
      const bDate: any = b.createdDate as Object;
      const aDate: any = a.createdDate as Object;
      return (
        new Date(aDate.seconds * 1000).getTime() -
        new Date(bDate.seconds * 1000).getTime()
      );
    });
    this.loader = false;
  } */

  /*  deleteImage(index: number) {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: {
        buttonText: 'Entfernen',
        text: 'Wollen Sie dieses Bild wirklich entfernen?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.mediaLibraryService.deleteImage(this.images[index].id);
        this.images.splice(index, 1);
        this.selectedImageIndex = -1;
      }
    });
  }
 */

  deleteImage(image: FolderImage) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '550px',
      data: {
        title: image.type === 'folder' ? 'Ordner löschen' : 'Bild löschen',
        text:
          image.type === 'folder'
            ? 'Wollen Sie diesen Ordner wirklich löschen?'
            : 'Wollen Sie dieses Bild wirklich löschen?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.mediaLibraryService.deleteImage(image.id);
        this.images = this.images.filter((item) => item.id !== image.id);
      }
    });
  }

  async uploadImage(event: any) {
    this.loader = true;
    for (let i = 0; i < event.target.files.length; i++) {
      let image: FolderImage = {
        id: uuidv4(),
        createdDate: new Date(),
        projectId: this.projectId,
        url: '',
        name: event.target.files[i].name,
        parentId: '',
        type: 'image',
        userId: '',
      };

      if (this.currentFolder === null) {
        image.userId = this.user.id;
      } else {
        image.parentId = this.currentFolder.id;
      }

      const picture = await this.mediaLibraryService.uploadImageStorage(
        this.user.id,
        image.id,
        event.target.files[i]
      );

      image.url = await picture.ref.getDownloadURL();

      await this.mediaLibraryService.setImageOfFolder(image);
      this.images.push(image);
    }

    this.images.sort((a, b) => {
      const bDate: any = b.createdDate as Object;
      const aDate: any = a.createdDate as Object;
      return (
        new Date(aDate.seconds * 1000).getTime() -
        new Date(bDate.seconds * 1000).getTime()
      );
    });
    this.loader = false;
  }
}
