<!-- component -->
<div class="min-h-full bg-gray-50 px-4 py-6 flex flex-col items-start mb-8">
  <div class="tabs mb-4">
    <a
      class="tab tab-bordered"
      [ngClass]="leftTabIndex === 0 ? 'tab-active' : ''"
      (click)="setLeftTabIndex(0)"
      >Seiten</a
    >
    <a
      class="tab tab-bordered"
      [ngClass]="leftTabIndex === 1 ? 'tab-active' : ''"
      (click)="setLeftTabIndex(1)"
      >Details</a
    >
    <a
      class="tab tab-bordered"
      [ngClass]="leftTabIndex === 2 ? 'tab-active' : ''"
      (click)="setLeftTabIndex(2)"
      >Design</a
    >
  </div>

  <app-pages-sidebar
    *ngIf="leftTabIndex === 0"
    class="w-full"
  ></app-pages-sidebar>

  <app-page-details-sidebar
    *ngIf="leftTabIndex === 1 || selectedElement !== null"
    class="w-full"
  ></app-page-details-sidebar>

  <app-page-design-sidebar
    *ngIf="leftTabIndex === 2"
    class="w-full"
  ></app-page-design-sidebar>
</div>
