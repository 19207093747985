<!-- This example requires Tailwind CSS v2.0+ -->
<div>
  <div class="flow-root mt-6 w-1/2 mx-auto">
    <ul role="list" class="-my-5 divide-y divide-gray-200">
      <li class="py-4" *ngFor="let funnel of funnels">
        <div class="flex items-center space-x-4">
          <div class="flex-shrink-0">
            <div
              class="h-3 w-3 rounded-full"
              [ngClass]="{
                'bg-red-400': !funnel.status,
                'bg-green-400': funnel.status
              }"
            ></div>
          </div>
          <div class="flex-1 min-w-0">
            <p class="text-sm font-medium text-gray-900 truncate">
              {{ funnel.name }}
            </p>
            <p class="text-sm text-gray-500 truncate">{{ funnel.id }}</p>
          </div>
          <div>
            <a
              target="_blank"
              [href]="baseUrl + '/funnel-editor/' + funnel.id + '?adminEdit=true'"
              class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
            >
              Funnel bearbeiten
            </a>
          </div>
          <div>
            <a
              *ngIf="funnel.status"
              target="_blank"
              [href]="baseUrl + '/' + funnel.id"
              class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
            >
              Funnel öffnen
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
