<div
  class="w-full px-4 flex flex-col justify-center items-center flex-wrap"
  [ngStyle]="style"
>
  <div [ngClass]="{ 'w-full': mobileView, 'w-1/3': !mobileView }">
    <div
      class="flex flex-col mt-2"
      *ngFor="let input of block.content.inputs; let inputIndex = index"
      (click)="selectWestnetzElement(input, inputIndex, 'input')"
    >
      <div class="relative">
        <div
          *ngIf="input.isIcon"
          class="absolute text-gray-600 dark:text-gray-400 flex items-center pl-4 h-full cursor-pointer"
        >
          <i [ngClass]="input.icon"></i>
        </div>
        <input
          class="focus:outline-none focus:border bg-white font-normal w-full h-10 flex items-center text-sm border-gray-300 rounded shadow border focus:border-gray-500 focus:ring-0"
          [ngClass]="{ 'pl-12': input.isIcon, 'pl-4': !input.isIcon }"
          [name]="input.text"
          ngDefaultControl
          [placeholder]="
            input.required ? input.text + '*' : input.text + ' (optional)'
          "
          [type]="input.type"
          [required]="input.required ? 'required' : null"
          (focus)="focusWestnetzInputIn(inputIndex)"
          (focusout)="focusWestnetzInputOut()"
          [ngStyle]="getWestnetzInputStyle(blockIndex, inputIndex)"
        />
      </div>
    </div>

    <p
      class="text-gray-600 text-xs my-2 flex justify-end"
      *ngIf="!checkIfRequiredFieldsPresent()"
    >
      * Pflichtfelder
    </p>
  </div>

  <button
    (click)="selectWestnetzElement(null, block.content.inputs.length + 1, 'submit')"
    [ngStyle]="getWestnetzSubmitButtonStyle(blockIndex)"
    class="flex justify-center items-center my-2 transition duration-150 ease-in-out focus:outline-none rounded border border-gray-300 px-6 py-2 w-full text-center"
    [ngClass]="{ 'w-full': mobileView, 'w-1/3': !mobileView }"
    (mouseover)="over()"
    (mouseout)="out()"
  >
    <p>{{ block.content.submitButton.text }}</p>
  </button>
</div>
