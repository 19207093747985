import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { icons } from 'src/app/icons/icons';
import { MediaImage } from 'src/app/models/media-image.model';
import { Project } from 'src/app/models/project.model';
import { MediaLibraryService } from 'src/app/services/media-library.service';
import { ProjectService } from 'src/app/services/project.service';
import { uuidv4 } from 'src/app/services/uuid';
import { DialogModalComponent } from '../dialog-modal/dialog-modal.component';

@Component({
  selector: 'app-icon-library',
  templateUrl: './icon-library.component.html',
  styleUrls: ['./icon-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLibraryComponent implements OnInit {
  selectedImageIndex: number = -1;
  currentProject$: Subscription;

  currentProject: Project;
  icons = icons;
  loadedIcons: string[] = [];
  loader: boolean = false;
  pos: number = 0;
  filterString: string = '';
  selectedIcon: string = '';
  constructor(
    public dialogRef: MatDialogRef<IconLibraryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private mediaLibraryService: MediaLibraryService
  ) {
    this.currentProject$ = this.projectService.currentProjectSubject.subscribe(
      (project: Project) => {
        if (project) {
          this.currentProject = project;
        }
      }
    );
  }

  ngOnInit() {
    this.infiniteLoad();
  }

  infiniteLoad() {
    if (this.filterString === '') {
      const obj = document.getElementById('list');
      if (!obj) return;
      if (obj.scrollTop === obj.scrollHeight - obj.offsetHeight) {
        this.loadIcons();
        this.pos += 50;
      }
    }
  }

  selectIcon(icon: string) {
    this.selectedIcon = icon;
  }

  loadIcons() {
    if (icons.length <= this.pos) return;
    let moreIcons: string[] = icons.slice(this.pos, this.pos + 50);
    this.loadedIcons = this.loadedIcons.concat(moreIcons);
  }

  filterIcons() {
    this.loader = true;
    const filter = this.filterString.toLowerCase();
    this.loadedIcons = icons.filter((icon) => {
      return icon.indexOf(filter) !== -1;
    });
    this.loader = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectImage(index: number) {
    this.selectedImageIndex = index;
    console.log(this.selectedImageIndex);
  }

  returnSelection() {
    this.dialogRef.close(this.selectedIcon);
  }
}
