<section aria-labelledby="payment-details-heading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 sm:p-6">
      <div>
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
          i18n="@@funnelSettingsPrivacy_headline_label"
        >
          Rechtliches
        </h2>
      </div>
      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="flex gap-x-4 items-center">
          <app-toggle
            [isEnabled]="currentProject.privacyEnabled!"
            (onToggle)="setStatus($event)"
          ></app-toggle>
          <p
            class="block text-sm font-medium text-gray-500"
            *ngIf="currentProject.privacyEnabled"
            i18n="@@funnelSettingsPrivacy_activated_label"
          >
            Aktiviert
          </p>
          <p
            class="block text-sm font-medium text-gray-500"
            *ngIf="!currentProject.privacyEnabled"
            i18n="@@funnelSettingsPrivacy_deactived_label"
          >
            Deaktiviert
          </p>
        </div>
      </div>
      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsPrivacy_imprint_label"
            >Impressum</label
          >
          <textarea
            type="text"
            name="first-name"
            id="first-name"
            rows="3"
            [(ngModel)]="currentProject.imprint"
            placeholder="Impressum"
            autocomplete="cc-given-name"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          ></textarea>
        </div>
      </div>
      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="last-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsPrivacy_privacyText_label"
            >Datenschutzerklärung</label
          >
          <textarea
            type="text"
            name="first-name"
            id="first-name"
            rows="3"
            [(ngModel)]="currentProject.dataPrivacy"
            placeholder="Datenschutzerklärung"
            autocomplete="cc-given-name"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="bg-white py-6 px-4 sm:p-6">
      <div>
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
          i18n="@@funnelSettingsPrivacy_cookieSettings_label"
        >
          Cookie-Einstellungen
        </h2>
      </div>
      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="flex gap-x-4 items-center">
          <app-toggle
            [isEnabled]="currentProject.showCookieConsent!"
            (onToggle)="setCookieConsent($event)"
          ></app-toggle>
          <p
            class="block text-sm font-medium text-gray-500"
            *ngIf="currentProject.showCookieConsent"
            i18n="@@funnelSettingsPrivacy_cookieSettingsActivated_label"
          >
            Aktiviert
          </p>
          <p
            class="block text-sm font-medium text-gray-500"
            *ngIf="!currentProject.showCookieConsent"
            i18n="@@funnelSettingsPrivacy_cookieSettingsDeactivated_label"
          >
            Deaktiviert
          </p>
        </div>
      </div>
      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsPrivacy_cookieSettingsHeadline_label"
            >Cookie Überschrift</label
          >
          <input
            type="text"
            [(ngModel)]="currentProject.cookieConsentHeader"
            placeholder="Cookie Überschrift"
            class="block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
      </div>
      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="last-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsPrivacy_cookieSettingsText_label"
            >Cookie Text</label
          >
          <input
            type="text"
            [(ngModel)]="currentProject.cookieConsentText"
            placeholder="Cookie Text"
            class="block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
      </div>

      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="last-name"
            class="block text-sm font-medium text-gray-700"
            i18n="
              @@funnelSettingsPrivacy_cookieSettingsCookieButtonAccept_label"
            >Cookie Button "Notwendige Cookies akzeptieren"</label
          >
          <input
            type="text"
            [(ngModel)]="currentProject.cookieConsentButtonTextAccept"
            placeholder="Cookie Button Akzeptieren"
            class="block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
      </div>

      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="last-name"
            class="block text-sm font-medium text-gray-700"
            i18n="
              @@funnelSettingsPrivacy_cookieSettingsCookieButtonGuidelines_label"
            >Cookie Button "Cookie Richtlinien"</label
          >
          <input
            type="text"
            [(ngModel)]="currentProject.cookieConsentButtonTextAGB"
            placeholder="Cookie Button AGB"
            class="block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
      </div>

      <div class="px-4 py-3 mt-6 text-right sm:px-6">
        <button
          (click)="saveProject()"
          class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900"
          i18n="@@funnelSettingsPrivacy_save_button"
        >
          Speichern
        </button>
      </div>
    </div>
  </div>
</section>
