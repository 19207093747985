import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextfieldType } from 'src/app/enums/textfield-type';
import { InputField } from 'src/app/models/block.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-details-form-item',
  templateUrl: './details-form-item.component.html',
  styleUrls: ['./details-form-item.component.scss'],
})
export class DetailsFormItemComponent implements OnInit {
  @Input() inputField: any;
  @Input() inputFieldIndex: number;
  @Input() inputFieldType: string | null;

  @Output() onShowForm = new EventEmitter();
  @Output() onRemoveItem = new EventEmitter();

  tabIndex: number = 0;
  inputFieldTypes: any[] = ['Text', 'E-Mail', 'Number', 'Telephone'];

  constructor(private appService: AppService) {}

  ngOnInit(): void {}
  goBack() {
    this.onShowForm.emit();
  }

  setTabIndex(index: number) {
    this.tabIndex = index;
  }

  removeItem() {
    this.onRemoveItem.emit(this.inputFieldIndex);
  }

  setIcon(icon: string) {
    this.inputField.icon = icon;
    this.appService.setShouldSave(true);
  }

  setInputFieldType(type: string) {
    switch (type) {
      case 'E-Mail':
        this.inputField.type = TextfieldType.EMAIL;

        break;
      case 'Text':
        this.inputField.type = TextfieldType.TEXT;

        break;
      case 'Number':
        this.inputField.type = TextfieldType.NUMBER;

        break;
      case 'Telephone':
        this.inputField.type = TextfieldType.TEL;

        break;
      default:
        break;
    }
  }

  setPlaceholder(text: string) {
    this.inputField.text = text;
    this.appService.setShouldSave(true);
  }

  setPrivacyLink(text: string) {
    this.inputField.link = text;
    this.appService.setShouldSave(true);
  }

  setInputFieldRequired(required: boolean) {
    this.inputField.required = required;
    this.appService.setShouldSave(true);
  }

  setInputFieldIsIcon(isIcon: boolean) {
    this.inputField.isIcon = isIcon;
    this.appService.setShouldSave(true);
  }
}
