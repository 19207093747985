<section aria-labelledby="payment-details-heading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 sm:p-6">
      <div>
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-800"
          i18n="@@funnelSettingsPrivacy_headline_label"
        >
          Lead Benachrichtigung
        </h2>
      </div>
      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="flex gap-x-4 items-center">
          <app-toggle
            [isEnabled]="currentProject.leadNotification.status"
            (onToggle)="toggleLeadNotification($event)"
          ></app-toggle>
          <p
            class="block text-sm font-medium text-gray-500"
            *ngIf="currentProject.leadNotification.status"
            i18n="@@funnelSettingsPrivacy_activated_label"
          >
            Aktiviert
          </p>
          <p
            class="block text-sm font-medium text-gray-500"
            *ngIf="!currentProject.leadNotification.status"
            i18n="@@funnelSettingsPrivacy_deactived_label"
          >
            Deaktiviert
          </p>
        </div>
      </div>

      <div
        *ngIf="currentProject.leadNotification.status"
        class="border-b border-gray-200"
      >
        <nav class="-mb-px flex" aria-label="Tabs">
          <a
            (click)="setTabIndex(0)"
            class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
            [ngClass]="{
              'border-kl-purple text-kl-purple': tabIndex === 0,
              'text-gray-500 hover:text-gray-700 hover:border-gray-300':
                tabIndex !== 0
            }"
            i18n="@@funnelSidebarDetailsContent_edit_label"
          >
            E-Mail
          </a>

          <a
            (click)="setTabIndex(1)"
            class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
            [ngClass]="{
              'border-kl-purple text-kl-purple': tabIndex === 1,
              'text-gray-500 hover:text-gray-700 hover:border-gray-300':
                tabIndex !== 1
            }"
            i18n="@@funnelSidebarDetailsContent_design_label"
          >
            SMTP-Einstellungen
          </a>
        </nav>
      </div>

      <div *ngIf="currentProject.leadNotification.status && tabIndex === 0">
        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsPrivacy_imprint_label"
              >From-E-Mail</label
            >
            <input
              type="text"
              [(ngModel)]="currentProject.leadNotification.fromEmail"
              placeholder="noreply@unternehmen.de"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-kl-purple focus:border-kl-purple sm:text-sm"
            />
          </div>
        </div>
        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsPrivacy_imprint_label"
              >Betreff</label
            >
            <input
              type="text"
              [(ngModel)]="currentProject.leadNotification.subject"
              placeholder="Danke für deine Eintragung!"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-kl-purple focus:border-kl-purple sm:text-sm"
            />
          </div>
        </div>
        <div class="mt-6 grid grid-cols-4 gap-6 items-end">
          <div class="col-span-4 sm:col-span-2">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsPrivacy_privacyText_label"
              >Inhalt</label
            >
            <div class="flex items-end">
              <textarea
                type="text"
                name="first-name"
                id="first-name"
                rows="3"
                disabled
                [(ngModel)]="currentProject.leadNotification.text"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-kl-purple focus:border-kl-purple sm:text-sm"
              ></textarea>
            </div>
          </div>
          <button
            (click)="openTextEditor()"
            class="ml-4 h-10 w-36 bg-kl-purple border border-transparent rounded-md shadow-sm py-2 flex items-center px-4 justify-center text-sm font-medium text-white hover:bg-purple-600"
            i18n="@@funnelSettingsPrivacy_save_button"
          >
            Text bearbeiten
          </button>
        </div>
        <div class="mt-12 grid grid-cols-4 gap-6 items-end">
          <div class="col-span-4 sm:col-span-2">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsPrivacy_imprint_label"
              >E-Mail Versand testen</label
            >
            <div class="flex items-end">
              <input
                type="email"
                [(ngModel)]="testMailAddress"
                placeholder=" "
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-kl-purple focus:border-kl-purple sm:text-sm"
              />
            </div>
          </div>
          <button
            (click)="sendTestMail()"
            class="ml-4 h-10 w-36 bg-kl-purple border border-transparent rounded-md shadow-sm py-1 flex items-center px-4 justify-center text-sm font-medium text-white hover:bg-purple-600"
            i18n="@@funnelSettingsPrivacy_save_button"
          >
            Senden
          </button>
        </div>
      </div>

      <div *ngIf="currentProject.leadNotification.status && tabIndex === 1">
        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsPrivacy_imprint_label"
              >SMTP Host</label
            >
            <input
              type="text"
              [(ngModel)]="currentProject.leadNotification.smtpHost"
              placeholder=" "
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-kl-purple focus:border-kl-purple sm:text-sm"
            />
          </div>
        </div>

        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsPrivacy_imprint_label"
              >SMTP Host</label
            >
            <input
              type="text"
              [(ngModel)]="currentProject.leadNotification.smtpUsername"
              placeholder=" "
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-kl-purple focus:border-kl-purple sm:text-sm"
            />
          </div>
        </div>
        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsPrivacy_imprint_label"
              >SMTP Passwort</label
            >
            <input
              type="password"
              [(ngModel)]="currentProject.leadNotification.smtpPassword"
              placeholder=" "
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-kl-purple focus:border-kl-purple sm:text-sm"
            />
          </div>
        </div>

        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsPrivacy_imprint_label"
              >SMTP Port</label
            >
            <input
              type="number"
              [(ngModel)]="currentProject.leadNotification.smtpPort"
              placeholder=" "
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-kl-purple focus:border-kl-purple sm:text-sm"
            />
          </div>
        </div>

        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsPrivacy_imprint_label"
              >SMTP Secure (Aktviert = Port 465, Deaktiviert Port 587)</label
            >
            <div class="flex gap-x-4 items-center">
              <app-toggle
                [isEnabled]="currentProject.leadNotification.secure"
                (onToggle)="currentProject.leadNotification.secure = $event"
              ></app-toggle>
              <p
                class="block text-sm font-medium text-gray-500"
                *ngIf="currentProject.leadNotification.secure"
                i18n="@@funnelSettingsPrivacy_activated_label"
              >
                Aktiviert
              </p>
              <p
                class="block text-sm font-medium text-gray-500"
                *ngIf="!currentProject.leadNotification.secure"
                i18n="@@funnelSettingsPrivacy_deactived_label"
              >
                Deaktiviert
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
      <button
        (click)="saveProject()"
        class="bg-kl-purple border border-transparent rounded-md shadow-sm py-1 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-kl-purple"
        i18n="@@funnelSettingsPrivacy_save_button"
      >
        Speichern
      </button>
    </div>
  </div>
</section>
