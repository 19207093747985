<h1 mat-dialog-title>Icon Bibliothek</h1>
<div class="h-full" mat-dialog-content>
  <div class="flex justify-end">
    <input
      type="text"
      class="bg-gray-50 my-2 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
      placeholder="Suche"
      [(ngModel)]="filterString"
      (keyup)="filterIcons()"
    />
  </div>

  <div
    class="h-5/6 rounded-lg bg-gray-100 flex flex-wrap overflow-y-auto p-4"
    (scroll)="infiniteLoad()"
  >
    <div class="flex" *ngIf="loader">
      <div
        *ngFor="let skeleton of [1, 2, 3, 4, 5, 6]"
        class="flex flex-col justify-center items-center w-40 h-32 mx-4 my-2 bg-gray-300 rounded-xl animate-pulse shadow-lg cursor-pointer"
      ></div>
    </div>
    <div class="flex flex-wrap" id="list" (scroll)="infiniteLoad()">
      <div
        *ngFor="let icon of loadedIcons; let i = index"
        class="w-32 h-32 object-cover flex flex-col justify-center items-center mx-2 my-2 cursor-pointer"
        [ngClass]="{
          'border border-3 border-blue-400 rounded-lg ': icon === selectedIcon
        }"
        (click)="selectIcon(icon)"
      >
        <i [ngClass]="icon" class="text-2xl"></i>
        <p>{{ icon }}</p>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="flex items-center justify-end">
  <button (click)="onNoClick()" class="text-zwblue text-sm font-bold mb-3">
    abbrechen
  </button>

  <button
    [disabled]="selectedIcon === ''"
    (click)="returnSelection()"
    class="ml-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3"
    [ngClass]="{
      'bg-gray-300 text-gray-500 cursor-not-allowed': selectedIcon === '',
      'bg-zwblue text-white hover:bg-blue-800 ': selectedIcon !== ''
    }"
  >
    auswählen
  </button>
</div>
