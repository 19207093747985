<div
  *ngIf="showSlideOver"
  class="fixed inset-0 overflow-auto"
  aria-labelledby="slide-over-title"
  role="dialog"
  aria-modal="true"
>
  <div class="absolute inset-0 overflow-auto">
    <!-- Background overlay, show/hide based on slide-over state. -->
    <div
      (click)="closeNotification()"
      class="absolute inset-0 bg-gray-300 opacity-60"
      aria-hidden="true"
    ></div>

    <div
      class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
    >
      <!--
          Slide-over panel, show/hide based on slide-over state.
  
          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
      <div class="pointer-events-auto w-screen max-w-md">
        <div
          class="flex h-full mt-14 flex-col overflow-y-scroll bg-gray-50 py-6 shadow-xl"
        >
          <div class="px-4 sm:px-6">
            <div class="flex items-start justify-between">
              <h2
                class="text-xl font-medium text-gray-900"
                id="slide-over-title"
                i18n="@@funnelLeadsSlideOver_headline_label"
              >
                Lead
              </h2>
              <div class="ml-3 flex h-7 items-center">
                <button
                  (click)="closeNotification()"
                  type="button"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Close panel</span>
                  <!-- Heroicon name: outline/x -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="relative mt-6 flex-1 px-4 sm:px-6 overflow-auto mb-6">
            <!-- Replace with your content -->
            <div class="absolute inset-0 px-4 sm:px-6">
              <div class="h-full" aria-hidden="true">
                <div class="bg-white p-10 rounded-lg shadow-lg overflow-auto">
                  <div *ngFor="let fd of formData">
                    <div class="my-4">
                      <dt
                        class="font-medium text-sm text-gray-500 sm:w-40 sm:flex-shrink-0"
                      >
                        {{ fd.text }}
                      </dt>
                      <dd class="mt-1 text-gray-900 sm:col-span-2">
                        {{ fd.value }}
                      </dd>
                    </div>
                  </div>

                  <div
                    *ngFor="let sub of detailData.answerSubmission.submissions"
                  >
                    <div class="my-4">
                      <dt
                        class="text-gray-500 text-sm sm:w-40 sm:flex-shrink-0"
                      >
                        {{ sub.answer !== null ? sub.pageName + ": " : "" }}
                      </dt>

                      <dd
                        *ngIf="!isLink(sub)"
                        class="mt-1 text-gray-900 sm:col-span-2"
                      >
                        {{ sub.answer !== null ? sub.answer.answerText : "" }}

                        {{
                          sub.answer !== null && !sub.answer.answerText
                            ? sub.answer
                            : ""
                        }}
                      </dd>

                      <a
                        *ngIf="isLink(sub)"
                        [href]="getLink(sub)"
                        target="_blank"
                      >
                        <button
                          class="mt-1 px-2 py-1 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                          i18n="@@funnelLeadsSlideOver_openFile_button"
                        >
                          Datei öffnen
                        </button></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
