import { Injectable } from '@angular/core';

import { UserService } from './user.service';
import { wording } from 'src/app/wording/wording';
import { User } from '../models/user.model';

@Injectable()
export class LanguageService {
  currentLanguage: string = 'ger';

  languages: string[] = ['ger', 'eng'];
  readonly wording: any = wording;
  userData: User;
  constructor(private userService: UserService) {
    this.loadUser();
  }

  async loadUser() {
    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.userData = user;
        if (typeof this.userData.language === 'undefined') {
          this.currentLanguage = 'ger';
          this.userData.language = 'ger';
        } else {
          this.currentLanguage = this.userData.language;
        }
      }
    });
  }

  getCurrentLanguage() {
    return this.currentLanguage;
  }

  async setLanguage(lang: string) {
    this.userService.userDataSubject.subscribe(async (user) => {
      if (user) {
        this.userData = user;
        this.currentLanguage = lang;
        if (typeof this.userData !== 'undefined') {
          this.userData.language = lang;
        }
        await this.userService.setUser(user);
      }
    });
  }

  getWording(page: string, word: string) {
    let language = this.wording[this.currentLanguage];
    let langPage = language[page][word];
    return langPage;
  }
}
