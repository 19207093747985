import { Component, Input, OnInit } from '@angular/core';
import { Block, BlockStyle } from 'src/app/models/block.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() block: Block;
  @Input() style: BlockStyle;
  @Input() mobileView: boolean;

  constructor(private appService: AppService) {}

  ngOnInit(): void {}

  changed(event: any) {
    this.appService.setShouldSave();
  }

  getAlignment() {
    switch (this.style.contentAlignment) {
      case 'center':
        return 'text-center';
      case 'start':
        return 'text-left';
      case 'end':
        return 'text-right';
      default:
        return 'text-center';
    }
  }

  update() {
    const doc = document.getElementById(this.block.id);
    this.block.content = doc?.innerHTML;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentProject: {
            ...this.appService.state.editor.currentProject,
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }
}
