<section class="flex items-center w-full bg-white bg-transparent">
  <div
    class="w-full m-auto p-8 bg-white rounded-md overflow-auto h-full"
    ngxStripeCardGroup
    [elementsOptions]="elementsOptions"
  >
    <div class="mb-8 rounded-md">
      <img
        class="mx-auto h-28 object-cover"
        src="https://shuffle.dev/flex-ui-assets/images/dashboard/forms/card2.png"
        alt=""
      />
    </div>
    <p class="mb-8 text-xl font-semibold text-gray-900">Zahlungsdaten</p>
    <div class="flex flex-wrap -m-3 mb-8">
      <div class="w-full">
        <p class="font-medium text-base text-gray-800 mb-2">
          Name
        </p>

        <input
          type="text"
          [(ngModel)]="name"
          placeholder="Max Mustermann"
          class="w-full px-4 text-base placeholder:text-gray-300 text-gray-900 font-normal border-none focus:outline-none focus:border-none outline-none rounded-lg shadow"
        />
      </div>
    </div>
    <div class="flex flex-wrap -m-3 mb-8">
      <div class="w-full">
        <p class="font-medium text-base text-gray-800 mb-2">
          Straße und Hausnummer
        </p>

        <input
          type="text"
          [(ngModel)]="address"
          placeholder="Musterstrasse 123"
          class="w-full px-4 text-base placeholder:text-gray-300 text-gray-900 font-normal border-none focus:outline-none focus:border-none outline-none rounded-lg shadow"
        />
      </div>
    </div>

    <div class="flex justify-between space-x-8">
      <div class="flex flex-wrap -m-3 mb-8 w-full">
        <div class="w-full">
          <p class="font-medium text-base text-gray-800 mb-2">PLZ</p>

          <input
            type="text"
            [(ngModel)]="zip"
            placeholder="12345"
            class="w-full px-4 text-base placeholder:text-gray-300 text-gray-900 font-normal border-none focus:outline-none focus:border-none outline-none rounded-lg shadow"
          />
        </div>
      </div>

      <div class="flex flex-wrap -m-3 mb-8 w-full">
        <div class="w-full">
          <p class="font-medium text-base text-gray-800 mb-2">Stadt</p>

          <input
            type="text"
            [(ngModel)]="city"
            placeholder="Musterstadt"
            class="w-full px-4 text-base placeholder:text-gray-300 text-gray-900 font-normal border-none focus:outline-none focus:border-none outline-none rounded-lg shadow"
          />
        </div>
      </div>
    </div>

    <fieldset>
      <div class="flex flex-wrap -m-3 mb-4">
        <div class="w-full">
          <p class="font-medium text-base text-gray-800 -mb-4">Zahlungsart</p>

          <div class="flex justify-start gap-x-4 py-6">
            <!--
          Checked: "border-transparent", Not Checked: "border-gray-300"
          Active: "border-indigo-600 ring-2 ring-indigo-600"
        -->
            <label
              class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
              [ngClass]="{
                'border-indigo-600 ring-2 ring-indigo-600':
                  selectedPaymentMethod === 'card',
                'border-gray-300': selectedPaymentMethod !== 'card'
              }"
            >
              <input
                type="radio"
                name="payment"
                value="card"
                [(ngModel)]="selectedPaymentMethod"
                class="sr-only"
                aria-labelledby="project-type-0-label"
                aria-describedby="project-type-0-description-0 project-type-0-description-1"
              />
              <span class="flex flex-1">
                <span class="flex flex-col">
                  <span
                    id="project-type-0-label"
                    class="block text-sm font-medium text-gray-900"
                    >Kreditkarte</span
                  >
                </span>
              </span>
              <!-- Not Checked: "invisible" -->
              <svg
                [ngClass]="{
                  border: selectedPaymentMethod === 'visible',
                  invisible: selectedPaymentMethod !== 'card'
                }"
                class="h-5 w-5 text-indigo-600 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clip-rule="evenodd"
                />
              </svg>
              <!--
            Active: "border", Not Active: "border-2"
            Checked: "border-indigo-600", Not Checked: "border-transparent"
          -->
              <span
                class="pointer-events-none absolute -inset-px rounded-lg border-2"
                aria-hidden="true"
                [ngClass]="{
                  'border-indigo-600': selectedPaymentMethod === 'card',
                  'border-transparent': selectedPaymentMethod !== 'card'
                }"
              ></span>
            </label>
            <!--
          Checked: "border-transparent", Not Checked: "border-gray-300"
          Active: "border-indigo-600 ring-2 ring-indigo-600"
        -->
        <!--     <label
              class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
              [ngClass]="{
                'border-indigo-600 ring-2 ring-indigo-600':
                  selectedPaymentMethod === 'sepa',
                'border-gray-300': selectedPaymentMethod !== 'sepa'
              }"
            >
              <input
                type="radio"
                name="payment"
                [(ngModel)]="selectedPaymentMethod"
                value="sepa"
                class="sr-only"
                aria-labelledby="project-type-1-label"
                aria-describedby="project-type-1-description-0 project-type-1-description-1"
              />
              <span class="flex flex-1">
                <span class="flex flex-col">
                  <span
                    id="project-type-1-label"
                    class="block text-sm font-medium text-gray-900"
                    >SEPA Lastschrift</span
                  >
                </span>
              </span>
               <svg
                [ngClass]="{
                  border: selectedPaymentMethod === 'visible',
                  '  invisible': selectedPaymentMethod !== 'sepa'
                }"
                class="h-5 w-5 text-indigo-600 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clip-rule="evenodd"
                />
              </svg>
        
              <span
                [ngClass]="{
                  'border-indigo-600': selectedPaymentMethod === 'sepa',
                  'border-transparent': selectedPaymentMethod !== 'sepa'
                }"
                class="pointer-events-none absolute -inset-px rounded-lg border-2"
                aria-hidden="true"
              ></span>
            </label> -->
          </div>
        </div>
      </div>
    </fieldset>

    <div class="flex flex-wrap -m-3 mb-2">
      <div class="w-full">
        <p class="font-medium text-base text-gray-800 -mb-4">Kartennummer</p>

        <ngx-stripe-card-number
          [ngClass]="{
            hidden: selectedPaymentMethod !== 'card'
          }"
          class="w-full px-4 text-base text-gray-900 font-normal outline-none rounded-lg shadow-input"
          [options]="cardOptions"
        ></ngx-stripe-card-number>

     <!--    <ngx-stripe-iban
          [ngClass]="{
            hidden: selectedPaymentMethod !== 'sepa'
          }"
          class="w-full px-4 text-base text-gray-900 font-normal outline-none rounded-lg shadow-input"
          [options]="stripeIbanOptions"
         
          
        >
        </ngx-stripe-iban> -->
   
      </div>
    </div>
    <div
      class="flex justify-between space-x-8"
      [ngClass]="{
        invisible: selectedPaymentMethod !== 'card',
        visible: selectedPaymentMethod === 'card'
      }"
    >
      <div class="flex flex-wrap -m-3 mb-2 w-full">
        <div class="w-full">
          <p class="font-medium text-base text-gray-800 -mb-4">Prüfziffer</p>

          <ngx-stripe-card-cvc
            class="w-full px-4 text-base text-gray-900 font-normal outline-none rounded-lg shadow-input"
            [options]="cardOptions"
          ></ngx-stripe-card-cvc>
        </div>
      </div>
      <div
        class="flex flex-wrap -m-3 mb-2 w-full"
        [ngClass]="{
          invisible: selectedPaymentMethod !== 'card',
          visible: selectedPaymentMethod === 'card'
        }"
      >
        <div class="w-full">
          <p class="font-medium text-base text-gray-800 -mb-4">Ablaufdatum</p>

          <ngx-stripe-card-expiry
            class="w-full px-4 text-base text-gray-900 font-normal outline-none rounded-lg shadow-input"
            [options]="cardOptions"
          ></ngx-stripe-card-expiry>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap justify-end -m-2">
      <div class="w-full md:w-1/2 p-2">
        <button
          (click)="close()"
          class="flex flex-wrap justify-center w-full px-4 py-2.5 bg-white font-medium text-base text-gray-500 hover:text-gray-600 border border-gray-200 hover:border-gray-300 rounded-md shadow-button"
        >
          <p>abbrechen</p>
        </button>
      </div>
      <div class="w-full md:w-1/2 p-2">
        <button
          (click)="createToken()"
          class="flex flex-wrap justify-center w-full px-4 py-2.5 bg-green-500 hover:bg-green-600 font-medium text-base text-white border border-green-500 rounded-md shadow-button"
        >
          <div class="flex">
            <i
              *ngIf="loading"
              class="bi bi-arrow-clockwise animate-spin text-lg mr-2"
            ></i>
            <i *ngIf="!loading" class="bi bi-lock-fill mr-2"></i> abonnieren
          </div>
        </button>
      </div>
      <p
        *ngIf="showRequiredFields"
        class="text-red-400 border border-red-400 p-2 rounded w-full"
      >
        Bitte fülle alle Felder aus.
      </p>
    </div>
  </div>
</section>
