<app-info-bar *ngIf="showPaymentInfo"></app-info-bar>
<div class="h-full flex flex-col">
  <div class="flex h-full">
    <!-- Narrow sidebar -->

    <!-- Content area -->
    <div class="flex-1 flex flex-col overflow-hidden" *ngIf="user">
      <!-- Main content -->
      <div class="flex-1 flex items-stretch overflow-hidden">
        <main class="flex-1 overflow-y-auto bg-gray-100 py-6 px-12 relative">
          <h2
            class="text-2xl font-bold leading-7 text-kl-grey sm:text-3xl sm:truncate mb-6"
            i18n="@@userSettings_userSettingsHeadline_label"
          >
            Mein Account
          </h2>
          <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
              <nav class="space-y-1">
                <!-- Current: "bg-gray-50 text-kl-purple hover:bg-white", Default: "text-gray-900 hover:text-gray-900 hover:bg-gray-50" -->
                <a
                  (click)="setActiveTab(0)"
                  class="text-gray-500 hover:text-gray-900 group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
                  [ngClass]="{
                    'bg-white hover:bg-gray-50 text-kl-purple': activeTab === 0
                  }"
                >
                  <!--
                        Heroicon name: outline/user-circle
          
                        Current: "text-kl-purple", Default: "text-gray-400 group-hover:text-gray-500"
                      -->
                  <svg
                    class="text-gray-500 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                    [ngClass]="{
                      'text-kl-purple': activeTab === 0
                    }"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span
                    class="truncate"
                    i18n="@@userSettings_navigationGeneral_label"
                  >
                    Allgemein
                  </span>
                </a>
                <a
                  (click)="setActiveTab(1)"
                  class="text-gray-500 hover:text-gray-900 group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
                  [ngClass]="{
                    'bg-white hover:bg-gray-50 text-kl-purple': activeTab === 1
                  }"
                >
                  <!--
                      Heroicon name: outline/user-circle
        
                      Current: "text-kl-purple", Default: "text-gray-400 group-hover:text-gray-500"
                    -->

                  <i
                    class="bi bi-credit-card text-lg mr-3"
                    [ngClass]="{
                      'text-kl-purple': activeTab === 1
                    }"
                  ></i>
                  <span
                    class="truncate"
                    i18n="@@userSettings_navigationPayments_label"
                  >
                    Zahlungen
                  </span>
                </a>
              </nav>
            </aside>

            <!-- Payment details -->
            <div
              class="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 h-full mb-10"
              *ngIf="activeTab === 0"
            >
              <section aria-labelledby="payment-details-heading">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="bg-white py-6 px-4 sm:p-6">
                    <div>
                      <h2
                        id="payment-details-heading"
                        class="text-lg leading-6 font-medium text-gray-900"
                        i18n="@@userSettings_generalTabHeadline_label"
                      >
                        Allgemein
                      </h2>
                    </div>

                    <div class="mt-6 grid grid-cols-4 gap-6">
                      <div class="col-span-4 sm:col-span-2">
                        <label
                          for="first-name"
                          class="block text-sm font-medium text-gray-700"
                          i18n="@@userSettings_generalTabName_label"
                          >Name</label
                        >
                        <input
                          type="text"
                          [(ngModel)]="user.displayName"
                          [ngModelOptions]="{ standalone: true }"
                          placeholder="Dein Name"
                          class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                        />
                      </div>

                      <div class="col-span-4 sm:col-span-2">
                        <label
                          i18n="@@userSettings_generalTabRegisterDate_label"
                          for="last-name"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Registriert seit
                        </label>
                        <div
                          class="w-full bg-gray-200 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                        >
                          {{ user.registerDate | firestoreDate }}
                        </div>
                      </div>
                    </div>
                    <div class="mt-6 grid grid-cols-4 gap-6">
                      <div class="col-span-4 sm:col-span-2">
                        <label
                          i18n="@@userSettings_generalTabEmail_label"
                          for="first-name"
                          class="block text-sm font-medium text-gray-700"
                          >E-Mail</label
                        >
                        <input
                          type="email"
                          disabled
                          [(ngModel)]="user.email"
                          [ngModelOptions]="{ standalone: true }"
                          placeholder="Dein Name"
                          class="bg-gray-200 text-gray-900 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                        />
                      </div>

                <!--       <div class="col-span-4 sm:col-span-2">
                        <label
                          i18n="@@userSettings_generalTabLanguage_label"
                          for="last-name"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Sprache
                        </label>

                        <input
                          type="text"
                          disabled
                          [(ngModel)]="user.language"
                          [ngModelOptions]="{ standalone: true }"
                          class="bg-gray-200 text-gray-900 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                        />
                      </div> -->
                    </div>
                  </div>
                  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      i18n="@@userSettings_generalTabSave_button"
                      (click)="saveUser()"
                      class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    >
                      Speichern
                    </button>
                  </div>
                </div>
              </section>

              <section aria-labelledby="payment-details-heading">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="bg-white py-6 px-4 sm:p-6">
                    <div>
                      <h2
                        i18n="@@userSettings_generalTabDeleteAccount_label"
                        id="payment-details-heading"
                        class="text-lg leading-6 font-medium text-gray-900"
                      >
                        Account löschen
                      </h2>
                      <p
                        i18n="
                          @@userSettings_generalTabDeleteAccountWarning_label"
                        class="text-sm leading-6 font-medium text-gray-700 mt-2"
                      >
                        Willst Du Deinen Account und alle dazugehörigen Funnels
                        sowie deren Leads löschen?
                      </p>
                    </div>
                  </div>
                  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      i18n="@@userSettings_generalTabDeleteAccount_button"
                      (click)="deleteAccount()"
                      class="bg-red-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    >
                      Account löschen
                    </button>
                  </div>
                </div>
              </section>
            </div>

            <div
              class="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 h-full mb-10"
              *ngIf="activeTab === 1"
            >
              <section aria-labelledby="payment-details-heading">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="bg-white py-6 px-4 sm:p-6">
                    <div *ngIf="!showPricingTable">
                      <h2
                        i18n="@@userSettings_paymentsTabHeadline_label"
                        id="payment-details-heading"
                        class="text-lg leading-6 font-medium text-gray-900"
                      >
                        Zahlungen
                      </h2>
                    </div>

                    <!-- This example requires Tailwind CSS v2.0+ -->
                    <div class="bg-white mt-6" *ngIf="user">
                      <app-billing
                        *ngIf="!showPricingTable"
                        [user]="user"
                        (onShowPricingTable)="showPricingTable = true"
                      ></app-billing>
                      <app-pricing-table
                        (onClose)="onClosePricingTable()"
                        *ngIf="showPricingTable"
                        [user]="user"
                      ></app-pricing-table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</div>
