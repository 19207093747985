<div class="mt-8 overflow-auto h-full">
  <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex gap-x-4 w-full items-center my-12">
      <img src="./assets/klicklead-logo.svg" class="w-auto h-8" />

      <h1 class="text-3xl font-medium text-gray-900">- Dashboard</h1>
    </div>

    <h2 class="text-lg leading-6 font-medium text-gray-900">Überblick</h2>
    <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <!-- Card -->

      <div class="bg-white overflow-hidden shadow rounded-lg">
        <div class="p-5">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/scale -->
            </div>
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Leads (letzten 30 Tage)
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ leads.length }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white overflow-hidden shadow rounded-lg">
        <div class="p-5">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/scale -->
            </div>
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Leads (heute)
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ todayLeads.length }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white overflow-hidden shadow rounded-lg">
        <div class="p-5">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/scale -->
            </div>
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Leads (gestern)
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ yesterdayLeads.length }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white overflow-hidden shadow rounded-lg">
        <div class="p-5">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/scale -->
            </div>
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Leads (durchschnitt/Tag)
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ leads.length / 30 | number: "1.0-2" }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <!-- More items... -->
    </div>
  </div>

  <h2
    class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8"
  >
    Funnels
  </h2>

  <!-- Activity table (small breakpoint and up) -->
  <div class="overflow-auto">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col mt-2">
        <div
          class="align-middle w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200 overflow-auto">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Funnel
                </th>
                <th
                  class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Leads (letzten 30 Tage)
                </th>

                <th
                  class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Leads (heute)
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 overflow-auto">
              <tr class="bg-white" *ngFor="let funnel of funnelsByLeads">
                <td
                  class="max-w-0 w-1/3 px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                >
                  <div class="flex">
                    <a
                      [href]="'https://app.klicklead.de/' + funnel.projectId"
                      target="_blank"
                      class="group inline-flex space-x-2 truncate text-sm"
                    >
                      <!-- Heroicon name: solid/cash -->

                      <p
                        class="text-gray-500 truncate group-hover:text-gray-900"
                      >
                        {{ funnel.name }}
                      </p>
                    </a>
                  </div>
                </td>
                <td
                  class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                >
                  <span class="text-gray-900 font-medium"
                    >{{ funnel.count }}
                  </span>
                </td>

                <td
                  class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                >
                  <span class="text-gray-900 font-medium"
                    >{{ funnel.countToday }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <h2
    class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8"
  >
    Neusten Leads
  </h2>

  <!-- Activity table (small breakpoint and up) -->
  <div class="overflow-auto">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col mt-2">
        <div
          class="align-middle w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200 overflow-auto">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Lead-Id
                </th>
                <th
                  class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Funnel
                </th>

                <th
                  class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Datum
                </th>
              </tr>
            </thead>
            <tbody
              class="bg-white divide-y divide-gray-200 overflow-auto"
              *ngIf="showTable"
            >
              <tr class="bg-white" *ngFor="let lead of leads">
                <td
                  class="max-w-0 w-1/3 px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                >
                  <div class="flex">
                    <a
                      href="#"
                      class="group inline-flex space-x-2 truncate text-sm"
                    >
                      <!-- Heroicon name: solid/cash -->

                      <p
                        class="text-gray-500 truncate group-hover:text-gray-900"
                      >
                        {{ lead.id }}
                      </p>
                    </a>
                  </div>
                </td>
                <td
                  class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                >
                  <span class="text-gray-900 font-medium"
                    >{{ getFunnelName(lead.projectId) }}
                  </span>
                </td>

                <td
                  class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                >
                  <time datetime="2020-07-11">{{
                    lead.date.toDate() | date: "yyyy/MM/dd h:mm:ss a"
                  }}</time>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
