import { Component, Input, OnInit } from '@angular/core';
import { Block, BlockStyle } from 'src/app/models/block.model';
import { AppService } from 'src/app/services/app.service';
import { BlockService } from 'src/app/services/block.service';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss'],
})
export class SelectionComponent implements OnInit {
  @Input() block: Block;
  @Input() style: BlockStyle;
  @Input() mobileView: boolean;
  @Input() blocks: Block[];
  @Input() blockIndex: number;

  itemIndex: number = -1;
  constructor(
    private blockService: BlockService,
    private appService: AppService
  ) {}

  ngOnInit(): void {}

  getSelectionStyle(preview?: boolean) {
    return this.blockService.getSelectionStyle(
      this.blocks,
      this.blockIndex,
      preview
    );
  }

  selectAnswer(index: number) {
    console.log(index);
    this.itemIndex = index;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentSelectionIndex: index,
        },
      },
      false
    );
  }

  changed(event: any) {
    this.block.content.answers[this.itemIndex].answerText =
      event.target.innerText;
    this.appService.setShouldSave(true);
  }
}
