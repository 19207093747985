<aside style="height: calc(100vh - 50px)" class="h-full">
  <div
    (click)="toggleSideBarDetail()"
    class="z-50 flex justify-start items-start absolute top-1/2 -right-4 bg-kl-grey rounded-r-full p-1 cursor-pointer"
    [ngClass]="{
      'bg-kl-grey': !isSidebarDetailOpen,
      'bg-white': isSidebarDetailOpen
    }"
  >
    <i *ngIf="!isSidebarDetailOpen" class="bi bi-chevron-right text-white"></i>
    <i *ngIf="isSidebarDetailOpen" class="bi bi-chevron-left"></i>
  </div>
  <div
    *ngIf="isSidebarDetailOpen"
    class="w-96 bg-white h-full border-l border-gray-200 overflow-y-auto lg:block p-4"
  >
    <app-details-blocks *ngIf="sidebarIndex === 0"></app-details-blocks>
    <app-details-content
      [selectedElement]="selectedElement"
      *ngIf="sidebarIndex === 1"
    ></app-details-content>
    <!--     <app-details-mediathek *ngIf="sidebarIndex === 2"></app-details-mediathek>
 -->
    <app-details-page *ngIf="sidebarIndex === 2"></app-details-page>
  </div>
</aside>
