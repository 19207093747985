import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { StripeDataService } from 'src/app/services/stripe-data.service';

@Component({
  selector: 'app-user-dashboard-invoices',
  templateUrl: './user-dashboard-invoices.component.html',
  styleUrls: ['./user-dashboard-invoices.component.scss'],
})
export class UserDashboardInvoicesComponent implements OnInit {
  @Input() user: User;
  invoices: any[] = [];

  constructor(private stripeDataService: StripeDataService) {}

  ngOnInit(): void {
    this.loadInvoices();
  }

  async loadInvoices() {
    if (this.user.stripeCustomerId) {
      const invoiceRequest: any = await this.stripeDataService.loadInvoices(
        this.user.stripeCustomerId!
      );
      this.invoices = invoiceRequest.data;
    }
  }
}
