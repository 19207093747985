import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-page-details-sidebar',
  templateUrl: './page-details-sidebar.component.html',
  styleUrls: ['./page-details-sidebar.component.scss'],
})
export class PageDetailsSidebarComponent implements OnInit, OnDestroy {
  currentProject$: Subscription;
  state$: Subscription;
  currentProject: Project;
  currentPageIndex: number = 0;
  pageName: string = '';
  facebookEvents: any[] = [
    'AddPaymentInfo',
    'AddToCart',
    'AddToWishlist',
    'CompleteRegistration',
    'Contact',
    'CustomizeProduct',
    'Donate',
    'FindLocation',
    'InitiateCheckout',
    'Lead',
    'Purchase',
    'Schedule',
    'Search',
    'StartTrial',
    'SubmitApplication',
    'Subscribe',
    'ViewContent',
  ];

  projectRedoArray: Project[] = [];
  projectUndoArray: Project[] = [];
  projectCurrentArray: Project[] = [];
  initialUndo: boolean = true;
  initialProject: Project;

  showUndo: boolean = false;
  showRedo: boolean = false;
  constructor(
    public projectService: ProjectService,
    public dialog: MatDialog,
    private appService: AppService
  ) {
    this.currentProject$ = this.projectService.currentProjectSubject.subscribe(
      (project: Project) => {
        if (project) {
          this.currentProject = project;
        }
      }
    );

    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
      /*   this.currentPageIndex = state.editor.currentPageIndex;
        this.projectRedoArray = state.history.projectRedoArray;
        this.projectUndoArray = state.history.projectUndoArray;
        this.projectCurrentArray = state.history.projectCurrentArray;
        this.showUndo = state.history.showUndo;
        this.showRedo = state.history.showRedo;
        this.initialUndo = state.history.initialUndo; */
      }
    });

   /*  this.currentPageIndex = this.appService.state.editor.currentPageIndex; */
  }

  ngOnInit(): void {
    this.initialProject = JSON.parse(JSON.stringify(this.currentProject));
    if (!this.initialProject) return;
    if (this.projectUndoArray.length == 0) {
      this.projectUndoArray.push(this.initialProject);
    }
  }

  setHistory() {
    this.projectRedoArray = [];
    this.showRedo = true;

    if (this.projectUndoArray.length == 0) {
      this.projectUndoArray.push(this.initialProject);
    }

    if (this.projectCurrentArray.length !== 0) {
      const cur_pro_pop = JSON.parse(
        JSON.stringify(this.projectCurrentArray.pop())
      );
      if (!cur_pro_pop) return;
      this.projectUndoArray.push(cur_pro_pop);
    } else {
      const cur_pro_pop = JSON.parse(JSON.stringify(this.currentProject));
      if (!cur_pro_pop) return;

      this.projectUndoArray.push(cur_pro_pop);
    }

    const cur_pro = JSON.parse(JSON.stringify(this.currentProject));
    let test = JSON.parse(JSON.stringify(cur_pro));
    this.projectCurrentArray.push(test);
    if (this.projectUndoArray.length == 0) {
      this.showUndo = false;
    } else {
      this.showUndo = true;
    }
    // }

    this.initialUndo = true;

    this.projectService.currentProjectSubject.next(this.projectCurrentArray[0]);
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,

          html: this.projectService.currentProjectSubject.getValue().pages[
            this.currentPageIndex
          ].blocks,
        },
      /*   history: {
          ...this.appService.state.history,
          projectRedoArray: this.projectRedoArray,
          projectUndoArray: this.projectUndoArray,
          projectCurrentArray: this.projectCurrentArray,
          showUndo: this.showUndo,
          showRedo: this.showRedo,
          initialUndo: this.initialUndo,
        }, */
      },
      true
    );
  }

  setFBEvent(event: any) {
    this.currentProject.pages[this.currentPageIndex].fbEvent =
      event.target.value;
    this.projectService.currentProjectSubject.next(this.currentProject);
    this.saveProject();
  }

  saveProject() {
    this.setHistory();
    //this.projectService.saveCurrentProject(this.currentProject);
  }
  ngOnDestroy() {}
}
