<div class="flex flex-col justify-between w-full p-4">
  <h1 class="text-2xl font-bold mb-4">{{ data.title }}</h1>
  <div>
    {{ data.text }}
  </div>
  <div class="flex items-center justify-between mt-8">
    <button (click)="onNoClick()">Abbrechen</button>
    <button
      color="warn"
      [mat-dialog-close]="true"
      class="text-white bg-red-500 hover:bg-red-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3"
    >
      Entfernen
    </button>
  </div>
</div>
