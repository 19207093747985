<aside>
  <nav class="space-y-1">
    <!-- Current: "bg-gray-50 text-kl-purple hover:bg-white", Default: "text-gray-900 hover:text-gray-900 hover:bg-gray-50" -->
    <div
      class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      (click)="setNavigation(0)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 0
      }"
    >
      <!--
                Heroicon name: outline/user-circle
  
                Current: "text-kl-purple", Default: "text-gray-400 group-hover:text-gray-500"
              -->
      <svg
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 0
        }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <span class="truncate" i18n="@@funnelLeadsNavigation_statistics_label">
        Statistik
      </span>
    </div>

    <div
      class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      (click)="setNavigation(1)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 1
      }"
    >
      <!-- Heroicon name: outline/cog -->
      <svg
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 1
        }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
      <span class="truncate" i18n="@@funnelLeadsNavigation_leads_label">
        Leads
      </span>
    </div>

    <div
      class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      (click)="setNavigation(2)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 2
      }"
    >
      <!-- Heroicon name: outline/key -->
      <svg
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 2
        }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
        />
      </svg>
      <span
        class="truncate"
        i18n="@@funnelLeadsNavigation_partialEntries_label"
      >
        Teileinträge
      </span>
    </div>
  </nav>
</aside>
