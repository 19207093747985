import { Component, Input, OnInit } from '@angular/core';
import { SelectAnswer } from 'src/app/models/block.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-details-selection-items',
  templateUrl: './details-selection-items.component.html',
  styleUrls: ['./details-selection-items.component.scss'],
})
export class DetailsSelectionItemsComponent implements OnInit {
  isOpenIndex: number = -1;

  @Input() items: any = [];

  currentItemIndex: number = -1;
  constructor(private appService: AppService) {}

  ngOnInit(): void {}

  openItem(index: number) {
    this.isOpenIndex = index;
  }

  removeItem(index: number) {
    this.items.splice(index, 1);
  }

  updateItem(answerText: string, index: number) {
    this.items[index].answerText = answerText;
    this.appService.setShouldSave(true);
  }

  addItem() {
    let answer: SelectAnswer = {
      answerText: 'Antwort ' + (this.items.length + 1),
      checked: false,
    };

    this.items.push(answer);
  }

  moveUp(index: number) {
    if (index > 0) {
      let item = this.items[index];
      this.items.splice(index, 1);
      this.items.splice(index - 1, 0, item);
    }
    this.appService.setShouldSave(true);
  }

  moveDown(index: number) {
    if (index < this.items.length - 1) {
      let item = this.items[index];
      this.items.splice(index, 1);
      this.items.splice(index + 1, 0, item);
    }
    this.appService.setShouldSave(true);
  }
}
