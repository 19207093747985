import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Block, InputField } from 'src/app/models/block.model';
import { AnswerSubmission, Page } from 'src/app/models/project.model';
import { LanguageService } from 'src/app/services/lang.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-leads-partial-entries',
  templateUrl: './leads-partial-entries.component.html',
  styleUrls: ['./leads-partial-entries.component.scss'],
})
export class LeadsPartialEntriesComponent implements OnInit {
  displayedColumns: string[] = [
    'Nr.',
    this.langService.getWording('funnelStatistics', 'tableDate'),
  ];
  displayedColumnsPartial: string[] = [
    'Nr.',
    this.langService.getWording('funnelStatistics', 'tableDate'),
  ];
  answerSubmissions: AnswerSubmission[] = [];
  form: any = { pageId: null, form: null };
  dataPartial: any[] = [];
  projectId: string;
  startIndex: number = 0;
  lastDoc: any = null;
  constructor(
    public langService: LanguageService,
    private projectService: ProjectService,
    private route: ActivatedRoute
  ) {
    this.projectId = this.route.snapshot.params.id;
  }

  async ngOnInit() {
    if (!this.projectService.currentProject) return;
    this.projectService.currentProject.pages.forEach((p: Page) => {
      this.displayedColumnsPartial.push(p.name);
      p.blocks.forEach((block: Block) => {
        if (block.type === 'form') {
          this.form.form = block.content['inputs'];
          this.form.pageId = p.id.toString();
        }
      });
    });

    if (this.form.form !== null) {
      this.form.form.forEach((inp: InputField) => {
        this.displayedColumns.push(inp.text);
      });
    }

    this.displayedColumns.push('Aktion');
    const subAnsObj:any= await this.projectService.getSubmissionAnswers(
      this.projectId
    );
    subAnsObj.forEach((element: AnswerSubmission) => {
      this.answerSubmissions.push(element);
    });
    //this.lastDoc = subAnsObj.last;

    console.log(this.answerSubmissions);
    console.log(this.lastDoc);

    this.answerSubmissions.forEach(
      (element: AnswerSubmission, index: number) => {
        let answerObj = {};
        // if (element.submissions.length !== this.projectService.currentProject.pages.length) {
        element.submissions.forEach((sub: any, index: number) => {
          if (!this.projectService.currentProject) return;
          let page = this.projectService.currentProject.pages.find(
            (p) => p.id.toString() === sub.pageId.toString()
          );

          if (typeof page !== 'undefined') {
            if (Array.isArray(sub.answer)) {
              let formData: any[] = [];
              sub.answer.forEach((inp: InputField) => {
                if (inp.value !== null) {
                  formData.push(inp.text + ': ' + inp.value);
                }
              });
              Object.assign(answerObj, { [page.name]: formData.join() });
            } else {
              let answer: string = '';

              if (sub.answer !== null) {
                answer = sub.answer.answerText
                  ? sub.answer.answerText
                  : this.truncate(sub.answer, 12);
              }

              Object.assign(answerObj, {
                [page.name]:
                  sub.answer !== null ? this.truncate(answer, 12) : '-',
              });
            }
          } else {
            if (Array.isArray(sub.answer)) {
              let formData: any[] = [];
              sub.answer.forEach((inp: InputField) => {
                if (inp.value !== null) {
                  formData.push(inp.text + ': ' + inp.value);
                }
              });
              Object.assign(answerObj, {
                ['gelöschte Seite' + index]: formData.join(),
              });
            } else {
              let answer: string = '';

              if (sub.answer !== null) {
                answer = sub.answer.answerText
                  ? sub.answer.answerText
                  : this.truncate(sub.answer, 12);
              }
              Object.assign(answerObj, {
                ['gelöschte Seite' + index]:
                  sub.answer !== null ? this.truncate(answer, 12) : '-',
              });
            }
          }
        });
        let dateString = this.langService.getWording(
          'funnelStatistics',
          'tableDate'
        );

        Object.assign(answerObj, {
          [dateString.toString()]: element.date,
        });

        this.dataPartial.push(answerObj);
        //  }
      }
    );
  }

  truncate(str: string, n: number) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  }

  async loadMoreEntries() {
    const subAnsObj: any = await this.projectService.getSubmissionAnswers(
      this.projectId
    );
    this.answerSubmissions = [];
    subAnsObj.answerSubmissions.forEach((element: AnswerSubmission) => {
      this.answerSubmissions.push(element);
    });
    //this.lastDoc = subAnsObj.last;
    console.log(this.answerSubmissions);
    console.log(this.lastDoc);

    this.answerSubmissions.forEach(
      (element: AnswerSubmission, index: number) => {
        let answerObj = {};
        // if (element.submissions.length !== this.projectService.currentProject.pages.length) {
        element.submissions.forEach((sub: any, index: number) => {
          if (!this.projectService.currentProject) return;
          let page = this.projectService.currentProject.pages.find(
            (p) => p.id.toString() === sub.pageId.toString()
          );

          if (typeof page !== 'undefined') {
            if (Array.isArray(sub.answer)) {
              let formData: any[] = [];
              sub.answer.forEach((inp: InputField) => {
                if (inp.value !== null) {
                  formData.push(inp.text + ': ' + inp.value);
                }
              });
              Object.assign(answerObj, { [page.name]: formData.join() });
            } else {
              let answer: string = '';

              if (sub.answer !== null) {
                answer = sub.answer.answerText
                  ? sub.answer.answerText
                  : this.truncate(sub.answer, 12);
              }

              Object.assign(answerObj, {
                [page.name]:
                  sub.answer !== null ? this.truncate(answer, 12) : '-',
              });
            }
          } else {
            if (Array.isArray(sub.answer)) {
              let formData: any[] = [];
              sub.answer.forEach((inp: InputField) => {
                if (inp.value !== null) {
                  formData.push(inp.text + ': ' + inp.value);
                }
              });
              Object.assign(answerObj, {
                ['gelöschte Seite' + index]: formData.join(),
              });
            } else {
              let answer: string = '';

              if (sub.answer !== null) {
                answer = sub.answer.answerText
                  ? sub.answer.answerText
                  : this.truncate(sub.answer, 12);
              }
              Object.assign(answerObj, {
                ['gelöschte Seite' + index]:
                  sub.answer !== null ? this.truncate(answer, 12) : '-',
              });
            }
          }
        });
        let dateString = this.langService.getWording(
          'funnelStatistics',
          'tableDate'
        );

        Object.assign(answerObj, {
          [dateString.toString()]: element.date,
        });

        this.dataPartial.push(answerObj);
        //  }
      }
    );
  }
}
