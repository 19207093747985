<div
  class="mt-4 flex items-center flex-wrap gap-8 overflow-auto ml-4"
  #editor="cdkDropList"
  cdkDropList
  [cdkDropListData]="blockList"
  cdkDropListConnectedTo="editor"
  (cdkDropListExited)="onSourceListExited($event)"
  (cdkDropListEntered)="onSourceListEntered()"
>
  <div
    class="flex flex-col items-center justify-between p-3 rounded-lg border w-1/4 h-24 group bg-white hover:bg-kl-grey border-gray-300 cursor-pointer"
    *ngFor="let block of blockList"
    cdkDrag
    [cdkDragData]="block"
  >
    <i
      [ngClass]="getBlockIcon(block.type)"
      class="text-2xl group-hover:text-white"
    ></i>
    <div class="text-xs text-center group-hover:text-white break-words">
      {{ getBlockName(block.type) }}
    </div>
  </div>
</div>

<!-- <smooth-dnd-container
  [behaviour]="'copy'"
  [groupName]="'1'"
  [getChildPayload]="getChildPayload1"
>
  <smooth-dnd-draggable *ngFor="let item of blockList">
    <div class="draggable-item">
      {{ item.type }}
    </div>
  </smooth-dnd-draggable>
</smooth-dnd-container>
 -->
