<section aria-labelledby="payment-details-heading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div
      class="bg-white py-6 px-4 sm:p-6 relative"
      [ngClass]="{
        'bg-gray-300 rounded-lg cursor-not-allowed': !embedEnabled,
        'opacity-100': embedEnabled
      }"
    >
      <i
        class="bi bi-lock-fill text-kl-purple text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-50"
        *ngIf="!embedEnabled"
      ></i>
      <button
        *ngIf="!embedEnabled"
        class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-14 z-50"
        (click)="openPaymentPage()"
        i18n="@@funnelSettingsEmbed_upgradeNow_button"
      >
        Jetzt upgraden
      </button>
      <div>
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
          i18n="@@funnelSettingsEmbed_headline_label"
        >
          Einbetten
        </h2>
      </div>

      <div class="mt-6 grid grid-cols-0 gap-6">
        <div class="col-span-12 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsEmbed_copyScriptInstruction_label"
            >Kopiere diesen Code an die gewünschte Stelle auf Deiner
            Webseite</label
          >
          <div class="flex items-center">
            <textarea
              rows="8"
              disabled
              readonly
              [value]="iframeCode"
              disabled
              readonly
              class="mt-1 block w-full border bg-gray-200 cursor-not-allowed border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            ></textarea>
            <a class="ml-4" (click)="copyIFrameCode()">
              <i class="bi bi-clipboard cursor-pointer"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
