<section aria-labelledby="payment-details-heading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 sm:p-6">
      <div>
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
          i18n="@@funnelSettingsTracking_headline_label"
        >
          Tracking
        </h2>
      </div>

      <div class="mt-6 grid grid-cols-4 gap-6">
        <div
          class="col-span-4 sm:col-span-2 relative p-2"
          [ngClass]="{
            'bg-gray-300 rounded-lg cursor-not-allowed': !facebookPixelRights,
            'opacity-100': facebookPixelRights
          }"
        >
          <!--    <i
            class="bi bi-lock-fill text-kl-purple text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-50"
            *ngIf="!facebookPixelRights"
          ></i> -->

          <button
            *ngIf="!facebookPixelRights"
            class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
            (click)="openPaymentPage()"
            i18n="@@funnelSettingsTracking_upgradNow_button"
          >
            <i class="bi bi-lock-fill text-white"></i> Jetzt upgraden
          </button>

          <label
            [ngClass]="{
              'opacity-50 z-0': !facebookPixelRights,
              'opacity-100': facebookPixelRights
            }"
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsTracking_facebookPixelId_label"
            >Facebook Pixel-ID</label
          >
          <input
            [ngClass]="{
              'opacity-50 z-0': !facebookPixelRights,
              'opacity-100': facebookPixelRights
            }"
            [disabled]="!facebookPixelRights"
            type="text"
            [(ngModel)]="currentProject.fbPixelId"
            placeholder="Facebook Pixel ID"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
          <p
            *ngIf="currentProject.fbPixelId"
            class="text-sm text-gray-400 mt-1"
          >
            Damit das Tracking funktioniert, wird automatisch die Cookie-Meldung
            angezeigt
          </p>
        </div>

        <div
          class="col-span-4 sm:col-span-2 relative p-2"
          [ngClass]="{
            'bg-gray-300 rounded-lg cursor-not-allowed': !googleRights,
            'opacity-100': googleRights
          }"
        >
          <!--   <i
            class="bi bi-lock-fill text-kl-purple text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-50"
            *ngIf="!googleRights"
          ></i> -->
          <button
            *ngIf="!googleRights"
            class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
            (click)="openPaymentPage()"
            i18n="@@funnelSettingsTracking_upgradeNow_label"
          >
            <i class="bi bi-lock-fill text-white"></i> Jetzt upgraden
          </button>
          <label
            [ngClass]="{
              'opacity-50 z-0': !googleRights,
              'opacity-100': googleRights
            }"
            for="last-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsTracking_googleAnalytics_label"
            >Google Analytics Tracking-ID</label
          >

          <input
            [ngClass]="{
              'opacity-50 z-0': !googleRights,
              'opacity-100': googleRights
            }"
            type="text"
            [disabled]="!googleRights"
            [(ngModel)]="currentProject.googleAnalyticsId"
            placeholder="UA-"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
          <p
            *ngIf="currentProject.googleAnalyticsId"
            class="text-sm text-gray-400 mt-1"
          >
            Damit das Tracking funktioniert, wird automatisch die Cookie-Meldung
            angezeigt
          </p>
        </div>
      </div>

      <div class="mt-6 grid grid-cols-4 gap-6">
        <div
          class="col-span-4 sm:col-span-2 relative p-2"
          [ngClass]="{
            'bg-gray-300 rounded-lg cursor-not-allowed':
              !multipleEntriesEnabled,
            'opacity-100': multipleEntriesEnabled
          }"
        >
          <!--   <i
            class="bi bi-lock-fill text-kl-purple text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-50"
            *ngIf="!multipleEntriesEnabled"
          ></i> -->

          <button
            *ngIf="!multipleEntriesEnabled"
            class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
            (click)="openPaymentPage()"
            i18n="@@funnelSettingsTracking_upgradeNow_label"
          >
            <i class="bi bi-lock-fill text-white"></i> Jetzt upgraden
          </button>
          <div class="relative flex items-start">
            <div class="flex items-center h-5">
              <input
                [ngClass]="{
                  'opacity-50 z-0': !multipleEntriesEnabled,
                  'opacity-100': multipleEntriesEnabled
                }"
                [disabled]="!multipleEntriesEnabled"
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                [(ngModel)]="currentProject.oneAnswerSubmissionPerUser"
                class="h-4 w-4 text-kl-purple border-gray-300 rounded"
              />
            </div>
            <div
              class="ml-3 text-sm"
              [ngClass]="{
                'opacity-50 z-0': !multipleEntriesEnabled,
                'opacity-100': multipleEntriesEnabled
              }"
            >
              <label
                for="comments"
                class="font-medium text-gray-700"
                i18n="@@funnelSettingsTracking_oneAnswerPerUser_label"
                >Eine Antwort pro Benutzer</label
              >
              <p
                id="comments-description"
                class="text-gray-400"
                i18n="
                  @@funnelSettingsTracking_oneAnswerPerUserDescription_label"
              >
                Jeder Benutzer kann den Funnel nur einmal ausfüllen.
              </p>
            </div>
          </div>
        </div>

        <div
          class="col-span-4 sm:col-span-2 relative p-2"
          [ngClass]="{
            'bg-gray-300 rounded-lg cursor-not-allowed': !googleRights,
            'opacity-100': googleRights
          }"
        >
          <!--   <i
            class="bi bi-lock-fill text-kl-purple text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-50"
            *ngIf="!googleRights"
          ></i> -->
          <button
            *ngIf="!googleRights"
            class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
            (click)="openPaymentPage()"
            i18n="@@funnelSettingsTracking_upgradeNow_label"
          >
            <i class="bi bi-lock-fill text-white"></i> Jetzt upgraden
          </button>
          <label
            [ngClass]="{
              'opacity-50 z-0': !googleRights,
              'opacity-100': googleRights
            }"
            for="last-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsTracking_googleAnalytics_label"
            >Google Analytics 4 Tracking-ID</label
          >

          <input
            [ngClass]="{
              'opacity-50 z-0': !googleRights,
              'opacity-100': googleRights
            }"
            type="text"
            [disabled]="!googleRights"
            [(ngModel)]="currentProject.googleAnalytics4Id"
            placeholder="G-"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
          <p
            *ngIf="currentProject.googleAnalytics4Id"
            class="text-sm text-gray-400 mt-1"
          >
            Damit das Tracking funktioniert, wird automatisch die Cookie-Meldung
            angezeigt
          </p>
        </div>
      </div>
      <div class="mt-6 grid grid-cols-4 gap-6 mb-36">
        <div class="col-span-4 sm:col-span-2">
          <!-- This example requires Tailwind CSS v2.0+ -->
          <div>
            <label
              id="listbox-label"
              class="block text-sm font-medium text-gray-700"
              i18n="@@funnelSettingsTracking_startTrackingAtPageHeadline_label"
            >
              Beginne das Tracking ab Seite
            </label>
            <div class="mt-1 relative">
              <button
                (click)="togglePagesDropdown()"
                type="button"
                class="bg-white z-50 relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none cursor-pointer sm:text-sm"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
              >
                <span class="block truncate"
                  >{{
                    currentProject.startAnalyticsPageIndex
                      ? currentProject.pages[
                          currentProject.startAnalyticsPageIndex
                        ].name
                      : currentProject.pages[0].name
                  }}
                </span>
                <span
                  class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                >
                  <!-- Heroicon name: solid/selector -->
                  <svg
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>

              <ul
                *ngIf="showTrackingPageSelect"
                class="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                tabindex="-1"
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-option-3"
              >
                <li
                  class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                  id="listbox-option-0"
                  role="option"
                  (click)="setAnalyticsStartPage(i)"
                  *ngFor="let page of currentProject.pages; let i = index"
                >
                  <span class="font-normal block truncate">{{
                    page.name
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6 grid grid-cols-4 gap-6">
        <div
          class="col-span-4 sm:col-span-2 relative p-2"
          [ngClass]="{
            'bg-gray-300 rounded-lg cursor-not-allowed': !partialEntriesEnabled,
            'opacity-100': partialEntriesEnabled
          }"
        >
          <!-- <i
            class="bi bi-lock-fill text-kl-purple text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-50"
            *ngIf="!partialEntriesEnabled"
          ></i> -->

          <button
            *ngIf="!partialEntriesEnabled"
            class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
            (click)="openPaymentPage()"
            i18n="@@funnelSettingsTracking_upgradeNow_label"
          >
            <i class="bi bi-lock-fill text-white"></i> Jetzt upgraden
          </button>
          <div class="relative flex items-start">
            <div class="flex items-center h-5">
              <input
                [ngClass]="{
                  'opacity-50 z-0': !partialEntriesEnabled,
                  'opacity-100': partialEntriesEnabled
                }"
                [disabled]="!partialEntriesEnabled"
                id="comments2"
                aria-describedby="comments-description2"
                name="comments2"
                type="checkbox"
                [(ngModel)]="currentProject.partialEntriesEnabled"
                class="h-4 w-4 text-kl-purple border-gray-300 rounded"
              />
            </div>
            <div
              class="ml-3 text-sm"
              [ngClass]="{
                'opacity-50 z-0': !partialEntriesEnabled,
                'opacity-100': partialEntriesEnabled
              }"
            >
              <label
                for="comments2"
                class="font-medium text-gray-700"
                i18n="@@funnelSettingsTracking_partialEntries_label"
                >Teileinträge</label
              >
              <p
                id="comments-description2"
                class="text-gray-400"
                i18n="@@funnelSettingsTracking_partialEntriesDescription_label"
              >
                Erhalte alle Antworten von Benutzern die den Funnel nicht
                komplett ausgefüllt haben.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="px-4 py-3 mt-6 text-right sm:px-6">
        <button
          (click)="saveProject()"
          class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900"
          i18n="@@funnelSettingsTracking_save_button"
        >
          Speichern
        </button>
      </div>
    </div>
  </div>
</section>
