import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UnsplashService } from 'src/app/services/unsplash.service';

@Component({
  selector: 'app-details-unsplash',
  templateUrl: './details-unsplash.component.html',
  styleUrls: ['./details-unsplash.component.scss'],
})
export class DetailsUnsplashComponent implements OnInit {
  unsplashImages: any[] = [];
  unsplashImagesLoader: boolean = false;

  searchString: string = '';
  @Output() onSelectImage = new EventEmitter<string>();
  constructor(
    private unsplashService: UnsplashService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.loadRandomImages();
  }

  async loadRandomImages() {
    this.unsplashImagesLoader = true;
    this.unsplashImages = await this.unsplashService.getRandomImages();
    console.log(this.unsplashImages);
    this.unsplashImagesLoader = false;
  }

  async search() {
    this.unsplashImagesLoader = true;
    if (this.searchString.length > 0) {
      this.unsplashImages = await this.unsplashService.searchImages(
        this.searchString
      );
      console.log(this.unsplashImages);
    }
    this.unsplashImagesLoader = false;
  }

  async selectPicture(url: string, downloadUrl: string) {
    const download = await this.unsplashService.trackDownload(
      downloadUrl
    );

    console.log(download);

    this.onSelectImage.emit(url);
  }
}
