import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/enums/notification-type';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class NotificationComponent implements OnInit {
  state$: Subscription;

  notificationDescription: string;
  notificationMessage: string;
  notificationType: NotificationType;
  showNotification: boolean = false;
  notificationTimeout: number;
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.notificationDescription =
          state.notification.notificationDescription;
        this.notificationMessage = state.notification.notificationMessage;
        this.notificationType = state.notification.notificationType;
        this.showNotification = state.notification.showNotification;
      }
    });
  }

  closeNotification() {
    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: false,
      },
    });
  }
}
