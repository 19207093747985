import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { ProjectService } from 'src/app/services/project.service';
import { LanguageService } from 'src/app/services/lang.service';
import { Project } from 'src/app/models/project.model';
import { AppService } from 'src/app/services/app.service';
import { MailMarketingService } from 'src/app/services/mail-marketing.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    /*  name: new FormControl('', [
       Validators.required,
     ]), */
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
    passwordSecond: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
    companyName: new FormControl('', [Validators.required]),
  });
  pass: boolean = false;

  isEmailValid: boolean | null = null;
  errors: boolean = false;
  errorText: string;
  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private projectService: ProjectService,
    public langService: LanguageService,
    private route: ActivatedRoute,
    private appService: AppService,
    private mailMarketingService: MailMarketingService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.projectService.currentProject = <Project>{};
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.form.controls['email'].setValue(params['email']);
    });

    this.appService.showHeaderSubject.next(false);

    this.googleAnalyticsService.loadGoogleAnalyticsScript();
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  validateEmail = (email: string) => {
    return !!email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  checkIsEmailValid() {
    this.isEmailValid = this.validateEmail(this.form.value.email);
  }

  submit() {
    if (this.form.value.password !== this.form.value.passwordSecond) {
      this.errors = true;
      this.errorText = 'Passwörter stimmen nicht überein';

      return;
    }

    if (this.form.valid) {
      this.authService
        .signUp({
          email: this.form.value.email,
          password: this.form.value.password,
        })
        .then(async (u) => {
          if (!u.user) return;

          const emailAdress = u.user.email ? u.user.email : '';

          const invitedUser = await this.checkIfUserWasInvited(emailAdress);

          if (invitedUser) {
            const registeredUser: User = {
              displayName: u.user.displayName ? u.user.displayName : '',
              email: emailAdress,
              id: u.user.uid,
              funnelIds: invitedUser.funnelIds,

              language: 'ger',
              registerDate: new Date(),
              showOnboarding: true,
              receiptEmail: emailAdress,
            };

            this.userService.setUserDataSubject(registeredUser);
            await this.userService.setUser(registeredUser);
            await this.userService.deleteUser(invitedUser.id);
          } else {
            const registeredUser: User = {
              displayName: u.user.displayName ? u.user.displayName : '',
              email: emailAdress,
              id: u.user.uid,
              funnelIds: [],
              companyName:  this.form.value.companyName,
              language: 'ger',
              registerDate: new Date(),
              showOnboarding: true,
              receiptEmail: emailAdress,
            };

            const isUserRegistered = await this.userService.getUserById(
              u.user.uid
            );

            if (!isUserRegistered) {
              this.userService.setUserDataSubject(registeredUser);
              await this.userService.setUser(registeredUser);
            }
          }

          this.googleAnalyticsService.eventEmitter(
            'Registration',
            'Sign_up',
            'klicklead.de'
          );

          await this.mailMarketingService.createSubscriber(emailAdress);
          await this.authService.sendEmailVerification();
          this.router.navigate(['/verify-email']);
        })
        .catch((e) => {
          if (e.code === 'auth/email-already-in-use') {
            this.errors = true;
            this.errorText = 'Die E-Mail Adresse wird bereits verwendet';
          }
        });
    } else {
      this.errors = true;
      this.errorText = 'Bitte fülle alle Felder aus';
    }
  }

  async checkIfUserWasInvited(email: string) {
    const user = await this.userService.getUserByEmail(email);
    if (user) {
      if (user.id.includes('INVITED')) {
        return user;
      }
    }
    return false;
  }

  async loginWithGoogle() {
    this.authService.signInWithGoogle().then(async (u) => {
      let isUserRegistered;
      if (u.user) {
        isUserRegistered = await this.userService.getUserById(u.user.uid);
      }
      if (!u.user) return;

      const emailAdress = u.user.email ? u.user.email : '';

      const invitedUser = await this.checkIfUserWasInvited(emailAdress);

      if (invitedUser) {
        const registeredUser: User = {
          displayName: u.user.displayName ? u.user.displayName : '',
          email: emailAdress,
          id: u.user.uid,
          funnelIds: invitedUser.funnelIds,

          language: 'ger',
          registerDate: new Date(),
          showOnboarding: true,
          receiptEmail: this.userService.user.email,
        };

        this.userService.setUserDataSubject(registeredUser);
        await this.userService.setUser(registeredUser);
        await this.userService.deleteUser(invitedUser.id);
      } else {
        if (!isUserRegistered) {
          const isUserRegisteredData = isUserRegistered.data();
          if (!isUserRegisteredData) {
            const registeredUser: User = {
              displayName: this.userService.user.displayName
                ? this.userService.user.displayName
                : '',
              email: this.userService.user.email
                ? this.userService.user.email
                : '',
              id: this.userService.user.uid,
              funnelIds: [],

              language: this.langService.getCurrentLanguage(),
              registerDate: new Date(),
              showOnboarding: true,
              receiptEmail: this.userService.user.email,
            };

            this.googleAnalyticsService.eventEmitter(
              'Registration',
              'Sign_up',
              'klicklead.de'
            );
            //this.userService.userData = registeredUser;
            //this.authService.sendEmailVerification()
            this.userService.setUserDataSubject(registeredUser);

            await this.userService.setUser(registeredUser);
          }
        } else {
          this.googleAnalyticsService.eventEmitter(
            'Login',
            'Login',
            'klicklead.de'
          );
        }
      }

      this.router.navigate(['/funnels']);
    });
  }
}
