import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BlockType } from '../enums/block-type';
import {
  Answer,
  Block,
  BulletPointsBlock,
  PrivacyCheck,
} from '../models/block.model';
import { Project } from '../models/project.model';
import { LanguageService } from './lang.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable()
export class BlockService {
  constructor(
    private sanitizer: DomSanitizer,
    private langService: LanguageService,
    private breakpointObserver: BreakpointObserver
  ) {}

  getSelectionStyle(html: Block[], i: number, preview?: boolean) {
    let currentSelectAnswer: Block = html[i];
    if (currentSelectAnswer.style) {
      let styles = {
        'font-size': currentSelectAnswer.style.fontSize + 'px',
        color: currentSelectAnswer.style.fontColor,
        'font-weight': currentSelectAnswer.style.fontWeight,
        'font-style': currentSelectAnswer.style.fontStyle,
        'text-decoration': currentSelectAnswer.style.textDecoration,
        'border-color': preview ? '' : currentSelectAnswer.style.fontColor,
      };

      return styles;
    }
    return {};
  }

  getFontCSS(html: Block[], index: number) {
    let currentBlock: Block = html[index];
    if (typeof currentBlock !== 'undefined') {
      if (currentBlock.style) {
        let font: string = currentBlock.style.fontFamily
          ? currentBlock.style.fontFamily
          : 'Roboto';

        font = font.replace(/ /g, '+');

        return this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://fonts.googleapis.com/css2?family=' +
            font +
            ':wght@100;200;300;400;500;600;700;800;900&display=swap'
        );
      }
    }
    return '';
  }

  getQuizTextStyle(html: Block[], answerIndex: number, i: number) {
    let answer: Answer = html[i].content['answers'][answerIndex];
    if (answer.style) {
      let styles = {
        'font-size': answer.style.fontSize + 'px',
         'color': answer.style.fontColor,
        'font-family': answer.style.fontFamily,
        'background-color': answer.style.quizButtonColor,
      };
      return styles;
    }
    return {};
  }

  getQuizIconStyle(html: Block[], answerIndex: number, i: number) {
    let answer: Answer = html[i].content['answers'][answerIndex];
    if (answer.style) {
      let styles = {
        'font-size': answer.style.iconSize + 'px',
        'padding-top': answer.style.paddingTop + 'px',
        'padding-bottom': answer.style.paddingBottom + 'px',
        'padding-left': answer.style.paddingLeft + 'px',
        'padding-right': answer.style.paddingRight + 'px',
        color: html[i].style?.iconColor,
      };
      return styles;
    }
    return {};
  }

  getQuizPictureStyle(html: Block[], answerIndex: number, i: number) {
    let answer: Answer = html[i].content['answers'][answerIndex];
    if (answer.style) {
      let styles = {
        'padding-top': answer.style.paddingTop + 'px',
        'padding-bottom': answer.style.paddingBottom + 'px',
        'padding-left': answer.style.paddingLeft + 'px',
        'padding-right': answer.style.paddingRight + 'px',
      };
      return styles;
    }
    return {};
  }

  getFormSubmitButtonStyle(html: Block[], index: number, hover?: boolean) {
    let currentBtn: any = html[index].content['submitButton'];
    if (typeof currentBtn !== 'undefined') {
      if (!currentBtn.style) return {};

      let styles = {
        'font-size': currentBtn.style.fontSize + 'px',
        width: currentBtn.style.width + '%',
        color: currentBtn.style.fontColor,
        'background-color': hover
          ? currentBtn.style.hoverColor
          : currentBtn.style.backgroundColor,
        'font-weight': currentBtn.style.fontWeight,
        'font-style': currentBtn.style.fontStyle,
        'text-decoration': currentBtn.style.textDecoration,
        'margin-top': '20px',
        'margin-bottom': currentBtn.style.marginBottom + 'px',
        height: currentBtn.style.height + 'px',
        'border-radius': currentBtn.style.borderRadius + 'px',
      };

      return styles;
    }
    return {};
  }

  getFormPrivacyCheckStyle(
    html: Block[],
    index: number,
    privacyError: boolean = false
  ) {
    let currentBtn: PrivacyCheck = html[index].content['privacyCheck'];
    if (typeof currentBtn !== 'undefined') {
      if (!currentBtn.style) return {};
      let styles = {
        'font-size': currentBtn.style.fontSize + 'px',
        color: privacyError ? '#FF0000' : currentBtn.style.fontColor,
      };

      return styles;
    }
    return '';
  }

  getFormCheckboxStyle(html: Block[], index: number) {
    let privacyCheck: PrivacyCheck = html[index].content['privacyCheck'];
    if (typeof privacyCheck !== 'undefined') {
      if (!privacyCheck.style) return {};
      let styles = {
        color: privacyCheck.style.checkboxColor
          ? privacyCheck.style.checkboxColor
          : privacyCheck.style.fontColor,
      };

      return styles;
    }
    return '';
  }

  getBackgroundColor(
    currentProject: Project,
    currentPageIndex: number,
    block?: Block
  ) {
    let styles = {
      'background-color': '',
    };
    let color = null;

    if (typeof block !== 'undefined') {
      if (block.style) {
        if (
          typeof block.style.containerBackgroundColor !== 'undefined' &&
          block.style.containerBackgroundColor !== '' &&
          block.style.containerBackgroundColor !== null
        ) {
          color = block.style.containerBackgroundColor;

          return (styles = {
            'background-color': color,
          });
        } else {
          color =
            currentProject.pages[currentPageIndex].style
              .containerBackgroundColor;
        }
      }
    } else {
      color =
        currentProject.pages[currentPageIndex].style.containerBackgroundColor;
    }

    styles = {
      'background-color': color,
    };
    return styles;
  }

  getProgress(currentProject: Project, currentPageIndex: number, type: string) {
    let styles = {};
    if (typeof currentProject !== 'undefined') {
      if (type === 'background') {
        styles = {
          'background-color': this.hexToRgbA(
            currentProject.progressbar.color,
            0.4
          ),
        };
      } else {
        styles = {
          'background-color': this.hexToRgbA(
            currentProject.progressbar.color,
            1
          ),
          width:
            ((currentPageIndex + 1) * 100) / currentProject.pages.length + '%',
        };
      }
      return styles;
    }
    return styles;
  }

  getStyle(html: Block[], index: number, inputIndex?: number) {
    let currentBlock: Block = html[index];
    let styles = {};
    let widthUnit = '%';
    if (currentBlock.type === 'picture' || currentBlock.type === 'fakeloader') {
      widthUnit = 'px';
    }

    if (typeof currentBlock !== 'undefined') {
      if (currentBlock.style) {
        const baseStyles = {
          contentAlignment: currentBlock.style.contentAlignment,
          alignment: currentBlock.style.alignment,
          'font-size': `${currentBlock.style.fontSize}px`,
          'font-family': currentBlock.style.fontFamily,
          height: `${currentBlock.style.height}${widthUnit}`,
          color: currentBlock.style.fontColor,
          'background-color': currentBlock.style.backgroundColor,
          'font-weight': currentBlock.style.fontWeight,
          'font-style': currentBlock.style.fontStyle,
          'text-decoration': currentBlock.style.textDecoration,
          'margin-top': `${currentBlock.style.marginTop}px`,
          'margin-bottom': `${currentBlock.style.marginBottom}px`,
          'margin-left': `${currentBlock.style.marginLeft}px`,
          'margin-right': `${currentBlock.style.marginRight}px`,
          'border-top-color':
            inputIndex === index
              ? currentBlock.style.fontColor
              : currentBlock.style.borderColor,
        };

        let breakpoint = 'sm';
        if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
          breakpoint = 'lg';
        } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
          breakpoint = 'md';
        }

        const widthStyles: any = {
          sm: {
            width: currentBlock.style.fullWidth
              ? '100%'
              : `${currentBlock.style.width}${widthUnit}`,
          },
          md: {
            width: currentBlock.style.mdFullWidth
              ? '100%'
              : `${currentBlock.style.mdWidth}${widthUnit}`,
          },
          lg: {
            width: currentBlock.style.lgFullWidth
              ? '100%'
              : `${currentBlock.style.lgWidth}${widthUnit}`,
          },
        };

        styles = {
          ...baseStyles,
          ...widthStyles[breakpoint],
        };

        if (currentBlock.type !== BlockType.FAKELOADER) {
          styles = {
            ...styles,
            'border-left-color':
              inputIndex === index
                ? currentBlock.style.fontColor
                : currentBlock.style.borderColor,
            'border-right-color':
              inputIndex === index
                ? currentBlock.style.fontColor
                : currentBlock.style.borderColor,
            'border-bottom-color':
              inputIndex === index
                ? currentBlock.style.fontColor
                : currentBlock.style.borderColor,
          };
        }

        /*    if (currentBlock.type === BlockType.SLIDER) {
          styles = {
            ...styles,
            'background-color': currentBlock.style.fontColor,
          };
        } */
      }

      return styles;
    }
    return '';
  }

  safeIncludes(str: string | undefined | null, searchString: string): boolean {
    return typeof str === 'string' && str.includes(searchString);
  }

  getQuizButtonStyle(html: Block[], answerIndex: number, i: number) {
    let currentBtn: Answer = html[i].content['answers'][answerIndex];
    if (typeof currentBtn !== 'undefined') {
      if (!currentBtn.style) return {};
      let styles = {
        'font-size': currentBtn.style.fontSize + 'px',
        width: currentBtn.style.width + '%',
        color: currentBtn.style.fontColor,
        'background-color': currentBtn.style.quizButtonColor,
        'font-weight': currentBtn.style.fontWeight,
        'font-style': currentBtn.style.fontStyle,
        'text-decoration': currentBtn.style.textDecoration,
        'margin-top': currentBtn.style.marginTop + 'px',
        'margin-bottom': currentBtn.style.marginBottom + 'px',
      };
      return styles;
    }
    return '';
  }

  getBulletpointStyle(html: Block[], index: number, type: string) {
    let currentBulletpointsBlock: BulletPointsBlock = html[index].content;

    if (typeof currentBulletpointsBlock !== 'undefined') {
      if (!currentBulletpointsBlock.textStyle) return {};
      let styles = {};
      if (type === 'icon') {
        styles = {
          color: currentBulletpointsBlock.iconStyle?.color,
          'font-size': currentBulletpointsBlock.iconStyle?.fontSize + 'px',
        };
      } else {
        styles = {
          'font-size': currentBulletpointsBlock.textStyle.fontSize + 'px',
          color: currentBulletpointsBlock.textStyle.color,
          'font-weight': currentBulletpointsBlock.textStyle.fontWeight,
          'font-style': currentBulletpointsBlock.textStyle.fontStyle,
        };
      }
      return styles;
    }
    return '';
  }

  getFormInputStyle(
    html: Block[],
    index: number,
    inputIndex: number,
    inputFormInputIndex: number
  ) {
    let currentBlock: Block = html[index];
    let styles = {};

    if (typeof currentBlock !== 'undefined') {
      if (currentBlock.style) {
        styles = {
          'border-left-color':
            inputFormInputIndex === inputIndex
              ? currentBlock.style.fontColor
              : currentBlock.style.borderColor,
          'border-right-color':
            inputFormInputIndex === inputIndex
              ? currentBlock.style.fontColor
              : currentBlock.style.borderColor,
          'border-bottom-color':
            inputFormInputIndex === inputIndex
              ? currentBlock.style.fontColor
              : currentBlock.style.borderColor,
          'border-top-color':
            inputFormInputIndex === inputIndex
              ? currentBlock.style.fontColor
              : currentBlock.style.borderColor,
        };
      }
      const element = document.getElementById('inputtext' + inputIndex);
      if (element) {
        element.style.setProperty(
          '--placeHolder-color',
          currentBlock.style?.placeholderColor!
        );
      }

      return styles;
    }
    return '';
  }

  hexToRgbA(hex: string, opacity: number) {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return (
        'rgba(' +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
        ',' +
        opacity +
        ')'
      );
    }
    throw new Error('Bad Hex');
  }

  getBlockName(type: string) {
    switch (type) {
      case 'button': {
        return this.langService.getWording(
          'funnelEditor',
          'tabRightBlockButton'
        );
      }
      case 'fakeloader': {
        return 'Ladebalken';
      }
      case 'form': {
        return this.langService.getWording('funnelEditor', 'tabRightBlockForm');
      }
      case 'header': {
        return 'Textfeld';
      }
      case 'picture': {
        return this.langService.getWording(
          'funnelEditor',
          'tabRightBlockPicture'
        );
      }
      case 'quiz': {
        return 'Auswahl';
      }
      case 'selectionquiz': {
        return 'Auswahl/Quiz';
      }
      case 'textfield': {
        return 'Eingabefeld';
      }
      case 'selection': {
        return 'Multiple Choice';
      }
      case 'video': {
        return 'Video';
      }
      case 'socialmedia': {
        return 'Social Media';
      }
      case 'slider': {
        return 'Schieberegler';
      }
      case 'bulletpoints': {
        return 'Aufzählung';
      }
      case 'upload': {
        return 'Upload';
      }
      case 'zw_westnetz': {
        return 'Westnetz Abfrage';
      }
    }
  }

  getBlockIcon(icon: string) {
    switch (icon) {
      case 'button': {
        return 'bi bi-app';
      }
      case 'form': {
        return 'bi bi-menu-up';
      }
      case 'fakeloader': {
        return 'bi bi-arrow-clockwise';
      }
      case 'header': {
        return 'bi bi-type';
      }
      case 'picture': {
        return 'bi bi-image';
      }
      case 'quiz': {
        return 'bi bi-ui-checks-grid';
      }
      case 'selectionquiz': {
        return 'bi bi-ui-checks-grid';
      }
      case 'textfield': {
        return 'bi bi-fonts';
      }
      case 'selection': {
        return 'bi bi-list-check';
      }
      case 'video': {
        return 'bi bi-film';
      }
      case 'socialmedia': {
        return 'bi bi-share';
      }

      case 'bulletpoints': {
        return 'bi bi-card-list';
      }
      case 'upload': {
        return 'bi bi-cloud-arrow-up';
      }
      case 'slider': {
        return 'bi bi-sliders';
      }

      case 'bulletpoints': {
        return 'bi bi-card-list';
      }
      case 'upload': {
        return 'bi bi-cloud-arrow-up';
      }
      case 'zw_westnetz': {
        return 'bi bi-lightning-charge-fill';
      }
      default:
        return '';
    }
  }
}
