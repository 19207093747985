import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-new-funnel-shop',
  templateUrl: './new-funnel-shop.component.html',
  styleUrls: ['./new-funnel-shop.component.scss'],
})
export class NewFunnelShopComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
