<div class="overflow-auto h-full">
  <button
    (click)="openMediathek()"
    type="button"
    class="flex justify-center items-center rounded-md border border-transparent bg-kl-purple px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-pruple-500 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 w-full"
  >
    Mediathek öffnen
  </button>

  <!--  <ngx-dropzone (change)="uploadImage($event)" accept="image/*" class="my-6">
    <ngx-dropzone-label>
      <div class="flex flex-col">
        <i *ngIf="!uploadLoader" class="bi bi-cloud-arrow-up text-2xl mr-2"></i>
        <i
          *ngIf="uploadLoader"
          class="bi bi-arrow-clockwise text-2xl animate-spin"
        ></i>

        <span
          *ngIf="!uploadLoader"
          class="block text-grey"
          i18n="@@funnelSidebarDetailMediathek_pictureUpload_label"
          >Bilder hochladen</span
        >
      </div>
    </ngx-dropzone-label>
 
  </ngx-dropzone>
  <button
    type="button"
    (click)="openMediathek()"
    class="mb-4 flex items-center space-x-2 rounded-full border border-transparent bg-kl-purple px-3 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-purple-800 focus:outline-none focus:ring-0 focus:ring-transparent"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-folder"
      viewBox="0 0 16 16"
    >
      <path
        d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"
      />
    </svg>
    <p>Mediathek öffnen</p>
  </button>

  <button
    type="button"
    (click)="newFolder()"
    class="mb-4 flex items-center space-x-2 rounded-full border border-transparent bg-kl-purple px-3 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-purple-800 focus:outline-none focus:ring-0 focus:ring-transparent"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-folder"
      viewBox="0 0 16 16"
    >
      <path
        d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"
      />
    </svg>
    <p>Neuer Ordner</p>
  </button>

  <nav
    class="flex mb-4 items-center space-x-2 cursor-pointer"
    (click)="folderBack()"
    *ngIf="currentFolder"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-chevron-left text-gray-400"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
      />
    </svg>
    <p class="text-sm text-gray-400">zurück</p>
  </nav>

  <div *ngIf="userImagesLoader" class="flex items-center flex-wrap gap-4 my-6">
    <div
      class="flex flex-col items-center justify-between p-2 w-1/4 h-auto group cursor-pointer border rounded-lg"
      *ngFor="let image of [1, 2, 3, 4]"
    >
      <img
        class="w-20 h-32 object-contain rounded-lg bg-gray-300 animate-pulse"
      />
    </div>
  </div>

  <div *ngIf="userImages.length > 0" class="flex flex-wrap gap-4">
    <div
      class="relative flex items-center justify-between p-10 w-36 h-auto group cursor-pointer border rounded-lg"
      *ngFor="let image of userImages"
    >
      <div
        class="absolute top-2 right-2 rounded-full flex justify-center items-center w-4 h-4 bg-red-400 text-white cursor-pointer"
        (click)="deleteImage(image)"
      >
        <i class="bi bi-x w-auto h-auto"></i>
      </div>
      <img
        *ngIf="image.type === 'image'"
        (click)="selectPicture(image)"
        [src]="image.url"
        class="w-44 h-auto object-contain rounded-lg p-10"
      />
      <div
        *ngIf="image.type === 'folder'"
        (click)="openFolder(image)"
        class="flex flex-col justify-center items-center w-44 p-10"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-folder h-8 w-8 text-gray-400 mb-2"
          viewBox="0 0 16 16"
        >
          <path
            d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"
          />
        </svg>
        <p class="text-sm">{{ image.name }}</p>
      </div>
    </div>
  </div> -->
</div>
