import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Page } from 'src/app/models/project.model';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @Input() items: any;
  @Input() selectedItem: any;
  @Input() headline: string;
  @Input() type: string = 'string';

  @Output() onChange = new EventEmitter();

  showList: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  selectItem(item: any) {
    this.onChange.emit(item);
    this.toggleList();
  }

  getPageTitle(pageId: string) {
    let page: Page = this.items.find((page: Page) => page.id === pageId);
    if (page) {
      return page.name;
    } else {
      return this.items[0].name;
    }
  }

  toggleList() {
    this.showList = !this.showList;
  }

  closeList() {
    this.showList = false;
  }
}
