import firebase from 'firebase/app';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Block } from '../models/block.model';
import {
  FunnelTemplate,
  TemplateCategory,
  UserTemplate,
} from '../models/funnel-template.model';

@Injectable()
export class TemplateService {
  db: any;
  isLoading: boolean = false;

  constructor(private userService: UserService) {
    this.db = firebase.firestore();
  }

  async setUserTemplate(template: UserTemplate) {
    this.isLoading = true;

    return this.db
      .collection('userTemplates')
      .doc(template.id)
      .set({ ...template })
      .then(() => (this.isLoading = false));
  }

  async getUserTemplates(userId: string) {
    this.isLoading = true;

    const snapshot = await this.db
      .collection('userTemplates')
      .where('userId', '==', userId)
      .get();
    let userTemplates: UserTemplate[] = [];
    snapshot.forEach((as: any) => {
      userTemplates.push(as.data());
    });
    this.isLoading = false;
    return userTemplates;
  }

  async deleteUserTemplate(templateId: string) {
    this.isLoading = true;

    return this.db
      .collection('userTemplates')
      .doc(templateId)
      .delete()
      .then(() => (this.isLoading = false));
  }

  async setFunnelTemplate(template: FunnelTemplate) {
    this.isLoading = true;

    return this.db
      .collection('funnelTemplates')
      .doc(template.id)
      .set({ ...template })
      .then(() => (this.isLoading = false));
  }

  async getFunnelTemplates() {
    this.isLoading = true;

    const snapshot = await this.db.collection('funnelTemplates').get();
    let documents: FunnelTemplate[] = [];
    snapshot.forEach((doc: any) => {
      documents.push(doc.data());
    });
    this.isLoading = false;
    return documents;
  }

  async deleteFunnelTemplate(templateId: string) {
    this.isLoading = true;

    return this.db
      .collection('funnelTemplates')
      .doc(templateId)
      .delete()
      .then(() => (this.isLoading = false));
  }

  async setCategoryTemplate(category: TemplateCategory) {
    this.isLoading = true;

    return this.db
      .collection('templateCategories')
      .doc(category.id)
      .set({ ...category })
      .then(() => (this.isLoading = false));
  }

  async getTemplateCategories() {
    this.isLoading = true;

    const snapshot = await this.db.collection('templateCategories').get();
    let documents: TemplateCategory[] = [];
    snapshot.forEach((doc: any) => {
      documents.push(doc.data());
    });
    this.isLoading = false;
    return documents;
  }

  async deleteTemplateCateory(categoryId: string) {
    this.isLoading = true;

    return this.db
      .collection('templateCategories')
      .doc(categoryId)
      .delete()
      .then(() => (this.isLoading = false));
  }
}
