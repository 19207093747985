import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Project } from 'src/app/models/project.model';
import { LanguageService } from 'src/app/services/lang.service';
import { ProjectService } from 'src/app/services/project.service';
import { RenameProjectDialogComponent } from '../modals/rename-project-dialog/rename-project-dialog.component';

@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss'],
})
export class InfoBarComponent implements OnInit {
  baseUrl: string;
  currentProject: Project;
  appUrl: string;
  constructor(
    private projectService: ProjectService,
    public langService: LanguageService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}
}
