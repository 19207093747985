<div
  *ngIf="!selectedElement"
  class="flex flex-col items-center justify-center h-full"
  i18n="@@funnelSidebarDetailsContent_noElementSelectedMessage_label"
>
  <p>Bitte wähle einen Block auf der Seite aus.</p>
  <i class="mt-6 text-4xl bi bi-arrow-return-right"></i>
</div>

<div
  *ngIf="
    selectedElement &&
    selectedAnswer === null &&
    selectedInputField === null &&
    selectedBulletpoint === null
  "
>
  <div class="flex items-center mb-4 gap-x-2">
    <i class="text-sm bi bi-chevron-right"></i>
    <p class="text-lg font-bold text-kl-grey">
      {{ getBlockName(selectedElement.type) }}
    </p>
  </div>

  <div class="border-b border-gray-200">
    <nav class="flex -mb-px" aria-label="Tabs">
      <a
        (click)="setTabIndex(0)"
        class="w-1/2 px-1 py-2 text-sm font-medium text-center border-b-2 border-transparent cursor-pointer"
        [ngClass]="{
          'border-kl-purple text-kl-purple': tabIndex === 0,
          'text-gray-500 hover:text-gray-700 hover:border-gray-300':
            tabIndex !== 0
        }"
        i18n="@@funnelSidebarDetailsContent_edit_label"
        >{{ selectedElement.type === "picture" ? "Bildauswahl" : "Bearbeiten" }}
      </a>

      <a
        (click)="setTabIndex(1)"
        class="w-1/2 px-1 py-2 text-sm font-medium text-center border-b-2 border-transparent cursor-pointer"
        [ngClass]="{
          'border-kl-purple text-kl-purple': tabIndex === 1,
          'text-gray-500 hover:text-gray-700 hover:border-gray-300':
            tabIndex !== 1
        }"
        i18n="@@funnelSidebarDetailsContent_design_label"
      >
        Design
      </a>
    </nav>
  </div>
</div>
<div class="h-full mb-32">
  <div *ngIf="tabIndex === 0 && selectedElement" class="h-full">
    <div class="my-6" *ngIf="isVisible('text')">
      <label
        for="comment"
        class="block text-sm font-medium text-gray-700"
        i18n="@@funnelSidebarDetailsContent_text_label"
        >Text</label
      >
      <div class="mt-1">
        <div
          contenteditable="true"
          rows="4"
          [innerHTML]="selectedElement.content"
          (blur)="updateElementContent($event)"
          class="block w-full h-32 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        ></div>
      </div>
    </div>

    <div *ngIf="isVisible('button')">
      <div class="my-6">
        <app-dropdown
          [headline]="'Klickaktion'"
          [items]="blockActionTypes"
          [selectedItem]="getActionTypeString()"
          (onChange)="setActionType($event)"
        ></app-dropdown>
      </div>

      <div
        class="my-6"
        *ngIf="selectedElement.action?.type === 'connectedPage'"
      >
        <app-dropdown
          [type]="'quizItem'"
          [headline]="'Seite auswählen'"
          [items]="pages"
          [selectedItem]="selectedElement.action?.connectedPage"
          (onChange)="setConnectedPage($event)"
        ></app-dropdown>
      </div>

      <div class="my-6" *ngIf="selectedElement.action?.type === 'link'">
        <app-text-input
          class="w-full mr-4"
          [value]="selectedElement.action?.link"
          (valueChange)="updateLink($event)"
          [placeholder]="'Link eingeben'"
          [title]="'Ausgehender Link'"
        ></app-text-input>
      </div>
    </div>

    <div class="my-6" *ngIf="isVisible('picture') && selectedElement.content">
      <label
        for="comment"
        class="block mb-1 text-sm font-medium text-gray-700"
        i18n="@@funnelSidebarDetailsContent_selectedImage_label"
        >Ausgewähltes Bild</label
      >

      <div
        class="relative flex flex-col items-center justify-between w-1/4 h-auto p-2 border rounded-lg cursor-pointer group"
      >
        <div
          class="absolute top-0 right-0 flex items-center justify-center w-4 h-4 text-white bg-red-400 rounded-full cursor-pointer"
          (click)="unsetImage()"
        >
          <i class="w-auto h-auto bi bi-x"></i>
        </div>
        <img
          [src]="selectedElement.content"
          class="object-contain w-full h-auto rounded-lg"
        />
      </div>
    </div>
    <div class="my-6" *ngIf="isVisible('picture')">
      <app-details-mediathek
        (onSelectImage)="setImage($event)"
      ></app-details-mediathek>

      <app-details-unsplash
        (onSelectImage)="setImage($event)"
      ></app-details-unsplash>
    </div>

    <div *ngIf="isVisible('socialmedia')">
      <div class="my-6">
        <app-dropdown
          [headline]="'Plattform'"
          [items]="socialMedias"
          [selectedItem]="selectedElement.content.type"
          (onChange)="setSocialMediaType($event)"
        ></app-dropdown>
      </div>
      <div class="my-6">
        <app-text-input
          [value]="selectedElement.content.url"
          [title]="'URL'"
          (valueChange)="changed($event, 'socialmediaUrl')"
          [placeholder]="'https://www.deine-webseite.de'"
        ></app-text-input>
      </div>
    </div>

    <div class="overflow-auto" *ngIf="selectedElement.showQuiz == true">
      <app-details-quiz-items
        class="overflow-auto"
        [items]="selectedElement.content.answers"
        [selectedQuizItem]="selectedAnswer"
        [quizBlock]="selectedElement"
      ></app-details-quiz-items>
    </div>

    <div class="overflow-auto" *ngIf="isVisible('form')">
      <app-details-form-items
        [items]="selectedElement.content.inputs"
        [privacy]="selectedElement.content.privacyCheck"
        [submitButton]="selectedElement.content.submitButton"
        [selectedFormItem]="selectedInputField"
        [project]="project"
        class="overflow-auto"
      ></app-details-form-items>
    </div>

    <div class="overflow-auto" *ngIf="isVisible('zw_westnetz')">
      <app-details-zw-westnetz-items
        [westnetz]="selectedElement"
        [items]="selectedElement.content.inputs"
        [submitButton]="selectedElement.content.submitButton"
        [selectedWestnetzItem]="selectedInputField"
        [project]="project"
        class="overflow-auto"
      ></app-details-zw-westnetz-items>
    </div>

    <div *ngIf="isVisible('video')">
      <div class="my-6">
        <app-text-input
          [value]="selectedElement.content.url"
          [title]="'Video URL'"
          (valueChange)="changed($event, 'videoUrl')"
          [placeholder]="'https://www.youtube.com/embed/dQw4w9WgXcQ'"
        ></app-text-input>
      </div>
    </div>

    <div *ngIf="selectedElement.showQuiz == false">
      <app-details-selection-items
        [items]="selectedElement.content.answers"
      ></app-details-selection-items>
    </div>
    <div *ngIf="isVisible('textfield')">
      <div class="my-6">
        <app-text-input
          [value]="selectedElement.content.text"
          [title]="'Platzhaltertext'"
          (valueChange)="changed($event, 'textfield')"
          [placeholder]="'z.B.: Dein Name'"
        ></app-text-input>
      </div>
      <div class="my-6">
        <app-dropdown
          [headline]="'Textfeldtyp'"
          [items]="textfieldTypes"
          [selectedItem]="selectedElement.content.type"
          (onChange)="setTextfieldType($event)"
        ></app-dropdown>
      </div>
      <div class="my-6">
        <app-toggle
          [title]="'Pflichtfeld'"
          [isEnabled]="selectedElement.content.required"
          (onToggle)="changed($event, 'textfieldRequired')"
        ></app-toggle>
      </div>
    </div>

    <div class="my-6" *ngIf="isVisible('selectionquiz')">
      <app-toggle
        [title]="'Auswahl oder Quiz'"
        [isEnabled]="selectedElement!.showQuiz!"
        (onToggle)="changed($event, 'showQuiz')"
      ></app-toggle>
    </div>

    <div class="my-6" *ngIf="isVisible('bulletpoints')">
      <app-details-bulletpoints-items
        [items]="selectedElement.content.bulletpoints"
      ></app-details-bulletpoints-items>
    </div>

    <div class="my-6" *ngIf="isVisible('upload')">
      <div class="my-6" *ngIf="selectedElement.content.picture">
        <label
          for="comment"
          class="block mb-1 text-sm font-medium text-gray-700"
          i18n="@@funnelSidebarDetailsContent_selectedImage_label"
          >Ausgewähltes Bild</label
        >
        <div
          class="relative flex flex-col items-center justify-between w-1/4 h-auto p-2 border rounded-lg cursor-pointer group"
        >
          <div
            class="absolute top-0 right-0 flex items-center justify-center w-4 h-4 text-white bg-red-400 rounded-full cursor-pointer"
            (click)="unsetImage('upload')"
          >
            <i class="w-auto h-auto bi bi-x"></i>
          </div>
          <img
            [src]="selectedElement.content.picture"
            class="object-contain w-full h-auto rounded-lg"
          />
        </div>
      </div>

      <div class="my-6">
        <app-details-mediathek
          (onSelectImage)="setUploadImage($event)"
        ></app-details-mediathek>
      </div>

      <div class="my-6">
        <app-text-input
          [value]="selectedElement.content.text"
          [title]="'Text'"
          (valueChange)="changed($event, 'textfield')"
          [placeholder]="'Bitte laden Sie Ihre Datei hoch'"
        ></app-text-input>
      </div>

      <div class="my-6">
        <app-toggle
          [title]="'Pflichtfeld'"
          [isEnabled]="selectedElement.content.required"
          (onToggle)="changed($event, 'textfieldRequired')"
        ></app-toggle>
      </div>

      <div class="my-6">
        <app-range-slider
          [value]="selectedElement.content.maxUploads"
          [title]="'Maximale Uploads'"
          [min]="1"
          [max]="10"
          (valueChange)="changed($event, 'maxUploads')"
        ></app-range-slider>
      </div>
    </div>

    <div class="my-6" *ngIf="isVisible('fakeloader')">
      <app-range-slider
        [value]="selectedElement.content.duration"
        [title]="'Dauer Ladebalken'"
        (valueChange)="changed($event, 'fakeloader')"
      ></app-range-slider>
    </div>

    <div *ngIf="isVisible('slider')">
      <div class="my-6">
        <app-text-input
          [value]="selectedElement.content.min"
          [title]="'Minimaler Wert'"
          (valueChange)="changed($event, 'slidermin')"
        ></app-text-input>
      </div>

      <div class="my-6">
        <app-text-input
          [value]="selectedElement.content.max"
          [title]="'Maximaler Wert'"
          (valueChange)="changed($event, 'slidermax')"
        ></app-text-input>
      </div>

      <div class="my-6">
        <app-range-slider
          [value]="selectedElement.content.steps"
          [title]="'Schritte'"
          (valueChange)="changed($event, 'slidersteps')"
        ></app-range-slider>
      </div>
    </div>
  </div>
  <div
    *ngIf="tabIndex === 1 && selectedElement"
    style="height: calc(100vh -20px)"
    class="overflow-auto"
  >
    <div class="my-6" *ngIf="isVisible('fonts')">
      <app-font-selection
        [title]="'Schriftart'"
        [selectedFont]="selectedElement.style?.fontFamily"
        (onSelectFont)="setFontFamily($event)"
      ></app-font-selection>
    </div>

    <div class="my-6" *ngIf="isVisible('fonts')">
      <app-range-slider
        [value]="
          selectedElement.style?.fontSize ? selectedElement.style?.fontSize : 16
        "
        [title]="'Schriftgröße'"
        (valueChange)="changed($event, 'fontSize')"
      ></app-range-slider>
    </div>

    <div class="my-6" *ngIf="isVisible('iconSize')">
      <app-range-slider
        [value]="
          selectedElement.style?.iconSize ? selectedElement.style?.iconSize : 16
        "
        [title]="'Icon Größe'"
        (valueChange)="changed($event, 'iconSize')"
      ></app-range-slider>
    </div>

    <div class="p-3 my-6 border rounded-lg" *ngIf="isVisible('picture')">
      <span
        class="inline-flex justify-center w-full rounded-md shadow-sm isolate"
      >
        <button
          type="button"
          class="relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-l-md ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10"
          [ngClass]="{
            'bg-gray-300': displaySize === 'sm'
          }"
          (click)="selectDisplaySize('sm')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-phone"
            viewBox="0 0 16 16"
          >
            <path
              d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
            />
            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
          </svg>
        </button>
        <button
          type="button"
          class="relative inline-flex items-center px-3 py-2 -ml-px text-sm font-semibold text-gray-900 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10"
          [ngClass]="{
            'bg-gray-300': displaySize === 'md'
          }"
          (click)="selectDisplaySize('md')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-laptop"
            viewBox="0 0 16 16"
          >
            <path
              d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5"
            />
          </svg>
        </button>
        <button
          type="button"
          class="relative inline-flex items-center px-3 py-2 -ml-px text-sm font-semibold text-gray-900 bg-white rounded-r-md ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10"
          [ngClass]="{
            'bg-gray-300': displaySize === 'lg'
          }"
          (click)="selectDisplaySize('lg')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-display"
            viewBox="0 0 16 16"
          >
            <path
              d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4q0 1 .25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75Q6 13 6 12H2s-2 0-2-2zm1.398-.855a.76.76 0 0 0-.254.302A1.5 1.5 0 0 0 1 4.01V10c0 .325.078.502.145.602q.105.156.302.254a1.5 1.5 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.76.76 0 0 0 .254-.302 1.5 1.5 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.76.76 0 0 0-.302-.254A1.5 1.5 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145"
            />
          </svg>
        </button>
      </span>

      <div class="flex flex-col" *ngIf="displaySize == 'sm'">
        <app-range-slider
          [value]="
            selectedElement.style?.width! ? selectedElement.style?.width! : 16
          "
          [max]="900"
          [title]="'Größe'"
          (valueChange)="changed($event, 'width')"
          *ngIf="!selectedElement.style?.fullWidth"
        ></app-range-slider>

        <app-toggle
          [title]="'Volle Breite'"
          [isEnabled]="selectedElement!.style!.fullWidth!"
          (onToggle)="changed($event, 'fullWidth')"
        ></app-toggle>
      </div>

      <div class="flex flex-col" *ngIf="displaySize == 'md'">
        <app-range-slider
          [value]="
            selectedElement.style?.mdWidth ? selectedElement.style?.mdWidth : 16
          "
          [max]="900"
          [title]="'Größe'"
          (valueChange)="changed($event, 'mdWidth')"
          *ngIf="!selectedElement.style?.mdFullWidth"
        ></app-range-slider>

        <app-toggle
          [title]="'Volle Breite'"
          [isEnabled]="selectedElement!.style!.mdFullWidth!"
          (onToggle)="changed($event, 'mdFullWidth')"
        ></app-toggle>
      </div>

      <div class="flex flex-col" *ngIf="displaySize == 'lg'">
        <app-range-slider
          [value]="
            selectedElement.style?.lgWidth ? selectedElement.style?.lgWidth : 16
          "
          [max]="900"
          [title]="'Größe'"
          (valueChange)="changed($event, 'lgWidth')"
          *ngIf="!selectedElement.style?.lgFullWidth"
        ></app-range-slider>

        <app-toggle
          [title]="'Volle Breite'"
          [isEnabled]="selectedElement!.style!.lgFullWidth!"
          (onToggle)="changed($event, 'lgFullWidth')"
        ></app-toggle>
      </div>
    </div>
    <div class="my-6" *ngIf="isVisible('alignment')">
      <app-alignment
        [title]="'Block Ausrichtung'"
        [alignment]="
          selectedElement.style?.alignment
            ? selectedElement.style?.alignment
            : 'left'
        "
        (onAlignmentChange)="setAlignment($event)"
      ></app-alignment>
    </div>

    <div class="my-6" *ngIf="isVisible('contentAlignment')">
      <app-alignment
        [title]="'Text Ausrichtung'"
        [alignment]="
          selectedElement.style?.contentAlignment
            ? selectedElement.style?.contentAlignment
            : 'left'
        "
        (onAlignmentChange)="setContentAlignment($event)"
      ></app-alignment>
    </div>

    <div class="my-6" *ngIf="isVisible('textDecoration')">
      <app-text-format
        [doc]="doc"
        [title]="'Format'"
        [selectedElement]="selectedElement"
      ></app-text-format>
    </div>

    <div class="my-6" *ngIf="isVisible('fontColor')">
      <app-color-picker
        [title]="'Textfarbe'"
        [color]="
          selectedElement.style?.fontColor
            ? selectedElement.style?.fontColor
            : '-'
        "
        (onColorChange)="setFontColor($event)"
      ></app-color-picker>
    </div>

    <div class="my-6">
      <app-color-picker
        [title]="'Block Hintergrundfarbe'"
        [color]="
          selectedElement.style?.containerBackgroundColor
            ? selectedElement.style?.containerBackgroundColor
            : '-'
        "
        (onColorChange)="setContainerBackgroundColor($event)"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('backgroundColor')">
      <app-color-picker
        [title]="'Hintergrundfarbe'"
        [color]="
          selectedElement.style?.backgroundColor
            ? selectedElement.style?.backgroundColor
            : '-'
        "
        (onColorChange)="setBackgroundColor($event)"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('quiz')">
      <app-color-picker
        [title]="'Iconfarbe'"
        [color]="
          selectedElement.content.iconStyle?.color
            ? selectedElement.content.iconStyle?.color
            : '-'
        "
        (onColorChange)="setQuizIconColor($event)"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('bulletpoints')">
      <app-color-picker
        [title]="'Iconfarbe'"
        [color]="
          selectedElement.content.iconStyle?.color
            ? selectedElement.content.iconStyle?.color
            : '-'
        "
        (onColorChange)="setBulletpointColor($event, 'icon')"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('bulletpoints')">
      <app-color-picker
        [title]="'Textfarbe'"
        [color]="
          selectedElement.content.textStyle?.color
            ? selectedElement.content.textStyle?.color
            : '-'
        "
        (onColorChange)="setBulletpointColor($event, 'text')"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('bulletpoints')">
      <app-range-slider
        [value]="
          selectedElement.content.iconStyle?.fontSize
            ? selectedElement.content.iconStyle?.fontSize
            : 16
        "
        [max]="50"
        [title]="'Icongröße'"
        (valueChange)="changed($event, 'bulletpointsIconSize')"
      ></app-range-slider>
    </div>

    <div class="my-6" *ngIf="isVisible('bulletpoints')">
      <app-range-slider
        [value]="
          selectedElement.content.textStyle?.fontSize
            ? selectedElement.content.textStyle?.fontSize
            : 16
        "
        [max]="50"
        [title]="'Textgröße'"
        (valueChange)="changed($event, 'bulletpointsTextSize')"
      ></app-range-slider>
    </div>

    <!--     <div class="my-6" *ngIf="isVisible('form')">
      <app-color-picker
        [title]="'Checkbox Farbe'"
        [color]="
          selectedElement.style?.checkboxColor
            ? selectedElement.style?.checkboxColor
            : '-'
        "
        (onColorChange)="setCheckboxColor($event)"
      ></app-color-picker>
    </div> -->
    <div class="my-6" *ngIf="isVisible('form')">
      <app-color-picker
        [title]="'Checkbox Textfarbe'"
        [color]="
          selectedElement.content.privacyCheck.style?.fontColor
            ? selectedElement.content.privacyCheck.style?.fontColor
            : '-'
        "
        (onColorChange)="setPrivacyFontColor($event)"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('form')">
      <app-color-picker
        [title]="'Placeholder Textfarbe'"
        [color]="
          selectedElement.style?.placeholderColor
            ? selectedElement.style?.placeholderColor
            : '-'
        "
        (onColorChange)="setPlaceholderColor($event)"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('form')">
      <app-range-slider
        [value]="
          selectedElement.content.submitButton.style?.fontSize
            ? selectedElement.content.submitButton.style?.fontSize
            : 16
        "
        [title]="'Formular Button Schriftgröße'"
        (valueChange)="changed($event, 'submitButtonFontSize')"
      ></app-range-slider>
    </div>

    <div class="my-6" *ngIf="isVisible('form')">
      <app-range-slider
        [value]="
          selectedElement.content.submitButton.style?.borderRadius
            ? selectedElement.content.submitButton.style?.borderRadius
            : 16
        "
        [title]="'Formular Button Eckenradius'"
        (valueChange)="changed($event, 'submitButtonBorderRadius')"
      ></app-range-slider>
    </div>

    <div class="my-6" *ngIf="isVisible('zw_westnetz')">
      <app-range-slider
        [value]="
          selectedElement.content.submitButton.style?.fontSize
            ? selectedElement.style?.fontSize
            : 16
        "
        [title]="'Schriftgröße'"
        (valueChange)="changed($event, 'submitButtonFontSize')"
      ></app-range-slider>
    </div>

    <div class="my-6" *ngIf="isVisible('form')">
      <app-color-picker
        [title]="'Button Textfarbe'"
        [color]="
          selectedElement.content.submitButton.style?.fontColor
            ? selectedElement.content.submitButton.style?.fontColor
            : '-'
        "
        (onColorChange)="setSubmitButtonFontColor($event)"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('zw_westnetz')">
      <app-color-picker
        [title]="'Button Textfarbe'"
        [color]="
          selectedElement.content.submitButton?.fontColor
            ? selectedElement.content.submitButton.style?.fontColor
            : '-'
        "
        (onColorChange)="setSubmitButtonFontColor($event)"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('form')">
      <app-color-picker
        [title]="'Button Hintergrundfarbe'"
        [color]="
          selectedElement.content.submitButton.style?.backgroundColor
            ? selectedElement.content.submitButton.style?.backgroundColor
            : '-'
        "
        (onColorChange)="setSubmitButtonBackgroundColor($event)"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('zw_westnetz')">
      <app-color-picker
        [title]="'Button Hintergrundfarbe'"
        [color]="
          selectedElement.content.submitButton.style?.backgroundColor
            ? selectedElement.content.submitButton.style?.backgroundColor
            : '-'
        "
        (onColorChange)="setSubmitButtonBackgroundColor($event)"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('form')">
      <app-color-picker
        [title]="'Button Hover Hintergrundfarbe'"
        [color]="
          selectedElement.content.submitButton.style?.hoverColor
            ? selectedElement.content.submitButton.style?.hoverColor
            : '-'
        "
        (onColorChange)="setSubmitButtonHoverBackgroundColor($event)"
      ></app-color-picker>
    </div>

    <div class="my-6" *ngIf="isVisible('zw_westnetz')">
      <app-color-picker
        [title]="'Button Hover Hintergrundfarbe'"
        [color]="
          selectedElement.content.submitButton.style?.hoverColor
            ? selectedElement.content.submitButton.style?.hoverColor
            : '-'
        "
        (onColorChange)="setSubmitButtonHoverBackgroundColor($event)"
      ></app-color-picker>
    </div>

    <!--   <div class="my-6" *ngIf="isVisible('button')">
      <app-color-picker
        [title]="'Hover Hintergrundfarbe'"
        [color]="
          selectedElement.style?.hoverColor
            ? selectedElement.style?.hoverColor
            : '-'
        "
        (onColorChange)="setHoverBackgroundColor($event)"
      ></app-color-picker>
    </div> -->

    <div class="w-full" *ngIf="isVisible('margin')">
      <div class="my-6">
        <app-margin
          (onMarginChange)="changed(true)"
          [selectedElement]="selectedElement"
        ></app-margin>
      </div>
    </div>
  </div>
</div>
