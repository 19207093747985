<section aria-labelledby="payment-details-heading" *ngIf="currentProject">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div
      class="bg-white py-6 px-4 sm:p-6 relative"
      [ngClass]="{
        'bg-gray-300 rounded-lg cursor-not-allowed': !webhookEnabled,
        'opacity-100': webhookEnabled
      }"
    >
      <i
        class="bi bi-lock-fill text-kl-purple text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-50"
        *ngIf="!webhookEnabled"
      ></i>
      <button
        *ngIf="!webhookEnabled"
        class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-14 z-50"
        (click)="openPaymentPage()"
        i18n="@@funnelSettingsIntegrations_upgradeNow_button"
      >
        Jetzt upgraden
      </button>
      <div>
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
          i18n="@@funnelSettingsGeneral_generalHeadline_label"
        >
          Integrationen
        </h2>
      </div>

      <div class="my-6 grid grid-cols-4 gap-6">
        <div class="col-span-8 sm:col-span-2">
          <label
            [ngClass]="{
              'opacity-50 z-0': !webhookEnabled,
              'opacity-100': webhookEnabled
            }"
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsGeneral_webhookUrl_label"
            >Webhook URL</label
          >
          <input
            [ngClass]="{
              'opacity-50 z-0': !webhookEnabled,
              'opacity-100': webhookEnabled
            }"
            [disabled]="!webhookEnabled"
            type="text"
            [(ngModel)]="currentProject.webhookUrl"
            placeholder="Webhook URL"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
      </div>

      <div class="my-6 grid grid-cols-4 gap-6">
        <div class="col-span-8 sm:col-span-2">
          <label
            [ngClass]="{
              'opacity-50 z-0': !webhookEnabled,
              'opacity-100': webhookEnabled
            }"
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsGeneral_webhookUrl_label"
            >Custobar URL</label
          >
          <input
            [ngClass]="{
              'opacity-50 z-0': !webhookEnabled,
              'opacity-100': webhookEnabled
            }"
            [disabled]="!webhookEnabled"
            type="text"
            [(ngModel)]="currentProject.custobarUrl"
            placeholder="https://<COMPANY>.custobar.com/"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
        <div class="col-span-8 sm:col-span-2">
          <label
            [ngClass]="{
              'opacity-50 z-0': !webhookEnabled,
              'opacity-100': webhookEnabled
            }"
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsGeneral_webhookUrl_label"
            >Custobar Secret Key</label
          >
          <input
            [ngClass]="{
              'opacity-50 z-0': !webhookEnabled,
              'opacity-100': webhookEnabled
            }"
            [disabled]="!webhookEnabled"
            type="password"
            [(ngModel)]="currentProject.custobarSecret"
            placeholder="Custobar Secret Key"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
      </div>

      <div class="px-4 py-3 mt-6 text-right sm:px-6">
        <button
          *ngIf="webhookEnabled"
          (click)="saveProject()"
          class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900"
          i18n="@@funnelSettingsGeneral_save_button"
        >
          Speichern
        </button>
      </div>
    </div>
  </div>
</section>
