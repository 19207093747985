import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MailMarketingService {
  tags = {
    '8632095': 'Probeabo',
    '8635316': 'Termin vereinbaren - Oliver Monschau',
    '8636705': 'Probeabo abgeschlossen',
    '8636723': 'Kunde',
  };

  sessionCookie: any;
  standardDoubleOptInListId = 257850;

  constructor(private http: HttpClient) {
    this.login();
  }

  async login() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const login: any = await this.http
      .post(environment.backendUrl + '/mail-marketing/klicktipp-login', {
        headers: headers,
      })
      .toPromise();

    console.log(login);
    this.sessionCookie = {
      sessid: login.sessid,
      session_name: login.session_name,
    };
    return login;
  }

  async createSubscriber(email: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const data = {
      email: email,
      tagid: 8632095,
      listid: this.standardDoubleOptInListId,
      cookie: this.sessionCookie,
    };

    return await this.http
      .post(
        environment.backendUrl + '/mail-marketing/create-subscriber',
        data,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  async deleteSubscriber(email: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return await this.http
      .post(
        environment.backendUrl + '/mail-marketing/delete-subscriber',
        JSON.stringify(email),
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  async tagSubscriber(email: string, tagId: number) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const data = {
      email: email,
      tagid: tagId,
    };

    return await this.http
      .post(environment.backendUrl + '/mail-marketing/tag-subscriber', data, {
        headers: headers,
      })
      .toPromise();
  }

  async untagSubscriber(email: string, tagId: number) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const data = {
      email: email,
      tagid: tagId,
    };

    return await this.http
      .post(environment.backendUrl + '/mail-marketing/untag-subscriber', data, {
        headers: headers,
      })
      .toPromise();
  }
}
