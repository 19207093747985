<div class="flex flex-col items-center justify-center w-full px-2">
  <div class="flex items-start justify-center mb-2 rounded-lg p-3 mx-2 w-full">
    <ul role="list" class="w-full">
      <li
        class="py-4 flex w-full items-center"
        [ngClass]="{
          'justify-start': style.contentAlignment === 'start',
          'justify-center': style.contentAlignment === 'center',
          'justify-end': style.contentAlignment === 'end'
        }"
        *ngFor="let bp of block.content.bulletpoints; let answerIndex = index"
      >
        <i [ngStyle]="getBulletpointIconStyle()" [ngClass]="bp.icon"> </i>
        <div class="ml-3">
          <p class="text-sm" [ngStyle]="getBulletpointStyle()">
            {{ bp.text }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</div>
