import { Injectable } from '@angular/core';
import { async } from '@angular/core/testing';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { CustomDomain, DomainId } from '../models/project.model';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  db: any;
  isLoading: boolean = false;

  constructor(private router: Router) {
    this.db = firebase.firestore();
  }

  async setCustomDomain(domain: CustomDomain) {
    this.isLoading = true;

    return this.db
      .collection('customDomains')
      .doc(domain.id)
      .set({ ...domain })
      .then(() => (this.isLoading = false));
  }

  async getCustomDomainsOfFunnel(projectId: string) {
    this.isLoading = true;

    const snapshot = await this.db
      .collection('customDomains')
      .where('projectId', '==', projectId)
      .get();
    let customDomains: CustomDomain[] = [];
    snapshot.forEach((as: any) => {
      customDomains.push(as.data());
    });
    this.isLoading = false;
    return customDomains;
  }

  async getCustomDomain(domainId: string) {
    this.isLoading = true;

    return await this.db
      .collection('customDomains')
      .doc(domainId)
      .get()
      .then((domain: any) => {
        return domain.data();
      });
  }

  async deleteCustomDomain(domainId: string) {
    this.isLoading = true;

    return this.db
      .collection('customDomains')
      .doc(domainId)
      .delete()
      .then(() => (this.isLoading = false));
  }

  async getDomainsOfUser(userId: string) {
    this.isLoading = true;

    const snapshot = await this.db
      .collection('customDomains')
      .where('userId', '==', userId)
      .get();
    let customDomains: CustomDomain[] = [];
    snapshot.forEach((as: any) => {
      customDomains.push(as.data());
    });
    this.isLoading = false;
    return customDomains;
  }

  async setDomainId(domain: DomainId) {
    this.isLoading = true;

    return this.db
      .collection('domainIds')
      .doc(domain.id)
      .set({ ...domain })
      .then(() => (this.isLoading = false));
  }

  async getDomainId(domainId: string) {
    this.isLoading = true;

    return await this.db
      .collection('domainIds')
      .doc(domainId)
      .get()
      .then((domain: any) => {
        return domain.data();
      })
      .catch(async () => {
        await this.router.navigate(['/not-found']);
      });
  }
  async getDomainIdOfFunnel(projectId: string) {
    this.isLoading = true;

    const snapshot = await this.db
      .collection('domainIds')
      .where('projectId', '==', projectId)
      .get();

    let domainIds: DomainId[] = [];

    snapshot.forEach((as: any) => {
      domainIds.push(as.data());
    });
    this.isLoading = false;
    return domainIds;
  }

  async deleteDomainId(domainId: string) {
    this.isLoading = true;

    return this.db
      .collection('domainIds')
      .doc(domainId)
      .delete()
      .then(() => (this.isLoading = false));
  }
}
