<div
  class="flex w-full"
  [ngClass]="{
    'justify-start items-start text-left': alignment === 'start',
    'justify-center items-center text-center': alignment === 'center',
    'justify-end items-end text-right': alignment === 'end'
  }"
>
  <input
    contenteditable="true"
    [placeholder]="
      block.content.required
        ? block.content.text
        : block.content.text + ' (optional)'
    "
    [ngStyle]="style"
    class="px-2 py-2 rounded border text-gray-400 focus:border-gray-500 focus:ring-0 w-full mx-2"
    (focus)="focusIn(blockIndex)"
    (focusout)="focusOut(blockIndex)"
    [ngClass]="{
      'w-full': mobileView,
      'w-1/3': !mobileView,
      'text-center': style.contentAlignment === 'center',
      'text-left': style.contentAlignment === 'start',
      'text-right': style.contentAlignment === 'end'
    }"
  />
</div>
