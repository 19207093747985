import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Block } from 'src/app/models/block.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-text-format',
  templateUrl: './text-format.component.html',
  styleUrls: ['./text-format.component.scss'],
})
export class TextFormatComponent implements OnInit {
  @Input() title: string = '';
  @Input() doc: Document;
  @Input() selectedElement: Block;

  @Output() setClickOutsideEvent = new EventEmitter();
  constructor(private appService: AppService) {}

  ngOnInit(): void {}

  setBold() {
    /* this.setBoldEvent.emit(); */
    this.selectedElement.style!.fontWeight =
      this.selectedElement.style?.fontWeight === 'bold' ? 'normal' : 'bold';
    this.doc.execCommand('bold', false, 'asd');
    this.setClickOutsideEvent.emit();
    this.appService.setShouldSave();
  }

  setItalic() {
    this.selectedElement.style!.fontStyle =
      this.selectedElement.style!.fontStyle === 'italic' ? 'normal' : 'italic';
    this.doc.execCommand('italic', false, undefined);
    this.setClickOutsideEvent.emit();
    this.appService.setShouldSave();
  }

  setUnderline() {
    this.selectedElement.style!.textDecoration =
      this.selectedElement.style!.textDecoration === 'underline'
        ? 'none'
        : 'underline';
    this.doc.execCommand('underline', false, undefined);
    this.setClickOutsideEvent.emit();
    this.appService.setShouldSave();
  }
}
