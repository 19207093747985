<section aria-labelledby="payment-details-heading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div
      class="bg-white py-6 px-4 sm:p-6"
      [ngClass]="{
        'bg-gray-300 rounded-lg cursor-not-allowed': !inviteUsers,
        'opacity-100': inviteUsers
      }"
    >
      <div>
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
            >
          Weitere User einladen
        </h2>
      </div>

      <div
        class="mt-6 grid grid-cols-0 gap-6"
        [ngClass]="{
          'bg-gray-300 rounded-lg cursor-not-allowed': !inviteUsers,
          'opacity-100': inviteUsers
        }"
      >
        <div class="col-span-12 sm:col-span-2 relative p-2">
          <i
            class="bi bi-lock-fill text-kl-purple text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-50"
            *ngIf="!inviteUsers"
          ></i>
          <button
            *ngIf="!inviteUsers"
            class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-14 z-50"
            (click)="openPaymentPage()"
          >
            Jetzt upgraden
          </button>
          <label
            [ngClass]="{
              'opacity-50 z-0': !inviteUsers,
              'opacity-100': inviteUsers
            }"
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            >E-Mail Adresse</label
          >
          <div
            class="flex mt-1"
            [ngClass]="{
              'opacity-50 z-0': !inviteUsers,
              'opacity-100': inviteUsers
            }"
          >
            <input
              [disabled]="!inviteUsers"
              type="text"
              placeholder="E-Mail Adresse"
              [(ngModel)]="inviteUserMail"
              (keydown.enter)="addUserEmail()"
              placeholder="E-Mail"
              class="block w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            />
            <button
              (click)="addUserEmail()"
              class="ml-4 bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900"
            >
              Benutzer hinzufügen
            </button>
          </div>
        </div>
      </div>

      <div class="mt-6 grid grid-cols-0 gap-6">
        <div class="col-span-12 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            >Eingeladene Benutzer</label
          >
          <p style="color: red" *ngIf="wrongInviteEmail">
            E-Mail ist nicht korrekt
          </p>
          <div
            class="flex justify-start mt-2 items-center"
            *ngFor="let user of usersOfFunnel; let i = index"
          >
            <p class="block text-sm font-medium text-gray-500">
              {{ user.email }}
            </p>

            <button
              (click)="removeInviteMail(user, i)"
              *ngIf="usersOfFunnel.length > 1"
            >
              <i class="bi bi-dash-circle ml-4"></i>
            </button>
            <p
              class="text-xs text-gray-400 ml-4"
              *ngIf="user.id.includes('INVITE')"
            >
              Noch nicht registriert
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
