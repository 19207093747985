import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, Subject } from 'rxjs';
import { InfoModalComponent } from '../components/modals/info-modal /info-modal .component';
import { NotificationType } from '../enums/notification-type';
import { Page, Project } from '../models/project.model';
import { State } from '../models/state.model';
import { ProjectService } from './project.service';

@Injectable()
export class AppService {
  showUserMenuSubject: Subject<boolean> = new Subject<boolean>();
  showUserMenu: boolean = false;
  showHeaderSubject: Subject<boolean> = new Subject<boolean>();
  state: State = {
    editor: {
      currentProject: <Project>{},
      currentPanelIndex: 0, //drop
      currentSelectionIndex: 0, //drop
      currentFormPanelIndex: 0, //drop
      shouldSave: false,
      layout: {
        designer: { currentElementIndex: -1, selectedElement: null },
        sideDetail: {
          isDetailSidebarOpen: false,
          detailSidebarIndex: 0,
          selectedAnswer: null,
          selectedAnswerIndex: -1,
          selectedInputField: null,
          selectedInputFieldIndex: -1,
          selectedBulletpoint: null,
          selectedBulletpointIndex: -1,
        },
        pageBar: {
          isPagesBarOpen: true,
          currentPageBlocks: [],
          currentPageIndex: 0,
        },
        isBlocksOpen: false,
        isDesignMenuOpen: false,
      },
      history: {
        projectRedoArray: [],
        projectUndoArray: [],
        projectCurrentArray: [],
        showRedo: false,
        showUndo: false,
        initialUndo: true,
        isSaved: true,
        initialProject: <Project>{},
        previousPageHistoryIndex: [],
      },
    },
    funnelSettings: {
      navigationIndex: 0,
    },
    funnelLeads: {
      navigationIndex: 0,
      currentLead: undefined,
      showSlideOver: false,
    },
    notification: {
      showNotification: false,
      notificationMessage: '',
      notificationType: NotificationType.SUCCESS,
      notificationDescription: '',
      notificationTimeout: 5000,
    },
  
  };
  stateSubject = new BehaviorSubject<any>(this.state); //Subject<State> = new Subject<State>();

  private pageHistory: number[] = [];

  constructor(
    private projectService: ProjectService,
    private dialog: MatDialog
  ) {
    this.showUserMenuSubject.subscribe((value) => {
      this.showUserMenu = value;
    });

    this.stateSubject.subscribe((value) => {
      this.state = value;
    });

    this.showHeaderSubject.next(true);

    this.autoSave();
  }

  addToPageHistory(pageIndex: number) {
    this.pageHistory.push(pageIndex);
  }

  getPreviousPage(): number | undefined {
    if (this.pageHistory.length > 1) {
      this.pageHistory.pop(); // Entfernen Sie die aktuelle Seite
      return this.pageHistory[this.pageHistory.length - 1];
    }
    return undefined;
  }

  clearPageHistory() {
    this.pageHistory = [];
  }

  setStateInitial() {
    this.stateSubject.next({
      ...this.state,
    });
  }

  autoSave() {
    setInterval(() => {
      if (this.state.editor.shouldSave) {
        console.log('auto save');
        this.stateSubject.next({
          ...this.state,
          editor: {
            ...this.state.editor,
            shouldSave: false,
          },
        });
        this.saveProject(this.state.editor.currentProject);
      }
    }, 5000);
  }

  setShouldSave(saveHistory: boolean = true) {
    if (saveHistory) {
      this.setHistory();
    }
    this.stateSubject.next({
      ...this.state,
      editor: {
        ...this.state.editor,
        shouldSave: true,
      },
    });
  }

  delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  getOb() {
    return this.stateSubject.asObservable();
  }

  async showNotification(
    message: string,
    description: string,
    type: NotificationType,
    timeout: number
  ) {
    this.stateSubject.next({
      ...this.state,
      notification: {
        ...this.state.notification,
        showNotification: true,
        notificationMessage: message,
        notificationType: type,
        notificationDescription: description,
      },
    });

    await this.delay(timeout);

    this.stateSubject.next({
      ...this.state,
      notification: {
        ...this.state.notification,
        showNotification: false,
      },
    });
  }

  showDetailSidebar(value: boolean) {
    this.stateSubject.next({
      ...this.state,
      editor: {
        ...this.state.editor,
        layout: {
          ...this.state.editor.layout,
          sideDetail: {
            ...this.state.editor.layout.sideDetail,
            isDetailSidebarOpen: value,
          },
        },
      },
    });
  }

  showPageBar(value: boolean) {
    this.stateSubject.next({
      ...this.state,
      editor: {
        ...this.state.editor,
        layout: {
          ...this.state.editor.layout,
          pageBar: {
            ...this.state.editor.layout.pageBar,
            isPagesBarOpen: value,
          },
        },
      },
    });
  }

  showBlocks(value: boolean) {
    this.stateSubject.next({
      ...this.state,
      editor: {
        ...this.state.editor,
        layout: {
          ...this.state.editor.layout,
          isBlocksOpen: value,
        },
      },
    });
  }

  showDesignMenu(value: boolean) {
    this.stateSubject.next({
      ...this.state,
      editor: {
        ...this.state.editor,
        layout: {
          ...this.state.editor.layout,
          isDesignMenuOpen: value,
        },
      },
    });
  }

  setUserMenu(value: boolean) {
    this.showUserMenuSubject.next(value);
  }

  setState(value: any, saveState: boolean) {
    this.stateSubject.next(value);
    if (saveState) {
      this.saveProject(this.state.editor.currentProject);
    }
  }

  showDialog(header: string, description: string) {
    const dialogRef = this.dialog.open(InfoModalComponent, {
      width: '350px',
      data: { header, description },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (typeof result !== 'undefined') {
      }
    });
  }

  async saveProject(project?: Project) {
    await this.projectService.saveCurrentProject(project);
  }

  setHistory() {
    this.state.editor.history.projectRedoArray = [];
    //this.state.editor.history.showRedo = true;

    if (this.state.editor.history.projectUndoArray.length == 0) {
      this.state.editor.history.projectUndoArray.push(
        this.state.editor.history.initialProject
      );
    }

    if (this.state.editor.history.projectCurrentArray.length !== 0) {
      const cur_pro_pop = JSON.parse(
        JSON.stringify(this.state.editor.history.projectCurrentArray.pop())
      );
      if (!cur_pro_pop) return;
      this.state.editor.history.projectUndoArray.push(cur_pro_pop);
    } else {
      const cur_pro_pop = JSON.parse(
        JSON.stringify(this.state.editor.currentProject)
      );
      if (!cur_pro_pop) return;

      this.state.editor.history.projectUndoArray.push(cur_pro_pop);
    }

    const cur_pro = JSON.parse(
      JSON.stringify(this.state.editor.currentProject)
    );
    let test = JSON.parse(JSON.stringify(cur_pro));
    this.state.editor.history.projectCurrentArray.push(test);
    if (this.state.editor.history.projectUndoArray.length == 0) {
      this.state.editor.history.showUndo = false;
    } else {
      this.state.editor.history.showUndo = true;
    }
    // }

    this.state.editor.history.initialUndo = true;

    this.projectService.currentProjectSubject.next(
      this.state.editor.history.projectCurrentArray[0]
    );

    this.stateSubject.next({
      ...this.state,
      editor: {
        ...this.state.editor,
        history: {
          ...this.state.editor.history,
          projectCurrentArray: this.state.editor.history.projectCurrentArray,
          projectUndoArray: this.state.editor.history.projectUndoArray,
          showUndo: this.state.editor.history.showUndo,
          showRedo: this.state.editor.history.showRedo,
          initialUndo: this.state.editor.history.initialUndo,
        },
      },
    });
  }
}
