import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import { NotificationType } from 'src/app/enums/notification-type';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { ProjectService } from 'src/app/services/project.service';
import { TierService } from 'src/app/services/tier.service';
import { UserService } from 'src/app/services/user.service';
import { EmailTextDialogComponent } from '../../modals/email-text-dialog/email-text-dialog.component';
import { InfoModalComponent } from '../../modals/info-modal /info-modal .component';

@Component({
  selector: 'app-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.scss'],
})
export class SettingsGeneralComponent implements OnInit {
  currentProject: Project;
  appUrl: string;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  inviteUserMail: string = '';
  wrongEmail: boolean = false;
  notificationMail: string = '';
  user: User;
  entriesEnabled: boolean = false;
  constructor(
    private projectService: ProjectService,
    private appService: AppService,
    private dialog: MatDialog,
    private userService: UserService,
    private tierService: TierService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
      }
    });

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
        this.getWebhookEnabledRights();
      }
    });

    let parsedUrl = new URL(window.location.href);
    this.appUrl = parsedUrl.origin + '/';
  }

  getWebhookEnabledRights() {
    if (this.user.tier) {
      this.entriesEnabled = this.tierService.getRights(
        'partialEntries',
        this.user.tier!
      );
      console.log(this.entriesEnabled);
    }
  }

  openPaymentPage() {
    this.router.navigateByUrl('/user-settings?payment=true');
  }

  copyToClipboard(url: string) {
    const elem = document.createElement('textarea');
    elem.value = url;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    this.appService.showNotification(
      'Erfolgreich kopiert',
      'Der Code wurde erfolgreich in die Zwischenablage kopiert.',
      NotificationType.INFO,
      5000
    );
  }

  async saveProject() {
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.appService.showNotification(
      'Erfolgreich gespeichert',
      'Die Änderungen wurden erfolgreich gespeichert.',
      NotificationType.SUCCESS,
      3000
    );

    await this.projectService.saveCurrentProject();
  }

  async resetColor() {
    this.currentProject.progressbar.color = '#000000';
    this.projectService.currentProjectSubject.next(this.currentProject);
  }

  saveProgressbarColor() {
    let color: any = document.getElementById('favcolor');
    if (color) {
      this.currentProject.progressbar.color = color.value;
    }
    this.projectService.currentProjectSubject.next(this.currentProject);
  }

  setStatus(status: boolean) {
    this.currentProject.status = status;
  }

  addEmail() {
    if (!this.entriesEnabled) {
      const dialogRef = this.dialog.open(InfoModalComponent, {
        width: '500px',
        data: {
          header: 'Höhere Preispaket benötigt',
          lockIcon: true,
          description:
            'Du kannst in deiner Preispaket die Funktion nicht nutzen',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        }
      });
      return;
    }

    if (typeof this.currentProject.notificationMails === 'undefined') {
      this.currentProject.notificationMails = [];
    }
    if (!this.validateEmail(this.notificationMail)) {
      this.wrongEmail = true;
      console.log('wrong email');
    } else {
      this.wrongEmail = false;
      if (this.currentProject.notificationMails === null) {
        this.currentProject.notificationMails = [];
      }
      this.currentProject.notificationMails.push(this.notificationMail);
      this.projectService.currentProjectSubject.next(this.currentProject);

      this.notificationMail = '';
    }
  }

  removeMail(index: number) {
    this.currentProject.notificationMails.splice(index, 1);
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.saveProject();
  }

  validateEmail(email: string) {
    let emailValidator =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.match(emailValidator)) {
      return false;
    } else {
      return true;
    }
  }

  editMailText() {
    if (!this.entriesEnabled) {
      const dialogRef = this.dialog.open(InfoModalComponent, {
        width: '500px',
        data: {
          header: 'Höhere Preispaket benötigt',
          lockIcon: true,
          description:
            'Du kannst in deiner Preispaket die Funktion nicht nutzen',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        }
      });
      return;
    }
    const dialogRef = this.dialog.open(EmailTextDialogComponent, {
      width: '50vw',
      height: '33vw',
      data: { name: this.currentProject.notificationText },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (typeof result !== 'undefined') {
        if (!this.currentProject) return;
        this.currentProject.notificationText = result;
        this.projectService.currentProjectSubject.next(this.currentProject);

        this.saveProject();
      }
    });
  }
}
