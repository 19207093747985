<!-- combined-quiz.component.html -->
<div class="">
  <app-quiz
    *ngIf="block.showQuiz"
    [block]="block"
    [style]="style"
    [buttonStyle]="buttonStyle"
    [blocks]="blocks"
    [blockIndex]="blockIndex"
    [mobileView]="mobileView"
  >
  </app-quiz>

  <app-selection
    *ngIf="!block.showQuiz"
    [block]="block"
    [style]="style"
    [mobileView]="mobileView"
    [blocks]="blocks"
    [blockIndex]="blockIndex"
  >
  </app-selection>
</div>
