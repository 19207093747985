<div class="max-h-full flex flex-col">
  <div class="flex h-full">
    <!-- sidebar -->
    <app-sidebar
      class="hidden w-28 h-full bg-kl-grey overflow-y-auto md:block"
    ></app-sidebar>
    <div class="flex-1 flex items-stretch overflow-hidden">
      <!-- sidebar detail-->
      <app-sidebar-detail class="relative"></app-sidebar-detail>

      <!-- designer-->
      <app-funnel-designer
        [user]="user"
        style="max-height: calc(100vh - 50px)"
        class="flex-1 overflow-y-auto bg-gray-100 relative"
      ></app-funnel-designer>
    </div>
  </div>
</div>
