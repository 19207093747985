import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  loadStripe,
  StripeCardElementOptions,
  StripeElementsOptions,
  StripeIbanElementOptions,
} from '@stripe/stripe-js';
import {
  StripeCardComponent,
  StripeCardNumberComponent,
  StripeIbanComponent,
  StripeService,
} from 'ngx-stripe';
import { User } from 'src/app/models/user.model';
import { MailMarketingService } from 'src/app/services/mail-marketing.service';
import { StripeDataService } from 'src/app/services/stripe-data.service';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent implements OnInit {
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Inter", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'de',
    appearance: {
      theme: 'night',
    },
  };

  stripeIbanOptions: StripeIbanElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Inter", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
    supportedCountries: ['SEPA'],
  };

  showRequiredFields: boolean = false;
  customerID: any;
  PaymentMethodID: any;
  loading: boolean = false;
  stripePromise: any;
  vat: string = '';
  address: string = '';
  zip: string = '';
  city: string = '';
  name: string = '';

  selectedPaymentMethod: string = 'card';

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  @ViewChild(StripeIbanComponent) stripeIBAN: StripeIbanComponent;

  @Output() onSubscriptionCreated: EventEmitter<any> = new EventEmitter();
  constructor(
    private stripeService: StripeService,
    private service: StripeDataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentCardComponent>,
    private mailMarketingService: MailMarketingService
  ) {
    this.stripePromise = loadStripe(
      'pk_test_51KvztvL8SlH6dpQ2pL7OQcisrgvT5vrKXcL0cfLk5bC3KdRtD2KrzqxJDHWApeu0oaSHn4cwPguEvtpO8vSUxVcP00QN90p84E'
    );
  }

  ngOnInit(): void {}

  /*   async checkout() {
    // Call your backend to create the Checkout session.

    const priceId =
      this.data.paymentInterval === 'monthly'
        ? this.data.pricing.monthlyPaymentPriceId
        : this.data.pricing.yearlyPaymentPriceId;
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await this.stripePromise;
    console.log(stripe);
    stripe!.redirectToCheckout({
      mode: 'subscription',
      lineItems: [{ price: priceId, quantity: 1 }],
      successUrl: `${window.location.href}/success`,
      cancelUrl: `${window.location.href}/failure`,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  } */

  onClickSubmit(data: any) {
    this.service
      .createCustomer({ email: data.emailID })
      .subscribe((res: any) => {
        this.customerID = res.customer.id;
        console.log(this.customerID);
      });
  }

  async createToken() {
    this.showRequiredFields = false;
    this.loading = true;
    if (!this.data.user.stripeCustomerId) {
      /*   if (
        (this.selectedPaymentMethod == 'card' && !this.city) ||
        !this.address ||
        !this.zip
      ) {
        this.showRequiredFields = true;
        this.loading = false;
        return;
      } */

      const customerResponse: any = await this.service
        .createCustomer({
          email: this.data.user.email,
          city: this.city,
          postal_code: this.zip,
          line1: this.address,
        })
        .toPromise();

      this.data.user.stripeCustomerId = customerResponse.customer.id;
    }

    /*  const taxId = await this.service
      .createTaxId({
        customerId: this.data.user.stripeCustomerId,
        vat: this.vat,
      })
      .toPromise();

    console.log(taxId);
 */
    console.log(this.stripeIBAN);

    let paymentMethodObject: any = {};

    if (this.selectedPaymentMethod == 'card') {
      paymentMethodObject = {
        type: 'card',
        card: this.card.element,
        billing_details: { name: 'null' },
      };
    } else {
      paymentMethodObject = {
        type: 'sepa_debit',
        billing_details: {
          name: this.name,
          email: this.data.user.email,
        },

        sepa_debit: this.stripeIBAN.elements.getElement('iban')!,
      };
    }

    this.stripeService
      .createPaymentMethod(paymentMethodObject)
      .subscribe(async (result) => {
        console.log('result', result);
        if (result.paymentMethod) {
          const priceId =
            this.data.paymentInterval === 'monthly'
              ? this.data.pricing.monthlyPaymentPriceId
              : this.data.pricing.yearlyPaymentPriceId;

          const pack = {
            paymentMethodId: result.paymentMethod,
            customerID: this.data.user.stripeCustomerId,
            priceId: priceId,
          }; // Send the payment method and customer ID to your server
          this.service.startSubscription(pack).subscribe((res) => {
            console.log(res);
            this.loading = false;
            this.dialogRef.close(true);
          });
          console.log(result.paymentMethod.id);

          await this.mailMarketingService.untagSubscriber(
            this.data.user.email,
            8632095
          );

          await this.mailMarketingService.untagSubscriber(
            this.data.user.email,
            8636705
          );

          await this.mailMarketingService.tagSubscriber(
            this.data.user.email,
            8636723
          );
        } else if (result.error) {
          // Error creating the token
          this.loading = false;

          console.log('err', result.error.message);
        }
      });
  }

  updateSubscription() {}

  close() {
    this.dialogRef.close(false);
  }
}
