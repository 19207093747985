import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { User } from '../models/user.model';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserService {
  db: any;
  user: any;
  userData: User | null;

  userSubject: Subject<any> = new Subject<any>();
  userDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private afStorage: AngularFireStorage,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.db = this.firestore.firestore;

    this.loadUser();
  }

  async loadUser() {
    return this.authService.userAuthObservable$.subscribe(async (user) => {
      if (user) {
        this.userData = await this.getUser(user);
        this.setUserDataSubject(this.userData);
      }
    });
    /*  await this.auth.onAuthStateChanged(async (user) => {
      if (typeof user !== 'undefined' && user !== null) {
        this.setUserSubject(user);
        this.getUser(user);
        this.user = user;
      }
    });

    return this.user; */
  }

  setUserSubject(user: any) {
    this.userSubject.next(user);
  }

  setUserDataSubject(user: any) {
    this.userDataSubject.next(user);
  }

  /*  async getUser() {
    const user = await this.loadUser();

    if (typeof user === 'undefined' || user === null) return null;
    console.log('getuser');

    await this.db
      .collection('users')
      .doc(user.uid)
      .get()
      .then((user: any) => {
        this.setUserDataSubject(user.data());
        this.userData = user.data();
      });

    return this.userData;
  } */

  async getUser(isUserLoggedIn: any) {
    if (await this.isLoggedIn()) {
      return await this.db
        .collection('users')
        .doc(isUserLoggedIn.uid)
        .get()
        .then((user: any) => {
          return user.data();
        });
    }
  }

  async getCurrentUser() {
    if (!this.userData) return;
    return await this.db
      .collection('users')
      .doc(this.userData.id)
      .get()
      .then((user: any) => {
        return user.data();
      });
  }

  async getUsers() {
    const snapshot = await this.db.collection('users').get();
    let users: User[] = [];
    snapshot.forEach((as: any) => {
      users.push(as.data());
    });
    return users;
  }

  async getUserObservable() {
    /*   if (!this.userData) {
      await this.getUser();
    } */

    if (!this.userData) return;

    this.db
      .collection('users')
      .doc(this.userData.id)
      .onSnapshot((querySnapshot: any) => {
        this.userDataSubject.next(querySnapshot.data());
      });
  }

  async getUserById(id: string) {
    return await this.db
      .collection('users')
      .doc(id)
      .get()
      .then((user: any) => {
        return user.data();
      });
  }

  async getUsersByFunnelId(funnelId: string) {
    const snapshot = await this.db
      .collection('users')
      .where('funnelIds', 'array-contains', funnelId)
      .get();
    let users: User[] = [];
    snapshot.forEach((as: any) => {
      users.push(as.data());
    });
    return users;
  }

  async getUserByEmail(email: string) {
    const snapshot = await this.db
      .collection('users')
      .where('email', '==', email)
      .get();

    let users: User[] = [];
    snapshot.forEach((as: any) => {
      users.push(as.data());
    });

    return users[0];
  }

  sendInviteMail(mail: any) {
    console.log('sendInviteMail', mail.email);

    let link = environment.backendUrl + '/inviteUser';
    let data = JSON.stringify(mail);
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
    });
    this.http.post(link, data, { headers: headers }).subscribe(
      (data) => {
        console.log(data);
      },
      (error) => {
        console.log('Oooops!', error);
      }
    );
  }

  uploadUserPicture(userId: any, picture: any) {
    return this.afStorage.upload('/picture/' + userId, picture);
  }

  public async getUserProfileInformation() {
    const user = await this.auth.currentUser;
    let name, email, photoUrl, uid, emailVerified;

    if (user != null) {
      name = user.displayName;
      email = user.email;
      photoUrl = user.photoURL;
      emailVerified = user.emailVerified;
      uid = user.uid;
    }
    return user;
  }

  async setUser(user: User, saveSubject: boolean = true) {
    if (saveSubject) {
      this.userDataSubject.next(user);
    }
    return this.db
      .collection('users')
      .doc(user.id)
      .set({ ...user });
  }

  isLoggedIn() {
    return new Promise<any>((resolve, reject) => {
      this.auth.onAuthStateChanged((user) => {
        if (user && user !== null) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  public async verificationUserEmail(): Promise<void> {
    const user = await this.auth.currentUser;
    if (!user) return;

    return user.sendEmailVerification().then(
      () => {
        // Email sent.
      },
      (error) => {
        // An error happened.
      }
    );
  }

  public async sendUserPasswordResetEmail(): Promise<void> {
    const user: any = await this.auth.currentUser;
    if (!user) return;

    return this.auth.sendPasswordResetEmail(user.email).then(
      () => {
        // Email sent.
      },
      (error) => {
        // An error happened.
      }
    );
  }

  async deleteUser(userId: string) {
    await this.db.collection('users').doc(userId).delete();
  }
}
