<div class="h-full flex flex-col bg-gray-50">
  <div class="flex flex-col">
    <i
      class="bi bi-chevron-left text-xl cursor-pointer"
      (click)="onNoClick()"
    ></i>
    <div class="md:flex md:items-center md:justify-between p-10">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-kl-grey sm:text-3xl sm:truncate"
        >
          Funnel Vorlagen
        </h2>
        <div class="flex items-center gap-4 mt-6">
          <span
            class="w-1/4 cursor-pointer inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
          >
            <input
              type="text"
              placeholder="Suche"
              [(ngModel)]="searchString"
              (input)="filterFunnels()"
              class="bg-transparent border-transparent focus:border-transparent focus:ring-0 border-none w-full"
            />
            <i
              (click)="resetSearch()"
              *ngIf="searchString.length > 0"
              class="ml-2 mr-2 bi bi-x-lg"
            ></i>
            <i class="ml-2 bi bi-search"></i>
          </span>

          <button
            *ngIf="user.displayName === 'klickleadAdmin'"
            (click)="addCategory()"
            type="button"
            class="mr-4 bg-blue-600 text-white px-2 py-1 rounded-xl items-center justify-center text-sm"
          >
            Kategorie hinzufügen
          </button>
          <p class="text-sm ml-8">Kategorien</p>
          <span
            (click)="showAllFunnel()"
            class="cursor-pointer inline-flex items-center px-4 py-2 rounded-full text-sm font-medium bg-gray-200 text-white"
            [ngClass]="{
              ' text-white bg-kl-purple': activeCategory === 0
            }"
          >
            Alle
            <span
              class="p-1 rounded-full text-white font-bold text-xs h-5 w-5 flex items-center justify-center ml-2"
              [ngClass]="{
                ' text-kl-grey  bg-white ': activeCategory === 0,
                '   bg-kl-grey ': activeCategory !== 0
              }"
              >{{ userTemplates.length + allFunnelTemplates.length }}</span
            >
          </span>

          <span
            (click)="selectUserTemplates()"
            class="cursor-pointer inline-flex items-center px-4 py-2 rounded-full text-sm font-medium bg-gray-200 text-white"
            [ngClass]="{
              ' text-white bg-kl-purple': activeCategory === 1
            }"
          >
            <i class="bi bi-star-fill mr-2"></i>
            Meine Vorlagen
            <span
              class="p-1 rounded-full text-white font-bold text-xs h-5 w-5 flex items-center justify-center ml-2"
              [ngClass]="{
                ' text-kl-grey  bg-white ': activeCategory === 1,
                '   bg-kl-grey ': activeCategory !== 1
              }"
              >{{ userTemplates.length }}</span
            >
          </span>
          <div class="flex" *ngIf="loader">
            <div
              *ngFor="let skeleton of [1, 2, 3, 4, 5, 6]"
              class="flex flex-col justify-center items-center w-20 h-8 mr-4 my-2 bg-gray-300 rounded-xl animate-pulse shadow-lg cursor-pointer"
            ></div>
          </div>
          <span
            (click)="filterByCategory(cat, i)"
            *ngFor="let cat of categories; let i = index"
            class="cursor-pointer inline-flex items-center px-4 py-2 rounded-full text-sm font-medium bg-gray-200 text-white"
            [ngClass]="{
              ' text-white bg-kl-purple': activeCategory === i + 2
            }"
          >
            {{ cat.categoryName }}
            <span
              class="p-1 rounded-full text-white font-bold text-xs h-5 w-5 flex items-center justify-center ml-2"
              [ngClass]="{
                ' text-kl-grey  bg-white ': activeCategory === i + 2,
                '   bg-kl-grey ': activeCategory !== i + 2
              }"
              >{{ getFunnelsLength(cat) }}</span
            >

            <button
              *ngIf="user.displayName === 'klickleadAdmin'"
              (click)="deleteCategory(cat)"
              type="button"
              class="text-white rounded-xl w-8 h-8 bg-red-400"
            >
              <i class="bi bi-trash"></i>
            </button>
          </span>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-wrap overflow-auto gap-10 px-10 py-1">
      <!-- component -->
      <div
        (click)="selectStarterTemplate()"
        class="w-1/5 bg-white hover:bg-kl-grey shadow-md rounded-md px-6 py-2 my-2 cursor-pointer group"
      >
        <div class="flex flex-col justify-center items-center h-full">
          <div
            class="block text-kl-grey group-hover:text-white hover:underline"
          >
            <i class="bi bi-plus-circle text-4xl"></i>
          </div>
          <h3
            class="mt-4 text-xl text-kl-grey group-hover:text-white font-bold"
          >
            Leeren Funnel erstellen
          </h3>
        </div>
      </div>
      <div
        *ngFor="let funnel of filteredFunnels; let i = index"
        class="w-1/5 bg-white shadow-lg rounded-md px-6 py-10 my-2 cursor-pointer relative flex flex-col justify-between"
      >
        <div
          *ngIf="funnel.userId"
          class="absolute top-2 right-2 bg-kl-green px-2 rounded-full flex items-center"
        >
          <i class="bi bi-star-fill"></i>
          <p class="ml-1 text-xs">Meine Vorlage</p>
        </div>
        <div class="flex flex-col justify-start w-full">
          <div class="flex flex-col justify-center items-center w-full h-44">
            <!--   <img src="./assets/preview.png" class="w-full h-auto" /> -->
            <h3 class="text-2xl text-kl-grey font-bold">
              {{
                funnel.name.length > 20
                  ? (funnel.name | slice: 0:20) + ".."
                  : funnel.name
              }}
            </h3>
          </div>
          <div class="flex justify-between w-full mt-1">
            <span class="text-gray-400 text-xs"> </span>
          </div>
        </div>

        <div class="mt-6 flex justify-center items-center w-full">
          <button
            (click)="selectTemplate(funnel)"
            class="bg-kl-purple w-full border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-kl-grey focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
          >
            Vorlage verwenden
          </button>
          <button
            *ngIf="funnel.userId"
            class="ml-2 text-2xl"
            [matMenuTriggerFor]="menu"
          >
            <i class="bi bi-three-dots-vertical"></i>
          </button>

          <button
            *ngIf="user.displayName === 'klickleadAdmin'"
            type="button"
            class="text-white rounded-xl w-8 h-8 bg-red-400"
            (click)="deleteFunnelTemplate(funnel)"
          >
            <i class="bi bi-trash"></i>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="renameTemplate(funnel)" mat-menu-item>
              <span>Umbenennen</span>
            </button>

            <button (click)="deleteUserTemplate(funnel)" mat-menu-item>
              <span>Entfernen</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
