import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { BlockActionType } from 'src/app/enums/block-action-type';
import {
  Answer,
  Block,
  BlockAction,
  BulletPoint,
} from 'src/app/models/block.model';
import { Page } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-details-bulletpoints-item',
  templateUrl: './details-bulletpoints-item.component.html',
  styleUrls: ['./details-bulletpoints-item.component.scss'],
})
export class DetailsBulletPointsItemComponent implements OnInit {
  state$: Subscription;

  @Input() bulletpoint: BulletPoint;
  @Input() bulletpointIndex: number;
  @Output() onShowBulletpoints = new EventEmitter();
  @Output() onRemoveItem = new EventEmitter();
  tabIndex: number = 0;

  constructor(private appService: AppService) {}

  ngOnInit(): void {}

  goBack() {
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            selectedBulletpoint: null,
          },
        },
      },
    });
    this.onShowBulletpoints.emit();
  }

  setTabIndex(index: number) {
    this.tabIndex = index;
  }

  updateText(event: any) {
    this.bulletpoint.text = event.target.innerHTML;
  }

  updateIcon(icon: string) {
    this.bulletpoint.icon = icon;
  }

  removeItem() {
    this.onRemoveItem.emit(this.bulletpointIndex);
  }
}
