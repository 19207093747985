import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewFunnelTemplateComponent } from 'src/app/components/new-funnel-template/new-funnel-template.component';

@Component({
  selector: 'app-new-funnels',
  templateUrl: './new-funnels.component.html',
  styleUrls: ['./new-funnels.component.scss'],
})
export class NewFunnelsComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openTemplate() {
    const dialogRef = this.dialog.open(NewFunnelTemplateComponent, {
      panelClass: 'bg-gray-100', 
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (typeof result !== 'undefined') {
      }
    });
  }
}
