import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  NgZone,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { time } from 'console';
import { Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { LanguageService } from 'src/app/services/lang.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { RenameProjectDialogComponent } from '../modals/rename-project-dialog/rename-project-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public isAuthenticated: string;
  status: boolean = false;

  baseUrl: any;
  user: User;
  isUserLoggedIn: boolean;
  currentLanguage: any;
  firstName: string = '';
  showHeader: boolean = true;
  userMenuOpen: boolean = false;
  showStatusDropdown: boolean = false;
  nameInitials: string = '';
  currentProject: Project;
  isSaved: boolean = true;
  appUrl: string = '';
  adminEdit: string;
  constructor(
    public authService: AuthService,
    public projectService: ProjectService,
    public router: Router,
    public userService: UserService,
    public langService: LanguageService,
    private appService: AppService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params: any) => {
      this.adminEdit = params['adminEdit'];
    });
    //this.isAuthenticated = this.authService.isAuthenticated()
    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
        this.nameInitials = this.getInitials(user.displayName);
        let [first] = user.displayName.split(' ');
        this.firstName = first;
      }
    });

    this.appService.showHeaderSubject.subscribe((showHeader) => {
      this.showHeader = showHeader;
    });

    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      this.currentProject = project;
    });

    this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.isSaved = state.editor.shouldSave;

        this.currentProject = state.editor.currentProject;
        console.log(this.currentProject);
      }
    });

    let parsedUrl = new URL(window.location.href);
    this.appUrl = parsedUrl.origin + '/';
  }

  toggleStatusDropdown() {
    this.showStatusDropdown = !this.showStatusDropdown;
  }

  toggleStatus() {
    this.toggleStatusDropdown();
    this.currentProject.status = !this.currentProject.status;
    this.appService.saveProject(this.currentProject);
  }

  clickEvent() {
    this.status = !this.status;
  }

  openUserSettings() {
    this.router.navigate(['user-settings']);
  }

  dropdownHandler(element: string) {
    let single = document.getElementById(element);
    if (!single) return;
    single.classList.toggle('hidden');
  }

  async ngOnInit() {
    this.isUserLoggedIn = await this.authService.isLoggedIn();
    /*  if (this.isUserLoggedIn) {
      this.langService.loadUser();
    } */

    this.currentLanguage = await this.langService.getCurrentLanguage();
  }

  toggle(test: any) {
    if (test.id !== 'user-menu-pic') {
      this.userMenuOpen = false;
    }
  }

  openUserMenu() {
    this.userMenuOpen = !this.userMenuOpen;
  }

  setLanguage() {
    this.langService.setLanguage(this.currentLanguage);
  }

  async isLoggedIn() {
    return this.isUserLoggedIn;
  }

  public async onLogout() {
    //this.alertService.showToaster(this.langService.getWording('login', 'logoutSuccess'));
    this.authService.signOut().then((u) => {
      this.userService.userDataSubject.next(null);
      this.firstName = '';
      this.dropdownHandler('userDropdown');
      this.router.navigate(['login/']);
    });
  }

  getInitials = (fullName: string) => {
    if (!fullName) return 'KL';
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  };

  openStatistics() {
    this.router.navigate(['statistics/' + this.currentProject.id]);
  }

  openFunnelEditor() {
    this.router.navigate(['funnel-editor/' + this.currentProject.id]);
  }

  openContact() {
    this.router.navigate(['contact']);
  }

  openFunnelFlow() {
    this.router.navigate(['funnel-flow/' + this.currentProject.id]);
  }

  async openFunnels() {
    this.projectService.currentProject = <Project>{};
    this.router.navigate(['funnels']);
  }

  openAccountSettings() {
    this.projectService.currentProjectSubject.next(null);
    this.router.navigate(['user-settings']);
  }

  async renameFunnel() {
    const dialogRef = this.dialog.open(RenameProjectDialogComponent, {
      width: '350px',
      data: { name: this.currentProject.name },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (typeof result !== 'undefined') {
        this.currentProject.name = result;
        this.projectService.currentProjectSubject.next(this.currentProject);
        this.saveProject();
      }
    });
  }

  saveProject() {
    this.projectService.saveCurrentProject();
  }

  openSettings() {
    this.router.navigate(['funnel-settings/' + this.currentProject.id]);
  }
}
