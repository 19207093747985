<div class="relative h-screen flex overflow-auto">
  <div
    class="overflow-auto flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-white"
  >
    <div
      class="overflow-auto md:flex md:items-center md:justify-center md:h-full w-full md:w-2/5 p-8 md:p-10 lg:p-14 sm:rounded-lg md:rounded-none bg-white"
    >
      <div class="overflow-auto max-w-md w-full space-y-8">
        <div class="text-center mb-10">
          <div class="flex justify-center">
            <img src="./assets/klicklead-logo.svg" class="h-10 w-auto" />
          </div>
        </div>

        <form
          class="overflow-auto mt-10 space-y-6"
          [formGroup]="form"
          (ngSubmit)="submit()"
        >
          <div class="relative">
            <div class="absolute right-3 mt-8">
              <svg
                *ngIf="isEmailValid"
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-kl-green"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <i
                *ngIf="!isEmailValid && isEmailValid !== null"
                class="bi bi-x-circle text-red-500"
              ></i>
            </div>
            <label
              i18n="@@login_email_label"
              class="ml-3 text-sm font-bold text-gray-700 tracking-wide"
              >E-Mail</label
            >
            <input
              class="w-full text-base px-4 py-2 border border-gray-300 focus:outline-none rounded-lg focus:border-kl-purple"
              type="email"
              formControlName="email"
              placeholder="mail@gmail.com"
              (blur)="checkIsEmailValid()"
            />
          </div>
          <div class="relative mt-8 content-center">
            <label
              i18n="@@login_password_label"
              class="ml-3 text-sm font-bold text-gray-700 tracking-wide"
            >
              Passwort
            </label>
            <input
              class="w-full content-center text-base px-4 py-2 border rounded-lg border-gray-300 focus:outline-none focus:border-kl-purple"
              type="password"
              [type]="pass ? 'text' : 'password'"
              formControlName="password"
              placeholder="*********"
            />
            <div
              class="absolute right-0 top-9 mr-3 cursor-pointer"
              (click)="pass = !pass"
            >
              <div id="show">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99978 2C11.5944 2 14.5851 4.58667 15.2124 8C14.5858 11.4133 11.5944 14 7.99978 14C4.40511 14 1.41444 11.4133 0.787109 8C1.41378 4.58667 4.40511 2 7.99978 2ZM7.99978 12.6667C9.35942 12.6664 10.6787 12.2045 11.7417 11.3568C12.8047 10.509 13.5484 9.32552 13.8511 8C13.5473 6.67554 12.8031 5.49334 11.7402 4.64668C10.6773 3.80003 9.35864 3.33902 7.99978 3.33902C6.64091 3.33902 5.32224 3.80003 4.25936 4.64668C3.19648 5.49334 2.45229 6.67554 2.14844 8C2.45117 9.32552 3.19489 10.509 4.25787 11.3568C5.32085 12.2045 6.64013 12.6664 7.99978 12.6667ZM7.99978 11C7.20413 11 6.44106 10.6839 5.87846 10.1213C5.31585 9.55871 4.99978 8.79565 4.99978 8C4.99978 7.20435 5.31585 6.44129 5.87846 5.87868C6.44106 5.31607 7.20413 5 7.99978 5C8.79543 5 9.55849 5.31607 10.1211 5.87868C10.6837 6.44129 10.9998 7.20435 10.9998 8C10.9998 8.79565 10.6837 9.55871 10.1211 10.1213C9.55849 10.6839 8.79543 11 7.99978 11ZM7.99978 9.66667C8.4418 9.66667 8.86573 9.49107 9.17829 9.17851C9.49085 8.86595 9.66644 8.44203 9.66644 8C9.66644 7.55797 9.49085 7.13405 9.17829 6.82149C8.86573 6.50893 8.4418 6.33333 7.99978 6.33333C7.55775 6.33333 7.13383 6.50893 6.82126 6.82149C6.5087 7.13405 6.33311 7.55797 6.33311 8C6.33311 8.44203 6.5087 8.86595 6.82126 9.17851C7.13383 9.49107 7.55775 9.66667 7.99978 9.66667Z"
                    fill="#71717A"
                  />
                </svg>
              </div>
              <div id="hide" class="hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-eye-off"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#27272A"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="3" y1="3" x2="21" y2="21" />
                  <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                  <path
                    d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div class="text-sm">
              <a
                i18n="@@login_passwordForget_link"
                (click)="goToPasswordReset()"
                class="text-kl-purple hover:text-purple-800 cursor-pointer"
              >
                Passwort vergessen?
              </a>
            </div>
          </div>
          <button
            i18n="@@login_login_button"
            type="submit"
            class="w-full flex justify-center bg-kl-purple hover:bg-purple-800 text-white p-4 rounded-lg tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in"
          >
            Einloggen
          </button>
        </form>
        <p
          *ngIf="errors"
          class="mt-4 text-center rounded-lg border border-red-400 text-black p-2"
        >
          {{ errorText }}
        </p>

        <div>
          <!-- This example requires Tailwind CSS v2.0+ -->
     <!--      <div class="relative mt-6">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center">
              <span
                class="px-2 bg-white text-sm text-gray-500"
                i18n="@@login_or_label"
              >
                Oder
              </span>
            </div>
          </div> -->

    <!--       <button
            (click)="loginWithGoogle()"
            aria-label="Continue with google"
            role="button"
            class="focus:outline-none p-3 border rounded-lg border-gray-700 flex justify-center items-center w-full mt-10 hover:bg-gray-100"
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.9892 10.1871C18.9892 9.36767 18.9246 8.76973 18.7847 8.14966H9.68848V11.848H15.0277C14.9201 12.767 14.3388 14.1512 13.047 15.0812L13.0289 15.205L15.905 17.4969L16.1042 17.5173C17.9342 15.7789 18.9892 13.221 18.9892 10.1871Z"
                fill="#4285F4"
              />
              <path
                d="M9.68813 19.9314C12.3039 19.9314 14.4999 19.0455 16.1039 17.5174L13.0467 15.0813C12.2286 15.6682 11.1306 16.0779 9.68813 16.0779C7.12612 16.0779 4.95165 14.3395 4.17651 11.9366L4.06289 11.9465L1.07231 14.3273L1.0332 14.4391C2.62638 17.6946 5.89889 19.9314 9.68813 19.9314Z"
                fill="#34A853"
              />
              <path
                d="M4.17667 11.9366C3.97215 11.3165 3.85378 10.6521 3.85378 9.96562C3.85378 9.27905 3.97215 8.6147 4.16591 7.99463L4.1605 7.86257L1.13246 5.44363L1.03339 5.49211C0.37677 6.84302 0 8.36005 0 9.96562C0 11.5712 0.37677 13.0881 1.03339 14.4391L4.17667 11.9366Z"
                fill="#FBBC05"
              />
              <path
                d="M9.68807 3.85336C11.5073 3.85336 12.7344 4.66168 13.4342 5.33718L16.1684 2.59107C14.4892 0.985496 12.3039 0 9.68807 0C5.89885 0 2.62637 2.23672 1.0332 5.49214L4.16573 7.99466C4.95162 5.59183 7.12608 3.85336 9.68807 3.85336Z"
                fill="#EB4335"
              />
            </svg>
            <p
              class="text-base text-center font-medium ml-4 text-gray-700"
              i18n="@@login_loginWithGoogle_label"
            >
              Mit Google anmelden
            </p>
          </button> -->
        </div>

        <p
          class="flex flex-col items-center justify-center mt-10 text-center text-md text-gray-500"
        >
          <span i18n="@@login_noAccountYet_label">Noch kein Account?</span>
          <a
            i18n="@@login_registerNow_link"
            routerLink="/register"
            class="text-kl-purple hover:text-purple-800 no-underline hover:underline cursor-pointer transition ease-in duration-300"
            >Jetzt registrieren</a
          >
        </p>
      </div>
    </div>
    <div
      class="md:w-3/5 h-full hidden md:flex flex-auto items-center justify-center p-10 overflow-auto bg-kl-purple text-white bg-no-repeat bg-cover relative"
      style="
        background-image: url('./assets/collage_iphone-klicklead-funnels.png');
        background-position: center;
        background-size: cover;
      "
    >
      <div
        class="absolute bg-gradient-to-b from-kl-purple to-kl-purple opacity-95 inset-0 z-0"
      ></div>
      <div class="w-full max-w-md z-10">
        <div class="sm:text-4xl xl:text-5xl font-bold leading-tight mb-6"></div>
        <div class="sm:text-sm xl:text-md text-gray-200 font-normal"></div>
      </div>
      <!---remove custom style-->
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</div>
