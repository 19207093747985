<div *ngIf="currentProject">
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/gh/jerosoler/Drawflow@0.0.48/dist/drawflow.min.css"
  />
  <link rel="stylesheet" type="text/css" href="/assets/page-flow.css" />

  <div class="relative w-full h-screen p-2">
    <div id="drawflow" ondrop="drop(event)" ondragover="allowDrop(event)">
      <div class="bar-zoom">
        <i class="fas fa-search-minus" (click)="zoom_out()"></i>
        <i class="fas fa-search" (click)="zoom_reset()"></i>
        <i class="fas fa-search-plus" (click)="zoom_in()"></i>
      </div>
    </div>
  </div>
</div>
