<div
  class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-40 overflow-hidden flex flex-col items-center justify-center"
  style="background: rgba(0, 0, 0, 0.3)"
>
  <div class="fixed bottom-0 right-0 z-50 p-6">
    <div
      class="relative max-w-2xl px-8 md:px-14 pt-6 pb-9 bg-white rounded-7xl shadow-3xl z-50 border-2 rounded-xl border-gray-200"
    >
      <div class="flex flex-wrap items-center -mx-4">
        <div class="w-full px-4 mb-6 md:mb-0">
          <div class="flex w-full items-center mb-4">
            <img class="mr-8 h-10 w-10" src="./assets/cookie.svg" alt="" />
            <h3
              class="font-heading font-medium text-lg md:text-2xl leading-loose"
            >
              {{
                project.cookieConsentHeader
                  ? project.cookieConsentHeader
                  : "Cookie Einstellungen"
              }}
            </h3>
          </div>
          <p class="text-base leading-6">
            {{
              project.cookieConsentText
                ? project.cookieConsentText
                : "Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und deine Erfahrung zu verbessern."
            }}
          </p>
        </div>
        <div
          class="w-full justify-center flex flex-col lg:flex-row lg:justify-end items-center px-4"
        >
          <div
            (click)="openCookieListPage()"
            class="inline-block py-1 mb-4 lg:mb-0 lg:mr-9 lg:mt-10 font-bold leading-7 border-b border-black cursor-pointer"
          >
            <span class="tracking-tight">
              {{
                project.cookieConsentButtonTextAGB
                  ? project.cookieConsentButtonTextAGB
                  : "Cookie Richtlinien"
              }}</span
            >
          </div>
          <div
            (click)="acceptCookies()"
            class="lg:max-w-max py-3 px-10 lg:mt-10 w-full text-lg leading-7 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl cursor-pointer"
          >
            {{
              project.cookieConsentButtonTextAccept
                ? project.cookieConsentButtonTextAccept
                : " Notwendige Cookies akzeptieren"
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
