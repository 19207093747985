import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { starterBlock, starterTemplate } from 'src/app/blocks/starterBlock';
import {
  FunnelTemplate,
  TemplateCategory,
  UserTemplate,
} from 'src/app/models/funnel-template.model';
import { MediaImage } from 'src/app/models/media-image.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { MediaLibraryService } from 'src/app/services/media-library.service';
import { ProjectService } from 'src/app/services/project.service';
import { TemplateService } from 'src/app/services/template.service';
import { UserService } from 'src/app/services/user.service';
import { uuidv4 } from 'src/app/services/uuid';
import { DialogModalComponent } from '../dialog-modal/dialog-modal.component';
import { TextModalComponent } from '../text-modal/text-modal.component';

@Component({
  selector: 'app-funnel-templates-modal',
  templateUrl: './funnel-templates-modal.component.html',
  styleUrls: ['./funnel-templates-modal.component.scss'],
})
export class FunnelTemplatesModalComponent implements OnInit {
  categories: TemplateCategory[] = [];
  funnelTemplates: any[] = [];
  filteredFunnels: any[] = [];
  allFunnelTemplates: FunnelTemplate[] = [];
  userTemplates: UserTemplate[] = [];
  user: User;
  loader: boolean = false;
  currentTab: number = 0;
  activeCategory: number = 0;

  searchString: string = '';
  constructor(
    public dialogRef: MatDialogRef<FunnelTemplatesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private templateService: TemplateService,
    private userService: UserService,
    private router: Router
  ) {
    this.userService.userDataSubject.subscribe(async (user: User) => {
      if (user) {
        this.user = user;
      }
    });
  }

  async ngOnInit() {
    await this.userService.loadUser();

    await this.loadCategories();
    await this.loadFunnelTemplates();
    await this.loadUserTemplates();
    this.funnelTemplates = [...this.funnelTemplates, ...this.userTemplates];
    this.filteredFunnels = this.funnelTemplates;
    console.log(this.funnelTemplates);
  }

  setCurrentTab(index: number) {
    this.currentTab = index;
    console.log(this.currentTab);
  }

  selectUserTemplates() {
    this.filteredFunnels = this.userTemplates;
    this.activeCategory = 1;
  }

  async loadCategories() {
    this.loader = true;
    this.categories = await this.templateService.getTemplateCategories();

    this.categories.sort((a, b) => {
      const bDate: any = b.createdAt as Object;
      const aDate: any = a.createdAt as Object;
      return (
        new Date(aDate.seconds * 1000).getTime() -
        new Date(bDate.seconds * 1000).getTime()
      );
    });
    this.loader = false;
  }

  async loadFunnelTemplates() {
    this.loader = true;
    this.funnelTemplates = await this.templateService.getFunnelTemplates();
    this.allFunnelTemplates = this.funnelTemplates;

    this.funnelTemplates.sort((a, b) => {
      const bDate: any = b.createdAt as Object;
      const aDate: any = a.createdAt as Object;
      return (
        new Date(aDate.seconds * 1000).getTime() -
        new Date(bDate.seconds * 1000).getTime()
      );
    });
    this.loader = false;
  }

  async loadUserTemplates() {
    this.loader = true;
    this.userTemplates = await this.templateService.getUserTemplates(
      this.user.id
    );

    this.userTemplates.sort((a, b) => {
      const bDate: any = b.createdAt as Object;
      const aDate: any = a.createdAt as Object;
      return (
        new Date(aDate.seconds * 1000).getTime() -
        new Date(bDate.seconds * 1000).getTime()
      );
    });
    this.loader = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  returnSelection() {
    // this.dialogRef.close(this.images[this.selectedImageIndex].url);
  }

  addCategory() {
    const dialogRef = this.dialog.open(TextModalComponent, {
      width: '350px',
      data: {
        headline: 'Kategorie hinzufügen',
        label: 'Name',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        console.log(result);
        const category: TemplateCategory = {
          categoryName: result,
          createdAt: new Date(),
          id: uuidv4(),
        };

        await this.templateService.setCategoryTemplate(category);
        await this.loadCategories();
      }
    });
  }

  deleteImage(index: number) {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: {
        buttonText: 'Entfernen',
        text: 'Wollen Sie dieses Bild wirklich entfernen?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
      }
    });
  }

  async selectTemplate(template: UserTemplate | FunnelTemplate) {
    if (!this.projectService.currentProject) return;
    if (!this.userService.userData) {
      const userAuth = await this.userService.getUserProfileInformation();
      if (!userAuth) return;
      this.user = await this.userService.getUserById(userAuth.uid);
    }

    await this.projectService.createProject(
      template.name,
      template.template,
      { containerBackgroundColor: '#000000' },
      { available: true, color: '#008000' },
      this.user
    );
    this.dialogRef.close();

    this.router.navigate([
      'funnel-editor/' + this.projectService.currentProject.id,
    ]);
  }

  async selectStarterTemplate() {
    if (!this.projectService.currentProject) return;
    if (!this.userService.userData) {
      const userAuth = await this.userService.getUserProfileInformation();
      if (!userAuth) return;
      this.user = await this.userService.getUserById(userAuth.uid);
    }

    await this.projectService.createProject(
      starterTemplate.name,
      starterTemplate.template,
      { containerBackgroundColor: '#000000' },
      { available: true, color: '#008000' },
      this.user
    );
    this.dialogRef.close();

    this.router.navigate([
      'funnel-editor/' + this.projectService.currentProject.id,
    ]);
  }

  filterFunnels() {
    this.filteredFunnels = this.funnelTemplates.filter((project: Project) => {
      return (
        project.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1
      );
    });
  }

  getFunnelsLength(category: TemplateCategory) {
    return this.allFunnelTemplates.filter(
      (funnel) => funnel.catergoryId === category.id
    ).length;
  }

  filterByCategory(category: TemplateCategory, index: number) {
    this.filteredFunnels = this.allFunnelTemplates.filter(
      (funnel) => funnel.catergoryId === category.id
    );
    this.activeCategory = index + 2;
  }

  showAllFunnel() {
    this.funnelTemplates = [...this.allFunnelTemplates, ...this.userTemplates];
    this.filteredFunnels = this.funnelTemplates;

    this.activeCategory = 0;
  }

  resetSearch() {
    this.searchString = '';
    this.filteredFunnels = this.funnelTemplates;
  }

  async deleteUserTemplate(template: UserTemplate) {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: {
        buttonText: 'Entfernen',
        text: 'Wollen Sie das Template wirklich entfernen?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.templateService.deleteUserTemplate(template.id);
        await this.loadUserTemplates();
      }
    });
  }

  async deleteFunnelTemplate(template: FunnelTemplate) {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: {
        buttonText: 'Entfernen',
        text: 'Wollen Sie das Template wirklich entfernen?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.templateService.deleteFunnelTemplate(template.id);
        await this.loadFunnelTemplates();
      }
    });
  }

  async deleteCategory(category: TemplateCategory) {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: {
        buttonText: 'Entfernen',
        text: 'Wollen Sie die Kategorien wirklich entfernen?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.templateService.deleteTemplateCateory(category.id);
        await this.loadCategories();
      }
    });
  }

  renameTemplate(template: UserTemplate) {
    const dialogRef = this.dialog.open(TextModalComponent, {
      width: '350px',
      data: {
        headline: 'Template umbenennen',
        label: 'Name',
        value: template.name,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        template.name = result;
        await this.templateService.setUserTemplate(template);
      }
    });
  }
}
