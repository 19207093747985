import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UnsplashService {
  baseUrl: string = 'https://api.unsplash.com/';
  constructor(private http: HttpClient) {}

  async getRandomImages() {
    return await this.http
      .get(
        this.baseUrl +
          'photos/random?count=10&client_id=' +
          'M-ueKo7vGahfPCYli6nFQpZt4qeKo5uSHcucnXKOUlg'
      )
      .toPromise()
      .then((response: any) => response as any[]);
  }

  async searchImages(query: string) {
    return await this.http
      .get(
        this.baseUrl +
          'search/photos?query=' +
          query +
          '&count=10&client_id=M-ueKo7vGahfPCYli6nFQpZt4qeKo5uSHcucnXKOUlg'
      )
      .toPromise()
      .then((response: any) => response.results as any[]);
  }

  async trackDownload(url: string) {
    return await this.http
      .get(url + '?client_id=M-ueKo7vGahfPCYli6nFQpZt4qeKo5uSHcucnXKOUlg')
      .toPromise()
      .then((response: any) => response as any);
  }
}
