<div class="mt-8 overflow-auto h-full">
  <div class="max-w-7xl mx-auto">
    <div class="flex gap-x-4 w-full items-center mt-4 mb-12">
      <img src="./assets/klicklead-logo.svg" class="w-auto h-8" />

      <h1 class="text-3xl font-medium text-gray-900">- Benutzerverwaltung</h1>
    </div>

    <h2 class="text-lg leading-6 font-medium text-gray-900">Überblick</h2>
    <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
      <!-- Card -->

      <div class="bg-white overflow-hidden shadow rounded-lg">
        <div class="p-5">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/scale -->
            </div>
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Users (gesamt)
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ allUsers.length }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white overflow-hidden shadow rounded-lg">
        <div class="p-5">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/scale -->
            </div>
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Users (letzten 30 Tage)
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ userLast30Days.length }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white overflow-hidden shadow rounded-lg">
        <div class="p-5">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/scale -->
            </div>
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  User in der Probephase
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ userInTrial.length }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white overflow-hidden shadow rounded-lg">
        <div class="p-5">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <!-- Heroicon name: outline/scale -->
            </div>
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  User mit Bezahlaccounts
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ usersWithPayment.length }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mx-auto max-w-7xl my-10 pb-10">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Benutzer</h1>
      </div>
    </div>

    <div class="my-4 flex justify-between gap-x-2">
      <input
        type="text"
        (input)="searchUser()"
        [(ngModel)]="searchString"
        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full"
        placeholder="Suche (Benutzername, E-Mail, User-Id, Funnel-Id, Preisstufe)"
      />

      <div class="relative inline-block text-left">
        <div>
          <button
            type="button"
            class="group border rounded-full p-3 inline-flex justify-center w-32 text-sm font-medium text-gray-700 hover:text-gray-900"
            id="menu-button"
            aria-expanded="false"
            aria-haspopup="true"
            (click)="showDropdown = !showDropdown"
          >
            Preisstufe
            <svg
              class="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>

        <!--
          Dropdown menu, show/hide based on menu state.

          Entering: "transition ease-out duration-100"
            From: "transform opacity-0 scale-95"
            To: "transform opacity-100 scale-100"
          Leaving: "transition ease-in duration-75"
            From: "transform opacity-100 scale-100"
            To: "transform opacity-0 scale-95"
        -->
        <div
          *ngIf="showDropdown"
          class="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <div class="py-1" role="none">
            <!--
              Active: "bg-gray-100", Not Active: ""

              Selected: "font-medium text-gray-900", Not Selected: "text-gray-500"
            -->
            <a
              (click)="toggleDropdown('')"
              class="text-gray-500 block px-4 py-2 text-sm cursor-pointer"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
              [ngClass]="{
                'bg-gray-100 font-medium text-gray-900':
                  selectedDropdown === '',
                'text-gray-500': selectedDropdown !== ''
              }"
            >
              -
            </a>
            <a
              (click)="toggleDropdown('trial')"
              class="text-gray-500 block px-4 py-2 text-sm cursor-pointer"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
              [ngClass]="{
                'bg-gray-100 font-medium text-gray-900':
                  selectedDropdown === 'trial',
                'text-gray-500': selectedDropdown !== 'trial'
              }"
            >
              Trial
            </a>

            <a
              (click)="toggleDropdown('basic')"
              class="text-gray-500 block px-4 py-2 text-sm cursor-pointer"
              role="menuitem"
              tabindex="-1"
              id="menu-item-1"
              [ngClass]="{
                'bg-gray-100 font-medium text-gray-900':
                  selectedDropdown === 'basic',
                'text-gray-500': selectedDropdown !== 'basic'
              }"
            >
              Basic
            </a>

            <a
              (click)="toggleDropdown('professional')"
              class="text-gray-500 block px-4 py-2 text-sm cursor-pointer"
              role="menuitem"
              tabindex="-1"
              id="menu-item-2"
              [ngClass]="{
                'bg-gray-100 font-medium text-gray-900':
                  selectedDropdown === 'professional',
                'text-gray-500': selectedDropdown !== 'professional'
              }"
            >
              Professional
            </a>

            <a
              (click)="toggleDropdown('premium')"
              class="text-gray-500 block px-4 py-2 text-sm cursor-pointer"
              role="menuitem"
              tabindex="-1"
              id="menu-item-2"
              [ngClass]="{
                'bg-gray-100 font-medium text-gray-900':
                  selectedDropdown === 'premium',
                'text-gray-500': selectedDropdown !== 'premium'
              }"
            >
              Premium
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-md h-full mb-10">
      <ul role="list" class="divide-y divide-gray-200">
        <li *ngFor="let user of users; let i = index">
          <div
            class="block hover:bg-gray-100"
            (click)="openUser(i)"
            [ngClass]="{ 'bg-gray-100': i === openUserIndex }"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div
                  class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4"
                >
                  <div>
                    <p class="text-sm font-medium text-indigo-600 truncate">
                      {{ user.displayName ? user.displayName : "-" }}
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <!-- Heroicon name: solid/mail -->
                      <svg
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                        />
                        <path
                          d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                        />
                      </svg>
                      <span class="truncate">{{ user.email }}</span>
                    </p>
                  </div>
                  <div class="hidden md:block">
                    <div>
                      <p class="text-sm text-gray-900">
                        Registriert am
                        {{
                          user?.registerDate?.toDate() | date: "dd.MM.yyyy hh:MM"
                        }}
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500">
                        Preisstufe:
                        {{
                          user.tier
                            ? (user.tier | titlecase)
                            : ("trial" | titlecase)
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <i
                  class="bi bi-chevron-down text-xl cursor-pointer text-kl-purple"
                ></i>
              </div>
            </div>
          </div>
          <div
            class="w-full flex flex-col border-t mb-10 bg-gray-100"
            *ngIf="openUserIndex === i"
          >
            <app-user-dashboard-navigation
              [user]="user"
              (setTabIndex)="tabIndex = $event"
              class=""
            ></app-user-dashboard-navigation>

            <app-user-dashboard-data
              [user]="user"
              *ngIf="tabIndex == 0"
            ></app-user-dashboard-data>
            <app-user-dashboard-funnels
              class="my-6"
              [user]="user"
              *ngIf="tabIndex == 1"
            ></app-user-dashboard-funnels>
            <app-user-dashboard-payment
              [user]="user"
              *ngIf="tabIndex == 2"
            ></app-user-dashboard-payment>
            <app-user-dashboard-invoices
              [user]="user"
              *ngIf="tabIndex == 3"
            ></app-user-dashboard-invoices>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
