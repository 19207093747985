<div class="flex flex-col">
  <label for="comment" class="block text-sm font-medium text-gray-700">{{
    title
  }}</label>
  <span class="relative z-0 inline-flex rounded-md">
    <button
      (click)="setAlignment('start')"
      type="button"
      class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
      [ngClass]="{ 'bg-gray-200': alignment === 'start' }"
    >
      <i class="bi bi-justify-left"></i>
    </button>
    <button
      (click)="setAlignment('center')"
      type="button"
      class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
      [ngClass]="{ 'bg-gray-200': alignment === 'center' }"
    >
      <i class="bi bi-justify"></i>
    </button>
    <button
      (click)="setAlignment('end')"
      type="button"
      class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
      [ngClass]="{ 'bg-gray-200': alignment === 'end' }"
    >
      <i class="bi bi-justify-right"></i>
    </button>
  </span>
</div>
