import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { fonts } from 'src/app/fonts/fonts';

@Component({
  selector: 'app-font-selection',
  templateUrl: './font-selection.component.html',
  styleUrls: ['./font-selection.component.scss'],
})
export class FontSelectionComponent implements OnInit {
  @Input() title: string = '';
  @Input() selectedFont: string | undefined = 'Arial';
  @Output() onSelectFont: EventEmitter<any> = new EventEmitter<any>();
  showList: boolean = false;
  filteredFonts: string[] = fonts;
  fonts: string[] = fonts;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  toggleList() {
    this.showList = !this.showList;
  }

  closeList() {
    this.showList = false;
  }

  selectFont(font: string) {
    this.selectedFont = font;
    this.onSelectFont.emit(this.selectedFont);
    this.toggleList();
  }

  getFontCSS(font: string) {
    font = font.replace(/ /g, '+');

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://fonts.googleapis.com/css2?family=' + font
    );
  }

  getFont(font: string) {
    return 'font-family:' + font + ' !important';
  }
}
