<div
  class="flex justify-center"
  [ngClass]="{
    'w-full': mobileView,
    'w-1/3': !mobileView
  }"
>
  <iframe
    style="pointer-events: none"
    [src]="block.content.url | video"
    height="315"
    frameborder="0"
    allowfullscreen
    class="w-full"
    [ngStyle]="style"
  ></iframe>
</div>
