<div class="flex flex-col h-full justify-between">
  <div class="flex flex-col space-y-6">
    <h1 class="font-bold">E-Mail Benachrichtigungstext</h1>

    <div class="flex flex-col space-y-6">
      <div class="flex flex-col my-4">
        <p class="text-slate-600 text-sm font-bold">Platzhalter</p>
        <div class="flex gap-x-4 my-2">
          <button
            (click)="addLead()"
            color="primary"
            class="text-sm flex items-center gap-x-1 border border-slate-500 hover:bg-slate-300 rounded-lg p-1 bg-slate-200"
          >
            <i class="bi bi-plus-circle"></i>Lead Informationen hinzufügen
          </button>

          <button
            (click)="addLeadAnswers()"
            color="primary"
            class="text-sm flex items-center gap-x-1 border border-slate-500 hover:bg-slate-300 rounded-lg p-1 bg-slate-200"
          >
            <i class="bi bi-plus-circle"></i>Lead Antworten hinzufügen
          </button>
        </div>
        <mat-form-field class="my-4">
          <mat-label>E-Mail Text</mat-label>
          <textarea
            matInput
            class="border border-black"
            id="foobar"
            rows="12"
            [(ngModel)]="data.name"
          >
          </textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="flex w-full justify-between">
    <button (click)="onNoClick()" class="text-kl-purple text-sm font-bold">
      {{ langService.getWording("renameProject", "abort") }}
    </button>

    <button
      [mat-dialog-close]="data.name"
      cdkFocusInitial
      class="text-white bg-kl-purple hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3"
    >
      {{ langService.getWording("renameProject", "save") }}
    </button>
  </div>
</div>
