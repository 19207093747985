<div
  class="px-2 w-full"
  contenteditable="true"
  (blur)="update()"
  [id]="block.id"
  [innerHTML]="block.content"
></div>

<!-- <iframe src="about:blank"></iframe>
 -->

<!-- <div
class="h-full"
contenteditable="true"
(blur)="update()"
[id]="block.id"
[innerHTML]="block.content"
></div> -->
<!-- 
<script type="text/template"  >
  <!DOCTYPE html>
  <html lang="en">
  <head>
  	<meta charset="UTF-8">
  	<title>Inner</title>
  </head>
  <body contenteditable="true">
         <div
         class="h-full"
         contenteditable="true"
         (blur)="update()"
         [id]="block.id"
         [innerHTML]="block.content"
       ></div>
  </body>
  </html>
</script> -->
<!-- 

<script type="text/template" >
	<!DOCTYPE html>
	<html lang="en">
	<head>
		<meta charset="UTF-8">
		<title>Inner</title>
	</head>
	<body contenteditable="true">
	<p>IFrame scrolling test.</p>
	<p>First line</p>
	<p>Second line</p>
	<p>Third line</p>
	<p>Fourth line</p>
	<p>Fifth line</p>
	<p>Sixth line</p>
	<p>Seventh line</p>
	<p>Some text</p>
	<p>Some text</p>
	<p>Some text</p>
	</body>
	</html>
</script>

 -->
