<div>
  <label for="comment" class="block text-sm font-medium text-gray-700">{{
    title
  }}</label>
  <div class="mt-1">
    <div
      class="flex flex-col justify-center items-center w-full border rounded-lg border-gray-300 p-1"
    >
      <div
        class="w-28 flex justify-center items-center h-10 mb-2 border-b-white"
      >
        <app-text-input
          class="mr-1"
          [type]="'number'"
          [value]="
            selectedElement.style?.marginTop
              ? selectedElement.style?.marginTop
              : 0
          "
          (valueChange)="setMargin($event, 'top')"
        ></app-text-input>
        px
      </div>
      <div class="flex w-full justify-between items-center my-1">
        <div
          class="w-full flex justify-center items-center border-y-white h-10 mx-2"
        >
          <app-text-input
            class="mr-1"
            [type]="'number'"
            [value]="
              selectedElement.style?.marginLeft
                ? selectedElement.style?.marginLeft
                : 0
            "
            (valueChange)="setMargin($event, 'left')"
          ></app-text-input>
          px
        </div>
        <div
          class="h-20 w-full border border-black border-dashed bg-gray-100 rounded-lg"
        ></div>
        <div
          class="w-full ml-4 flex justify-center items-center border-y-white h-10 mx-2"
        >
          <app-text-input
            class="mr-1"
            [type]="'number'"
            [value]="
              selectedElement.style?.marginRight
                ? selectedElement.style?.marginRight
                : 0
            "
            (valueChange)="setMargin($event, 'right')"
          ></app-text-input>
          px
        </div>
      </div>
      <div
        class="w-28 flex justify-center items-center border-t-white mb-2 h-10"
      >
        <app-text-input
          class="mr-1"
          [type]="'number'"
          [value]="
            selectedElement.style?.marginBottom
              ? selectedElement.style?.marginBottom
              : 0
          "
          (valueChange)="setMargin($event, 'bottom')"
        ></app-text-input>
        px
      </div>
    </div>
  </div>
</div>
