<!-- component -->
<div class="min-h-full bg-gray-50 pb-6 flex flex-col items-start">
  <div
    *ngIf="leftTabIndex === 0 && currentProject"
    class="w-full"
    cdkDropList
    #elementsList="cdkDropList"
    (cdkDropListDropped)="drop($event)"
  >
    <span
      (click)="togglePageBlock()"
      class="
        bg-gray-100
        hover:bg-gray-300
        text-gray-800 text-xs
        font-medium
        inline-flex
        items-center
        px-2.5
        py-0.5
        rounded
        mr-2
        cursor-pointer
      "
    >
      <i *ngIf="showPageBlocks" class="bi bi-chevron-down mr-2"></i>
      <i *ngIf="!showPageBlocks" class="bi bi-chevron-right mr-2"></i>
      Seitenblöcke anzeigen
    </span>

    <div
      cdkDrag
      class="flex flex-col"
      *ngFor="
        let page of currentProject.pages;
        let i = index;
        first as isFirst;
        last as isLast
      "
    >
      <div
        cdkDropList
        [cdkDropListData]="page.blocks"
        [id]="page.id"
        [cdkDropListConnectedTo]="pageDropIds"
        (cdkDropListDropped)="dropElement($event, i)"
      >
        <div class="flex px-2 justify-between items-center w-full my-4 mb-2">
          <div class="flex justify-between items-center">
            <i class="bi bi-grip-vertical mr-2" cdkDragHandle></i>

            <div
              (click)="selectPage(i)"
              class="
                rounded-full
                w-8
                h-8
                flex
                justify-center
                items-center
                border border-gray-300
                cursor-pointer
              "
              [ngClass]="i === currentPageIndex ? 'border-blue-400' : ''"
            >
              <p>{{ i + 1 }}</p>
            </div>
          </div>
          <div
            class="flex justify-center items-center cursor-pointer"
            (click)="selectPage(i)"
          >
            <p [ngClass]="i === currentPageIndex ? 'text-blue-400' : ''">
              {{
                page.name.length > 20
                  ? (page.name | slice: 0:20) + "..."
                  : page.name
              }}
            </p>
          </div>
          <div class="flex items-center justify-center">
            <button mat-button [matMenuTriggerFor]="menu">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                [ngClass]="i === currentPageIndex ? 'text-blue-400' : ''"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editPageName(i)">
                Umbenennen
              </button>
              <button mat-menu-item (click)="copyPage(i)">Duplizieren</button>
              <button mat-menu-item (click)="deletePage(i)">Entfernen</button>
            </mat-menu>
          </div>
        </div>
        <div *ngIf="showPageBlocks" class="flex flex-col justify-center">
          <div
            class="text-xs flex justify-center items-center mb-4"
            *ngFor="let block of page.blocks; let blockIndex = index"
            cdkDrag
            [cdkDragData]="block"
          >
            <div class="flex flex-col items-center cursor-pointer">
              <i [ngClass]="getBlockIcon(block.type)" class="text-2xl"></i>
              <p>{{ getBlockName(block.type) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center mt-10">
      <button (click)="addPage()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8 hover:text-blue-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    </div>
  </div>
</div>
