import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-details-page',
  templateUrl: './details-page.component.html',
  styleUrls: ['./details-page.component.scss'],
})
export class DetailsPageComponent implements OnInit {
  currentProject: Project;
  pageIndex: number = 0;

  facebookEvents: string[] = [
    '-',
    'AddPaymentInfo',
    'AddToCart',
    'AddToWishlist',
    'CompleteRegistration',
    'Contact',
    'CustomizeProduct',
    'Donate',
    'FindLocation',
    'InitiateCheckout',
    'Lead',
    'Purchase',
    'Schedule',
    'Search',
    'StartTrial',
    'SubmitApplication',
    'Subscribe',
    'ViewContent',
  ];

  googleEvents: string[] = [
    '-',
    'add_payment_info',
    'add_to_cart',
    'add_to_wishlist',
    'begin_checkout',
    'checkout_progress',
    'generate_lead',
    'login',
    'purchase',
    'refund',
    'remove_from_cart',
    'search',
    'select_content',
    'set_checkout_option',
    'share',
    'sign_up',
    'view_item',
    'view_item_list',
    'view_promotion',
    'view_search_results',
  ];

  constructor(public appService: AppService) {}

  async ngOnInit() {
    this.currentProject = this.appService.state.editor.currentProject;

    this.pageIndex =
      this.appService.state.editor.layout.pageBar.currentPageIndex;
  }

  setPageName(name: string) {
    this.currentProject.pages[this.pageIndex].name = name;
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        currentProject: {
          ...this.appService.state.editor.currentProject,
          pages: [...this.currentProject.pages],
        },
      },
    });
    this.appService.setShouldSave();
  }

  setPageBackgroundcolor(color: string) {
    this.currentProject.pages[this.pageIndex].style.containerBackgroundColor =
      color;
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        currentProject: {
          ...this.appService.state.editor.currentProject,
          pages: [...this.currentProject.pages],
        },
      },
    });
    this.appService.setShouldSave();
  }

  setFacebookEvent(event: string) {
    if (event === '-') {
      this.currentProject.pages[this.pageIndex].fbEvent = '';
    } else {
      this.currentProject.pages[this.pageIndex].fbEvent = event;
    }
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        currentProject: {
          ...this.appService.state.editor.currentProject,
          pages: [...this.currentProject.pages],
        },
      },
    });
    this.appService.setShouldSave();
  }

  setGoogleEvent(event: string) {
    if (event === '-') {
      this.currentProject.pages[this.pageIndex].googleEvent = '';
    } else {
      this.currentProject.pages[this.pageIndex].googleEvent = event;
    }
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        currentProject: {
          ...this.appService.state.editor.currentProject,
          pages: [...this.currentProject.pages],
        },
      },
    });
    this.appService.setShouldSave();
  }
}
