<div class="bg-white w-full overflow-scroll">
  <div
    (click)="togglePageBar()"
    class="z-40 flex flex-col justify-center items-center absolute left-1/2 bg-white rounded-t-full p-1 cursor-pointer"
    [ngClass]="{ '-top-6': !isPageBarOpen, '-top-7': isPageBarOpen }"
  >
    <i
      *ngIf="!isPageBarOpen"
      class="font-bold bi bi-chevron-up animate-bounce"
    ></i>

    <i *ngIf="isPageBarOpen" class="bi bi-chevron-down"></i>
  </div>
  <div *ngIf="isPageBarOpen" class="">
    <div class="flex justify-start items-center">
      <nav
        class="relative z-0 rounded-lg flex divide-x divide-gray-200"
        aria-label="Tabs"
        cdkDropList
        [cdkDropListData]="pages"
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="dropElement($event)"
      >
        <a
          *ngFor="let page of pages; let i = index"
          class="group text-kl-grey border rounded-lg group relative flex-1 overflow-hidden bg-white py-3 w-44 h-12 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer shadow m-1"
          aria-current="page"
          cdkDrag
          cdkDragLockAxis="x"
        >
          <div class="flex justify-between">
            <span class="w-full h-full" (click)="selectPage(i)"
              >{{
                page.name.length > 16
                  ? (page.name | slice: 0:16) + ".."
                  : page.name
              }}
            </span>
            <div
              class="group-hover:opacity-100 opacity-0 px-2"
              mat-button
              [matMenuTriggerFor]="menu"
            >
              <i class="bi bi-three-dots-vertical"></i>
            </div>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="editPageName(i)"
                i18n="@@funnelPageBar_pageRename_label"
              >
                Umbenennen
              </button>
              <button
                mat-menu-item
                (click)="copyPage(i)"
                i18n="@@funnelPageBar_pageduplicate_label"
              >
                Duplizieren
              </button>
              <button
                mat-menu-item
                (click)="deletePage(i)"
                i18n="@@funnelPageBar_pageDelete_label"
              >
                Entfernen
              </button>
            </mat-menu>
          </div>
          <span
            aria-hidden="true"
            class="bg-transparent absolute inset-x-0 bottom-0 h-0.5"
            [ngClass]="{
              'bg-kl-purple': currentPageIndex === i
            }"
          ></span>
        </a>
      </nav>
      <i
        (click)="addPage()"
        class="bi bi-plus-circle-fill text-2xl text-kl-purple hover:text-kl-grey ml-6 cursor-pointer"
      ></i>
    </div>
  </div>
</div>
