<div
  style="width: 900px"
  class="absolute -left-44 -bottom-20 bg-kl-grey rounded-xl flex flex-wrap justify-between p-4 z-50"
>
  <div
    class="flex flex-col items-center cursor-pointer"
    *ngFor="let block of blockList"
    (click)="addElement(block)"
  >
    <i
      [ngClass]="getBlockIcon(block.type)"
      class="text-white cursor-pointer"
    ></i>
    <div class="text-white text-xs">
      {{ getBlockName(block.type) }}
    </div>
  </div>
</div>
