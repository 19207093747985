<div class="h-full flex flex-col">
  <div class="flex h-full">
    <!-- Narrow sidebar -->

    <!-- Content area -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <!-- Main content -->
      <div class="flex-1 flex items-stretch overflow-hidden">
        <main class="flex-1 overflow-y-auto bg-gray-100 py-6 px-12 relative">
          <h2
            i18n="@@funnelStatistic_funnelStatisticHeadline_label"
            class="text-2xl font-bold leading-7 text-kl-grey sm:text-3xl sm:truncate mb-6"
          >
            Funnel-Leads
          </h2>
          <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <app-leads-navigation
              class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2"
            ></app-leads-navigation>

            <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 h-full mb-10">
              <app-leads-charts
                [allLeads]="allLeads"
                [answerSubmissions]="answerSubmissions"
                [impressions]="impressions"
                *ngIf="navigationIndex === 0 && impressions"
              ></app-leads-charts>
              <app-leads-table
                [allLeads]="allLeads"
                *ngIf="navigationIndex === 1"
              ></app-leads-table>

              <app-leads-partial-entries
                *ngIf="navigationIndex === 2"
              ></app-leads-partial-entries>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</div>
<app-leads-slide-over></app-leads-slide-over>
