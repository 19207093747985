<main class="flex flex-col h-full">
  <button
    *ngIf="user?.tier !== 'premium'"
    (click)="navigateToPayment()"
    type="button"
    class="z-50 absolute right-14 bottom-24 mr-11 flex justify-center text-sm items-center px-2.5 py-1 bg-gradient-to-r from-purple-800 to-green-500 text-white rounded-full"
    i18n="@@funnelDesigner_upgradeNow_label"
  >
    <i class="mr-2 text-lg bi bi-arrow-up-circle-fill"></i>
    Jetzt upgraden
  </button>
  <div class="absolute z-50 flex right-6 bottom-24">
    <span class="relative z-0 inline-flex rounded-md shadow-sm">
      <button
        (click)="toggleSize()"
        type="button"
        class="relative inline-flex items-center p-2 text-sm font-medium text-white bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 focus:z-10 focus:outline-none"
        [ngClass]="{ 'bg-gray-200': mobileView }"
      >
        <svg
          class="w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path
            d="M304 0h-224c-35.35 0-64 28.65-64 64v384c0 35.35 28.65 64 64 64h224c35.35 0 64-28.65 64-64V64C368 28.65 339.3 0 304 0zM192 480c-17.75 0-32-14.25-32-32s14.25-32 32-32s32 14.25 32 32S209.8 480 192 480zM304 64v320h-224V64H304z"
          />
        </svg>
      </button>
      <button
        (click)="toggleSize()"
        [ngClass]="{ 'bg-gray-200': !mobileView }"
        type="button"
        class="relative inline-flex items-center p-2 -ml-px text-sm font-medium text-white bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 focus:z-10 focus:outline-none"
      >
        <svg
          class="w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path
            d="M528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h192L224 464H152C138.8 464 128 474.8 128 488S138.8 512 152 512h272c13.25 0 24-10.75 24-24s-10.75-24-24-24H352L336 416h192c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM512 288H64V64h448V288z"
          />
        </svg>
      </button>
    </span>
  </div>
  <section
    aria-labelledby="primary-heading"
    class="flex flex-col items-center justify-between flex-1 h-full min-w-0"
    cdkDropListGroup
  >
    <div
      class="flex flex-col items-center justify-start w-full py-10 mt-6 overflow-auto"
      style="min-height: calc(100vh - 260px)"
      cdkDropList
      [cdkDropListData]="blocks"
      (cdkDropListDropped)="drop($event)"
      id="editor"
      #editor="cdkDropList"
    >
      <div
        (clickOutside)="setElementToNull()"
        [ngClass]="{ 'w-full': !mobileView, 'w-auto': mobileView }"
        class="flex flex-col"
      >
        <div
          class="z-auto flex justify-start h-32 px-4 group hover:border-blue-400"
          *ngIf="blocks.length < 1"
        >
          <div
            class="flex flex-col h-full transform bg-white border-t border-l border-r border-gray-400 rounded-t-lg group-hover:border-blue-400"
            [ngClass]="{
              'w-full ': !mobileView,
              'w-96': mobileView,
              'border-b-0': currentProject?.showBadge,
              'border-b rounded-b-lg': !currentProject?.showBadge
            }"
            [ngStyle]="{
              'background-color':
                currentProject?.designPresets?.backgroundColor! || '#ffffff'
            }"
          ></div>
          <div
            class="flex flex-col items-center justify-center w-32 pl-2"
          ></div>
        </div>
        <div
          class="z-50 flex justify-start group hover:border-blue-400"
          *ngFor="
            let block of blocks;
            let i = index;
            first as isFirst;
            last as isLast
          "
          [ngClass]="{ 'w-full': !mobileView, 'w-3/5': mobileView }"
          (cdkDragStarted)="cdkDragStarted($event)"
        >
          <div
            class="flex flex-col h-full transform bg-white border-l border-r border-gray-400 group-hover:border-blue-400"
            [ngClass]="{
              'border-t rounded-t-lg ': isFirst,
              'border-b rounded-b-lg  ': !currentProject.showBadge && isLast,
              'justify-start items-start text-left':
                getAlignment(i) === 'start',
              'justify-center items-center text-center':
                getAlignment(i) === 'center',
              'justify-end items-end text-right': getAlignment(i) === 'end',
              'w-full ': !mobileView,
              'w-96': mobileView,
              'border-l-2 border-r-2 border-t-2 border-b-2  border-blue-400':
                highlightedElementIndex === i
            }"
            (mouseenter)="hoverListItem(i)"
            (mouseleave)="hoverListItem(null)"
            [ngStyle]="getBackgroundColor(block)"
          >
            <link [href]="getFontCSS(i)" rel="stylesheet" />
            <div
              class="bg-white example-custom-placeholder"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
              [ngStyle]="{ 'min-height.px': dragHeight }"
              *cdkDragPlaceholder
            ></div>

            <app-picture
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'picture'"
              [block]="block"
              [style]="getStyle(i)"
              class="flex px-0.5 overflow-hidden"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView,
                'justify-start items-start text-left':
                  getAlignment(i) === 'start',
                'justify-center items-center text-center':
                  getAlignment(i) === 'center',
                'justify-end items-end text-right': getAlignment(i) === 'end'
              }"
              s
            ></app-picture>

            <app-quiz
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'quiz'"
              [block]="block"
              [style]="getStyle(i)"
              [blockIndex]="i"
              [blocks]="blocks"
              [mobileView]="mobileView"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
            ></app-quiz>

            <app-header-block
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'header'"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96 px-4': mobileView
              }"
              [ngStyle]="getStyle(i)"
              [block]="block"
              style="width: 23.8rem"
            ></app-header-block>

            <app-upload
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'upload'"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
              [style]="getStyle(i)"
              [block]="block"
              [mobileView]="mobileView"
            ></app-upload>

            <app-bulletpoints
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'bulletpoints'"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
              [style]="getStyle(i)"
              [block]="block"
              [blocks]="blocks"
              [index]="i"
            ></app-bulletpoints>

            <app-fakeloader
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'fakeloader'"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
              [style]="getStyle(i)"
              [block]="block"
            ></app-fakeloader>

            <app-button
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'button'"
              [ngClass]="{
                'w-full mx-2': !mobileView,
                'w-96': mobileView
              }"
              [style]="getStyle(i)"
              [block]="block"
              [mobileView]="mobileView"
            ></app-button>

            <app-video
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'video'"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
              [style]="getStyle(i)"
              [block]="block"
              [mobileView]="mobileView"
              class="flex justify-center"
            ></app-video>

            <app-selection
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'selection'"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
              [style]="getStyle(i)"
              [block]="block"
              [blockIndex]="i"
              [blocks]="blocks"
              [mobileView]="mobileView"
            ></app-selection>

            <app-selectionquiz
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'selectionquiz'"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
              [style]="getStyle(i)"
              [block]="block"
              [blockIndex]="i"
              [blocks]="blocks"
              [mobileView]="mobileView"
            ></app-selectionquiz>

            <app-socialmedia
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'socialmedia'"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
              [style]="getStyle(i)"
              [block]="block"
            ></app-socialmedia>

            <app-textfield
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'textfield'"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
              [blockIndex]="i"
              [style]="getStyle(i)"
              [block]="block"
              [mobileView]="mobileView"
              [alignment]="getAlignment(i)"
            ></app-textfield>

            <app-form
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'form'"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
              [style]="getStyle(i)"
              [block]="block"
              [blockIndex]="i"
              [blocks]="blocks"
              [mobileView]="mobileView"
            ></app-form>

            <div
              *ngIf="isBackButtonAllowed(block) && pageIndex > 0"
              class="flex justify-center"
            >
              <button
                class="flex items-center w-auto p-3 text-sm text-gray-500 rounded focus:outline-none focus:shadow-outline"
              >
                <span class="mx-2">zurück</span>
              </button>
            </div>

            <app-zw-westnetz
              (click)="selectBlock(block, i)"
              *ngIf="block.type === 'zw_westnetz'"
              [ngClass]="{
                'w-full ': !mobileView,
                'w-96': mobileView
              }"
              [style]="getStyle(i)"
              [block]="block"
              [blockIndex]="i"
              [blocks]="blocks"
              [mobileView]="mobileView"
            ></app-zw-westnetz>

            <app-design-menu
              (setClickOutsideEvent)="onClickOutside(block.id)"
              [doc]="doc"
              [selectedElement]="currentElement"
              *ngIf="currentElementIndex === i && currentElement"
            >
            </app-design-menu>
          </div>
          <div class="flex flex-col items-center justify-center w-32 pl-2">
            <p *ngIf="block.isHeader" class="text-sm text-gray-300">Header</p>
            <div
              class="flex self-center opacity-0 group-hover:opacity-100"
              (mouseenter)="hoverListItem(i)"
              (mouseleave)="hoverListItem(null)"
            >
              <!--  <i class="bi bi-grip-vertical" cdkDragHandle></i> -->
              <i
                class="cursor-pointer bi bi-chevron-down"
                (click)="moveDown(i)"
              ></i>
              <i
                class="cursor-pointer bi bi-chevron-up"
                (click)="moveUp(i)"
              ></i>
              <button class="self-end" (click)="copyElement(i)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
              </button>
              <button class="self-end" (click)="removeElement(i)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          *ngIf="currentProject"
          class="z-auto flex justify-start w-full group hover:border-blue-400"
          [ngClass]="{
            'px-4  ': blocks.length < 1
          }"
        >
          <div
            *ngIf="currentProject.showBadge"
            class="flex flex-col items-center justify-center h-full text-center transform bg-white border-b border-l border-r border-gray-400 rounded-b-lg group-hover:border-blue-400"
            [ngClass]="{
              'w-full ': !mobileView,
              'w-96': mobileView
            }"
            [ngStyle]="getBadgeBackgroundColor()"
          >
            <button
              (click)="showUpgradeModal()"
              type="button"
              class="my-10 w-auto gap-x-2 inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kl-purple"
            >
              <p
                class="text-xs text-kl-grey"
                i18n="@@funnelDesigner_badgeCreatedWith_label"
              >
                Erstellt mit
              </p>
              <img
                class="w-24"
                src="./assets/klicklead-logo.svg"
                alt="klicklead logo"
              />
            </button>
          </div>
          <div class="w-32"></div>
        </div>
      </div>
      <div class="flex items-start justify-start w-auto mb-32">
        <div class="relative flex flex-col items-center justify-center w-96">
          <i
            (click)="toggleBlocks()"
            class="mt-10 text-3xl cursor-pointer bi bi-plus-circle-dotted animate-pulse"
          ></i>
          <app-blocks-menu
            (onAddElement)="addElement($event)"
            *ngIf="isBlocksOpen"
          ></app-blocks-menu>
          <div *ngIf="blocks.length === 0">
            <i class="mb-4 text-2xl bi bi-arrow-up animate-bounce"></i>
            <p class="text-xl" i18n="@@funnelDesigner_emptyFunnelMessage_label">
              Füge Deinen ersten Block hinzu
            </p>
          </div>
        </div>

        <div class="w-32"></div>
      </div>
    </div>

    <!-- pages bar -->
    <app-pages-bar
      class="relative flex items-center w-full gap-8 p-2 bg-white"
    ></app-pages-bar>
  </section>
</main>
