import { AfterViewInit, Component } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import {
  CustomDomain,
  DomainId,
  Page,
  Project,
} from 'src/app/models/project.model';

import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { ProjectService } from 'src/app/services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/lang.service';
import { EmailTextDialogComponent } from 'src/app/components/modals/email-text-dialog/email-text-dialog.component';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { State } from 'src/app/models/state.model';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import version from 'src/git-version.json';
import { User } from 'src/app/models/user.model';
import { uuidv4 } from 'src/app/services/uuid';
import { environment } from 'src/environments/environment';
import { DialogModalComponent } from 'src/app/components/modals/dialog-modal/dialog-modal.component';
import { AddDomainModalComponent } from 'src/app/components/modals/add-domain-modal/add-domain-modal.component';
import { DomainService } from 'src/app/services/domain.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isUserInTrialAllowed } from 'src/app/trialCheck';

@Component({
  selector: 'app-funnel-settings',
  templateUrl: './funnel-settings.component.html',
  styleUrls: ['./funnel-settings.component.scss'],
})
export class FunnelSettingsComponent {
  state$: Subscription;
  currentProject: Project;
  statisticData = [];

  projectId: string;
  displayError: boolean = false;
  notificationMail: string;
  inviteUserMail: string;
  wrongEmail: boolean = false;
  wrongInviteEmail: boolean = false;
  iframeCode: string;
  height: number = 400;
  width: number = 100;
  widthUnit: string = '%';
  heightUnit: string = 'px';
  appUrl: string;

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  appVersion: string;
  usersOfFunnel: User[] = [];
  currentUser: User;

  customDomains: CustomDomain[] = [];
  domainIds: DomainId[] = [];
  navigationIndex: number = 0;
  adminEdit: string;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    public langService: LanguageService,
    public projectService: ProjectService,
    private dialog: MatDialog,
    private appService: AppService,
    private router: Router,
    private domainService: DomainService,
    private http: HttpClient
  ) {
    this.projectId = this.route.snapshot.params.id;
    this.adminEdit = this.route.snapshot.queryParamMap.get('adminEdit') || '';

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        const isAdmin = this.checkIfEmailIncludesAdminMailaddress(user);
        if (this.adminEdit !== 'true' && isAdmin) {
          if (!user.funnelIds.includes(this.projectId)) {
            this.router.navigate(['/funnels']);
          }
        }
        if (!isUserInTrialAllowed(user)) {
          this.router.navigateByUrl('/user-settings?payment=true');
        }
      }
    });

    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.navigationIndex = state.funnelSettings.navigationIndex;
      }
    });

    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
      }
    });

    let parsedUrl = new URL(window.location.href);
    this.appUrl = parsedUrl.origin + '/';

    this.appVersion = version.shortSHA;
  }

  checkIfEmailIncludesAdminMailaddress(user: User) {
    if (
      user.email.includes('@zewotherm.de') ||
      user.email.includes('@zw-systems.de') ||
      user.email.includes('@klicklead.de')
    ) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    this.projectService.currentProject =
      await this.projectService.getProjectById(this.projectId);

    this.setIFrameCode();

    this.usersOfFunnel = await this.userService.getUsersByFunnelId(
      this.projectId
    );

    await this.loadDomains();
  }

  async loadDomains() {
    this.customDomains = await this.domainService.getCustomDomainsOfFunnel(
      this.projectId
    );
    this.domainIds = await this.domainService.getDomainIdOfFunnel(
      this.projectId
    );
  }

  async deleteDomainId(domain: DomainId, index: number) {
    await this.domainService.deleteDomainId(domain.id);
    this.domainIds.splice(index, 1);
  }

  async deleteCustomDomain(customDomain: CustomDomain, index: number) {
    await this.domainService.deleteCustomDomain(customDomain.id);
    this.customDomains.splice(index, 1);

    const customDomainURL = new URL(customDomain.domain);

    let link = `https://api.vercel.com/v8/projects/prj_MudunYJ1NG3wSRRSpdIJ7mHsjDN0/domains/${customDomainURL.host}?teamId=team_b5hpvvNtFK82xKc6Rv6HkrqE`;

    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      Authorization: `Bearer ${environment.vercelToken}`,
    });
    this.http.delete(link, { headers: headers }).subscribe(
      (data) => {
        console.log(data);
      },
      (error) => {
        console.log('Oooops!', error);
      }
    );
  }

  copyInputMessage(inputElement: any) {
    let id = this.currentProject.urlShort
      ? this.currentProject.urlShort
      : this.currentProject.id;
    var dummy = document.createElement('textarea');
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = 'https://funnelbuilder.com/' + id.toString();
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    // this.alertService.showToaster('URL in die Zwischenablage kopiert');
  }

  async saveProject() {
    this.projectService.currentProjectSubject.next(this.currentProject);

    await this.projectService.saveCurrentProject();
  }

  resetColor() {
    this.currentProject.progressbar.color = '#000000';
    this.projectService.currentProjectSubject.next(this.currentProject);
    this.saveProject();
  }

  saveProgressbarColor() {
    let color: any = document.getElementById('favcolor');
    if (color) {
      this.currentProject.progressbar.color = color.value;
    }
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.saveProject();
  }

  togglePagesDropdown() {
    const element = document.getElementById('dropdownInformation');
    if (!element) return;
    element.classList.toggle('hidden');
  }

  setAnalyticsStartPage(index: number) {
    this.currentProject.startAnalyticsPageIndex = index;
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.saveProject();
    this.togglePagesDropdown();
  }

  async setURL(userUrl: string) {
    userUrl = userUrl.trim();

    if (userUrl === '') {
      let project: Project = await this.projectService.getProjectById(
        this.currentProject.id
      );
      if (!project.urlShort) return;

      await this.projectService.deleteUrl(project.urlShort);
      this.currentProject.urlShort = this.currentProject.id.toString();
      this.projectService.currentProjectSubject.next(this.currentProject);

      this.saveProject();
      return;
    }

    if (!this.currentProject.urlShort) return;

    this.currentProject.urlShort = this.currentProject.urlShort.trim();
    const url = await this.projectService.getUrl(userUrl);
    if (typeof url === 'undefined') {
      let project: Project = await this.projectService.getProjectById(
        this.currentProject.id
      );
      this.displayError = false;
      await this.projectService.setUrl(
        this.currentProject.id,
        this.currentProject.urlShort
      );

      project.urlShort = project.urlShort ? project.urlShort.trim() : userUrl;
      if (project.urlShort !== this.currentProject.urlShort) {
        await this.projectService.deleteUrl(project.urlShort);
      }
      this.projectService.currentProjectSubject.next(this.currentProject);

      this.saveProject();
    } else {
      if (url.projectId === this.currentProject.id) {
        let project: Project = await this.projectService.getProjectById(
          this.currentProject.id
        );
        project.urlShort = project.urlShort ? project.urlShort.trim() : userUrl;

        this.displayError = false;
        await this.projectService.setUrl(
          this.currentProject.id,
          this.currentProject.urlShort
        );
        if (project.urlShort !== this.currentProject.urlShort) {
          await this.projectService.deleteUrl(project.urlShort);
        }
        this.projectService.currentProjectSubject.next(this.currentProject);

        this.saveProject();
      } else {
        this.displayError = true;
      }
    }
  }

  addEmail() {
    if (typeof this.currentProject.notificationMails === 'undefined') {
      this.currentProject.notificationMails = [];
    }
    if (!this.validateEmail(this.notificationMail)) {
      this.wrongEmail = true;
    } else {
      this.wrongEmail = false;
      if (this.currentProject.notificationMails === null) {
        this.currentProject.notificationMails = [];
      }
      this.currentProject.notificationMails.push(this.notificationMail);
      this.projectService.currentProjectSubject.next(this.currentProject);

      this.saveProject();
      this.notificationMail = '';
    }
  }

  removeMail(index: number) {
    this.currentProject.notificationMails.splice(index, 1);
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.saveProject();
  }

  async removeInviteMail(user: User, index: number) {
    const name = user.displayName ? user.displayName : user.email;
    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: {
        buttonText: 'Entfernen',
        text:
          'Wollen sie den Benutzer ' +
          name +
          ' wirklich aus dem Funnel entfernen?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.usersOfFunnel.splice(index, 1);
        user.funnelIds.splice(
          user.funnelIds.indexOf(this.currentProject.id),
          1
        );
        await this.userService.setUser(user, false);

        if (user.email === this.currentUser.email) {
          this.router.navigate(['/']);
        }
      }
    });
  }

  validateEmail(email: string) {
    let emailValidator =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.match(emailValidator)) {
      return false;
    } else {
      return true;
    }
  }

  editMailText() {
    const dialogRef = this.dialog.open(EmailTextDialogComponent, {
      width: '500px',
      height: '500px',
      data: { name: this.currentProject.notificationText },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined') {
        if (!this.currentProject) return;
        this.currentProject.notificationText = result;
        this.projectService.currentProjectSubject.next(this.currentProject);

        this.saveProject();
      }
    });
  }

  openAddDomainModal() {
    const dialogRef = this.dialog.open(AddDomainModalComponent, {
      width: '550px',
      height: '450px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.loadDomains();
    });
  }

  async addUserEmail() {
    if (!this.validateEmail(this.inviteUserMail)) {
      this.wrongInviteEmail = true;
    } else {
      this.wrongInviteEmail = false;

      const user: User = await this.userService.getUserByEmail(
        this.inviteUserMail
      );

      const mail = {
        email: this.inviteUserMail,
        subject: 'Du wurdest zu einem Funnel eingeladen',
        text: `Hallo, <br> du wurdest zum Funnel "${this.currentProject.name}" eingeladen. 
        <br><br> 
        Klicke auf den Link um am Funnel mitzuarbeiten <a href="${environment.frontendUrl}/funnel-this.editor/${this.currentProject.id}">${environment.frontendUrl}/funnel-this.editor/${this.currentProject.id}</a> 
        <br><br> 
        Falls du noch nicht registriert bist, kannst du dich hier <a href="${environment.frontendUrl}/register?email=${this.inviteUserMail}">registrieren</a> bzw. hier <a href="${environment.frontendUrl}/login">einloggen</a>. <br>`,
      };

      if (user) {
        //user  registered
        if (!user.funnelIds.includes(this.currentProject.id)) {
          user.funnelIds.push(this.currentProject.id);
          this.usersOfFunnel.push(user);
          await this.userService.setUser(user, false);

          //send notification mail

          this.userService.sendInviteMail(mail);
        }
      } else {
        //user not registered
        const notRegisteredUser: User = {
          displayName: '',
          email: this.inviteUserMail,
          id: 'INVITED' + uuidv4(),
          funnelIds: [this.currentProject.id],
          language: 'ger',
          registerDate: new Date(),
          showOnboarding: true,
          receiptEmail: '',
        };

        await this.userService.setUser(notRegisteredUser, false);
        this.usersOfFunnel.push(notRegisteredUser);
        //send notification mail

        this.userService.sendInviteMail(mail);
      }

      this.inviteUserMail = '';
    }
  }

  isDisabled(feature: any) {
    /*  const isEnabled = this.tierService.getRights(
      feature,
      this.userService.userData.tier
    ); */

    return true;
  }

  setIFrameCode() {
    let widgetUrl: string =
      'https://firebasestorage.googleapis.com/v0/b/funnelbuilder-zw.appspot.com/o/widget%2Ffunneltool-widget.js?alt=media&token=0a83faa6-ea76-4d39-803b-90a367783329';

    this.iframeCode =
      `<funneltool-widget id="` +
      this.currentProject.id +
      `"></funneltool-widget>
       <script type="text/javascript" src="` +
      widgetUrl +
      `"></script>
     `;
  }

  setUnit(size: string, unit: string) {
    switch (size) {
      case 'width':
        this.widthUnit = unit;
        break;
      case 'heigth':
        this.heightUnit = unit;
        break;
      default:
        break;
    }
    this.setIFrameCode();
  }

  copyIFrameCode() {
    /* if (!this.isDisabled('embed')) {
      this.alertService.showToaster(
        'In Ihrem Plan (' +
          this.userService.userData.tier.tier.toUpperCase() +
          ') ist die Funktion nicht verfügbar'
      );

      return;
    } */

    var dummy = document.createElement('textarea');
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = this.iframeCode;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    /*  this.alertService.showToaster(
      'IFrame Code wurde in die Zwischenablage kopiert'
    ); */
  }
}
