import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateCategory } from 'src/app/models/funnel-template.model';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-text-modal',
  templateUrl: './text-modal.component.html',
  styleUrls: ['./text-modal.component.scss'],
})
export class TextModalComponent implements OnInit {
  categories: TemplateCategory[] = [];
  selectedCategory: TemplateCategory;
  constructor(
    public dialogRef: MatDialogRef<TextModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private templateService: TemplateService
  ) {}

  async ngOnInit() {
    if (this.data.showCategories) {
      this.categories = await this.templateService.getTemplateCategories();
    }
  }

  toggleDropdown() {
    const element = document.getElementById('dropdown');
    if (!element) return;
    element.classList.toggle('hidden');
  }

  selectCategory(category: TemplateCategory) {
    this.selectedCategory = category;
    this.toggleDropdown();
  }

  save() {
    this.dialogRef.close({
      category: this.selectedCategory.id,
      name: this.data.name,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
