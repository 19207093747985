<div
  class="flex flex-col flex-wrap items-center justify-center w-full px-4"
  [ngStyle]="style"
>
  <div [ngClass]="{ 'w-full': mobileView, 'w-1/3': !mobileView }">
    <div
      class="flex flex-col mt-2"
      *ngFor="let input of block.content.inputs; let inputIndex = index"
      (click)="selectFormElement(input, inputIndex, 'input')"
    >
      <div class="relative">
        <div
          *ngIf="input.isIcon"
          class="absolute flex items-center h-full pl-4 text-gray-600 cursor-pointer dark:text-gray-400"
        >
          <i [ngClass]="input.icon"></i>
        </div>
        <input
          [id]="'inputtext' + inputIndex"
          class="flex items-center w-full h-10 text-sm font-normal bg-white border border-gray-300 rounded shadow focus:outline-none focus:border focus:border-gray-500 focus:ring-0"
          [ngClass]="{ 'pl-12': input.isIcon, 'pl-4': !input.isIcon }"
          [name]="input.text"
          ngDefaultControl
          [placeholder]="
            input.required ? input.text + '*' : input.text + ' (optional)'
          "
          [type]="input.type"
          [required]="input.required ? 'required' : null"
          (focus)="focusFormInputIn(inputIndex)"
          (focusout)="focusFormInputOut()"
          [ngStyle]="getFormInputStyle(blockIndex, inputIndex)"
          [style.color]="'blue'"
        />
      </div>
    </div>

    <p
      class="flex justify-end my-2 text-xs text-gray-600"
      *ngIf="!checkIfRequiredFieldsPresent()"
    >
      * Pflichtfelder
    </p>

    <div
      *ngIf="block.content.privacyCheck.required"
      (click)="
        selectFormElement(null, block.content.inputs.length, 'privacyCheck')
      "
      class="flex-row w-full break-words"
    >
      <div class="p-1 mt-2 card bordered">
        <div class="form-control">
          <label class="flex justify-start cursor-pointer label">
            <input
              type="checkbox"
              class="w-4 h-4 mr-2 border-gray-300 rounded focus:ring-transparent focus:outline-none"
            />

            <p
              class="break-words whitespace-pre-line"
              [ngStyle]="getFormPrivacyCheckStyle(blockIndex)"
            >
              {{ block.content.privacyCheck.text }}
            </p>
          </label>
        </div>
      </div>
    </div>
  </div>

  <button
    (click)="selectFormElement(null, block.content.inputs.length + 1, 'submit')"
    [ngStyle]="getFormSubmitButtonStyle(blockIndex)"
    class="flex items-center justify-center w-full px-6 py-2 my-2 text-center transition duration-150 ease-in-out border border-gray-300 rounded focus:outline-none"
    [ngClass]="{ 'w-full': mobileView, 'w-1/3': !mobileView }"
    (mouseover)="over()"
    (mouseout)="out()"
  >
    <p>{{ block.content.submitButton.text }}</p>
  </button>
</div>
