import {
  Component,
  ViewChild,
  NgZone,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import {
  CdkDragDrop,
  moveItemInArray,
  copyArrayItem,
} from '@angular/cdk/drag-drop';
import {
  Block,
  QuizBlock,
  Answer,
  InputField,
  SubmitButton,
  PrivacyCheck,
} from 'src/app/models/block.model';
import { Page, Project } from 'src/app/models/project.model';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ProjectService } from 'src/app/services/project.service';
import { LanguageService } from 'src/app/services/lang.service';
import { AppService } from 'src/app/services/app.service';
import { uuidv4 } from 'src/app/services/uuid';
import { starterBlock } from 'src/app/blocks/starterBlock';
import { State } from 'src/app/models/state.model';
import { Subscription } from 'rxjs';
import { blockList } from 'src/app/blocks/blockList';
import { BlockType } from 'src/app/enums/block-type';
import { BlockActionType } from 'src/app/enums/block-action-type';
import { TextfieldType } from 'src/app/enums/textfield-type';
import { BlockService } from 'src/app/services/block.service';
import { isUserInTrialAllowed } from 'src/app/trialCheck';

@Component({
  selector: 'app-funnel-editor',
  templateUrl: './funnel-editor.component.html',
  styleUrls: ['./funnel-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FunnelEditorComponent implements OnInit {
  state$: Subscription;

  //icons = MaterialIconsList;
  showBlocks: boolean = false;
  colorToggle: boolean = false;

  facebookEvents: any[] = [
    'AddPaymentInfo',
    'AddToCart',
    'AddToWishlist',
    'CompleteRegistration',
    'Contact',
    'CustomizeProduct',
    'Donate',
    'FindLocation',
    'InitiateCheckout',
    'Lead',
    'Purchase',
    'Schedule',
    'Search',
    'StartTrial',
    'SubmitApplication',
    'Subscribe',
    'ViewContent',
  ];

  isHoverIndex: number | null;

  starterHtml: Block[] = starterBlock;

  html: any[] = [];

  elements: Block[] = blockList;
  actionControl = new FormControl();

  selectedElement: Block | null | any = null;
  currentElementIndex: number | null;
  currentPageIndex: number = 0;
  leftTabIndex = 0;
  rightTabIndex = 0;
  currentPanelIndex: number = -1;
  currentFormPanelIndex: number = -1;

  downloadJsonHref: any;
  projectId: any;
  currentProject: Project;

  baseUrl: string;
  constructor(
    private userService: UserService,
    public projectService: ProjectService,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private appService: AppService,
    private router: Router
  ) {
    this.projectId = this.route.snapshot.paramMap.get('id');

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
       /*  if (!user.funnelIds.includes(this.projectId)) {
          this.router.navigate(['/funnels']);
        } */

        if (!isUserInTrialAllowed(user)) {
          this.router.navigateByUrl('/user-settings?payment=true');
        }
      }
    });

    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        /*  this.selectedElement = state.editor.selectedElement;
        this.showBlocks = state.editor.showBlocks; */
      }
    });

    /*  this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
      }
    }); */
  }

  async ngOnInit() {
    this.projectService.currentProject =
      await this.projectService.getProjectById(this.projectId);

    if (!this.projectService.currentProject) return;

    const initialProject = JSON.parse(
      JSON.stringify(this.projectService.currentProject)
    );

    if (this.appService.state.editor.history.projectUndoArray.length == 0) {
      this.appService.state.editor.history.projectUndoArray.push(
        initialProject
      );
    }

    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          pageBar: {
            ...this.appService.state.editor.layout.pageBar,
            currentPageIndex: 0,
          },
        },
        history: {
          ...this.appService.state.editor.history,
          initialProject: this.projectService.currentProject,
        },
        currentProject: this.projectService.currentProject,
      },
    });
  }
}
