<section aria-labelledby="payment-details-heading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div
      class="bg-white py-6 px-4 sm:p-6"
      *ngIf="currentProject.wording || createWording()"
    >
      <div>
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
          i18n="@@funnelSettingsWording_headline_label"
        >
          Übersetzungen
        </h2>
      </div>

      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-12 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsWording_uploadBlockInstruction_label"
            >Upload-Block: "Datei hochladen"</label
          >
          <input
            type="text"
            [(ngModel)]="currentProject.wording!.uploadBlockErrorMessage"
            placeholder="Bitte laden Sie die Dateien hoch"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
      </div>

      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-12 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsWording_uploadBlockTooBig_label"
            >Upload-Block: "Die Datei ist zu groß!"</label
          >
          <input
            type="text"
            [(ngModel)]="currentProject.wording!.uploadBlockErrorTooBig"
            placeholder="Die Datei ist zu groß!"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
      </div>

      <div class="mt-6 grid grid-cols-4 gap-6">
        <div class="col-span-12 sm:col-span-2">
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700"
            i18n="@@funnelSettingsWording_FormRequiredFields_label"
            >Formular-Block: "Pflichtfelder"</label
          >
          <input
            type="text"
            [(ngModel)]="currentProject.wording!.formBlockRequired"
            placeholder="Pflichtfelder"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
        </div>
      </div>

      <div class="px-4 py-3 mt-6 text-right sm:px-6">
        <button
          (click)="saveProject()"
          class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900"
          i18n="@@funnelSettingsWording_save_button"
        >
          Speichern
        </button>
      </div>
    </div>
  </div>
</section>
