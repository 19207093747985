import firebase from 'firebase/app';
import { Injectable } from '@angular/core';

import {
  Project,
  Page,
  Progressbar,
  AnswerSubmission,
} from 'src/app/models/project.model';

import { UserService } from './user.service';
import { LanguageService } from './lang.service';
import { Block, BlockStyle } from '../models/block.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { uuidv4 } from './uuid';
import { starterBlock } from '../blocks/starterBlock';
import { User } from '../models/user.model';
import { Router } from '@angular/router';

@Injectable()
export class MonthlyImpressionsService {
  db: any;

  constructor(
    private userService: UserService,
    private langService: LanguageService,
    private router: Router
  ) {
    this.db = firebase.firestore();
  }

  increaseImpressionsPerMonth(userId: string) {
    var month = new Date().getUTCMonth() + 1;
    var year = new Date().getUTCFullYear();
    const combinedMonthYear = month + '-' + year;

    return this.db
      .collection('monthlyImpressions')
      .doc(userId)
      .set(
        {
          [combinedMonthYear]: firebase.firestore.FieldValue.increment(1),
        },
        { merge: true }
      );
  }

  async getMonthlyImpressionsByUserId(userId: string) {
    var month = new Date().getUTCMonth() + 1;
    var year = new Date().getUTCFullYear();
    const combinedMonthYear: any = month + '-' + year;
    return await this.db
      .collection('monthlyImpressions')
      .doc(userId)
      .get()
      .then((monthlyImpressions: any) => {
        const monthlyImpressionsArray: any[] = monthlyImpressions.data();
        console.log(monthlyImpressionsArray);
        if (monthlyImpressionsArray) {
          return monthlyImpressionsArray[combinedMonthYear]
            ? monthlyImpressionsArray[combinedMonthYear]
            : 0;
        } else {
          return 0;
        }
      });
  }

  async getMonthlyImpressionsByMonthYear(userId: string, monthYear: any) {
    return await this.db
      .collection('monthlyImpressions')
      .doc(userId)
      .get()
      .then((monthlyImpressions: any) => {
        const monthlyImpressionsArray: any[] = monthlyImpressions.data();
        console.log(monthlyImpressionsArray);
        return monthlyImpressionsArray[monthYear];
      });
  }
}
