import { Component, Input, OnInit } from '@angular/core';
import { Block, BlockStyle } from 'src/app/models/block.model';
import { AppService } from 'src/app/services/app.service';
import { BlockService } from 'src/app/services/block.service';

@Component({
  selector: 'app-selectionquiz',
  templateUrl: './selectionQuiz.component.html',
  styleUrls: ['./selectionQuiz.component.scss'],
})
export class SelectionQuizComponent implements OnInit {
  @Input() block: Block;
  @Input() style: BlockStyle;
  @Input() buttonStyle: BlockStyle;
  @Input() blocks: Block[];
  @Input() blockIndex: number;
  @Input() mobileView: boolean;

  ngOnInit(): void {
    console.log('showQuiz', this.block.showQuiz);
  }

  toggleView() {
    console.log('toggleView', this.block.showQuiz);
    this.block.showQuiz = !this.block.showQuiz;
  }
}
