<!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="!selectedQuizItem">
  <div class="flow-root my-6">
    <ul role="list" class="mb-1 -my-5 divide-y divide-gray-200">
      <li
        class="py-4 cursor-pointer group"
        *ngFor="let item of items; let i = index"
      >
        <div class="flex flex-col">
          <div class="flex-1 min-w-0 mb-2 cursor-pointer">
            <div class="flex items-center justify-between">
              <div class="flex space-x-8">
                <p
                  (click)="openItem(item, i)"
                  class="text-sm font-medium text-gray-900 truncate"
                  [innerHTML]="item.answerText"
                ></p>
              </div>

              <div class="flex space-x-2">
                <div
                  class="flex justify-between space-x-2"
                  (click)="openItem(item, i)"
                >
                  <i class="mr-2 cursor-pointer bi bi-chevron-right"></i>
                </div>
                <div class="flex space-x-2">
                  <i
                    (click)="moveUp(i)"
                    class="opacity-0 cursor-pointer bi bi-chevron-up"
                    [ngClass]="{ 'group-hover:opacity-100': i > 0 }"
                  ></i>
                  <i
                    (click)="moveDown(i)"
                    class="opacity-0 cursor-pointer bi bi-chevron-down"
                    [ngClass]="{
                      ' group-hover:opacity-100 ': i < items.length - 1
                    }"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div
      class="py-4 mt-1 border-t cursor-pointer group"
      *ngIf="quizBlock.content.isMultipleChoice"
    >
      <div class="flex flex-col">
        <div class="flex-1 min-w-0 mb-2 cursor-pointer">
          <div class="flex items-center justify-between">
            <div class="flex space-x-8">
              <p
                (click)="openButton()"
                class="text-sm font-medium text-gray-900 truncate"
                [innerHTML]="'Button'"
              ></p>
            </div>
            <div class="flex space-x-2">
              <div
                class="flex justify-between space-x-2"
                (click)="openButton()"
              >
                <i class="mr-2 cursor-pointer bi bi-chevron-right"></i>
              </div>
              <div class="flex space-x-2">
                <i class="opacity-0 bi bi-chevron-up"></i>
                <i class="opacity-0 bi bi-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-6">
    <a
      (click)="addItem()"
      class="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50"
      i18n="@@funnelSidebarDetailQuizItems_add_button"
    >
      <i class="mr-1 bi bi-plus-circle"></i> hinzufügen
    </a>
  </div>

  <div class="my-6">
    <app-toggle
      [title]="'Multiple-Choice'"
      [isEnabled]="quizBlock.content.isMultipleChoice ? true : false"
      (onToggle)="setIsMultipleChoice($event)"
    ></app-toggle>
  </div>
</div>
<app-details-quiz-item
  class="overflow-auto"
  *ngIf="selectedQuizItem"
  [answer]="selectedQuizItem"
  [answerIndex]="selectedQuizItemIndex"
  [answerType]="selectedQuizItemType"
  (onShowAnswers)="selectedQuizItem = null"
  (onRemoveItem)="removeItem(selectedQuizItemIndex)"
  (onSetStyleToAllAnswers)="setStyleToAllAnswers($event)"
></app-details-quiz-item>
