<div class="flex flex-col">
  <label for="comment" class="block text-sm font-medium text-gray-700">{{
    title
  }}</label>
  <div class="flex justify-between items-center gap-x-4 mt-1">
    <div
      class="w-9 h-8 border border-gray-300 cursor-pointer rounded-full"
      [ngStyle]="{ 'background-color': color }"
      (click)="openColorPicker()"
    ></div>
    <input
      class="-ml-2 opacity-20 w-0 h-0"
      type="color"
      [id]="id"
      name="favcolor"
      (blur)="setColor($event)"
    />
    <input
      type="text"
      [(ngModel)]="color"
      (input)="setColor($event)"
      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      placeholder="#ffffff"
    />
  </div>
</div>
