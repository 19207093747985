import {
  CdkDragDrop,
  copyArrayItem,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { blockList } from 'src/app/blocks/blockList';
import {
  Answer,
  Block,
  BulletPoint,
  InputField,
  SelectAnswer,
  SubmitButton,
} from 'src/app/models/block.model';
import { Page, Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';
import { LanguageService } from 'src/app/services/lang.service';
import { ProjectService } from 'src/app/services/project.service';
import { uuidv4 } from 'src/app/services/uuid';
import { icons } from 'src/app/icons/icons';
import { BlockType } from 'src/app/enums/block-type';
import { TextfieldType } from 'src/app/enums/textfield-type';
import { BlockActionType } from 'src/app/enums/block-action-type';
import { DomSanitizer } from '@angular/platform-browser';
import { fonts } from 'src/app/fonts/fonts';
import { MediaLibraryModalComponent } from '../modals/media-library-modal/media-library-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BlockService } from 'src/app/services/block.service';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  take,
} from 'rxjs/operators';
import { SocialMediaType } from 'src/app/enums/social-media';
import { IconLibraryComponent } from '../modals/icon-library/icon-library.component';

@Component({
  selector: 'app-details-sidebar',
  templateUrl: './details-sidebar.component.html',
  styleUrls: ['./details-sidebar.component.scss'],
})
export class DetailsSidebarComponent implements OnInit {
  state$: Subscription;
  html: any;
  selectedElement: Block | null | any = null;
  rightTabIndex: number = 0;
  showBlocks: boolean = true;
  currentElementIndex: number | null;
  currentPageIndex: number = 0;
  currentFormPanelIndex: number = 0;
  currentSelectionIndex: number = 0;
  readonly elements: Block[] = blockList;
  icons = icons;
  currentIcons = icons;
  currentPanelIndex: number = 0;
  htmlList: any;
  fonts: string[] = fonts;
  filteredFonts: string[] = fonts;

  currentProject: Project;

  showUndo: boolean = false;
  showRedo: boolean = false;

  projectRedoArray: Project[] = [];
  projectUndoArray: Project[] = [];
  projectCurrentArray: Project[] = [];
  initialUndo: boolean = true;
  initialProject: Project;

  initialSet: boolean = false;

  htmlChanged: Subject<any> = new Subject<any>();
  isSaved: boolean = true;

  socialMedias: string[] = Object.values(SocialMediaType);
  constructor(
    private appService: AppService,
    public langService: LanguageService,
    public projectService: ProjectService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private blockService: BlockService
  ) {
    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
        this.initCurrentProject();
      }
    });

    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        /*  this.currentPageIndex = state.editor.currentPageIndex;
        this.selectedElement = state.editor.selectedElement;
        this.html = state.editor.html;
        this.showBlocks = state.editor.showBlocks;
        this.currentElementIndex = state.editor.currentElementIndex;
        this.rightTabIndex = state.editor.rightTabIndex; */
        this.currentPanelIndex = state.editor.currentPanelIndex;
        this.currentFormPanelIndex = state.editor.currentFormPanelIndex;
        this.currentSelectionIndex = state.editor.currentSelectionIndex;
        /* this.projectRedoArray = state.history.projectRedoArray;
        this.projectUndoArray = state.history.projectUndoArray;
        this.projectCurrentArray = state.history.projectCurrentArray;
        this.showUndo = state.history.showUndo;
        this.showRedo = state.history.showRedo;
        this.initialUndo = state.history.initialUndo;
        this.isSaved = state.history.isSaved; */
      }
    });

    this.htmlChanged
      .pipe(
        debounceTime(500),
        switchMap(async (test) => {
          this.isSaved = true;
          console.log(test);
        })
      )
      .subscribe((html) => {
        this.isSaved = true;
        this.setHistory();
      });
  }

  openIconLibrary(type: string, index: number) {
    const dialogRef = this.dialog.open(IconLibraryComponent, {
      width: '90%',
      height: '90%',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (typeof result !== 'undefined') {
        switch (type) {
          case 'form':
            this.selectedElement.content.inputs[index].icon = result;
            break;
          case 'bulletpoints':
            this.selectedElement.content.bulletpoints[index].icon = result;
            break;

          default:
            this.selectedElement.content.answers[index].answerIcon = result;
            break;
        }

        this.changed('setIcon');
      }
    });
  }

  changed(event: any) {
    this.isSaved = false;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          rightTabIndex: this.rightTabIndex,
        },

        /*    history: {
          ...this.appService.state.history,
          isSaved: this.isSaved,
        }, */
      },
      false
    );
    this.htmlChanged.next(event);
  }
  ngOnInit(): void {}

  initCurrentProject() {
    if (!this.initialSet) {
      this.initialProject = JSON.parse(JSON.stringify(this.currentProject));
      if (!this.initialProject) return;
      if (this.projectUndoArray.length == 0) {
        this.projectUndoArray.push(this.initialProject);
      }
      this.initialSet = true;
    }
  }

  setHistory() {
    this.projectRedoArray = [];
    this.showRedo = true;

    if (this.projectUndoArray.length == 0) {
      this.projectUndoArray.push(this.initialProject);
    }

    if (this.projectCurrentArray.length !== 0) {
      const cur_pro_pop = JSON.parse(
        JSON.stringify(this.projectCurrentArray.pop())
      );
      if (!cur_pro_pop) return;
      this.projectUndoArray.push(cur_pro_pop);
    } else {
      const cur_pro_pop = JSON.parse(JSON.stringify(this.currentProject));
      if (!cur_pro_pop) return;

      this.projectUndoArray.push(cur_pro_pop);
    }

    const cur_pro = JSON.parse(JSON.stringify(this.currentProject));
    let test = JSON.parse(JSON.stringify(cur_pro));
    this.projectCurrentArray.push(test);
    if (this.projectUndoArray.length == 0) {
      this.showUndo = false;
    } else {
      this.showUndo = true;
    }
    // }

    this.initialUndo = true;

    if (this.currentElementIndex === null) return;
    this.html[this.currentElementIndex] = this.selectedElement;
    this.projectCurrentArray[0].pages[this.currentPageIndex].blocks = this.html;
    this.projectService.currentProjectSubject.next(this.projectCurrentArray[0]);
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          html: this.html,

          rightTabIndex: this.rightTabIndex,
        },
        /*  history: {
          ...this.appService.state.history,
          projectRedoArray: this.projectRedoArray,
          projectUndoArray: this.projectUndoArray,
          projectCurrentArray: this.projectCurrentArray,
          showUndo: this.showUndo,
          showRedo: this.showRedo,
          initialUndo: this.initialUndo,
          isSaved: this.isSaved,
        }, */
      },
      true
    );
  }

  dropForm(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  dropQuiz(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  drop(event: CdkDragDrop<any[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.selectedElement = this.html[this.html.length - 1];
      this.rightTabIndex = 0;
    }
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  addElement(element: Block) {
    if (element.type === BlockType.FORM) {
      let formUser: boolean = false;
      if (!this.projectService.currentProject) return;

      this.projectService.currentProject.pages.forEach((p: Page) => {
        p.blocks.forEach((b: Block) => {
          if (b.type === BlockType.FORM) {
            formUser = true;
          }
        });
      });
      if (formUser) {
        //this.alertService.showToaster('Es kann nur ein Formular im Funnel benutzt werden');

        return;
      }
    }

    let newBlock: Block = JSON.parse(JSON.stringify(element));
    newBlock.id = uuidv4();
    this.html.push(newBlock);
    this.showBlocks = false;
    this.selectedElement = newBlock;

    this.rightTabIndex = 0;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          rightTabIndex: this.rightTabIndex,

          showBlocks: false,
        },
      },
      true
    );
    this.changed('addElement');
  }

  toggleFontsDropdown() {
    const element = document.getElementById('dropdownInformation');
    if (!element) return;
    element.classList.toggle('hidden');
  }

  toggleSocialMediaDropdown() {
    const element = document.getElementById('dropdownSocialMedia');
    if (!element) return;
    element.classList.toggle('hidden');
  }

  onKey(value: any) {
    this.currentIcons = this.search(value.target.value);
  }

  onKeyFonts(value: any) {
    this.filteredFonts = this.fonts;

    this.filteredFonts = this.filteredFonts.filter((i) =>
      i.toLowerCase().includes(value.target.value.toLowerCase())
    );
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.icons.filter((option) => option.toLowerCase().includes(filter));
  }

  selectFont(font: string) {
    this.selectedElement.style.fontFamily = font;
    this.toggleFontsDropdown();
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('selectFont');
  }

  selectSocialMedia(type: string) {
    this.selectedElement.content.type = type;
    this.toggleSocialMediaDropdown();
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          rightTabIndex: this.rightTabIndex,
        },
      },
      false
    );
    this.changed('selectSocialMedia');
  }

  getFontCSS(font: string) {
    font = font.replace(/ /g, '+');

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://fonts.googleapis.com/css2?family=' + font
    );
  }

  getFont(font: string) {
    return 'font-family:' + font + ' !important';
  }

  getBlockName(type: string) {
    return this.blockService.getBlockName(type);
  }

  getBlockIcon(icon: string) {
    return this.blockService.getBlockIcon(icon);
  }

  isVisible(element: string) {
    if (
      typeof this.selectedElement === 'undefined' ||
      this.selectedElement === null
    ) {
      return false;
    }
    switch (element) {
      case 'fonts': {
        const allowedBlocks = [
          'header',
          'button',
          'textfield',
          'selection',
          'quiz',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'text': {
        const allowedBlocks = ['header', 'button'];
        return allowedBlocks.includes(this.selectedElement.type);
      }

      case 'upload': {
        const allowedBlocks = ['upload'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'textfield': {
        const allowedBlocks = ['textfield'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'socialmedia': {
        const allowedBlocks = ['socialmedia'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'video': {
        const allowedBlocks = ['video'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'selection': {
        const allowedBlocks = ['selection'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'fakeloader': {
        const allowedBlocks = ['fakeloader'];
        return allowedBlocks.includes(this.selectedElement.type);
      }

      case 'slider': {
        const allowedBlocks = ['slider'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'bulletpoints': {
        const allowedBlocks = ['bulletpoints'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'alignment': {
        const allowedBlocks = [
          'header',
          'button',
          'picture',
          'textfield',
          'fakeloader',
          'video',
          'socialmedia',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'textDecoration': {
        const allowedBlocks = [
          'header',
          'button',
          'textfield',
          'selection',
          'bulletpoints',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'width': {
        const allowedBlocks = [
          'header',
          'button',
          'picture',
          'form',
          'textfield',
          'fakeloader',
          'socialmedia',
          'slider',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'fontColor': {
        const allowedBlocks = [
          'header',
          'button',
          'form',
          'textfield',
          'selection',
          'slider',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'backgroundColor': {
        const allowedBlocks = [
          'header',
          'quiz',
          'picture',
          'button',
          'form',
          'textfield',
          'selection',
          'video',
          'socialmedia',
          'slider',
          'bulletpoints',
          'upload',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'marginTop': {
        const allowedBlocks = [
          'header',
          'quiz',
          'picture',
          'button',
          'form',
          'textfield',
          'fakeloader',
          'selection',
          'video',
          'socialmedia',
          'slider',
          'bulletpoints',
          'upload',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'marginBottom': {
        const allowedBlocks = [
          'header',
          'quiz',
          'picture',
          'button',
          'form',
          'textfield',
          'selection',
          'fakeloader',
          'video',
          'socialmedia',
          'slider',
          'bulletpoints',
          'upload',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'marginLeft': {
        const allowedBlocks = [
          'header',
          'quiz',
          'picture',
          'button',
          'form',
          'textfield',
          'selection',
          'fakeloader',
          'video',
          'socialmedia',
          'slider',
          'bulletpoints',
          'upload',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'marginRight': {
        const allowedBlocks = [
          'header',
          'quiz',
          'picture',
          'button',
          'form',
          'textfield',
          'selection',
          'fakeloader',
          'video',
          'socialmedia',
          'slider',
          'bulletpoints',
          'upload',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
    }
    return false;
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  setTextDecorationFormButton(button: SubmitButton, event: any) {
    if (!button.style) return;
    button.style.fontWeight = '';
    button.style.fontStyle = '';
    button.style.textDecoration = '';

    event.forEach((element: any) => {
      if (!button.style) return;
      switch (element) {
        case 'bold':
          button.style.fontWeight = 'bold';
          break;
        case 'italic':
          button.style.fontStyle = 'italic';
          break;
        case 'underline':
          button.style.textDecoration = 'underline';
          break;
      }
    });
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  selectFormElement(input: any, inputIndex: number, type: any) {
    this.currentFormPanelIndex = inputIndex;
  }

  removeInputField(index: number) {
    this.selectedElement.content['inputs'].splice(index, 1);
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('removeInputField');
  }

  addFormInput() {
    let formElememt: InputField = {
      type: TextfieldType.TEXT,
      text: 'Text',
      isIcon: true,
      icon: 'content_copy',
      required: true,
      value: null,
    };

    this.selectedElement.content['inputs'].push(formElememt);
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('addFormInput');
  }

  removeAnswer(index: number) {
    this.selectedElement.content['answers'].splice(index, 1);
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('removeAnswer');
  }

  removeBulletPoint(index: number) {
    this.selectedElement.content['bulletpoints'].splice(index, 1);
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('removeBulletpoint');
  }

  deleteColor(colorAttribute: string) {
    if (!this.projectService.currentProject) return;
    // this.selectedElement = item;
    this.selectedElement.style[colorAttribute] = '';

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('deleteColor');
  }

  setPictureOrIcon(type: any, index: number) {
    this.selectedElement.content['answers'][index].type = type;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('setPictureOrIcon');
  }

  onAnswerSelectFile(event: any, index: number) {
    // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      var reader: FileReader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      let i = index;
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.selectedElement.content['answers'][i].answerPicture =
          reader.result;
        this.appService.setState(
          {
            ...this.appService.state,
            editor: {
              ...this.appService.state.editor,
              selectedElement: this.selectedElement,

              rightTabIndex: this.rightTabIndex,
            },
          },
          true
        );
        this.changed(event);
      };
    }
  }

  openPictureInputById(id: any) {
    const element = document.getElementById(id);
    if (!element) return;
    element.click();
  }

  setStyleToAllAnswers(answer: Answer) {
    this.selectedElement.content['answers'].forEach((ans: Answer) => {
      ans.style = JSON.parse(JSON.stringify(answer.style));
    });
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('setStyleToAllAnswers');
  }

  addAnswer() {
    switch (this.selectedElement.type) {
      case 'quiz': {
        let answerObj: Answer[] = this.selectedElement.content['answers'];

        let answer: Answer = {
          answerText: this.langService.getWording(
            'funnelEditor',
            'tabRightQuizAnswer'
          ),
          type: 'picture',
          answerPicture: 'https://source.unsplash.com/random/300x200',
          action: {
            type: BlockActionType.CONNECTEDPAGE,
          },
          style: {
            fontColor: '#ffffff',
            backgroundColor: '#4797ff',
            fontSize: 19,
          },
        };

        answerObj.push(answer);
        break;
      }
      case 'selection': {
        let answers: SelectAnswer[] = this.selectedElement.content['answers'];

        let answer: SelectAnswer = {
          answerText: 'Antwort',
          checked: false,
        };

        answers.push(answer);
        break;
      }

      case 'bulletpoints': {
        let bulletpoints: BulletPoint[] =
          this.selectedElement.content['bulletpoints'];

        let bulletPoint: BulletPoint = {
          text: 'Neuer Punkt',
          icon: 'bi bi-star-fill',
        };

        bulletpoints.push(bulletPoint);
        break;
      }

      default:
        break;
    }

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('addAnswer');
  }

  onPictureSelectFile(event: any) {
    // called each time file input changes

    if (event.target.files && event.target.files[0]) {
      var reader: FileReader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        if (!reader.result) return;
        this.selectedElement.content = reader.result.toString();
        this.saveProject();
      };
    }
  }

  openPictureInput() {
    const element = document.getElementById('fileInput');
    if (!element) return;
    element.click();
  }

  setButtonColor(color: any) {
    this.selectedElement.style.backgroundColor = color.target.value;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('setButtonColor');
  }

  setColor(event: any) {
    if (!this.selectedElement?.style) return;

    this.selectedElement.style.fontColor = event.target.value;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      false
    );
    this.changed(event);
  }

  setBulletpointColor(event: any) {
    if (!this.selectedElement?.style) return;

    this.selectedElement.content.textStyle.color = event.target.value;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      false
    );
    this.changed(event);
  }

  setBulletpointIconColor(event: any) {
    if (!this.selectedElement?.style) return;

    this.selectedElement.content.iconStyle.color = event.target.value;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      false
    );
    this.changed(event);
  }

  setBorderColor(event: any) {
    if (!this.selectedElement?.style) return;
    this.selectedElement.style.borderColor = event.target.value;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  setQuizFontColor(answerIndex: number, event: any) {
    this.selectedElement.content['answers'][answerIndex].style.fontColor =
      event.target.value;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  setQuizBackgroundColor(answerIndex: number, event: any) {
    this.selectedElement.content['answers'][answerIndex].style.backgroundColor =
      event.target.value;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  deleteQuizBackgroundColor(answerIndex: number) {
    this.selectedElement.content['answers'][answerIndex].style.backgroundColor =
      '';
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('deleteQuizBackgroundColor');
  }

  setQuizFontSize(answerIndex: number, event: any) {
    this.selectedElement.content['answers'][answerIndex].style.fontSize =
      event.value;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      false
    );
    this.changed('setQuizFontSize');
  }

  setQuizIconSize(answerIndex: number, event: any) {
    this.selectedElement.content['answers'][answerIndex].style.iconSize =
      event.value;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('setQuizIconSize');
  }

  deleteQuizFontColor(answerIndex: number) {
    this.selectedElement.content['answers'][answerIndex].style.fontColor = '';
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('deleteQuizFontColor');
  }

  setPrivacyButtonFontSize(event: any) {
    this.selectedElement.content.privacyCheck.style.fontSize = event.value;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  setSliderSteps(event: any) {
    this.selectedElement.content.steps = event.value;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  setMaxUploads(event: any) {
    this.selectedElement.content.maxUploads = event.value;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  deletePrivacyColor() {
    if (!this.selectedElement?.content) return;
    this.selectedElement.content.privacyCheck.style.fontColor = '';

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('deletePrivacyColor');
  }

  deleteSubmitButtonColor(colorAttribute: string) {
    if (!this.selectedElement?.content) return;
    this.selectedElement.content.submitButton.style[colorAttribute] = '';

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed('deleteSubmitButtonColor');
  }

  setPrivacyColor(event: any) {
    if (!this.selectedElement?.style) return;
    this.selectedElement.content.privacyCheck.style.fontColor =
      event.target.value;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  setContainerBackgroundColor(event: any) {
    if (!this.selectedElement?.style) return;
    this.selectedElement.style.containerBackgroundColor = event.target.value;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  setBackgroundColor(event: any) {
    if (!this.selectedElement?.style) return;
    this.selectedElement.content.submitButton.style.backgroundColor =
      event.target.value;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  setHoverBackgroundColor(event: any) {
    if (!this.selectedElement?.style) return;
    this.selectedElement.content.submitButton.style.hoverColor =
      event.target.value;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  setFontColor(event: any) {
    if (!this.selectedElement?.style) return;
    this.selectedElement.content.submitButton.style.fontColor =
      event.target.value;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  alignElement(alignment: any) {
    if (!this.selectedElement?.style) return;
    switch (alignment) {
      case 'left-item':
        this.selectedElement.style.alignment = 'start';
        break;
      case 'center-item':
        this.selectedElement.style.alignment = 'center';

        break;
      case 'right-item':
        this.selectedElement.style.alignment = 'end';

        break;
    }

    /*   if (!this.currentElementIndex) return;
    this.html[this.currentElementIndex] = this.selectedElement;
    this.projectCurrentArray[0].pages[this.currentPageIndex].blocks = this.html; */
    /*     this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,
          html: this.projectService.currentProjectSubject.getValue().pages[
            this.currentPageIndex
          ].blocks,
          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    ); */
    this.changed('alignElement');
  }

  setTextDecoration(event: any) {
    if (!this.selectedElement?.style) return;
    this.selectedElement.style.fontWeight = '';
    this.selectedElement.style.fontStyle = '';
    this.selectedElement.style.textDecoration = '';

    event.forEach((element: any) => {
      if (!this.selectedElement?.style) return;
      switch (element) {
        case 'bold':
          this.selectedElement.style.fontWeight = 'bold';
          break;
        case 'italic':
          this.selectedElement.style.fontStyle = 'italic';
          break;
        case 'underline':
          this.selectedElement.style.textDecoration = 'underline';
          break;
      }
    });
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  changeFontSize(
    event: any,
    blockType: BlockType,
    ele: Block,
    bulletpointType?: string
  ) {
    if (!this.selectedElement?.style) return;
    switch (blockType) {
      case 'button':
        this.selectedElement.style.width = event.value;
        break;
      case 'header':
        this.selectedElement.style.fontSize = event.value;
        break;
      case 'selection':
        this.selectedElement.style.fontSize = event.value;
        break;
      case 'picture':
        this.selectedElement.style.width = event.value;
        break;
      case 'textfield':
        this.selectedElement.style.width = event.value;
        break;
      case 'fakeloader':
        this.selectedElement.style.width = event.value;
        this.selectedElement.style.height = event.value;
        break;
      case 'form':
        this.selectedElement.style.fontSize = event.value;
        break;
      case 'socialmedia':
        this.selectedElement.style.fontSize = event.value;
        break;
      /*  case 'slider':
        this.selectedElement.style.width = event.value;
        break; */
      case 'bulletpoints':
        if (bulletpointType === 'text') {
          this.selectedElement.content.textStyle.fontSize = event.value;
        } else {
          this.selectedElement.content.iconStyle.fontSize = event.value;
        }
        break;
      
    }
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      true
    );
    this.changed(event);
  }

  setMargin(value: any, margin: any) {
    if (!this.selectedElement?.style) return;
    switch (margin) {
      case 'top':
        this.selectedElement.style.marginTop = value;
        break;
      case 'bottom':
        this.selectedElement.style.marginBottom = value;
        break;
      case 'left':
        this.selectedElement.style.marginLeft = value;
        break;
      case 'right':
        this.selectedElement.style.marginRight = value;
        break;
    }
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          selectedElement: this.selectedElement,

          rightTabIndex: this.rightTabIndex,
        },
      },
      false
    );
    this.changed('setMargin');
  }

  openMediaLibrary(type: string, answerIndex: number = -1) {
    const dialogRef = this.dialog.open(MediaLibraryModalComponent, {
      maxWidth: '80vw',
      maxHeight: '80vh',
      height: '80%',
      width: '80%',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (typeof result !== 'undefined') {
        switch (type) {
          case 'image':
            this.selectedElement.content = result;
            this.saveProject();
            break;
          case 'quiz':
            if (answerIndex === -1) return;
            this.selectedElement.content['answers'][answerIndex].answerPicture =
              result;
            this.saveProject();
            break;

          case 'upload':
            if (answerIndex === -1) return;
            this.selectedElement.content.picture = result;
            this.saveProject();
            break;

          default:
            break;
        }
      }
    });
  }

  setHeader() {
    if (this.currentElementIndex !== 0) return;
    const element: Block = this.selectedElement;
    if (!this.projectService.currentProject) return;
    this.projectService.currentProject.pages.forEach(
      (p: Page, index: number) => {
        if (index !== this.currentPageIndex) {
          if (!this.selectedElement.isHeader) {
            p.blocks = p.blocks.filter(function (item: Block) {
              return item.id !== element.id;
            });
          } else {
            if (p.blocks[0].isHeader) {
              p.blocks[0] = element;
            } else {
              p.blocks = [element, ...p.blocks];
            }
          }
        }
      }
    );
    this.projectService.currentProjectSubject.next(
      this.projectService.currentProject
    );
  }

  saveProject() {
    this.setHeader();
    /*  this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentElementIndex: this.currentElementIndex,
          selectedElement: this.selectedElement,
          html: this.html,
          rightTabIndex: this.rightTabIndex,
          currentPanelIndex: this.currentPanelIndex,
          currentFormPanelIndex: this.currentFormPanelIndex,
          currentSelectionIndex: this.currentSelectionIndex,
        },
      },
      true
    ); */
    this.changed('saveProject');
    this.projectService.saveCurrentProject();
  }
}
