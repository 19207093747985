import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Block } from 'src/app/models/block.model';
import { uuidv4 } from 'src/app/services/uuid';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  @Input() color: string | undefined | null;
  @Input() title: string = '';
  id: string = uuidv4();
  @Output() onColorChange: EventEmitter<any> = new EventEmitter<any>();
  showColor: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  setColor(event: any) {
    this.showColor = false;

    this.color = event.target.value;
    this.onColorChange.emit(this.color);
    /*   this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      true
    ); */
  }

  openColorPicker() {
    this.showColor = true;

    const colorPicker = document.getElementById(this.id);
    colorPicker?.focus();
    colorPicker?.click();
  }
}
