import { Injectable } from '@angular/core';
declare var fbq: Function;

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelEventTrackerService {
  constructor() {}

  public trackViewContent() {
    if (typeof fbq === 'undefined') {
      return;
    }
    fbq('track', 'ViewContent');
  }

  public trackAddToCart() {
    if (typeof fbq === 'undefined') {
      return;
    }
    fbq('track', 'AddToCart');
  }

  public trackEvent(event: string) {
    if (typeof fbq === 'undefined') {
      return;
    }

    if (event === 'CompleteRegistration') {
      fbq('track', 'CompleteRegistration', {
        value: 0,
        currency: 'EUR',
      });
    } else {
      fbq('track', event);
    }
  }

  load(id: any) {
    ((f: any, b, e, v, n?: any, t?: any, s?: any) => {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );
    (window as any).fbq.disablePushState = true; //not recommended, but can be done
    (window as any).fbq('init', id);
    (window as any).fbq('track', 'PageView');
  }
}
