import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-user-dashboard-navigation',
  templateUrl: './user-dashboard-navigation.component.html',
  styleUrls: ['./user-dashboard-navigation.component.scss'],
})
export class UserDashboardNavigationComponent implements OnInit {
  @Output() setTabIndex = new EventEmitter<any>();
  @Input() user: User;
  activeTabIndex: number = 0;
  constructor() {}

  ngOnInit(): void {}

  setActiveTab(index: number) {
    this.activeTabIndex = index;
    this.setTabIndex.emit(index);
  }
}
