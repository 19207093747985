<div class="h-full flex flex-col">
  <div class="flex h-full">
    <!-- Narrow sidebar -->

    <!-- Content area -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <!-- Main content -->
      <div class="flex-1 flex items-stretch overflow-hidden">
        <main class="flex-1 overflow-y-auto bg-gray-100 px-12 relative">
          <div class="flex justify-between items-center mb-8">
            <h2
              class="text-2xl font-bold leading-7 text-kl-grey sm:text-3xl sm:truncate"
              i18n="@@funnelLeadsTable_headline_label"
            >
              Leads
            </h2>
            <button
              (click)="exportToCsv()"
              class="bg-kl-green border border-transparent rounded-full shadow-sm flex justify-center items-center p-1 h-12 w-12 text-sm font-medium text-kl-grey hover:bg-gray-900 hover:text-white relative"
            >
              <i class="bi bi-cloud-download text-xl"></i>
            </button>
          </div>
          <div class="lg:grid lg:grid-cols-12">
            <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 h-full mb-10">
              <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="py-2 align-middle inline-blborder-b-2 border-kl-purpleock min-w-full sm:px-6 lg:px-8"
                  >
                    <!--  <div class="flex justify-end items-center mb-6">
                         <span
                        class="w-1/4 cursor-pointer inline-flex items-center px-4 rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
                      >
                        <input
                          type="text"
                          placeholder="Filter"
                          class="bg-transparent border-transparent focus:border-transparent focus:ring-0 border-none w-full"
                        />
                        <i class="ml-2 bi bi-search"></i>
                      </span>
                    
                    </div> -->
                    <div
                      class="shadow overflow border-b border-gray-300 sm:rounded-lg"
                    >
                      <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-kl-grey">
                          <tr>
                            <th
                              (click)="sortBy(column)"
                              *ngFor="
                                let column of displayedColumns;
                                let isLast = last;
                                let isFirst = first
                              "
                              scope="col"
                              class="py-3 text-left text-xs font-bold text-white uppercase w-auto cursor-pointer"
                              [ngClass]="{
                                'pl-4 ': isFirst
                              }"
                            >
                              {{ column }}
                              <i
                                *ngIf="
                                  sortDirections[column] === 'desc' &&
                                  column !== 'Nr.'
                                "
                                class="bi bi-arrow-down-short text-lg"
                              ></i>
                              <i
                                *ngIf="
                                  sortDirections[column] === 'asc' &&
                                  column !== 'Nr.'
                                "
                                class="bi bi-arrow-up-short text-lg"
                              ></i>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr *ngFor="let val of allLeads; let i = index">
                            <!--  <td class="w-10 pl-5 py-4">
                              <div class="flex items-center">
                                <input
                                  id="person-1"
                                  name="person-1"
                                  type="checkbox"
                                  class="h-6 w-6 text-kl-purple border-gray-300 rounded border-transparent focus:border-transparent focus:ring-0"
                                />
                              </div>
                            </td> -->
                            <td
                              *ngFor="
                                let col of displayedColumns;
                                let isLast = last;
                                let isFirst = first;
                                let index = index
                              "
                              class="w-auto px-1 py-4 whitespace-nowrap"
                              [ngClass]="{
                                'w-10 ': isFirst,
                                'w-20 ': index === 1
                              }"
                            >
                              <div
                                class="flex items-center"
                                [ngClass]="{
                                  'ml-4 ': isFirst
                                }"
                              >
                                {{ isFirst ? i + 1 + "." : "" }}
                                <div
                                  *ngIf="!isLast && col !== 'Datum' && !isFirst"
                                >
                                  <p class="text-sm">
                                    {{
                                      val[col]
                                        ? val[col].length > 12
                                          ? (val[col] | slice: 0:12) + ".."
                                          : val[col]
                                        : "-"
                                    }}
                                  </p>
                                </div>

                                <div class="flex space-x-2" *ngIf="isLast">
                                  <button
                                    mat-icon-button
                                    color="accent"
                                    (click)="openLead(val)"
                                  >
                                    <i class="bi bi-search"></i>
                                  </button>
                                </div>

                                <div *ngIf="col === 'Datum'" class="w-auto">
                                  {{ val.Datum | firestoreDate }}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</div>
