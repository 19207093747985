<div class="w-full h-screen bg-gray-100">
  <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
    <div class="relative bg-white shadow-xl">
      <div class="grid grid-cols-1 lg:grid-cols-3">
        <!-- Contact information -->
        <div
          class="relative overflow-hidden py-10 px-6 bg-kl-purple sm:px-10 xl:p-12"
        >
          <div
            class="absolute inset-0 pointer-events-none sm:hidden"
            aria-hidden="true"
          >
            <svg
              class="absolute inset-0 w-full h-full"
              width="343"
              height="388"
              viewBox="0 0 343 388"
              fill="none"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                fill="url(#linear1)"
                fill-opacity=".1"
              />
              <defs>
                <linearGradient
                  id="linear1"
                  x1="254.553"
                  y1="107.554"
                  x2="961.66"
                  y2="814.66"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#fff"></stop>
                  <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div
            class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
            aria-hidden="true"
          >
            <svg
              class="absolute inset-0 w-full h-full"
              width="359"
              height="339"
              viewBox="0 0 359 339"
              fill="none"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                fill="url(#linear2)"
                fill-opacity=".1"
              />
              <defs>
                <linearGradient
                  id="linear2"
                  x1="192.553"
                  y1="28.553"
                  x2="899.66"
                  y2="735.66"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#fff"></stop>
                  <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div
            class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
            aria-hidden="true"
          >
            <svg
              class="absolute inset-0 w-full h-full"
              width="160"
              height="678"
              viewBox="0 0 160 678"
              fill="none"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                fill="url(#linear3)"
                fill-opacity=".1"
              />
              <defs>
                <linearGradient
                  id="linear3"
                  x1="192.553"
                  y1="325.553"
                  x2="899.66"
                  y2="1032.66"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#fff"></stop>
                  <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h3
            class="text-lg font-medium text-white"
            i18n="@@contact_contactHeadline_label"
          >
            Kontakt
          </h3>
          <p
            class="mt-6 text-base text-indigo-50 max-w-3xl"
            i18n="@@contact_klicklead_label"
          >
            KlickLead
          </p>
          <p
            class="text-base text-indigo-50 max-w-3xl"
            i18n="@@contact_zwsystems_label"
          >
            ZW Systems GmbH
          </p>
          <p
            class="text-base text-indigo-50 max-w-3xl"
            i18n="@@contact_street_label"
          >
            Konrad-Zuse-Ring 34
          </p>
          <p
            class="text-base text-indigo-50 max-w-3xl"
            i18n="@@contact_zipCity_label"
          >
            53424 Remagen
          </p>

          <dl class="mt-8 space-y-6">
            <dt><span class="sr-only">E-Mail</span></dt>
            <dd class="flex text-base text-indigo-50">
              <!-- Heroicon name: outline/mail -->
              <svg
                class="flex-shrink-0 w-6 h-6 text-indigo-200"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              <a
                href="mailto:support@klicklead.de"
                class="ml-3 cursor-pointer"
                i18n="@@contact_supportMail_link"
                >support@klicklead.de</a
              >
            </dd>
          </dl>
          <ul role="list" class="mt-8 flex space-x-12">
            <li>
              <a
                class="text-indigo-200 hover:text-indigo-100"
                href="https://www.facebook.com/klicklead"
                target="_blank"
              >
                <span class="sr-only">Facebook</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-7 h-7"
                  aria-hidden="true"
                >
                  <path
                    d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                class="text-indigo-200 hover:text-indigo-100"
                href="https://www.linkedin.com/showcase/klicklead/about/?viewAsMember=true"
                target="_blank"
              >
                <span class="sr-only">Twitter</span>
                <i
                  class="bi bi-linkedin text-indigo-200 text-2xl hover:text-indigo-100"
                ></i>
              </a>
            </li>
          </ul>
        </div>

        <!-- Contact form -->
        <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
          <h3
            class="text-lg font-medium text-gray-900"
            i18n="@@contact_formHeadline_label"
          >
            Melde uns einen Bug
          </h3>
          <form
            action="#"
            method="POST"
            class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div class="sm:col-span-2">
              <div class="flex justify-between">
                <label
                  i18n="@@contact_formMessage_label"
                  for="message"
                  class="block text-sm font-medium text-gray-900"
                  >Nachricht</label
                >
              </div>
              <div class="mt-1">
                <textarea
                  id="message"
                  name="message"
                  [(ngModel)]="message"
                  rows="4"
                  class="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-kl-purple focus:border-kl-purple border border-gray-300 rounded-md"
                  aria-describedby="message-max"
                ></textarea>
              </div>
            </div>
            <div class="sm:col-span-2 sm:flex sm:justify-end">
              <button
                i18n="@@contact_formSend_button"
                (click)="sendMessage()"
                type="submit"
                class="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-kl-purple hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kl-purple sm:w-auto"
              >
                Senden
                <i
                  *ngIf="showLoader"
                  class="ml-2 bi bi-arrow-clockwise animate-spin"
                ></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
