<mat-tab-group class="h-full" [(selectedIndex)]="rightTabIndex">
  <mat-tab *ngIf="selectedElement !== null || showBlocks" label="Blöcke">
    <div
      cdkDropList
      #elementsList="cdkDropList"
      [cdkDropListData]="elements"
      cdkDropListConnectedTo="htmlList"
    >
      <div class="flex flex-wrap mt-4">
        <button
          cdkDrag
          class="flex flex-col items-center w-14 m-5"
          *ngFor="let block of elements"
          (click)="addElement(block)"
        >
          <div class="flex flex-col items-center">
            <i [ngClass]="getBlockIcon(block.type)" class="text-2xl"></i>
            <p class="">{{ getBlockName(block.type) }}</p>
          </div>
        </button>
      </div>
    </div>
  </mat-tab>
  <mat-tab *ngIf="selectedElement !== null" label="Details">
    <div class="mb-5"></div>

    <div *ngIf="currentElementIndex === 0">
      <div class="flex justify-between mt-5 mr-5 ml-5 mb-8">
        <p>Als Header für alle Seiten verwenden</p>
        <input
          [(ngModel)]="selectedElement.isHeader"
          (change)="setHeader()"
          id="isHeader"
          aria-describedby="isHeader"
          type="checkbox"
          class="bg-gray-50 border border-gray-300 h-4 w-4 rounded focus:ring-transparent"
          required
        />
      </div>
    </div>

    <div class="m-5">
      <div *ngIf="isVisible('bulletpoints')">
        <div>
          <p>Aufzählungen</p>
          <mat-accordion>
            <div
              cdkDropList
              [cdkDropListData]="selectedElement.content.answers"
              (cdkDropListDropped)="dropForm($event)"
            >
              <div
                *ngFor="
                  let bp of selectedElement.content.bulletpoints;
                  let i = index
                "
              >
                <mat-expansion-panel
                  cdkDrag
                  [expanded]="i === currentSelectionIndex"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <i class="bi bi-grip-vertical mr-2" cdkDragHandle></i>
                      {{ bp.text.substring(0, 20) }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="mt-5">
                    <div>
                      <div class="flex items-center justify-between">
                        <p>Ausgewähltes Icon:</p>

                        <i [ngClass]="bp.icon"></i>
                      </div>
                      <button
                        class="mt-4 text-zwblue hover:text-white border border-zwblue hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3 w-full"
                        (click)="openIconLibrary('bulletpoints', i)"
                      >
                        Icon Bibliothek
                      </button>
                    </div>
                    <mat-form-field>
                      <mat-label>Text</mat-label>
                      <input
                        matInput
                        type="text"
                        (ngModelChange)="changed($event)"
                        [(ngModel)]="bp.text"
                      />
                    </mat-form-field>
                  </div>

                  <div class="text-center">
                    <button
                      mat-icon-button
                      color="warn"
                      (click)="removeBulletPoint(i)"
                    >
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </mat-expansion-panel>
              </div>
            </div>
          </mat-accordion>

          <div class="text-center w-2/3">
            <button
              mat-fab
              class="mt-20"
              color="secondary"
              (click)="addAnswer()"
            >
              <i class="bi bi-plus-circle"></i>
            </button>
            <div class="mb-5"></div>
          </div>
        </div>
      </div>

      <div *ngIf="isVisible('selection')">
        <div>
          <p>Antworten</p>
          <mat-accordion>
            <div
              cdkDropList
              [cdkDropListData]="selectedElement.content.answers"
              (cdkDropListDropped)="dropForm($event)"
            >
              <div
                *ngFor="
                  let answer of selectedElement.content.answers;
                  let i = index
                "
              >
                <mat-expansion-panel
                  cdkDrag
                  [expanded]="i === currentSelectionIndex"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <i class="bi bi-grip-vertical mr-2" cdkDragHandle></i>
                      {{ answer.answerText }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="mt-5">
                    <mat-form-field>
                      <mat-label>{{
                        langService.getWording(
                          "funnelEditor",
                          "tabRightQuizAnswerText"
                        )
                      }}</mat-label>
                      <input
                        matInput
                        type="text"
                        (ngModelChange)="changed($event)"
                        [(ngModel)]="answer.answerText"
                      />
                    </mat-form-field>
                  </div>

                  <div class="text-center">
                    <button
                      mat-icon-button
                      color="warn"
                      (click)="removeAnswer(i)"
                    >
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </mat-expansion-panel>
              </div>
            </div>
          </mat-accordion>

          <div class="text-center w-2/3">
            <button
              mat-fab
              class="mt-20"
              color="secondary"
              (click)="addAnswer()"
            >
              <i class="bi bi-plus-circle"></i>
            </button>
            <div class="mb-5"></div>
          </div>
        </div>
      </div>
      <div *ngIf="isVisible('text')">
        <div>
          <p>
            {{ langService.getWording("funnelEditor", "tabRightHeader") }}
          </p>
          <mat-form-field class="">
            <textarea
              matInput
              *ngIf="selectedElement && selectedElement.type !== 'quiz'"
              rows="4"
              type="text"
              (ngModelChange)="changed($event)"
              [(ngModel)]="selectedElement.content"
            ></textarea>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isVisible('upload')">
        <div>
          <p>Text</p>
          <mat-form-field class="">
            <textarea
              matInput
              rows="4"
              type="text"
              (ngModelChange)="changed($event)"
              [(ngModel)]="selectedElement.content.text"
            ></textarea>
          </mat-form-field>
        </div>

        <div class="mt-6">
          <p>Platzhalter</p>
          <div class="image-upload text-center">
            <label (click)="openMediaLibrary('upload', 1)">
              <img
                class="cursor-pointer w-full"
                [src]="getSantizeUrl(selectedElement.content.picture)"
              />
            </label>
          </div>
          <button
            (click)="openMediaLibrary('upload', 1)"
            type="button"
            class="mt-4 text-zwblue hover:text-white border border-zwblue hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3 w-full"
          >
            <i class="bi bi-collection-play"></i> Mediathek
          </button>
        </div>

        <div class="mt-6">
          <p>Pflichtfeld</p>
          <mat-slide-toggle
            class="mt-5"
            color="primary"
            (ngModelChange)="changed($event)"
            [(ngModel)]="selectedElement.content.required"
          ></mat-slide-toggle>
        </div>

        <div class="mt-6">
          <p>Maximale Uploads</p>
          <div class="mt-5">
            <div class="flex justify-start items-center">
              <mat-slider
                color="primary"
                thumbLabel
                min="1"
                (ngModelChange)="changed($event)"
                [value]="selectedElement.content.maxUploads"
                (input)="setMaxUploads($event)"
                max="10"
              ></mat-slider>

              <input
                type="number"
                (ngModelChange)="changed($event)"
                [(ngModel)]="selectedElement.content.maxUploads"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
              />
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isVisible('video')">
        <div>
          <p>Video URL</p>

          <input
            type="text"
            id="videoUrl"
            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
            placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            (ngModelChange)="changed($event)"
            [(ngModel)]="selectedElement.content.url"
            required
          />
        </div>
      </div>
      <div *ngIf="isVisible('fakeloader')">
        <div>
          <p>Dauer Ladebalken (Sekunden)</p>
          <div class="mt-5">
            <div class="flex justify-start items-center">
              <mat-slider
                color="primary"
                thumbLabel
                min="1"
                (ngModelChange)="changed($event)"
                [value]="selectedElement.content.duration"
                (input)="selectedElement.content.duration = $event.value"
                max="30"
              ></mat-slider>

              <input
                type="number"
                (ngModelChange)="changed($event)"
                [(ngModel)]="selectedElement.content.duration"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
              />
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isVisible('socialmedia')">
        <div>
          <p>Plattform</p>
          <div class="mt-5">
            <button
              id="dropdownButton"
              data-dropdown-toggle="dropdownSocialMedia"
              (click)="toggleSocialMediaDropdown()"
              class="text-white bg-zwblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center capitalize dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
            >
              {{
                selectedElement.content.type
                  ? selectedElement.content.type
                  : "Plattform auswählen"
              }}
              <svg
                class="ml-2 w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            <!-- Dropdown menu -->
            <div
              id="dropdownSocialMedia"
              class="hidden z-10 w-44 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700"
            >
              <ul class="py-1" aria-labelledby="dropdownButton">
                <li
                  *ngFor="let sm of socialMedias"
                  (click)="selectSocialMedia(sm)"
                >
                  <p
                    class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer capitalize"
                  >
                    {{ sm }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <p>URL</p>
          <div class="mt-2">
            <input
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-full p-2.5"
              *ngIf="selectedElement"
              type="text"
              (ngModelChange)="changed($event)"
              [(ngModel)]="selectedElement.content.url"
            />
          </div>
        </div>
      </div>
      <div *ngIf="isVisible('slider')">
        <div>
          <p>Minimaler Wert</p>
          <div class="mt-5 mb-5">
            <input
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-full p-2.5"
              *ngIf="selectedElement"
              type="number"
              (ngModelChange)="changed($event)"
              [(ngModel)]="selectedElement.content.min"
            />
          </div>
        </div>
        <div>
          <p>Maximaler Wert</p>
          <div class="mt-5 mb-5">
            <input
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-full p-2.5"
              *ngIf="selectedElement"
              type="number"
              (ngModelChange)="changed($event)"
              [(ngModel)]="selectedElement.content.max"
            />
          </div>
        </div>

        <p>Schritte</p>
        <div class="flex justify-start items-center">
          <mat-slider
            color="primary"
            thumbLabel
            min="5"
            (ngModelChange)="changed($event)"
            [value]="selectedElement.content.steps"
            (input)="setSliderSteps($event)"
            max="100"
          ></mat-slider>

          <input
            type="number"
            (ngModelChange)="changed($event)"
            [(ngModel)]="selectedElement.content.steps"
            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
          />
        </div>
      </div>
      <div *ngIf="isVisible('textfield')">
        <div>
          <p>
            {{ langService.getWording("funnelEditor", "tabRightHeader") }}
          </p>
          <div class="mt-5">
            <input
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-full p-2.5"
              *ngIf="selectedElement"
              type="text"
              (ngModelChange)="changed($event)"
              [(ngModel)]="selectedElement.content.text"
            />
          </div>
        </div>
        <div class="mt-5">
          <p>
            {{ langService.getWording("funnelEditor", "tabRightFormRequired") }}
          </p>
          <mat-slide-toggle
            class="mt-5"
            color="primary"
            (ngModelChange)="changed($event)"
            [(ngModel)]="selectedElement.content.required"
          ></mat-slide-toggle>
        </div>

        <div class="mt-5">
          <mat-form-field appearance="outline" color="primary">
            <mat-label>
              {{
                langService.getWording("funnelEditor", "tabRightFormType")
              }}</mat-label
            >
            <mat-select
              matNativeControl
              (ngModelChange)="changed($event)"
              [(ngModel)]="selectedElement.content.type"
              matNativeControl
            >
              <mat-option value="text">
                {{
                  langService.getWording("funnelEditor", "tabRightFormTypeText")
                }}
              </mat-option>
              <mat-option value="email">
                {{
                  langService.getWording(
                    "funnelEditor",
                    "tabRightFormTypeEmail"
                  )
                }}
              </mat-option>
              <mat-option value="number">
                {{
                  langService.getWording(
                    "funnelEditor",
                    "tabRightFormTypeNumber"
                  )
                }}
              </mat-option>
              <mat-option value="tel">
                {{
                  langService.getWording("funnelEditor", "tabRightFormTypeTel")
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div
        *ngIf="selectedElement && selectedElement.type === 'form'"
        cdkDropList
        [cdkDropListData]="selectedElement.content.inputs"
        (cdkDropListDropped)="dropForm($event)"
      >
        <div
          *ngFor="
            let input of selectedElement.content.inputs;
            let inputIndex = index;
            let last = last
          "
        >
          <mat-expansion-panel
            cdkDrag
            (click)="selectFormElement(null, inputIndex, null)"
            [expanded]="inputIndex === currentFormPanelIndex"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <i class="bi bi-grip-vertical mr-2" cdkDragHandle></i>
                <p>{{ input.text }}</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <mat-form-field>
                <mat-label>Text</mat-label>
                <input
                  matInput
                  rows="2"
                  type="text"
                  (ngModelChange)="changed($event)"
                  [(ngModel)]="input.text"
                />
              </mat-form-field>
            </div>

            <div>
              <p>
                {{
                  langService.getWording("funnelEditor", "tabRightFormRequired")
                }}
              </p>
              <mat-slide-toggle
                color="primary"
                (ngModelChange)="changed($event)"
                [(ngModel)]="input.required"
              ></mat-slide-toggle>
            </div>

            <div>
              <mat-form-field appearance="outline" color="primary">
                <mat-label>
                  {{
                    langService.getWording("funnelEditor", "tabRightFormType")
                  }}</mat-label
                >
                <mat-select
                  matNativeControl
                  (ngModelChange)="changed($event)"
                  [(ngModel)]="input.type"
                  matNativeControl
                >
                  <mat-option value="text">
                    {{
                      langService.getWording(
                        "funnelEditor",
                        "tabRightFormTypeText"
                      )
                    }}
                  </mat-option>
                  <mat-option value="email">
                    {{
                      langService.getWording(
                        "funnelEditor",
                        "tabRightFormTypeEmail"
                      )
                    }}
                  </mat-option>
                  <mat-option value="number">
                    {{
                      langService.getWording(
                        "funnelEditor",
                        "tabRightFormTypeNumber"
                      )
                    }}
                  </mat-option>
                  <mat-option value="tel">
                    {{
                      langService.getWording(
                        "funnelEditor",
                        "tabRightFormTypeTel"
                      )
                    }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div>
              <p>Icon</p>
              <mat-slide-toggle
                color="primary"
                (ngModelChange)="changed($event)"
                [(ngModel)]="input.isIcon"
              ></mat-slide-toggle>
            </div>

            <div *ngIf="input.isIcon">
              <div>
                <p>Filter</p>

                <!--   <mat-form-field
                  class="flex flex-col"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>Icons</mat-label>
                  <mat-select
                    #iconSelect
                    matNativeControl
                    [(ngModel)]="input.icon"
                    matNativeControl
                  >
                    <input
                      class="w-full border-2 border-gray-600 my-4"
                      (keyup)="onKey($event)"
                      placeholder="Filter"
                    />
                    <mat-select-trigger>
                      <i [ngClass]="input.icon"></i>
                    </mat-select-trigger>
                    <mat-option
                      (click)="saveProject()"
                      *ngFor="let ico of currentIcons; let i = index"
                      [value]="ico"
                    >
                      <i [ngClass]="ico"></i> {{ ico }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->

                <div>
                  <div class="flex items-center justify-between">
                    <p>Ausgewähltes Icon:</p>

                    <i [ngClass]="input.icon"></i>
                  </div>
                  <button
                    class="mt-4 text-zwblue hover:text-white border border-zwblue hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3 w-full"
                    (click)="openIconLibrary('form', inputIndex)"
                  >
                    Icon Bibliothek
                  </button>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button
                mat-icon-button
                color="warn"
                (click)="removeInputField(inputIndex)"
              >
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel
            (click)="
              selectFormElement(
                null,
                selectedElement.content.inputs.length,
                null
              )
            "
            [expanded]="
              selectedElement.content.inputs.length === currentFormPanelIndex
            "
            *ngIf="last"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ langService.getWording("funnelEditor", "privacyPolicy") }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="mt-5">
              <p>
                {{
                  langService.getWording("funnelEditor", "tabRightFormRequired")
                }}
              </p>
              <mat-slide-toggle
                color="primary"
                (ngModelChange)="changed($event)"
                [(ngModel)]="selectedElement.content.privacyCheck.required"
              ></mat-slide-toggle>
            </div>

            <div class="mt-5">
              <mat-form-field>
                <mat-label>Text</mat-label>
                <textarea
                  matInput
                  rows="2"
                  (ngModelChange)="changed($event)"
                  type="text"
                  [(ngModel)]="selectedElement.content.privacyCheck.text"
                ></textarea>
              </mat-form-field>
            </div>

            <div class="mt-5">
              <mat-form-field>
                <mat-label>Link</mat-label>
                <input
                  matInput
                  type="text"
                  (ngModelChange)="changed($event)"
                  [(ngModel)]="selectedElement.content.privacyCheck.link"
                />
              </mat-form-field>
            </div>

            <div class="mt-5">
              <p>
                {{
                  langService.getWording(
                    "funnelEditor",
                    "tabRightQuizAnswerFontColor"
                  )
                }}
              </p>
              <div class="flex justify-between items-center">
                <input
                  type="color"
                  id="favcolor"
                  name="favcolor"
                  class="w-10 h-10"
                  (blur)="setPrivacyColor($event)"
                  [value]="selectedElement.content.privacyCheck.style.fontColor"
                />
                <input
                  type="text"
                  placeholder="#ffffff"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
                  (blur)="setPrivacyColor($event)"
                  [value]="
                    selectedElement.content.privacyCheck.style.fontColor
                      ? selectedElement.content.privacyCheck.style.fontColor
                      : ''
                  "
                />
                <button
                  mat-icon-button
                  color="warn"
                  (click)="deletePrivacyColor()"
                >
                  <i class="bi bi-trash"></i>
                </button>
              </div>
            </div>

            <div class="mt-5">
              <p>
                {{
                  langService.getWording(
                    "funnelEditor",
                    "tabRightQuizAnswerFontSize"
                  )
                }}
              </p>
              <div class="flex justify-start items-center">
                <mat-slider
                  color="primary"
                  thumbLabel
                  min="5"
                  (ngModelChange)="changed($event)"
                  [value]="selectedElement.content.privacyCheck.style.fontSize"
                  (input)="setPrivacyButtonFontSize($event)"
                  max="30"
                ></mat-slider>

                <input
                  type="number"
                  (ngModelChange)="changed($event)"
                  [(ngModel)]="
                    selectedElement.content.privacyCheck.style.fontSize
                  "
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
                />
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            (click)="
              selectFormElement(
                null,
                selectedElement.content.inputs.length + 1,
                null
              )
            "
            [expanded]="
              selectedElement.content.inputs.length + 1 ===
              currentFormPanelIndex
            "
            *ngIf="last"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ selectedElement.content.submitButton.text }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-tab-group color="primary">
              <mat-tab label="Button">
                <div class="mt-5">
                  <p>Text</p>
                  <mat-form-field>
                    <mat-label>Text</mat-label>
                    <input
                      matInput
                      type="text"
                      (ngModelChange)="changed($event)"
                      [(ngModel)]="selectedElement.content.submitButton.text"
                    />
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field appearance="outline" color="primary">
                    <mat-label>{{
                      langService.getWording("funnelEditor", "tabRightAction")
                    }}</mat-label>
                    <mat-select
                      matNativeControl
                      (ngModelChange)="changed($event)"
                      [(ngModel)]="
                        selectedElement.content.submitButton.action.type
                      "
                      matNativeControl
                    >
                      <mat-option value="nextPage">
                        {{
                          langService.getWording(
                            "funnelEditor",
                            "tabRightctionNextPage"
                          )
                        }}
                      </mat-option>
                      <mat-option value="connectedPage">
                        {{
                          langService.getWording(
                            "funnelEditor",
                            "tabRightActionOtherPageText"
                          )
                        }}
                      </mat-option>
                      <mat-option value="link">
                        {{
                          langService.getWording(
                            "funnelEditor",
                            "tabRightActionOtherPage"
                          )
                        }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                  *ngIf="
                    selectedElement.content.submitButton.action.type ===
                    'connectedPage'
                  "
                >
                  <p>
                    {{
                      langService.getWording(
                        "funnelEditor",
                        "tabRightActionOtherPageText"
                      )
                    }}
                  </p>
                  <mat-form-field appearance="outline" color="primary">
                    <mat-label>
                      {{
                        langService.getWording("funnelEditor", "tabRightAction")
                      }}</mat-label
                    >
                    <mat-select
                      matNativeControl
                      *ngIf="projectService.currentProject"
                      (ngModelChange)="changed($event)"
                      [(ngModel)]="
                        selectedElement.content.submitButton.action
                          .connectedPage
                      "
                      matNativeControl
                    >
                      <mat-option
                        *ngFor="
                          let page of projectService.currentProject.pages;
                          let i = index
                        "
                        [value]="page.id"
                      >
                        {{ page.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                  *ngIf="
                    selectedElement.content.submitButton.action.type === 'link'
                  "
                >
                  <p>Link</p>
                  <mat-form-field>
                    <mat-label>Link</mat-label>
                    <input
                      matInput
                      type="text"
                      (ngModelChange)="changed($event)"
                      [(ngModel)]="
                        selectedElement.content.submitButton.action.link
                      "
                    />
                  </mat-form-field>
                </div>
              </mat-tab>
              <mat-tab label="Design">
                <div class="mt-5">
                  <p>
                    {{
                      langService.getWording("funnelEditor", "tabDesignFormat")
                    }}
                  </p>

                  <mat-button-toggle-group
                    *ngIf="selectedElement"
                    multiple
                    (change)="
                      setTextDecorationFormButton(
                        selectedElement.content.submitButton,
                        $event.value
                      )
                    "
                    name="fontStyle"
                    aria-label="Font Style"
                  >
                    <mat-button-toggle
                      [checked]="
                        selectedElement.content.submitButton.style
                          .fontWeight === 'bold'
                      "
                      value="bold"
                    >
                      <i class="bi bi-type-bold"></i>
                    </mat-button-toggle>
                    <mat-button-toggle
                      [checked]="
                        selectedElement.content.submitButton.style.fontStyle ===
                        'italic'
                      "
                      value="italic"
                    >
                      <i class="bi bi-type-italic"></i>
                    </mat-button-toggle>
                    <mat-button-toggle
                      [checked]="
                        selectedElement.content.submitButton.style
                          .textDecoration === 'underline'
                      "
                      value="underline"
                    >
                      <i class="bi bi-type-underline"></i>
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>

                <div>
                  <p>
                    {{
                      langService.getWording(
                        "funnelEditor",
                        "tabRightQuizAnswerFontColor"
                      )
                    }}
                  </p>
                  <div class="flex justify-between items-center">
                    <input
                      type="color"
                      id="favcolor"
                      class="h-10 w-10"
                      name="favcolor"
                      (blur)="setFontColor($event)"
                      [value]="
                        selectedElement.content.submitButton.style.fontColor
                      "
                    />

                    <input
                      type="text"
                      placeholder="#ffffff"
                      class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
                      (blur)="setFontColor($event)"
                      [value]="
                        selectedElement.content.submitButton.style.fontColor
                          ? selectedElement.content.submitButton.style.fontColor
                          : ''
                      "
                    />
                    <button
                      mat-icon-button
                      color="warn"
                      (click)="deleteSubmitButtonColor('fontColor')"
                    >
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>

                <div>
                  <p>
                    {{
                      langService.getWording(
                        "funnelEditor",
                        "tabRightQuizAnswerDesignBackgroundColor"
                      )
                    }}
                  </p>

                  <div class="flex justify-between items-center">
                    <input
                      type="color"
                      class="h-10 w-10"
                      id="favcolor"
                      name="favcolor"
                      (blur)="setBackgroundColor($event)"
                      [value]="
                        selectedElement.content.submitButton.style
                          .backgroundColor
                      "
                    />

                    <input
                      type="text"
                      placeholder="#ffffff"
                      class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
                      (blur)="setBackgroundColor($event)"
                      [value]="
                        selectedElement.content.submitButton.style
                          .backgroundColor
                          ? selectedElement.content.submitButton.style
                              .backgroundColor
                          : ''
                      "
                    />
                    <button
                      mat-icon-button
                      color="warn"
                      (click)="deleteSubmitButtonColor('backgroundColor')"
                    >
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>

                <div>
                  <p>Hover Hintergrundfarbe</p>

                  <div class="flex justify-between items-center">
                    <input
                      type="color"
                      class="h-10 w-10"
                      id="favcolor"
                      name="favcolor"
                      (blur)="setHoverBackgroundColor($event)"
                      [value]="
                        selectedElement.content.submitButton.style.hoverColor
                      "
                    />

                    <input
                      type="text"
                      placeholder="#ffffff"
                      class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
                      (blur)="setHoverBackgroundColor($event)"
                      [value]="
                        selectedElement.content.submitButton.style.hoverColor
                          ? selectedElement.content.submitButton.style
                              .hoverColor
                          : ''
                      "
                    />
                    <button
                      mat-icon-button
                      color="warn"
                      (click)="deleteSubmitButtonColor('hoverColor')"
                    >
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>

                <div>
                  <p>
                    {{
                      langService.getWording(
                        "funnelEditor",
                        "tabRightQuizAnswerDesignFontSize"
                      )
                    }}
                  </p>
                  <div class="flex justify-start items-center">
                    <mat-slider
                      color="primary"
                      thumbLabel
                      min="5"
                      (ngModelChange)="changed($event)"
                      [value]="
                        selectedElement.content.submitButton.style.fontSize
                      "
                      (input)="
                        selectedElement.content.submitButton.style.fontSize =
                          $event.value
                      "
                      max="50"
                    ></mat-slider>

                    <input
                      type="number"
                      (ngModelChange)="changed($event)"
                      [(ngModel)]="
                        selectedElement.content.privacyCheck.style.fontSize
                      "
                      class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
                    />
                  </div>
                </div>

                <div>
                  <p>
                    {{
                      langService.getWording("funnelEditor", "tabDesignSize")
                    }}
                  </p>
                  <div class="flex justify-start items-center">
                    <mat-slider
                      color="primary"
                      thumbLabel
                      min="30"
                      (ngModelChange)="changed($event)"
                      [value]="
                        selectedElement.content.submitButton.style.height
                      "
                      (input)="
                        selectedElement.content.submitButton.style.height =
                          $event.value
                      "
                      max="100"
                    ></mat-slider>

                    <input
                      type="number"
                      (ngModelChange)="changed($event)"
                      [(ngModel)]="
                        selectedElement.content.submitButton.style.height
                      "
                      class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
                    />
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </mat-expansion-panel>
        </div>
        <div class="text-center">
          <button
            mat-fab
            class="mt-16"
            color="secondary"
            (ngModelChange)="changed($event)"
            (click)="addFormInput()"
          >
            <i class="bi bi-plus-circle"></i>
          </button>
          <div class="mb-5"></div>
        </div>
      </div>

      <div *ngIf="selectedElement && selectedElement.type === 'quiz'">
        <div>
          <p>
            {{ langService.getWording("funnelEditor", "tabRightQuizAnwswers") }}
          </p>
          <mat-accordion>
            <div
              cdkDropList
              [cdkDropListData]="selectedElement.content.answers"
              (cdkDropListDropped)="dropQuiz($event)"
            >
              <div
                *ngFor="
                  let answer of selectedElement.content.answers;
                  let i = index
                "
              >
                <mat-expansion-panel
                  cdkDrag
                  [expanded]="i === currentPanelIndex"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <i class="bi bi-grip-vertical mr-2" cdkDragHandle></i>
                      <p>{{ answer.answerText }}</p>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-tab-group color="primary">
                    <mat-tab label="Info">
                      <div class="mt-5">
                        <mat-form-field>
                          <mat-label>{{
                            langService.getWording(
                              "funnelEditor",
                              "tabRightQuizAnswerText"
                            )
                          }}</mat-label>
                          <input
                            matInput
                            type="text"
                            (ngModelChange)="changed($event)"
                            [(ngModel)]="answer.answerText"
                          />
                        </mat-form-field>
                      </div>

                      <div class="mt-5">
                        <p>
                          {{
                            langService.getWording(
                              "funnelEditor",
                              "tabRightAction"
                            )
                          }}
                        </p>

                        <mat-form-field appearance="outline" color="primary">
                          <mat-label>
                            {{
                              langService.getWording(
                                "funnelEditor",
                                "tabRightAction"
                              )
                            }}
                          </mat-label>
                          <mat-select
                            matNativeControl
                            [(ngModel)]="answer.action.type"
                            (ngModelChange)="changed($event)"
                            matNativeControl
                          >
                            <mat-option value="nextPage">
                              {{
                                langService.getWording(
                                  "funnelEditor",
                                  "tabRightctionNextPage"
                                )
                              }}
                            </mat-option>
                            <mat-option value="connectedPage">
                              {{
                                langService.getWording(
                                  "funnelEditor",
                                  "tabRightActionOtherPage"
                                )
                              }}
                            </mat-option>
                            <mat-option value="link">
                              {{
                                langService.getWording(
                                  "funnelEditor",
                                  "tabRightActionLink"
                                )
                              }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div
                        class="mt-5"
                        *ngIf="answer.action.type === 'connectedPage'"
                      >
                        <p>
                          {{
                            langService.getWording(
                              "funnelEditor",
                              "tabRightActionOtherPageText"
                            )
                          }}
                        </p>
                        <mat-form-field appearance="outline" color="primary">
                          <mat-label>
                            {{
                              langService.getWording(
                                "funnelEditor",
                                "tabRightAction"
                              )
                            }}</mat-label
                          >
                          <mat-select
                            matNativeControl
                            *ngIf="projectService.currentProject"
                            (ngModelChange)="changed($event)"
                            [(ngModel)]="answer.action.connectedPage"
                            matNativeControl
                          >
                            <mat-option
                              *ngFor="
                                let page of projectService.currentProject.pages;
                                let i = index
                              "
                              [value]="page.id"
                            >
                              {{ page.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="mt-5" *ngIf="answer.action.type === 'link'">
                        <p>Link</p>
                        <mat-form-field>
                          <mat-label>Link</mat-label>
                          <input
                            matInput
                            type="text"
                            (ngModelChange)="changed($event)"
                            [(ngModel)]="answer.action.link"
                          />
                        </mat-form-field>
                      </div>

                      <div class="mt-5" class="text-center">
                        <button
                          mat-icon-button
                          color="warn"
                          (click)="removeAnswer(i)"
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                      </div>
                    </mat-tab>
                    <mat-tab label="Design">
                      <div class="mt-5">
                        <p>
                          {{
                            langService.getWording(
                              "funnelEditor",
                              "tabRightQuizAnswerDesignPictureIcon"
                            )
                          }}
                        </p>
                        <mat-button-toggle-group [value]="answer.type">
                          <mat-button-toggle
                            value="picture"
                            (click)="setPictureOrIcon('picture', i)"
                          >
                            <i class="bi bi-image"></i>
                          </mat-button-toggle>
                          <mat-button-toggle
                            value="icon"
                            (click)="setPictureOrIcon('icon', i)"
                          >
                            <i class="bi bi-emoji-smile"></i>
                          </mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>

                      <div class="mt-5" *ngIf="answer.type === 'icon'">
                        <div>
                          <div class="flex items-center justify-between">
                            <p>Ausgewähltes Icon:</p>

                            <i [ngClass]="answer.answerIcon"></i>
                          </div>
                          <button
                            class="mt-4 text-zwblue hover:text-white border border-zwblue hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3 w-full"
                            (click)="openIconLibrary('quiz', i)"
                          >
                            Icon Bibliothek
                          </button>
                        </div>
                      </div>
                      <div class="mt-5" *ngIf="answer.type === 'picture'">
                        <div>
                          <p>
                            {{
                              langService.getWording(
                                "funnelEditor",
                                "tabRightQuizAnswerDesignPicture"
                              )
                            }}
                          </p>

                          <div class="image-upload">
                            <label
                              [for]="'fileinput' + i"
                              (click)="openMediaLibrary('quiz', i)"
                            >
                              <img
                                class="cursor-pointer w-full"
                                [src]="getSantizeUrl(answer.answerPicture)"
                              />
                            </label>

                            <!--  <input
                              (change)="onAnswerSelectFile($event, i)"
                              [id]="'fileinput' + i"
                               (ngModelChange)="changed($event)"
                              type="file"
                              class="hidden"
                            /> -->

                            <!--    <div class="text-center">
                          <button
                            color="primary"
                            mat-icon-button
                            (click)="openPictureInputById('fileinput' + i)"
                            class="primary"
                          >
                            <i class="bi bi-plus-circle"></i>
                          </button>
                        </div> -->
                          </div>
                        </div>
                      </div>

                      <div class="mt-5">
                        <p>
                          {{
                            langService.getWording(
                              "funnelEditor",
                              "tabRightQuizAnswerDesignColor"
                            )
                          }}
                        </p>
                        <div class="flex justify-between items-center">
                          <input
                            type="color"
                            class="h-10 w-10"
                            id="favcolor"
                            (blur)="setQuizFontColor(i, $event)"
                            name="favcolor"
                            [value]="answer.style.fontColor"
                          />

                          <input
                            type="text"
                            placeholder="#ffffff"
                            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
                            (blur)="setQuizFontColor(i, $event)"
                            [value]="
                              answer.style.fontColor
                                ? answer.style.fontColor
                                : ''
                            "
                          />
                          <button
                            mat-icon-button
                            color="warn"
                            (click)="deleteQuizFontColor(i)"
                          >
                            <i class="bi bi-trash"></i>
                          </button>
                        </div>
                      </div>

                      <div class="mt-5">
                        <p>
                          {{
                            langService.getWording(
                              "funnelEditor",
                              "tabRightQuizAnswerDesignBackgroundColor"
                            )
                          }}
                        </p>
                        <div class="flex justify-between items-center">
                          <input
                            type="color"
                            class="h-10 w-10"
                            id="favcolor"
                            name="favcolor"
                            (blur)="setQuizBackgroundColor(i, $event)"
                            [value]="answer.style.backgroundColor"
                          />

                          <input
                            type="text"
                            placeholder="#ffffff"
                            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
                            (blur)="setQuizBackgroundColor(i, $event)"
                            [value]="
                              answer.style.backgroundColor
                                ? answer.style.backgroundColor
                                : ''
                            "
                          />
                          <button
                            mat-icon-button
                            color="warn"
                            (click)="deleteQuizBackgroundColor(i)"
                          >
                            <i class="bi bi-trash"></i>
                          </button>
                        </div>
                      </div>

                      <div class="mt-5">
                        <p>Schriftgröße</p>
                        <div class="flex justify-start items-center">
                          <mat-slider
                            color="primary"
                            thumbLabel
                            (ngModelChange)="changed($event)"
                            min="5"
                            [value]="answer.style.fontSize"
                            (input)="setQuizFontSize(i, $event)"
                            max="100"
                          ></mat-slider>

                          <input
                            type="number"
                            (ngModelChange)="changed($event)"
                            [(ngModel)]="answer.style.fontSize"
                            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
                          />
                        </div>
                      </div>

                      <div class="mt-5">
                        <p>Icon Größe</p>
                        <div class="flex justify-start items-center">
                          <mat-slider
                            color="primary"
                            thumbLabel
                            (ngModelChange)="changed($event)"
                            min="5"
                            [value]="answer.style.iconSize"
                            (input)="setQuizIconSize(i, $event)"
                            max="100"
                          ></mat-slider>

                          <input
                            type="number"
                            (ngModelChange)="changed($event)"
                            [(ngModel)]="answer.style.iconSize"
                            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
                          />
                        </div>
                      </div>

                      <button
                        color="primary"
                        mat-icon-button
                        (ngModelChange)="changed($event)"
                        (click)="setStyleToAllAnswers(answer)"
                        class="primary mt-5"
                      >
                        <i class="bi bi-collection"></i>
                        {{
                          langService.getWording(
                            "funnelEditor",
                            "tabRightQuizAnswerDesignApplyToAllAnswers"
                          )
                        }}
                      </button>
                    </mat-tab>
                  </mat-tab-group>
                </mat-expansion-panel>
              </div>
            </div>
          </mat-accordion>

          <div class="text-center w-full">
            <button
              class="mt-10 h-20 w-20"
              color="secondary "
              (click)="addAnswer()"
            >
              <i class="bi bi-plus-circle text-2xl"></i>
            </button>
            <div class="mb-5"></div>
          </div>
        </div>
      </div>

      <div *ngIf="selectedElement">
        <mat-form-field
          *ngIf="selectedElement.type === 'button'"
          appearance="outline"
          color="primary"
        >
          <mat-label>{{
            langService.getWording("funnelEditor", "tabRightAction")
          }}</mat-label>
          <mat-select
            matNativeControl
            (ngModelChange)="changed($event)"
            [(ngModel)]="selectedElement.action.type"
            matNativeControl
          >
            <mat-option value="nextPage">
              {{
                langService.getWording("funnelEditor", "tabRightctionNextPage")
              }}
            </mat-option>
            <mat-option value="connectedPage">
              {{
                langService.getWording(
                  "funnelEditor",
                  "tabRightActionOtherPageText"
                )
              }}
            </mat-option>
            <mat-option value="link">
              {{
                langService.getWording(
                  "funnelEditor",
                  "tabRightActionOtherPage"
                )
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="selectedElement.action">
          <div *ngIf="selectedElement.action.type === 'connectedPage'">
            <p>
              {{
                langService.getWording(
                  "funnelEditor",
                  "tabRightActionOtherPageText"
                )
              }}
            </p>
            <mat-form-field appearance="outline" color="primary">
              <mat-label>
                {{
                  langService.getWording("funnelEditor", "tabRightAction")
                }}</mat-label
              >
              <mat-select
                matNativeControl
                *ngIf="projectService.currentProject"
                (ngModelChange)="changed($event)"
                [(ngModel)]="selectedElement.action.connectedPage"
                matNativeControl
              >
                <mat-option
                  *ngFor="
                    let page of projectService.currentProject.pages;
                    let i = index
                  "
                  [value]="page.id"
                >
                  {{ page.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="selectedElement.action.type === 'link'">
            <p>Link</p>
            <mat-form-field>
              <mat-label>Link</mat-label>
              <input
                matInput
                type="text"
                (ngModelChange)="changed($event)"
                [(ngModel)]="selectedElement.action.link"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="selectedElement">
        <div *ngIf="selectedElement.type === 'picture'">
          <p>
            {{
              langService.getWording(
                "funnelEditor",
                "tabRightQuizAnswerDesignPicture"
              )
            }}
          </p>
          <div class="image-upload text-center">
            <label (click)="openMediaLibrary('image')">
              <img
                class="cursor-pointer w-full"
                [src]="getSantizeUrl(selectedElement.content)"
              />
            </label>
          </div>
          <button
            (click)="openMediaLibrary('image')"
            type="button"
            class="mt-4 text-zwblue hover:text-white border border-zwblue hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3 w-full"
          >
            <i class="bi bi-collection-play"></i> Mediathek
          </button>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab *ngIf="selectedElement !== null" label="Design">
    <div class="mb-5"></div>

    <div class="m-5">
      <div *ngIf="selectedElement">
        <div class="my-4" *ngIf="selectedElement.type === 'button'">
          <div>
            <p>Button Hintergrundfarbe</p>
            <div class="flex justify-between items-center">
              <input
                type="color"
                id="favcolor"
                class="h-10 w-10"
                name="favcolor"
                (ngModelChange)="changed($event)"
                (change)="setButtonColor($event)"
                [value]="selectedElement.style.backgroundColor"
              />
              <input
                type="text"
                placeholder="#ffffff"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
                (ngModelChange)="changed($event)"
                (change)="setButtonColor($event)"
                [value]="
                  selectedElement.style.backgroundColor
                    ? selectedElement.style.backgroundColor
                    : ''
                "
              />
              <button
                mat-icon-button
                color="warn"
                (click)="deleteColor('backgroundColor')"
              >
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isVisible('fakeloader')">
        <div class="my-4">
          <label
            for="countries"
            class="text-sm font-medium text-gray-900 block mb-2"
            >Typ</label
          >
          <select
            [(ngModel)]="selectedElement.content.type"
            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
          >
            <option value="spinner">Spinner</option>
            <!--  <option value="progressbar">Fortschrittsbalken</option> -->
          </select>
        </div>
      </div>

      <div *ngIf="isVisible('bulletpoints')">
        <div class="my-4">
          <p>Textfarbe</p>
          <div class="flex justify-between items-center">
            <input
              *ngIf="selectedElement"
              type="color"
              id="favcolor"
              name="favcolor"
              class="h-10 w-10"
              (change)="setBulletpointColor($event)"
              [value]="selectedElement.content.textStyle?.color"
            />

            <input
              type="text"
              placeholder="#ffffff"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
              (ngModelChange)="changed($event)"
              (change)="setBulletpointColor($event)"
              [value]="
                selectedElement.content.textStyle?.color
                  ? selectedElement.content.textStyle?.color
                  : ''
              "
            />
            <button
              mat-icon-button
              color="warn"
              (click)="deleteColor('fontColor')"
            >
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="isVisible('bulletpoints')">
        <div class="my-4">
          <p>Iconfarbe</p>
          <div class="flex justify-between items-center">
            <input
              *ngIf="selectedElement"
              type="color"
              id="favcolor"
              name="favcolor"
              class="h-10 w-10"
              (change)="setBulletpointIconColor($event)"
              [value]="selectedElement.content.iconStyle?.color"
            />

            <input
              type="text"
              placeholder="#ffffff"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
              (ngModelChange)="changed($event)"
              (change)="setBulletpointIconColor($event)"
              [value]="
                selectedElement.content.iconStyle?.color
                  ? selectedElement.content.iconStyle?.color
                  : ''
              "
            />
            <button
              mat-icon-button
              color="warn"
              (click)="deleteColor('fontColor')"
            >
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="isVisible('fontColor')">
        <div class="my-4">
          <p>
            {{
              langService.getWording(
                "funnelEditor",
                "tabRightQuizAnswerDesignColor"
              )
            }}
          </p>
          <div class="flex justify-between items-center">
            <input
              *ngIf="selectedElement"
              type="color"
              id="favcolor"
              name="favcolor"
              class="h-10 w-10"
              (change)="setColor($event)"
              [value]="selectedElement.style.fontColor"
            />

            <input
              type="text"
              placeholder="#ffffff"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
              (ngModelChange)="changed($event)"
              (change)="setColor($event)"
              [value]="
                selectedElement.style.fontColor
                  ? selectedElement.style.fontColor
                  : ''
              "
            />
            <button
              mat-icon-button
              color="warn"
              (click)="deleteColor('fontColor')"
            >
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="mt-5" *ngIf="isVisible('bulletpoints')">
        <p>Icongröße</p>
        <div class="flex justify-start items-center">
          <mat-slider
            color="primary"
            thumbLabel
            (ngModelChange)="changed($event)"
            min="5"
            [value]="selectedElement.content.iconStyle?.fontSize"
            (input)="
              changeFontSize($event, selectedElement.type, selectedElement)
            "
            max="100"
          ></mat-slider>

          <input
            type="number"
            (ngModelChange)="changed($event)"
            [(ngModel)]="selectedElement.content.iconStyle.fontSize"
            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
          />
        </div>
      </div>

      <div class="mt-5" *ngIf="isVisible('bulletpoints')">
        <p>Textgröße</p>
        <div class="flex justify-start items-center">
          <mat-slider
            color="primary"
            thumbLabel
            (ngModelChange)="changed($event)"
            min="5"
            [value]="selectedElement.content.textStyle?.fontSize"
            (input)="
              changeFontSize(
                $event,
                selectedElement.type,
                selectedElement,
                'text'
              )
            "
            max="100"
          ></mat-slider>

          <input
            type="number"
            (ngModelChange)="changed($event)"
            [(ngModel)]="selectedElement.content.textStyle.fontSize"
            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
          />
        </div>
      </div>

      <div *ngIf="isVisible('fakeloader')">
        <div class="my-4">
          <p>
            {{
              langService.getWording(
                "funnelEditor",
                "tabRightQuizAnswerDesignColor"
              )
            }}
          </p>
          <div class="flex justify-between items-center">
            <input
              *ngIf="selectedElement"
              type="color"
              class="h-10 w-10"
              id="favcolor"
              name="favcolor"
              (ngModelChange)="changed($event)"
              (change)="setBorderColor($event)"
              [value]="selectedElement.style.borderColor"
            />
            <input
              type="text"
              placeholder="#ffffff"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
              (ngModelChange)="changed($event)"
              (change)="setBorderColor($event)"
              [value]="
                selectedElement.style.borderColor
                  ? selectedElement.style.borderColor
                  : ''
              "
            />
            <button
              mat-icon-button
              color="warn"
              (click)="deleteColor('borderColor')"
            >
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="isVisible('backgroundColor')">
        <div class="my-4">
          <p>Container Hintergrundfarbe</p>
          <div class="flex justify-between items-center">
            <input
              *ngIf="selectedElement"
              type="color"
              id="favcolor"
              name="favcolor"
              class="h-10 w-10"
              (ngModelChange)="changed($event)"
              (change)="setContainerBackgroundColor($event)"
              [value]="selectedElement.style.containerBackgroundColor"
            />
            <input
              type="text"
              placeholder="#ffffff"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-1/2 p-2.5 mx-2"
              (ngModelChange)="changed($event)"
              (change)="setContainerBackgroundColor($event)"
              [value]="
                selectedElement.style.containerBackgroundColor
                  ? selectedElement.style.containerBackgroundColor
                  : ''
              "
            />
            <button
              mat-icon-button
              color="warn"
              *ngIf="projectService.currentProject"
              (click)="deleteColor('containerBackgroundColor')"
            >
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="isVisible('alignment')">
        <div class="my-4">
          <p>
            {{ langService.getWording("funnelEditor", "tabDesignAlignment") }}
          </p>

          <mat-button-toggle-group
            *ngIf="selectedElement"
            [value]="selectedElement.style.alignment"
            #group="matButtonToggleGroup"
          >
            <mat-button-toggle
              value="start"
              (click)="alignElement('left-item')"
              aria-label="Text align left"
            >
              <i class="bi bi-justify-left"></i>
            </mat-button-toggle>
            <mat-button-toggle
              value="center"
              (click)="alignElement('center-item')"
              aria-label="Text align center"
            >
              <i class="bi bi-justify"></i>
            </mat-button-toggle>
            <mat-button-toggle
              value="end"
              (click)="alignElement('right-item')"
              aria-label="Text align right"
            >
              <i class="bi bi-justify-left"></i>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <button
        *ngIf="isVisible('fonts')"
        id="dropdownInformationButton"
        (click)="toggleFontsDropdown()"
        class="border border-blue-700 text-blue-700 hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center items-center w-full flex justify-between"
        type="button"
      >
        <p>
          Schrift:
          {{
            selectedElement.style.fontFamily
              ? selectedElement.style.fontFamily
              : "Roboto"
          }}
        </p>
        <svg
          class="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>

      <!-- Dropdown menu -->
      <div
        id="dropdownInformation"
        class="hidden bg-white text-base z-10 list-none divide-y divide-gray-100 rounded shadow w-full"
      >
        <div class="px-4 py-3">
          <input
            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border focus:border-gray-500 block w-full p-2.5"
            type="text"
            (keyup)="onKeyFonts($event)"
            placeholder="Filter"
          />
        </div>
        <ul
          class="py-1 h-36 overflow-y-scroll"
          aria-labelledby="dropdownInformationButton "
        >
          <li *ngFor="let font of filteredFonts">
            <link [href]="getFontCSS(font)" rel="stylesheet" />
            <p
              (click)="selectFont(font)"
              class="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2 cursor-pointer"
              [attr.style]="getFont(font)"
            >
              {{ font }}
            </p>
          </li>
        </ul>
      </div>

      <div *ngIf="isVisible('textDecoration')">
        <div class="my-4">
          <p>
            {{ langService.getWording("funnelEditor", "tabDesignFormat") }}
          </p>

          <mat-button-toggle-group
            *ngIf="selectedElement"
            multiple
            (change)="setTextDecoration($event.value)"
            name="fontStyle"
            aria-label="Font Style"
          >
            <mat-button-toggle
              [checked]="selectedElement.style.fontWeight === 'bold'"
              value="bold"
            >
              <i class="bi bi-type-bold"></i>
            </mat-button-toggle>
            <mat-button-toggle
              [checked]="selectedElement.style.fontStyle === 'italic'"
              value="italic"
            >
              <i class="bi bi-type-italic"></i>
            </mat-button-toggle>
            <mat-button-toggle
              [checked]="selectedElement.style.textDecoration === 'underline'"
              value="underline"
            >
              <i class="bi bi-type-underline"></i>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div *ngIf="isVisible('width')">
        <div class="my-4">
          <p>
            {{ langService.getWording("funnelEditor", "tabDesignSize") }}
          </p>
          <div class="flex justify-start items-center">
            <mat-slider
              color="primary"
              thumbLabel
              min="5"
              *ngIf="
                selectedElement.type !== 'picture' &&
                selectedElement.type !== 'fakeloader' &&
                selectedElement.type !== 'slider'
              "
              [value]="selectedElement.style.fontSize"
              (input)="
                changeFontSize($event, selectedElement.type, selectedElement)
              "
              max="100"
            ></mat-slider>

            <div
              *ngIf="
                selectedElement.type !== 'picture' &&
                selectedElement.type !== 'fakeloader' &&
                selectedElement.type !== 'slider'
              "
            >
              <input
                type="number"
                (ngModelChange)="changed($event)"
                [(ngModel)]="selectedElement.style.fontSize"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
              />
            </div>
          </div>
          <div class="flex justify-start items-center">
            <mat-slider
              color="primary"
              thumbLabel
              min="5"
              *ngIf="
                selectedElement.type === 'picture' ||
                selectedElement.type === 'fakeloader'
              "
              [value]="selectedElement.style.width"
              (input)="
                changeFontSize($event, selectedElement.type, selectedElement)
              "
              max="500"
            ></mat-slider>

            <div
              *ngIf="
                selectedElement.type === 'picture' ||
                selectedElement.type === 'fakeloader'
              "
            >
              <input
                type="number"
                (ngModelChange)="changed($event)"
                [(ngModel)]="selectedElement.style.width"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
              />
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isVisible('marginTop')">
        <div class="my-4">
          <p>
            {{ langService.getWording("funnelEditor", "tabDesignMarginTop") }}
          </p>
          <div class="flex justify-start items-center">
            <mat-slider
              color="primary"
              thumbLabel
              (ngModelChange)="changed($event)"
              min="-20"
              *ngIf="selectedElement"
              [value]="selectedElement.style.marginTop"
              (input)="setMargin($event.value, 'top')"
              max="100"
            ></mat-slider>

            <input
              type="number"
              (ngModelChange)="changed($event)"
              [(ngModel)]="selectedElement.style.marginTop"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
            />
          </div>
        </div>
      </div>
      <div *ngIf="isVisible('marginBottom')">
        <div class="my-4">
          <p>
            {{
              langService.getWording("funnelEditor", "tabDesignMarginBottom")
            }}
          </p>
          <div class="flex justify-start items-center">
            <mat-slider
              color="primary"
              thumbLabel
              (ngModelChange)="changed($event)"
              min="-20"
              *ngIf="selectedElement"
              [value]="selectedElement.style.marginBottom"
              (input)="setMargin($event.value, 'bottom')"
              max="100"
            ></mat-slider>

            <input
              type="number"
              (ngModelChange)="changed($event)"
              [(ngModel)]="selectedElement.style.marginBottom"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
            />
          </div>
        </div>
      </div>
      <div *ngIf="isVisible('marginLeft')">
        <div class="my-4">
          <p>Abstand links</p>
          <div class="flex justify-start items-center">
            <mat-slider
              color="primary"
              thumbLabel
              (ngModelChange)="changed($event)"
              min="-20"
              *ngIf="selectedElement"
              [value]="selectedElement.style.marginLeft"
              (change)="setMargin($event.value, 'left')"
              max="100"
            ></mat-slider>

            <input
              type="number"
              (ngModelChange)="changed($event)"
              [(ngModel)]="selectedElement.style.marginLeft"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
            />
          </div>
        </div>
      </div>
      <div *ngIf="isVisible('marginRight')">
        <div class="my-4">
          <p>Abstand rechts</p>
          <div class="flex justify-start items-center">
            <mat-slider
              color="primary"
              thumbLabel
              (ngModelChange)="changed($event)"
              min="-20"
              *ngIf="selectedElement"
              [value]="selectedElement.style.marginRight"
              (change)="setMargin($event.value, 'right')"
              max="100"
            ></mat-slider>

            <input
              type="number"
              (ngModelChange)="changed($event)"
              [(ngModel)]="selectedElement.style.marginRight"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg block w-14 p-2"
            />
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
