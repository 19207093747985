import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationType } from 'src/app/enums/notification-type';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { ProjectService } from 'src/app/services/project.service';
import { TierService } from 'src/app/services/tier.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-settings-tracking',
  templateUrl: './settings-tracking.component.html',
  styleUrls: ['./settings-tracking.component.scss'],
})
export class SettingsTrackingComponent implements OnInit {
  currentProject: Project;
  user: User;
  showTrackingPageSelect: boolean = false;
  facebookPixelRights: boolean = false;
  googleRights: boolean = false;
  multipleEntriesEnabled: boolean = false;
  partialEntriesEnabled: boolean = false;
  constructor(
    private projectService: ProjectService,
    private appService: AppService,
    private userService: UserService,
    private tierService: TierService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
      }
    });

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
        this.getFacebookPixelRights();
        this.getGoogleRights();
        this.getMultipleEntriesEnabledRights();
        this.getPartialEntriesRights();
      }
    });
  }

  getFacebookPixelRights() {
    if (this.user.tier) {
      this.facebookPixelRights = this.tierService.getRights(
        'facebookTracking',
        this.user.tier!
      );
      console.log(this.facebookPixelRights);
    }
  }

  getPartialEntriesRights() {
    if (this.user.tier) {
      this.partialEntriesEnabled = this.tierService.getRights(
        'partialEntries',
        this.user.tier!
      );
      console.log(this.partialEntriesEnabled);
    }
  }

  getGoogleRights() {
    if (this.user.tier) {
      this.googleRights = this.tierService.getRights(
        'googleTracking',
        this.user.tier!
      );
      console.log(this.googleRights);
    }
  }

  getMultipleEntriesEnabledRights() {
    if (this.user.tier) {
      this.multipleEntriesEnabled = this.tierService.getRights(
        'multipleEntriesEnabled',
        this.user.tier!
      );
      console.log(this.multipleEntriesEnabled);
    }
  }

  openPaymentPage() {
    this.router.navigateByUrl('/user-settings?payment=true');
  }

  async saveProject() {
    if (
      this.currentProject.googleAnalyticsId ||
      this.currentProject.googleAnalytics4Id ||
      this.currentProject.fbPixelId
    ) {
      this.currentProject.showCookieConsent = true;
    }

    this.projectService.currentProjectSubject.next(this.currentProject);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: true,
        notificationType: NotificationType.SUCCESS,
        notificationMessage: 'Erfolgreich gespeichert',
        notificationDescription:
          'Die Änderungen wurden erfolgreich gespeichert.',
      },
    });
    await this.projectService.saveCurrentProject();

    await this.appService.delay(5000);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: false,
      },
    });
  }

  togglePagesDropdown() {
    this.showTrackingPageSelect = !this.showTrackingPageSelect;
  }

  setAnalyticsStartPage(index: number) {
    this.currentProject.startAnalyticsPageIndex = index;
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.togglePagesDropdown();
  }
}
