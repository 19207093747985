<div
  class="flex"
  [ngClass]="{
    'justify-start items-start text-left': block.style?.alignment === 'start',
    'justify-center items-center text-center':
      block.style?.alignment === 'center',
    'justify-end items-end text-right': block.style?.alignment === 'end'
  }"
>
  <i
    class="cursor-pointer"
    [ngClass]="getSocialMediaIcon(block.content.type)"
    [ngStyle]="getSocialMediaStyle(block.content.type)"
  ></i>
</div>
