import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BlockActionType } from 'src/app/enums/block-action-type';
import { SocialMediaType } from 'src/app/enums/social-media';
import { TextfieldType } from 'src/app/enums/textfield-type';
import {
  Answer,
  Block,
  BlockAction,
  BulletPoint,
  InputField,
} from 'src/app/models/block.model';
import { Page, Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';
import { BlockService } from 'src/app/services/block.service';

@Component({
  selector: 'app-details-content',
  templateUrl: './details-content.component.html',
  styleUrls: ['./details-content.component.scss'],
})
export class DetailsContentComponent implements OnInit {
  state$: Subscription;
  selectedAnswer: Answer | null = null;
  selectedBulletpoint: BulletPoint | null = null;
  selectedInputField: InputField | null = null;
  public doc = document;

  tabIndex: number = 0;

  @Input() selectedElement: Block | null;

  socialMedias: string[] = Object.values(SocialMediaType);
  textfieldTypes: string[] = Object.values(TextfieldType);
  blockActionTypes: string[] = ['Nächste Seite', 'Andere Seite', 'Link'];

  pages: Page[] = [];

  showColor: boolean = false;
  project: Project;

  displaySize: 'sm' | 'md' | 'lg' = 'sm';
  constructor(
    private appService: AppService,
    private blockService: BlockService
  ) {
    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.project = state.editor.currentProject;
        this.selectedAnswer = state.editor.layout.sideDetail.selectedAnswer;
        this.selectedInputField =
          state.editor.layout.sideDetail.selectedInputField;
        this.selectedBulletpoint =
          state.editor.layout.sideDetail.selectedBulletpoint;
      }
    });
  }

  ngOnInit(): void {
    this.pages = this.appService.state.editor.currentProject.pages;
  }

  setTabIndex(index: number) {
    this.tabIndex = index;
  }

  setConnectedPage(page: Page) {
    this.selectedElement!.action!.connectedPage = page.id;
  }

  updateLink(link: string) {
    this.selectedElement!.action!.link = link;
  }

  selectDisplaySize(size: 'sm' | 'md' | 'lg') {
    this.displaySize = size;
  }

  getActionTypeString() {
    switch (this.selectedElement!.action?.type) {
      case BlockActionType.NEXTPAGE: {
        return 'Nächste Seite';
      }
      case BlockActionType.CONNECTEDPAGE: {
        return 'Andere Seite';
      }
      case BlockActionType.LINK: {
        return 'Link';
      }
    }
    return '';
  }

  setFontColor(color: string) {
    this.showColor = false;

    if (!this.selectedElement?.style) return;
    this.selectedElement.style.fontColor = color;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setContainerBackgroundColor(color: string) {
    this.showColor = false;
    if (!this.selectedElement?.style) return;
    this.selectedElement.style.containerBackgroundColor = color;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setBackgroundColor(color: string) {
    this.showColor = false;
    if (!this.selectedElement?.style) return;
    this.selectedElement.style.backgroundColor = color;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setBulletpointColor(color: string, type: string) {
    this.showColor = false;
    if (!this.selectedElement?.content) return;

    if (type === 'icon') {
      this.selectedElement.content.iconStyle.color = color;
    } else {
      this.selectedElement.content.textStyle.color = color;
    }

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setQuizIconColor(color: string) {
    this.showColor = false;
    if (!this.selectedElement?.style) return;
    this.selectedElement.style.iconColor =
      this.selectedElement.style.iconColor || '#000000';
    this.selectedElement.style.iconColor = color;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setCheckboxColor(color: string) {
    this.showColor = false;
    if (!this.selectedElement?.style) return;
    this.selectedElement.content.privacyCheck.style.checkboxColor = color;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setPrivacyFontColor(color: string) {
    this.showColor = false;
    if (!this.selectedElement?.style) return;
    this.selectedElement.content.privacyCheck.style.fontColor = color;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setHoverBackgroundColor(color: string) {
    this.showColor = false;
    if (!this.selectedElement?.style) return;
    this.selectedElement.style.hoverColor = color;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setSubmitButtonFontColor(color: string) {
    this.showColor = false;
    if (!this.selectedElement?.style) return;
    this.selectedElement.content.submitButton.style.fontColor = color;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setSubmitButtonHoverBackgroundColor(color: string) {
    this.showColor = false;
    if (!this.selectedElement?.style) return;
    this.selectedElement.content.submitButton.style.hoverColor = color;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setSubmitButtonBackgroundColor(color: string) {
    this.showColor = false;
    if (!this.selectedElement?.style) return;
    this.selectedElement.content.submitButton.style.backgroundColor = color;

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setFontFamily(font: string) {
    if (!this.selectedElement?.style) return;
    this.selectedElement.style.fontFamily = font;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  setAlignment(alignment: string) {
    if (!this.selectedElement?.style) return;
    this.selectedElement.style.alignment = alignment;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    console.log('setAlignment');
    this.appService.setShouldSave();
  }

  setContentAlignment(alignment: string) {
    if (!this.selectedElement?.style) return;
    this.selectedElement.style.contentAlignment = alignment;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    console.log('setContentAlignment');
    this.appService.setShouldSave();
  }

  openColorPicker() {
    this.showColor = true;

    const colorPicker = document.getElementById('colorPickerSidebar');
    colorPicker?.focus();
    colorPicker?.click();
  }

  updateElementContent(event: any) {
    if (this.selectedElement) {
      this.selectedElement.content = event.target.innerHTML;
    }
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          designer: {
            ...this.appService.state.editor.layout.designer,
            selectedElement: this.selectedElement,
          },
        },
      },
    });
    this.appService.setShouldSave();
  }

  setCaretPosition(el: any, pos: number) {
    // Loop through all child nodes
    for (var node of el.childNodes) {
      if (node.nodeType == 3) {
        // we have a text node
        if (node.length >= pos) {
          // finally add our range
          var range = document.createRange(),
            sel: any = window.getSelection();
          range.setStart(node, pos);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
          return -1; // we are done
        } else {
          pos -= node.length;
        }
      } else {
        pos = this.setCaretPosition(node, pos);
        if (pos == -1) {
          return -1; // no need to finish the for loop
        }
      }
    }
    return pos; // needed because of recursion stuff
  }

  getCaretIndex(element: any) {
    let position = 0;
    const isSupported = typeof window.getSelection !== 'undefined';
    if (isSupported) {
      const selection: any = window.getSelection();
      if (selection.rangeCount !== 0) {
        const range: any = window.getSelection()?.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        position = preCaretRange.toString().length;
      }
    }
    return position;
  }

  unsetImage(type?: string) {
    if (type === 'upload') {
      this.selectedElement!.content.picture = '';
    } else {
      this.selectedElement!.content = '';
    }
  }

  isVisible(element: string) {
    if (
      typeof this.selectedElement === 'undefined' ||
      this.selectedElement === null
    ) {
      return false;
    }
    switch (element) {
      case 'fonts': {
        const allowedBlocks = [
          'header',
          'button',
          'textfield',
          'selection',
          'quiz',

          'fontSize',
          'zw_westnetz',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'text': {
        const allowedBlocks = ['header', 'button'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'button': {
        const allowedBlocks = ['button'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'picture': {
        const allowedBlocks = ['picture'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'textfield': {
        const allowedBlocks = ['textfield'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'socialmedia': {
        const allowedBlocks = ['socialmedia'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'video': {
        const allowedBlocks = ['video'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'bulletpoints': {
        const allowedBlocks = ['bulletpoints'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'upload': {
        const allowedBlocks = ['upload'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'selection': {
        const allowedBlocks = ['selection'];
        if (this.selectedElement.showQuiz === true) {
          return allowedBlocks.includes(this.selectedElement.type);
        }
        return false;
      }
      case 'selectionquiz': {
        const allowedBlocks = ['selectionquiz'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'fakeloader': {
        const allowedBlocks = ['fakeloader'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'quiz': {
        const allowedBlocks = ['quiz'];
        if (this.selectedElement.showQuiz === true) {
          return allowedBlocks.includes(this.selectedElement.type);
        }
        return false;
      }
      case 'form': {
        const allowedBlocks = ['form'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'zw_westnetz': {
        const allowedBlocks = ['zw_westnetz'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'slider': {
        const allowedBlocks = ['slider'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'bulletpoints': {
        const allowedBlocks = ['bulletpoints'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'alignment': {
        const allowedBlocks = [
          'header',
          'button',
          'picture',
          'textfield',
          'fakeloader',
          'video',
          'socialmedia',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'contentAlignment': {
        const allowedBlocks = [
          'button',
          'textfield',
          'selection',
          'bulletpoints',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }

      case 'textDecoration': {
        const allowedBlocks = ['header', 'button', 'textfield', 'selection'];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'width': {
        const allowedBlocks = [
          'header',
          'button',
          'picture',
          'form',
          'zw_westnetz',
          'textfield',
          'fakeloader',
          'socialmedia',
          'slider',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'fontColor': {
        const allowedBlocks = [
          'header',
          'button',
          'form',
          'zw_westnetz',
          'textfield',
          'selection',
          'slider',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'backgroundColor': {
        const allowedBlocks = [
          'header',
          'quiz',
          'button',
          'form',
          'zw_westnetz',
          'textfield',
          'selection',
          'socialmedia',
          'slider',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
      case 'margin': {
        const allowedBlocks = [
          'header',
          'quiz',
          'picture',
          'button',
          'form',
          'zw_westnetz',
          'textfield',
          'fakeloader',
          'selection',
          'video',
          'socialmedia',
          'slider',
          'upload',
          'bulletpoints',
        ];
        return allowedBlocks.includes(this.selectedElement.type);
      }
    }
    return false;
  }

  changed(event: any, type?: string) {
    switch (type) {
      case 'videoUrl': {
        this.selectedElement!.content.url = event;
        break;
      }

      case 'fakeloader': {
        this.selectedElement!.content.duration = event;
        break;
      }

      case 'slidersteps': {
        this.selectedElement!.content.steps = event;
        break;
      }
      case 'slidermin': {
        this.selectedElement!.content.min = event;
        break;
      }
      case 'slidermax': {
        this.selectedElement!.content.max = event;
        break;
      }
      case 'socialmediaUrl': {
        this.selectedElement!.content.url = event;
        break;
      }
      case 'textfield': {
        this.selectedElement!.content.text = event;
        break;
      }
      case 'textfieldRequired': {
        this.selectedElement!.content.required = event;
        break;
      }
      case 'showQuiz': {
        this.selectedElement!.showQuiz = event;
        break;
      }
      case 'fontSize': {
        this.selectedElement!.style!.fontSize = event;
        if (this.selectedElement!.type === 'quiz') {
          this.selectedElement!.content.answers.forEach((answer: Answer) => {
            answer.style!.fontSize = event;
          });
        }
        break;
      }
      case 'iconSize': {
        this.selectedElement!.style!.iconSize = event;
        break;
      }

      case 'bulletpointsIconSize': {
        this.selectedElement!.content.iconStyle.fontSize = event;
        break;
      }
      case 'bulletpointsTextSize': {
        this.selectedElement!.content.textStyle.fontSize = event;
        break;
      }
      case 'submitButtonFontSize': {
        this.selectedElement!.content.submitButton.style.fontSize = event;
        break;
      }
      case 'submitButtonBorderRadius': {
        this.selectedElement!.content.submitButton.style.borderRadius = event;
        break;
      }
      case 'width': {
        this.selectedElement!.style!.width = event;
        break;
      }
      case 'mdWidth': {
        this.selectedElement!.style!.mdWidth = event;
        break;
      }
      case 'lgWidth': {
        this.selectedElement!.style!.lgWidth = event;
        break;
      }

      case 'fullWidth': {
        this.selectedElement!.style!.fullWidth = event;
        if (event) {
          this.selectedElement!.style!.width = '100vw';
        } else {
          this.selectedElement!.style!.width = '200';
        }

        break;
      }
      case 'mdFullWidth': {
        this.selectedElement!.style!.mdFullWidth = event;
        if (event) {
          this.selectedElement!.style!.width = '100vw';
        } else {
          this.selectedElement!.style!.width = '200';
        }

        break;
      }
      case 'lgFullWidth': {
        this.selectedElement!.style!.lgFullWidth = event;
        if (event) {
          this.selectedElement!.style!.width = '100vw';
        } else {
          this.selectedElement!.style!.width = '200';
        }

        break;
      }

      default:
        break;
    }

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              selectedElement: this.selectedElement,
            },
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }

  getBlockName(type: string) {
    return this.blockService.getBlockName(type);
  }

  setDuration(event: any) {
    console.log(event.target.value);
    this.selectedElement!.content.duration = event.target.value;
    this.appService.setShouldSave();
  }

  setImage(url: string) {
    this.selectedElement!.content = url;
    this.appService.setShouldSave();
  }

  setPlaceholderColor(color: string) {
    this.selectedElement!.style!.placeholderColor = color;
    this.appService.setShouldSave();
  }

  setUploadImage(url: string) {
    this.selectedElement!.content.picture = url;
    this.appService.setShouldSave();
  }

  setSocialMediaType(type: string) {
    this.selectedElement!.content.type = type;
    this.appService.setShouldSave();
  }

  setActionType(action: string) {
    console.log(action);
    switch (action) {
      case 'Nächste Seite':
        {
          const blockAction: BlockAction = {
            type: BlockActionType.NEXTPAGE,
          };
          this.selectedElement!.action = blockAction;
        }
        break;
      case 'Andere Seite':
        {
          const blockAction: BlockAction = {
            type: BlockActionType.CONNECTEDPAGE,
          };
          this.selectedElement!.action = blockAction;
        }
        break;
      case 'Link': {
        const blockAction: BlockAction = {
          type: BlockActionType.LINK,
        };
        this.selectedElement!.action = blockAction;

        break;
      }
    }

    this.appService.setShouldSave();
  }

  setTextfieldType(type: string) {
    this.selectedElement!.content.type = type;
    this.appService.setShouldSave();
  }
}
