import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit {
  @Input() isEnabled: boolean = false;
  @Input() title: string = '';
  @Output() onToggle = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  toggle() {
    this.isEnabled = !this.isEnabled;
    this.onToggle.emit(this.isEnabled);
  }
}
