export enum SocialMediaType {
  YOUTUBE = 'youtube',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedin',
  PINTEREST = 'pinterest',
  GITHUB = 'github',
  DRIBBBLE = 'dribbble',
  VIMEO = 'vimeo',
}
