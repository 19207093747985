<!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="!selectedWestnetzItem" class="pb-20">
  <div class="flow-root my-6">
    <ul role="list" class="-my-5 divide-y divide-gray-200">
      <li
        class="py-4 cursor-pointer group"
        *ngFor="let item of items; let i = index"
      >
        <div class="flex flex-col">
          <div class="flex-1 min-w-0 mb-2 cursor-pointer">
            <div class="flex justify-between items-center">
              <div class="flex space-x-8">
                <p
                  (click)="openItem(item, i)"
                  class="text-sm font-medium text-gray-900 truncate"
                >
                  {{ item.text }}
                </p>
                <div class="flex space-x-2">
                  <i
                    (click)="moveUp(i)"
                    class="bi bi-chevron-up opacity-0 group-hover:opacity-100 cursor-pointer"
                  ></i>
                  <i
                    (click)="moveDown(i)"
                    class="bi bi-chevron-down opacity-0 group-hover:opacity-100 cursor-pointer"
                  ></i>
                </div>
              </div>

              <i
                (click)="openItem(item, i)"
                class="bi bi-chevron-right cursor-pointer mr-2"
              ></i>
            </div>
          </div>
        </div>
      </li>

      <li class="py-4 cursor-pointer" (click)="openButton()">
        <div class="flex flex-col">
          <div class="flex-1 min-w-0 mb-2 cursor-pointer">
            <div class="flex justify-between items-center">
              <p
                class="text-sm font-medium text-gray-900 truncate"
                i18n="@@funnelSidebarDetailWestnetzItems_button_label"
              >
                Button
              </p>

              <i class="bi bi-chevron-right cursor-pointer mr-2"></i>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <p class="font-bold mt-4 -mb-4">Erfolgsfall</p>
  <div class="mb-6">
    <app-dropdown
      [headline]="'Klickaktion'"
      [items]="actions"
      [selectedItem]="getActionTypeStringOnSuccess()"
      (onChange)="setOnSuccessAction($event)"
    ></app-dropdown>
  </div>

  <div
    class="my-6"
    *ngIf="westnetz.content.onSuccess?.type === 'connectedPage'"
  >
    <app-dropdown
      [type]="'quizItem'"
      [headline]="'Seite auswählen'"
      [items]="pages"
      [selectedItem]="westnetz.content.onSuccess.connectedPage"
      (onChange)="setConnectedPageOnSuccess($event)"
    ></app-dropdown>
  </div>

  <div class="my-6" *ngIf="westnetz.content.onSuccess?.type === 'link'">
    <app-text-input
      class="w-full mr-4"
      [value]="westnetz.content.onSuccess.link"
      (valueChange)="updateLinkOnSuccess($event)"
      [placeholder]="'Link eingeben'"
      [title]="'Ausgehender Link'"
    ></app-text-input>
  </div>

  <p class="font-bold mt-4 -mb-4">Fehlschlag</p>
  <div class="mb-6 ">
    <app-dropdown
      [headline]="'Klickaktion'"
      [items]="actions"
      [selectedItem]="getActionTypeStringOnFail()"
      (onChange)="setOnFailAction($event)"
    ></app-dropdown>
  </div>

  <div class="my-6" *ngIf="westnetz.content.onFail?.type === 'connectedPage'">
    <app-dropdown
      [type]="'quizItem'"
      [headline]="'Seite auswählen'"
      [items]="pages"
      [selectedItem]="westnetz.content.onFail.connectedPage"
      (onChange)="setConnectedPageOnFail($event)"
    ></app-dropdown>
  </div>

  <div class="my-6" *ngIf="westnetz.content.onFail?.type === 'link'">
    <app-text-input
      class="w-full mr-4"
      [value]="westnetz.content.onFail.link"
      (valueChange)="updateLinkOnFail($event)"
      [placeholder]="'Link eingeben'"
      [title]="'Ausgehender Link'"
    ></app-text-input>
  </div>
</div>
<app-details-zw-westnetz-item
  *ngIf="selectedWestnetzItem"
  [inputField]="selectedWestnetzItem"
  [inputFieldIndex]="selectedWestnetzItemIndex"
  [inputFieldType]="selectedWestnetzItemType"
  (onShowWestnetz)="showWestnetz()"
></app-details-zw-westnetz-item>
