import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from 'src/app/models/project.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements OnInit {
  @Input() public projectId: string;
  @Input() public project: Project;
  @Output() public onAcceptCookies = new EventEmitter();

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {}

  acceptCookies() {
    this.localStorageService.set('cookieConsent:' + this.projectId, 'accepted');
    this.onAcceptCookies.emit(true);
  }

  listCookies() {
    var theCookies = document.cookie.split(';');
    var aString = '';
    for (var i = 1; i <= theCookies.length; i++) {
      aString += i + ' ' + theCookies[i - 1] + '\n';
    }
    return aString;
  }

  openCookieListPage() {
    window.open('https://klicklead.de/datenschutz/', '_blank');
  }
}
