<section aria-labelledby="payment-details-heading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 sm:p-6">
      <div>
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
          i18n="@@funnelSettingsDomain_headline_label"
        >
          Domains
        </h2>
      </div>

      <div class="mt-6 grid grid-cols-0 gap-6">
        <div class="col-span-12">
          <div class="bg-white shadow-lg sm:rounded-lg w-1/2">
            <div class="flex items-center justify-between p-4">
              <div class="flex flex-col py-5 sm:p-6">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  i18n="@@funnelSettingsDomain_addDomain_label"
                >
                  Domain hinzufügen
                </h3>
                <div class="mt-2 w-3/4">
                  <div class="max-w-xl text-sm text-gray-500">
                    <p i18n="@@funnelSettingsDomain_description_label">
                      Verknüpfe Deine eigene Domain oder erstelle eine eigene
                      KlickLead-ID für Deinen Funnel
                    </p>
                  </div>
                </div>
              </div>
              <div class="   ">
                <button
                  (click)="openAddDomainModal()"
                  type="button"
                  class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900"
                  i18n="@@funnelSettingsDomain_add_button"
                >
                  Hinzufügen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-10" *ngIf="customDomains.length > 0">
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
          i18n="@@funnelSettingsDomain_ownDomains_label"
        >
          Eigene Domains ({{ customDomainsOfUser.length }}/{{
            maxCustomDomains === 1000 ? "unbegrenzt" : maxCustomDomains
          }})
        </h2>
      </div>

      <div class="mt-6 grid grid-cols-0 gap-6">
        <div class="col-span-12 sm:col-span-2">
          <div
            *ngFor="let domain of customDomains; let i = index"
            class="border rounded-xl flex justify-between w-full md:w-1/2 py-6 px-4 mb-2"
          >
            <div class="flex flex-col">
              <p class="font-bold text-lg">{{ domain.domain }}</p>
              <p
                class="mt-4 text-sm text-gray-500"
                i18n="@@funnelSettingsDomain_createdDate_label"
              >
                erstellt am {{ domain.createdAt.toDate() | date }}
              </p>
            </div>
            <button
              class="text-2xl"
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <i class="bi bi-three-dots-vertical"></i>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="deleteCustomDomain(domain, i)" mat-menu-item>
                <span i18n="@@funnelSettingsDomain_deleteDomain_button"
                  >Entfernen</span
                >
              </button>
            </mat-menu>
          </div>
        </div>
      </div>

      <div class="mt-10" *ngIf="domainIds.length > 0">
        <h2
          id="payment-details-heading"
          class="text-lg leading-6 font-medium text-gray-900"
          i18n="@@funnelSettingsDomain_funnelId_label"
        >
          KlickLead-ID
        </h2>
      </div>

      <div class="mt-6 grid grid-cols-0 gap-6">
        <div class="col-span-12 sm:col-span-2">
          <div
            *ngFor="let domain of domainIds; let i = index"
            class="border rounded-xl flex justify-between w-full md:w-1/2 py-6 px-4 mb-2"
          >
            <div class="flex flex-col">
              <p
                class="font-bold text-lg"
                i18n="@@funnelSettingsDomain_klickleadUrlPreview_label"
              >
                https://funnel.klicklead.de/{{ domain.id }}
              </p>
              <p
                class="mt-4 text-sm text-gray-500"
                i18n="@@funnelSettingsDomain_createdDateDomain_label"
              >
                erstellt am {{ domain.createdAt.toDate() | date }}
              </p>
            </div>
            <button
              class="text-2xl"
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <i class="bi bi-three-dots-vertical"></i>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="deleteDomainId(domain, i)" mat-menu-item>
                <span i18n="@@funnelSettingsDomain_deleteDomain_button"
                  >Entfernen</span
                >
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
