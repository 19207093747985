<div class="w-full flex gap-x-2 my-12">
  <div class="flex flex-col w-full">
    <table
      class="min-w-full divide-y divide-gray-300"
      *ngIf="invoices.length > 0"
    >
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
          >
            Datum
          </th>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Betrag
          </th>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Status
          </th>
          <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr *ngFor="let invoice of invoices">
          <td
            class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
          >
            <a
              [href]="invoice.hosted_invoice_url"
              target="_blank"
              class="text-black text-sm"
            >
              {{ invoice.created * 1000 | date: "dd-MM-yyyy HH:mm" }}</a
            >
          </td>
          <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <a
              [href]="invoice.hosted_invoice_url"
              target="_blank"
              class="text-sm text-gray-500"
            >
              {{ invoice.total / 100 }} {{ invoice.currency | uppercase }}
            </a>
          </td>
          <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <a
              [href]="invoice.hosted_invoice_url"
              target="_blank"
              *ngIf="invoice.status === 'paid'"
              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
            >
              {{ "Bezahlt" | uppercase }}
            </a>
            <a
              [href]="invoice.hosted_invoice_url"
              target="_blank"
              *ngIf="invoice.status !== 'paid'"
              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
            >
              Nicht bezahlt
            </a>
          </td>
          <td
            class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
          >
            <a
              [href]="invoice.hosted_invoice_url"
              target="_blank"
              class="text-black text-sm"
            >
              <i class="bi bi-box-arrow-up-right cursor-pointer"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
