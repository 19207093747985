import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingComponent implements OnInit {
  step: number = 0;
  name: string = '';
  companyName: string = '';
  user: User;
  constructor(
    public dialogRef: MatDialogRef<OnboardingComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    await this.userService.loadUser();
    this.userService.userDataSubject.subscribe(async (user: User) => {
      if (user) {
        this.user = user;
      }
    });
  }

  next() {
    this.step++;
  }

  prev() {
    this.step--;
  }

  setUserCompanyName() {
    this.user.companyName = this.companyName;
    this.userService.userDataSubject.next({
      ...this.user,
      displayName: this.name,
      companyName: this.companyName,
      showOnboarding: false,
    });
  }

  async close() {
    if (this.companyName !== '' && this.name !== '') {
      this.setUserCompanyName();
      await this.userService.setUser(this.user);
      this.dialogRef.close();
    }
  }
}
