<div>
  <label for="email" class="block text-sm font-medium text-gray-700">{{
    title
  }}</label>
  <div class="mt-1">
    <input
      [type]="type"
      [(ngModel)]="value"
      (input)="changed($event)"
      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      [placeholder]="placeholder"
    />
  </div>
</div>
