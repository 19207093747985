<div class="h-full">
  <div class="my-6">
    <label
      for="email"
      class="block text-sm font-medium text-gray-700"
      i18n="@@funnelSidebarDetailIconLibrary_search_label"
      >Suchen</label
    >

    <div class="mt-1 relative flex items-center">
      <input
        type="text"
        (keydown.enter)="filterIcons()"
        [(ngModel)]="searchString"
        placeholder="z.B.: Google"
        class="shadow-sm block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
      />
      <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
        <kbd
          (click)="filterIcons()"
          class="cursor-pointer inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400"
        >
          Enter <i class="ml-1 bi bi-arrow-return-left"></i>
        </kbd>
      </div>
    </div>
  </div>
  <div *ngIf="loader" class="flex items-center flex-wrap gap-4 overflow-auto">
    <div
      class="flex flex-col items-center justify-between p-2 w-1/4 h-auto group cursor-pointer border rounded-lg"
      *ngFor="let image of [1, 2, 3, 4, 5, 6, 7, 8]"
    >
      <img
        class="w-20 h-32 object-contain rounded-lg bg-gray-300 animate-pulse"
      />
    </div>
  </div>
  <!--   <div
    *ngIf="!loader"
    class="flex items-center flex-wrap gap-4 overflow-auto h-5/6"
    
  >
    <div
      class="flex flex-col items-center justify-between p-2 w-1/4 h-auto group cursor-pointer"
      *ngFor="let icon of loadedIcons"
      (click)="selectIcon(icon)"
    >
      <i [ngClass]="icon" class="text-xl"></i>
      <p>{{ icon }}</p>
    </div>
  </div> -->

  <div
    class="h-5/6 rounded-lg flex flex-wrap overflow-y-auto p-2"
    (scroll)="infiniteLoad()"
  >
    <div class="flex" *ngIf="loader">
      <div
        *ngFor="let skeleton of [1, 2, 3, 4, 5, 6]"
        class="flex flex-col justify-center items-center w-40 h-32 mx-4 my-2 rounded-xl animate-pulse shadow-lg cursor-pointer"
      ></div>
    </div>
    <div
      class="flex flex-wrap overflow-y-auto"
      style="height: calc(80vh - 140px)"
      id="list"
      (scroll)="infiniteLoad()"
    >
      <div
        *ngFor="let icon of loadedIcons; let i = index"
        class="w-32 h-32 object-cover flex flex-col justify-center items-center mx-2 my-2 cursor-pointer"
        [ngClass]="{
          'border border-3 border-blue-400 rounded-lg ': icon === selectedIcon
        }"
        (click)="selectIcon(icon)"
      >
        <i [ngClass]="icon" class="text-lg"></i>
        <p>{{ icon }}</p>
      </div>
    </div>
  </div>
</div>
