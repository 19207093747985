export const wording = {
  eng: {
    login: {
      header: 'Login',
      email: 'E-Mail',
      password: 'Password',
      loginBtn: 'Login',
      resetPassword: 'Reset Password',
      createAccount: 'Create Account',
      or: 'OR',
      wrongPassword: 'Wrong Password',
      resetPasswordToaster: 'Successfully reset. Check your email inbox.',
      logoutSuccess: 'You have been successfully logged out'
    },
    register: {
      header: 'Create Account',
      email: 'E-Mail',
      password1: 'Password (min. 6 characters)',
      password2: ' Repeat Password',
      registerBtn: 'Register for free',
      login: 'Login instead'
    },
    funnels: {
      header: 'My Funnels',
      add: 'Create a Funnel',
      statusLive: 'Live',
      statusEdit: 'Edit Mode',
      actions: 'Actions',
      actionEdit: 'Edit',
      actionRename: 'Rename',
      actionOpen: 'Open Funnel',
      actionStatistics: 'Statistics',
      actionSettings: 'Settings',
      actionDuplicate: 'Duplicate',
      actionDelete: 'Delete'
    },
    header: {
      funnels: 'My Funnels',
      profile: 'Settings',
      logout: 'Logout'
    },
    profile: {
      header: 'Settings',
      name: 'Name',
      email: 'E-Mail',
      resetPassword: 'Reset Password'
    },
    funnelSettings: {
      header: 'Funnel Settings',
      general: 'General',
      funnelName: 'Funnel Name',
      funnelURL: 'URL',
      funnelStatus: 'Publish Funnel',
      design: 'Design',
      pageTitle: 'Page Title',
      pageDescription: 'Page Description',
      showBadge: 'Show Badge',
      showProgressbar: 'Show Progressbar',
      progressbarColor: 'Progressbar Color',
      tracking: 'Tracking',
      fbPixelId: 'Facebook Pixel ID',
      privacy: 'Privacy',
      imprint: 'Imprint',
      dataProtection: 'Data protection',
    },
    funnelStatistics: {
      header: 'Statistics',
      tabAnalytics: 'Analytics',
      tabLeads: 'Leads',
      tabEntries: 'All Entries',
      totalPageviews: 'Total Pageviews',
      convRate: 'Conversion Rate',
      pageviews: 'Pageviews',
      pages: 'Pages',
      filter: 'Filter',
      csvDownload: 'Download Leads (.csv)',
      tableDate: 'Date',
      actions: 'Actions'
    },
    funnelEditor: {
      leftTabPages: 'Pages',
      leftTabDetails: 'Details',
      leftTabDesign: 'Design',
      tabPagesRename: 'Rename',
      tabPagesMoveDown: 'Move down',
      tabPagesMoveUp: 'Move up',
      tabPagesDuplicate: 'Duplicate',
      tabPagesDelete: 'Delete',
      tabDetailsPageName: 'Page Name',
      tabDetailsFbPixelEvent: 'Facebook Pixel Event',
      tabDesignBackgroundColor: 'Background Color',
      tabDesignApplyToAllPages: 'Apply to all pages',
      imprint: 'Imprint',
      privacyPolicy: 'Privacy Policy',
      tabRightBlocks: 'Blocks',
      tabRightBlockButton: 'Button',
      tabRightBlockForm: 'Form',
      tabRightBlockHeader: 'Text',
      tabRightBlockPicture: 'Picture',
      tabRightBlockQuiz: 'Question',
      tabRightPicture: 'Picture',
      tabRightHeader: 'Text',
      tabRightQuizAnwswers: 'Answers',
      tabRightQuizAnswer: 'Answer',
      tabRightQuizAnswerText: 'Question Text',
      tabRightFormRequired: 'Required',
      tabRightFormType: 'Type',
      tabRightFormTypeText: 'Text',
      tabRightFormTypeEmail: 'E-Mail',
      tabRightFormTypeNumber: 'Number',
      tabRightFormTypeTel: 'Telephone',
      tabRightQuizAnswerFontColor: 'Font Color',
      tabRightQuizAnswerFontSize: 'Font Size',
      tabRightAction: 'Click Action',
      tabRightctionNextPage: 'Next Page',
      tabRightActionOtherPage: 'Other Page',
      tabRightActionOtherPageText: 'Choose Page',
      tabRightActionLink: 'Link',
      tabRightQuizAnswerDesignPictureIcon: 'Picture / Icon',
      tabRightQuizAnswerDesignPicture: 'Picture',
      tabRightQuizAnswerDesignIcons: 'Icons',
      tabRightQuizAnswerDesignColor: 'Color',
      tabRightQuizAnswerDesignBackgroundColor: 'Background Color',
      tabRightQuizAnswerDesignFontSize: 'Font Size',
      tabRightQuizAnswerDesignApplyToAllAnswers: 'Apply to all answers',
      tabRightButtonText: 'Text',
      tabDesignAlignment: 'Alignment',
      tabDesignSize: 'Size',
      tabDesignMarginTop: 'Margin Top',
      tabDesignMarginBottom: 'Margin Bottom',
      tabDesignFormat: 'Text Style',
      page: 'Page'
    },
    deleteDialog: {
      header: 'You really want to delete this?',
      abort: 'Abort',
      delete: 'Delete'
    },
    renamePage: {
      header: 'Rename page',
      pageName: 'Pagename',
      abort: 'Abort',
      save: 'Save'
    },
    renameProject: {
      header: 'Rename Project Name',
      pageName: 'Projectname',
      abort: 'Abort',
      save: 'Save'
    },
    resetEmail: {
      header: 'Reset E-Mail',
      email: 'Your E-Mail',
      abort: 'Abort',
      reset: 'Reset'
    },
    editName: {
      header: 'Edit Name',
      name: 'Your Name',
      abort: 'Abort',
      save: 'Save'
    },

  },
  ger: {
    login: {
      header: 'Login',
      email: 'E-Mail',
      password: 'Passwort',
      loginBtn: 'Login',
      resetPassword: 'Passwort zurücksetzen',
      createAccount: 'Account erstellen',
      or: 'ODER',
      wrongPassword: 'Falsches Password',
      resetPasswordToaster: 'Die E-Mail Adresse wurde zurückgesetzt. Siehe in deinen E-Mail Postfach',
      logoutSuccess: 'Du wurdest erfolgreich ausgelogged'

    },
    register: {
      header: 'Account erstellen',
      email: 'E-Mail',
      password1: 'Passwort (mind. 6 Zeichen)',
      password2: 'Passwort wiederholen',
      registerBtn: 'Kostenlos registrieren',
      login: 'Zum Login'
    },
    funnels: {
      header: 'Meine Funnels',
      add: 'Erstellen',
      statusLive: 'Live',
      statusEdit: 'In Bearbeitung',
      actions: 'Aktionen',
      actionEdit: 'Bearbeiten',
      actionRename: 'Umbennenen',
      actionOpen: 'Funnel öffnen',
      actionStatistics: 'Statistik',
      actionSettings: 'Einstellungen',
      actionDuplicate: 'Duplizieren',
      actionDelete: 'Löschen'
    },
    header: {
      funnels: 'Meine Funnels',
      profile: 'Einstellungen',
      logout: 'Logout'
    },
    profile: {
      header: 'Einstellungen',
      name: 'Name',
      email: 'E-Mail',
      resetPassword: 'Passwort zurücksetzen'
    },
    funnelSettings: {
      header: 'Funnel Einstellungen',
      general: 'Allgemein',
      funnelName: 'Funnelname',
      funnelURL: 'URL',
      funnelStatus: 'Funnel veröffentlichen',
      design: 'Design',
      pageTitle: 'Seiten Titel',
      pageDescription: 'Seiten Beschreibung',
      showBadge: 'Badge anzeigen',
      showProgressbar: 'Progressbar anzeigen',
      progressbarColor: 'Progressbarfarbe',
      tracking: 'Tracking',
      fbPixelId: 'Facebook Pixel ID',
      privacy: 'Datenschutz',
      imprint: 'Impressum',
      dataProtection: 'Datenschutzerklärung',
    },
    funnelStatistics: {
      header: 'Statistik',
      tabAnalytics: 'Analyse',
      tabLeads: 'Leads',
      tabEntries: 'Alle Einträge',
      totalPageviews: 'Aufrufe',
      convRate: 'Konvertierungsrate',
      pageviews: 'Aufrufe',
      pages: 'Seiten',
      filter: 'Filter',
      csvDownload: 'Download Leads (.csv)',
      tableDate: 'Datum',
      actions: 'Actions'
    },
    funnelEditor: {
      leftTabPages: 'Seiten',
      leftTabDetails: 'Details',
      leftTabDesign: 'Design',
      tabPagesRename: 'Umbennenen',
      tabPagesMoveDown: 'Nach unten',
      tabPagesMoveUp: 'Nach oben',
      tabPagesDuplicate: 'Duplizieren',
      tabPagesDelete: 'Löschen',
      tabDetailsPageName: 'Seitenname',
      tabDetailsFbPixelEvent: 'Facebook Pixel Event',
      tabDesignBackgroundColor: 'Hintergrundfarbe',
      tabDesignApplyToAllPages: 'Auf alle Seiten anwenden',
      imprint: 'Impressum',
      privacyPolicy: 'Datenschutzerklärung',
      tabRightBlocks: 'Blocks',
      tabRightBlockButton: 'Button',
      tabRightBlockForm: 'Formular',
      tabRightBlockHeader: 'Text',
      tabRightBlockPicture: 'Bild',
      tabRightBlockQuiz: 'Fragen',
      tabRightPicture: 'Bild',
      tabRightHeader: 'Text',
      tabRightQuizAnwswers: 'Antworten',
      tabRightQuizAnswer: 'Antwort',
      tabRightQuizAnswerText: 'Antwort Text',
      tabRightFormRequired: 'Pflichtfeld',
      tabRightFormType: 'Typ',
      tabRightFormTypeText: 'Text',
      tabRightFormTypeEmail: 'E-Mail',
      tabRightFormTypeNumber: 'Zahl',
      tabRightFormTypeTel: 'Telefon',

      tabRightQuizAnswerFontColor: 'Schriftfarbe',
      tabRightQuizAnswerFontSize: 'Schriftgröße',
      tabRightAction: 'Klick Aktion',
      tabRightctionNextPage: 'Nächste Seite',
      tabRightActionOtherPage: 'Andere Seite',
      tabRightActionOtherPageText: 'Seite auswählen',
      tabRightActionLink: 'Link',
      tabRightQuizAnswerDesignPictureIcon: 'Bild / Icon',
      tabRightQuizAnswerDesignPicture: 'Bild',
      tabRightQuizAnswerDesignIcons: 'Icons',
      tabRightQuizAnswerDesignColor: 'Farbe',
      tabRightQuizAnswerDesignBackgroundColor: 'Hintergrundfarbe',
      tabRightQuizAnswerDesignFontSize: 'Schriftgröße',
      tabRightQuizAnswerDesignApplyToAllAnswers: 'Auf alle Antworten anwenden',
      tabRightButtonText: 'Text',
      tabDesignAlignment: 'Ausrichtung',
      tabDesignSize: 'Größe',
      tabDesignMarginTop: 'Abstand oben',
      tabDesignMarginBottom: 'Abstand unten',
      tabDesignFormat: 'Format',
      page: 'Seite'
    },
    deleteDialog: {
      header: 'Wirklich löschen?',
      abort: 'Abbrechen',
      delete: 'Löschen'
    },
    renamePage: {
      header: 'Seitenname ändern',
      pageName: 'Seitenname',
      abort: 'Abbrechen',
      save: 'Speichern'
    },
    renameProject: {
      header: 'Projektnamen ändern',
      pageName: 'Projektname',
      abort: 'Abbrechen',
      save: 'Speichern'
    },
    resetEmail: {
      header: 'E-Mail zurücksetzen',
      email: 'Deine E-Mail',
      abort: 'Abbrechen',
      reset: 'Zurücksetzen'
    },
    editName: {
      header: 'Namen ändern',
      name: 'Dein Name',
      abort: 'Abbrechen',
      save: 'Speichern'
    }
  }
};
