import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Block, BlockStyle } from 'src/app/models/block.model';
import { AppService } from 'src/app/services/app.service';
import { BlockService } from 'src/app/services/block.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  @Input() block: Block;
  @Input() style: BlockStyle;
  @Input() buttonStyle: BlockStyle;
  @Input() blocks: Block[];
  @Input() blockIndex: number;
  @Input() mobileView: boolean;

  isMultipleChoice: boolean = false;

  answerIndex: number = -1;
  selectedAnswers: number[] = [];
  constructor(
    private sanitizer: DomSanitizer,
    private appService: AppService,
    private blockService: BlockService
  ) {}

  ngOnInit(): void {}

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  selectAnswerButton(index: number) {
    this.answerIndex = index;

    if (this.block.content.isMultipleChoice) {
      if (this.selectedAnswers.includes(index)) {
        this.selectedAnswers = this.selectedAnswers.filter(
          (answer) => answer !== index
        );
      } else {
        this.selectedAnswers.push(index);
      }
    }

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentPanelIndex: index,
        },
      },
      false
    );
  }

  getQuizButtonStyle(answerIndex: number, i: number) {
    return this.blockService.getQuizButtonStyle(this.blocks, answerIndex, i);
  }

  getQuizTextStyle(answerIndex: number, i: number) {
    return this.blockService.getQuizTextStyle(this.blocks, answerIndex, i);
  }

  getQuizIconStyle(answerIndex: number, i: number) {
    return this.blockService.getQuizIconStyle(this.blocks, answerIndex, i);
  }
  getQuizPictureStyle(answerIndex: number, i: number) {
    return this.blockService.getQuizPictureStyle(this.blocks, answerIndex, i);
  }

  getMultipleChoiceButtonStyle() {
    let currentBtn: any = this.block.content.multipleChoiceButton;
    if (typeof currentBtn !== 'undefined') {
      if (!currentBtn.style) return {};
      let styles = {
        'font-size': currentBtn.style.fontSize + 'px',
        width: currentBtn.style.width + '%',
        color: currentBtn.style.fontColor,
        'background-color': currentBtn.style.quizButtonColor,
        'font-weight': currentBtn.style.fontWeight,
        'font-style': currentBtn.style.fontStyle,
        'text-decoration': currentBtn.style.textDecoration,
        'margin-top': currentBtn.style.marginTop + 'px',
        'margin-bottom': currentBtn.style.marginBottom + 'px',
      };
      return styles;
    }
    return '';
  }

  getFontCSS(answerIndex: number, i: number) {
    let currentAnswer = this.blocks[i].content.answers[answerIndex];
    if (typeof currentAnswer !== 'undefined') {
      if (currentAnswer.style) {
        let font: string = currentAnswer.style.fontFamily
          ? currentAnswer.style.fontFamily
          : 'Roboto';

        font = font.replace(/ /g, '+');

        return this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://fonts.googleapis.com/css2?family=' +
            font +
            ':wght@100;200;300;400;500;600;700;800;900&display=swap'
        );
      }
    }
    return '';
  }

  changed(event: any) {
    this.block.content.answers[this.answerIndex].answerText =
      event.target.innerHTML;
    this.appService.setShouldSave();
  }
}
