<!-- This example requires Tailwind CSS v2.0+ -->
<!--
  This example requires updating your template:

  ```
  <html class="h-full">
  <body class="h-full">
  ```
-->
<main
  class="h-screen bg-cover bg-top sm:bg-top"
  style="background-image: url('./assets/notfound.jpeg')"
>
  <div
    class="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48"
  >
    <div class="flex w-full justify-center my-6">
      <img src="./assets/klicklead-logo-white.png" class="w-52 h-auto" />
    </div>
    <h1
      class="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl"
      i18n="@@funnelNotFound_funnelNotFoundHeadline_label"
    >
      Uh oh! Diese Seite existiert nicht (mehr)
    </h1>
    <p
      class="mt-2 text-lg font-medium text-white"
      i18n="@@funnelNotFound_description_label"
    >
      Du kannst Dir Deinen eigenen Funnel hier selbst bauen. Jetzt schnell
      registrieren.
    </p>
    <div class="mt-6">
      <a
        i18n="@@funnelNotFound_cta_link"
        href="https://klicklead.de/"
        class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md text-white bg-kl-purple"
      >
        Erstelle Deinen Funnel
      </a>
    </div>
  </div>
</main>
