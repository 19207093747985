import { Component, OnInit } from '@angular/core';
import { NotificationType } from 'src/app/enums/notification-type';
import { Project } from 'src/app/models/project.model';
import { AppService } from 'src/app/services/app.service';
import { MediaLibraryService } from 'src/app/services/media-library.service';
import { ProjectService } from 'src/app/services/project.service';
import { uuidv4 } from 'src/app/services/uuid';

@Component({
  selector: 'app-settings-meta',
  templateUrl: './settings-meta.component.html',
  styleUrls: ['./settings-meta.component.scss'],
})
export class SettingsMetaComponent implements OnInit {
  currentProject: Project;
  uploadLoader: boolean = false;
  constructor(
    private projectService: ProjectService,
    private appService: AppService,
    private mediaLibraryService: MediaLibraryService
  ) {}

  ngOnInit(): void {
    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
      }
    });
  }

  async saveProject() {
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: true,
        notificationType: NotificationType.SUCCESS,
        notificationMessage: 'Erfolgreich gespeichert',
        notificationDescription:
          'Die Änderungen wurden erfolgreich gespeichert.',
      },
    });
    await this.projectService.saveCurrentProject();

    await this.appService.delay(5000);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: false,
      },
    });
  }

  async uploadImage(event: any) {
    this.uploadLoader = true;
    for (let i = 0; i < event.addedFiles.length; i++) {
      const picture = await this.mediaLibraryService.uploadFaviconStorage(
        this.currentProject.id,
        uuidv4(),
        event.addedFiles[i]
      );

      this.currentProject.favicon = await picture.ref.getDownloadURL();

      await this.projectService.saveProject(this.currentProject);
    }

    this.uploadLoader = false;
  }

  async removeFavicon() {
    this.currentProject.favicon = '';
    await this.projectService.saveProject(this.currentProject);
  }
}
