import { BlockActionType } from '../enums/block-action-type';
import { BlockType } from '../enums/block-type';
import { SocialMediaType } from '../enums/social-media';
import { TextfieldType } from '../enums/textfield-type';
import { VideoType } from '../enums/video-type';
import { Block } from '../models/block.model';
import { uuidv4 } from '../services/uuid';

export const blockList: Block[] = [
  {
    id: uuidv4(),
    content: 'Mein Button',
    type: BlockType.BUTTON,
    style: {
      fontSize: 17,
      alignment: 'center',
      contentAlignment: 'center',
    },
    action: {
      type: BlockActionType.NEXTPAGE,
    },
  },
  {
    id: uuidv4(),
    content: {
      inputs: [
        {
          type: 'text',
          text: 'Text',
          isIcon: false,
          icon: 'content_copy',
          required: true,
          value: null,
        },
        { type: 'email', text: 'E-Mail', required: true, value: null },
      ],
      submitButton: {
        text: 'Senden',
        action: { type: BlockActionType.NEXTPAGE },
        style: {
          fontColor: '#ffffff',
          backgroundColor: '#820fff',
          hoverColor: '#5b07b6',
          fontSize: 20,
          height: 45,
        },
      },
      privacyCheck: {
        text: 'Datenschutzbestimmungen gelesen und akzeptiert',
        checked: false,
        required: true,
        style: { fontColor: '#000000', fontSize: 12 },
      },
    },
    type: BlockType.FORM,
    style: {
      fontSize: 14,
      alignment: 'center-item',
    },
    action: {
      type: BlockActionType.NEXTPAGE,
    },
  },
  {
    id: uuidv4(),
    content: 'Headline',
    type: BlockType.HEADER,
    style: {
      fontSize: 17,
      alignment: 'center',
    },
  },
  {
    id: uuidv4(),
    content: './assets/placeholder.jpg',
    type: BlockType.PICTURE,
    style: {
      fontSize: 17,
      alignment: 'center',
    },
  },
 /*  {
    id: uuidv4(),
    content: {
      answers: [
        {
          answerText: 'Antwort' + ' 1',
          type: 'picture',
          answerPicture: './assets/placeholder.jpg',
          action: {
            type: BlockActionType.NEXTPAGE,
          },
          style: { fontColor: '#000000', backgroundColor: null },
        },
        {
          answerText: 'Antwort' + ' 2',
          type: 'picture',
          answerPicture: './assets/placeholder.jpg',
          action: {
            type: BlockActionType.NEXTPAGE,
          },
          style: { fontColor: '#000000', backgroundColor: null },
        },
        {
          answerText: 'Antwort' + ' 3',
          type: 'picture',
          answerPicture: './assets/placeholder.jpg',
          action: {
            type: BlockActionType.NEXTPAGE,
          },
          style: { fontColor: '#000000', backgroundColor: null },
        },
        {
          answerText: 'Antwort' + ' 4',
          type: 'picture',
          answerPicture: './assets/placeholder.jpg',
          action: {
            type: BlockActionType.NEXTPAGE,
          },
          style: { fontColor: '#000000', backgroundColor: null },
        },
      ],
    },
    type: BlockType.QUIZ,
    style: {
      fontSize: 17,
      alignment: 'center-item',
    },
  }, */
  {
    id: uuidv4(),
    content: {
      type: TextfieldType.TEXT,
      required: true,
      text: 'Eingabefeld',
      value: null,
    },
    type: BlockType.TEXTFIELD,
    style: {
      fontSize: 17,
      alignment: 'center',
      fontColor: '#000000',
    },
  },
  {
    id: uuidv4(),
    content: { type: 'spinner', duration: 5 },
    type: BlockType.FAKELOADER,
    style: {
      alignment: 'center',
      fontColor: '#000000',
      borderColor: '#820fff',
      height: 80,
      width: 80,
    },
  },
  {
    id: uuidv4(),
    showQuiz: true,
    content: {
      answers: [
        {
          answerText: 'Antwort' + ' 1',
          checked: false,
        },
        {
          answerText: 'Antwort' + ' 2',
          checked: false,
        },
        {
          answerText: 'Antwort' + ' 3',
          checked: false,
        },
      ],
      style: {
        fontSize: 17,
        fontColor: '#000000',
      },
    },
    type: BlockType.SELECTIONQUIZ,
    style: {
      alignment: 'center',
      fontColor: '#000000',
    },
  }, 
 /*  {
    id: uuidv4(),
    content: {
      answers: [
        {
          answerText: 'Antwort' + ' 1',
          checked: false,
        },
        {
          answerText: 'Antwort' + ' 2',
          checked: false,
        },
        {
          answerText: 'Antwort' + ' 3',
          checked: false,
        },
      ],
      style: {
        fontSize: 17,
        fontColor: '#000000',
      },
    },
    type: BlockType.SELECTION,
    style: {
      alignment: 'center',
      fontColor: '#000000',
    },
  }, */
  {
    id: uuidv4(),
    content: {
      type: VideoType.YOUTUBE,
      url: 'https://www.youtube.com/watch?v=k-ZXEDMEaew',
    },
    type: BlockType.VIDEO,
    style: {
      alignment: 'center',
      marginBottom: 20,
      marginTop: 20,
    },
  },
  {
    id: uuidv4(),
    content: {
      type: SocialMediaType.FACEBOOK,
      url: '',
    },
    type: BlockType.SOCIALMEDIA,
    style: {
      alignment: 'center',
      marginBottom: 20,
      marginTop: 20,
      fontSize: 50,
    },
  },

  {
    id: uuidv4(),
    content: {
      bulletpoints: [
        {
          text: 'Bulletpoint 1',
          icon: 'bi bi-star-fill',
        },
        {
          text: 'Bulletpoint 2',
          icon: 'bi bi-star-fill',
        },
        {
          text: 'Bulletpoint 3',
          icon: 'bi bi-star-fill',
        },
      ],
      textStyle: {
        color: '#000000',
      },
      iconStyle: {
        color: '#000000',
      },
    },
    type: BlockType.BULLETPOINTS,
    style: {
      alignment: 'center',
      marginBottom: 20,
      marginTop: 20,
    },
  },

  {
    id: uuidv4(),
    content: {
      uploads: [],
      maxUploads: 10,
      picture:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Noun_Project_cloud_upload_icon_411593_cc.svg/149px-Noun_Project_cloud_upload_icon_411593_cc.svg.png?20180227235404',
      text: 'Klicken Sie hier um Bilder hochzuladen',
      required: true,
    },
    type: BlockType.UPLOAD,
    style: {
      alignment: 'center',
      marginBottom: 20,
      marginTop: 20,
    },
  },
  {
    id: uuidv4(),
    content: {
      inputs: [
        {
          type: 'text',
          text: 'Straße',
          isIcon: false,
          icon: 'content_copy',
          required: true,
          value: null,
        },
        {
          type: 'number',
          text: 'Hausnummer',
          isIcon: false,
          icon: 'content_copy',
          required: true,
          value: null,
        },
        { type: 'text', text: 'PLZ', required: true, value: null },
        { type: 'text', text: 'Ort', required: true, value: null },
      ],
      submitButton: {
        text: 'Weiter',
        action: { type: BlockActionType.NEXTPAGE },
        style: {
          fontColor: '#ffffff',
          backgroundColor: '#820fff',
          hoverColor: '#5b07b6',
          fontSize: 20,
          height: 45,
        },
      },

      onSuccess: {
        type: BlockActionType.NEXTPAGE,
        
      },
      onFail: {
        type: BlockActionType.NEXTPAGE,
      },
    },
    type: BlockType.ZW_WESTNETZ,
    style: {
      fontSize: 14,
      alignment: 'center-item',
    },
  },

  /* {
    id: uuidv4(),
    content: {
      min: 0,
      max: 100,
      value: 50,
      steps: 1,
    },
    type: BlockType.SLIDER,
    style: {
      alignment: 'center',
      marginBottom: 20,
      marginTop: 20,
    },
  },*/
];
