<div style="height: calc(100vh - 50px)" class="h-full">
  <div class="w-full h-full py-3 flex flex-col justify-between items-center">
    <!-- Current: "bg-indigo-800 text-white", Default: "text-white hover:bg-indigo-800 hover:text-white" -->

    <div class="flex flex-col gap-y-2">
      <a
        *ngFor="let item of menuItems; let i = index"
        (click)="selectSideBarIndex(i)"
        class="bg-kl-purple text-white group w-full py-3 px-6 rounded-md flex flex-col items-center text-xs font-medium cursor-pointer"
        [ngClass]="{
          'bg-kl-purple text-white ': currentSidebarIndex === i,
          'text-white hover:bg-gray-700 hover:text-white':
            currentSidebarIndex !== i
        }"
      >
        <i [class]="item.icon + ' text-white'"></i>

        <span class="mt-2 text-white">{{ item.name }}</span>
      </a>
    </div>

    <div class="flex flex-col">
      <a
        (click)="undo()"
        class="text-white hover:bg-gray-700 hover:text-white group w-full p-3 rounded-md flex flex-col items-center font-medium cursor-pointer"
        [ngClass]="{ 'hover:text-gray-500  text-gray-500': !showUndo }"
      >
        <i
          class="bi bi-arrow-counterclockwise text-xl text-white"
          [ngClass]="{ 'hover:text-gray-500  text-gray-500': !showUndo }"
        ></i>
        <span class="mt-2 text-xs" i18n="@@funnelSidebar_undo_button"
          >Rückgängig</span
        >
      </a>
      <a
        (click)="redo()"
        class="text-white hover:bg-gray-700 hover:text-white group w-full p-3 rounded-md flex flex-col items-center font-medium cursor-pointer"
        [ngClass]="{ 'hover:text-gray-500  text-gray-500': !showRedo }"
      >
        <i
          class="bi bi-arrow-clockwise text-xl text-white"
          [ngClass]="{ 'hover:text-gray-500  text-gray-500': !showRedo }"
        ></i>
        <span class="mt-2 text-xs" i18n="@@funnelSidebar_redo_button"
          >Wiederholen</span
        >
      </a>
      <a
        href="#"
        class="text-white hover:bg-gray-700 hover:text-white group w-full p-3 rounded-md flex flex-col items-center font-medium"
      >
        <i class="bi bi-question-circle-fill text-xl text-white"></i>
        <span class="mt-2 text-xs" i18n="@@funnelSidebar_help_button"
          >Hilfe</span
        >
      </a>
    </div>
  </div>
</div>
