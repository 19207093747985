import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationType } from 'src/app/enums/notification-type';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { ProjectService } from 'src/app/services/project.service';
import { TierService } from 'src/app/services/tier.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-settings-embed',
  templateUrl: './settings-embed.component.html',
  styleUrls: ['./settings-embed.component.scss'],
})
export class SettingsEmbedComponent implements OnInit {
  currentProject: Project;
  iframeCode: string;
  user: User;
  embedEnabled: boolean = false;
  constructor(
    private projectService: ProjectService,
    private appService: AppService,
    private userService: UserService,
    private tierService: TierService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
      }
    });
    this.setIFrameCode();

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
        this.getEmbedRights();
      }
    });
  }

  getEmbedRights() {
    if (this.user.tier) {
      this.embedEnabled = this.tierService.getRights('embed', this.user.tier!);
      console.log(this.embedEnabled);
      if (!this.embedEnabled) {
        this.iframeCode = 'Diese Funktion ist nicht verfügbar';
      }
    }
  }

  openPaymentPage() {
    this.router.navigateByUrl('/user-settings?payment=true');
  }

  setIFrameCode() {
    let widgetUrl: string =
      'https://firebasestorage.googleapis.com/v0/b/funnelbuilder-zw.appspot.com/o/widget%2Ffunneltool-widget.js?alt=media&token=0a83faa6-ea76-4d39-803b-90a367783329';

    this.iframeCode =
      `<funneltool-widget id="` +
      this.currentProject.id +
      `"></funneltool-widget> \n` +
      `<script type="text/javascript" src="` +
      widgetUrl +
      `" defer></script>
     `;
  }

  async saveProject() {
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: true,
        notificationType: NotificationType.SUCCESS,
        notificationMessage: 'Erfolgreich gespeichert',
        notificationDescription:
          'Die Änderungen wurden erfolgreich gespeichert.',
      },
    });
    await this.projectService.saveCurrentProject();

    await this.appService.delay(5000);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: false,
      },
    });
  }

  copyIFrameCode() {
    var dummy = document.createElement('textarea');

    document.body.appendChild(dummy);
    dummy.value = this.iframeCode;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    this.appService.showNotification(
      'Erfolgreich kopiert',
      'Der Code wurde erfolgreich in die Zwischenablage kopiert.',
      NotificationType.INFO,
      5000
    );
  }
}
