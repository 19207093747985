import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';
import { MediaLibraryModalComponent } from 'src/app/components/modals/media-library-modal/media-library-modal.component';
import { RenameProjectDialogComponent } from 'src/app/components/modals/rename-project-dialog/rename-project-dialog.component';
import { FolderImage, MediaImage } from 'src/app/models/media-image.model';
import { User } from 'src/app/models/user.model';
import { MediaLibraryService } from 'src/app/services/media-library.service';
import { UnsplashService } from 'src/app/services/unsplash.service';
import { UserService } from 'src/app/services/user.service';
import { uuidv4 } from 'src/app/services/uuid';

@Component({
  selector: 'app-details-mediathek',
  templateUrl: './details-mediathek.component.html',
  styleUrls: ['./details-mediathek.component.scss'],
})
export class DetailsMediathekComponent implements OnInit {
  userImages: any[] = [];
  userImagesLoader: boolean = false;
  unsplashImages: any[] = [];
  unsplashImagesLoader: boolean = false;

  searchString: string = '';
  tabIndex: number = 0;
  projectId: string;
  uploadLoader: boolean = false;
  user!: User;

  currentFolder: any = null;

  @Output() onSelectImage = new EventEmitter<string>();
  constructor(
    private unsplashService: UnsplashService,
    private mediaLibraryService: MediaLibraryService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService
  ) {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.projectId = id;
    }

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
        this.loadUserImages();
      }
    });
  }

  async ngOnInit() {}

  async loadUserImages() {
    this.userImagesLoader = true;

    try {
      this.userImages = await this.mediaLibraryService.getImagesOfUser(
        this.user.id
      );

      this.userImages.sort((a, b) => {
        return (
          new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
        );
      });
    } catch (error) {
      console.error('Error loading user images:', error);
      // Handle error (e.g., user notification)
    } finally {
      this.userImagesLoader = false;
    }
  }

  async loadRandomImages() {
    this.unsplashImagesLoader = true;
    this.unsplashImages = await this.unsplashService.getRandomImages();
    console.log(this.unsplashImages);
    this.unsplashImagesLoader = false;
  }

  async search() {
    this.unsplashImagesLoader = true;
    if (this.searchString.length > 0) {
      this.unsplashImages = await this.unsplashService.searchImages(
        this.searchString
      );
      console.log(this.unsplashImages);
    }
    this.unsplashImagesLoader = false;
  }

  setTabIndex(index: number) {
    if (index === 1) {
      this.loadRandomImages();
    }
    this.tabIndex = index;
  }

  async uploadImage(event: any) {
    this.uploadLoader = true;
    const processImageUpload = async (file: File) => {
      const image: FolderImage = {
        id: uuidv4(),
        createdDate: new Date(),
        projectId: this.projectId,
        url: '',
        name: file.name,
        parentId: this.currentFolder ? this.currentFolder.id : '',
        type: 'image',
        userId: this.currentFolder ? '' : this.user.id,
      };

      const picture = await this.mediaLibraryService.uploadImageStorage(
        this.user.id,
        image.id,
        file
      );

      image.url = await picture.ref.getDownloadURL();
      await this.mediaLibraryService.setImageOfFolder(image);

      this.userImages.push(image);
    };

    await Promise.all(event.addedFiles.map(processImageUpload));

    this.userImages.sort((a, b) => {
      return (
        new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
      );
    });

    this.uploadLoader = false;
  }

  async openMediathek() {
    const dialogRef = this.dialog.open(MediaLibraryModalComponent, {
      width: '100vw',
      height: '80vh',
      data: {
        projectId: this.projectId,
        title: 'Mediathek öffnen',
        description: 'Wähle einen Ordner aus, um ihn zu öffnen.',
        inputPlaceholder: 'Ordnername',
        inputType: 'select',
        selectOptions: this.userImages,
        selectOptionLabel: 'name',
        selectOptionValue: 'id',
      },
    });

    const result = await dialogRef.afterClosed().toPromise();

    if (result) {
      this.onSelectImage.emit(result);
    }
  }

  async newFolder() {
    let folder: FolderImage = {
      id: uuidv4(),
      createdDate: new Date(),
      projectId: this.projectId,
      url: '',
      name: 'Neuer Ordner',
      parentId: '',
      type: 'folder',
      userId: this.user.id,
    };

    const dialogRef = this.dialog.open(RenameProjectDialogComponent, {
      width: '550px',
      data: {
        header: 'Neuer Ordner',
        label: 'Geben Sie einen Namen für den Ordner ein',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        folder.name = result;
        if (this.currentFolder !== null) {
          folder.parentId = this.currentFolder.id;
          folder.userId = '';
          await this.mediaLibraryService.setImageOfFolder(folder);
          this.openFolder(this.currentFolder);
        } else {
          await this.mediaLibraryService.setImageOfFolder(folder);
          this.loadUserImages();
        }
      }
    });
  }

  async folderBack() {
    if (this.currentFolder !== null) {
      if (this.currentFolder.parentId === '') {
        this.loadUserImages();
        this.currentFolder = null;
      } else {
        const folder = await this.mediaLibraryService.getFolder(
          this.currentFolder.parentId
        );
        this.openFolder(folder);
      }
    } else {
      this.currentFolder = null;
      this.loadUserImages();
    }
  }

  selectPicture(image: MediaImage) {
    this.onSelectImage.emit(image.url);
  }

  async openFolder(folder: FolderImage) {
    this.currentFolder = folder;
    this.userImages = await this.mediaLibraryService.getImagesOfFolder(
      this.currentFolder.id
    );
    console.log(this.userImages);
    this.userImages.sort((a, b) => {
      const bDate: any = b.createdDate as Object;
      const aDate: any = a.createdDate as Object;
      return (
        new Date(aDate.seconds * 1000).getTime() -
        new Date(bDate.seconds * 1000).getTime()
      );
    });
  }

  deleteImage(image: MediaImage) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '550px',
      data: {
        title: 'Bild entfernen',
        text: 'Möchten Sie das Bild wirklich entfernen?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.mediaLibraryService.deleteImage(image.id);
        this.userImages = this.userImages.filter(
          (item) => item.id !== image.id
        );
      }
    });
  }
}
