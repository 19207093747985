import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Tier, User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-user-dashboard-payment',
  templateUrl: './user-dashboard-payment.component.html',
  styleUrls: ['./user-dashboard-payment.component.scss'],
})
export class UserDashboardPaymentComponent implements OnInit {
  @Input() user: User;
  trials: Tier;
  registerDate: Date;

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.user.tier = this.user.tier || Tier.TRIAL;
    this.registerDate = this.user.registerDate.toDate();
  }

  async saveUser() {
    const save = await this.userService.setUser(this.user, false);
    this.snackBar.open('Ihre Daten wurden gespeichert', 'Okay', {
      duration: 5000,
    });
  }

  addEvent(type: string, event: any) {
    this.user.registerDate = firebase.default.firestore.Timestamp.fromDate(
      event.value
    );
    this.registerDate = event.value;
    console.log(this.user.registerDate);
  }
}
