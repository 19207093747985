export const fonts = [
  'Roboto',
  'Open Sans',
  'Lato',
  'Montserrat',
  'Oswald',
  'Roboto Condensed',
  'Raleway',
  'PT Sans',
  'Roboto Slab',
  'Merriweather',
  'Ubuntu',
  'Noto Sans',
  'Poppins',
  'Lora',
  'Roboto Mono',
  'Playfair Display',
  'PT Serif',
  'Nunito',
  'Titillium Web',
  'PT Sans Narrow',
  'Arimo',
  'Noto Serif',
  'Fira Sans',
  'Rubik',
  'Dosis',
  'Work Sans',
  'Oxygen',
  'Bitter',
  'Inconsolata',
  'Quicksand',
  'Lobster',
  'Noto Sans KR',
  'Indie Flower',
  'Cabin',
  'Fjalla One',
  'Nanum Gothic',
  'Anton',
  'Mukta',
  'Josefin Sans',
  'Nunito Sans',
  'Libre Baskerville',
  'Karla',
  'Abel',
  'Noto Sans TC',
  'Heebo',
  'Pacifico',
  'Varela Round',
  'Dancing Script',
  'Shadows Into Light',
  'Exo 2',
  'Libre Franklin',
  'Merriweather Sans',
  'Source Code Pro',
  'Bree Serif',
  'Asap',
  'Barlow',
  'Ubuntu Condensed',
  'Archivo Narrow',
  'Play',
  'Francois One',
];
