import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './views/login/login.component';

import { RegisterComponent } from './views/register/register.component';
import { FunnelListComponent } from './views/funnel-list/funnel-list.component';
import { FunnelEditorComponent } from './views/funnel-editor/funnel-editor.component';
import { FunnelStatisticComponent } from './views/funnel-statistic/funnel-statistic.component';
import { FunnelSettingsComponent } from './views/funnel-settings/funnel-settings.component';
import { AuthGuard } from './guards/auth.guard';
import { FunnelAppComponent } from './views/funnel-app/funnel-app.component';
import { PageNotFoundComponent } from './views/not-found/not-found.component';
import { PasswordResetComponent } from './views/password-reset/password-reset.component';
import { VerifyEmailComponent } from './views/verify-email/verify-email.component';
import { PageFlowComponent } from './views/page-flow/page-flow.component';
import { UserSettingsComponent } from './views/user-settings/user-settings.component';
import { NewEditorComponent } from './views/new-editor/new-editor.component';
import { NewLoginComponent } from './views/new-login/new-login.component';
import { NewRegisterComponent } from './views/new-register/new-register.component';
import { NewFunnelsComponent } from './views/new-funnels/new-funnels.component';
import { NewFunnelsSettingComponent } from './views/new-funnels-setting/new-funnels-setting.component';
import { NewFunnelLeadsComponent } from './views/new-funnel-leads/new-funnel-leads.component';
import { NewFunnelFlowComponent } from './views/new-funnel-flow/new-funnel-flow.component';
import { NewFunnelShopComponent } from './views/new-funnel-shop/new-funnel-shop.component';
import { AdminDashboardComponent } from './views/admin-dashboard/admin-dashboard.component';
import { ContactComponent } from './views/contact/contact.component';
import { UserDashboardComponent } from './views/user-dashboard/user-dashboard.component';
import { FunnelPresetsComponent } from './components/funnel-presets/funnel-presets.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  { path: 'verify-email', component: VerifyEmailComponent },

  { path: 'funnels', component: FunnelListComponent, canActivate: [AuthGuard] },
  {
    path: 'funnel-flow/:id',
    component: PageFlowComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-settings',
    component: UserSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'funnel-leads/:id',
    component: FunnelStatisticComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'funnel-leads/:id/:adminEdit',
    component: FunnelStatisticComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'funnel-editor/:id',
    component: NewEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'funnel-presets/:id',
    component: FunnelPresetsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'funnel-editor/:id/:adminEdit',
    component: NewEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'funnel-settings/:id/:adminEdit',
    component: FunnelSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'funnel-settings/:id',
    component: FunnelSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin-dashboard',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-dashboard',
    component: UserDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':id',
    component: FunnelAppComponent,
  },
  {
    path: '',
    component: FunnelListComponent,
  },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'funnels' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
