<div
  class="flex justify-start items-center cursor-pointer mb-2"
  (click)="goBack()"
>
  <i class="bi bi-chevron-left cursor-pointer"></i>
  <p
    class="ml-2 text-sm font-medium text-kl-grey hover:text-gray-500 truncate"
    i18n="@@funnelSidebarDetailBulletpointItem_headline_label"
  >
    Icon-Auflistung
  </p>
</div>

<div class="border-b border-gray-200">
  <nav class="-mb-px flex" aria-label="Tabs">
    <a
      (click)="setTabIndex(0)"
      class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
      [ngClass]="{
        'border-kl-purple text-kl-purple': tabIndex === 0,
        'text-gray-500 hover:text-gray-700 hover:border-gray-300':
          tabIndex !== 0
      }"
      i18n="@@funnelSidebarDetailBulletpointItem_edit_label"
      >Bearbeiten
    </a>

    <a
      (click)="setTabIndex(3)"
      class="border-transparent w-1/2 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
      [ngClass]="{
        'border-kl-purple text-kl-purple': tabIndex === 3,
        'text-gray-500 hover:text-gray-700 hover:border-gray-300':
          tabIndex !== 3
      }"
      i18n="@@funnelSidebarDetailBulletpointItem_icon_label"
      >Iconauswahl
    </a>
  </nav>
</div>

<div *ngIf="tabIndex === 0">
  <div class="my-6 flex justify-between">
    <div
      contenteditable="true"
      rows="4"
      [innerHTML]="bulletpoint.text"
      (blur)="updateText($event)"
      class="shadow-sm border h-32 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md p-2"
    ></div>
  </div>

  <div class="flex justify-end my-6">
    <button
      (click)="removeItem()"
      type="button"
      class="my-6 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-800 focus:outline-none"
      i18n="@@funnelSidebarDetailBulletpointItem_delete_label"
    >
      Entfernen
    </button>
  </div>
</div>

<div *ngIf="tabIndex === 3" class="overflow-auto">
  <app-details-icon-library
    (selectedIconEvent)="updateIcon($event)"
  ></app-details-icon-library>
</div>
<!-- 
<div *ngIf="tabIndex === 4 && answer" style="height: calc(80vh)">
  <div class="w-full">
    <div class="my-6">
      <app-margin
        [selectedElement]="answer"
        [title]="'Abstände (innen)'"
        [type]="'answer'"
        (onMarginChange)="setMargin($event)"
      ></app-margin>
    </div>
  </div>

  <div class="my-6">
    <app-color-picker
      [title]="'Textfarbe'"
      [color]="answer.style?.fontColor ? answer.style?.fontColor : '#000000'"
      (onColorChange)="setFontColor($event)"
    ></app-color-picker>
  </div>

  <div class="my-6">
    <app-color-picker
      [title]="'Quiz Card Hintergrundfarbe'"
      [color]="
        answer.style?.quizButtonColor
          ? answer.style?.quizButtonColor
          : '#000000'
      "
      (onColorChange)="setQuizCardBackgroundColor($event)"
    ></app-color-picker>
  </div>

  <div class="my-6">
    <app-color-picker
      [title]="'Hintergrundfarbe'"
      [color]="
        answer.style?.backgroundColor
          ? answer.style?.backgroundColor
          : '#000000'
      "
      (onColorChange)="setBackgroundColor($event)"
    ></app-color-picker>
  </div>

  <div class="my-6">
    <app-range-slider
      [value]="answer.style?.fontSize ? answer.style?.fontSize : 16"
      [title]="'Schriftgröße'"
      (valueChange)="setFontSize($event)"
    ></app-range-slider>
  </div>

  <div class="my-6" *ngIf="answer.type === 'icon'">
    <app-range-slider
      [value]="answer.style?.iconSize ? answer.style?.iconSize : 16"
      [title]="'Icon Größe'"
      (valueChange)="setIconSize($event)"
    ></app-range-slider>
  </div>
</div> -->
