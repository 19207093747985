<div class="flex flex-col justify-between m-4">
  <div class="mb-6">
    <div>
      <div class="text-sm text-gray-500">Name</div>
      <input
        type="text"
        (blur)="saveProject()"
        [(ngModel)]="currentProject.pages[this.currentPageIndex].name"
      />
    </div>
  </div>

  <div>
    <p class="text-sm text-gray-500">Facebook Pixel Event</p>
    <div appearance="outline" color="primary">
      <select
        [(ngModel)]="currentProject.pages[currentPageIndex].fbEvent"
        class="select select-bordered w-full max-w-xs"
        (input)="setFBEvent($event)"
      >
        <option
          *ngFor="let event of facebookEvents"
          [value]="event"
         
        >
          {{ event }}
        </option>
      </select>
    </div>
  </div>
</div>
