<div class="flex flex-col p-6">
  <h1 class="text-2xl font-bold my-4">Domain hinzufügen</h1>
  <div class="h-72">
    <!-- This example requires Tailwind CSS v2.0+ -->

    <div *ngIf="currentStep === 1">
      <div
        class="h-full flex flex-col justify-start items-start"
        *ngIf="domainType === 'id'"
      >
        <label
          for="company-website"
          class="block text-base font-medium text-gray-900"
        >
          Eigene Funnel ID
        </label>
        <div class="mt-4 flex rounded-md shadow-sm w-full">
          <span
            class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
          >
            https://funnel.klicklead.de/
          </span>
          <input
            type="text"
            [(ngModel)]="domainId"
            name="company-website"
            id="company-website"
            class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
            placeholder="deine Funnel ID"
          />
        </div>

        <div *ngIf="showDomainAvailabilityError" class="mt-4 text-red-500">
          Die ID ist bereits vergeben.
        </div>
      </div>

      <div
        class="h-full flex flex-col w-full justify-start items-start"
        *ngIf="domainType === 'custom'"
      >
        <label
          for="company-website"
          class="block text-base font-medium text-gray-900"
          >Eigene Domain</label
        >
        <div class="mt-4 relative rounded-md shadow-sm w-full">
          <input
            type="text"
            [(ngModel)]="customDomain"
            name="company-website"
            id="company-website"
            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="https://www.deine-domain.de"
          />
        </div>
        <div *ngIf="showCustomDomainWrongURL" class="mt-4 text-red-500">
          Die URL ist nicht valide.
        </div>
        <div
          *ngIf="showCustomDomainAvailabilityError"
          class="mt-4 text-red-500"
        >
          Die URL ist bereits vergeben.
        </div>
      </div>
    </div>

    <fieldset class="h-full flex items-center" *ngIf="currentStep === 0">
      <legend class="text-base font-medium text-gray-900">
        Art der Domain
      </legend>

      <div class="mt-4 flex justify-between items-center w-full">
        <!--
        Checked: "border-transparent", Not Checked: "border-gray-300"
        Active: "ring-2 ring-indigo-500"
      -->
        <label
          class="relative bg-white hover:bg-gray-100 border h-36 rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
          [ngClass]="{
            'border-transparent ring-2 ring-indigo-500': domainType === 'id',
            'border-gray-300': domainType !== 'id',
            'bg-gray-300 rounded-lg cursor-not-allowed': !domainIdEnabled,
            'opacity-100': domainIdEnabled
          }"
        >
          <!--   <i
          class="bi bi-lock-fill text-kl-purple text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-50"
          *ngIf="!domainIdEnabled"
        ></i> -->
          <button
            *ngIf="!domainIdEnabled"
            class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            (click)="openPaymentPage()"
          >
            <i class="bi bi-lock-fill text-white"></i> Jetzt upgraden
          </button>
          <input
            type="radio"
            name="project-type"
            value="id"
            [disabled]="!domainIdEnabled"
            [(ngModel)]="domainType"
            class="sr-only"
            aria-labelledby="project-type-0-label"
            aria-describedby="project-type-0-description-0 project-type-0-description-1"
          />
          <div class="flex-1 flex">
            <div class="flex flex-col">
              <span
                id="project-type-0-label"
                class="block text-sm font-medium text-gray-900"
              >
                Eigene Funnel ID
              </span>
              <span
                id="project-type-0-description-0"
                class="mt-3 flex items-center text-xs text-gray-400"
              >
                https://funnel.klicklead.de/deinName
              </span>
            </div>
          </div>
          <!--
          Not Checked: "invisible"
  
          Heroicon name: solid/check-circle
        -->
          <svg
            class="h-5 w-5 text-indigo-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            [ngClass]="{
              invisible: domainType !== 'id'
            }"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>
          <!--
          Active: "border", Not Active: "border-2"
          Checked: "border-indigo-500", Not Checked: "border-transparent"
        -->
          <div
            class="absolute -inset-px rounded-lg border-2 pointer-events-none"
            aria-hidden="true"
          ></div>
        </label>

        <!--
        Checked: "border-transparent", Not Checked: "border-gray-300"
        Active: "ring-2 ring-indigo-500"
      -->
        <label
          class="relative bg-white hover:bg-gray-100 border h-36 rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
          [ngClass]="{
            'border-transparent ring-2 ring-indigo-500':
              domainType === 'custom',
            'border-gray-300': domainType !== 'custom',
            'bg-gray-300 rounded-lg cursor-not-allowed':
              customDomainsOfUser >= maxCustomDomains,
            'opacity-100': customDomainsOfUser <= maxCustomDomains
          }"
        >
          <!--  <i
          class="bi bi-lock-fill text-kl-purple text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-50"
          *ngIf="customDomainsOfUser >= maxCustomDomains"
        ></i> -->
          <button
            *ngIf="customDomainsOfUser >= maxCustomDomains"
            class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            (click)="openPaymentPage()"
          >
            <i class="bi bi-lock-fill text-white"></i> Jetzt upgraden
          </button>
          <input
            type="radio"
            name="project-type"
            value="custom"
            [disabled]="customDomainsOfUser >= maxCustomDomains"
            [(ngModel)]="domainType"
            class="sr-only"
            [(ngModel)]="domainType"
            aria-labelledby="project-type-1-label"
            aria-describedby="project-type-1-description-0 project-type-1-description-1"
          />
          <div class="flex-1 flex">
            <div class="flex flex-col">
              <span
                id="project-type-1-label"
                class="block text-sm font-medium text-gray-900"
              >
                Eigene URL
              </span>
              <span
                id="project-type-1-description-0"
                class="mt-3 flex items-center text-xs text-gray-500"
              >
                https://deine-domain.de/
              </span>
            </div>
          </div>
          <!--
          Not Checked: "invisible"
  
          Heroicon name: solid/check-circle
        -->
          <svg
            class="h-5 w-5 text-indigo-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            [ngClass]="{
              invisible: domainType !== 'custom'
            }"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>
          <!--
          Active: "border", Not Active: "border-2"
          Checked: "border-indigo-500", Not Checked: "border-transparent"
        -->
          <div
            class="absolute -inset-px rounded-lg border-2 pointer-events-none"
            aria-hidden="true"
          ></div>
        </label>
      </div>
    </fieldset>

    <div
      *ngIf="currentStep === 2 && domainType === 'custom'"
      class="flex flex-col justify-start items-start"
    >
      <label
        for="company-website"
        class="block text-base font-medium text-gray-900"
      >
        Wie verbindest du deinen Funnel mit deiner Domain?
      </label>
      <p class="mt-4 text-sm text-gray-700">
        Du hast die Domain {{ customDomain }} zu KlickLead hinzugefügt. Damit
        deiine Domain korrekt funktioniert, muss du dich bei deinem
        Domain-Registrar anmelden und einen CNAME-DNS-Eintrag anlegen.
      </p>

      <div class="mt-4 flex w-full justify-between p-4 border">
        <div class="flex flex-col">
          <p class="text-gray-400 text-sm uppercase">Typ</p>
          <p class="border-b border-dashed">CNAME</p>
        </div>
        <div class="flex flex-col">
          <p class="text-gray-400 text-sm uppercase">Host</p>
          <p class="border-b border-dashed">www</p>
        </div>
        <div class="flex flex-col">
          <p class="text-gray-400 text-sm uppercase">Value</p>
          <p class="border-b border-dashed">cname.vercel-dns.com</p>
        </div>
        <div class="flex flex-col">
          <p class="text-gray-400 text-sm uppercase">TTL</p>
          <p class="border-b border-dashed">Auto</p>
        </div>
      </div>
    </div>

    <div
      *ngIf="currentStep === 2 && domainType === 'id'"
      class="flex flex-col justify-start items-start"
    >
      <p class="mt-4 text-sm text-gray-700">
        Du kannst deinen Funnel jetzt über die URL
        <a
          class="text-blue-600 cursor-pointer"
          [href]="'https://funnel.klicklead.de/' + domainId"
          >https://funnel.klicklead.de/{{ domainId }}</a
        >
        erreichen.
      </p>
    </div>

    <div
      *ngIf="currentStep === 3"
      class="relative flex flex-col justify-center sm:flex-row items-center bg-white rounded-md py-5"
    >
      <div
        class="flex flex-col justify-center items-center border-b sm:border-b-0 w-full"
      >
        <div class="text-green-500">
          <svg
            class="w-12 h-12"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </div>
        <div class="text-xl font-medium">Domain erfolgreich hinzugefügt</div>
        <div class="text-sm tracking-wide text-gray-500 mt-6">
          Du hast deine Domain erfolgreich hinzugefügt. Nun kannst du deinen
          Funnel unter deiner neuen Domain nutzen.
        </div>
      </div>
    </div>
  </div>
  <div class="flex items-center justify-between">
    <button
      (click)="previousStep()"
      class="text-sm font-bold"
      [ngClass]="{ 'opacity-0': currentStep === 0 }"
    >
      zurück
    </button>
    <nav class="flex items-center justify-center" aria-label="Progress">
      <ol role="list" class="ml-8 flex items-center space-x-5">
        <li>
          <!-- Completed Step -->
          <div
            class="relative flex items-center justify-center"
            aria-current="step"
          >
            <span
              *ngIf="currentStep === 0"
              class="absolute w-5 h-5 p-px flex"
              aria-hidden="true"
            >
              <span class="w-full h-full rounded-full bg-indigo-200"></span>
            </span>
            <span
              class="relative block w-2.5 h-2.5 bg-gray-200 rounded-full"
              aria-hidden="true"
              [ngClass]="{ 'bg-indigo-600': currentStep >= 0 }"
            ></span>
            <span class="sr-only">Schritt 1</span>
          </div>
        </li>

        <li>
          <!-- Current Step -->
          <div
            class="relative flex items-center justify-center"
            aria-current="step"
          >
            <span
              *ngIf="currentStep === 1"
              class="absolute w-5 h-5 p-px flex"
              aria-hidden="true"
            >
              <span class="w-full h-full rounded-full bg-indigo-200"></span>
            </span>
            <span
              class="relative block w-2.5 h-2.5 bg-gray-200 rounded-full"
              aria-hidden="true"
              [ngClass]="{ 'bg-indigo-600': currentStep >= 1 }"
            ></span>
            <span class="sr-only">Schritt 2</span>
          </div>
        </li>

        <li>
          <!-- Upcoming Step -->
          <div
            class="relative flex items-center justify-center"
            aria-current="step"
          >
            <span
              *ngIf="currentStep === 2"
              class="absolute w-5 h-5 p-px flex"
              aria-hidden="true"
            >
              <span class="w-full h-full rounded-full bg-indigo-200"></span>
            </span>
            <span
              class="relative block w-2.5 h-2.5 bg-gray-200 rounded-full"
              aria-hidden="true"
              [ngClass]="{ 'bg-indigo-600': currentStep >= 2 }"
            ></span>
            <span class="sr-only">Schritt 3</span>
          </div>
        </li>

        <li>
          <!-- Upcoming Step -->
          <div
            class="relative flex items-center justify-center"
            aria-current="step"
          >
            <span
              *ngIf="currentStep === 3"
              class="absolute w-5 h-5 p-px flex"
              aria-hidden="true"
            >
              <span class="w-full h-full rounded-full bg-gray-200"></span>
            </span>
            <span
              class="relative block w-2.5 h-2.5 rounded-full"
              aria-hidden="true"
              [ngClass]="{
                'bg-indigo-600': currentStep >= 3,
                'bg-gray-200': currentStep < 3
              }"
            ></span>
            <span class="sr-only">Schritt 4</span>
          </div>
        </li>
      </ol>
    </nav>
    <button
      (click)="nextStep()"
      *ngIf="currentStep < 3"
      class="ml-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3"
      [ngClass]="{ 'opacity-0': !checkVisibility() }"
    >
      weiter
    </button>

    <button
      (click)="close()"
      *ngIf="currentStep === 3"
      class="ml-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3"
    >
      Fertig
    </button>
  </div>
</div>
