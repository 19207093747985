import { Component, Input, OnInit } from '@angular/core';
import { Block, BlockStyle } from 'src/app/models/block.model';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
  @Input() block: Block;
  @Input() style: BlockStyle;
  @Input() mobileView: boolean;
  constructor() {}

  ngOnInit(): void {}
}
