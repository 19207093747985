import { Injectable } from '@angular/core';
 
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventLabel: string | null = null,
    eventValue: number | null = null
  ) {
    /*     gtag('event', 'Aktion', {
      event_Category: 'Kateogrie',
      event_label: 'label',
      value: eventValue,
    }); */

    gtag('event', eventCategory, {
      event_category: eventName,
      event_label: eventLabel,
      value: eventValue,
    });
  }

  loadGoogleAnalyticsScript(): void {
    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=G-GK89BMTPDJ`
    );

    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'G-GK89BMTPDJ\');`;

    if (document.documentElement.firstChild) {
      document.documentElement.firstChild.appendChild(gaScript);
      document.documentElement.firstChild.appendChild(gaScript2);
    }
  }
}
