import { CdkDragExit } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { blockList } from 'src/app/blocks/blockList';
import { generateItems } from 'src/app/dnd-utils';
import { Block } from 'src/app/models/block.model';
import { User } from 'src/app/models/user.model';
import { BlockService } from 'src/app/services/block.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-details-blocks',
  templateUrl: './details-blocks.component.html',
  styleUrls: ['./details-blocks.component.scss'],
})
export class DetailsBlocksComponent implements OnInit {
  blockList: Block[] = blockList;
  items: any;
  user: any;
  transferringItem: string | undefined = undefined;

  constructor(
    private blockService: BlockService,
    private userService: UserService
  ) {
    this.userService.userDataSubject.subscribe(async (user: User) => {
      if (user && this.user !== null) {
        this.user = user;
        this.items = generateItems(blockList);
        console.log('this.items', this.items);
        this.blockList = this.filterWestnetzBlock(this.blockList);
        console.log('this.items', this.items);
      }
    });
    this.getChildPayload1 = this.getChildPayload1.bind(this);
  }

  // allow westnetz block just for users with the email domain @zewotherm.de, @zw-systems.de, @klicklead.de
  filterWestnetzBlock(items: any) {
    if (
      this.user.email &&
      (this.user.email.includes('@zewotherm.de') ||
        this.user.email.includes('@zw-systems.de') ||
        this.user.email.includes('@klicklead.de'))
    ) {
      return items;
    } else {
      return items.filter((item: any) => item.type !== 'zw_westnetz');
    }
  }

  ngOnInit(): void {}

  getBlockName(type: string) {
    return this.blockService.getBlockName(type);
  }

  getBlockIcon(icon: string) {
    return this.blockService.getBlockIcon(icon);
  }

  getChildPayload1(index: number) {
    return this.items[index];
  }

  onSourceListEntered() {
    this.transferringItem = undefined;
  }

  onSourceListExited(e: any) {
    this.transferringItem = e.item.data.type;
  }
}
