import { Component, Input, OnInit } from '@angular/core';
import { Block, BlockStyle } from 'src/app/models/block.model';

@Component({
  selector: 'app-socialmedia',
  templateUrl: './socialmedia.component.html',
  styleUrls: ['./socialmedia.component.scss'],
})
export class SocialmediaComponent implements OnInit {
  @Input() block: Block;
  @Input() style: BlockStyle;
  constructor() {}

  ngOnInit(): void {}

  getSocialMediaIcon(type: string) {
    switch (type) {
      case 'facebook':
        return 'bi bi-facebook';
      case 'twitter':
        return 'bi bi-twitter';
      case 'instagram':
        return 'bi bi-instagram';
      case 'youtube':
        return 'bi bi-youtube';
      case 'linkedin':
        return 'bi bi-linkedin';
      case 'pinterest':
        return 'bi bi-pinterest';
      case 'vimeo':
        return 'bi bi-vimeo';
      case 'github':
        return 'bi bi-github';
      case 'dribbble':
        return 'bi bi-dribbble';

      default:
        return '';
    }
  }

  getSocialMediaStyle(type: string) {
    let style = this.style;

    switch (type) {
      case 'facebook':
        style = { ...style, color: '#3b5998' };
        break;
      case 'twitter':
        style = { ...style, color: '#00aced' };
        break;
      case 'instagram':
        style = { ...style, color: '#bc2a8d' };
        break;
      case 'youtube':
        style = { ...style, color: '#bb0000' };
        break;
      case 'linkedin':
        style = { ...style, color: '#007bb6' };
        break;
      case 'pinterest':
        style = { ...style, color: '#cb2027' };
        break;
      case 'vimeo':
        style = { ...style, color: '#1ab7ea' };
        break;
      case 'github':
        style = { ...style, color: '#000000' };
        break;
      case 'dribbble':
        style = { ...style, color: '#ea4c89' };
        break;

      default:
        style.fontColor = '#000000';
        break;
    }
    return style;
  }
}
