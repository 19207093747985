<div style="display: flex; flex-direction: column">
  <button style="align-self: flex-end" mat-dialog-close>
    <i class="bi bi-x-lg"></i>
  </button>
  <div style="white-space: normal">
    <h2 class="font-bold text-lg">
      {{ data.type === "imprint" ? "Impressum" : "Datenschutzerklärung" }}
    </h2>
    <p class="text-base whitespace-pre-wrap">{{ data.name }}</p>
  </div>
</div>
