<!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="!selectedBulletpointItem">
  <div class="flow-root my-6">
    <ul role="list" class="-my-5 divide-y divide-gray-200">
      <li
        class="py-4 cursor-pointer group"
        *ngFor="let item of items; let i = index"
      >
        <div class="flex flex-col">
          <div class="flex-1 min-w-0 mb-2 cursor-pointer">
            <div class="flex justify-between items-center">
              <div class="flex space-x-8">
                <p
                  (click)="openItem(item, i)"
                  class="text-sm font-medium text-gray-900 truncate"
                  [innerHTML]="item.text"
                ></p>
                <div class="flex space-x-2">
                  <i
                    (click)="moveUp(i)"
                    class="bi bi-chevron-up opacity-0 group-hover:opacity-100 cursor-pointer"
                  ></i>
                  <i
                    (click)="moveDown(i)"
                    class="bi bi-chevron-down opacity-0 group-hover:opacity-100 cursor-pointer"
                  ></i>
                </div>
              </div>

              <i
                (click)="openItem(item, i)"
                class="bi bi-chevron-right cursor-pointer mr-2"
              ></i>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="mt-6">
    <a
      (click)="addItem()"
      class="w-full cursor-pointer flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      i18n="@@funnelSidebarDetailBulletpoints_add_label"
    >
      <i class="mr-1 bi bi-plus-circle"></i> hinzufügen
    </a>
  </div>
</div>
<app-details-bulletpoints-item
  class="overflow-auto"
  *ngIf="selectedBulletpointItem"
  [bulletpoint]="selectedBulletpointItem"
  [bulletpointIndex]="selectedBulletpointItemIndex"
  (onShowBulletpoints)="selectedBulletpointItem = null"
  (onRemoveItem)="removeItem(selectedBulletpointItemIndex)"
></app-details-bulletpoints-item>
