<div [ngStyle]="style" class="flex flex-wrap justify-center w-full">
  <div
    class="mx-3 my-3"
    *ngFor="let answer of block.content.answers; let answerIndex = index"
  >
    <div
      (click)="selectAnswerButton(answerIndex)"
      class="flex flex-col items-center w-40 h-full border border-gray-200 rounded shadow-xl justify-evenly"
      [ngClass]="{
        'justify-between ': answer.type === 'picture',
        'justify-evenly': answer.type === 'icon',
        ' border-2 border-green-300 ':
          block.content.isMultipleChoice &&
          selectedAnswers.includes(answerIndex)
      }"
      [ngStyle]="getQuizButtonStyle(answerIndex, blockIndex)"
    >
      <div class="relative flex flex-col items-center justify-between w-full">
        <svg
          *ngIf="
            selectedAnswers.includes(answerIndex) &&
            block.content.isMultipleChoice
          "
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="absolute text-green-400 bg-white rounded-full right-4 top-2 bi bi-check-circle-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
          />
        </svg>
        <img
          *ngIf="answer.type === 'picture'"
          [src]="getSantizeUrl(answer.answerPicture)"
          class="object-cover w-full h-24 rounded-t"
          [ngStyle]="getQuizPictureStyle(answerIndex, blockIndex)"
        />

        <div
          *ngIf="answer.type === 'icon'"
          class="flex items-center justify-center h-24"
          [ngStyle]="getQuizIconStyle(answerIndex, blockIndex)"
        >
          <i class="" [ngClass]="answer.answerIcon"> </i>
        </div>
      </div>
      <link [href]="getFontCSS(answerIndex, blockIndex)" rel="stylesheet" />
      <div
        class="flex items-start justify-center w-full h-full p-2 rounded-b"
        [ngStyle]="getQuizTextStyle(answerIndex, blockIndex)"
      >
        <div
          contenteditable="true"
          [innerHTML]="answer.answerText"
          class="w-full px-2 text-center bg-transparent border-transparent focus:border-transparent focus:ring-0"
          (blur)="changed($event)"
        ></div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="block.content.isMultipleChoice"
  class="flex justify-center w-full px-5"
>
  <button
    class="w-full py-2 my-1 text-sm text-gray-800 transition duration-150 ease-in-out bg-white border border-gray-300 rounded hover:bg-gray-100 focus:outline-none"
    [ngStyle]="getMultipleChoiceButtonStyle()"
    [ngClass]="{ 'w-full': mobileView, 'w-1/3': !mobileView }"
  >
    <div
      contenteditable="true"
      [innerHTML]="
        block.content.multipleChoiceButton
          ? block.content.multipleChoiceButton.text
          : 'Weiter'
      "
      class="w-full px-2 mx-auto bg-transparent border-transparent focus:border-transparent focus:ring-0"
      [id]="block.id"
    ></div>
  </button>
</div>
