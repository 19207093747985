import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'video',
})
export class VideoPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string): SafeResourceUrl {
    const videoType = this.getVideoProviderType(url);
    let videoLink = '';
    switch (videoType) {
      case 'youtube':
        const youtubeId = this.getYoutubeId(url);
        videoLink = 'https://www.youtube-nocookie.com/embed/' + youtubeId;
        break;
      case 'vimeo':
        var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
        var match = url.match(regExp);
        if (!match) return '';

        videoLink = 'https://player.vimeo.com/video/' + match[2];

        break;
      default:
        break;
    }

    return this.getSantizeUrl(videoLink);
  }

  getVideoProviderType(url: string) {
    // - Supported YouTube URL formats:
    //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
    //   - http://youtu.be/My2FRPA3Gf8
    //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
    // - Supported Vimeo URL formats:
    //   - http://vimeo.com/25451551
    //   - http://player.vimeo.com/video/25451551
    // - Also supports relative URLs:
    //   - //player.vimeo.com/video/25451551

    let type: string = '';
    if (url.indexOf('youtube.com') > 0 || url.indexOf('youtu.be') > 0) {
      type = 'youtube';
    }
    if (url.indexOf('vimeo.com') > 0 || url.indexOf('player.vimeo.com') > 0) {
      type = 'vimeo';
    }

    return type;
  }

  getYoutubeId(url: string) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
