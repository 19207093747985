import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { BlockActionType } from 'src/app/enums/block-action-type';
import {
  Answer,
  Block,
  BlockAction,
  QuizMultipleChoiceButton,
} from 'src/app/models/block.model';
import { Page } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-details-quiz-item',
  templateUrl: './details-quiz-item.component.html',
  styleUrls: ['./details-quiz-item.component.scss'],
})
export class DetailsQuizItemComponent implements OnInit {
  state$: Subscription;

  @Input() answer: any;
  @Input() answerIndex: number;
  @Input() answerType: string;
  @Output() onShowAnswers = new EventEmitter();
  @Output() onRemoveItem = new EventEmitter();
  @Output() onSetStyleToAllAnswers = new EventEmitter();

  tabIndex: number = 0;
  actions: string[] = ['Nächste Seite', 'Andere Seite', 'Link'];

  pages: Page[] = [];
  constructor(private appService: AppService) {
    /*  this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.pages = state.editor.currentProject.pages;
        console.log(this.pages);
      }
    }); */
  }

  ngOnInit(): void {
    this.pages = this.appService.state.editor.currentProject.pages;
  }

  goBack() {
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            selectedAnswer: null,
          },
        },
      },
    });
    this.onShowAnswers.emit();
  }

  updateItem(event: any) {
    if (this.answerType === 'answer') {
      this.answer = this.answer as Answer;
      this.answer.answerText = event.target.innerHTML;
      this.appService.setShouldSave(true);
    }
    if (this.answerType === 'button') {
      this.answer = this.answer as QuizMultipleChoiceButton;
      this.answer.text = event.target.innerHTML;
      this.appService.setShouldSave(true);
    }
  }

  setMargin(answer: Answer) {
    console.log(answer);
    this.answer = answer;
    this.appService.setShouldSave(true);
  }

  updateLink(link: string) {
    if (this.answer.action) {
      this.answer.action.link = link;
    }
    this.appService.setShouldSave(true);
  }

  setFontColor(color: string) {
    this.answer.style!.fontColor = color;
    this.appService.setShouldSave(true);
  }

  setFontSize(fontSize: number) {
    this.answer.style!.fontSize = fontSize;
    this.appService.setShouldSave(true);
  }

  setBackgroundColor(color: string) {
    this.answer.style!.backgroundColor = color;
    this.appService.setShouldSave(true);
  }

  setQuizCardBackgroundColor(color: string) {
    this.answer.style!.quizButtonColor = color;
    this.appService.setShouldSave(true);
  }

  setIconSize(size: number) {
    this.answer.style!.iconSize = size;
    this.appService.setShouldSave(true);
  }

  toggleType(type: string) {
    this.answer.type = type;
    if (type === 'picture') this.tabIndex = 1;
    this.appService.setShouldSave(true);
  }

  setImage(image: string) {
    this.answer.answerPicture = image;
    this.appService.setShouldSave(true);
  }

  setIcon(icon: string) {
    this.answer.answerIcon = icon;
    this.appService.setShouldSave(true);
  }

  getActionTypeString() {
    switch (this.answer.action?.type) {
      case BlockActionType.NEXTPAGE: {
        return 'Nächste Seite';
      }
      case BlockActionType.CONNECTEDPAGE: {
        return 'Andere Seite';
      }
      case BlockActionType.LINK: {
        return 'Link';
      }
    }
    this.appService.setShouldSave(true);

    return '';
  }

  removeItem() {
    this.appService.setShouldSave(true);

    this.onRemoveItem.emit(this.answerIndex);
  }

  setAnswerAction(action: string) {
    console.log(action);
    switch (action) {
      case 'Nächste Seite':
        {
          const blockAction: BlockAction = {
            type: BlockActionType.NEXTPAGE,
          };
          this.answer.action = blockAction;
        }
        break;
      case 'Andere Seite':
        {
          const blockAction: BlockAction = {
            type: BlockActionType.CONNECTEDPAGE,
          };
          this.answer.action = blockAction;
        }
        break;
      case 'Link': {
        const blockAction: BlockAction = {
          type: BlockActionType.LINK,
        };
        this.answer.action = blockAction;

        break;
      }
    }
    this.appService.setShouldSave(true);
  }

  setStyleToAllAnswers() {
    this.onSetStyleToAllAnswers.emit(this.answer);
    this.appService.setShouldSave(true);
  }

  unsetImage() {
    this.answer.answerPicture = '';
    this.answer.type = 'icon';
    this.answer.answerIcon = 'fas fa-rocket';
    this.appService.setShouldSave(true);
  }

  setConnectedPage(page: Page) {
    if (this.answer.action) {
      this.answer.action.connectedPage = page.id;
    }

    this.appService.setShouldSave(true);
  }

  setTabIndex(index: number) {
    this.tabIndex = index;
  }
}
