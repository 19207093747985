// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCBuJQbHmur3t0mWmVnodMSnElCpnVnzWU',
    authDomain: 'funnelbuilder-dev.firebaseapp.com',
    projectId: 'funnelbuilder-dev',
    storageBucket: 'funnelbuilder-dev.appspot.com',
    messagingSenderId: '534215768942',
    appId: '1:534215768942:web:058683376eba700ff2df7f',
  },
  appVersion: require('../../package.json').version + '-dev',
  recaptchaKey: '6LdHYlodAAAAAGQslJbqg9Xg-idXsQQXdLLam1Dk',
  backendUrl: 'https://klic.onrender.com',
  frontendUrl: 'http://localhost:4200',
  vercelToken: '3x6dQ24zOaBExlKjMQaUuzLO',
  cryptoPass: 'HeikoOliEugen',
  priceIds: {
    basic_monthly: 'price_1KxSEeL8SlH6dpQ2Mj5a1T7A',
    basic_yearly: 'price_1KxSEeL8SlH6dpQ2ZIE2FOdd',
    professional_monthly: 'price_1KxSEeL8SlH6dpQ2CoUad377',
    professional_yearly: 'price_1KxSEeL8SlH6dpQ2b2qyUWUg',
    premium_monthly: 'price_1KxSEeL8SlH6dpQ2xMwQ9dKw',
    premium_yearly: 'price_1KxSEeL8SlH6dpQ26bMTcsRb',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
