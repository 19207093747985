import { Component, Input, OnInit } from '@angular/core';
import { TextfieldType } from 'src/app/enums/textfield-type';
import { FormBlock, InputField } from 'src/app/models/block.model';
import { Project } from 'src/app/models/project.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-details-form-items',
  templateUrl: './details-form-items.component.html',
  styleUrls: ['./details-form-items.component.scss'],
})
export class DetailsFormItemsComponent implements OnInit {
  @Input() selectedFormItem: InputField | null;
  selectedFormItemType: string | null;
  selectedFormItemIndex: number = -1;
  @Input() items: InputField[] = [];
  @Input() privacy: any;
  @Input() submitButton: any;

  @Input() project: Project;
  constructor(private appService: AppService) {}

  ngOnInit(): void {}

  openItem(inputField: InputField, index: number) {
    this.selectedFormItemType = null;
    this.selectedFormItem = inputField;
    this.selectedFormItemIndex = index;
    this.appService.stateSubject.next({
      ...this.appService.state,

      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            selectedInputField: inputField,
          },
        },
      },
    });
  }

  openPrivacy() {
    this.selectedFormItem = this.privacy;
    this.selectedFormItemType = 'privacy';
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            selectedInputField: this.selectedFormItem,
          },
        },
      },
    });
  }

  openButton() {
    this.selectedFormItem = this.submitButton;
    this.selectedFormItemType = 'submitButton';

    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            selectedInputField: this.selectedFormItem,
          },
        },
      },
    });
  }

  showForm() {
    this.selectedFormItem = null;
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            selectedInputField: this.selectedFormItem,
          },
        },
      },
    });
  }

  showFormItem() {
    let returnValue: boolean = false;

    if (this.selectedFormItem || this.selectedFormItem !== null) {
      returnValue = true;
    }

    return returnValue;
  }

  addItem() {
    let formElememt: InputField = {
      type: TextfieldType.TEXT,
      text: 'Text',
      isIcon: true,
      icon: 'fas fa-chevron-circle-right',
      required: true,
      value: null,
    };

    this.items.push(formElememt);
    this.appService.setShouldSave(true);
  }

  removeItem() {
    this.items.splice(this.selectedFormItemIndex, 1);
    this.selectedFormItem = null;
    this.appService.setShouldSave(true);
  }

  moveUp(index: number) {
    if (index > 0) {
      let item = this.items[index];
      this.items.splice(index, 1);
      this.items.splice(index - 1, 0, item);
    }
    this.appService.setShouldSave(true);
  }

  moveDown(index: number) {
    if (index < this.items.length - 1) {
      let item = this.items[index];
      this.items.splice(index, 1);
      this.items.splice(index + 1, 0, item);
    }
    this.appService.setShouldSave(true);
  }

  setCustobarType(type: string) {
    this.project.custobarType = type;
    this.appService.setShouldSave(true);
  }

  setCustobarCompanySpecificField(type: string) {
    this.project.custobarCompanySpecificField = type;

    this.appService.setShouldSave(true);
  }

  setCustobarCompanySpecificFieldValue(value: string) {
    this.project.custobarCompanySpecificFieldValue = value;

    this.appService.setShouldSave(true);
  }
}
