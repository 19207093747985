import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Router } from '@angular/router';
import {
  Project,
  AnswerSubmission,
  PageAnswers,
} from 'src/app/models/project.model';

import { InputField } from 'src/app/models/block.model';
import { UserService } from 'src/app/services/user.service';
import { ProjectService } from 'src/app/services/project.service';
import { LanguageService } from 'src/app/services/lang.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';
import { Tier, User } from 'src/app/models/user.model';
import { uuidv4 } from 'src/app/services/uuid';
import { starterBlock } from 'src/app/blocks/starterBlock';
import { RenameProjectDialogComponent } from 'src/app/components/modals/rename-project-dialog/rename-project-dialog.component';
import { NewFunnelTemplateComponent } from 'src/app/components/new-funnel-template/new-funnel-template.component';
import { AppService } from 'src/app/services/app.service';
import { FunnelTemplatesModalComponent } from 'src/app/components/modals/funnel-templates-modal/funnel-templates-modal.component';
import { isUserInTrialAllowed } from 'src/app/trialCheck';
import { TierService } from 'src/app/services/tier.service';
import { InfoModalComponent } from 'src/app/components/modals/info-modal /info-modal .component';
import { OnboardingComponent } from 'src/app/components/modals/onboarding/onboarding.component';
import { first, take } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MonthlyImpressionsService } from 'src/app/services/monthly-impressions.service';

@Component({
  selector: 'app-funnel-list',
  templateUrl: './funnel-list.component.html',
  styleUrls: ['./funnel-list.component.scss'],
})
export class FunnelListComponent implements OnInit, OnDestroy {
  projects: any[] = [];
  filteredProjects: Project[] = [];
  projectLeads: { [key: string]: number } = {};
  projectPageViews: { [key: string]: number } = {};
  projectConvRates: { [key: string]: number } = {};
  wording: any = {};
  maxFunnels: number;
  userSub$: Subscription;
  user: User;
  currentProject: Project;
  searchString: string = '';
  projectResult: Subscription;
  viewMode: string = 'card';
  sortHeadingOrder: any = {
    name: 'asc',
    status: 'asc',
    impressions: 'asc',
    leads: 'asc',
    conversion: 'asc',
  };
  loading: boolean = true;
  monthlyImpressions: number = 0;
  maxImpressions: number = 0;
  constructor(
    private userService: UserService,
    private router: Router,
    private projectService: ProjectService,
    public langService: LanguageService,
    private dialog: MatDialog,
    private appService: AppService,
    private tierService: TierService,
    private localStorage: LocalStorageService,
    private monthlyImpressionsService: MonthlyImpressionsService
  ) {}

  async ngOnInit() {
    this.appService.showHeaderSubject.next(true);

    this.projectService.currentProject = <Project>{};
    this.appService.stateSubject.next({
      ...this.appService.stateSubject.getValue(),
      editor: {
        ...this.appService.stateSubject.getValue().editor,
        currentProject: <Project>{},
      },
    });

    this.viewMode = this.localStorage.get('viewMode') || 'card';

    // await this.userService.loadUser();
    // pipe subscription while user is not set
    this.projectResult = this.userService.userDataSubject.subscribe(
      async (user: User) => {
        if (user && this.user !== null) {
          this.user = user;

          this.user = user;
          this.loading = true;
          this.projects = await this.projectService.getProjectList(
            user.funnelIds
          );

          this.monthlyImpressions =
            await this.monthlyImpressionsService.getMonthlyImpressionsByUserId(
              this.user.id
            );

          console.log(this.monthlyImpressions);

          console.log(this.projects);
          this.filteredProjects = this.projects;

          // sort projects by createdAt descending
          this.filteredProjects.sort((a: Project, b: Project) => {
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            if (a.createdAt > b.createdAt) {
              return -1;
            }
            return 0;
          });

          console.log(this.projects);

          for (let funnelId of user.funnelIds) {
            const project = this.projects.find((p) => p.id === funnelId);
            if (project.leadCount || project.impressions) {
              this.projectLeads[project.id] = project.leadCount
                ? project.leadCount
                : 0;
              this.projectPageViews[project.id] = project.impressions
                ? project.impressions
                : 0;
              this.projectConvRates[project.id] = this.getConverationRate(
                project.id,
                project.impressions,
                project.leadCount
              );
            } else {
              this.projectLeads[project.id] = 0;
              this.projectPageViews[project.id] = 0;
              this.projectConvRates[project.id] = 0;
            }
          }

          if (!this.user.tier) {
            this.user.tier = Tier.TRIAL;
            this.maxFunnels = this.tierService.getRights(
              'maxFunnels',
              this.user.tier
            );
            this.maxImpressions = this.tierService.getRights(
              'maxImpressions',
              this.user.tier
            );
            await this.userService.setUser(this.user);
          } else {
            if (
              user.email.includes('@zewotherm.de') ||
              user.email.includes('@zw-systems.de') ||
              user.email.includes('@klicklead.de')
            ) {
              this.maxFunnels = 9999;
            } else {
              this.maxFunnels = this.tierService.getRights(
                'maxFunnels',
                this.user.tier
              );
              this.maxImpressions = this.tierService.getRights(
                'maxImpressions',
                this.user.tier
              );
            }
          }
          this.loading = false;

          if (!isUserInTrialAllowed(user)) {
            this.router.navigateByUrl('/user-settings?payment=true');
          }
        }
      }
    );

    if (!this.user) {
      const authUser = await this.userService.getUserProfileInformation();
      this.user = await this.userService.getUserById(authUser!.uid);
      if (
        this.user.showOnboarding ||
        typeof this.user.showOnboarding === 'undefined'
      ) {
        this.showOnboardingDialog();
      }
    }

    this.projectService.currentProjectSubject.next(null);
  }

  setViewMode(mode: string) {
    this.viewMode = mode;
    this.localStorage.set('viewMode', mode);
  }

  openPaymentPage() {
    this.router.navigateByUrl('/user-settings?payment=true');
  }

  getConverationRate(projectId: string, impressions: any, leads: number) {
    if (impressions !== 0) {
      let returnVal = ((leads * 100) / impressions).toFixed(2);

      if (returnVal === 'NaN') {
        return 0;
      } else {
        return parseInt(returnVal);
      }
    }
    return 0;
  }

  getProjectLeadsOfAllProjects() {
    let leadCount = 0;

    for (let funnel of Object.values(this.projectLeads)) {
      leadCount += funnel;
    }

    return leadCount;
  }

  getConversionRateOfAllProjects() {
    let convRate = 0;
    let pageViews = 0;

    for (let funnel of this.filteredProjects) {
      convRate += this.projectConvRates[funnel.id];
    }

    let returnVal = (convRate / this.filteredProjects.length).toFixed(2);

    if (returnVal === 'NaN') {
      return 0;
    } else {
      return parseInt(returnVal);
    }
  }

  getProjectNameMostLeads() {
    let maxLeads = 0;
    let funnelName = '';

    for (let funnel of Object.keys(this.projectLeads)) {
      if (this.projectLeads[funnel] > maxLeads) {
        maxLeads = this.projectLeads[funnel];
        funnelName = funnel;
      }
    }

    return this.filteredProjects.find((p) => p.id === funnelName)
      ? this.filteredProjects.find((p) => p.id === funnelName)!.name
      : '';
  }

  showOnboardingDialog() {
    const dialogRef = this.dialog.open(OnboardingComponent, {
      disableClose: true,
      panelClass: ['xl:w-3/5', 'w-full'],
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.userService.userDataSubject.next({
        ...this.user,
        showOnboarding: false,
      });
    });
  }

  openFunnelTemplatesModal() {
    // check if user has reached max funnels but exclude the following emails from this check (for testing purposes) -> @zewotherm.de, @zw-systems.de, @klicklead.de
    if (
      this.maxFunnels > 0 &&
      this.filteredProjects.length >= this.maxFunnels &&
      !this.user.email.includes('@zewotherm.de') &&
      !this.user.email.includes('@zw-systems.de') &&
      !this.user.email.includes('@klicklead.de')
    ) {
      const dialogRef = this.dialog.open(InfoModalComponent, {
        width: '350px',
        data: {
          header: 'Maximale Anzahl von Funnels erreicht',
          description:
            'Sie haben die maximale Anzahl von Funnels erreicht. Bitte buchen Sie ein höheres Preispaket.',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigateByUrl('/user-settings?payment=true');
        }
      });
    } else {
      const dialogRef = this.dialog.open(FunnelTemplatesModalComponent, {
        panelClass: 'bg-gray-100',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        data: {},
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (typeof result !== 'undefined') {
        }
      });
    }
  }

  async createFunnel() {
    if (!this.projectService.currentProject) return;
    if (!this.userService.userData) {
      const userAuth = await this.userService.getUserProfileInformation();
      if (!userAuth) return;
      this.user = await this.userService.getUserById(userAuth.uid);
    }

    await this.projectService.createProject(
      'Neuer Funnel',
      [
        {
          id: uuidv4(),
          blocks: JSON.parse(JSON.stringify(starterBlock)),
          name: this.langService.getWording('funnelEditor', 'page') + ' 1',
          style: { containerBackgroundColor: '#F2F2F2' },
        },
      ],
      { containerBackgroundColor: '#000000' },
      { available: true, color: '#008000' },
      this.user
    );

    this.router.navigate([
      'funnel-editor/' + this.projectService.currentProject.id,
    ]);
  }

  filterFunnels() {
    this.filteredProjects = this.projects.filter((project: Project) => {
      return (
        project.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1
      );
    });
  }

  openFunnel(projectId: string) {
    this.router.navigate(['funnel-editor/' + projectId]);
  }

  openStatistic(projectId: string) {
    this.router.navigate(['statistics/' + projectId]);
  }

  openSettings(projectId: string) {
    this.router.navigate(['funnel-settings/' + projectId]);
  }

  async renameFunnel(projectId: string) {
    let renameFunnel: Project = await this.projectService.getProjectById(
      projectId
    );

    const dialogRef = this.dialog.open(RenameProjectDialogComponent, {
      width: '350px',
      data: { name: renameFunnel.name },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (typeof result !== 'undefined') {
        renameFunnel.name = result;
        this.projects[
          this.projects.findIndex((p) => p.id === renameFunnel.id)
        ].name = result;
        this.projects[
          this.projects.findIndex((p) => p.id === renameFunnel.id)
        ].status = renameFunnel.status;
        this.projectService.currentProjectSubject.next(renameFunnel);
        this.projectService.saveCurrentProject();
      }
    });
  }

  async copyFunnel(project: Project) {
    let funnelCopy: Project = await this.projectService.getProjectById(
      project.id
    );
    await this.projectService.createProject(
      'Kopie von ' + funnelCopy.name,
      funnelCopy.pages,
      funnelCopy.style,
      funnelCopy.progressbar,
      this.user,
      funnelCopy
    );

    this.projects = await this.projectService.getProjectList(
      this.user.funnelIds
    );

    this.user.funnelIds.forEach(async (id: any) => {
      this.projectLeads[id] = await (await this.getLeads(id)).leads;
    });
    this.projectService.currentProjectSubject.next(null);
  }

  deleteFunnel(project: Project) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px',
      data: {
        title: 'Funnel löschen',
        text: 'Möchtest Du den Funnel löschen?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const projectIndex = this.projects.findIndex(
          (p) => p.id === project.id
        );
        this.projects.splice(projectIndex, 1);
        this.projectService.deleteProject(project.id);
      }
    });
  }

  async getLeads(projectId: string) {
    let data: any[] = [];
    let answerSubmissions: AnswerSubmission[];

    answerSubmissions = await this.projectService.getLeads(projectId);

    if (typeof answerSubmissions !== 'undefined') {
      answerSubmissions.forEach((as: AnswerSubmission) => {
        as.submissions.forEach((sub: PageAnswers) => {
          if (Array.isArray(sub.answer)) {
            let subArray = [];
            sub.answer.forEach((inp: InputField) => {
              if (inp.value !== null) {
                subArray.push({ [inp.text]: inp.value });
              }
            });

            if (subArray.length !== 0) {
              let object = {};
              subArray.push({ answerSubmission: as });
              subArray.forEach(function (element: any, index: number) {
                Object.assign(object, element);
              });
              // Object.assign(object, { nr: data.length + 1 })
              let dateString = this.langService.getWording(
                'funnelStatistics',
                'tableDate'
              );

              Object.assign(object, {
                [dateString.toString()]: as.date,
              });

              data.push(object);
            }
          }
        });
      });
    }

    let conversionRate = (data.length * 100) / answerSubmissions.length;

    if (isNaN(conversionRate)) {
      conversionRate = 0;
    }

    const output = {
      leads: data.length,
      pageViews: answerSubmissions.length,
      conversionRate: conversionRate,
    };

    return output;
  }

  openTemplate() {
    const dialogRef = this.dialog.open(NewFunnelTemplateComponent, {
      panelClass: 'bg-gray-100',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (typeof result !== 'undefined') {
      }
    });
  }

  sortBy(sortString: string) {
    switch (sortString) {
      case 'name':
        if (this.sortHeadingOrder.name === 'asc') {
          this.filteredProjects.sort((a: Project, b: Project) => {
            if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
              return -1;
            }
            if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
              return 1;
            }
            return 0;
          });
          this.sortHeadingOrder.name = 'desc';
        } else {
          this.filteredProjects.sort((a: Project, b: Project) => {
            if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
              return -1;
            }
            if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
              return 1;
            }
            return 0;
          });
          this.sortHeadingOrder.name = 'asc';
        }
        break;

      case 'status':
        if (this.sortHeadingOrder.status === 'asc') {
          this.filteredProjects.sort((a: Project, b: Project) => {
            if (a.status < b.status) {
              return -1;
            }
            if (a.status > b.status) {
              return 1;
            }
            return 0;
          });
          this.sortHeadingOrder.status = 'desc';
        } else {
          this.filteredProjects.sort((a: Project, b: Project) => {
            if (a.status > b.status) {
              return -1;
            }
            if (a.status < b.status) {
              return 1;
            }
            return 0;
          });
          this.sortHeadingOrder.status = 'asc';
        }
        break;

      case 'impressions':
        if (this.sortHeadingOrder.impressions === 'asc') {
          this.filteredProjects.sort((a: Project, b: Project) => {
            const impressionsA = this.projectPageViews[a.id];
            const impressionsB = this.projectPageViews[b.id];

            if (impressionsA < impressionsB) {
              return -1;
            }
            if (impressionsA > impressionsB) {
              return 1;
            }
            return 0;
          });
          this.sortHeadingOrder.impressions = 'desc';
        } else {
          this.filteredProjects.sort((a: Project, b: Project) => {
            const impressionsA = this.projectPageViews[a.id];
            const impressionsB = this.projectPageViews[b.id];

            if (impressionsA > impressionsB) {
              return -1;
            }
            if (impressionsA < impressionsB) {
              return 1;
            }
            return 0;
          });
          this.sortHeadingOrder.impressions = 'asc';
        }
        break;

      case 'leads':
        if (this.sortHeadingOrder.leads === 'asc') {
          this.filteredProjects.sort((a: Project, b: Project) => {
            const leadsA = this.projectLeads[a.id];
            const leadsB = this.projectLeads[b.id];

            if (leadsA < leadsB) {
              return -1;
            }
            if (leadsA > leadsB) {
              return 1;
            }
            return 0;
          });
          this.sortHeadingOrder.leads = 'desc';
        } else {
          this.filteredProjects.sort((a: Project, b: Project) => {
            const leadsA = this.projectLeads[a.id];
            const leadsB = this.projectLeads[b.id];

            if (leadsA > leadsB) {
              return -1;
            }
            if (leadsA < leadsB) {
              return 1;
            }
            return 0;
          });
          this.sortHeadingOrder.leads = 'asc';
        }
        break;
      case 'conversion':
        if (this.sortHeadingOrder.conversion === 'asc') {
          this.filteredProjects.sort((a: Project, b: Project) => {
            const conversionA = this.projectConvRates[a.id];
            const conversionB = this.projectConvRates[b.id];

            if (conversionA < conversionB) {
              return -1;
            }
            if (conversionA > conversionB) {
              return 1;
            }
            return 0;
          });
          this.sortHeadingOrder.conversion = 'desc';
        } else {
          this.filteredProjects.sort((a: Project, b: Project) => {
            const conversionA = this.projectConvRates[a.id];
            const conversionB = this.projectConvRates[b.id];

            if (conversionA > conversionB) {
              return -1;
            }
            if (conversionA < conversionB) {
              return 1;
            }
            return 0;
          });
          this.sortHeadingOrder.conversion = 'asc';
        }
        break;
    }
  }

  resetSearch() {
    this.searchString = '';
    this.filteredProjects = this.projects;
  }

  ngOnDestroy() {
    this.projectResult.unsubscribe();
  }
}
