<div class="relative pt-1">
  <label for="customRange1" class="block text-sm font-medium text-gray-700">{{
    title
  }}</label>
  <input
    type="range"
    class="mt-2 form-range appearance-none w-full h-1 rounded-xl p-0 text-kl-purple bg-gray-200 focus:outline-none focus:ring-0 focus:shadow-none"
    id="customRange1"
    (input)="changed($event)"
    [value]="value"
    [max]="max"
    [min]="min"
  />
  <div class="flex justify-end items-center py-2">
    <div>
      <input
        type="text"
        value="20"
        maxlength="5"
        [(ngModel)]="value"
        (input)="changed($event)"
        class="px-2 py-1 border border-gray-200 rounded w-24 text-center"
      />
    </div>
  </div>
</div>
