<!-- This example requires Tailwind CSS v2.0+ -->
<div>
  <div class="flow-root mt-6">
    <ul role="list" class="-my-5 divide-y divide-gray-200">
      <li class="py-4 group" *ngFor="let item of items; let i = index">
        <div class="flex flex-col">
          <div class="flex-1 min-w-0 mb-2 cursor-pointer">
            <div class="flex justify-between items-center">
              <div class="flex space-x-8">
                <p
                  (click)="openItem(i)"
                  class="text-sm font-medium text-gray-900 truncate"
                >
                  {{ item.answerText }}
                </p>
                <div class="flex space-x-2">
                  <i
                    (click)="moveUp(i)"
                    class="bi bi-chevron-up opacity-0 group-hover:opacity-100 cursor-pointer"
                  ></i>
                  <i
                    (click)="moveDown(i)"
                    class="bi bi-chevron-down opacity-0 group-hover:opacity-100 cursor-pointer"
                  ></i>
                </div>
              </div>

              <i
                (click)="openItem(i)"
                *ngIf="isOpenIndex !== i"
                class="bi bi-chevron-down cursor-pointer mr-2"
              ></i>
              <i
                (click)="openItem(i)"
                *ngIf="isOpenIndex === i"
                class="bi bi-chevron-up cursor-pointer mr-2"
              ></i>
            </div>
          </div>
          <div
            *ngIf="isOpenIndex === i"
            class="flex flex-col items-end justify-end"
          >
            <app-text-input
              class="w-full"
              [value]="item.answerText"
              (valueChange)="updateItem($event, i)"
            ></app-text-input>

            <button
              (click)="removeItem(i)"
              type="button"
              class="my-6 inline-flex w-auto items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-800 focus:outline-none"
              i18n="@@funnelSidebarDetailSelection_delete_button"
            >
              Entfernen
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="mt-6">
    <a
      (click)="addItem()"
      class="w-full cursor-pointer flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      i18n="@@funnelSidebarDetailQuizItem_add_button"
    >
      <i class="mr-1 bi bi-plus-circle"></i> hinzufügen
    </a>
  </div>
</div>
