import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PricingItem, Tier, User } from 'src/app/models/user.model';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { MailMarketingService } from 'src/app/services/mail-marketing.service';
import { ProjectService } from 'src/app/services/project.service';
import { StripeDataService } from 'src/app/services/stripe-data.service';
import { UserService } from 'src/app/services/user.service';
import { DialogModalComponent } from '../modals/dialog-modal/dialog-modal.component';
import { PaymentCardComponent } from '../payment-card/payment-card.component';
import { pricingList } from '../shared/pricingItems';

@Component({
  selector: 'app-pricing-table',
  templateUrl: './pricing-table.component.html',
  styleUrls: ['./pricing-table.component.scss'],
})
export class PricingTableComponent implements OnInit {
  pricingList: PricingItem[] = pricingList;
  @Input() user: User;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  paymentInterval: string = 'monthly';
  showPaymentForm: boolean = false;
  invoices: any;
  constructor(
    private stripeDataService: StripeDataService,
    private dialog: MatDialog,
    private userService: UserService,
    private mailMarketingService: MailMarketingService,
    private projectService: ProjectService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  async ngOnInit() {
    this.googleAnalyticsService.loadGoogleAnalyticsScript();
  }

  // load all pricings from a product from the stripe api
  loadStripePricings(productId: string) {
    this.stripeDataService.loadStripePricings(productId).subscribe(
      (data) => {
        //this.pricings = data;
      },
      (error) => {}
    );
  }

  togglePaymentInterval() {
    this.paymentInterval =
      this.paymentInterval === 'monthly' ? 'yearly' : 'monthly';
  }

  closePricingTable() {
    this.onClose.emit();
  }

  async openPricingModal(pricing: PricingItem) {
    if (this.user.stripeCustomerId) {
      const dialogRef = this.dialog.open(DialogModalComponent, {
        data: {
          headline: 'Preis ändern',
          text: 'Möchtest du den Preis wirklich ändern?',
          buttonText: 'Ändern',
        },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          if (this.paymentInterval === 'monthly') {
            this.updateSubscription(pricing.monthlyPaymentPriceId);
            this.user.priceId = pricing.monthlyPaymentPriceId;
          } else {
            this.updateSubscription(pricing.yearlyPaymentPriceId);
            this.user.priceId = pricing.yearlyPaymentPriceId;
          }
          this.user.tier = pricing.tier;

          if (this.user.tier === Tier.BASIC) {
            for (let funnelId of this.user.funnelIds) {
              const funnel = await this.projectService.getProjectById(funnelId);
              if (funnel) {
                funnel['showBadge'] = true;
                funnel['embedEnabled'] = false;

                await this.projectService.saveProject(funnel);
              }
            }
          } else {
            for (let funnelId of this.user.funnelIds) {
              const funnel = await this.projectService.getProjectById(funnelId);
              if (funnel) {
                funnel['showBadge'] = false;
                funnel['embedEnabled'] = true;

                await this.projectService.saveProject(funnel);
              }
            }
          }

          this.user.paymentInterval = this.paymentInterval;
          this.user.cancelAt = '';

          this.googleAnalyticsService.eventEmitter(
            'Purchase',
            'Purchase',
            'klicklead.de'
          );

          await this.userService.setUser(this.user);
          await this.mailMarketingService.tagSubscriber(
            this.user.email,
            8636723
          );
          this.closePricingTable();
        }
      });
    } else {
      const dialogRef = this.dialog.open(PaymentCardComponent, {
        width: '50vw',

        panelClass: 'custom-modalbox',
        data: {
          user: this.user,
          pricing: pricing,
          paymentInterval: this.paymentInterval,
        },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.user.tier = pricing.tier;
          if (this.paymentInterval === 'monthly') {
            this.user.priceId = pricing.monthlyPaymentPriceId;
          } else {
            this.user.priceId = pricing.yearlyPaymentPriceId;
          }

          if (this.user.tier === Tier.BASIC || this.user.tier === Tier.TRIAL) {
            for (let funnelId of this.user.funnelIds) {
              const funnel = await this.projectService.getProjectById(funnelId);
              if (funnel) {
                funnel['showBadge'] = false;
                await this.projectService.saveProject(funnel);
              }
            }
          } else {
            for (let funnelId of this.user.funnelIds) {
              const funnel = await this.projectService.getProjectById(funnelId);
              if (funnel) {
                funnel['showBadge'] = false;
                await this.projectService.saveProject(funnel);
              }
            }
          }

          this.user.paymentInterval = this.paymentInterval;
          this.user.cancelAt = '';


          this.googleAnalyticsService.eventEmitter(
            'Purchase',
            'Purchase',
            'klicklead.de'
          );

          await this.userService.setUser(this.user);
          await this.mailMarketingService.tagSubscriber(
            this.user.email,
            8636723
          );

          this.closePricingTable();
        }
      });
    }
  }

  setUserSubscription() {
    this.user.tier = 'Basic';
  }

  openCustomerPortal() {
    this.stripeDataService
      .createCustomerPortalSession(this.user.stripeCustomerId!)
      .then((session: any) => {
        window.open(session.session.url, '_blank');
      });
  }

  // calculate the savings in percent for the yearly payment
  calulateYearlySaving(pricingItem: PricingItem) {
    const yearlyPrice = pricingItem.yearlyPrice;
    const monthlyPrice = pricingItem.monthlyPrice;
    const yearlySaving = ((monthlyPrice - yearlyPrice) / monthlyPrice) * 100;
    return Math.round(yearlySaving);
  }

  updateSubscription(priceId: string) {
    this.stripeDataService
      .updateSubscription(this.user.stripeCustomerId!, priceId)
      .then((data: any) => {
        console.log(data);
      });
  }
}
