import { Component, Input, OnInit } from '@angular/core';
import { BlockActionType } from 'src/app/enums/block-action-type';
import {
  Answer,
  Block,
  QuizBlock,
  QuizMultipleChoiceButton,
} from 'src/app/models/block.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-details-quiz-items',
  templateUrl: './details-quiz-items.component.html',
  styleUrls: ['./details-quiz-items.component.scss'],
})
export class DetailsQuizItemsComponent implements OnInit {
  @Input() selectedQuizItem: Answer | QuizMultipleChoiceButton | null = null;
  selectedQuizItemIndex: number;
  @Input() items: Answer[] = [];
  @Input() quizBlock: Block;

  selectedQuizItemType: string = '';
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.quizBlock.content.multipleChoiceButton = this.quizBlock.content
      .multipleChoiceButton || {
      text: 'Weiter',
      style: {},
      action: { type: BlockActionType.NEXTPAGE },
    };
  }

  openItem(answer: Answer, index: number) {
    this.selectedQuizItem = answer;
    this.selectedQuizItemIndex = index;
    this.selectedQuizItemType = 'answer';
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            selectedAnswer: answer,
          },
        },
      },
    });
  }

  openButton() {
    this.selectedQuizItemType = 'button';
    this.selectedQuizItemIndex = -1;
    this.selectedQuizItem = this.quizBlock.content.multipleChoiceButton || {
      text: 'Weiter',
      style: {},
      action: { type: BlockActionType.NEXTPAGE },
    };
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            selectedAnswer: this.selectedQuizItem,
          },
        },
      },
    });
  }

  setStyleToAllAnswers(answer: Answer) {
    this.items.forEach((item) => {
      item.style = answer.style;
    });

    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            selectedAnswer: answer,
          },
        },
      },
    });

    this.appService.setShouldSave(true);
  }

  setIsMultipleChoice(event: any) {
    this.quizBlock.content.isMultipleChoice = event;

    this.appService.setShouldSave(true);
  }

  addItem() {
    let answer: Answer = {
      answerText: 'Antwort ' + (this.items.length + 1),
      type: 'picture',
      answerPicture: 'https://source.unsplash.com/random/300x200',
      action: {
        type: BlockActionType.CONNECTEDPAGE,
      },
      style: {
        fontColor: '#ffffff',
        backgroundColor: '#4797ff',
        fontSize: 19,
      },
    };

    this.items.push(answer);
  }

  removeItem(index: number) {
    this.items.splice(index, 1);
    this.selectedQuizItem = null;
  }

  moveUp(index: number) {
    if (index > 0) {
      let item = this.items[index];
      this.items.splice(index, 1);
      this.items.splice(index - 1, 0, item);
    }
    this.appService.setShouldSave(true);
  }

  moveDown(index: number) {
    if (index < this.items.length - 1) {
      let item = this.items[index];
      this.items.splice(index, 1);
      this.items.splice(index + 1, 0, item);
    }
    this.appService.setShouldSave(true);
  }
}
