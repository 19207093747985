import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/app';
import { AnswerSubmission, Project } from 'src/app/models/project.model';
import { AppService } from 'src/app/services/app.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  leads: AnswerSubmission[] = [];
  todayLeads: AnswerSubmission[] = [];
  yesterdayLeads: AnswerSubmission[] = [];
  funnels: { [key: string]: Project } = {};
  showTable: boolean = false;
  funnelsByLeads: any[] = [];
  constructor(
    private projectService: ProjectService,
    private appService: AppService
  ) {}

  async ngOnInit() {
    this.appService.showHeaderSubject.next(false);
    this.leads = await this.projectService.getAllLeadsByDate(
      this.getDateByDaysAgo(30)
    );

    for (let lead of this.leads) {
      await this.loadFunnelsOfLead(lead);
    }
    this.showTable = true;

    console.log(this.leads);
    this.todayLeads = this.getLeadsFromToday();
    this.yesterdayLeads = this.getLeadsFromYesterday();

    console.log(this.getFunnelName(this.getMostUsedProject()));
    console.log(this.getProjectsByLeads());
    this.funnelsByLeads = this.getProjectsByLeads();
    console.log(this.todayLeads);
  }

  async loadFunnelsOfLead(lead: AnswerSubmission) {
    if (this.funnels[lead.projectId]) {
      this.funnels[lead.projectId].name;
    } else {
      await this.projectService
        .getProjectById(lead.projectId)
        .then((funnel) => {
          this.funnels[lead.projectId] = funnel;
        });
    }
  }

  getFunnelName(funnelId: string) {
    const funnel = this.funnels[funnelId];
    if (funnel) {
      return this.funnels[funnelId].name;
    }
    return '';
  }

  getLeadsFromToday() {
    return this.leads.filter((lead) => {
      const leadDate = new Date(
        lead.date.seconds * 1000 + lead.date.nanoseconds / 1000000
      );
      return new Date(leadDate).getDate() + 1 == new Date().getDate();
    });
  }

  getLeadsFromTodayByProject(projectId: string) {
    return this.leads.filter((lead) => {
      const leadDate = new Date(
        lead.date.seconds * 1000 + lead.date.nanoseconds / 1000000
      );
      return (
        new Date(leadDate).getDate() + 1 == new Date().getDate() &&
        lead.projectId == projectId
      );
    });
  }

  getLeadsFromYesterday() {
    return this.leads.filter((lead) => {
      const leadDate = new Date(
        lead.date.seconds * 1000 + lead.date.nanoseconds / 1000000
      );
      return new Date(leadDate).getDate() == new Date().getDate() - 1;
    });
  }

  getDateByDaysAgo(daysAgo: number) {
    return new Date(new Date().setDate(new Date().getDate() - daysAgo));
  }

  // get array of projects ordered by leads descending (most leads first) and the count of leads of today
  getProjectsByLeads() {
    let projects = this.leads.map((lead) => lead.projectId);
    let projectCount: any = {};
    projects.forEach((project: any) => {
      if (projectCount[project]) {
        projectCount[project]++;
      } else {
        projectCount[project] = 1;
      }
    });
    let projectsByLeads: any[] = [];
    for (let project in projectCount) {
      if (this.funnels[project]) {
        projectsByLeads.push({
          projectId: project,
          name: this.funnels[project].name,
          count: projectCount[project],
          countToday: this.getLeadsFromTodayByProject(project).length,
        });
      }
    }
    projectsByLeads.sort((a, b) => {
      return b.count - a.count;
    });
    return projectsByLeads;
  }

  getMostUsedProject() {
    let projects = this.leads.map((lead) => lead.projectId);
    let projectCount: any = {};
    projects.forEach((project: any) => {
      if (projectCount[project]) {
        projectCount[project]++;
      } else {
        projectCount[project] = 1;
      }
    });
    let max = 0;
    let mostUsedProject = '';
    for (let project in projectCount) {
      if (projectCount[project] > max) {
        max = projectCount[project];
        mostUsedProject = project;
      }
    }
    return mostUsedProject;
  }
}
