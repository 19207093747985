<div class="h-full flex flex-col">
  <div class="flex h-full">
    <!-- Narrow sidebar -->

    <!-- Content area -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <!-- Main content -->
      <div class="flex-1 flex items-stretch overflow-hidden">
        <main class="flex-1 overflow-y-auto bg-gray-100 px-12 relative">
          <h2
            class="text-2xl font-bold leading-7 text-kl-grey sm:text-3xl sm:truncate mb-6"
            i18n="@@funnelLeadsPartialEntries_headline_label"
          >
            Teileinträge
          </h2>
          <div class="lg:grid lg:grid-cols-12">
            <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 h-full mb-10">
              <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="py-2 align-middle inline-blborder-b-2 border-kl-purpleock min-w-full sm:px-6 lg:px-8"
                  >
                    <div class="flex justify-end items-center mb-6">
                      <!--   <span
                        class="w-1/4 cursor-pointer inline-flex items-center px-4 rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
                      >
                        <input
                          type="text"
                          placeholder="Filter"
                          class="bg-transparent border-transparent focus:border-transparent focus:ring-0 border-none w-full"
                        />
                        <i class="ml-2 bi bi-search"></i>
                      </span> -->
                    </div>
                    <div
                      class="shadow overflow border-b border-gray-300 sm:rounded-lg"
                    >
                      <table
                        class="min-w-full divide-y divide-gray-300 overflow-x-auto"
                      >
                        <thead class="bg-kl-grey overflow-x-auto">
                          <tr>
                            <th
                              *ngFor="
                                let column of displayedColumnsPartial;
                                let isLast = last;
                                let isFirst = first
                              "
                              scope="col"
                              class="py-3 text-left text-xs font-bold text-white uppercase w-auto"
                              [ngClass]="{
                                'pl-4 ': isFirst
                              }"
                            >
                              {{ column }}
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          class="bg-white divide-y divide-gray-200 overflow-x-auto"
                        >
                          <tr *ngFor="let val of dataPartial; let i = index">
                            <!--  <td class="w-10 pl-5 py-4">
                              <div class="flex items-center">
                                <input
                                  id="person-1"
                                  name="person-1"
                                  type="checkbox"
                                  class="h-6 w-6 text-kl-purple border-gray-300 rounded border-transparent focus:border-transparent focus:ring-0"
                                />
                              </div>
                            </td> -->
                            <td
                              *ngFor="
                                let col of displayedColumnsPartial;
                                let isLast = last;
                                let isFirst = first;
                                let index = index
                              "
                              class="w-auto px-1 py-4 whitespace-nowrap"
                              [ngClass]="{
                                'w-10 ': isFirst,
                                'w-20 ': index === 1
                              }"
                            >
                              <div
                                class="flex items-center"
                                [ngClass]="{
                                  'ml-4 ': isFirst
                                }"
                              >
                                {{ isFirst ? i + 1 + "." : "" }}
                                <div *ngIf="col !== 'Datum' && !isFirst">
                                  <p class="text-sm">
                                    {{ val[col] ? val[col] : "-" }}
                                  </p>
                                </div>

                                <div
                                  *ngIf="col === 'Datum'"
                                  class="w-auto text-sm"
                                >
                                  {{ val.Datum | date: "dd.MM.yyyy HH:mm:ss" }}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <!--  <div class="flex justify-end mt-6 py-4">
                      <button
                        (click)="loadMoreEntries()"
                        class="bg-kl-purple border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                        i18n="@@funnelLeadsPartialEntries_loadMore_button"
                      >
                      Mehr laden 
                      </button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</div>
