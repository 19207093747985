import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  Block,
  Answer,
  PrivacyCheck,
  SubmitButton,
  InputField,
  BlockAction,
  SelectAnswer,
} from 'src/app/models/block.model';
import { CustomDomain, PageAnswers } from 'src/app/models/project.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { AnswerSubmission, Project } from 'src/app/models/project.model';
import { starterBlock } from 'src/app/blocks/starterBlock';
import { ProjectService } from 'src/app/services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { FacebookPixelEventTrackerService } from 'src/app/services/facebook.service';
import { uuidv4 } from 'src/app/services/uuid';
import { PrivacyDialogComponent } from 'src/app/components/privacy/privacy-dialog.component';
import { AppService } from 'src/app/services/app.service';
import { BlockActionType } from 'src/app/enums/block-action-type';
import { BlockType } from 'src/app/enums/block-type';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { BlockService } from 'src/app/services/block.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, take } from 'rxjs/operators';
import { DomainService } from 'src/app/services/domain.service';
import { MediaLibraryService } from 'src/app/services/media-library.service';
import { MediaImage } from 'src/app/models/media-image.model';
import { environment } from 'src/environments/environment';
import { MonthlyImpressionsService } from 'src/app/services/monthly-impressions.service';
declare let gtag: Function;
declare var grecaptcha: any;
import * as crypto from 'crypto-js';

@Component({
  selector: 'funnel-app', // Comment out for Widget
  templateUrl: 'funnel-app.component.html',
  styleUrls: ['./funnel-app.component.scss'],
  /*  encapsulation: ViewEncapsulation., ===> For Widget */
})
export class FunnelAppComponent implements OnInit {
  favIcon: any = document.querySelector('#favIcon');

  html: Block[] = starterBlock;
  currentIndex: number;
  currentPageIndex: number = 0;
  projectId: any;

  errorIndex: any = [];
  privacyError: boolean = false;
  answerSubmission: AnswerSubmission;

  answerIndex: any;
  notFound: boolean = false;

  currentProject: Project;
  showFormToast: boolean = false;

  answerAnimationIndex: number = -1;
  isPreviewMode: boolean = false;
  inputFormInputIndex: number = -1;
  inputIndex: number = -1;
  isAlreadySubmitted: boolean = false;

  showCookieOverlay: boolean = false;

  uploadLoader: boolean = false;
  showErrorTooBig: boolean = false;
  selectedAnswers: number[] = [];

  @Input() public id: string;

  submitBtnHover: boolean = false;

  westNetzLoader: boolean = false;
  westNetzErrorText: string = '';

  uploadNames: { name: string; url: string }[] = [];
  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    public dialog: MatDialog,
    private facebookService: FacebookPixelEventTrackerService,
    private appService: AppService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private localStorageService: LocalStorageService,
    private blockService: BlockService,
    private http: HttpClient,
    private domainService: DomainService,
    private mediaLibraryService: MediaLibraryService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private monthlyImpressionsService: MonthlyImpressionsService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this.initializeProject();
  }

  private initializeProject(): void {
    this.projectService.currentProjectSubject
      .pipe(
        filter((project) => !!project),
        take(1)
      )
      .subscribe(async (project: Project) => {
        if (project) {
          this.currentProject = project;
          this.isCookiePolicyAccepted();
          this.setFavicon();
          if (this.currentProject.userId) {
            await this.monthlyImpressionsService.increaseImpressionsPerMonth(
              this.currentProject.userId
            );
          }
        }
      });
  }

  async ngOnInit() {
    this.ngZone.run(async () => {
      try {
        await this.initializeApp();
        this.cdr.detectChanges();
      } catch (error) {
        console.error('Error initializing app:', error);
        this.handleInitializationError(error);
      }
    });
  }

  private async initializeApp(): Promise<void> {
    this.appService.showHeaderSubject.next(false);
    this.loadScript();
    await this.setProjectId();
    await this.handleRouteParams();
    await this.loadProject();
    await this.setupProject();

    // wait 2 seconds for the page to load
    await this.delay(2000);
    console.log(this.currentProject);
  }

  private async setProjectId(): Promise<void> {
    this.projectId = this.id || this.route.snapshot.paramMap.get('id');
  }

  private async handleRouteParams(): Promise<void> {
    this.route.queryParams.subscribe((params) => {
      this.isPreviewMode = !!params['preview'];
    });

    this.appService.clearPageHistory();
    this.appService.addToPageHistory(0);

    if (!this.projectId || this.projectId === 'not-found') {
      this.notFound = true;
      this.router.navigate(['not-found']);
    }
  }

  private async loadProject(): Promise<void> {
    if (this.id) {
      await this.loadProjectById();
    } else {
      await this.loadProjectByDomain();
    }
  }

  private async loadProjectById(): Promise<void> {
    if (!this.projectId) {
      this.appService.showHeaderSubject.next(true);
      this.router.navigate(['/funnels']);
      return;
    }

    const url = await this.domainService.getDomainId(this.projectId);
    if (!url) {
      await this.projectService.getProjectById(this.projectId);
      if (!this.currentProject) {
        this.notFound = true;
      }
    } else {
      this.projectId = url.projectId;
      await this.projectService.getProjectById(url.projectId);
    }
  }

  private async loadProjectByDomain(): Promise<void> {
    if (this.isKlickleadOrLocalhost()) {
      await this.loadProjectForKlickleadOrLocalhost();
    } else {
      await this.loadProjectForCustomDomain();
    }
  }

  private isKlickleadOrLocalhost(): boolean {
    return (
      window.location.href.includes('klicklead') ||
      window.location.href.includes('localhost') || 
      window.location.href.includes('funnelbuilder')
    );
  }

  private async loadProjectForKlickleadOrLocalhost(): Promise<void> {
    if (!this.projectId) {
      this.appService.showHeaderSubject.next(true);
      this.router.navigate(['/funnels']);
      return;
    }

    const url = await this.domainService.getDomainId(this.projectId);
    if (!url) {
      await this.projectService.getProjectById(this.projectId);
      if (!this.currentProject) {
        this.notFound = true;
      }
    } else {
      this.projectId = url.projectId;
      await this.projectService.getProjectById(url.projectId);
    }
  }

  private async loadProjectForCustomDomain(): Promise<void> {
    const customDomainURL = new URL(window.location.href);
    const customDomain: CustomDomain = await this.domainService.getCustomDomain(
      customDomainURL.host
    );

    if (!customDomain) {
      this.appService.showHeaderSubject.next(true);
      this.router.navigate(['/funnels']);
      return;
    }

    this.projectId = customDomain.projectId;
    await this.projectService.getProjectById(customDomain.projectId);
  }

  private async setupProject(): Promise<void> {
    if (this.currentProject) {
      this.checkAlreadySubmitted();
      this.setupProjectDetails();
      await this.initializeAnswerSubmission();
    }
  }

  private checkAlreadySubmitted(): void {
    const alreadySubmitted = this.localStorageService.get(
      this.currentProject.id
    );
    if (alreadySubmitted && this.currentProject.oneAnswerSubmissionPerUser) {
      this.isAlreadySubmitted = true;
    }
  }

  private setupProjectDetails(): void {
    if (!this.currentProject.status) {
      this.projectService.currentProjectSubject.next(null);
      this.notFound = true;
      this.router.navigate(['not-found']);
      return;
    }

    if (!this.id) {
      this.titleService.setTitle(this.currentProject.pageTitle);
      this.meta.updateTag({
        name: 'description',
        content: this.currentProject.pageDescription,
      });
    }

    this.html = JSON.parse(JSON.stringify(this.currentProject.pages[0].blocks));
    this.currentPageIndex = 0;
  }

  private async initializeAnswerSubmission(): Promise<void> {
    if (this.isFakeLoaderInPage()) {
      await this.activateFakeLoader();
    }

    this.answerSubmission = {
      id: uuidv4(),
      projectId: this.currentProject.id,
      date: new Date(),
      submissions: [],
    };

    await this.saveAnswerSubmission(true);
  }

  private handleInitializationError(error: any): void {
    console.error('Initialization error:', error);
    this.notFound = true;
    this.router.navigate(['error']);
  }

  setFavicon() {
    if (!this.id && this.currentProject.favicon) {
      this.favIcon.href = this.currentProject.favicon;
    }
  }

  acceptCookies(isAccepted: boolean) {
    this.isCookiePolicyAccepted();
  }

  isCookiePolicyAccepted() {
    if (this.currentProject.showCookieConsent) {
      this.showCookieOverlay = true;
    } else {
      this.showCookieOverlay = false;
      return;
    }

    let localStorageCookieConsent = this.localStorageService.get(
      'cookieConsent:' + this.projectId
    );

    if (typeof localStorageCookieConsent === 'undefined') {
      localStorageCookieConsent = false;
    }

    this.showCookieOverlay =
      localStorageCookieConsent === 'accepted' ? false : true;

    if (!this.showCookieOverlay) {
      if (this.currentProject.fbPixelId) {
        this.facebookService.load(this.currentProject.fbPixelId);
        this.trackFacebookEvent();
      }

      if (
        this.currentProject.googleAnalyticsId ||
        this.currentProject.googleAnalytics4Id
      ) {
        if (this.currentProject.googleAnalyticsId) {
          this.loadGoogleAnalyticsScript(this.currentProject.googleAnalyticsId);
        }

        if (this.currentProject.googleAnalytics4Id) {
          this.loadGoogleAnalyticsScript(
            this.currentProject.googleAnalytics4Id
          );
        }

        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            gtag('config', this.currentProject.googleAnalyticsId, {
              page_path: event.urlAfterRedirects,
            });
          }
        });
        gtag('config', this.currentProject.googleAnalytics4Id);
        this.trackGoogleEvent();
      }
    }
  }

  async uploadFile(event: any, itemIndex: number) {
    this.errorIndex = [];
    this.uploadLoader = true;
    if (
      event.addedFiles.length + this.html[itemIndex].content.uploads.length >
      this.html[itemIndex].content.maxUploads
    ) {
      this.errorIndex.push(itemIndex);
      this.uploadLoader = false;
      return;
    }

    for (let i = 0; i < event.addedFiles.length; i++) {
      let mediaFile: MediaImage = {
        id: uuidv4(),
        createdDate: new Date(),
        projectId: this.currentProject.id,
        url: '',
      };

      const config = {
        file: event.addedFiles[i],
        maxSize: 500,
      };

      if (event.addedFiles[i].name.match(/.(jpg|jpeg|png|gif)$/i)) {
        const resizedImage = await this.resizeImage(config);

        const file = await this.mediaLibraryService.uploadBlockStorage(
          this.currentProject.id,
          mediaFile.id,
          resizedImage
        );
        mediaFile.url = await file.ref.getDownloadURL();
      } else {
        if (this.validateSize(event.addedFiles[i])) {
          const file = await this.mediaLibraryService.uploadBlockStorage(
            this.currentProject.id,
            mediaFile.id,
            event.addedFiles[i]
          );
          mediaFile.url = await file.ref.getDownloadURL();
        } else {
          this.uploadLoader = false;
          this.showErrorTooBig = true;
          return;
        }
      }

      this.uploadNames.push({
        name: event.addedFiles[i].name,
        url: mediaFile.url,
      });

      this.html[itemIndex].content.uploads.push(mediaFile.url);
    }

    this.uploadLoader = false;
    this.errorIndex = [];
  }

  removeUpload(itemIndex: number, uploadIndex: number) {
    this.html[itemIndex].content.uploads.splice(uploadIndex, 1);
    this.uploadNames.splice(uploadIndex, 1);
  }

  validateSize(file: any) {
    const maxSize: number = 3;
    const fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > maxSize) {
      return false;
    } else {
      return true;
    }
  }

  resizeImage = (settings: any) => {
    const file = settings.file;
    const maxSize = settings.maxSize;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');
    const dataURItoBlob = (dataURI: string) => {
      const bytes =
        dataURI.split(',')[0].indexOf('base64') >= 0
          ? atob(dataURI.split(',')[1])
          : unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    };
    const resize = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')!.drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL('image/jpeg');
      return dataURItoBlob(dataUrl);
    };

    return new Promise((ok, no) => {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }

      reader.onload = (readerEvent: any) => {
        image.onload = () => ok(resize());
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  funnelHasCookiesBlocks(): boolean {
    const hasVideos: boolean = this.currentProject.pages.some((page) => {
      return page.blocks.some((block) => {
        return block.type === BlockType.VIDEO;
      });
    });

    const hasFacebookPixel: boolean = !!this.currentProject.fbPixelId;
    const hasGoogleAnalytics: boolean = !!this.currentProject.googleAnalyticsId;

    return hasVideos || hasFacebookPixel || hasGoogleAnalytics;
  }

  loadScript() {
    if (this.id) {
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('link');
      style.id = 'css-styling';
      style.rel = 'stylesheet';
      style.href =
        'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css';
      head.appendChild(style);
    }
  }

  public onToTop(): void {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  getAlignment(index: number) {
    let currentBlock: Block = this.html[index];
    return currentBlock.style?.alignment;
  }

  getContentAlignment(index: number) {
    let currentBlock: Block = this.html[index];
    return currentBlock.style?.contentAlignment;
  }

  getElementStyle(
    itemStyle: any,
    isFirst: boolean,
    isLast: boolean,
    index: number
  ) {
    let style = isFirst ? 'element-item-first' : 'element-item';

    return itemStyle + ' ' + style + ' ' + this.isActive(index);
  }

  isActive(index: number) {
    if (index === this.currentIndex) {
      return ' active-element';
    }
    return '';
  }

  getBackgroundColor(block?: Block) {
    return this.blockService.getBackgroundColor(
      this.currentProject,
      this.currentPageIndex,
      block
    );
  }

  getElement(block: Block) {}

  getQuizTextStyle(answerIndex: number, i: number) {
    return this.blockService.getQuizTextStyle(this.html, answerIndex, i);
  }

  getQuizIconStyle(answerIndex: number, i: number) {
    return this.blockService.getQuizIconStyle(this.html, answerIndex, i);
  }

  getFontCSS(index: number) {
    return this.blockService.getFontCSS(this.html, index);
  }

  getAnswerFontCSS(answerIndex: number, i: number) {
    let currentAnswer = this.html[i].content.answers[answerIndex];
    if (typeof currentAnswer !== 'undefined') {
      if (currentAnswer.style) {
        let font: string = currentAnswer.style.fontFamily
          ? currentAnswer.style.fontFamily
          : 'Roboto';

        font = font.replace(/ /g, '+');

        return this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://fonts.googleapis.com/css2?family=' +
            font +
            ':wght@100;200;300;400;500;600;700;800;900&display=swap'
        );
      }
    }
    return '';
  }

  getStyle(index: number) {
    return this.blockService.getStyle(this.html, index, this.inputIndex);
  }

  getFont(index: number) {
    let currentBlock: Block = this.html[index];
    let styles = '';

    if (typeof currentBlock !== 'undefined') {
      if (currentBlock.style) {
        let font = currentBlock.style.fontFamily
          ? currentBlock.style.fontFamily
          : 'Roboto';
        styles = 'font-family:' + font + ' !important';
      }

      return styles;
    }
    return '';
  }

  openSocialMedia(url: string) {
    window.open(url, '_blank');
  }

  getSocialMediaIcon(type: string) {
    switch (type) {
      case 'facebook':
        return 'bi bi-facebook';
      case 'twitter':
        return 'bi bi-twitter';
      case 'instagram':
        return 'bi bi-instagram';
      case 'youtube':
        return 'bi bi-youtube';
      case 'linkedin':
        return 'bi bi-linkedin';
      case 'pinterest':
        return 'bi bi-pinterest';
      case 'vimeo':
        return 'bi bi-vimeo';
      case 'github':
        return 'bi bi-github';
      case 'dribbble':
        return 'bi bi-dribbble';

      default:
        return '';
    }
  }

  getSocialMediaStyle(index: number, type: string) {
    let style = this.getStyle(index);

    switch (type) {
      case 'facebook':
        style = { ...style, color: '#3b5998' };
        break;
      case 'twitter':
        style = { ...style, color: '#00aced' };
        break;
      case 'instagram':
        style = { ...style, color: '#bc2a8d' };
        break;
      case 'youtube':
        style = { ...style, color: '#bb0000' };
        break;
      case 'linkedin':
        style = { ...style, color: '#007bb6' };
        break;
      case 'pinterest':
        style = { ...style, color: '#cb2027' };
        break;
      case 'vimeo':
        style = { ...style, color: '#1ab7ea' };
        break;
      case 'github':
        style = { ...style, color: '#000000' };
        break;
      case 'dribbble':
        style = { ...style, color: '#ea4c89' };
        break;

      default:
        break;
    }
    return style;
  }

  focusIn(index: number) {
    this.inputIndex = index;
  }

  focusOut(index: number) {
    this.inputIndex = -1;
  }

  focusFormInputIn(index: number) {
    this.inputFormInputIndex = index;
  }

  focusFormInputOut() {
    this.inputFormInputIndex = -1;
  }

  getQuizButtonStyle(answerIndex: number, i: number) {
    return this.blockService.getQuizButtonStyle(this.html, answerIndex, i);
  }

  checkRequiredInputs(input: InputField, inputIndex: number) {
    this.errorIndex.splice(inputIndex, 1);
    if (input.required) {
      if (
        input.type === 'text' ||
        input.type === 'email' ||
        input.type === 'number' ||
        input.type === 'tel'
      ) {
        if (input.value === '' || input.value === null) {
          this.errorIndex.push(inputIndex);
        } else {
          if (input.type === 'email') {
            let emailValidator = /^\w+[\w-+\.]*\@\w+([-\.]\w+)*\.[a-zA-Z]{2,}$/;

            if (!emailValidator.test(input.value)) {
              this.errorIndex.push(inputIndex);
            }
          }
        }
      }
    }
  }

  getQuizPictureStyle(answerIndex: number, i: number) {
    return this.blockService.getQuizPictureStyle(this.html, answerIndex, i);
  }

  getFormSubmitButtonStyle(index: number) {
    return this.blockService.getFormSubmitButtonStyle(
      this.html,
      index,
      this.submitBtnHover
    );
  }

  getFormPrivacyCheckStyle(index: number) {
    return this.blockService.getFormPrivacyCheckStyle(
      this.html,
      index,
      this.privacyError
    );
  }

  getBadgeBackgroundColor() {
    const lastBlock = this.html[this.html.length - 1];
    return this.blockService.getBackgroundColor(
      this.currentProject,
      this.currentPageIndex,
      lastBlock
    );
  }

  getFormCheckBoxStyle(index: number) {
    return this.blockService.getFormCheckboxStyle(this.html, index);
  }

  getFormInputStyle(index: number, inputIndex: number) {
    return this.blockService.getFormInputStyle(
      this.html,
      index,
      inputIndex,
      this.inputFormInputIndex
    );
  }

  over() {
    this.submitBtnHover = true;
  }

  out() {
    this.submitBtnHover = false;
  }

  getBorderStyle(index: number) {
    let currentBlock: Block = this.html[index];

    if (typeof currentBlock !== 'undefined') {
      if (!currentBlock.style) return {};
      let styles = {
        'border-width': '1px',
        'border-style': 'solid ',
        'border-radius': '10px',
        'padding-bottom': '20px',
        'padding-top': '20px',
        'margin-bottom': '20px',
        'border-color': currentBlock.style.fontColor + '85',
      };

      return styles;
    }
    return '';
  }

  getLabelStyle(index: number) {
    let currentBlock: Block = this.html[index];

    if (typeof currentBlock !== 'undefined') {
      if (!currentBlock.style) return {};
      let styles = {
        color: currentBlock.style.fontColor,
      };

      return styles;
    }
    return '';
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getVideoUrl(url: string) {
    const videoType = this.getVideoProviderType(url);
    let videoLink = '';
    switch (videoType) {
      case 'youtube':
        const youtubeId = this.getYoutubeId(url);
        videoLink = 'https://www.youtube-nocookie.com/embed/' + youtubeId;
        break;
      case 'vimeo':
        var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
        var match = url.match(regExp);
        if (!match) return;

        videoLink = 'https://player.vimeo.com/video/' + match[2];

        break;
      default:
        break;
    }

    return this.getSantizeUrl(videoLink);
  }

  getVideoProviderType(url: string) {
    // - Supported YouTube URL formats:
    //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
    //   - http://youtu.be/My2FRPA3Gf8
    //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
    // - Supported Vimeo URL formats:
    //   - http://vimeo.com/25451551
    //   - http://player.vimeo.com/video/25451551
    // - Also supports relative URLs:
    //   - //player.vimeo.com/video/25451551

    let type: string = '';
    if (url.indexOf('youtube.com') > 0 || url.indexOf('youtu.be') > 0) {
      type = 'youtube';
    }
    if (url.indexOf('vimeo.com') > 0 || url.indexOf('player.vimeo.com') > 0) {
      type = 'vimeo';
    }

    return type;
  }

  getYoutubeId(url: string) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  isFakeLoaderInPage() {
    let isInPage: boolean = false;
    this.html.forEach((element) => {
      if (element.type === 'fakeloader') {
        isInPage = true;
      }
    });

    return isInPage;
  }

  async activateFakeLoader() {
    let fakeloader: any;
    this.html.forEach((element) => {
      if (element.type === 'fakeloader') {
        fakeloader = element;
      }
    });

    if (fakeloader) {
      await this.delay(fakeloader.content.duration * 1000);

      this.currentPageIndex++;
      if (this.currentProject.pages.length - 1 >= this.currentPageIndex) {
        this.html = JSON.parse(
          JSON.stringify(
            this.currentProject.pages[this.currentPageIndex].blocks
          )
        );

        this.currentProject.startAnalyticsPageIndex = this.currentProject
          .startAnalyticsPageIndex
          ? this.currentProject.startAnalyticsPageIndex
          : 0;

        if (
          this.currentPageIndex >= this.currentProject.startAnalyticsPageIndex
        ) {
          this.answerSubmission.submissions.push({
            pageId:
              this.currentProject.pages[this.currentPageIndex].id.toString(),
            answer: '-',
          });
        }
      }
    }
  }

  loadGoogleAnalyticsScript(trackingID: string): void {
    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${trackingID}`
    );

    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;

    if (document.documentElement.firstChild) {
      document.documentElement.firstChild.appendChild(gaScript);
      document.documentElement.firstChild.appendChild(gaScript2);
    }
  }

  getBulletpointStyle(index: number) {
    let style: any = this.blockService.getBulletpointStyle(
      this.html,
      index,
      'text'
    );

    return style;
  }

  getBulletpointIconStyle(index: number) {
    let style: any = this.blockService.getBulletpointStyle(
      this.html,
      index,
      'icon'
    );
    return style;
  }

  loadGoogleRecaptchaScript(): void {
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('async', 'true');
    recaptchaScript.setAttribute(
      'src',
      `https://www.google.com/recaptcha/api.js?render=${environment.recaptchaKey}`
    );

    if (document.documentElement.firstChild) {
      document.documentElement.firstChild.appendChild(recaptchaScript);
    }
  }

  isBackButtonAllowed(block: Block) {
    return this.projectService.isBackButtonAllowed(block);
  }

  setPreviousPageIndex(pageIndex: number) {
    // push page index to history
    let previousPageIndex: number[] =
      this.appService.stateSubject.getValue().editor.history
        .previousPageHistoryIndex ?? [];

    previousPageIndex.push(pageIndex);

    this.appService.stateSubject.next({
      ...this.appService.stateSubject.getValue(),
      editor: {
        ...this.appService.stateSubject.getValue().editor,
        history: {
          ...this.appService.stateSubject.getValue().editor.history,
          previousPageHistoryIndex: previousPageIndex,
        },
      },
    });
  }

  removePreviousPageIndex() {
    // push page index to history
    let previousPageIndex: number[] =
      this.appService.stateSubject.getValue().editor.history
        .previousPageHistoryIndex ?? [];

    previousPageIndex.pop();

    if (previousPageIndex.length > 0) {
      this.currentPageIndex = previousPageIndex[previousPageIndex.length - 1];
    } else {
      this.currentPageIndex = 0;
    }

    this.appService.stateSubject.next({
      ...this.appService.stateSubject.getValue(),
      editor: {
        ...this.appService.stateSubject.getValue().editor,
        history: {
          ...this.appService.stateSubject.getValue().editor.history,
          previousPageHistoryIndex: previousPageIndex,
        },
      },
    });
  }

  async backToPreviousPage() {
    const previousPageIndex = this.appService.getPreviousPage();
    if (previousPageIndex !== undefined) {
      this.currentPageIndex = previousPageIndex;
      this.html = JSON.parse(
        JSON.stringify(this.currentProject.pages[this.currentPageIndex].blocks)
      );

      // Entfernen Sie die letzte Antwort aus dem Submission-Array
      this.answerSubmission.submissions.pop();

      await this.projectService.saveSubmissionAnswer(this.answerSubmission);

      this.onToTop();
    }
  }

  delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  async openNextPage(pushAnswer: boolean = true) {
    var menu = document.querySelector('.menu'); // Using a class instead, see note below.
    if (menu) {
      menu.classList.toggle('fade-in');
    }

    this.currentPageIndex++;
    this.appService.addToPageHistory(this.currentPageIndex);

    if (this.currentProject.pages.length - 1 >= this.currentPageIndex) {
      this.html = JSON.parse(
        JSON.stringify(this.currentProject.pages[this.currentPageIndex].blocks)
      );
      if (menu) {
        menu.classList.toggle('fade-in');
      }
      this.currentProject.startAnalyticsPageIndex = this.currentProject
        .startAnalyticsPageIndex
        ? this.currentProject.startAnalyticsPageIndex
        : 0;

      if (
        this.currentPageIndex >= this.currentProject.startAnalyticsPageIndex
      ) {
        this.answerSubmission.submissions.push({
          pageId:
            this.currentProject.pages[this.currentPageIndex].id.toString(),
          answer: null,
        });
      }

      if (this.isFakeLoaderInPage()) {
        await this.activateFakeLoader();
      }
    } else {
      this.currentPageIndex--;
    }
    this.setPreviousPageIndex(this.currentPageIndex);

    this.onToTop();

    this.trackEvents();

    console.log(
      this.appService.stateSubject.getValue().editor.history
        .previousPageHistoryIndex
    );
    console.log(this.currentPageIndex);
  }

  async openConnectedPage(action: BlockAction, pushAnswer: boolean = true) {
    if (!action) return;

    this.setPreviousPageIndex(this.currentPageIndex);

    var menu = document.querySelector('.menu'); // Using a class instead, see note below.
    if (menu) {
      menu.classList.toggle('fade-in');
    }
    const connectedPageId = action.connectedPage?.toString();
    if (!connectedPageId) return;

    this.currentPageIndex = this.currentProject.pages.findIndex(
      (page) => page.id.toString() === connectedPageId
    );

    this.appService.addToPageHistory(this.currentPageIndex);

    if (this.currentProject.pages.length - 1 >= this.currentPageIndex) {
      this.html = JSON.parse(
        JSON.stringify(this.currentProject.pages[this.currentPageIndex].blocks)
      );
      if (menu) {
        menu.classList.toggle('fade-in');
      }
      if (pushAnswer) {
        this.answerSubmission.submissions.push({
          pageId:
            this.currentProject.pages[this.currentPageIndex].id.toString(),
          answer: null,
        });
      }
      if (this.isFakeLoaderInPage()) {
        await this.activateFakeLoader();
      }
    } else {
      this.currentPageIndex--;
    }
    this.onToTop();

    this.trackEvents();
    console.log(
      this.appService.stateSubject.getValue().editor.history
        .previousPageHistoryIndex
    );
    console.log(this.currentPageIndex);
  }

  trackEvents() {
    if (!this.showCookieOverlay) {
      if (this.currentProject.fbPixelId) {
        this.trackFacebookEvent();
      }

      if (
        this.currentProject.googleAnalyticsId ||
        this.currentProject.googleAnalytics4Id
      ) {
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            gtag('config', this.currentProject.googleAnalyticsId, {
              page_path: event.urlAfterRedirects,
            });

            gtag('config', this.currentProject.googleAnalytics4Id);
          }
        });

        this.trackGoogleEvent();
      }
    }
  }

  openQuizLink(action: BlockAction, pushAnswer: boolean = true) {
    if (!action) return;
    if (pushAnswer) {
      this.answerSubmission.submissions.push({
        pageId: this.currentProject.pages[this.currentPageIndex].id.toString(),
        answer: null,
      });
    }
    const url = action.link;
    window.open(url, '_blank', 'noreferrer');
  }

  async selectAnswer(answer: Answer, answerIndex: number, block: Block) {
    // await this.delay(200);

    if (block.content.isMultipleChoice) {
      if (this.selectedAnswers.includes(answerIndex)) {
        this.selectedAnswers = this.selectedAnswers.filter(
          (answer) => answer !== answerIndex
        );
      } else {
        this.selectedAnswers.push(answerIndex);
      }
    } else {
      this.setSelectedAnswerToAnswerSubmission(answer);

      /*  console.log('select answer1', answer);
    await this.saveAnswerSubmission(); */

      if (!answer.action) return;
      switch (answer.action.type) {
        case BlockActionType.NEXTPAGE: {
          this.openNextPage();

          break;
        }
        case BlockActionType.CONNECTEDPAGE: {
          this.openConnectedPage(answer.action);

          break;
        }
        case BlockActionType.LINK: {
          this.openQuizLink(answer.action);
          break;
        }
      }
      this.answerAnimationIndex = -1;

      this.answerSubmission.submissions[
        this.answerSubmission.submissions.length - 1
      ] =
        this.answerSubmission.submissions[
          this.currentPageIndex <= this.answerSubmission.submissions.length - 1
            ? this.currentPageIndex
            : this.answerSubmission.submissions.length - 1
        ];

      console.log('select answer2', answer);
      await this.saveAnswerSubmission();

      this.setOneAnswerSubmissionPerUser();

      //this.trackEvents();
    }
  }

  async selectMultipleChoiceButton(answerBlock: Block) {
    let answers: Answer[] = [];

    answerBlock.content.answers.forEach((answer: Answer, index: number) => {
      if (this.selectedAnswers.includes(index)) {
        answers.push(answer);
      }
    });

    console.log(answers);

    let selectedAnswerBlocks: Answer[] = [];

    answerBlock.content.answers.forEach((answer: Answer, index: number) => {
      if (this.selectedAnswers.includes(index)) {
        selectedAnswerBlocks.push(answer);
      }
    });

    this.setSelectedAnswerToAnswerSubmissionForMultipleChoice(
      selectedAnswerBlocks
    );

    if (!answerBlock.content.multipleChoiceButton.action) return;
    switch (answerBlock.content.multipleChoiceButton.action.type) {
      case BlockActionType.NEXTPAGE: {
        this.openNextPage();

        break;
      }
      case BlockActionType.CONNECTEDPAGE: {
        this.openConnectedPage(answerBlock.content.multipleChoiceButton.action);

        break;
      }
      case BlockActionType.LINK: {
        this.openQuizLink(answerBlock.content.multipleChoiceButton.action);
        break;
      }
    }

    this.selectedAnswers = [];
    this.answerAnimationIndex = -1;

    this.answerSubmission.submissions[
      this.answerSubmission.submissions.length - 1
    ] =
      this.answerSubmission.submissions[
        this.currentPageIndex <= this.answerSubmission.submissions.length - 1
          ? this.currentPageIndex
          : this.answerSubmission.submissions.length - 1
      ];

    await this.saveAnswerSubmission();

    this.setOneAnswerSubmissionPerUser();

    //this.trackEvents();
  }

  checkIfCurrentPageIndexIsGreaterThanPagesLength() {
    return this.currentPageIndex >= this.currentProject.pages.length - 1;
  }

  async saveAnswerSubmission(initialCall?: boolean) {
    if (!this.isPreviewMode) {
      this.currentProject.startAnalyticsPageIndex = this.currentProject
        .startAnalyticsPageIndex
        ? this.currentProject.startAnalyticsPageIndex
        : 0;

      if (
        this.currentPageIndex >= this.currentProject.startAnalyticsPageIndex
      ) {
        if (initialCall) {
          this.answerSubmission.submissions.push({
            pageId: this.currentProject.pages[0].id.toString(),
            answer: null,
          });

          await this.projectService.increaseImpressions(
            this.projectId,
            this.currentProject.pages[0].id.toString()
          );
        } else {
          this.projectService.increaseImpressions(
            this.projectId,
            this.currentProject.pages[this.currentPageIndex].id
          );
        }

        await this.projectService.saveSubmissionAnswer(this.answerSubmission);
      }
    }
  }

  setSelectedAnswerToAnswerSubmission(answer: Answer) {
    this.currentProject.startAnalyticsPageIndex = this.currentProject
      .startAnalyticsPageIndex
      ? this.currentProject.startAnalyticsPageIndex
      : 0;

    if (
      this.currentPageIndex >= this.currentProject.startAnalyticsPageIndex &&
      !this.isPreviewMode
    ) {
      this.answerSubmission.submissions[
        this.answerSubmission.submissions.length - 1
      ].answer = answer;
    }
  }

  setSelectedAnswerToAnswerSubmissionForMultipleChoice(answers: Answer[]) {
    this.currentProject.startAnalyticsPageIndex = this.currentProject
      .startAnalyticsPageIndex
      ? this.currentProject.startAnalyticsPageIndex
      : 0;

    if (
      this.currentPageIndex >= this.currentProject.startAnalyticsPageIndex &&
      !this.isPreviewMode
    ) {
      const splittedAnswers = answers.map((answer) => answer.answerText);
      this.answerSubmission.submissions[
        this.answerSubmission.submissions.length - 1
      ].answer = splittedAnswers.join(', ');
    }
  }

  getPageIdOfPageWithForm() {
    const page = this.currentProject.pages.find((page) =>
      page.blocks.find((block) => block.type === BlockType.FORM)
    );

    if (!page) return null;

    return page;
  }

  getAnswerSubmissions() {
    let answers: any[] = [];
    //let formPageId = this.currentProject.pages.find(page => page.blocks === BlockType.FORM)

    this.answerSubmission.submissions.forEach((submission: any, index) => {
      const pageById = this.currentProject.pages.find(
        (page) => page.id === submission.pageId
      );
      const pageWithForm = this.getPageIdOfPageWithForm();
      if (pageWithForm!.id !== pageById?.id) {
        if (
          typeof submission.answer === 'string' ||
          typeof submission.answer === 'number'
        ) {
          const answerText = submission.answer ? submission.answer : '-';
          answers.push(pageById?.name + ': ' + answerText);
        } else {
          const answerText = submission.answer
            ? submission.answer['answerText']
            : '-';
          answers.push(pageById?.name + ': ' + answerText);
        }
      }
    });
    return answers;
  }

  async submitWestnetzData(block: Block, index: number) {
    this.westNetzLoader = true;

    const street = block.content.inputs.find(
      (input: any) => input.text === 'Straße'
    ).value;
    const houseNumber = block.content.inputs.find(
      (input: any) => input.text === 'Hausnummer'
    ).value;
    const zipCode = block.content.inputs.find(
      (input: any) => input.text === 'PLZ'
    ).value;
    const city = block.content.inputs.find(
      (input: any) => input.text === 'Ort'
    ).value;

    if (!street || !houseNumber || !zipCode || !city) {
      this.westNetzErrorText = 'Bitte füllen Sie alle Pflichtfelder aus.';
      this.westNetzLoader = false;
      return;
    }

    const address = {
      street: street,
      houseNumber: houseNumber,
      zipCode: zipCode,
      city: city,
    };

    const req: any = await this.projectService.westnetzRequest(address);
    console.log(req);
    this.westNetzLoader = false;

    this.setSelectedAnswerToAnswerSubmission({
      answerText:
        address.street +
        ' ' +
        address.houseNumber +
        ', ' +
        address.zipCode +
        ' ' +
        address.city,
    });

    if (req.strom === null) {
      if (!block.content.onFail.type) return;
      switch (block.content.onFail.type) {
        case BlockActionType.NEXTPAGE: {
          this.openNextPage();

          break;
        }
        case BlockActionType.CONNECTEDPAGE: {
          this.openConnectedPage(block.content.onFail);

          break;
        }
        case BlockActionType.LINK: {
          this.openQuizLink(block.content.onFail);
          break;
        }
      }
    } else {
      if (req.strom.supplied) {
        if (!block.content.onSuccess.type) return;
        switch (block.content.onSuccess.type) {
          case BlockActionType.NEXTPAGE: {
            this.openNextPage();

            break;
          }
          case BlockActionType.CONNECTEDPAGE: {
            this.openConnectedPage(block.content.onSuccess);

            break;
          }
          case BlockActionType.LINK: {
            this.openQuizLink(block.content.onSuccess);
            break;
          }
        }
      } else {
        if (!block.content.onFail.type) return;
        switch (block.content.onFail.type) {
          case BlockActionType.NEXTPAGE: {
            this.openNextPage();

            break;
          }
          case BlockActionType.CONNECTEDPAGE: {
            this.openConnectedPage(block.content.onFail);

            break;
          }
          case BlockActionType.LINK: {
            this.openQuizLink(block.content.onFail);
            break;
          }
        }
      }
    }

    this.answerSubmission.submissions[
      this.answerSubmission.submissions.length - 1
    ] =
      this.answerSubmission.submissions[
        this.currentPageIndex <= this.answerSubmission.submissions.length - 1
          ? this.currentPageIndex
          : this.answerSubmission.submissions.length - 1
      ];

    await this.saveAnswerSubmission();

    this.setOneAnswerSubmissionPerUser();

    //this.trackEvents();
  }

  async sendLeadNotification(inputsCopy: any[]) {
    let lead: any = [];
    inputsCopy.forEach((element: any) => {
      lead.push(element.text + ':' + element.value);
    });

    let answers = this.getAnswerSubmissions();

    this.currentProject.notificationMails.forEach((mail) => {
      let mailText = this.currentProject.notificationText;
      mailText = mailText.replace('[LEAD]', lead.join('\r\n'));
      mailText = mailText.replace('[ANSWERS]', answers.join('\r\n'));
      mailText = mailText.replace(/\n/g, '<br>');

      this.currentProject.notificationMails.forEach((mail) => {
        let link;

        link = environment.backendUrl + '/notification/new-lead-notification';

        const getEmail = inputsCopy.find(
          (input: { value: string }) => input.value && input.value.includes('@')
        );

        let myData = JSON.stringify({
          receiverMail: mail,
          htmlBody: mailText,
          subject: 'Neuer Lead wurde erfasst',
          replyTo: getEmail ? getEmail.value : '',
        });

        const headers = new HttpHeaders({
          'content-Type': 'application/json',
        });
        this.http.post(link, myData, { headers: headers }).subscribe(
          (data) => {
            console.log('data', data);
          },
          (error) => {
            console.log('error', error);
          }
        );

        if (this.currentProject.webhookUrl) {
          let leadData: any = {};
          inputsCopy.forEach((element: any) => {
            leadData['Lead_' + element.text] = element.value;
          });

          let answersData: any = {};
          answers.forEach((element: any) => {
            let splitted = element.split(':');
            answersData['Answer_' + splitted[0]] = splitted[1];
          });

          const whData = JSON.stringify({
            webhookURL: this.currentProject.webhookUrl!,
            ...leadData,
            ...answersData,
          });
          let webhookLink =
            environment.backendUrl + '/webhook/new-lead-notification-webhook';

          this.http.post(webhookLink, whData, { headers: headers }).subscribe(
            (data) => {
              console.log('data', data);
            },
            (error) => {
              console.log('error', error);
            }
          );
        }

        if (
          this.currentProject.custobarUrl &&
          this.currentProject.custobarSecret
        ) {
          const getEmail = inputsCopy.find(
            (input: { value: string }) =>
              input.value && input.value.includes('@')
          );

          if (!getEmail.value) return;

          const custobarSecretCrypt = crypto.AES.encrypt(
            this.currentProject.custobarSecret,
            environment.cryptoPass
          );

          const custobarData = JSON.stringify({
            type: this.currentProject.custobarType,
            email: getEmail.value,
            date: new Date().toISOString(),
            custobarCompanySpecificField:
              this.currentProject.custobarCompanySpecificField,
            custobarCompanySpecificFieldValue:
              this.currentProject.custobarCompanySpecificFieldValue,
            custobarUrl: this.currentProject.custobarUrl,
            custobarSecret: custobarSecretCrypt.toString(),
          });

          console.log(custobarData);
          const headers = new HttpHeaders({
            'content-Type': 'application/json',
          });
          this.http
            .post(environment.backendUrl + '/webhook/custobar/', custobarData, {
              headers: headers,
            })
            .subscribe(
              (data) => {
                console.log('data', data);
              },
              (error) => {
                console.log('error', error);
              }
            );
        }
      });
    });
  }

  async submitFormData(
    inputs: InputField[],
    action: BlockAction,
    index: number,
    privacyCheck: PrivacyCheck
  ) {
    this.errorIndex = [];
    this.privacyError = false;
    var menu = document.querySelector('.menu'); // Using a class instead, see note below.
    if (menu) {
      menu.classList.toggle('fade-in');
    }

    for (let [index, input] of inputs.entries()) {
      this.checkRequiredInputs(input, index);
    }

    if (privacyCheck.required) {
      if (!privacyCheck.checked) {
        this.privacyError = true;
      }
    }

    if (this.errorIndex.length > 0 || this.privacyError) {
      return;
    }

    this.setSelectedAnswerToAnswerSubmission(
      JSON.parse(JSON.stringify(inputs))
    );

    let inputsCopy = JSON.parse(JSON.stringify(inputs));

    if (this.currentProject.notification) {
      if (this.currentProject.notificationMails.length > 0) {
        this.sendLeadNotification(inputsCopy);
      }
    }

    // Send mail notification to end-user
    if (
      this.currentProject.leadNotification &&
      this.currentProject.leadNotification.status
    ) {
      let lead: any = [];
      inputsCopy.forEach((element: any) => {
        lead.push(element.text + ':' + element.value);
      });

      let answers = this.getAnswerSubmissions();

      let mailText = this.currentProject.leadNotification.text;
      mailText = mailText.replace('[LEAD]', lead.join(', '));
      mailText = mailText.replace('[ANSWERS]', answers.join('\r\n'));
      mailText = mailText.replace(/\n/g, '<br>');

      const getEmail = inputsCopy.find(
        (input: { value: string }) => input.value && input.value.includes('@')
      );

      const pass = crypto.AES.encrypt(
        this.currentProject.leadNotification?.smtpPassword,
        environment.cryptoPass
      );

      if (getEmail) {
        this.projectService.sendLeadNotification({
          testMailAddress: getEmail.value,
          smtpUser: this.currentProject.leadNotification?.smtpUsername,
          smtpPassword: pass.toString(),
          smtpHost: this.currentProject.leadNotification?.smtpHost,
          smtpPort: this.currentProject.leadNotification?.smtpPort ?? 0,
          secure: this.currentProject.leadNotification?.secure,
          fromEmail: this.currentProject.leadNotification?.fromEmail,
          subject: this.currentProject.leadNotification?.subject,
          text: mailText,
        });
      }
    }

    switch (action.type) {
      case BlockActionType.NEXTPAGE: {
        this.openNextPage();

        break;
      }
      case BlockActionType.CONNECTEDPAGE: {
        this.openConnectedPage(action);

        break;
      }
      case BlockActionType.LINK: {
        this.openQuizLink(action);
        break;
      }
    }

    let page = this.currentProject.pages[this.currentPageIndex];

    let form = page.blocks.find((b) => b.type === BlockType.FORM);
    if (typeof form !== 'undefined') {
      form.content['inputs'].forEach((inp: InputField) => {
        inp.value = null;
      });
      form.content['privacyCheck'].checked = false;

      console.log('submitFormData1', form.content['inputs']);

      await this.saveAnswerSubmission();
      await this.projectService.saveLead(this.answerSubmission);

      // Show Succes Toast if there is no next page
      if (this.currentProject.pages.length - 1 <= this.currentPageIndex) {
        this.showFormToast = true;
        //await this.delay(3000);
        this.showFormToast = false;
      }

      this.html[index].content['inputs'] = inputsCopy;
    } else {
      console.log('submitFormData2');
      await this.saveAnswerSubmission();
      await this.projectService.saveLead(this.answerSubmission);
    }

    this.setOneAnswerSubmissionPerUser();

    //this.trackEvents();
  }

  trackFacebookEvent() {
    let fbEvent = this.currentProject.pages[this.currentPageIndex].fbEvent
      ? this.currentProject.pages[this.currentPageIndex].fbEvent
      : '';
    if (typeof fbEvent !== 'undefined' && fbEvent !== '') {
      this.facebookService.trackEvent(fbEvent);
    }
  }

  trackGoogleEvent() {
    let googleEvent = this.currentProject.pages[this.currentPageIndex]
      .googleEvent
      ? this.currentProject.pages[this.currentPageIndex].googleEvent
      : '';
    if (typeof googleEvent !== 'undefined' && googleEvent !== '') {
      this.googleAnalyticsService.eventEmitter(
        this.currentProject.id,
        googleEvent,
        'klicklead.de'
      );
    }
  }

  setOneAnswerSubmissionPerUser() {
    if (this.currentProject.oneAnswerSubmissionPerUser && !this.isPreviewMode) {
      if (this.currentProject.pages.length - 1 === this.currentPageIndex) {
        this.localStorageService.set(this.currentProject.id.toString(), true);
      }
    }
  }

  async buttonClick(item: Block) {
    let textfieldsInPage: any = null;
    let sliderInPage: Block | null = null;
    let selectionInPage: Block | null = null;
    let uploadInPage: Block | null = null;
    let uploadUrls: string[] = [];
    this.errorIndex = [];
    let selectionValue: string[] = [];

    var menu = document.querySelector('.menu'); // Using a class instead, see note below.
    if (menu) {
      menu.classList.toggle('fade-in');
    }

    this.html.forEach((block: Block) => {
      if (block.type === BlockType.TEXTFIELD) {
        if (textfieldsInPage === null) {
          textfieldsInPage = [];
        }
        textfieldsInPage?.push(block);
      }
      /*   if (block.type === BlockType.SLIDER) {
        sliderInPage = block;
      } */

      if (block.type === BlockType.UPLOAD) {
        uploadInPage = block;
        uploadInPage.content.uploads.forEach((url: string) => {
          uploadUrls.push(url);
        });
      }
      if (block.type === BlockType.SELECTION) {
        selectionInPage = block;
        selectionInPage.content.answers.forEach((answer: SelectAnswer) => {
          if (answer.checked && answer.answerText) {
            selectionValue.push(answer.answerText);
          }
        });
      }
    });

    /*  if (textfieldsInPage !== null) {
      this.checkRequiredInputs(textfieldInPage['content'], 0);
    } */
    if (uploadInPage !== null) {
      const uploadInPageIndex = this.html.indexOf(uploadInPage);
      if (uploadInPage['content']['required'] && uploadUrls.length === 0) {
        this.errorIndex.push(uploadInPageIndex);
      }

      if (uploadUrls.length > uploadInPage['content']['maxUploads']) {
        this.errorIndex.push(uploadInPageIndex);
      }
    }

    if (this.errorIndex.length > 0 || this.privacyError) {
      return;
    }

    // check if required fields are filled
    if (textfieldsInPage !== null) {
      textfieldsInPage.forEach((textfield: Block) => {
        if (textfield['content']['required']) {
          if (textfield['content']['value'] === null) {
            this.errorIndex.push(this.html.indexOf(textfield));
          }
        }
      });

      if (this.errorIndex.length > 0 || this.privacyError) {
        return;
      }

      const allValues = textfieldsInPage.map((block: Block) => {
        return block.content.value;
      });
      this.answerSubmission.submissions[
        this.answerSubmission.submissions.length - 1
      ].answer = allValues.join(', ');

      console.log('btnCLick textfieldsInPage');
      await this.saveAnswerSubmission();
    }

    if (sliderInPage !== null) {
      this.answerSubmission.submissions[
        this.answerSubmission.submissions.length - 1
      ].answer = sliderInPage['content']['value'];

      console.log('btnCLick sliderInPage');
      await this.saveAnswerSubmission();
    }

    if (uploadInPage !== null) {
      this.answerSubmission.submissions[
        this.answerSubmission.submissions.length - 1
      ].answer = uploadUrls.join(', ');

      console.log('btnCLick uploadInPage');
      await this.saveAnswerSubmission();
    }

    if (selectionInPage !== null) {
      this.answerSubmission.submissions[
        this.answerSubmission.submissions.length - 1
      ].answer = selectionValue.join();

      if (!this.isPreviewMode) {
        //this.projectService.saveAnswerSubmissions(this.answerSubmission);
      }
    }

    if (!item.action) return;
    switch (item.action.type) {
      case BlockActionType.NEXTPAGE: {
        let isTextOrSelectionInPage = !!textfieldsInPage || !!selectionInPage;
        this.openNextPage(isTextOrSelectionInPage);

        break;
      }
      case BlockActionType.CONNECTEDPAGE: {
        let isTextOrSelectionInPage = !!textfieldsInPage || !!selectionInPage;
        this.openConnectedPage(item.action, isTextOrSelectionInPage);

        break;
      }
      case BlockActionType.LINK: {
        let isTextOrSelectionInPage = !!textfieldsInPage || !!selectionInPage;
        this.openQuizLink(item.action, isTextOrSelectionInPage);
        break;
      }
    }

    if (textfieldsInPage || selectionInPage || sliderInPage || uploadInPage) {
      this.answerSubmission.submissions[
        this.answerSubmission.submissions.length - 1
      ] =
        this.answerSubmission.submissions[
          this.currentPageIndex <= this.answerSubmission.submissions.length - 1
            ? this.currentPageIndex
            : this.answerSubmission.submissions.length - 1
        ];

      console.log(
        'btnCLick textfieldsInPage || selectionInPage || sliderInPage || uploadInPage'
      );
      await this.saveAnswerSubmission();

      this.setOneAnswerSubmissionPerUser();
    } else {
      this.answerSubmission.submissions[
        this.answerSubmission.submissions.length - 1
      ].answer = null;

      console.log(
        'btnCLick ELSE textfieldsInPage || selectionInPage || sliderInPage || uploadInPage'
      );

      await this.saveAnswerSubmission();
    }
  }

  checkIfRequiredFieldsPresent() {
    let requiredFieldsPresent = true;
    let block: Block | null = null;
    this.html.forEach((element) => {
      if (element.type === 'form' || element.type === 'zw_westnetz') {
        block = element;
      }
    });
    block!.content.inputs.forEach((input: any) => {
      if (input.required && input.value == null) {
        requiredFieldsPresent = false;
      }
    });
    return requiredFieldsPresent;
  }

  getMultipleChoiceButtonStyle(block: Block) {
    let currentBtn: any = block.content.multipleChoiceButton;
    if (typeof currentBtn !== 'undefined') {
      if (!currentBtn.style) return {};
      let styles = {
        'font-size': currentBtn.style.fontSize + 'px',
        width: currentBtn.style.width + '%',
        color: currentBtn.style.fontColor,
        'background-color': currentBtn.style.quizButtonColor,
        'font-weight': currentBtn.style.fontWeight,
        'font-style': currentBtn.style.fontStyle,
        'text-decoration': currentBtn.style.textDecoration,
        'margin-top': currentBtn.style.marginTop + 'px',
        'margin-bottom': currentBtn.style.marginBottom + 'px',
      };
      return styles;
    }
    return '';
  }

  getSelectionStyle(i: number) {
    return this.blockService.getSelectionStyle(this.html, i);
  }

  getProgress(type: string) {
    return this.blockService.getProgress(
      this.currentProject,
      this.currentPageIndex,
      type
    );
  }

  openImprint() {
    const dialogRef = this.dialog.open(PrivacyDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        name: this.currentProject.imprint,
        type: 'imprint',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined') {
      }
    });
  }

  openPrivacy() {
    const dialogRef = this.dialog.open(PrivacyDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        name: this.currentProject.dataPrivacy,
        type: 'privacy',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined') {
      }
    });
  }

  openPrivacyText(link: string) {
    window.open(link, '_blank', 'noreferrer');
  }

  createId() {
    return Math.floor(Math.random() * 1000000);
  }
  showCookieConsent() {
    if (!this.currentProject) return;
    if (this.currentProject.hasOwnProperty('showCookieConsent')) {
      return this.currentProject.showCookieConsent;
    }
    return false;
  }
}
