import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
})
export class RangeSliderComponent implements OnInit {
  @Input() title: string;
  @Input() max: number = 200;
  @Input() min: number = 0;
  @Input() value: number | undefined = 0;
  @Output() valueChange = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  changed(event: any) {
    this.value = event.target.value;
    this.valueChange.emit(this.value);
  }
}
