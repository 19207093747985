import { Component, Input, OnInit } from '@angular/core';
import { Block, BlockStyle } from 'src/app/models/block.model';

@Component({
  selector: 'app-textfield',
  templateUrl: './textfield.component.html',
  styleUrls: ['./textfield.component.scss'],
})
export class TextfieldComponent implements OnInit {
  @Input() block: Block;
  @Input() style: BlockStyle;
  @Input() mobileView: boolean;
  @Input() alignment: string | undefined;
  @Input() blockIndex: number;

  inputIndex: number = -1;
  constructor() {}

  ngOnInit(): void {}

  focusIn(index: number) {
    this.inputIndex = index;
  }

  focusOut(index: number) {
    this.inputIndex = -1;
  }

  getContentAlignment() {
    switch (this.style.contentAlignment) {
      case 'center':
        return 'text-center';
      case 'start':
        return 'text-left';
      case 'end':
        return 'text-right';
      default:
        return 'text-center';
    }
  }
}
