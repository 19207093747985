import {
  CdkDragDrop,
  copyArrayItem,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { applyDrag, degenerateItems, generateItems } from 'src/app/dnd-utils';
import { BlockType } from 'src/app/enums/block-type';
import { Block } from 'src/app/models/block.model';
import { Page, Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';
import { BlockService } from 'src/app/services/block.service';
import { ProjectService } from 'src/app/services/project.service';
import { uuidv4 } from 'src/app/services/uuid';
import { DialogModalComponent } from '../../modals/dialog-modal/dialog-modal.component';
import agenturMarketing from 'src/app/funnel-templates/VORLAGE_ Agentur (Marketing).json';
import angebot from 'src/app/funnel-templates/VORLAGE_ Angebot.json';
import anwalt from 'src/app/funnel-templates/VORLAGE_ Anwälte.json';
import abnehmen from 'src/app/funnel-templates/VORLAGE_ Coaching (Abnehmen).json';
import dj from 'src/app/funnel-templates/VORLAGE_ DJ.json';
import fotograf from 'src/app/funnel-templates/VORLAGE_ Fotograf.json';

import gartner from 'src/app/funnel-templates/VORLAGE_ Gärtner.json';
import gewinnspiel from 'src/app/funnel-templates/VORLAGE_ Gewinnspiel.json';
import grafikdesign from 'src/app/funnel-templates/VORLAGE_ Grafikdesign.json';
import immo from 'src/app/funnel-templates/VORLAGE_ Immobilie verkaufen.json';
import konfig from 'src/app/funnel-templates/VORLAGE_ Produktkonfigurator.json';
import recruit1 from 'src/app/funnel-templates/VORLAGE_ Recruiting (einfach).json';
import recruit2 from 'src/app/funnel-templates/VORLAGE_ Recruiting (erweitert).json';
import video from 'src/app/funnel-templates/VORLAGE_ Videoproduktion.json';

import { TemplateService } from 'src/app/services/template.service';
import {
  FunnelTemplate,
  TemplateCategory,
} from 'src/app/models/funnel-template.model';
import { User } from 'src/app/models/user.model';
import { InfoModalComponent } from '../../modals/info-modal /info-modal .component';
import { NotificationType } from 'src/app/enums/notification-type';

@Component({
  selector: 'app-funnel-designer',
  templateUrl: './funnel-designer.component.html',
  styleUrls: ['./funnel-designer.component.scss'],
})
export class FunnelDesignerComponent implements OnInit {
  state$: Subscription;
  isBlocksOpen: boolean = false;
  blocks: Block[] = [];
  currentElementIndex: number = 0;
  currentElement: Block | null;
  public doc = document;
  currentProject: Project;
  pageIndex: number = 0;
  isHoverIndex: number | null = null;
  highlightedElementIndex: number | null = null;
  mobileView: boolean = true;
  items1: any;
  currentBlockElementIndex: number = -1;
  dragHeight: number = 0;

  @Input() user: User;
  uniqueBlocksPerPage = [
    BlockType.BUTTON,
    BlockType.QUIZ,
    BlockType.FORM,
    BlockType.FAKELOADER,
  ];
  constructor(
    private appService: AppService,
    private blockService: BlockService,
    private router: Router,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private templateService: TemplateService
  ) {}
  async ngOnInit() {
    const projectId = this.route.snapshot.paramMap.get('id') || '';

    const project = await this.projectService.getProjectById(projectId);
    const initialProject = JSON.parse(JSON.stringify(project));

    /*   const templates: any[] = [
      { template: agenturMarketing, name: 'Agentur (Marketing)' },
      { template: angebot, name: 'Angebot' },
      { template: anwalt, name: 'Anwälte' },
      { template: abnehmen, name: 'Coaching (Abnehmen)' },
      { template: dj, name: 'DJ' },
      { template: fotograf, name: 'Fotograf' },
      { template: gartner, name: 'Gärtner' },
      { template: gewinnspiel, name: 'Gewinnspiel' },
      { template: grafikdesign, name: 'Grafikdesign' },
      { template: immo, name: 'Immoblie verkaufen' },
      { template: konfig, name: 'Produktkonfigurator' },
      { template: recruit1, name: 'Recruiting (einfach)' },
      { template: recruit2, name: 'Recruiting (erweitert)' },
      { template: video, name: 'Videoproduktion' },
    ];

    for (let i = 0; i < templates.length; i++) {
      const template: any = templates[i];
      const funnelTemplate: FunnelTemplate = {
        id: uuidv4(),
        name: template.name,
        catergoryId: uuidv4(),
        createdAt: new Date(),
        updatedAt: new Date(),
        template: template.template.pages,
      };
      await this.templateService.setFunnelTemplate(funnelTemplate);
    } */

    /*  const coachingObj = coaching;
    coachingObj.id = uuidv4();
    const coachingPages: Page[] = coachingObj.pages as Page[];

    const coachingCat: TemplateCategory = {
      id: uuidv4(),
      categoryName: 'Coaching',
      createdAt: new Date(),
    };
    console.log(coachingCat);
    await this.templateService.setCategoryTemplate(coachingCat);

    const template = {
      catergoryId: coachingCat.id,
      name: 'Coaching (Abnehmen)',
      createdAt: new Date(),
      id: uuidv4(),
      template: coachingObj.pages,
      updatedAt: new Date(),
    };
    await this.templateService.setFunnelTemplate(template as FunnelTemplate);

    const immoObj = immo;
    immoObj.id = uuidv4();

    const immoPages: Page[] = immoObj.pages as Page[];

    const immoCat: TemplateCategory = {
      id: uuidv4(),
      categoryName: 'Immobilien',
      createdAt: new Date(),
    };
    console.log(immoCat);
    await this.templateService.setCategoryTemplate(immoCat);

    const immmotemplate = {
      catergoryId: immoCat.id,
      name: 'Immobilie verkaufen',
      createdAt: new Date(),
      id: uuidv4(),
      template: immoObj.pages,
      updatedAt: new Date(),
    };
    await this.templateService.setFunnelTemplate(
      immmotemplate as FunnelTemplate
    );

    const rec1bj = rec1;
    rec1bj.id = uuidv4();

    const rec1Pages: Page[] = immoObj.pages as Page[];

    const rec1Cat: TemplateCategory = {
      id: uuidv4(),
      categoryName: 'Recruiting',
      createdAt: new Date(),
    };
    await this.templateService.setCategoryTemplate(rec1Cat);

    const rec1template = {
      catergoryId: rec1Cat.id,
      name: 'Recruiting (einfach)',
      createdAt: new Date(),
      id: uuidv4(),
      template: rec1bj.pages,
      updatedAt: new Date(),
    };
    await this.templateService.setFunnelTemplate(
      rec1template as FunnelTemplate
    );

    const rec2Obj = rec2;
    rec2Obj.id = uuidv4();

    const rec2template = {
      catergoryId: rec1Cat.id,
      name: 'Recruiting (erweitert)',
      createdAt: new Date(),
      id: uuidv4(),
      template: rec2Obj.pages,
      updatedAt: new Date(),
    };
    await this.templateService.setFunnelTemplate(
      rec2template as FunnelTemplate
    ); */

    if (project) {
      this.appService.stateSubject.next({
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentProject: project,
          history: {
            ...this.appService.state.editor.history,
            initialProject: initialProject,
          },
        },
      });
    }

    if (this.appService.state.editor.history.projectUndoArray.length == 0) {
      this.appService.state.editor.history.projectUndoArray.push(
        initialProject
      );
    }

    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.currentProject = state.editor.currentProject;

        this.pageIndex = state.editor.layout.pageBar.currentPageIndex;
        this.isBlocksOpen = state.editor.layout.isBlocksOpen;
        if (this.currentProject.pages) {
          this.blocks =
            this.currentProject.pages[
              this.appService.state.editor.layout.pageBar.currentPageIndex
            ].blocks;

          this.items1 = generateItems(
            this.currentProject.pages[
              this.appService.state.editor.layout.pageBar.currentPageIndex
            ].blocks
          );
        }
        this.currentElementIndex =
          state.editor.layout.designer.currentElementIndex;
        this.currentElement = state.editor.layout.designer.selectedElement;
      }
    });

    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          designer: { currentElementIndex: -1, selectedElement: null },
          sideDetail: {
            isDetailSidebarOpen: false,
            detailSidebarIndex: 0,
            selectedAnswer: null,
            selectedAnswerIndex: -1,
            selectedInputField: null,
            selectedInputFieldIndex: -1,
            selectedBulletpoint: null,
            selectedBulletpointIndex: -1,
          },
          pageBar: {
            isPagesBarOpen: true,
            currentPageBlocks: [],
            currentPageIndex: 0,
          },
          isBlocksOpen: false,
          isDesignMenuOpen: false,
        },
      },
    });

    this.getChildPayload1 = this.getChildPayload1.bind(this);
  }

  getChildPayload1(index: number) {
    debugger
    return this.items1[index];
  }

  setBlockIndex(index: number) {
    console.log(index);
    if (this.currentBlockElementIndex === index) {
      this.currentBlockElementIndex = -1;
    } else {
      this.currentBlockElementIndex = index;
    }
  }

  isBackButtonAllowed(block: Block) {
    return this.projectService.isBackButtonAllowed(block);
  }

  toggleBlocks() {
    const blockState: boolean = !this.isBlocksOpen;
    this.appService.showBlocks(blockState);
  }

  toggleSize() {
    this.mobileView = !this.mobileView;
  }

  getStyle(index: number, id?: any) {
    let style: any = this.blockService.getStyle(this.blocks, index);
    /*  if (id) {
      let element = document.getElementById(id);
      if (!element) return;
      style = { ...style, height: this.autoGrowTextZone(element) };
    } */
    return style;
  }

  getBackgroundColor(block?: Block) {
    return this.blockService.getBackgroundColor(
      this.currentProject,
      this.pageIndex,
      block
    );
  }

  getBadgeBackgroundColor() {
    const lastBlock = this.blocks[this.blocks.length - 1];
    return this.blockService.getBackgroundColor(
      this.currentProject,
      this.pageIndex,
      lastBlock
    );
  }

  getAlignment(index: number) {
    let currentBlock: Block = this.blocks[index];

    return currentBlock.style?.alignment;
  }
  hoverListItem(index: number | null) {
    this.isHoverIndex = index;
  }

  removeElement(index: any) {
    this.blocks.splice(index, 1);

    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          designer: {
            ...this.appService.state.editor.layout.designer,
            currentElementIndex: -1,
            selectedElement: null,
          },
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,

            selectedAnswer: null,
            selectedInputField: null,
          },
        },
      },
    });
    this.appService.setShouldSave();
  }

  copyElement(index: any) {
    this.currentElement = null;

    let copyBlock: Block = JSON.parse(JSON.stringify(this.blocks[index])); //Object.assign({}, this.html[index]);
    copyBlock.id = uuidv4();
    this.blocks.splice(index + 1, 0, copyBlock);

    this.currentElement = this.blocks.filter(
      (el: any) => copyBlock.id === el.id
    )[0];

    this.appService.setShouldSave();
    this.selectBlock(this.currentElement, index + 1);
  }

  moveDown(index: number) {
    if (index < this.blocks.length - 1) {
      let temp = this.blocks[index];
      this.blocks[index] = this.blocks[index + 1];
      this.blocks[index + 1] = temp;
      this.appService.setShouldSave();
    }
  }

  moveUp(index: number) {
    if (index > 0) {
      let temp = this.blocks[index];
      this.blocks[index] = this.blocks[index - 1];
      this.blocks[index - 1] = temp;
      this.appService.setShouldSave();
    }
  }

  showUpgradeModal() {
    const dialogRef = this.dialog.open(InfoModalComponent, {
      width: '500px',
      data: {
        header: 'Upgrade',
        description:
          'Um den Klicklead-Badge zu entfernen, musst du mindestens die Business-Version von Klicklead aktivieren.',
        button: 'Upgrade',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.navigateToPayment();
      }
    });
  }

  getFontCSS(index: number) {
    return this.blockService.getFontCSS(this.blocks, index);
  }

  drop(event: CdkDragDrop<any[]>): void {
    let element: Block = event.previousContainer.data[event.previousIndex];
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if (!this.checkUniqueBlockInBlocks(element.type)) {
        const dialogRef = this.dialog.open(DialogModalComponent, {
          width: '550px',
          data: {
            headline: 'Validierung',
            text: 'Es kann nur 1 weiterleitender Block pro Seite benutzt werden',
            buttonText: 'Ok',
          },
        });
        return;
      }

      if (element.type === BlockType.FORM) {
        this.checkIfFormBlockIsOnLastPage();
      }

      this.applyPresetsToBlock(element);

      copyArrayItem(
        JSON.parse(JSON.stringify(event.previousContainer.data)),
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.blocks[event.currentIndex].id = uuidv4();
      this.currentElement = this.blocks[event.currentIndex];
      if (element.type === BlockType.QUIZ) {
        this.addNewHeaderBlock(event.currentIndex);
      }
    }
    this.appService.setShouldSave();

    //this.setHeaderToFirstElement();
  }

  addNewHeaderBlock(index: number = -1) {
    const presets = this.currentProject.designPresets;
    const newHeaderBlock: Block = {
      id: uuidv4(),
      type: BlockType.HEADER,
      style: {
        alignment: 'center',
        contentAlignment: presets?.contentAlignment || 'center',
        fontSize: presets?.fontSize || 20,
        fontWeight: 'bold',
        color: presets?.fontColor || '#000000',
        backgroundColor: presets?.backgroundColor || '#ffffff',
      },
      content: 'Deine Frage',
    };
    if (index === -1) {
      this.blocks.push(newHeaderBlock);
    } else {
      this.blocks.splice(index, 0, newHeaderBlock);
    }
    this.appService.setShouldSave();
  }

  checkIfFormBlockIsOnLastPage() {
    let isQuizBlockOnLastPage = false;
    if (this.pageIndex <= this.currentProject.pages.length - 2) {
      this.appService.showNotification(
        'Validierung',
        'Für eine bessere Conversion sollte der Formular-Block auf einer der letzten Seiten in Deinem Funnel platziert werden.',
        NotificationType.WARNING,
        10000
      );
    }
    return isQuizBlockOnLastPage;
  }

  addElement(block: Block) {
     
    if (!this.checkUniqueBlockInBlocks(block.type)) {
      const dialogRef = this.dialog.open(DialogModalComponent, {
        width: '550px',
        data: {
          headline: 'Validierung',
          text: 'Es kann nur 1 weiterleitender Block pro Seite benutzt werden',
          buttonText: 'Ok',
        },
      });
      return;
    }

    if (block.type === BlockType.QUIZ) {
      this.addNewHeaderBlock(this.blocks.length - 1);
    }

    if (block.type === BlockType.FORM) {
      this.checkIfFormBlockIsOnLastPage();
    }

    this.applyPresetsToBlock(block);

    this.blocks.push(block);
    this.toggleBlocks();

    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          designer: {
            ...this.appService.state.editor.layout.designer,
            currentElementIndex: this.blocks.length - 1,
            selectedElement: block,
          },
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            detailSidebarIndex: 1,
            selectedAnswer: null,
            selectedInputField: null,
          },
          isBlocksOpen: false,
        },
      },
    });
    this.appService.showDetailSidebar(true);
    this.appService.setShouldSave();
  }

  applyPresetsToBlock(block: Block) {
    const presets = this.currentProject.designPresets;
    switch (block.type) {
      case BlockType.HEADER:
        block.style = {
          alignment: 'center',
          contentAlignment: presets?.contentAlignment || 'center',
          fontSize: presets?.fontSize || 20,
          fontWeight: 'bold',
          backgroundColor: presets?.backgroundColor || '#ffffff',
          fontColor: presets?.fontColor || '#000000',
          fontFamily: presets?.fontFamily || 'Arial',
        };
        break;
      case BlockType.SELECTIONQUIZ:
        debugger;
        block.content.answers.forEach((answer: any) => {
          answer.style = {
            quizButtonColor: presets?.quizAnswerBackgroundColor || '#ffffff',
            fontColor: presets?.quizAnswerFontColor || '#000000',
            fontFamily: presets?.fontFamily || 'Arial',
          };
        });

        break;

      default:
        block.style = {
          alignment: 'center',
          contentAlignment: presets?.contentAlignment || 'center',
          fontSize: presets?.fontSize || 20,
          fontWeight: 'bold',
          backgroundColor: presets?.backgroundColor || '#ffffff',
          fontColor: presets?.fontColor || '#000000',
          fontFamily: presets?.fontFamily || 'Arial',
        };
        break;
    }
  }

  checkUniqueBlockInBlocks(blockType: BlockType) {
    if (!this.uniqueBlocksPerPage.includes(blockType)) {
      return true;
    } else {
      const isInUniqueBlock = this.blocks.some((block: Block) =>
        this.uniqueBlocksPerPage.includes(block.type)
      );

      if (isInUniqueBlock) {
        return false;
      }
    }
    return true;
  }

  cdkDragStarted(event: any) {
    this.dragHeight = event.source.element.nativeElement.offsetHeight;
  }

  onClickOutside(id?: string) {
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        element.blur();
      }
    }

    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          layout: {
            ...this.appService.state.editor.layout,
            designer: {
              ...this.appService.state.editor.layout.designer,
              /*  currentElementIndex: -1,
              selectedElement: null, */
            },
          },
        },
      },
      false
    );
  }

  onClick(event: any) {
    console.log(
      window.getSelection()?.anchorOffset,
      window.getSelection()?.focusOffset
    );
  }

  navigateToPayment() {
    this.router.navigateByUrl('/user-settings?payment=true');
  }

  setElementToNull() {
    this.currentElementIndex = -1;

    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          designer: {
            ...this.appService.state.editor.layout.designer,
            currentElementIndex: -1,
            selectedAnswer: null,
            selectedInputField: null,
            selectedBulletpoint: null,
          },
        },
      },
    });
  }

  selectBlock(block: Block, index: number) {
    this.appService.stateSubject.next({
      ...this.appService.state,
      editor: {
        ...this.appService.state.editor,
        layout: {
          ...this.appService.state.editor.layout,
          designer: {
            ...this.appService.state.editor.layout.designer,
            currentElementIndex: index,
            selectedElement: block,
          },
          sideDetail: {
            ...this.appService.state.editor.layout.sideDetail,
            detailSidebarIndex: 1,
            selectedAnswer: null,
            selectedInputField: null,
            selectedBulletpoint: null,
          },
          isBlocksOpen: false,
        },
      },
    });
    this.highlightedElementIndex = index;
    this.appService.showDetailSidebar(true);
    console.log(block);
  }

  ngDestroy() {
    this.state$.unsubscribe();
  }
}
