<aside>
  <nav class="space-y-1">
    <!-- Current: "bg-gray-50 text-kl-purple hover:bg-white", Default: "text-gray-900 hover:text-gray-900 hover:bg-gray-50" -->
    <div
      class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      (click)="setNavigation(0)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 0
      }"
    >
      <!--
              Heroicon name: outline/user-circle

              Current: "text-kl-purple", Default: "text-gray-400 group-hover:text-gray-500"
            -->
      <svg
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 0
        }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <span class="truncate" i18n="@@funnelSettingsNavigation_general_label">
        Allgemein
      </span>
    </div>

    <div
      (click)="setNavigation(9)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 9
      }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-4 h-5 w-5"
        viewBox="0 0 16 16"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 9
        }"
      >
        <path
          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"
        />
      </svg>
      <span
        class="truncate"
        i18n="@@funnelSettingsNavigation_translations_label"
      >
        Lead Benachrichtigung
      </span>
    </div>

    <div
      class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      (click)="setNavigation(1)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 1
      }"
    >
      <!-- Heroicon name: outline/cog -->
      <svg
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 1
        }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
      <span class="truncate" i18n="@@funnelSettingsNavigation_meta_label">
        Metadaten
      </span>
    </div>

    <!--   <div
      class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      (click)="setNavigation(2)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 2
      }"
    >
       <svg
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 2
        }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
        />
      </svg>
      <span class="truncate"> Kollaboration </span>
    </div> -->

    <div
      class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      (click)="setNavigation(3)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 3
      }"
    >
      <!-- Heroicon name: outline/key -->
      <svg
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 3
        }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
        />
      </svg>
      <span class="truncate" i18n="@@funnelSettingsNavigation_tracking_label">
        Tracking
      </span>
    </div>

    <div
      class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      (click)="setNavigation(4)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 4
      }"
    >
      <!-- Heroicon name: outline/bell -->
      <svg
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 4
        }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
        />
      </svg>
      <span class="truncate" i18n="@@funnelSettingsNavigation_domains_label">
        Domains
      </span>
    </div>

    <div
      (click)="setNavigation(5)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 5
      }"
      aria-current="page"
    >
      <!-- Heroicon name: outline/credit-card -->
      <svg
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 5
        }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
        />
      </svg>
      <span class="truncate" i18n="@@funnelSettingsNavigation_embed_label">
        Einbetten
      </span>
    </div>
    <div
      (click)="setNavigation(6)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 6
      }"
    >
      <!-- Heroicon name: outline/view-grid-add -->

      <i
        class="bi bi-arrow-left-right text-gray-400 group-hover:text-gray-500 mr-3 text-lg"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 6
        }"
      ></i>
      <span
        class="truncate"
        i18n="@@funnelSettingsNavigation_integrations_label"
      >
        Integrationen
      </span>
    </div>
    <div
      (click)="setNavigation(7)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 7
      }"
    >
      <!-- Heroicon name: outline/view-grid-add -->

      <svg
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 7
        }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
        />
      </svg>
      <span class="truncate" i18n="@@funnelSettingsNavigation_privacy_label">
        Rechtliches
      </span>
    </div>

    <div
      (click)="setNavigation(8)"
      class="text-gray-900 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer"
      [ngClass]="{
        'text-kl-purple hover:text-gray-900 hover:bg-gray-50 bg-gray-50':
          navigationIndex === 8
      }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
        [ngClass]="{
          'text-kl-purple ': navigationIndex === 8
        }"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
        />
      </svg>
      <span
        class="truncate"
        i18n="@@funnelSettingsNavigation_translations_label"
      >
        Übersetzungen
      </span>
    </div>
  </nav>
</aside>
