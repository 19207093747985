import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-user-dashboard-funnels',
  templateUrl: './user-dashboard-funnels.component.html',
  styleUrls: ['./user-dashboard-funnels.component.scss'],
})
export class UserDashboardFunnelsComponent implements OnInit {
  @Input() user: User;
  funnels: Project[] = [];
  baseUrl: string;
  constructor(private projectService: ProjectService) {
    this.baseUrl = window.location.origin;
  }

  async ngOnInit() {
    console.log(this.user);
    for (let funnelId of this.user.funnelIds) {
      const funnel = await this.projectService.getProjectById(funnelId);
      if (funnel) {
        this.funnels.push(funnel);
      }
    }
  }
}
