<div class="flex justify-center mx-4">
  <style>
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      input[type="range"]::-webkit-slider-thumb {
        width: 15px;
        -webkit-appearance: none;
        appearance: none;
        height: 15px;
        background: #fff;
        border-radius: 50%;
        box-shadow: -405px 0 0 400px #d3d3d3;
      }
    }
  </style>
  <div class="flex flex-col w-full justify-center items-center">
    <input
      class="rounded-lg overflow-hidden appearance-none h-4"
      type="range"
      [min]="block.content.min"
      [max]="block.content.max"
      [value]="block.content.value"
      [(ngModel)]="block.content.value"
      [step]="block.content.steps"
      [ngStyle]="style"
    />
    <input
      type="text"
      [(ngModel)]="block.content.value"
      class="mt-1 block p-1 w-24 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md text-center"
    />
  </div>
</div>
