import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-leads-slide-over',
  templateUrl: './leads-slide-over.component.html',
  styleUrls: ['./leads-slide-over.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LeadsSlideOverComponent implements OnInit {
  state$: Subscription;

  lead: any;
  showSlideOver: boolean = false;
  formData: any;
  detailData: any;
  constructor(
    private appService: AppService,
    private projectService: ProjectService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.lead = state.funnelLeads.currentLead;
        this.showSlideOver = state.funnelLeads.showSlideOver;
        if (this.lead) {
          let detail = JSON.parse(JSON.stringify(this.lead));
          let formData = null;
          let deleteIndex = -1;
          detail.answerSubmission.submissions.forEach(
            (element: any, index: number) => {
              if (!this.projectService.currentProject) return;
              let page = this.projectService.currentProject.pages.find(
                (p) => p.id.toString() === element.pageId
              );
              if (typeof page !== 'undefined') {
                element.pageName = page.name;
              } else {
                element.pageName = 'gelöschte Seite';
              }

              if (Array.isArray(element.answer)) {
                formData = element.answer;
                deleteIndex = index;
              }
            }
          );

          if (deleteIndex !== -1) {
            detail.answerSubmission.submissions.splice(deleteIndex, 1);
          }
          this.formData = formData;
          this.detailData = detail;
        }
      }
    });
  }

  getLeadData(sub: any) {
    return this.domSanitizer.bypassSecurityTrustHtml(
      !sub.answer.answerText ? sub.answer : ''
    );
  }

  getLink(sub: any) {
    const link = !sub.answer.answerText ? sub.answer : '';
    return this.isValidHttpUrl(link) ? link : 'https://' + link;
  }

  isLink(sub: any) {
    let link;
    if (sub.answer !== null) {
      if (!sub.answer.answerText) {
        link = sub.answer;
      } else {
        link = sub.answer.answerText;
      }
      return this.isValidHttpUrl(link);
    }
    return false;
  }

  isValidHttpUrl(urlString: string) {
    let url;

    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  closeNotification() {
    this.appService.stateSubject.next({
      ...this.appService.state,
      funnelLeads: {
        ...this.appService.state.funnelLeads,
        showSlideOver: false,
      },
    });
  }
}
