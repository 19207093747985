import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Block } from 'src/app/models/block.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-header-block',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderBlockComponent implements OnInit, AfterViewInit {
  @Input() block: Block;
  constructor(private appService: AppService) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    /*   var iframe: any = document.getElementById(this.block.id + 'iframe');
    var content:any = document.getElementById(this.block.id)?.innerHTML;
    var frameDoc = iframe.document;
    if (iframe.contentWindow)
      frameDoc = iframe.contentWindow.document;
    
    frameDoc.open();
    frameDoc.writeln(content); */
  }

  update() {
    const doc = document.getElementById(this.block.id);
    this.block.content = doc?.innerHTML;
    this.appService.setState(
      {
        ...this.appService.state,
        editor: {
          ...this.appService.state.editor,
          currentProject: {
            ...this.appService.state.editor.currentProject,
          },
        },
      },
      false
    );
    this.appService.setShouldSave();
  }
}
