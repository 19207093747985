export interface User {
  displayName: string;
  email: string;
  companyName?: string;
  id: any;
  registerDate: any;
  funnelIds: string[];
  language: string;
  tier?: string;
  stripeCustomerId?: string;
  subscriptionId?: string;
  paymentInterval?: string;
  priceId?: string;
  trialEnd?: Date;
  cancelAt?: any;
  showOnboarding: boolean;
  receiptEmail: string;
}

export interface Billing {
  tier: string;
  price?: string;
  currency?: string;
  description?: string;
  startDate: Date;
  trialEnd: Date;
  couponCode?: string;
  active: boolean;
  paymentPlan?: string; //monthly or year
  lastPaymentDate?: Date;
  nextPaymentDate?: Date;
}
export enum Tier {
  TRIAL = 'trial',
  BASIC = 'basic',
  PROFESSIONAL = 'professional',
  PREMIUM = 'premium',
}

export interface PricingItem {
  name: string;
  tier: Tier;
  description: string;
  monthlyPrice: number;
  yearlyPrice: number;
  interval: string;
  currency: string;
  monthlyPaymentPriceId: string;
  yearlyPaymentPriceId: string;
  features: string[];
}
