<div class="flex flex-col items-center justify-center w-full px-2 h-full">
  <div [ngClass]="{ 'w-full': mobileView, 'w-1/3': !mobileView }">
    <label
      class="flex flex-col rounded hover:bg-gray-100 border-gray-600 border-2 border-dashed w-full h-60 group text-center cursor-pointer"
    >
      <div class="flex flex-auto max-h-48 w-auto mx-auto">
        <img
          class="has-mask h-36 w-auto mt-4 object-center"
          [src]="block.content.picture"
        />
      </div>
      <p class="pointer-none text-gray-500">
        <span class="text-sm">{{ block.content.text }}</span>
      </p>
    </label>
  </div>
</div>
