<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<!--
  This example requires updating your template:

  ```
  <html class="h-full bg-gray-50">
  <body class="h-full overflow-hidden">
  ```
-->
<div class="h-full flex flex-col">
  <header class="w-full border-kl-grey">
    <div class="relative z-10 flex-shrink-0 h-14 bg-white shadow-sm flex">
      <div class="flex flex-col justify-center items-center ml-6 ml-4 p-3">
        <img class="h-8 w-auto" src="./assets/kl.png" alt="Workflow" />
      </div>
      <button
        type="button"
        class="border-r border-gray-200 px-4 text-kl-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
      >
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: outline/menu-alt-2 -->
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>
      <div class="flex-1 flex justify-between px-4 sm:px-6">
        <div class="flex w-full justify-between items-center">
          <nav class="flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div>
                  <a href="#" class="text-kl-grey hover:text-white">
                    <!-- Heroicon name: solid/home -->
                    <svg
                      class="flex-shrink-0 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                      />
                    </svg>
                    <span class="sr-only">Home</span>
                  </a>
                </div>
              </li>

              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg
                    class="flex-shrink-0 h-5 w-5 text-kl-grey"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <a
                    href="#"
                    class="text-sm font-medium text-kl-grey hover:text-gray-700"
                    >PV Funnel</a
                  >
                </div>
              </li>
            </ol>
          </nav>

          <div class="flex gap-8 items-center">
            <div class="flex flex-col items-center">
              <i class="bi bi-pencil-fill text-lg"></i>
              <p class="text-xs">Editor</p>
            </div>

            <div class="flex flex-col items-center">
              <i class="bi bi-diagram-3-fill text-lg"></i>
              <p class="text-xs">Funnel-Flow</p>
            </div>
            <div class="flex flex-col items-center">
              <i class="bi bi-people-fill text-lg"></i>
              <p class="text-xs border-b-2 border-kl-purple">Leads</p>
            </div>
            <div class="flex flex-col items-center">
              <i class="bi bi-gear-fill text-lg"></i>
              <p class="text-xs">Einstellungen</p>
            </div>
          </div>
          <button
            type="button"
            class="mr-10 inline-flex items-center px-2 py-1.5 border border-transparent shadow-sm text-sm leading-4 text-white font-medium rounded-md bg-kl-green"
          >
            Veröffentlichen
          </button>
        </div>
        <div class="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
          <!-- Profile dropdown -->
          <div class="relative flex-shrink-0">
            <div>
              <button
                type="button"
                class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span class="sr-only">Open user menu</span>
                <img
                  class="h-8 w-8 rounded-full"
                  src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                  alt=""
                />
              </button>
            </div>

            <!--
                    Dropdown menu, show/hide based on menu state.
    
                    Entering: "transition ease-out duration-100"
                      From: "transform opacity-0 scale-95"
                      To: "transform opacity-100 scale-100"
                    Leaving: "transition ease-in duration-75"
                      From: "transform opacity-100 scale-100"
                      To: "transform opacity-0 scale-95"
                  -->
            <div
              class="opacity-0 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
            >
              <!-- Active: "bg-gray-100", Not Active: "" -->
              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-0"
                >Your Profile</a
              >

              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                >Sign out</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="flex h-full">
    <!-- Narrow sidebar -->

    <!-- Content area -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <!-- Main content -->
      <div class="flex-1 flex items-stretch overflow-hidden">
        <main class="flex-1 overflow-y-auto bg-gray-100 py-6 px-12 relative">
          <h2
            class="text-2xl font-bold leading-7 text-kl-grey sm:text-3xl sm:truncate mb-6"
          >
            Leads
          </h2>
          <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
              <nav class="space-y-1">
                <!-- Current: "bg-gray-50 text-kl-purple hover:bg-white", Default: "text-gray-900 hover:text-gray-900 hover:bg-gray-50" -->
                <a
                  href="#"
                  class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                >
                  <!--
                        Heroicon name: outline/user-circle
          
                        Current: "text-kl-purple", Default: "text-gray-400 group-hover:text-gray-500"
                      -->
                  <svg
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span class="truncate"> Statistik </span>
                </a>

                <a
                  href="#"
                  class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: outline/cog -->
                  <svg
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <span class="truncate"> Leads </span>
                </a>

                <a
                  href="#"
                  class="text-kl-purple hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                >
                  <!-- Heroicon name: outline/view-grid-add -->

                  <svg
                    class="text-kl-purple flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                    />
                  </svg>
                  <span class="truncate"> Teileinträge </span>
                </a>
              </nav>
            </aside>

            <!-- Payment details -->
            <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 h-full mb-10">
              <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="py-2 align-middle inline-blborder-b-2 border-kl-purpleock min-w-full sm:px-6 lg:px-8"
                  >
                    <div class="flex items-center mb-6">
                      <input
                        id="person-1"
                        name="person-1"
                        type="checkbox"
                        class="mr-8 ml-4 h-6 w-6 text-kl-purple border-gray-300 rounded border-transparent focus:border-transparent focus:ring-0"
                      />

                      <span
                        class="w-1/4 cursor-pointer inline-flex items-center px-4 rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
                      >
                        <input
                          type="text"
                          placeholder="Filter"
                          class="bg-transparent border-transparent focus:border-transparent focus:ring-0 border-none w-full"
                        />
                        <i class="ml-2 bi bi-search"></i>
                      </span>
                      
                    </div>
                    <div
                      class="shadow overflow-hidden border-b border-gray-300 sm:rounded-lg"
                    >
                      <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-kl-grey">
                          <tr>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-bold text-white uppercase tracking-wider"
                            ></th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-bold text-white uppercase tracking-wider"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-bold text-white uppercase tracking-wider"
                            >
                              Title
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-bold text-white uppercase tracking-wider"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-bold text-white uppercase tracking-wider"
                            >
                              Role
                            </th>
                            <th scope="col" class="relative px-6 py-3">
                              <span class="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr>
                            <td class="px-1 py-4 whitespace-nowrap">
                              <div class="flex items-center">
                                <input
                                  id="person-1"
                                  name="person-1"
                                  type="checkbox"
                                  class="ml-4 h-6 w-6 text-kl-purple border-gray-300 rounded border-transparent focus:border-transparent focus:ring-0"
                                />
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="flex items-center">
                                <div class=" ">
                                  <div
                                    class="text-sm font-medium text-gray-900"
                                  >
                                    Jane Cooper
                                  </div>
                                  <div class="text-sm text-gray-500">
                                    jane.cooper@example.com
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="text-sm text-gray-900">
                                Regional Paradigm Technician
                              </div>
                              <div class="text-sm text-gray-500">
                                Optimization
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <span
                                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                              >
                                Active
                              </span>
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              Admin
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                            >
                              <a
                                href="#"
                                class="text-indigo-600 hover:text-indigo-900"
                                >Edit</a
                              >
                            </td>
                          </tr>

                          <tr>
                            <td class="px-1 py-4 whitespace-nowrap">
                              <div class="flex items-center">
                                <input
                                  id="person-1"
                                  name="person-1"
                                  type="checkbox"
                                  class="ml-4 h-6 w-6 text-kl-purple border-gray-300 rounded border-transparent focus:border-transparent focus:ring-0"
                                />
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="flex items-center">
                                <div class=" ">
                                  <div
                                    class="text-sm font-medium text-gray-900"
                                  >
                                    Jane Cooper
                                  </div>
                                  <div class="text-sm text-gray-500">
                                    jane.cooper@example.com
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="text-sm text-gray-900">
                                Regional Paradigm Technician
                              </div>
                              <div class="text-sm text-gray-500">
                                Optimization
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <span
                                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                              >
                                Active
                              </span>
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              Admin
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                            >
                              <a
                                href="#"
                                class="text-indigo-600 hover:text-indigo-900"
                                >Edit</a
                              >
                            </td>
                          </tr>

                          <tr>
                            <td class="px-1 py-4 whitespace-nowrap">
                              <div class="flex items-center">
                                <input
                                  id="person-1"
                                  name="person-1"
                                  type="checkbox"
                                  class="ml-4 h-6 w-6 text-kl-purple border-gray-300 rounded border-transparent focus:border-transparent focus:ring-0"
                                />
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="flex items-center">
                                <div class=" ">
                                  <div
                                    class="text-sm font-medium text-gray-900"
                                  >
                                    Jane Cooper
                                  </div>
                                  <div class="text-sm text-gray-500">
                                    jane.cooper@example.com
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="text-sm text-gray-900">
                                Regional Paradigm Technician
                              </div>
                              <div class="text-sm text-gray-500">
                                Optimization
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <span
                                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                              >
                                Active
                              </span>
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              Admin
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                            >
                              <a
                                href="#"
                                class="text-indigo-600 hover:text-indigo-900"
                                >Edit</a
                              >
                            </td>
                          </tr>

                          <tr>
                            <td class="px-1 py-4 whitespace-nowrap">
                              <div class="flex items-center">
                                <input
                                  id="person-1"
                                  name="person-1"
                                  type="checkbox"
                                  class="ml-4 h-6 w-6 text-kl-purple border-gray-300 rounded border-transparent focus:border-transparent focus:ring-0"
                                />
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="flex items-center">
                                <div class=" ">
                                  <div
                                    class="text-sm font-medium text-gray-900"
                                  >
                                    Jane Cooper
                                  </div>
                                  <div class="text-sm text-gray-500">
                                    jane.cooper@example.com
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="text-sm text-gray-900">
                                Regional Paradigm Technician
                              </div>
                              <div class="text-sm text-gray-500">
                                Optimization
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <span
                                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                              >
                                Active
                              </span>
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              Admin
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                            >
                              <a
                                href="#"
                                class="text-indigo-600 hover:text-indigo-900"
                                >Edit</a
                              >
                            </td>
                          </tr>

                          <tr>
                            <td class="px-1 py-4 whitespace-nowrap">
                              <div class="flex ">
                                <input
                                  id="person-1"
                                  name="person-1"
                                  type="checkbox"
                                  class="ml-4 h-6 w-6 text-kl-purple border-gray-300 rounded border-transparent focus:border-transparent focus:ring-0"
                                />
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="flex items-center">
                                <div class=" ">
                                  <div
                                    class="text-sm font-medium text-gray-900"
                                  >
                                    Jane Cooper
                                  </div>
                                  <div class="text-sm text-gray-500">
                                    jane.cooper@example.com
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div class="text-sm text-gray-900">
                                Regional Paradigm Technician
                              </div>
                              <div class="text-sm text-gray-500">
                                Optimization
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <span
                                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                              >
                                Active
                              </span>
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              Admin
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                            >
                              <a
                                href="#"
                                class="text-indigo-600 hover:text-indigo-900"
                                >Edit</a
                              >
                            </td>
                          </tr>
                          <!-- More people... -->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</div>
