import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alignment',
  templateUrl: './alignment.component.html',
  styleUrls: ['./alignment.component.scss'],
})
export class AlignmentComponent implements OnInit {
  @Input() title: string = '';
  @Input() alignment: string | undefined = 'left';
  @Output() onAlignmentChange: EventEmitter<string> =
    new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  setAlignment(alignment: string) {
    this.alignment = alignment;
    this.onAlignmentChange.emit(this.alignment);
  }
}
