<div class="px-2">
  <button
    class="w-full my-1 hover:bg-gray-100 bg-white transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 py-2 text-sm"
    [ngStyle]="style"
    [ngClass]="{ 'w-full': mobileView, 'w-1/3': !mobileView }"
  >
    <div
      contenteditable="true"
      [innerHTML]="block.content"
      class="w-full bg-transparent px-2 border-transparent focus:border-transparent focus:ring-0"
      [ngClass]="getAlignment()"
      (blur)="update()"
      [id]="block.id"
    ></div>
  </button>
</div>
