import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { icons } from 'src/app/icons/icons';

@Component({
  selector: 'app-details-icon-library',
  templateUrl: './details-icon-library.component.html',
  styleUrls: ['./details-icon-library.component.scss'],
})
export class DetailsIconLibraryComponent implements OnInit {
  searchString: string = '';
  pos: number = 0;
  icons = icons;
  loadedIcons: string[] = [];
  loader: boolean = false;
  selectedIcon: string = '';
  @Output() selectedIconEvent = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {
    this.loader = true;
    setTimeout(() => {
      this.loader = false;
    }, 300);
    this.infiniteLoad();
  }

  infiniteLoad() {
    console.log('infinite load');
    if (this.searchString === '') {
      this.loadIcons();
      this.pos += 50;
    }
  }

  selectIcon(icon: string) {
    this.selectedIcon = icon;
    this.selectedIconEvent.emit(icon);
  }

  loadIcons() {
    if (icons.length <= this.pos) return;
    let moreIcons: string[] = icons.slice(this.pos, this.pos + 50);
    this.loadedIcons = this.loadedIcons.concat(moreIcons);
  }

  filterIcons() {
    this.loader = true;
    const filter = this.searchString.toLowerCase();
    this.loadedIcons = icons.filter((icon) => {
      return icon.indexOf(filter) !== -1;
    });
    this.loader = false;
  }
}
