import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Block } from 'src/app/models/block.model';
import { Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-sidebar-detail',
  templateUrl: './sidebar-detail.component.html',
  styleUrls: ['./sidebar-detail.component.scss'],
})
export class SidebarDetailComponent implements OnInit {
  state$: Subscription;
  isSidebarDetailOpen: boolean = false;
  sidebarIndex: number = 0;
  selectedElement: Block | null;
  currentProject: Project;
  pageIndex: number = 0;
  constructor(private appService: AppService) {
    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.isSidebarDetailOpen =
          state.editor.layout.sideDetail.isDetailSidebarOpen;

        this.sidebarIndex = state.editor.layout.sideDetail.detailSidebarIndex;
        this.selectedElement = state.editor.layout.designer.selectedElement;

        this.currentProject = state.editor.currentProject;
        this.pageIndex = state.editor.layout.pageBar.currentPageIndex;
      }
    });
  }
  ngOnInit(): void {}

  toggleSideBarDetail() {
    const sideBarDetailState: boolean = !this.isSidebarDetailOpen;
    this.appService.showDetailSidebar(sideBarDetailState);
  }
}
