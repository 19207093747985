export const icons = [
/*   'fab fa-500px',
  'fab fa-accessible-icon',
  'fab fa-accusoft',
  'fab fa-acquisitions-incorporated',
  'fas fa-ad',
  'fas fa-address-book',
  'far fa-address-book',
  'fas fa-address-card',
  'far fa-address-card',
  'fas fa-adjust',
  'fab fa-adn',
  'fab fa-adversal',
  'fab fa-affiliatetheme',
  'fas fa-air-freshener',
  'fab fa-airbnb',
  'fab fa-algolia',
  'fas fa-align-center',
  'fas fa-align-justify',
  'fas fa-align-left',
  'fas fa-align-right',
  'fab fa-alipay',
  'fas fa-allergies',
  'fab fa-amazon',
  'fab fa-amazon-pay',
  'fas fa-ambulance',
  'fas fa-american-sign-language-interpreting',
  'fab fa-amilia',
  'fas fa-anchor',
  'fab fa-android',
  'fab fa-angellist',
  'fas fa-angle-double-down',
  'fas fa-angle-double-left',
  'fas fa-angle-double-right',
  'fas fa-angle-double-up',
  'fas fa-angle-down',
  'fas fa-angle-left',
  'fas fa-angle-right',
  'fas fa-angle-up',
  'fas fa-angry',
  'far fa-angry',
  'fab fa-angrycreative',
  'fab fa-angular',
  'fas fa-ankh',
  'fab fa-app-store',
  'fab fa-app-store-ios',
  'fab fa-apper',
  'fab fa-apple',
  'fas fa-apple-alt',
  'fab fa-apple-pay',
  'fas fa-archive',
  'fas fa-archway',
  'fas fa-arrow-alt-circle-down',
  'far fa-arrow-alt-circle-down',
  'fas fa-arrow-alt-circle-left',
  'far fa-arrow-alt-circle-left',
  'fas fa-arrow-alt-circle-right',
  'far fa-arrow-alt-circle-right',
  'fas fa-arrow-alt-circle-up',
  'far fa-arrow-alt-circle-up',
  'fas fa-arrow-circle-down',
  'fas fa-arrow-circle-left',
  'fas fa-arrow-circle-right',
  'fas fa-arrow-circle-up',
  'fas fa-arrow-down',
  'fas fa-arrow-left',
  'fas fa-arrow-right',
  'fas fa-arrow-up',
  'fas fa-arrows-alt',
  'fas fa-arrows-alt-h',
  'fas fa-arrows-alt-v',
  'fab fa-artstation',
  'fas fa-assistive-listening-systems',
  'fas fa-asterisk',
  'fab fa-asymmetrik',
  'fas fa-at',
  'fas fa-atlas',
  'fab fa-atlassian',
  'fas fa-atom',
  'fab fa-audible',
  'fas fa-audio-description',
  'fab fa-autoprefixer',
  'fab fa-avianex',
  'fab fa-aviato',
  'fas fa-award',
  'fab fa-aws',
  'fas fa-baby',
  'fas fa-baby-carriage',
  'fas fa-backspace',
  'fas fa-backward',
  'fas fa-bacon',
  'fas fa-bacteria',
  'fas fa-bacterium',
  'fas fa-bahai',
  'fas fa-balance-scale',
  'fas fa-balance-scale-left',
  'fas fa-balance-scale-right',
  'fas fa-ban',
  'fas fa-band-aid',
  'fab fa-bandcamp',
  'fas fa-barcode',
  'fas fa-bars',
  'fas fa-baseball-ball',
  'fas fa-basketball-ball',
  'fas fa-bath',
  'fas fa-battery-empty',
  'fas fa-battery-full',
  'fas fa-battery-half',
  'fas fa-battery-quarter',
  'fas fa-battery-three-quarters',
  'fab fa-battle-net',
  'fas fa-bed',
  'fas fa-beer',
  'fab fa-behance',
  'fab fa-behance-square',
  'fas fa-bell',
  'far fa-bell',
  'fas fa-bell-slash',
  'far fa-bell-slash',
  'fas fa-bezier-curve',
  'fas fa-bible',
  'fas fa-bicycle',
  'fas fa-biking',
  'fab fa-bimobject',
  'fas fa-binoculars',
  'fas fa-biohazard',
  'fas fa-birthday-cake',
  'fab fa-bitbucket',
  'fab fa-bitcoin',
  'fab fa-bity',
  'fab fa-black-tie',
  'fab fa-blackberry',
  'fas fa-blender',
  'fas fa-blender-phone',
  'fas fa-blind',
  'fas fa-blog',
  'fab fa-blogger',
  'fab fa-blogger-b',
  'fab fa-bluetooth',
  'fab fa-bluetooth-b',
  'fas fa-bold',
  'fas fa-bolt',
  'fas fa-bomb',
  'fas fa-bone',
  'fas fa-bong',
  'fas fa-book',
  'fas fa-book-dead',
  'fas fa-book-medical',
  'fas fa-book-open',
  'fas fa-book-reader',
  'fas fa-bookmark',
  'far fa-bookmark',
  'fab fa-bootstrap',
  'fas fa-border-all',
  'fas fa-border-none',
  'fas fa-border-style',
  'fas fa-bowling-ball',
  'fas fa-box',
  'fas fa-box-open',
  'fas fa-box-tissue',
  'fas fa-boxes',
  'fas fa-braille',
  'fas fa-brain',
  'fas fa-bread-slice',
  'fas fa-briefcase',
  'fas fa-briefcase-medical',
  'fas fa-broadcast-tower',
  'fas fa-broom',
  'fas fa-brush',
  'fab fa-btc',
  'fab fa-buffer',
  'fas fa-bug',
  'fas fa-building',
  'far fa-building',
  'fas fa-bullhorn',
  'fas fa-bullseye',
  'fas fa-burn',
  'fab fa-buromobelexperte',
  'fas fa-bus',
  'fas fa-bus-alt',
  'fas fa-business-time',
  'fab fa-buy-n-large',
  'fab fa-buysellads',
  'fas fa-calculator',
  'fas fa-calendar',
  'far fa-calendar',
  'fas fa-calendar-alt',
  'far fa-calendar-alt',
  'fas fa-calendar-check',
  'far fa-calendar-check',
  'fas fa-calendar-day',
  'fas fa-calendar-minus',
  'far fa-calendar-minus',
  'fas fa-calendar-plus',
  'far fa-calendar-plus',
  'fas fa-calendar-times',
  'far fa-calendar-times',
  'fas fa-calendar-week',
  'fas fa-camera',
  'fas fa-camera-retro',
  'fas fa-campground',
  'fab fa-canadian-maple-leaf',
  'fas fa-candy-cane',
  'fas fa-cannabis',
  'fas fa-capsules',
  'fas fa-car',
  'fas fa-car-alt',
  'fas fa-car-battery',
  'fas fa-car-crash',
  'fas fa-car-side',
  'fas fa-caravan',
  'fas fa-caret-down',
  'fas fa-caret-left',
  'fas fa-caret-right',
  'fas fa-caret-square-down',
  'far fa-caret-square-down',
  'fas fa-caret-square-left',
  'far fa-caret-square-left',
  'fas fa-caret-square-right',
  'far fa-caret-square-right',
  'fas fa-caret-square-up',
  'far fa-caret-square-up',
  'fas fa-caret-up',
  'fas fa-carrot',
  'fas fa-cart-arrow-down',
  'fas fa-cart-plus',
  'fas fa-cash-register',
  'fas fa-cat',
  'fab fa-cc-amazon-pay',
  'fab fa-cc-amex',
  'fab fa-cc-apple-pay',
  'fab fa-cc-diners-club',
  'fab fa-cc-discover',
  'fab fa-cc-jcb',
  'fab fa-cc-mastercard',
  'fab fa-cc-paypal',
  'fab fa-cc-stripe',
  'fab fa-cc-visa',
  'fab fa-centercode',
  'fab fa-centos',
  'fas fa-certificate',
  'fas fa-chair',
  'fas fa-chalkboard',
  'fas fa-chalkboard-teacher',
  'fas fa-charging-station',
  'fas fa-chart-area',
  'fas fa-chart-bar',
  'far fa-chart-bar',
  'fas fa-chart-line',
  'fas fa-chart-pie',
  'fas fa-check',
  'fas fa-check-circle',
  'far fa-check-circle',
  'fas fa-check-double',
  'fas fa-check-square',
  'far fa-check-square',
  'fas fa-cheese',
  'fas fa-chess',
  'fas fa-chess-bishop',
  'fas fa-chess-board',
  'fas fa-chess-king',
  'fas fa-chess-knight',
  'fas fa-chess-pawn',
  'fas fa-chess-queen',
  'fas fa-chess-rook',
  'fas fa-chevron-circle-down',
  'fas fa-chevron-circle-left',
  'fas fa-chevron-circle-right',
  'fas fa-chevron-circle-up',
  'fas fa-chevron-down',
  'fas fa-chevron-left',
  'fas fa-chevron-right',
  'fas fa-chevron-up',
  'fas fa-child',
  'fab fa-chrome',
  'fab fa-chromecast',
  'fas fa-church',
  'fas fa-circle',
  'far fa-circle',
  'fas fa-circle-notch',
  'fas fa-city',
  'fas fa-clinic-medical',
  'fas fa-clipboard',
  'far fa-clipboard',
  'fas fa-clipboard-check',
  'fas fa-clipboard-list',
  'fas fa-clock',
  'far fa-clock',
  'fas fa-clone',
  'far fa-clone',
  'fas fa-closed-captioning',
  'far fa-closed-captioning',
  'fas fa-cloud',
  'fas fa-cloud-download-alt',
  'fas fa-cloud-meatball',
  'fas fa-cloud-moon',
  'fas fa-cloud-moon-rain',
  'fas fa-cloud-rain',
  'fas fa-cloud-showers-heavy',
  'fas fa-cloud-sun',
  'fas fa-cloud-sun-rain',
  'fas fa-cloud-upload-alt',
  'fab fa-cloudflare',
  'fab fa-cloudscale',
  'fab fa-cloudsmith',
  'fab fa-cloudversify',
  'fas fa-cocktail',
  'fas fa-code',
  'fas fa-code-branch',
  'fab fa-codepen',
  'fab fa-codiepie',
  'fas fa-coffee',
  'fas fa-cog',
  'fas fa-cogs',
  'fas fa-coins',
  'fas fa-columns',
  'fas fa-comment',
  'far fa-comment',
  'fas fa-comment-alt',
  'far fa-comment-alt',
  'fas fa-comment-dollar',
  'fas fa-comment-dots',
  'far fa-comment-dots',
  'fas fa-comment-medical',
  'fas fa-comment-slash',
  'fas fa-comments',
  'far fa-comments',
  'fas fa-comments-dollar',
  'fas fa-compact-disc',
  'fas fa-compass',
  'far fa-compass',
  'fas fa-compress',
  'fas fa-compress-alt',
  'fas fa-compress-arrows-alt',
  'fas fa-concierge-bell',
  'fab fa-confluence',
  'fab fa-connectdevelop',
  'fab fa-contao',
  'fas fa-cookie',
  'fas fa-cookie-bite',
  'fas fa-copy',
  'far fa-copy',
  'fas fa-copyright',
  'far fa-copyright',
  'fab fa-cotton-bureau',
  'fas fa-couch',
  'fab fa-cpanel',
  'fab fa-creative-commons',
  'fab fa-creative-commons-by',
  'fab fa-creative-commons-nc',
  'fab fa-creative-commons-nc-eu',
  'fab fa-creative-commons-nc-jp',
  'fab fa-creative-commons-nd',
  'fab fa-creative-commons-pd',
  'fab fa-creative-commons-pd-alt',
  'fab fa-creative-commons-remix',
  'fab fa-creative-commons-sa',
  'fab fa-creative-commons-sampling',
  'fab fa-creative-commons-sampling-plus',
  'fab fa-creative-commons-share',
  'fab fa-creative-commons-zero',
  'fas fa-credit-card',
  'far fa-credit-card',
  'fab fa-critical-role',
  'fas fa-crop',
  'fas fa-crop-alt',
  'fas fa-cross',
  'fas fa-crosshairs',
  'fas fa-crow',
  'fas fa-crown',
  'fas fa-crutch',
  'fab fa-css3',
  'fab fa-css3-alt',
  'fas fa-cube',
  'fas fa-cubes',
  'fas fa-cut',
  'fab fa-cuttlefish',
  'fab fa-d-and-d',
  'fab fa-d-and-d-beyond',
  'fab fa-dailymotion',
  'fab fa-dashcube',
  'fas fa-database',
  'fas fa-deaf',
  'fab fa-deezer',
  'fab fa-delicious',
  'fas fa-democrat',
  'fab fa-deploydog',
  'fab fa-deskpro',
  'fas fa-desktop',
  'fab fa-dev',
  'fab fa-deviantart',
  'fas fa-dharmachakra',
  'fab fa-dhl',
  'fas fa-diagnoses',
  'fab fa-diaspora',
  'fas fa-dice',
  'fas fa-dice-d20',
  'fas fa-dice-d6',
  'fas fa-dice-five',
  'fas fa-dice-four',
  'fas fa-dice-one',
  'fas fa-dice-six',
  'fas fa-dice-three',
  'fas fa-dice-two',
  'fab fa-digg',
  'fab fa-digital-ocean',
  'fas fa-digital-tachograph',
  'fas fa-directions',
  'fab fa-discord',
  'fab fa-discourse',
  'fas fa-disease',
  'fas fa-divide',
  'fas fa-dizzy',
  'far fa-dizzy',
  'fas fa-dna',
  'fab fa-dochub',
  'fab fa-docker',
  'fas fa-dog',
  'fas fa-dollar-sign',
  'fas fa-dolly',
  'fas fa-dolly-flatbed',
  'fas fa-donate',
  'fas fa-door-closed',
  'fas fa-door-open',
  'fas fa-dot-circle',
  'far fa-dot-circle',
  'fas fa-dove',
  'fas fa-download',
  'fab fa-draft2digital',
  'fas fa-drafting-compass',
  'fas fa-dragon',
  'fas fa-draw-polygon',
  'fab fa-dribbble',
  'fab fa-dribbble-square',
  'fab fa-dropbox',
  'fas fa-drum',
  'fas fa-drum-steelpan',
  'fas fa-drumstick-bite',
  'fab fa-drupal',
  'fas fa-dumbbell',
  'fas fa-dumpster',
  'fas fa-dumpster-fire',
  'fas fa-dungeon',
  'fab fa-dyalog',
  'fab fa-earlybirds',
  'fab fa-ebay',
  'fab fa-edge',
  'fab fa-edge-legacy',
  'fas fa-edit',
  'far fa-edit',
  'fas fa-egg',
  'fas fa-eject',
  'fab fa-elementor',
  'fas fa-ellipsis-h',
  'fas fa-ellipsis-v',
  'fab fa-ello',
  'fab fa-ember',
  'fab fa-empire',
  'fas fa-envelope',
  'far fa-envelope',
  'fas fa-envelope-open',
  'far fa-envelope-open',
  'fas fa-envelope-open-text',
  'fas fa-envelope-square',
  'fab fa-envira',
  'fas fa-equals',
  'fas fa-eraser',
  'fab fa-erlang',
  'fab fa-ethereum',
  'fas fa-ethernet',
  'fab fa-etsy',
  'fas fa-euro-sign',
  'fab fa-evernote',
  'fas fa-exchange-alt',
  'fas fa-exclamation',
  'fas fa-exclamation-circle',
  'fas fa-exclamation-triangle',
  'fas fa-expand',
  'fas fa-expand-alt',
  'fas fa-expand-arrows-alt',
  'fab fa-expeditedssl',
  'fas fa-external-link-alt',
  'fas fa-external-link-square-alt',
  'fas fa-eye',
  'far fa-eye',
  'fas fa-eye-dropper',
  'fas fa-eye-slash',
  'far fa-eye-slash',
  'fab fa-facebook',
  'fab fa-facebook-f',
  'fab fa-facebook-messenger',
  'fab fa-facebook-square',
  'fas fa-fan',
  'fab fa-fantasy-flight-games',
  'fas fa-fast-backward',
  'fas fa-fast-forward',
  'fas fa-faucet',
  'fas fa-fax',
  'fas fa-feather',
  'fas fa-feather-alt',
  'fab fa-fedex',
  'fab fa-fedora',
  'fas fa-female',
  'fas fa-fighter-jet',
  'fab fa-figma',
  'fas fa-file',
  'far fa-file',
  'fas fa-file-alt',
  'far fa-file-alt',
  'fas fa-file-archive',
  'far fa-file-archive',
  'fas fa-file-audio',
  'far fa-file-audio',
  'fas fa-file-code',
  'far fa-file-code',
  'fas fa-file-contract',
  'fas fa-file-csv',
  'fas fa-file-download',
  'fas fa-file-excel',
  'far fa-file-excel',
  'fas fa-file-export',
  'fas fa-file-image',
  'far fa-file-image',
  'fas fa-file-import',
  'fas fa-file-invoice',
  'fas fa-file-invoice-dollar',
  'fas fa-file-medical',
  'fas fa-file-medical-alt',
  'fas fa-file-pdf',
  'far fa-file-pdf',
  'fas fa-file-powerpoint',
  'far fa-file-powerpoint',
  'fas fa-file-prescription',
  'fas fa-file-signature',
  'fas fa-file-upload',
  'fas fa-file-video',
  'far fa-file-video',
  'fas fa-file-word',
  'far fa-file-word',
  'fas fa-fill',
  'fas fa-fill-drip',
  'fas fa-film',
  'fas fa-filter',
  'fas fa-fingerprint',
  'fas fa-fire',
  'fas fa-fire-alt',
  'fas fa-fire-extinguisher',
  'fab fa-firefox',
  'fab fa-firefox-browser',
  'fas fa-first-aid',
  'fab fa-first-order',
  'fab fa-first-order-alt',
  'fab fa-firstdraft',
  'fas fa-fish',
  'fas fa-fist-raised',
  'fas fa-flag',
  'far fa-flag',
  'fas fa-flag-checkered',
  'fas fa-flag-usa',
  'fas fa-flask',
  'fab fa-flickr',
  'fab fa-flipboard',
  'fas fa-flushed',
  'far fa-flushed',
  'fab fa-fly',
  'fas fa-folder',
  'far fa-folder',
  'fas fa-folder-minus',
  'fas fa-folder-open',
  'far fa-folder-open',
  'fas fa-folder-plus',
  'fas fa-font',
  'fab fa-font-awesome',
  'fab fa-font-awesome-alt',
  'fab fa-font-awesome-flag',
  'fab fa-fonticons',
  'fab fa-fonticons-fi',
  'fas fa-football-ball',
  'fab fa-fort-awesome',
  'fab fa-fort-awesome-alt',
  'fab fa-forumbee',
  'fas fa-forward',
  'fab fa-foursquare',
  'fab fa-free-code-camp',
  'fab fa-freebsd',
  'fas fa-frog',
  'fas fa-frown',
  'far fa-frown',
  'fas fa-frown-open',
  'far fa-frown-open',
  'fab fa-fulcrum',
  'fas fa-funnel-dollar',
  'fas fa-futbol',
  'far fa-futbol',
  'fab fa-galactic-republic',
  'fab fa-galactic-senate',
  'fas fa-gamepad',
  'fas fa-gas-pump',
  'fas fa-gavel',
  'fas fa-gem',
  'far fa-gem',
  'fas fa-genderless',
  'fab fa-get-pocket',
  'fab fa-gg',
  'fab fa-gg-circle',
  'fas fa-ghost',
  'fas fa-gift',
  'fas fa-gifts',
  'fab fa-git',
  'fab fa-git-alt',
  'fab fa-git-square',
  'fab fa-github',
  'fab fa-github-alt',
  'fab fa-github-square',
  'fab fa-gitkraken',
  'fab fa-gitlab',
  'fab fa-gitter',
  'fas fa-glass-cheers',
  'fas fa-glass-martini',
  'fas fa-glass-martini-alt',
  'fas fa-glass-whiskey',
  'fas fa-glasses',
  'fab fa-glide',
  'fab fa-glide-g',
  'fas fa-globe',
  'fas fa-globe-africa',
  'fas fa-globe-americas',
  'fas fa-globe-asia',
  'fas fa-globe-europe',
  'fab fa-gofore',
  'fas fa-golf-ball',
  'fab fa-goodreads',
  'fab fa-goodreads-g',
  'fab fa-google',
  'fab fa-google-drive',
  'fab fa-google-pay',
  'fab fa-google-play',
  'fab fa-google-plus',
  'fab fa-google-plus-g',
  'fab fa-google-plus-square',
  'fab fa-google-wallet',
  'fas fa-gopuram',
  'fas fa-graduation-cap',
  'fab fa-gratipay',
  'fab fa-grav',
  'fas fa-greater-than',
  'fas fa-greater-than-equal',
  'fas fa-grimace',
  'far fa-grimace',
  'fas fa-grin',
  'far fa-grin',
  'fas fa-grin-alt',
  'far fa-grin-alt',
  'fas fa-grin-beam',
  'far fa-grin-beam',
  'fas fa-grin-beam-sweat',
  'far fa-grin-beam-sweat',
  'fas fa-grin-hearts',
  'far fa-grin-hearts',
  'fas fa-grin-squint',
  'far fa-grin-squint',
  'fas fa-grin-squint-tears',
  'far fa-grin-squint-tears',
  'fas fa-grin-stars',
  'far fa-grin-stars',
  'fas fa-grin-tears',
  'far fa-grin-tears',
  'fas fa-grin-tongue',
  'far fa-grin-tongue',
  'fas fa-grin-tongue-squint',
  'far fa-grin-tongue-squint',
  'fas fa-grin-tongue-wink',
  'far fa-grin-tongue-wink',
  'fas fa-grin-wink',
  'far fa-grin-wink',
  'fas fa-grip-horizontal',
  'fas fa-grip-lines',
  'fas fa-grip-lines-vertical',
  'fas fa-grip-vertical',
  'fab fa-gripfire',
  'fab fa-grunt',
  'fab fa-guilded',
  'fas fa-guitar',
  'fab fa-gulp',
  'fas fa-h-square',
  'fab fa-hacker-news',
  'fab fa-hacker-news-square',
  'fab fa-hackerrank',
  'fas fa-hamburger',
  'fas fa-hammer',
  'fas fa-hamsa',
  'fas fa-hand-holding',
  'fas fa-hand-holding-heart',
  'fas fa-hand-holding-medical',
  'fas fa-hand-holding-usd',
  'fas fa-hand-holding-water',
  'fas fa-hand-lizard',
  'far fa-hand-lizard',
  'fas fa-hand-middle-finger',
  'fas fa-hand-paper',
  'far fa-hand-paper',
  'fas fa-hand-peace',
  'far fa-hand-peace',
  'fas fa-hand-point-down',
  'far fa-hand-point-down',
  'fas fa-hand-point-left',
  'far fa-hand-point-left',
  'fas fa-hand-point-right',
  'far fa-hand-point-right',
  'fas fa-hand-point-up',
  'far fa-hand-point-up',
  'fas fa-hand-pointer',
  'far fa-hand-pointer',
  'fas fa-hand-rock',
  'far fa-hand-rock',
  'fas fa-hand-scissors',
  'far fa-hand-scissors',
  'fas fa-hand-sparkles',
  'fas fa-hand-spock',
  'far fa-hand-spock',
  'fas fa-hands',
  'fas fa-hands-helping',
  'fas fa-hands-wash',
  'fas fa-handshake',
  'far fa-handshake',
  'fas fa-handshake-alt-slash',
  'fas fa-handshake-slash',
  'fas fa-hanukiah',
  'fas fa-hard-hat',
  'fas fa-hashtag',
  'fas fa-hat-cowboy',
  'fas fa-hat-cowboy-side',
  'fas fa-hat-wizard',
  'fas fa-hdd',
  'far fa-hdd',
  'fas fa-head-side-cough',
  'fas fa-head-side-cough-slash',
  'fas fa-head-side-mask',
  'fas fa-head-side-virus',
  'fas fa-heading',
  'fas fa-headphones',
  'fas fa-headphones-alt',
  'fas fa-headset',
  'fas fa-heart',
  'far fa-heart',
  'fas fa-heart-broken',
  'fas fa-heartbeat',
  'fas fa-helicopter',
  'fas fa-highlighter',
  'fas fa-hiking',
  'fas fa-hippo',
  'fab fa-hips',
  'fab fa-hire-a-helper',
  'fas fa-history',
  'fab fa-hive',
  'fas fa-hockey-puck',
  'fas fa-holly-berry',
  'fas fa-home',
  'fab fa-hooli',
  'fab fa-hornbill',
  'fas fa-horse',
  'fas fa-horse-head',
  'fas fa-hospital',
  'far fa-hospital',
  'fas fa-hospital-alt',
  'fas fa-hospital-symbol',
  'fas fa-hospital-user',
  'fas fa-hot-tub',
  'fas fa-hotdog',
  'fas fa-hotel',
  'fab fa-hotjar',
  'fas fa-hourglass',
  'far fa-hourglass',
  'fas fa-hourglass-end',
  'fas fa-hourglass-half',
  'fas fa-hourglass-start',
  'fas fa-house-damage',
  'fas fa-house-user',
  'fab fa-houzz',
  'fas fa-hryvnia',
  'fab fa-html5',
  'fab fa-hubspot',
  'fas fa-i-cursor',
  'fas fa-ice-cream',
  'fas fa-icicles',
  'fas fa-icons',
  'fas fa-id-badge',
  'far fa-id-badge',
  'fas fa-id-card',
  'far fa-id-card',
  'fas fa-id-card-alt',
  'fab fa-ideal',
  'fas fa-igloo',
  'fas fa-image',
  'far fa-image',
  'fas fa-images',
  'far fa-images',
  'fab fa-imdb',
  'fas fa-inbox',
  'fas fa-indent',
  'fas fa-industry',
  'fas fa-infinity',
  'fas fa-info',
  'fas fa-info-circle',
  'fab fa-innosoft',
  'fab fa-instagram',
  'fab fa-instagram-square',
  'fab fa-instalod',
  'fab fa-intercom',
  'fab fa-internet-explorer',
  'fab fa-invision',
  'fab fa-ioxhost',
  'fas fa-italic',
  'fab fa-itch-io',
  'fab fa-itunes',
  'fab fa-itunes-note',
  'fab fa-java',
  'fas fa-jedi',
  'fab fa-jedi-order',
  'fab fa-jenkins',
  'fab fa-jira',
  'fab fa-joget',
  'fas fa-joint',
  'fab fa-joomla',
  'fas fa-journal-whills',
  'fab fa-js',
  'fab fa-js-square',
  'fab fa-jsfiddle',
  'fas fa-kaaba',
  'fab fa-kaggle',
  'fas fa-key',
  'fab fa-keybase',
  'fas fa-keyboard',
  'far fa-keyboard',
  'fab fa-keycdn',
  'fas fa-khanda',
  'fab fa-kickstarter',
  'fab fa-kickstarter-k',
  'fas fa-kiss',
  'far fa-kiss',
  'fas fa-kiss-beam',
  'far fa-kiss-beam',
  'fas fa-kiss-wink-heart',
  'far fa-kiss-wink-heart',
  'fas fa-kiwi-bird',
  'fab fa-korvue',
  'fas fa-landmark',
  'fas fa-language',
  'fas fa-laptop',
  'fas fa-laptop-code',
  'fas fa-laptop-house',
  'fas fa-laptop-medical',
  'fab fa-laravel',
  'fab fa-lastfm',
  'fab fa-lastfm-square',
  'fas fa-laugh',
  'far fa-laugh',
  'fas fa-laugh-beam',
  'far fa-laugh-beam',
  'fas fa-laugh-squint',
  'far fa-laugh-squint',
  'fas fa-laugh-wink',
  'far fa-laugh-wink',
  'fas fa-layer-group',
  'fas fa-leaf',
  'fab fa-leanpub',
  'fas fa-lemon',
  'far fa-lemon',
  'fab fa-less',
  'fas fa-less-than',
  'fas fa-less-than-equal',
  'fas fa-level-down-alt',
  'fas fa-level-up-alt',
  'fas fa-life-ring',
  'far fa-life-ring',
  'fas fa-lightbulb',
  'far fa-lightbulb',
  'fab fa-line',
  'fas fa-link',
  'fab fa-linkedin',
  'fab fa-linkedin-in',
  'fab fa-linode',
  'fab fa-linux',
  'fas fa-lira-sign',
  'fas fa-list',
  'fas fa-list-alt',
  'far fa-list-alt',
  'fas fa-list-ol',
  'fas fa-list-ul',
  'fas fa-location-arrow',
  'fas fa-lock',
  'fas fa-lock-open',
  'fas fa-long-arrow-alt-down',
  'fas fa-long-arrow-alt-left',
  'fas fa-long-arrow-alt-right',
  'fas fa-long-arrow-alt-up',
  'fas fa-low-vision',
  'fas fa-luggage-cart',
  'fas fa-lungs',
  'fas fa-lungs-virus',
  'fab fa-lyft',
  'fab fa-magento',
  'fas fa-magic',
  'fas fa-magnet',
  'fas fa-mail-bulk',
  'fab fa-mailchimp',
  'fas fa-male',
  'fab fa-mandalorian',
  'fas fa-map',
  'far fa-map',
  'fas fa-map-marked',
  'fas fa-map-marked-alt',
  'fas fa-map-marker',
  'fas fa-map-marker-alt',
  'fas fa-map-pin',
  'fas fa-map-signs',
  'fab fa-markdown',
  'fas fa-marker',
  'fas fa-mars',
  'fas fa-mars-double',
  'fas fa-mars-stroke',
  'fas fa-mars-stroke-h',
  'fas fa-mars-stroke-v',
  'fas fa-mask',
  'fab fa-mastodon',
  'fab fa-maxcdn',
  'fab fa-mdb',
  'fas fa-medal',
  'fab fa-medapps',
  'fab fa-medium',
  'fab fa-medium-m',
  'fas fa-medkit',
  'fab fa-medrt',
  'fab fa-meetup',
  'fab fa-megaport',
  'fas fa-meh',
  'far fa-meh',
  'fas fa-meh-blank',
  'far fa-meh-blank',
  'fas fa-meh-rolling-eyes',
  'far fa-meh-rolling-eyes',
  'fas fa-memory',
  'fab fa-mendeley',
  'fas fa-menorah',
  'fas fa-mercury',
  'fas fa-meteor',
  'fab fa-microblog',
  'fas fa-microchip',
  'fas fa-microphone',
  'fas fa-microphone-alt',
  'fas fa-microphone-alt-slash',
  'fas fa-microphone-slash',
  'fas fa-microscope',
  'fab fa-microsoft',
  'fas fa-minus',
  'fas fa-minus-circle',
  'fas fa-minus-square',
  'far fa-minus-square',
  'fas fa-mitten',
  'fab fa-mix',
  'fab fa-mixcloud',
  'fab fa-mixer',
  'fab fa-mizuni',
  'fas fa-mobile',
  'fas fa-mobile-alt',
  'fab fa-modx',
  'fab fa-monero',
  'fas fa-money-bill',
  'fas fa-money-bill-alt',
  'far fa-money-bill-alt',
  'fas fa-money-bill-wave',
  'fas fa-money-bill-wave-alt',
  'fas fa-money-check',
  'fas fa-money-check-alt',
  'fas fa-monument',
  'fas fa-moon',
  'far fa-moon',
  'fas fa-mortar-pestle',
  'fas fa-mosque',
  'fas fa-motorcycle',
  'fas fa-mountain',
  'fas fa-mouse',
  'fas fa-mouse-pointer',
  'fas fa-mug-hot',
  'fas fa-music',
  'fab fa-napster',
  'fab fa-neos',
  'fas fa-network-wired',
  'fas fa-neuter',
  'fas fa-newspaper',
  'far fa-newspaper',
  'fab fa-nimblr',
  'fab fa-node',
  'fab fa-node-js',
  'fas fa-not-equal',
  'fas fa-notes-medical',
  'fab fa-npm',
  'fab fa-ns8',
  'fab fa-nutritionix',
  'fas fa-object-group',
  'far fa-object-group',
  'fas fa-object-ungroup',
  'far fa-object-ungroup',
  'fab fa-octopus-deploy',
  'fab fa-odnoklassniki',
  'fab fa-odnoklassniki-square',
  'fas fa-oil-can',
  'fab fa-old-republic',
  'fas fa-om',
  'fab fa-opencart',
  'fab fa-openid',
  'fab fa-opera',
  'fab fa-optin-monster',
  'fab fa-orcid',
  'fab fa-osi',
  'fas fa-otter',
  'fas fa-outdent',
  'fab fa-page4',
  'fab fa-pagelines',
  'fas fa-pager',
  'fas fa-paint-brush',
  'fas fa-paint-roller',
  'fas fa-palette',
  'fab fa-palfed',
  'fas fa-pallet',
  'fas fa-paper-plane',
  'far fa-paper-plane',
  'fas fa-paperclip',
  'fas fa-parachute-box',
  'fas fa-paragraph',
  'fas fa-parking',
  'fas fa-passport',
  'fas fa-pastafarianism',
  'fas fa-paste',
  'fab fa-patreon',
  'fas fa-pause',
  'fas fa-pause-circle',
  'far fa-pause-circle',
  'fas fa-paw',
  'fab fa-paypal',
  'fas fa-peace',
  'fas fa-pen',
  'fas fa-pen-alt',
  'fas fa-pen-fancy',
  'fas fa-pen-nib',
  'fas fa-pen-square',
  'fas fa-pencil-alt',
  'fas fa-pencil-ruler',
  'fab fa-penny-arcade',
  'fas fa-people-arrows',
  'fas fa-people-carry',
  'fas fa-pepper-hot',
  'fab fa-perbyte',
  'fas fa-percent',
  'fas fa-percentage',
  'fab fa-periscope',
  'fas fa-person-booth',
  'fab fa-phabricator',
  'fab fa-phoenix-framework',
  'fab fa-phoenix-squadron',
  'fas fa-phone',
  'fas fa-phone-alt',
  'fas fa-phone-slash',
  'fas fa-phone-square',
  'fas fa-phone-square-alt',
  'fas fa-phone-volume',
  'fas fa-photo-video',
  'fab fa-php',
  'fab fa-pied-piper',
  'fab fa-pied-piper-alt',
  'fab fa-pied-piper-hat',
  'fab fa-pied-piper-pp',
  'fab fa-pied-piper-square',
  'fas fa-piggy-bank',
  'fas fa-pills',
  'fab fa-pinterest',
  'fab fa-pinterest-p',
  'fab fa-pinterest-square',
  'fas fa-pizza-slice',
  'fas fa-place-of-worship',
  'fas fa-plane',
  'fas fa-plane-arrival',
  'fas fa-plane-departure',
  'fas fa-plane-slash',
  'fas fa-play',
  'fas fa-play-circle',
  'far fa-play-circle',
  'fab fa-playstation',
  'fas fa-plug',
  'fas fa-plus',
  'fas fa-plus-circle',
  'fas fa-plus-square',
  'far fa-plus-square',
  'fas fa-podcast',
  'fas fa-poll',
  'fas fa-poll-h',
  'fas fa-poo',
  'fas fa-poo-storm',
  'fas fa-poop',
  'fas fa-portrait',
  'fas fa-pound-sign',
  'fas fa-power-off',
  'fas fa-pray',
  'fas fa-praying-hands',
  'fas fa-prescription',
  'fas fa-prescription-bottle',
  'fas fa-prescription-bottle-alt',
  'fas fa-print',
  'fas fa-procedures',
  'fab fa-product-hunt',
  'fas fa-project-diagram',
  'fas fa-pump-medical',
  'fas fa-pump-soap',
  'fab fa-pushed',
  'fas fa-puzzle-piece',
  'fab fa-python',
  'fab fa-qq',
  'fas fa-qrcode',
  'fas fa-question',
  'fas fa-question-circle',
  'far fa-question-circle',
  'fas fa-quidditch',
  'fab fa-quinscape',
  'fab fa-quora',
  'fas fa-quote-left',
  'fas fa-quote-right',
  'fas fa-quran',
  'fab fa-r-project',
  'fas fa-radiation',
  'fas fa-radiation-alt',
  'fas fa-rainbow',
  'fas fa-random',
  'fab fa-raspberry-pi',
  'fab fa-ravelry',
  'fab fa-react',
  'fab fa-reacteurope',
  'fab fa-readme',
  'fab fa-rebel',
  'fas fa-receipt',
  'fas fa-record-vinyl',
  'fas fa-recycle',
  'fab fa-red-river',
  'fab fa-reddit',
  'fab fa-reddit-alien',
  'fab fa-reddit-square',
  'fab fa-redhat',
  'fas fa-redo',
  'fas fa-redo-alt',
  'fas fa-registered',
  'far fa-registered',
  'fas fa-remove-format',
  'fab fa-renren',
  'fas fa-reply',
  'fas fa-reply-all',
  'fab fa-replyd',
  'fas fa-republican',
  'fab fa-researchgate',
  'fab fa-resolving',
  'fas fa-restroom',
  'fas fa-retweet',
  'fab fa-rev',
  'fas fa-ribbon',
  'fas fa-ring',
  'fas fa-road',
  'fas fa-robot',
  'fas fa-rocket',
  'fab fa-rocketchat',
  'fab fa-rockrms',
  'fas fa-route',
  'fas fa-rss',
  'fas fa-rss-square',
  'fas fa-ruble-sign',
  'fas fa-ruler',
  'fas fa-ruler-combined',
  'fas fa-ruler-horizontal',
  'fas fa-ruler-vertical',
  'fas fa-running',
  'fas fa-rupee-sign',
  'fab fa-rust',
  'fas fa-sad-cry',
  'far fa-sad-cry',
  'fas fa-sad-tear',
  'far fa-sad-tear',
  'fab fa-safari',
  'fab fa-salesforce',
  'fab fa-sass',
  'fas fa-satellite',
  'fas fa-satellite-dish',
  'fas fa-save',
  'far fa-save',
  'fab fa-schlix',
  'fas fa-school',
  'fas fa-screwdriver',
  'fab fa-scribd',
  'fas fa-scroll',
  'fas fa-sd-card',
  'fas fa-search',
  'fas fa-search-dollar',
  'fas fa-search-location',
  'fas fa-search-minus',
  'fas fa-search-plus',
  'fab fa-searchengin',
  'fas fa-seedling',
  'fab fa-sellcast',
  'fab fa-sellsy',
  'fas fa-server',
  'fab fa-servicestack',
  'fas fa-shapes',
  'fas fa-share',
  'fas fa-share-alt',
  'fas fa-share-alt-square',
  'fas fa-share-square',
  'far fa-share-square',
  'fas fa-shekel-sign',
  'fas fa-shield-alt',
  'fas fa-shield-virus',
  'fas fa-ship',
  'fas fa-shipping-fast',
  'fab fa-shirtsinbulk',
  'fas fa-shoe-prints',
  'fab fa-shopify',
  'fas fa-shopping-bag',
  'fas fa-shopping-basket',
  'fas fa-shopping-cart',
  'fab fa-shopware',
  'fas fa-shower',
  'fas fa-shuttle-van',
  'fas fa-sign',
  'fas fa-sign-in-alt',
  'fas fa-sign-language',
  'fas fa-sign-out-alt',
  'fas fa-signal',
  'fas fa-signature',
  'fas fa-sim-card',
  'fab fa-simplybuilt',
  'fas fa-sink',
  'fab fa-sistrix',
  'fas fa-sitemap',
  'fab fa-sith',
  'fas fa-skating',
  'fab fa-sketch',
  'fas fa-skiing',
  'fas fa-skiing-nordic',
  'fas fa-skull',
  'fas fa-skull-crossbones',
  'fab fa-skyatlas',
  'fab fa-skype',
  'fab fa-slack',
  'fab fa-slack-hash',
  'fas fa-slash',
  'fas fa-sleigh',
  'fas fa-sliders-h',
  'fab fa-slideshare',
  'fas fa-smile',
  'far fa-smile',
  'fas fa-smile-beam',
  'far fa-smile-beam',
  'fas fa-smile-wink',
  'far fa-smile-wink',
  'fas fa-smog',
  'fas fa-smoking',
  'fas fa-smoking-ban',
  'fas fa-sms',
  'fab fa-snapchat',
  'fab fa-snapchat-ghost',
  'fab fa-snapchat-square',
  'fas fa-snowboarding',
  'fas fa-snowflake',
  'far fa-snowflake',
  'fas fa-snowman',
  'fas fa-snowplow',
  'fas fa-soap',
  'fas fa-socks',
  'fas fa-solar-panel',
  'fas fa-sort',
  'fas fa-sort-alpha-down',
  'fas fa-sort-alpha-down-alt',
  'fas fa-sort-alpha-up',
  'fas fa-sort-alpha-up-alt',
  'fas fa-sort-amount-down',
  'fas fa-sort-amount-down-alt',
  'fas fa-sort-amount-up',
  'fas fa-sort-amount-up-alt',
  'fas fa-sort-down',
  'fas fa-sort-numeric-down',
  'fas fa-sort-numeric-down-alt',
  'fas fa-sort-numeric-up',
  'fas fa-sort-numeric-up-alt',
  'fas fa-sort-up',
  'fab fa-soundcloud',
  'fab fa-sourcetree',
  'fas fa-spa',
  'fas fa-space-shuttle',
  'fab fa-speakap',
  'fab fa-speaker-deck',
  'fas fa-spell-check',
  'fas fa-spider',
  'fas fa-spinner',
  'fas fa-splotch',
  'fab fa-spotify',
  'fas fa-spray-can',
  'fas fa-square',
  'far fa-square',
  'fas fa-square-full',
  'fas fa-square-root-alt',
  'fab fa-squarespace',
  'fab fa-stack-exchange',
  'fab fa-stack-overflow',
  'fab fa-stackpath',
  'fas fa-stamp',
  'fas fa-star',
  'far fa-star',
  'fas fa-star-and-crescent',
  'fas fa-star-half',
  'far fa-star-half',
  'fas fa-star-half-alt',
  'fas fa-star-of-david',
  'fas fa-star-of-life',
  'fab fa-staylinked',
  'fab fa-steam',
  'fab fa-steam-square',
  'fab fa-steam-symbol',
  'fas fa-step-backward',
  'fas fa-step-forward',
  'fas fa-stethoscope',
  'fab fa-sticker-mule',
  'fas fa-sticky-note',
  'far fa-sticky-note',
  'fas fa-stop',
  'fas fa-stop-circle',
  'far fa-stop-circle',
  'fas fa-stopwatch',
  'fas fa-stopwatch-20',
  'fas fa-store',
  'fas fa-store-alt',
  'fas fa-store-alt-slash',
  'fas fa-store-slash',
  'fab fa-strava',
  'fas fa-stream',
  'fas fa-street-view',
  'fas fa-strikethrough',
  'fab fa-stripe',
  'fab fa-stripe-s',
  'fas fa-stroopwafel',
  'fab fa-studiovinari',
  'fab fa-stumbleupon',
  'fab fa-stumbleupon-circle',
  'fas fa-subscript',
  'fas fa-subway',
  'fas fa-suitcase',
  'fas fa-suitcase-rolling',
  'fas fa-sun',
  'far fa-sun',
  'fab fa-superpowers',
  'fas fa-superscript',
  'fab fa-supple',
  'fas fa-surprise',
  'far fa-surprise',
  'fab fa-suse',
  'fas fa-swatchbook',
  'fab fa-swift',
  'fas fa-swimmer',
  'fas fa-swimming-pool',
  'fab fa-symfony',
  'fas fa-synagogue',
  'fas fa-sync',
  'fas fa-sync-alt',
  'fas fa-syringe',
  'fas fa-table',
  'fas fa-table-tennis',
  'fas fa-tablet',
  'fas fa-tablet-alt',
  'fas fa-tablets',
  'fas fa-tachometer-alt',
  'fas fa-tag',
  'fas fa-tags',
  'fas fa-tape',
  'fas fa-tasks',
  'fas fa-taxi',
  'fab fa-teamspeak',
  'fas fa-teeth',
  'fas fa-teeth-open',
  'fab fa-telegram',
  'fab fa-telegram-plane',
  'fas fa-temperature-high',
  'fas fa-temperature-low',
  'fab fa-tencent-weibo',
  'fas fa-tenge',
  'fas fa-terminal',
  'fas fa-text-height',
  'fas fa-text-width',
  'fas fa-th',
  'fas fa-th-large',
  'fas fa-th-list',
  'fab fa-the-red-yeti',
  'fas fa-theater-masks',
  'fab fa-themeco',
  'fab fa-themeisle',
  'fas fa-thermometer',
  'fas fa-thermometer-empty',
  'fas fa-thermometer-full',
  'fas fa-thermometer-half',
  'fas fa-thermometer-quarter',
  'fas fa-thermometer-three-quarters',
  'fab fa-think-peaks',
  'fas fa-thumbs-down',
  'far fa-thumbs-down',
  'fas fa-thumbs-up',
  'far fa-thumbs-up',
  'fas fa-thumbtack',
  'fas fa-ticket-alt',
  'fab fa-tiktok',
  'fas fa-times',
  'fas fa-times-circle',
  'far fa-times-circle',
  'fas fa-tint',
  'fas fa-tint-slash',
  'fas fa-tired',
  'far fa-tired',
  'fas fa-toggle-off',
  'fas fa-toggle-on',
  'fas fa-toilet',
  'fas fa-toilet-paper',
  'fas fa-toilet-paper-slash',
  'fas fa-toolbox',
  'fas fa-tools',
  'fas fa-tooth',
  'fas fa-torah',
  'fas fa-torii-gate',
  'fas fa-tractor',
  'fab fa-trade-federation',
  'fas fa-trademark',
  'fas fa-traffic-light',
  'fas fa-trailer',
  'fas fa-train',
  'fas fa-tram',
  'fas fa-transgender',
  'fas fa-transgender-alt',
  'fas fa-trash',
  'fas fa-trash-alt',
  'far fa-trash-alt',
  'fas fa-trash-restore',
  'fas fa-trash-restore-alt',
  'fas fa-tree',
  'fab fa-trello',
  'fas fa-trophy',
  'fas fa-truck',
  'fas fa-truck-loading',
  'fas fa-truck-monster',
  'fas fa-truck-moving',
  'fas fa-truck-pickup',
  'fas fa-tshirt',
  'fas fa-tty',
  'fab fa-tumblr',
  'fab fa-tumblr-square',
  'fas fa-tv',
  'fab fa-twitch',
  'fab fa-twitter',
  'fab fa-twitter-square',
  'fab fa-typo3',
  'fab fa-uber',
  'fab fa-ubuntu',
  'fab fa-uikit',
  'fab fa-umbraco',
  'fas fa-umbrella',
  'fas fa-umbrella-beach',
  'fab fa-uncharted',
  'fas fa-underline',
  'fas fa-undo',
  'fas fa-undo-alt',
  'fab fa-uniregistry',
  'fab fa-unity',
  'fas fa-universal-access',
  'fas fa-university',
  'fas fa-unlink',
  'fas fa-unlock',
  'fas fa-unlock-alt',
  'fab fa-unsplash',
  'fab fa-untappd',
  'fas fa-upload',
  'fab fa-ups',
  'fab fa-usb',
  'fas fa-user',
  'far fa-user',
  'fas fa-user-alt',
  'fas fa-user-alt-slash',
  'fas fa-user-astronaut',
  'fas fa-user-check',
  'fas fa-user-circle',
  'far fa-user-circle',
  'fas fa-user-clock',
  'fas fa-user-cog',
  'fas fa-user-edit',
  'fas fa-user-friends',
  'fas fa-user-graduate',
  'fas fa-user-injured',
  'fas fa-user-lock',
  'fas fa-user-md',
  'fas fa-user-minus',
  'fas fa-user-ninja',
  'fas fa-user-nurse',
  'fas fa-user-plus',
  'fas fa-user-secret',
  'fas fa-user-shield',
  'fas fa-user-slash',
  'fas fa-user-tag',
  'fas fa-user-tie',
  'fas fa-user-times',
  'fas fa-users',
  'fas fa-users-cog',
  'fas fa-users-slash',
  'fab fa-usps',
  'fab fa-ussunnah',
  'fas fa-utensil-spoon',
  'fas fa-utensils',
  'fab fa-vaadin',
  'fas fa-vector-square',
  'fas fa-venus',
  'fas fa-venus-double',
  'fas fa-venus-mars',
  'fas fa-vest',
  'fas fa-vest-patches',
  'fab fa-viacoin',
  'fab fa-viadeo',
  'fab fa-viadeo-square',
  'fas fa-vial',
  'fas fa-vials',
  'fab fa-viber',
  'fas fa-video',
  'fas fa-video-slash',
  'fas fa-vihara',
  'fab fa-vimeo',
  'fab fa-vimeo-square',
  'fab fa-vimeo-v',
  'fab fa-vine',
  'fas fa-virus',
  'fas fa-virus-slash',
  'fas fa-viruses',
  'fab fa-vk',
  'fab fa-vnv',
  'fas fa-voicemail',
  'fas fa-volleyball-ball',
  'fas fa-volume-down',
  'fas fa-volume-mute',
  'fas fa-volume-off',
  'fas fa-volume-up',
  'fas fa-vote-yea',
  'fas fa-vr-cardboard',
  'fab fa-vuejs',
  'fas fa-walking',
  'fas fa-wallet',
  'fas fa-warehouse',
  'fab fa-watchman-monitoring',
  'fas fa-water',
  'fas fa-wave-square',
  'fab fa-waze',
  'fab fa-weebly',
  'fab fa-weibo',
  'fas fa-weight',
  'fas fa-weight-hanging',
  'fab fa-weixin',
  'fab fa-whatsapp',
  'fab fa-whatsapp-square',
  'fas fa-wheelchair',
  'fab fa-whmcs',
  'fas fa-wifi',
  'fab fa-wikipedia-w',
  'fas fa-wind',
  'fas fa-window-close',
  'far fa-window-close',
  'fas fa-window-maximize',
  'far fa-window-maximize',
  'fas fa-window-minimize',
  'far fa-window-minimize',
  'fas fa-window-restore',
  'far fa-window-restore',
  'fab fa-windows',
  'fas fa-wine-bottle',
  'fas fa-wine-glass',
  'fas fa-wine-glass-alt',
  'fab fa-wix',
  'fab fa-wizards-of-the-coast',
  'fab fa-wodu',
  'fab fa-wolf-pack-battalion',
  'fas fa-won-sign',
  'fab fa-wordpress',
  'fab fa-wordpress-simple',
  'fab fa-wpbeginner',
  'fab fa-wpexplorer',
  'fab fa-wpforms',
  'fab fa-wpressr',
  'fas fa-wrench',
  'fas fa-x-ray',
  'fab fa-xbox',
  'fab fa-xing',
  'fab fa-xing-square',
  'fab fa-y-combinator',
  'fab fa-yahoo',
  'fab fa-yammer',
  'fab fa-yandex',
  'fab fa-yandex-international',
  'fab fa-yarn',
  'fab fa-yelp',
  'fas fa-yen-sign',
  'fas fa-yin-yang',
  'fab fa-yoast',
  'fab fa-youtube',
  'fab fa-youtube-square',
  'fab fa-zhihu', */
  'bi bi-alarm',
  'bi bi-alarm-fill',
  'bi bi-align-bottom',
  'bi bi-align-center',
  'bi bi-align-end',
  'bi bi-align-middle',
  'bi bi-align-start',
  'bi bi-align-top',
  'bi bi-alt',
  'bi bi-app',
  'bi bi-app-indicator',
  'bi bi-archive',
  'bi bi-archive-fill',
  'bi bi-arrow-90deg-down',
  'bi bi-arrow-90deg-left',
  'bi bi-arrow-90deg-right',
  'bi bi-arrow-90deg-up',
  'bi bi-arrow-bar-down',
  'bi bi-arrow-bar-left',
  'bi bi-arrow-bar-right',
  'bi bi-arrow-bar-up',
  'bi bi-arrow-clockwise',
  'bi bi-arrow-counterclockwise',
  'bi bi-arrow-down',
  'bi bi-arrow-down-circle',
  'bi bi-arrow-down-circle-fill',
  'bi bi-arrow-down-left-circle',
  'bi bi-arrow-down-left-circle-fill',
  'bi bi-arrow-down-left-square',
  'bi bi-arrow-down-left-square-fill',
  'bi bi-arrow-down-right-circle',
  'bi bi-arrow-down-right-circle-fill',
  'bi bi-arrow-down-right-square',
  'bi bi-arrow-down-right-square-fill',
  'bi bi-arrow-down-square',
  'bi bi-arrow-down-square-fill',
  'bi bi-arrow-down-left',
  'bi bi-arrow-down-right',
  'bi bi-arrow-down-short',
  'bi bi-arrow-down-up',
  'bi bi-arrow-left',
  'bi bi-arrow-left-circle',
  'bi bi-arrow-left-circle-fill',
  'bi bi-arrow-left-square',
  'bi bi-arrow-left-square-fill',
  'bi bi-arrow-left-right',
  'bi bi-arrow-left-short',
  'bi bi-arrow-repeat',
  'bi bi-arrow-return-left',
  'bi bi-arrow-return-right',
  'bi bi-arrow-right',
  'bi bi-arrow-right-circle',
  'bi bi-arrow-right-circle-fill',
  'bi bi-arrow-right-square',
  'bi bi-arrow-right-square-fill',
  'bi bi-arrow-right-short',
  'bi bi-arrow-up',
  'bi bi-arrow-up-circle',
  'bi bi-arrow-up-circle-fill',
  'bi bi-arrow-up-left-circle',
  'bi bi-arrow-up-left-circle-fill',
  'bi bi-arrow-up-left-square',
  'bi bi-arrow-up-left-square-fill',
  'bi bi-arrow-up-right-circle',
  'bi bi-arrow-up-right-circle-fill',
  'bi bi-arrow-up-right-square',
  'bi bi-arrow-up-right-square-fill',
  'bi bi-arrow-up-square',
  'bi bi-arrow-up-square-fill',
  'bi bi-arrow-up-left',
  'bi bi-arrow-up-right',
  'bi bi-arrow-up-short',
  'bi bi-arrows-angle-contract',
  'bi bi-arrows-angle-expand',
  'bi bi-arrows-collapse',
  'bi bi-arrows-expand',
  'bi bi-arrows-fullscreen',
  'bi bi-arrows-move',
  'bi bi-aspect-ratio',
  'bi bi-aspect-ratio-fill',
  'bi bi-asterisk',
  'bi bi-at',
  'bi bi-award',
  'bi bi-award-fill',
  'bi bi-back',
  'bi bi-backspace',
  'bi bi-backspace-fill',
  'bi bi-backspace-reverse',
  'bi bi-backspace-reverse-fill',
  'bi bi-badge-3d',
  'bi bi-badge-3d-fill',
  'bi bi-badge-4k',
  'bi bi-badge-4k-fill',
  'bi bi-badge-8k',
  'bi bi-badge-8k-fill',
  'bi bi-badge-ad',
  'bi bi-badge-ad-fill',
  'bi bi-badge-ar',
  'bi bi-badge-ar-fill',
  'bi bi-badge-cc',
  'bi bi-badge-cc-fill',
  'bi bi-badge-hd',
  'bi bi-badge-hd-fill',
  'bi bi-badge-tm',
  'bi bi-badge-tm-fill',
  'bi bi-badge-vo',
  'bi bi-badge-vo-fill',
  'bi bi-badge-vr',
  'bi bi-badge-vr-fill',
  'bi bi-badge-wc',
  'bi bi-badge-wc-fill',
  'bi bi-bag',
  'bi bi-bag-check',
  'bi bi-bag-check-fill',
  'bi bi-bag-dash',
  'bi bi-bag-dash-fill',
  'bi bi-bag-fill',
  'bi bi-bag-plus',
  'bi bi-bag-plus-fill',
  'bi bi-bag-x',
  'bi bi-bag-x-fill',
  'bi bi-bank',
  'bi bi-bank2',
  'bi bi-bar-chart',
  'bi bi-bar-chart-fill',
  'bi bi-bar-chart-line',
  'bi bi-bar-chart-line-fill',
  'bi bi-bar-chart-steps',
  'bi bi-basket',
  'bi bi-basket-fill',
  'bi bi-basket2',
  'bi bi-basket2-fill',
  'bi bi-basket3',
  'bi bi-basket3-fill',
  'bi bi-battery',
  'bi bi-battery-charging',
  'bi bi-battery-full',
  'bi bi-battery-half',
  'bi bi-bell',
  'bi bi-bell-fill',
  'bi bi-bell-slash',
  'bi bi-bell-slash-fill',
  'bi bi-bezier',
  'bi bi-bezier2',
  'bi bi-bicycle',
  'bi bi-binoculars',
  'bi bi-binoculars-fill',
  'bi bi-blockquote-left',
  'bi bi-blockquote-right',
  'bi bi-book',
  'bi bi-book-fill',
  'bi bi-book-half',
  'bi bi-bookmark',
  'bi bi-bookmark-check',
  'bi bi-bookmark-check-fill',
  'bi bi-bookmark-dash',
  'bi bi-bookmark-dash-fill',
  'bi bi-bookmark-fill',
  'bi bi-bookmark-heart',
  'bi bi-bookmark-heart-fill',
  'bi bi-bookmark-plus',
  'bi bi-bookmark-plus-fill',
  'bi bi-bookmark-star',
  'bi bi-bookmark-star-fill',
  'bi bi-bookmark-x',
  'bi bi-bookmark-x-fill',
  'bi bi-bookmarks',
  'bi bi-bookmarks-fill',
  'bi bi-bookshelf',
  'bi bi-bootstrap',
  'bi bi-bootstrap-fill',
  'bi bi-bootstrap-reboot',
  'bi bi-border',
  'bi bi-border-all',
  'bi bi-border-bottom',
  'bi bi-border-center',
  'bi bi-border-inner',
  'bi bi-border-left',
  'bi bi-border-middle',
  'bi bi-border-outer',
  'bi bi-border-right',
  'bi bi-border-style',
  'bi bi-border-top',
  'bi bi-border-width',
  'bi bi-bounding-box',
  'bi bi-bounding-box-circles',
  'bi bi-box',
  'bi bi-box-arrow-down-left',
  'bi bi-box-arrow-down-right',
  'bi bi-box-arrow-down',
  'bi bi-box-arrow-in-down',
  'bi bi-box-arrow-in-down-left',
  'bi bi-box-arrow-in-down-right',
  'bi bi-box-arrow-in-left',
  'bi bi-box-arrow-in-right',
  'bi bi-box-arrow-in-up',
  'bi bi-box-arrow-in-up-left',
  'bi bi-box-arrow-in-up-right',
  'bi bi-box-arrow-left',
  'bi bi-box-arrow-right',
  'bi bi-box-arrow-up',
  'bi bi-box-arrow-up-left',
  'bi bi-box-arrow-up-right',
  'bi bi-box-seam',
  'bi bi-braces',
  'bi bi-bricks',
  'bi bi-briefcase',
  'bi bi-briefcase-fill',
  'bi bi-brightness-alt-high',
  'bi bi-brightness-alt-high-fill',
  'bi bi-brightness-alt-low',
  'bi bi-brightness-alt-low-fill',
  'bi bi-brightness-high',
  'bi bi-brightness-high-fill',
  'bi bi-brightness-low',
  'bi bi-brightness-low-fill',
  'bi bi-broadcast',
  'bi bi-broadcast-pin',
  'bi bi-brush',
  'bi bi-brush-fill',
  'bi bi-bucket',
  'bi bi-bucket-fill',
  'bi bi-bug',
  'bi bi-bug-fill',
  'bi bi-building',
  'bi bi-bullseye',
  'bi bi-calculator',
  'bi bi-calculator-fill',
  'bi bi-calendar',
  'bi bi-calendar-check',
  'bi bi-calendar-check-fill',
  'bi bi-calendar-date',
  'bi bi-calendar-date-fill',
  'bi bi-calendar-day',
  'bi bi-calendar-day-fill',
  'bi bi-calendar-event',
  'bi bi-calendar-event-fill',
  'bi bi-calendar-fill',
  'bi bi-calendar-minus',
  'bi bi-calendar-minus-fill',
  'bi bi-calendar-month',
  'bi bi-calendar-month-fill',
  'bi bi-calendar-plus',
  'bi bi-calendar-plus-fill',
  'bi bi-calendar-range',
  'bi bi-calendar-range-fill',
  'bi bi-calendar-week',
  'bi bi-calendar-week-fill',
  'bi bi-calendar-x',
  'bi bi-calendar-x-fill',
  'bi bi-calendar2',
  'bi bi-calendar2-check',
  'bi bi-calendar2-check-fill',
  'bi bi-calendar2-date',
  'bi bi-calendar2-date-fill',
  'bi bi-calendar2-day',
  'bi bi-calendar2-day-fill',
  'bi bi-calendar2-event',
  'bi bi-calendar2-event-fill',
  'bi bi-calendar2-fill',
  'bi bi-calendar2-minus',
  'bi bi-calendar2-minus-fill',
  'bi bi-calendar2-month',
  'bi bi-calendar2-month-fill',
  'bi bi-calendar2-plus',
  'bi bi-calendar2-plus-fill',
  'bi bi-calendar2-range',
  'bi bi-calendar2-range-fill',
  'bi bi-calendar2-week',
  'bi bi-calendar2-week-fill',
  'bi bi-calendar2-x',
  'bi bi-calendar2-x-fill',
  'bi bi-calendar3',
  'bi bi-calendar3-event',
  'bi bi-calendar3-event-fill',
  'bi bi-calendar3-fill',
  'bi bi-calendar3-range',
  'bi bi-calendar3-range-fill',
  'bi bi-calendar3-week',
  'bi bi-calendar3-week-fill',
  'bi bi-calendar4',
  'bi bi-calendar4-event',
  'bi bi-calendar4-range',
  'bi bi-calendar4-week',
  'bi bi-camera',
  'bi bi-camera2',
  'bi bi-camera-fill',
  'bi bi-camera-reels',
  'bi bi-camera-reels-fill',
  'bi bi-camera-video',
  'bi bi-camera-video-fill',
  'bi bi-camera-video-off',
  'bi bi-camera-video-off-fill',
  'bi bi-capslock',
  'bi bi-capslock-fill',
  'bi bi-card-checklist',
  'bi bi-card-heading',
  'bi bi-card-image',
  'bi bi-card-list',
  'bi bi-card-text',
  'bi bi-caret-down',
  'bi bi-caret-down-fill',
  'bi bi-caret-down-square',
  'bi bi-caret-down-square-fill',
  'bi bi-caret-left',
  'bi bi-caret-left-fill',
  'bi bi-caret-left-square',
  'bi bi-caret-left-square-fill',
  'bi bi-caret-right',
  'bi bi-caret-right-fill',
  'bi bi-caret-right-square',
  'bi bi-caret-right-square-fill',
  'bi bi-caret-up',
  'bi bi-caret-up-fill',
  'bi bi-caret-up-square',
  'bi bi-caret-up-square-fill',
  'bi bi-cart',
  'bi bi-cart-check',
  'bi bi-cart-check-fill',
  'bi bi-cart-dash',
  'bi bi-cart-dash-fill',
  'bi bi-cart-fill',
  'bi bi-cart-plus',
  'bi bi-cart-plus-fill',
  'bi bi-cart-x',
  'bi bi-cart-x-fill',
  'bi bi-cart2',
  'bi bi-cart3',
  'bi bi-cart4',
  'bi bi-cash',
  'bi bi-cash-coin',
  'bi bi-cash-stack',
  'bi bi-cast',
  'bi bi-chat',
  'bi bi-chat-dots',
  'bi bi-chat-dots-fill',
  'bi bi-chat-fill',
  'bi bi-chat-left',
  'bi bi-chat-left-dots',
  'bi bi-chat-left-dots-fill',
  'bi bi-chat-left-fill',
  'bi bi-chat-left-quote',
  'bi bi-chat-left-quote-fill',
  'bi bi-chat-left-text',
  'bi bi-chat-left-text-fill',
  'bi bi-chat-quote',
  'bi bi-chat-quote-fill',
  'bi bi-chat-right',
  'bi bi-chat-right-dots',
  'bi bi-chat-right-dots-fill',
  'bi bi-chat-right-fill',
  'bi bi-chat-right-quote',
  'bi bi-chat-right-quote-fill',
  'bi bi-chat-right-text',
  'bi bi-chat-right-text-fill',
  'bi bi-chat-square',
  'bi bi-chat-square-dots',
  'bi bi-chat-square-dots-fill',
  'bi bi-chat-square-fill',
  'bi bi-chat-square-quote',
  'bi bi-chat-square-quote-fill',
  'bi bi-chat-square-text',
  'bi bi-chat-square-text-fill',
  'bi bi-chat-text',
  'bi bi-chat-text-fill',
  'bi bi-check',
  'bi bi-check-all',
  'bi bi-check-circle',
  'bi bi-check-circle-fill',
  'bi bi-check-lg',
  'bi bi-check-square',
  'bi bi-check-square-fill',
  'bi bi-check2',
  'bi bi-check2-all',
  'bi bi-check2-circle',
  'bi bi-check2-square',
  'bi bi-chevron-bar-contract',
  'bi bi-chevron-bar-down',
  'bi bi-chevron-bar-expand',
  'bi bi-chevron-bar-left',
  'bi bi-chevron-bar-right',
  'bi bi-chevron-bar-up',
  'bi bi-chevron-compact-down',
  'bi bi-chevron-compact-left',
  'bi bi-chevron-compact-right',
  'bi bi-chevron-compact-up',
  'bi bi-chevron-contract',
  'bi bi-chevron-double-down',
  'bi bi-chevron-double-left',
  'bi bi-chevron-double-right',
  'bi bi-chevron-double-up',
  'bi bi-chevron-down',
  'bi bi-chevron-expand',
  'bi bi-chevron-left',
  'bi bi-chevron-right',
  'bi bi-chevron-up',
  'bi bi-circle',
  'bi bi-circle-fill',
  'bi bi-circle-half',
  'bi bi-slash-circle',
  'bi bi-circle-square',
  'bi bi-clipboard',
  'bi bi-clipboard-check',
  'bi bi-clipboard-data',
  'bi bi-clipboard-minus',
  'bi bi-clipboard-plus',
  'bi bi-clipboard-x',
  'bi bi-clock',
  'bi bi-clock-fill',
  'bi bi-clock-history',
  'bi bi-cloud',
  'bi bi-cloud-arrow-down',
  'bi bi-cloud-arrow-down-fill',
  'bi bi-cloud-arrow-up',
  'bi bi-cloud-arrow-up-fill',
  'bi bi-cloud-check',
  'bi bi-cloud-check-fill',
  'bi bi-cloud-download',
  'bi bi-cloud-download-fill',
  'bi bi-cloud-drizzle',
  'bi bi-cloud-drizzle-fill',
  'bi bi-cloud-fill',
  'bi bi-cloud-fog',
  'bi bi-cloud-fog-fill',
  'bi bi-cloud-fog2',
  'bi bi-cloud-fog2-fill',
  'bi bi-cloud-hail',
  'bi bi-cloud-hail-fill',
  'bi bi-cloud-haze',
  'bi bi-cloud-haze-1',
  'bi bi-cloud-haze-fill',
  'bi bi-cloud-haze2-fill',
  'bi bi-cloud-lightning',
  'bi bi-cloud-lightning-fill',
  'bi bi-cloud-lightning-rain',
  'bi bi-cloud-lightning-rain-fill',
  'bi bi-cloud-minus',
  'bi bi-cloud-minus-fill',
  'bi bi-cloud-moon',
  'bi bi-cloud-moon-fill',
  'bi bi-cloud-plus',
  'bi bi-cloud-plus-fill',
  'bi bi-cloud-rain',
  'bi bi-cloud-rain-fill',
  'bi bi-cloud-rain-heavy',
  'bi bi-cloud-rain-heavy-fill',
  'bi bi-cloud-slash',
  'bi bi-cloud-slash-fill',
  'bi bi-cloud-sleet',
  'bi bi-cloud-sleet-fill',
  'bi bi-cloud-snow',
  'bi bi-cloud-snow-fill',
  'bi bi-cloud-sun',
  'bi bi-cloud-sun-fill',
  'bi bi-cloud-upload',
  'bi bi-cloud-upload-fill',
  'bi bi-clouds',
  'bi bi-clouds-fill',
  'bi bi-cloudy',
  'bi bi-cloudy-fill',
  'bi bi-code',
  'bi bi-code-slash',
  'bi bi-code-square',
  'bi bi-coin',
  'bi bi-collection',
  'bi bi-collection-fill',
  'bi bi-collection-play',
  'bi bi-collection-play-fill',
  'bi bi-columns',
  'bi bi-columns-gap',
  'bi bi-command',
  'bi bi-compass',
  'bi bi-compass-fill',
  'bi bi-cone',
  'bi bi-cone-striped',
  'bi bi-controller',
  'bi bi-cpu',
  'bi bi-cpu-fill',
  'bi bi-credit-card',
  'bi bi-credit-card-2-back',
  'bi bi-credit-card-2-back-fill',
  'bi bi-credit-card-2-front',
  'bi bi-credit-card-2-front-fill',
  'bi bi-credit-card-fill',
  'bi bi-crop',
  'bi bi-cup',
  'bi bi-cup-fill',
  'bi bi-cup-straw',
  'bi bi-currency-bitcoin',
  'bi bi-currency-dollar',
  'bi bi-currency-euro',
  'bi bi-currency-exchange',
  'bi bi-currency-pound',
  'bi bi-currency-yen',
  'bi bi-cursor',
  'bi bi-cursor-fill',
  'bi bi-cursor-text',
  'bi bi-dash',
  'bi bi-dash-circle',
  'bi bi-dash-circle-dotted',
  'bi bi-dash-circle-fill',
  'bi bi-dash-lg',
  'bi bi-dash-square',
  'bi bi-dash-square-dotted',
  'bi bi-dash-square-fill',
  'bi bi-diagram-2',
  'bi bi-diagram-2-fill',
  'bi bi-diagram-3',
  'bi bi-diagram-3-fill',
  'bi bi-diamond',
  'bi bi-diamond-fill',
  'bi bi-diamond-half',
  'bi bi-dice-1',
  'bi bi-dice-1-fill',
  'bi bi-dice-2',
  'bi bi-dice-2-fill',
  'bi bi-dice-3',
  'bi bi-dice-3-fill',
  'bi bi-dice-4',
  'bi bi-dice-4-fill',
  'bi bi-dice-5',
  'bi bi-dice-5-fill',
  'bi bi-dice-6',
  'bi bi-dice-6-fill',
  'bi bi-disc',
  'bi bi-disc-fill',
  'bi bi-discord',
  'bi bi-display',
  'bi bi-display-fill',
  'bi bi-distribute-horizontal',
  'bi bi-distribute-vertical',
  'bi bi-door-closed',
  'bi bi-door-closed-fill',
  'bi bi-door-open',
  'bi bi-door-open-fill',
  'bi bi-dot',
  'bi bi-download',
  'bi bi-droplet',
  'bi bi-droplet-fill',
  'bi bi-droplet-half',
  'bi bi-earbuds',
  'bi bi-easel',
  'bi bi-easel-fill',
  'bi bi-egg',
  'bi bi-egg-fill',
  'bi bi-egg-fried',
  'bi bi-eject',
  'bi bi-eject-fill',
  'bi bi-emoji-angry',
  'bi bi-emoji-angry-fill',
  'bi bi-emoji-dizzy',
  'bi bi-emoji-dizzy-fill',
  'bi bi-emoji-expressionless',
  'bi bi-emoji-expressionless-fill',
  'bi bi-emoji-frown',
  'bi bi-emoji-frown-fill',
  'bi bi-emoji-heart-eyes',
  'bi bi-emoji-heart-eyes-fill',
  'bi bi-emoji-laughing',
  'bi bi-emoji-laughing-fill',
  'bi bi-emoji-neutral',
  'bi bi-emoji-neutral-fill',
  'bi bi-emoji-smile',
  'bi bi-emoji-smile-fill',
  'bi bi-emoji-smile-upside-down',
  'bi bi-emoji-smile-upside-down-fill',
  'bi bi-emoji-sunglasses',
  'bi bi-emoji-sunglasses-fill',
  'bi bi-emoji-wink',
  'bi bi-emoji-wink-fill',
  'bi bi-envelope',
  'bi bi-envelope-fill',
  'bi bi-envelope-open',
  'bi bi-envelope-open-fill',
  'bi bi-eraser',
  'bi bi-eraser-fill',
  'bi bi-exclamation',
  'bi bi-exclamation-circle',
  'bi bi-exclamation-circle-fill',
  'bi bi-exclamation-diamond',
  'bi bi-exclamation-diamond-fill',
  'bi bi-exclamation-lg',
  'bi bi-exclamation-octagon',
  'bi bi-exclamation-octagon-fill',
  'bi bi-exclamation-square',
  'bi bi-exclamation-square-fill',
  'bi bi-exclamation-triangle',
  'bi bi-exclamation-triangle-fill',
  'bi bi-exclude',
  'bi bi-eye',
  'bi bi-eye-fill',
  'bi bi-eye-slash',
  'bi bi-eye-slash-fill',
  'bi bi-eyedropper',
  'bi bi-eyeglasses',
  'bi bi-facebook',
  'bi bi-file',
  'bi bi-file-arrow-down',
  'bi bi-file-arrow-down-fill',
  'bi bi-file-arrow-up',
  'bi bi-file-arrow-up-fill',
  'bi bi-file-bar-graph',
  'bi bi-file-bar-graph-fill',
  'bi bi-file-binary',
  'bi bi-file-binary-fill',
  'bi bi-file-break',
  'bi bi-file-break-fill',
  'bi bi-file-check',
  'bi bi-file-check-fill',
  'bi bi-file-code',
  'bi bi-file-code-fill',
  'bi bi-file-diff',
  'bi bi-file-diff-fill',
  'bi bi-file-earmark',
  'bi bi-file-earmark-arrow-down',
  'bi bi-file-earmark-arrow-down-fill',
  'bi bi-file-earmark-arrow-up',
  'bi bi-file-earmark-arrow-up-fill',
  'bi bi-file-earmark-bar-graph',
  'bi bi-file-earmark-bar-graph-fill',
  'bi bi-file-earmark-binary',
  'bi bi-file-earmark-binary-fill',
  'bi bi-file-earmark-break',
  'bi bi-file-earmark-break-fill',
  'bi bi-file-earmark-check',
  'bi bi-file-earmark-check-fill',
  'bi bi-file-earmark-code',
  'bi bi-file-earmark-code-fill',
  'bi bi-file-earmark-diff',
  'bi bi-file-earmark-diff-fill',
  'bi bi-file-earmark-easel',
  'bi bi-file-earmark-easel-fill',
  'bi bi-file-earmark-excel',
  'bi bi-file-earmark-excel-fill',
  'bi bi-file-earmark-fill',
  'bi bi-file-earmark-font',
  'bi bi-file-earmark-font-fill',
  'bi bi-file-earmark-image',
  'bi bi-file-earmark-image-fill',
  'bi bi-file-earmark-lock',
  'bi bi-file-earmark-lock-fill',
  'bi bi-file-earmark-lock2',
  'bi bi-file-earmark-lock2-fill',
  'bi bi-file-earmark-medical',
  'bi bi-file-earmark-medical-fill',
  'bi bi-file-earmark-minus',
  'bi bi-file-earmark-minus-fill',
  'bi bi-file-earmark-music',
  'bi bi-file-earmark-music-fill',
  'bi bi-file-earmark-pdf',
  'bi bi-file-earmark-pdf-fill',
  'bi bi-file-earmark-person',
  'bi bi-file-earmark-person-fill',
  'bi bi-file-earmark-play',
  'bi bi-file-earmark-play-fill',
  'bi bi-file-earmark-plus',
  'bi bi-file-earmark-plus-fill',
  'bi bi-file-earmark-post',
  'bi bi-file-earmark-post-fill',
  'bi bi-file-earmark-ppt',
  'bi bi-file-earmark-ppt-fill',
  'bi bi-file-earmark-richtext',
  'bi bi-file-earmark-richtext-fill',
  'bi bi-file-earmark-ruled',
  'bi bi-file-earmark-ruled-fill',
  'bi bi-file-earmark-slides',
  'bi bi-file-earmark-slides-fill',
  'bi bi-file-earmark-spreadsheet',
  'bi bi-file-earmark-spreadsheet-fill',
  'bi bi-file-earmark-text',
  'bi bi-file-earmark-text-fill',
  'bi bi-file-earmark-word',
  'bi bi-file-earmark-word-fill',
  'bi bi-file-earmark-x',
  'bi bi-file-earmark-x-fill',
  'bi bi-file-earmark-zip',
  'bi bi-file-earmark-zip-fill',
  'bi bi-file-easel',
  'bi bi-file-easel-fill',
  'bi bi-file-excel',
  'bi bi-file-excel-fill',
  'bi bi-file-fill',
  'bi bi-file-font',
  'bi bi-file-font-fill',
  'bi bi-file-image',
  'bi bi-file-image-fill',
  'bi bi-file-lock',
  'bi bi-file-lock-fill',
  'bi bi-file-lock2',
  'bi bi-file-lock2-fill',
  'bi bi-file-medical',
  'bi bi-file-medical-fill',
  'bi bi-file-minus',
  'bi bi-file-minus-fill',
  'bi bi-file-music',
  'bi bi-file-music-fill',
  'bi bi-file-pdf',
  'bi bi-file-pdf-fill',
  'bi bi-file-person',
  'bi bi-file-person-fill',
  'bi bi-file-play',
  'bi bi-file-play-fill',
  'bi bi-file-plus',
  'bi bi-file-plus-fill',
  'bi bi-file-post',
  'bi bi-file-post-fill',
  'bi bi-file-ppt',
  'bi bi-file-ppt-fill',
  'bi bi-file-richtext',
  'bi bi-file-richtext-fill',
  'bi bi-file-ruled',
  'bi bi-file-ruled-fill',
  'bi bi-file-slides',
  'bi bi-file-slides-fill',
  'bi bi-file-spreadsheet',
  'bi bi-file-spreadsheet-fill',
  'bi bi-file-text',
  'bi bi-file-text-fill',
  'bi bi-file-word',
  'bi bi-file-word-fill',
  'bi bi-file-x',
  'bi bi-file-x-fill',
  'bi bi-file-zip',
  'bi bi-file-zip-fill',
  'bi bi-files',
  'bi bi-files-alt',
  'bi bi-film',
  'bi bi-filter',
  'bi bi-filter-circle',
  'bi bi-filter-circle-fill',
  'bi bi-filter-left',
  'bi bi-filter-right',
  'bi bi-filter-square',
  'bi bi-filter-square-fill',
  'bi bi-flag',
  'bi bi-flag-fill',
  'bi bi-flower1',
  'bi bi-flower2',
  'bi bi-flower3',
  'bi bi-folder',
  'bi bi-folder-check',
  'bi bi-folder-fill',
  'bi bi-folder-minus',
  'bi bi-folder-plus',
  'bi bi-folder-symlink',
  'bi bi-folder-symlink-fill',
  'bi bi-folder-x',
  'bi bi-folder2',
  'bi bi-folder2-open',
  'bi bi-fonts',
  'bi bi-forward',
  'bi bi-forward-fill',
  'bi bi-front',
  'bi bi-fullscreen',
  'bi bi-fullscreen-exit',
  'bi bi-funnel',
  'bi bi-funnel-fill',
  'bi bi-gear',
  'bi bi-gear-fill',
  'bi bi-gear-wide',
  'bi bi-gear-wide-connected',
  'bi bi-gem',
  'bi bi-gender-ambiguous',
  'bi bi-gender-female',
  'bi bi-gender-male',
  'bi bi-gender-trans',
  'bi bi-geo',
  'bi bi-geo-alt',
  'bi bi-geo-alt-fill',
  'bi bi-geo-fill',
  'bi bi-gift',
  'bi bi-gift-fill',
  'bi bi-github',
  'bi bi-globe',
  'bi bi-globe2',
  'bi bi-google',
  'bi bi-graph-down',
  'bi bi-graph-up',
  'bi bi-grid',
  'bi bi-grid-1x2',
  'bi bi-grid-1x2-fill',
  'bi bi-grid-3x2',
  'bi bi-grid-3x2-gap',
  'bi bi-grid-3x2-gap-fill',
  'bi bi-grid-3x3',
  'bi bi-grid-3x3-gap',
  'bi bi-grid-3x3-gap-fill',
  'bi bi-grid-fill',
  'bi bi-grip-horizontal',
  'bi bi-grip-vertical',
  'bi bi-hammer',
  'bi bi-hand-index',
  'bi bi-hand-index-fill',
  'bi bi-hand-index-thumb',
  'bi bi-hand-index-thumb-fill',
  'bi bi-hand-thumbs-down',
  'bi bi-hand-thumbs-down-fill',
  'bi bi-hand-thumbs-up',
  'bi bi-hand-thumbs-up-fill',
  'bi bi-handbag',
  'bi bi-handbag-fill',
  'bi bi-hash',
  'bi bi-hdd',
  'bi bi-hdd-fill',
  'bi bi-hdd-network',
  'bi bi-hdd-network-fill',
  'bi bi-hdd-rack',
  'bi bi-hdd-rack-fill',
  'bi bi-hdd-stack',
  'bi bi-hdd-stack-fill',
  'bi bi-headphones',
  'bi bi-headset',
  'bi bi-headset-vr',
  'bi bi-heart',
  'bi bi-heart-fill',
  'bi bi-heart-half',
  'bi bi-heptagon',
  'bi bi-heptagon-fill',
  'bi bi-heptagon-half',
  'bi bi-hexagon',
  'bi bi-hexagon-fill',
  'bi bi-hexagon-half',
  'bi bi-hourglass',
  'bi bi-hourglass-bottom',
  'bi bi-hourglass-split',
  'bi bi-hourglass-top',
  'bi bi-house',
  'bi bi-house-door',
  'bi bi-house-door-fill',
  'bi bi-house-fill',
  'bi bi-hr',
  'bi bi-hurricane',
  'bi bi-image',
  'bi bi-image-alt',
  'bi bi-image-fill',
  'bi bi-images',
  'bi bi-inbox',
  'bi bi-inbox-fill',
  'bi bi-inboxes-fill',
  'bi bi-inboxes',
  'bi bi-info',
  'bi bi-info-circle',
  'bi bi-info-circle-fill',
  'bi bi-info-lg',
  'bi bi-info-square',
  'bi bi-info-square-fill',
  'bi bi-input-cursor',
  'bi bi-input-cursor-text',
  'bi bi-instagram',
  'bi bi-intersect',
  'bi bi-journal',
  'bi bi-journal-album',
  'bi bi-journal-arrow-down',
  'bi bi-journal-arrow-up',
  'bi bi-journal-bookmark',
  'bi bi-journal-bookmark-fill',
  'bi bi-journal-check',
  'bi bi-journal-code',
  'bi bi-journal-medical',
  'bi bi-journal-minus',
  'bi bi-journal-plus',
  'bi bi-journal-richtext',
  'bi bi-journal-text',
  'bi bi-journal-x',
  'bi bi-journals',
  'bi bi-joystick',
  'bi bi-justify',
  'bi bi-justify-left',
  'bi bi-justify-right',
  'bi bi-kanban',
  'bi bi-kanban-fill',
  'bi bi-key',
  'bi bi-key-fill',
  'bi bi-keyboard',
  'bi bi-keyboard-fill',
  'bi bi-ladder',
  'bi bi-lamp',
  'bi bi-lamp-fill',
  'bi bi-laptop',
  'bi bi-laptop-fill',
  'bi bi-layer-backward',
  'bi bi-layer-forward',
  'bi bi-layers',
  'bi bi-layers-fill',
  'bi bi-layers-half',
  'bi bi-layout-sidebar',
  'bi bi-layout-sidebar-inset-reverse',
  'bi bi-layout-sidebar-inset',
  'bi bi-layout-sidebar-reverse',
  'bi bi-layout-split',
  'bi bi-layout-text-sidebar',
  'bi bi-layout-text-sidebar-reverse',
  'bi bi-layout-text-window',
  'bi bi-layout-text-window-reverse',
  'bi bi-layout-three-columns',
  'bi bi-layout-wtf',
  'bi bi-life-preserver',
  'bi bi-lightbulb',
  'bi bi-lightbulb-fill',
  'bi bi-lightbulb-off',
  'bi bi-lightbulb-off-fill',
  'bi bi-lightning',
  'bi bi-lightning-charge',
  'bi bi-lightning-charge-fill',
  'bi bi-lightning-fill',
  'bi bi-link',
  'bi bi-link-45deg',
  'bi bi-linkedin',
  'bi bi-list',
  'bi bi-list-check',
  'bi bi-list-nested',
  'bi bi-list-ol',
  'bi bi-list-stars',
  'bi bi-list-task',
  'bi bi-list-ul',
  'bi bi-lock',
  'bi bi-lock-fill',
  'bi bi-mailbox',
  'bi bi-mailbox2',
  'bi bi-map',
  'bi bi-map-fill',
  'bi bi-markdown',
  'bi bi-markdown-fill',
  'bi bi-mask',
  'bi bi-mastodon',
  'bi bi-megaphone',
  'bi bi-megaphone-fill',
  'bi bi-menu-app',
  'bi bi-menu-app-fill',
  'bi bi-menu-button',
  'bi bi-menu-button-fill',
  'bi bi-menu-button-wide',
  'bi bi-menu-button-wide-fill',
  'bi bi-menu-down',
  'bi bi-menu-up',
  'bi bi-messenger',
  'bi bi-mic',
  'bi bi-mic-fill',
  'bi bi-mic-mute',
  'bi bi-mic-mute-fill',
  'bi bi-minecart',
  'bi bi-minecart-loaded',
  'bi bi-moisture',
  'bi bi-moon',
  'bi bi-moon-fill',
  'bi bi-moon-stars',
  'bi bi-moon-stars-fill',
  'bi bi-mouse',
  'bi bi-mouse-fill',
  'bi bi-mouse2',
  'bi bi-mouse2-fill',
  'bi bi-mouse3',
  'bi bi-mouse3-fill',
  'bi bi-music-note',
  'bi bi-music-note-beamed',
  'bi bi-music-note-list',
  'bi bi-music-player',
  'bi bi-music-player-fill',
  'bi bi-newspaper',
  'bi bi-node-minus',
  'bi bi-node-minus-fill',
  'bi bi-node-plus',
  'bi bi-node-plus-fill',
  'bi bi-nut',
  'bi bi-nut-fill',
  'bi bi-octagon',
  'bi bi-octagon-fill',
  'bi bi-octagon-half',
  'bi bi-option',
  'bi bi-outlet',
  'bi bi-paint-bucket',
  'bi bi-palette',
  'bi bi-palette-fill',
  'bi bi-palette2',
  'bi bi-paperclip',
  'bi bi-paragraph',
  'bi bi-patch-check',
  'bi bi-patch-check-fill',
  'bi bi-patch-exclamation',
  'bi bi-patch-exclamation-fill',
  'bi bi-patch-minus',
  'bi bi-patch-minus-fill',
  'bi bi-patch-plus',
  'bi bi-patch-plus-fill',
  'bi bi-patch-question',
  'bi bi-patch-question-fill',
  'bi bi-pause',
  'bi bi-pause-btn',
  'bi bi-pause-btn-fill',
  'bi bi-pause-circle',
  'bi bi-pause-circle-fill',
  'bi bi-pause-fill',
  'bi bi-peace',
  'bi bi-peace-fill',
  'bi bi-pen',
  'bi bi-pen-fill',
  'bi bi-pencil',
  'bi bi-pencil-fill',
  'bi bi-pencil-square',
  'bi bi-pentagon',
  'bi bi-pentagon-fill',
  'bi bi-pentagon-half',
  'bi bi-people',
  'bi bi-person-circle',
  'bi bi-people-fill',
  'bi bi-percent',
  'bi bi-person',
  'bi bi-person-badge',
  'bi bi-person-badge-fill',
  'bi bi-person-bounding-box',
  'bi bi-person-check',
  'bi bi-person-check-fill',
  'bi bi-person-dash',
  'bi bi-person-dash-fill',
  'bi bi-person-fill',
  'bi bi-person-lines-fill',
  'bi bi-person-plus',
  'bi bi-person-plus-fill',
  'bi bi-person-square',
  'bi bi-person-x',
  'bi bi-person-x-fill',
  'bi bi-phone',
  'bi bi-phone-fill',
  'bi bi-phone-landscape',
  'bi bi-phone-landscape-fill',
  'bi bi-phone-vibrate',
  'bi bi-phone-vibrate-fill',
  'bi bi-pie-chart',
  'bi bi-pie-chart-fill',
  'bi bi-piggy-bank',
  'bi bi-piggy-bank-fill',
  'bi bi-pin',
  'bi bi-pin-angle',
  'bi bi-pin-angle-fill',
  'bi bi-pin-fill',
  'bi bi-pin-map',
  'bi bi-pin-map-fill',
  'bi bi-pip',
  'bi bi-pip-fill',
  'bi bi-play',
  'bi bi-play-btn',
  'bi bi-play-btn-fill',
  'bi bi-play-circle',
  'bi bi-play-circle-fill',
  'bi bi-play-fill',
  'bi bi-plug',
  'bi bi-plug-fill',
  'bi bi-plus',
  'bi bi-plus-circle',
  'bi bi-plus-circle-dotted',
  'bi bi-plus-circle-fill',
  'bi bi-plus-lg',
  'bi bi-plus-square',
  'bi bi-plus-square-dotted',
  'bi bi-plus-square-fill',
  'bi bi-power',
  'bi bi-printer',
  'bi bi-printer-fill',
  'bi bi-puzzle',
  'bi bi-puzzle-fill',
  'bi bi-question',
  'bi bi-question-circle',
  'bi bi-question-diamond',
  'bi bi-question-diamond-fill',
  'bi bi-question-circle-fill',
  'bi bi-question-lg',
  'bi bi-question-octagon',
  'bi bi-question-octagon-fill',
  'bi bi-question-square',
  'bi bi-question-square-fill',
  'bi bi-rainbow',
  'bi bi-receipt',
  'bi bi-receipt-cutoff',
  'bi bi-reception-0',
  'bi bi-reception-1',
  'bi bi-reception-2',
  'bi bi-reception-3',
  'bi bi-reception-4',
  'bi bi-record',
  'bi bi-record-btn',
  'bi bi-record-btn-fill',
  'bi bi-record-circle',
  'bi bi-record-circle-fill',
  'bi bi-record-fill',
  'bi bi-record2',
  'bi bi-record2-fill',
  'bi bi-recycle',
  'bi bi-reddit',
  'bi bi-reply',
  'bi bi-reply-all',
  'bi bi-reply-all-fill',
  'bi bi-reply-fill',
  'bi bi-rss',
  'bi bi-rss-fill',
  'bi bi-rulers',
  'bi bi-safe',
  'bi bi-safe-fill',
  'bi bi-safe2',
  'bi bi-safe2-fill',
  'bi bi-save',
  'bi bi-save-fill',
  'bi bi-save2',
  'bi bi-save2-fill',
  'bi bi-scissors',
  'bi bi-screwdriver',
  'bi bi-sd-card',
  'bi bi-sd-card-fill',
  'bi bi-search',
  'bi bi-segmented-nav',
  'bi bi-server',
  'bi bi-share',
  'bi bi-share-fill',
  'bi bi-shield',
  'bi bi-shield-check',
  'bi bi-shield-exclamation',
  'bi bi-shield-fill',
  'bi bi-shield-fill-check',
  'bi bi-shield-fill-exclamation',
  'bi bi-shield-fill-minus',
  'bi bi-shield-fill-plus',
  'bi bi-shield-fill-x',
  'bi bi-shield-lock',
  'bi bi-shield-lock-fill',
  'bi bi-shield-minus',
  'bi bi-shield-plus',
  'bi bi-shield-shaded',
  'bi bi-shield-slash',
  'bi bi-shield-slash-fill',
  'bi bi-shield-x',
  'bi bi-shift',
  'bi bi-shift-fill',
  'bi bi-shop',
  'bi bi-shop-window',
  'bi bi-shuffle',
  'bi bi-signpost',
  'bi bi-signpost-2',
  'bi bi-signpost-2-fill',
  'bi bi-signpost-fill',
  'bi bi-signpost-split',
  'bi bi-signpost-split-fill',
  'bi bi-sim',
  'bi bi-sim-fill',
  'bi bi-skip-backward',
  'bi bi-skip-backward-btn',
  'bi bi-skip-backward-btn-fill',
  'bi bi-skip-backward-circle',
  'bi bi-skip-backward-circle-fill',
  'bi bi-skip-backward-fill',
  'bi bi-skip-end',
  'bi bi-skip-end-btn',
  'bi bi-skip-end-btn-fill',
  'bi bi-skip-end-circle',
  'bi bi-skip-end-circle-fill',
  'bi bi-skip-end-fill',
  'bi bi-skip-forward',
  'bi bi-skip-forward-btn',
  'bi bi-skip-forward-btn-fill',
  'bi bi-skip-forward-circle',
  'bi bi-skip-forward-circle-fill',
  'bi bi-skip-forward-fill',
  'bi bi-skip-start',
  'bi bi-skip-start-btn',
  'bi bi-skip-start-btn-fill',
  'bi bi-skip-start-circle',
  'bi bi-skip-start-circle-fill',
  'bi bi-skip-start-fill',
  'bi bi-skype',
  'bi bi-slack',
  'bi bi-slash',
  'bi bi-slash-circle-fill',
  'bi bi-slash-lg',
  'bi bi-slash-square',
  'bi bi-slash-square-fill',
  'bi bi-sliders',
  'bi bi-smartwatch',
  'bi bi-snow',
  'bi bi-snow2',
  'bi bi-snow3',
  'bi bi-sort-alpha-down',
  'bi bi-sort-alpha-down-alt',
  'bi bi-sort-alpha-up',
  'bi bi-sort-alpha-up-alt',
  'bi bi-sort-down',
  'bi bi-sort-down-alt',
  'bi bi-sort-numeric-down',
  'bi bi-sort-numeric-down-alt',
  'bi bi-sort-numeric-up',
  'bi bi-sort-numeric-up-alt',
  'bi bi-sort-up',
  'bi bi-sort-up-alt',
  'bi bi-soundwave',
  'bi bi-speaker',
  'bi bi-speaker-fill',
  'bi bi-speedometer',
  'bi bi-speedometer2',
  'bi bi-spellcheck',
  'bi bi-square',
  'bi bi-square-fill',
  'bi bi-square-half',
  'bi bi-stack',
  'bi bi-star',
  'bi bi-star-fill',
  'bi bi-star-half',
  'bi bi-stars',
  'bi bi-stickies',
  'bi bi-stickies-fill',
  'bi bi-sticky',
  'bi bi-sticky-fill',
  'bi bi-stop',
  'bi bi-stop-btn',
  'bi bi-stop-btn-fill',
  'bi bi-stop-circle',
  'bi bi-stop-circle-fill',
  'bi bi-stop-fill',
  'bi bi-stoplights',
  'bi bi-stoplights-fill',
  'bi bi-stopwatch',
  'bi bi-stopwatch-fill',
  'bi bi-subtract',
  'bi bi-suit-club',
  'bi bi-suit-club-fill',
  'bi bi-suit-diamond',
  'bi bi-suit-diamond-fill',
  'bi bi-suit-heart',
  'bi bi-suit-heart-fill',
  'bi bi-suit-spade',
  'bi bi-suit-spade-fill',
  'bi bi-sun',
  'bi bi-sun-fill',
  'bi bi-sunglasses',
  'bi bi-sunrise',
  'bi bi-sunrise-fill',
  'bi bi-sunset',
  'bi bi-sunset-fill',
  'bi bi-symmetry-horizontal',
  'bi bi-symmetry-vertical',
  'bi bi-table',
  'bi bi-tablet',
  'bi bi-tablet-fill',
  'bi bi-tablet-landscape',
  'bi bi-tablet-landscape-fill',
  'bi bi-tag',
  'bi bi-tag-fill',
  'bi bi-tags',
  'bi bi-tags-fill',
  'bi bi-telegram',
  'bi bi-telephone',
  'bi bi-telephone-fill',
  'bi bi-telephone-forward',
  'bi bi-telephone-forward-fill',
  'bi bi-telephone-inbound',
  'bi bi-telephone-inbound-fill',
  'bi bi-telephone-minus',
  'bi bi-telephone-minus-fill',
  'bi bi-telephone-outbound',
  'bi bi-telephone-outbound-fill',
  'bi bi-telephone-plus',
  'bi bi-telephone-plus-fill',
  'bi bi-telephone-x',
  'bi bi-telephone-x-fill',
  'bi bi-terminal',
  'bi bi-terminal-fill',
  'bi bi-text-center',
  'bi bi-text-indent-left',
  'bi bi-text-indent-right',
  'bi bi-text-left',
  'bi bi-text-paragraph',
  'bi bi-text-right',
  'bi bi-textarea',
  'bi bi-textarea-resize',
  'bi bi-textarea-t',
  'bi bi-thermometer',
  'bi bi-thermometer-half',
  'bi bi-thermometer-high',
  'bi bi-thermometer-low',
  'bi bi-thermometer-snow',
  'bi bi-thermometer-sun',
  'bi bi-three-dots',
  'bi bi-three-dots-vertical',
  'bi bi-toggle-off',
  'bi bi-toggle-on',
  'bi bi-toggle2-off',
  'bi bi-toggle2-on',
  'bi bi-toggles',
  'bi bi-toggles2',
  'bi bi-tools',
  'bi bi-tornado',
  'bi bi-translate',
  'bi bi-trash',
  'bi bi-trash-fill',
  'bi bi-trash2',
  'bi bi-trash2-fill',
  'bi bi-tree',
  'bi bi-tree-fill',
  'bi bi-triangle',
  'bi bi-triangle-fill',
  'bi bi-triangle-half',
  'bi bi-trophy',
  'bi bi-trophy-fill',
  'bi bi-tropical-storm',
  'bi bi-truck',
  'bi bi-truck-flatbed',
  'bi bi-tsunami',
  'bi bi-tv',
  'bi bi-tv-fill',
  'bi bi-twitch',
  'bi bi-twitter',
  'bi bi-type',
  'bi bi-type-bold',
  'bi bi-type-h1',
  'bi bi-type-h2',
  'bi bi-type-h3',
  'bi bi-type-italic',
  'bi bi-type-strikethrough',
  'bi bi-type-underline',
  'bi bi-ui-checks',
  'bi bi-ui-checks-grid',
  'bi bi-ui-radios',
  'bi bi-ui-radios-grid',
  'bi bi-umbrella',
  'bi bi-umbrella-fill',
  'bi bi-union',
  'bi bi-unlock',
  'bi bi-unlock-fill',
  'bi bi-upc',
  'bi bi-upc-scan',
  'bi bi-upload',
  'bi bi-vector-pen',
  'bi bi-view-list',
  'bi bi-view-stacked',
  'bi bi-vinyl',
  'bi bi-vinyl-fill',
  'bi bi-voicemail',
  'bi bi-volume-down',
  'bi bi-volume-down-fill',
  'bi bi-volume-mute',
  'bi bi-volume-mute-fill',
  'bi bi-volume-off',
  'bi bi-volume-off-fill',
  'bi bi-volume-up',
  'bi bi-volume-up-fill',
  'bi bi-vr',
  'bi bi-wallet',
  'bi bi-wallet-fill',
  'bi bi-wallet2',
  'bi bi-watch',
  'bi bi-water',
  'bi bi-whatsapp',
  'bi bi-wifi',
  'bi bi-wifi-1',
  'bi bi-wifi-2',
  'bi bi-wifi-off',
  'bi bi-wind',
  'bi bi-window',
  'bi bi-window-dock',
  'bi bi-window-sidebar',
  'bi bi-wrench',
  'bi bi-x',
  'bi bi-x-circle',
  'bi bi-x-circle-fill',
  'bi bi-x-diamond',
  'bi bi-x-diamond-fill',
  'bi bi-x-lg',
  'bi bi-x-octagon',
  'bi bi-x-octagon-fill',
  'bi bi-x-square',
  'bi bi-x-square-fill',
  'bi bi-youtube',
  'bi bi-zoom-in',
  'bi bi-zoom-out',
];
