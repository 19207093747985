<div
  cdkDropListGroup
  *ngIf="currentProject"
  class="maincontainer2 flex justify-center items-center w-full h-full"
>
  <div id="container"></div>
  <div
    class="maincontainer2 w-full py-5 bg-gray-100 flex items-center overflow-auto"
    style="height: calc(100%)"
    cdkDropList
    id="htmlList"
    [cdkDropListData]="html"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="flex justify-center self-start ml-2 -mt-2 -mb-2">
      <button
        [disabled]="!showUndo"
        (click)="undo()"
        type="button"
        class="rounded-full p-1 text-center inline-flex items-center mr-2 w-7 h-7 font-bold text-lg justify-center"
        [ngClass]="{
          'opacity-0': !showUndo,
          ' text-blue-700 hover:bg-blue-100 opacity-100': showUndo
        }"
      >
        <i class="bi bi-arrow-counterclockwise"></i>
      </button>
    </div>
    <div class="flex justify-center self-end mr-2">
      <p *ngIf="isSaved" class="text-xs text-gray-300">gespeichert</p>
      <p *ngIf="!isSaved" class="text-xs text-gray-300 italic">
        ...wird gespeichert
      </p>
    </div>
    <div class="w-3/5 flex mb-4 -mt-6 justify-start ml-4">
      <div class="flex justify-center w-96">
        <mat-button-toggle-group [(ngModel)]="mobileView">
          <mat-button-toggle [value]="true">
            <i class="bi bi-phone text-lg"></i>
          </mat-button-toggle>
          <mat-button-toggle [value]="false"
            ><i class="bi bi-window text-lg"></i
          ></mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <div
      class="flex px-4 group hover:border-blue-400"
      *ngFor="
        let items of html;
        let i = index;
        first as isFirst;
        last as isLast;
        trackBy: trackByFn
      "
      [ngClass]="{ 'w-full': !mobileView, 'w-3/5': mobileView }"
      cdkDrag
      cdkDragBoundary=".maincontainer2"
    >
      <div
        class="transform flex flex-row border-l border-r border-gray-400 group-hover:border-blue-400"
        [ngClass]="{
          'border ': isHoverIndex === i || currentElementIndex === i,
          'border-t   ': currentElementIndex === i,
          'border-blue-400': currentElementIndex === i,
          'border-t rounded-t-lg ': isFirst,
          'justify-start items-start text-left': getAlignment(i) === 'start',
          'justify-center items-center text-center':
            getAlignment(i) === 'center',
          'justify-end items-end text-right': getAlignment(i) === 'end',
          'w-full ': !mobileView,
          'w-96': mobileView
        }"
        (click)="selectElement(items, i)"
        (mouseenter)="hoverListItem(i)"
        (mouseleave)="hoverListItem(null)"
        [ngStyle]="getBackgroundColor(items)"
      >
        <div
          [ngClass]="{
            'w-full': !mobileView,
            'w-3/5': mobileView
          }"
          class="flex px-4"
          *cdkDragPlaceholder
        >
          <div
            class="example-custom-placeholder border border-1 border-dotted h-20 bg-gray-300"
            [ngClass]="{
              'w-full': !mobileView,
              'w-96': mobileView
            }"
          ></div>
          <div class="w-1/6"></div>
        </div>
        <link [href]="getFontCSS(i)" rel="stylesheet" />
        <div
          class="flex justify-center"
          *ngIf="items.type === 'button'"
          [ngClass]="{ 'w-full': mobileView, 'w-1/3': !mobileView }"
        >
          <button
            class="mx-2 my-2 bg-white transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-6 py-2 text-sm"
            [ngStyle]="getStyle(i)"
          >
            <input
              type="text"
              [(ngModel)]="items.content"
              class="w-full text-center bg-transparent px-2 border-transparent focus:border-transparent focus:ring-0"
              (ngModelChange)="changed($event)"
            />
            <!--  {{ items.content }} -->
          </button>
        </div>

        <div
          class="flex justify-center"
          *ngIf="items.type === 'header'"
          [ngClass]="{ 'w-full ': mobileView, 'w-1/3': !mobileView }"
        >
          <textarea
            [id]="items.id"
            type="text"
            [(ngModel)]="items.content"
            class="w-full px-2 border-transparent bg-transparent focus:border-transparent focus:ring-0"
            (ngModelChange)="changed($event)"
            [ngStyle]="getStyle(i, items.id)"
            [ngClass]="{
              'text-left': getAlignment(i) === 'start',
              'text-center': getAlignment(i) === 'center',
              'text-right': getAlignment(i) === 'end'
            }"
          ></textarea>
        </div>

        <div
          class="flex justify-center"
          *ngIf="items.type === 'socialmedia'"
          [ngClass]="{ 'w-full ': mobileView, 'w-1/3': !mobileView }"
          [ngClass]="{
            'text-left': getAlignment(i) === 'start',
            'text-center': getAlignment(i) === 'center',
            'text-right': getAlignment(i) === 'end'
          }"
        >
          <i
            class="cursor-pointer"
            [ngClass]="getSocialMediaIcon(items.content.type)"
            [ngStyle]="getSocialMediaStyle(i, items.content.type)"
          ></i>
        </div>

        <div
          class="flex justify-center"
          *ngIf="items.type === 'upload'"
          [ngClass]="{ 'w-full ': mobileView, 'w-1/3': !mobileView }"
          [ngStyle]="getStyle(i)"
        >
          <label
            class="flex flex-col rounded-lg border-4 border-dashed w-full h-60 mx-2 group text-center"
          >
            <div class="flex flex-auto max-h-48 mx-auto">
              <img
                class="has-mask h-36 mt-4 object-center"
                [src]="items.content.picture"
              />
            </div>
            <p class="pointer-none text-gray-500">
              <span class="text-sm">{{ items.content.text }}</span>
            </p>

            <!--  <input type="file" class="hidden" /> -->
          </label>
        </div>

        <div
          class="flex justify-center mx-4"
          *ngIf="items.type === 'slider'"
          [ngClass]="{ 'w-full ': mobileView, 'w-1/3': !mobileView }"
        >
          <style>
            @media screen and (-webkit-min-device-pixel-ratio: 0) {
              input[type="range"]::-webkit-slider-thumb {
                width: 15px;
                -webkit-appearance: none;
                appearance: none;
                height: 15px;
                background: #fff;
                border-radius: 50%;
                box-shadow: -405px 0 0 400px #d3d3d3;
              }
            }
          </style>
          <div class="flex flex-col w-full justify-center items-center">
            <input
              class="rounded-lg overflow-hidden appearance-none h-4"
              type="range"
              [min]="items.content.min"
              [max]="items.content.max"
              [value]="items.content.value"
              [(ngModel)]="items.content.value"
              [step]="items.content.steps"
              [ngStyle]="getStyle(i)"
            />
            <input
              type="text"
              [(ngModel)]="items.content.value"
              class="mt-1 block p-1 w-24 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md text-center"
            />
          </div>
        </div>

        <div
          class="flex justify-center"
          *ngIf="items.type === 'video'"
          [ngClass]="{ 'w-full ': mobileView, 'w-1/3': !mobileView }"
        >
          <iframe
            style="pointer-events: none"
            [src]="items.content.url | video"
            height="315"
            frameborder="0"
            allowfullscreen
            class="w-full"
            [ngStyle]="getStyle(i)"
          ></iframe>
        </div>

        <div
          class="flex justify-center"
          *ngIf="items.type === 'textfield'"
          [ngClass]="{
            'justify-start items-start text-left': getAlignment(i) === 'start',
            'justify-center items-center text-center':
              getAlignment(i) === 'center',
            'justify-end items-end text-right': getAlignment(i) === 'end'
          }"
          [ngClass]="{ 'w-full': mobileView, 'w-1/3': !mobileView }"
        >
          <input
            [type]="items.content.type"
            [placeholder]="
              items.content.required
                ? items.content.text
                : items.content.text + ' (optional)'
            "
            class="px-4 py-2 rounded-lg border focus:border-gray-500 focus:ring-0"
            [ngStyle]="getStyle(i)"
            (focus)="focusIn(i)"
            (focusout)="focusOut(i)"
          />
        </div>

        <div
          class="flex flex-col items-center justify-center w-full mx-2"
          *ngIf="items.type === 'bulletpoints'"
          [ngStyle]="getStyle(i)"
        >
          <div
            class="flex items-start justify-between mb-2 rounded-lg p-3 mx-2"
            [ngClass]="{
              'w-full': mobileView,
              'w-1/3': !mobileView
            }"
          >
            <ul role="list" class="w-full">
              <li
                class="py-4 flex w-full items-center"
                *ngFor="
                  let bp of items.content.bulletpoints;
                  let answerIndex = index
                "
              >
                <i [ngStyle]="getBulletpointIconStyle(i)" [ngClass]="bp.icon">
                </i>
                <div class="ml-3">
                  <p
                    class="text-sm text-left"
                    [ngStyle]="getBulletpointStyle(i)"
                  >
                    {{ bp.text }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="flex flex-col items-center justify-center w-full mx-2"
          *ngIf="items.type === 'selection'"
          [ngStyle]="getStyle(i)"
        >
          <div
            class="flex items-center justify-between mb-4 rounded-lg p-3 mx-2 hover:bg-gray-100 shadow-lg"
            [ngClass]="{
              'w-full': mobileView,
              'w-1/3': !mobileView,
              'border-2': answer.checked,
              border: !answer.checked
            }"
            [ngStyle]="getSelectionStyle(i)"
            *ngFor="
              let answer of items.content.answers;
              let answerIndex = index
            "
            (click)="selectAnswer(answerIndex)"
          >
            <!--  <label
              [for]="'checkbox-' + answerIndex"
              class="text-sm mr-3 font-medium"
              [ngStyle]="getSelectionStyle(i)"
              >{{ answer.answerText }}
            </label> -->
            <input
              type="text"
              [(ngModel)]="answer.answerText"
              class="bg-transparent text-left px-2 w-full border-transparent focus:border-transparent focus:ring-0"
              (ngModelChange)="changed($event)"
              [ngStyle]="getSelectionStyle(i, true)"
            />
            <input
              [id]="'checkbox-' + answerIndex"
              aria-describedby="checkbox-1"
              type="checkbox"
              class="bg-gray-50 border-gray-300 h-5 w-5 rounded-full"
              [ngStyle]="getSelectionStyle(i)"
            />
          </div>
        </div>

        <div
          *ngIf="items.type === 'fakeloader'"
          class="flex justify-center"
          [ngClass]="{ 'w-full': mobileView, 'w-1/3': !mobileView }"
        >
          <div>
            <div
              *ngIf="items.content.type === 'spinner'"
              class="border-2 border-solid rounded-full animate-spin border-t-transparent"
              [ngStyle]="getStyle(i)"
            ></div>
          </div>
        </div>

        <img
          *ngIf="items.type === 'picture'"
          [ngStyle]="getStyle(i)"
          [src]="getSantizeUrl(items.content)"
        />

        <div
          [ngStyle]="getStyle(i)"
          *ngIf="items.type === 'quiz'"
          class="w-full flex justify-center flex-wrap"
        >
          <div
            class="mx-3 my-3"
            *ngFor="
              let answer of items.content.answers;
              let answerIndex = index
            "
          >
            <div
              (click)="selectAnswerButton(answerIndex)"
              class="h-36 w-40 flex flex-col justify-evenly items-center rounded-lg shadow-xl border border-gray-200"
              [ngClass]="{
                'justify-between ': answer.type === 'picture',
                'justify-evenly': answer.type === 'icon'
              }"
              [ngStyle]="getQuizButtonStyle(answerIndex, i)"
            >
              <div class="flex flex-col justify-between items-center w-full">
                <img
                  *ngIf="answer.type === 'picture'"
                  [src]="getSantizeUrl(answer.answerPicture)"
                  class="w-full h-24 rounded-t-lg object-cover"
                />

                <div
                  *ngIf="answer.type === 'icon'"
                  class="h-24 flex justify-center items-center"
                  [ngStyle]="getQuizIconStyle(answerIndex, i)"
                >
                  <i class="" [ngClass]="answer.answerIcon"> </i>
                </div>
              </div>
              <div class="flex justify-center items-center w-full h-full">
                <!--  <p
                  class="text-base"
                  [ngStyle]="getQuizTextStyle(answerIndex, i)"
                >
                  {{ answer.answerText }}
                </p> -->
                <input
                  type="text"
                  [(ngModel)]="answer.answerText"
                  class="w-full bg-transparent text-center px-2 border-transparent focus:border-transparent focus:ring-0"
                  (ngModelChange)="changed($event)"
                  [ngStyle]="getQuizTextStyle(answerIndex, i)"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="items.type === 'form'"
          class="w-full px-4 flex justify-center flex-wrap"
          [ngStyle]="getStyle(i)"
        >
          <div [ngClass]="{ 'w-full': mobileView, 'w-2/3': !mobileView }">
            <div
              class="flex flex-col mt-2"
              *ngFor="let input of items.content.inputs; let inputIndex = index"
              (click)="selectFormElement(input, inputIndex, 'input')"
            >
              <div class="relative">
                <div
                  *ngIf="input.isIcon"
                  class="absolute text-gray-600 dark:text-gray-400 flex items-center pl-4 h-full cursor-pointer"
                >
                  <i [ngClass]="input.icon"></i>
                </div>
                <input
                  class="focus:outline-none focus:border bg-white font-normal w-full h-10 flex items-center text-sm border-gray-300 rounded-lg shadow border focus:border-gray-500 focus:ring-0"
                  [ngClass]="{ 'pl-12': input.isIcon, 'pl-4': !input.isIcon }"
                  [name]="input.text"
                  ngDefaultControl
                  [placeholder]="
                    input.required ? input.text : input.text + ' (optional)'
                  "
                  [type]="input.type"
                  [required]="input.required ? 'required' : null"
                  (focus)="focusFormInputIn(inputIndex)"
                  (focusout)="focusFormInputOut()"
                  [ngStyle]="getFormInputStyle(i, inputIndex)"
                />
              </div>
            </div>

            <div
              *ngIf="items.content.privacyCheck.required"
              (click)="
                selectFormElement(
                  null,
                  items.content.inputs.length,
                  'privacyCheck'
                )
              "
              class="w-full flex-row break-words"
            >
              <div class="p-1 card bordered mt-2">
                <div class="form-control">
                  <label class="cursor-pointer label flex justify-start">
                    <mat-checkbox class="rounded-lg mr-4"> </mat-checkbox>
                    <p
                      class="break-words whitespace-pre-line"
                      [ngStyle]="getFormPrivacyCheckStyle(i)"
                    >
                      {{ items.content.privacyCheck.text }}
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <button
            (click)="
              selectFormElement(null, items.content.inputs.length + 1, 'submit')
            "
            [ngStyle]="getFormSubmitButtonStyle(i)"
            class="my-2 transition duration-150 ease-in-out focus:outline-none rounded-lg border border-gray-300 px-6 py-2 w-full"
            [ngClass]="{ 'w-full': mobileView, 'w-2/3': !mobileView }"
            (mouseover)="over()"
            (mouseout)="out()"
          >
            {{ items.content.submitButton.text }}
          </button>
        </div>
      </div>
      <div class="w-1/6 flex flex-col items-center justify-center">
        <p class="text-sm text-gray-300" *ngIf="items.isHeader">Header</p>
        <div
          class="flex opacity-0 self-center group-hover:opacity-100"
          [ngClass]="{ 'opacity-100': currentElementIndex === i }"
          (mouseenter)="hoverListItem(i)"
          (mouseleave)="hoverListItem(null)"
        >
          <i class="bi bi-grip-vertical" cdkDragHandle></i>
          <button class="self-end" (click)="copyElement(i)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
          </button>
          <button class="self-end" (click)="removeElement(i)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="!html"
      class="px-4"
      [ngClass]="{ 'w-full': !mobileView, 'w-3/5': mobileView }"
    >
      <div [ngStyle]="getBackgroundColor()" class="flex w-3/5">
        <button
          mat-fab
          class="mt-20"
          color="secondary"
          (click)="toggleBlocks()"
        >
          +
        </button>
        <div class="mb-10"></div>
      </div>
    </div>

    <div
      *ngIf="html"
      class="px-4 flex"
      [ngClass]="{ 'w-full': !mobileView, 'w-3/5': mobileView }"
    >
      <div
        [ngStyle]="getBackgroundColor()"
        class="flex flex-col rounded-b-lg border-l border-r border-b border-gray-400"
        [ngClass]="{
          'w-full': !mobileView,
          ' w-96': mobileView,
          'border-t rounded-t-lg': html.length === 0
        }"
      >
        <button (click)="toggleBlocks()" class="my-10 self-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-12 w-12 text-blue-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>

        <div
          *ngIf="currentProject.privacyEnabled"
          class="flex justify-evenly px-4"
        >
          <a>Impressum</a>
          <a>Datenschutzerklärung</a>
        </div>

        <div class="mb-10"></div>

        <div
          id="progressbar"
          class="mx-0.5"
          style="left: 0; height: 4px; z-index: 3"
          [ngStyle]="getProgress('background')"
        >
          <div
            style="transition: width 0.4s ease-out; height: 4px"
            [ngStyle]="getProgress('val')"
          ></div>
        </div>
      </div>
      <div class="w-1/6"></div>
    </div>
  </div>
</div>
