import * as firebase from 'firebase';
import { Injectable } from '@angular/core';

import { UserService } from './user.service';
import { wording } from 'src/app/wording/wording';
import { Tier } from 'src/app/models/user.model';

@Injectable()
export class TierService {
  currentTier: Tier;

  readonly wording = wording;

  readonly USER_RIGHTS: any = {
    trial: {
      maxFunnels: 20,
      customDomain: 0, //Todo: delete domains
      hostingIdEnabled: true,
      maxPages: 1000, //Todo: deactivate pages
      facebookTracking: true,
      googleTracking: true,
      uploadBlockEnabled: true,
      branchingEnabled: true,
      multipleEntriesEnabled: true,
      showBadge: false,
      webhook: true,
      csvExportEnabled: true,
      embed: true,
      tracking: true,
      premiumBlock: true,
      premiumSupport: true,
      notification: true,
      partialEntries: true,
      inviteUsers: 0,
      maxImpressions: 100000,
    },
    basic: {
      maxFunnels: 5,
      customDomain: 1,
      hostingIdEnabled: true,
      maxPages: 5,
      facebookTracking: true,
      googleTracking: false,
      uploadBlockEnabled: false,
      branchingEnabled: false,
      multipleEntriesEnabled: false,
      csvExportEnabled: false,
      partialEntries: false,
      showBadge: true,
      webhook: false,
      embed: false,
      notification: false,
      inviteUsers: 0,
      maxImpressions: 8000,
    },
    professional: {
      maxFunnels: 10,
      customDomain: 5,
      hostingIdEnabled: true,
      maxPages: 1000,
      facebookTracking: true,
      googleTracking: true,
      uploadBlockEnabled: true,
      branchingEnabled: true,
      multipleEntriesEnabled: true,
      csvExportEnabled: true,
      showBadge: false,
      customDomains: 5,
      webhook: true,
      embed: true,
      notification: true,
      partialEntries: true,
      inviteUsers: 0,
      maxImpressions: 40000,
    },
    premium: {
      maxFunnels: 20,
      customDomain: 10,
      hostingIdEnabled: true,
      maxPages: 1000,
      facebookTracking: true,
      googleTracking: true,
      uploadBlockEnabled: true,
      branchingEnabled: true,
      multipleEntriesEnabled: true,
      csvExportEnabled: true,
      showBadge: false,
      webhook: true,
      embed: true,
      notification: true,
      partialEntries: true,
      inviteUsers: 3,
      maxImpressions: 100000,
    },
  };

  constructor(private userService: UserService) {}

  getCurrentTier() {
    return this.currentTier;
  }

  async setLanguage(tier: Tier) {
    /*  this.currentTier = tier
    if (typeof this.userService.userData !== 'undefined') {
      this.userService.userData.tier.tier = tier
    }
    await this.userService.setUser(this.userService.userData) */
  }

  getRights(feature: string, tier: string) {
    return this.USER_RIGHTS[tier][feature];
  }
}
