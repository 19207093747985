import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-settings-navigation',
  templateUrl: './settings-navigation.component.html',
  styleUrls: ['./settings-navigation.component.scss'],
})
export class SettingsNavigationComponent implements OnInit {
  state$: Subscription;
  navigationIndex: number = 0;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.navigationIndex = state.funnelSettings.navigationIndex;
      }
    });
  }

  setNavigation(index: number) {
    this.appService.stateSubject.next({
      ...this.appService.state,
      funnelSettings: {
        ...this.appService.state.funnelSettings,
        navigationIndex: index,
      },
    });
  }
}
