import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Block } from 'src/app/models/block.model';
import { State } from 'src/app/models/state.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent implements OnInit, OnDestroy {
  state$: Subscription;

  leftTabIndex: number = 0;
  selectedElement: Block | null;
  constructor(public dialog: MatDialog, private appService: AppService) {
    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        this.selectedElement = state.editor.layout.designer.selectedElement;
      }
    });
  }

  ngOnInit(): void {}

  setLeftTabIndex(index: number) {
    this.leftTabIndex = index;
  }

  ngOnDestroy() {}
}
