import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/models/project.model';
import { State } from 'src/app/models/state.model';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-new-editor',
  templateUrl: './new-editor.component.html',
  styleUrls: ['./new-editor.component.scss'],
})
export class NewEditorComponent implements OnInit {
  state$: Subscription;

  projectId: string;
  project: Project | null = null;
  user: User;
  adminEdit: string = '';
  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private userService: UserService,
    private router: Router,
    private projectService: ProjectService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.projectId = this.route.snapshot.paramMap.get('id') || '';
    this.adminEdit = this.route.snapshot.queryParamMap.get('adminEdit') || '';

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
        const isAdmin = this.checkIfEmailIncludesAdminMailaddress(user);
        if (this.adminEdit !== 'true' && isAdmin) {
          if (!user.funnelIds.includes(this.projectId)) {
            this.router.navigate(['/funnels']);
          }
        }
      }
    });

    this.state$ = this.appService.stateSubject.subscribe((state: State) => {
      if (state) {
        /*  this.selectedElement = state.editor.selectedElement;
        this.showBlocks = state.editor.showBlocks; */
      }
    });

    this.googleAnalyticsService.loadGoogleAnalyticsScript();
    this.googleAnalyticsService.eventEmitter(
      this.projectId,
      'Open_Funnel',
      'klicklead.de'
    );
  }

  checkIfEmailIncludesAdminMailaddress(user: User) {
    if (
      user.email.includes('@zewotherm.de') ||
      user.email.includes('@zw-systems.de') ||
      user.email.includes('@klicklead.de')
    ) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {}
}
