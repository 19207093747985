<div class="h-full flex flex-col bg-gray-50">
  <div class="flex flex-col">
    <i class="bi bi-chevron-left text-xl cursor-pointer" (click)="close()"></i>
    <div class="md:flex md:items-center md:justify-between p-10">
        
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-kl-grey sm:text-3xl sm:truncate"
        >
          Funnel Vorlagen
        </h2>
        <div class="flex gap-4 mt-6">
          <span
            class="w-1/4 cursor-pointer inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
          >
            <input
              type="text"
              placeholder="Suche"
              class="bg-transparent border-transparent focus:border-transparent focus:ring-0 border-none w-full"
            />
            <i class="ml-2 bi bi-search"></i>
          </span>

          <span
            class="cursor-pointer inline-flex items-center px-4  rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
          >
            Alle
          </span>

          <span
            class="cursor-pointer inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
          >
            <i class="bi bi-star-fill mr-2"></i>
            Meine Vorlagen
          </span>

          <span
            class="cursor-pointer inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
          >
            Recruiting-Funnel
          </span>
          <span
            class="cursor-pointer inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
            >Termin-Funnel
          </span>

          <span
            class="cursor-pointer inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
            >PV-Funnel
          </span>

          <span
            class="cursor-pointer inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-gray-200 text-kl-grey"
            >eCommerce-Funnel
          </span>
        </div>
      </div>
    </div>

    <div class="w-full flex gap-10 px-10 py-1">
      <!-- component -->
      <div
        class="w-1/5 bg-white hover:bg-kl-grey shadow-md rounded-md px-6 py-2 my-2 cursor-pointer group"
      >
        <div class="flex flex-col justify-center items-center h-full">
          <div
            class="block text-kl-grey group-hover:text-white hover:underline"
          >
            <i class="bi bi-plus-circle text-4xl"></i>
          </div>
          <h3
            class="mt-4 text-xl text-kl-grey group-hover:text-white font-bold"
          >
            Leeren Funnel erstellen
          </h3>
        </div>
      </div>
      <div
        class="w-1/5 bg-white shadow-lg rounded-md px-6 py-2 my-2 cursor-pointer"
      >
        <div class="flex flex-col justify-start w-full">
          <div class="flex flex-col justify-start items-center w-full">
            <img src="./assets/preview.png" class="w-full h-auto" />
            <h3 class="text-2xl text-kl-grey font-bold">PV-Funnel</h3>
          </div>
          <div class="flex justify-between w-full mt-1">
            <span class="text-gray-400 text-xs"
              >Beschreibung des Funnels lorem ipsum....
            </span>
          </div>
        </div>

        <div class="flex justify-end">
          <a class="block mt-6 text-kl-green hover:underline" href="#"
            ><i class="bi bi-arrow-right-circle-fill text-4xl"></i
          ></a>
        </div>
      </div>

      <div
        class="w-1/5 bg-white shadow-lg rounded-md px-6 py-2 my-2 cursor-pointer"
      >
        <div class="flex flex-col justify-start w-full">
          <div class="flex flex-col justify-start items-center w-full">
            <img src="./assets/preview.png" class="w-full h-auto" />
            <h3 class="text-2xl text-kl-grey font-bold">Recruiting-Funnel</h3>
          </div>
          <div class="flex justify-between w-full mt-1">
            <span class="text-gray-400 text-xs"
              >Beschreibung des Funnels lorem ipsum....
            </span>
          </div>
        </div>

        <div class="flex justify-end">
          <a class="block mt-6 text-kl-purple hover:underline" href="#"
            ><i class="bi bi-arrow-right-circle-fill text-4xl"></i
          ></a>
        </div>
      </div>

      <div
        class="w-1/5 bg-white shadow-lg rounded-md px-6 py-2 my-2 cursor-pointer"
      >
        <div class="flex flex-col justify-start w-full">
          <div class="flex flex-col justify-start items-center w-full">
            <img src="./assets/preview.png" class="w-full h-auto" />
            <h3 class="text-2xl text-kl-grey font-bold">
              Softwareentwickler-Funnel
            </h3>
          </div>
          <div class="flex justify-between w-full mt-1">
            <span class="text-gray-400 text-xs"
              >Beschreibung des Funnels lorem ipsum....
            </span>
          </div>
        </div>

        <div class="flex justify-end">
          <a class="block mt-6 text-kl-purple hover:underline" href="#"
            ><i class="bi bi-arrow-right-circle-fill text-4xl"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
