import { Component, Input, OnInit } from '@angular/core';
import { Block, BlockStyle } from 'src/app/models/block.model';
import { BlockService } from 'src/app/services/block.service';

@Component({
  selector: 'app-bulletpoints',
  templateUrl: './bulletpoints.component.html',
  styleUrls: ['./bulletpoints.component.scss'],
})
export class BulletpointsComponent implements OnInit {
  @Input() block: Block;
  @Input() blocks: Block[] = [];
  @Input() index: number = 0;
  @Input() style: BlockStyle;

  constructor(private blockService: BlockService) {}

  ngOnInit(): void {}

  getBulletpointIconStyle() {
    let style: any = this.blockService.getBulletpointStyle(
      this.blocks,
      this.index,
      'icon'
    );
    return style;
  }

  getBulletpointStyle() {
    let style: any = this.blockService.getBulletpointStyle(
      this.blocks,
      this.index,
      'text'
    );

    return style;
  }
}
