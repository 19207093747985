import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { isUserInTrialAllowed } from 'src/app/trialCheck';

@Component({
  selector: 'app-funnel-presets',
  templateUrl: './funnel-presets.component.html',
  styleUrls: ['./funnel-presets.component.scss'],
})
export class FunnelPresetsComponent implements OnInit {
  projectId: string;
  presets: any;
  showColor: boolean = false;
  selectedElement: any;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private appService: AppService,
    private sanitizer: DomSanitizer
  ) {
    this.projectId = this.route.snapshot.params.id;

    this.userService.userDataSubject.subscribe((user: any) => {
      if (user) {
        if (!isUserInTrialAllowed(user)) {
          this.router.navigateByUrl('/user-settings?payment=true');
        }
      }
    });

    this.projectService.currentProjectSubject.subscribe((project: any) => {
      if (project) {
        if (project.designPresets !== undefined) {
          this.presets = project.designPresets;
        } else {
          this.presets = {
            fontFamily: 'Arial',
            alignment: 'center',
            contentAlignment: 'center',
            backgroundColor: '#ffffff',
            fontColor: '#000000',
            fontSize: 16,
          };
        }

      /*   this.createFontLink(); */
      }
    });
  }
/* 
  createFontLink() {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
     link.href = this.getFontCSS() as string;
    document.head.appendChild(link);
  } */

  getFontCSS() {
    if (!this.presets || !this.presets.fontFamily) {
      return 'Roboto';
    }
    const font = this.presets.fontFamily.replace(' ', '+');
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://fonts.googleapis.com/css2?family=' +
        font +
        ':wght@100;200;300;400;500;600;700;800;900&display=swap'
    );
  }

  async ngOnInit() {
    this.projectService.currentProject =
      await this.projectService.getProjectById(this.projectId);
  }

  setFontFamily(font: string) {
    this.presets.fontFamily = font;

    this.projectService.currentProjectSubject.next({
      ...this.projectService.currentProject,
      designPresets: this.presets,
    });
/*     this.createFontLink();
 */
    this.projectService.saveProject(this.projectService.currentProject!);
  }

  changed(event: any, type?: string) {
    switch (type) {
      case 'videoUrl': {
        this.presets.content.url = event;
        break;
      }

      case 'fakeloader': {
        this.presets.content.duration = event;
        break;
      }

      case 'slidersteps': {
        this.presets.content.steps = event;
        break;
      }
      case 'slidermin': {
        this.presets.content.min = event;
        break;
      }
      case 'slidermax': {
        this.presets.content.max = event;
        break;
      }
      case 'socialmediaUrl': {
        this.presets.content.url = event;
        break;
      }
      case 'textfield': {
        this.presets.content.text = event;
        break;
      }
      case 'textfieldRequired': {
        this.presets.content.required = event;
        break;
      }
      case 'fontSize': {
        this.presets.fontSize = event;

        break;
      }
      case 'iconSize': {
        this.presets.style!.iconSize = event;
        break;
      }
      case 'picture': {
        this.presets.style!.width = event;
        break;
      }
      case 'bulletpointsIconSize': {
        this.presets.content.iconStyle.fontSize = event;
        break;
      }
      case 'bulletpointsTextSize': {
        this.presets.content.textStyle.fontSize = event;
        break;
      }
      case 'submitButtonFontSize': {
        this.presets.content.submitButton.style.fontSize = event;
        break;
      }

      default:
        break;
    }

    this.projectService.currentProjectSubject.next({
      ...this.projectService.currentProject,
      designPresets: this.presets,
    });

    this.projectService.saveProject(this.projectService.currentProject!);
  }

  setAlignment(alignment: string) {
    this.presets.alignment = alignment;

    this.projectService.currentProjectSubject.next({
      ...this.projectService.currentProject,
      designPresets: this.presets,
    });

    this.projectService.saveProject(this.projectService.currentProject!);
  }

  setContentAlignment(alignment: string) {
    this.presets.contentAlignment = alignment;

    this.projectService.currentProjectSubject.next({
      ...this.projectService.currentProject,
      designPresets: this.presets,
    });

    this.projectService.saveProject(this.projectService.currentProject!);
  }

  openColorPicker() {
    this.showColor = true;

    const colorPicker = document.getElementById('colorPickerSidebar');
    colorPicker?.focus();
    colorPicker?.click();
  }

  setFontColor(color: string) {
    this.showColor = false;

    this.presets.fontColor = color;

    this.projectService.currentProjectSubject.next({
      ...this.projectService.currentProject,
      designPresets: this.presets,
    });

    this.projectService.saveProject(this.projectService.currentProject!);
  }

  setContainerBackgroundColor(color: string) {
    this.showColor = false;
    this.presets.containerBackgroundColor = color;

    this.projectService.currentProjectSubject.next({
      ...this.projectService.currentProject,
      designPresets: this.presets,
    });

    this.projectService.saveProject(this.projectService.currentProject!);
  }

  setBackgroundColor(color: string) {
    this.showColor = false;
    this.presets.backgroundColor = color;

    this.projectService.currentProjectSubject.next({
      ...this.projectService.currentProject,
      designPresets: this.presets,
    });

    this.projectService.saveProject(this.projectService.currentProject!);
  }

  setQuizAnswerBackgroundColor(color: string) {
    this.showColor = false;
    this.presets.quizAnswerBackgroundColor = color;

    this.projectService.currentProjectSubject.next({
      ...this.projectService.currentProject,
      designPresets: this.presets,
    });

    this.projectService.saveProject(this.projectService.currentProject!);
  }

  setQuizAnswerFontColor(color: string) {
    this.showColor = false;
    this.presets.quizAnswerFontColor = color;

    this.projectService.currentProjectSubject.next({
      ...this.projectService.currentProject,
      designPresets: this.presets,
    });

    this.projectService.saveProject(this.projectService.currentProject!);
  }
}
