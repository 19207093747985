import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-email-text-dialog',
  templateUrl: './email-text-dialog.component.html',
  styleUrls: ['./email-text-dialog.component.scss'],
})
export class EmailTextDialogComponent implements OnInit {
  pos: number = 0;

  constructor(
    public dialogRef: MatDialogRef<EmailTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public langService: LanguageService
  ) {}

  ngOnInit() {
    let foo = document.getElementById('foobar');
    if (!foo) return;
    foo.addEventListener('keyup', (e: any) => {
      this.pos = e.target['selectionStart'];
    });

    foo.addEventListener('mouseup', (e: any) => {
      this.pos = e.target['selectionStart'];
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addLead() {
    this.data.name =
      this.data.name.slice(0, this.pos) +
      '[LEAD]' +
      this.data.name.slice(this.pos);
  }

  addLeadAnswers() {
    this.data.name =
      this.data.name.slice(0, this.pos) +
      '[ANSWERS]' +
      this.data.name.slice(this.pos);
  }
}
