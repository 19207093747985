<div
  class="flex flex-col items-center justify-center w-full px-2"
  
>
  <div
    class="flex items-center justify-between p-3 mx-2 mb-4 rounded shadow-lg"
    [ngClass]="{
      'w-full': mobileView,
      'w-1/3': !mobileView,
      'border-2': answer.checked,
      border: !answer.checked
    }"
    
    [ngStyle]="style"
    *ngFor="let answer of block.content.answers; let answerIndex = index"
    (click)="selectAnswer(answerIndex)"
  >
    <div
      contenteditable="true"
      class="w-full px-2 bg-transparent border-transparent focus:border-transparent focus:ring-0"
      (blur)="changed($event)"
      [ngStyle]="getSelectionStyle(true)"
      [ngClass]="{
        'text-center': style.contentAlignment === 'center',
        'text-left': style.contentAlignment === 'start',
        'text-right': style.contentAlignment === 'end'
      }"
    >
      {{ answer.answerText }}
    </div>
    <input
      [id]="'checkbox-' + answerIndex"
      aria-describedby="checkbox-1"
      type="checkbox"
      class="w-5 h-5 border-gray-300 rounded-full bg-gray-50"
      [ngStyle]="getSelectionStyle()"
    />
  </div>
</div>
