import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from 'src/app/enums/notification-type';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { MailMarketingService } from 'src/app/services/mail-marketing.service';
import { ProjectService } from 'src/app/services/project.service';
import { StripeDataService } from 'src/app/services/stripe-data.service';
import { UserService } from 'src/app/services/user.service';
import { DialogModalComponent } from '../modals/dialog-modal/dialog-modal.component';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  @Input() user: User;
  @Output() onShowPricingTable: EventEmitter<any> = new EventEmitter();
  invoices: any[] = [];
  loading: boolean = false;
  constructor(
    private stripeDataService: StripeDataService,
    private dialog: MatDialog,
    private userService: UserService,
    private mailMarketingService: MailMarketingService,
    private projectService: ProjectService,
    private appService: AppService
  ) {}

  async ngOnInit() {
    this.loadInvoices();
    console.log(this.invoices);
  }

  // check if its a valid email address
  async updateUser() {
    const validatedEmail = this.validateEmail(this.user.receiptEmail);
    if (validatedEmail) {
      await this.userService.setUser(this.user);
      this.user.receiptEmail = this.user.receiptEmail || '';
      await this.stripeDataService.updateReceiptEmail(
        this.user.stripeCustomerId!,
        this.user.receiptEmail
      );

      this.appService.showNotification(
        'Gespeichert',
        'Daten wurden aktualisiert',
        NotificationType.SUCCESS,
        4000
      );
    } else {
      this.appService.showNotification(
        'E-Mail',
        'Bitte gib eine gültige E-Mail Adresse ein',
        NotificationType.ERROR,
        4000
      );
    }
  }

  validateEmail = (email: string) => {
    return !!email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  async loadInvoices() {
    this.loading = true;
    if (this.user.stripeCustomerId) {
      const invoiceRequest: any = await this.stripeDataService.loadInvoices(
        this.user.stripeCustomerId!
      );
      this.invoices = invoiceRequest.data;
    }
    this.loading = false;
  }

  showPricingTable() {
    this.loadInvoices();
    this.onShowPricingTable.emit();
  }

  cancelSubscription() {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      data: {
        headline: 'Plan kündigen',
        text: 'Möchtest du dein Plan wirklich kündigen?',
        buttonText: 'kündigen',
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const canceled: any = await this.stripeDataService.cancelSubscription(
          this.user.stripeCustomerId!
        );
        console.log(canceled);
        this.user.priceId = '';
        this.user.tier = 'trial';
        this.user.cancelAt = canceled.subscription['cancel_at'];

        for (let funnelId of this.user.funnelIds) {
          const funnel = await this.projectService.getProjectById(funnelId);
          funnel.showBadge = true;
          await this.projectService.saveProject(funnel);
        }

        this.user.paymentInterval = '';
        //this.user.stripeCustomerId = '';
        this.user.subscriptionId = '';

        await this.userService.setUser(this.user);
        await this.mailMarketingService.untagSubscriber(
          this.user.email,
          8636723
        );
      }
    });
  }
}
