<div
  class="flex absolute left-1/2 transform -translate-x-1/2 -top-10 ml-3"
  style="width: 400px"
>
  <div
    class="w-full h-9 bg-kl-grey rounded-xl flex justify-around items-center px-2 py-4"
    *ngIf="true"
  >
    <button
      type="button"
      (click)="setBold()"
      [disabled]="!isVisible('bold')"
      class="-ml-px relative inline-flex items-center px-1 py-1 bg-kl-grey font-medium text-white hover:bg-gray-500 focus:z-10"
      [ngClass]="{ 'opacity-20': !isVisible('bold') }"
    >
      <i class="bi bi-type-bold text-white cursor-pointer"></i>
    </button>
    <button
      type="button"
      (click)="setItalic()"
      [disabled]="!isVisible('italic')"
      class="-ml-px relative inline-flex items-center px-1 py-1 bg-kl-grey font-medium text-white hover:bg-gray-500 focus:z-10"
      [ngClass]="{ 'opacity-20': !isVisible('italic') }"
    >
      <i class="bi bi-type-italic text-white cursor-pointer"></i>
    </button>
    <button
      type="button"
      (click)="setUnderline()"
      [disabled]="!isVisible('underline')"
      class="-ml-px relative inline-flex items-center px-1 py-1 bg-kl-grey font-medium text-white hover:bg-gray-500 focus:z-10"
      [ngClass]="{ 'opacity-20': !isVisible('underline') }"
    >
      <i class="bi bi-type-underline text-white cursor-pointer"></i>
    </button>
    <div
      class="flex justify-center items-center"
      [ngClass]="{ 'opacity-20': !isVisible('color') }"
    >
      <div
        class="w-4 h-4 border border-white cursor-pointer rounded-full"
        [ngStyle]="{ 'background-color': selectedElement.style?.fontColor }"
        (click)="openColorPicker()"
      ></div>
      <input
        *ngIf="isVisible('color')"
        class="-ml-2 opacity-20 w-0 h-0"
        type="color"
        id="colorPicker"
        name="favcolor"
        (blur)="setColor($event)"
      />
    </div>
    <!-- <div
    (click)="toggleColorSketch()"
    class="w-4 h-4 rounded-full bg-blue-600 cursor-pointer"
  ></div> -->

    <span
      class="relative z-0 inline-flex shadow-sm rounded-md"
      [ngClass]="{ 'opacity-20': !isVisible('fontsize') }"
    >
      <button
        [disabled]="!isVisible('fontsize')"
        (click)="decreaseFontSize()"
        type="button"
        class="relative inline-flex items-center px-1 py-1 rounded-l-md border border-gray-300 bg-kl-grey text-xs font-medium text-white hover:bg-gray-500 focus:z-10"
      >
        -
      </button>
      <input
        [disabled]="!isVisible('fontsize')"
        *ngIf="selectedElement && selectedElement.style"
        [(ngModel)]="selectedElement.style.fontSize"
        type="number"
        class="-ml-px relative w-12 inline-flex items-center px-1 py-1 border border-gray-300 bg-kl-grey text-xs font-medium text-white hover:bg-gray-500 focus:z-10"
      />
      <button
        [disabled]="!isVisible('fontsize')"
        (click)="increaseFontSize()"
        type="button"
        class="-ml-px relative inline-flex items-center px-1 py-1 rounded-r-md border border-gray-300 bg-kl-grey text-xs font-medium text-white hover:bg-gray-500 focus:z-10"
      >
        +
      </button>
    </span>

    <span
      class="relative z-0 inline-flex shadow-sm rounded-md"
      [ngClass]="{ 'opacity-20': !isVisible('alignment') }"
    >
      <button
        [disabled]="!isVisible('alignment')"
        (click)="alignElement('left-item')"
        type="button"
        class="relative inline-flex items-center px-1 py-1 rounded-l-md border border-gray-300 bg-kl-grey text-xs font-medium text-white hover:bg-gray-500 focus:z-10"
      >
        <i class="bi bi-justify-left"></i>
      </button>
      <button
        [disabled]="!isVisible('alignment')"
        (click)="alignElement('center-item')"
        type="button"
        class="-ml-px relative inline-flex items-center px-1 py-1 border border-gray-300 bg-kl-grey text-xs font-medium text-white hover:bg-gray-500 focus:z-10"
      >
        <i class="bi bi-justify"></i>
      </button>
      <button
        [disabled]="!isVisible('alignment')"
        (click)="alignElement('right-item')"
        type="button"
        class="-ml-px relative inline-flex items-center px-1 py-1 rounded-r-md border border-gray-300 bg-kl-grey text-xs font-medium text-white hover:bg-gray-500 focus:z-10"
      >
        <i class="bi bi-justify-right"></i>
      </button>
    </span>
  </div>
  <span class="relative z-0 inline-flex rounded-md">
    <button
      (click)="close()"
      type="button"
      class="-ml-px relative inline-flex items-center px-1 py-1 text-xs font-medium text-gray-700 focus:z-10"
    >
      <i class="bi bi-x text-lg"></i>
    </button>
  </span>
</div>
