import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Block, InputField } from 'src/app/models/block.model';
import {
  AnswerSubmission,
  Page,
  PageAnswers,
} from 'src/app/models/project.model';
import { AppService } from 'src/app/services/app.service';
import { LanguageService } from 'src/app/services/lang.service';
import { ProjectService } from 'src/app/services/project.service';
import { DialogModalComponent } from '../../modals/dialog-modal/dialog-modal.component';

@Component({
  selector: 'app-leads-charts',
  templateUrl: './leads-charts.component.html',
  styleUrls: ['./leads-charts.component.scss'],
})
export class LeadsChartsComponent implements OnInit {
  projectId: string;
  displayedColumnsPartial: string[] = [
    'Nr.',
    this.langService.getWording('funnelStatistics', 'tableDate'),
  ];

  form: any = { pageId: null, form: null };
  statisticData: any[] = [];
  isMenuOpen: boolean = false;
  currentMenuSelection: string = 'Gesamt';
  menuItems: string[] = [
    'Heute',
    'Gestern',
    'Letzten 7 Tage',
    'Letzten 30 Tage',
    'Letzten 90 Tage',
    'Letzten 365 Tage',
    'Gesamt',
  ];
  todayDate: Date = new Date();
  fromDate: Date | null = new Date();
  toDate: Date | null = new Date();
  leads: any[] = [];
  leadCount: number = 0;
  impressionCount: number = 0;

  @Input() allLeads: any[] = [];
  @Input() impressions: any;
  convRate: number = 0;
  @Input() answerSubmissions: AnswerSubmission[] = [];
  allAnswerSubmissions: AnswerSubmission[] = [];
  @ViewChild(MatDatepicker) pickerTo: MatDatepicker<any>;

  @ViewChild(MatDatepicker) pickerFrom: MatDatepicker<any>;

  view: [number, number] = [700, 400];
  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private appService: AppService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.projectId = this.route.snapshot.params.id;

    this.leads = [...this.allLeads];
    const partialRespone = await this.projectService.getSubmissionAnswers(
      this.projectId
    );
    this.allAnswerSubmissions = partialRespone;
    this.loadStatistics(this.allAnswerSubmissions, false);
    console.log(this.leads);
    console.log(this.answerSubmissions);
    this.loadLeads();
    this.getConvertRate();
    this.toDate = null;
    this.fromDate = null;
  }
  navigateToAllLeads() {
    this.appService.stateSubject.next({
      ...this.appService.state,
      funnelLeads: {
        ...this.appService.state.funnelLeads,
        navigationIndex: 1,
      },
    });
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  onResize(event: any) {
    this.view = [event.target.innerWidth / 1.0, 30];
  }

  async selectMenuItem(menuItem: string) {
    this.toDate = null;
    this.fromDate = null;

    this.currentMenuSelection = menuItem;
    switch (menuItem) {
      case 'Heute': {
        this.answerSubmissions = this.allAnswerSubmissions.filter((d) => {
          var time = new Date(d.date).getTime();

          const subTime = new Date(d.date);
          return subTime.getTime() <= time && time <= this.todayDate.getTime();
        });
        this.toDate = this.todayDate;
        this.fromDate = this.todayDate;
        this.loadStatistics(this.answerSubmissions, false);

        this.getConvertRate('custom');
        break;
      }
      case 'Gestern': {
        let date = new Date();

        date.setDate(date.getDate() - 1);
        const yesterday: Date = date;
        console.log(yesterday);
        this.toDate = yesterday;
        this.fromDate = yesterday;
        this.getDates(this.fromDate, this.toDate);
        this.loadStatistics(this.answerSubmissions, false);

        break;
      }

      case 'Letzten 7 Tage': {
        let date = new Date();

        date.setDate(date.getDate() - 7);
        const last7Days = date;
        console.log(last7Days);

        this.toDate = this.todayDate;
        this.fromDate = last7Days;
        this.getDates(last7Days, this.todayDate);
        this.loadStatistics(this.answerSubmissions, false);

        this.getConvertRate('custom');

        break;
      }

      case 'Letzten 30 Tage': {
        let date = new Date();

        date.setDate(date.getDate() - 30);
        const last30Days = date;
        this.toDate = this.todayDate;
        this.fromDate = last30Days;
        this.getDates(last30Days, this.todayDate);
        this.loadStatistics(this.answerSubmissions, false);

        this.getConvertRate('custom');

        break;
      }
      case 'Letzten 90 Tage': {
        let date = new Date();

        date.setDate(date.getDate() - 90);
        const last90Days = date;
        this.toDate = this.todayDate;
        this.fromDate = last90Days;
        this.getDates(last90Days, this.todayDate);
        this.loadStatistics(this.answerSubmissions, false);

        this.getConvertRate('custom');

        break;
      }
      case 'Letzten 365 Tage': {
        let date = new Date();

        date.setDate(date.getDate() - 365);
        const last365Days = date;
        this.toDate = this.todayDate;
        this.fromDate = last365Days;
        this.getDates(last365Days, this.todayDate);
        this.loadStatistics(this.answerSubmissions, false);

        this.getConvertRate('custom');

        break;
      }
      case 'Gesamt': {
        this.pickerFrom.select(undefined);
        this.pickerTo.select(undefined);
        this.answerSubmissions = this.allAnswerSubmissions;
        this.loadStatistics(this.answerSubmissions, false);

        this.loadLeads();
        this.getConvertRate();
        break;
      }

      default:
        break;
    }

    this.toggleMenu();
  }

  setFilterDate(event: any, type: string) {
    if (type === 'from') {
      this.fromDate = event.value;
    } else {
      this.toDate = event.value;
    }

    if (this.fromDate && this.toDate) {
      let date = new Date(this.toDate);

      let toDate = date;

      this.getDates(this.fromDate, toDate);
      this.loadStatistics(this.answerSubmissions, false);

      this.currentMenuSelection = 'Individuell';
      this.getConvertRate('custom');
    }
    console.log(event);
  }

  getDates(startDate: Date, stopDate: Date) {
    this.answerSubmissions = this.allAnswerSubmissions;
    this.answerSubmissions = this.answerSubmissions.filter((d) => {
      var time = new Date(d.date).getTime();
      startDate.setHours(0, 0, 0, 0);
      stopDate.setHours(23, 59, 59, 999);
      console.log(startDate.getTime());
      return startDate.getTime() <= time && time <= stopDate.getTime();
    });
    this.loadStatistics(this.answerSubmissions, false);
    console.log(this.answerSubmissions);
  }

  async loadLeads() {
    let data: any[] = [];
    let dataPartial: any[] = [];
    let statdata: any[] = [];
    this.leadCount = 0;
    /* 
    partialRespone.forEach((as: AnswerSubmission) => {
      as.submissions.forEach((sub: PageAnswers) => {
        if (Array.isArray(sub.answer)) {
          let subArray = [];
          sub.answer.forEach((inp: InputField) => {
            if (inp.value !== null) {
              subArray.push({ [inp.text]: inp.value });
            }
          });

          if (subArray.length !== 0) {
            let object = {};
            subArray.push({ answerSubmission: as });
            subArray.forEach(function (element: any, index: number) {
              Object.assign(object, element);
            });
            // Object.assign(object, { nr: data.length + 1 })
            let dateString = this.langService.getWording(
              'funnelStatistics',
              'tableDate'
            );

            Object.assign(object, {
              [dateString.toString()]: as.date,
            });

            data.push(object);
          }
        }

        let index = statdata.findIndex((sd) => sd.id === sub.pageId);

        if (index !== -1) {
          statdata[index].value++;
        }
      });
    });

    this.statisticData = statdata; */

    this.answerSubmissions.forEach(
      (element: AnswerSubmission, index: number) => {
        let answerObj = {};
        // if (element.submissions.length !== this.projectService.currentProject.pages.length) {
        element.submissions.forEach((sub: any, index: number) => {
          if (!this.projectService.currentProject) return;
          let page = this.projectService.currentProject.pages.find(
            (p) => p.id.toString() === sub.pageId.toString()
          );

          if (typeof page !== 'undefined') {
            if (Array.isArray(sub.answer)) {
              let formData: any[] = [];
              sub.answer.forEach((inp: InputField) => {
                if (inp.value !== null) {
                  formData.push(inp.text + ': ' + inp.value);
                }
              });
              Object.assign(answerObj, { [page.name]: formData.join() });
            } else {
              let answer: string = '';

              if (sub.answer !== null) {
                answer = sub.answer.answerText
                  ? sub.answer.answerText
                  : sub.answer;
              }

              Object.assign(answerObj, {
                [page.name]: sub.answer !== null ? answer : '-',
              });
            }
          } else {
            if (Array.isArray(sub.answer)) {
              let formData: any[] = [];
              sub.answer.forEach((inp: InputField) => {
                if (inp.value !== null) {
                  formData.push(inp.text + ': ' + inp.value);
                }
              });
              Object.assign(answerObj, {
                ['gelöschte Seite' + index]: formData.join(),
              });
            } else {
              let answer: string = '';

              if (sub.answer !== null) {
                answer = sub.answer.answerText
                  ? sub.answer.answerText
                  : sub.answer;
              }
              Object.assign(answerObj, {
                ['gelöschte Seite' + index]: sub.answer !== null ? answer : '-',
              });
            }
          }
        });
        let dateString = this.langService.getWording(
          'funnelStatistics',
          'tableDate'
        );

        Object.assign(answerObj, {
          [dateString.toString()]: element.date,
        });

        dataPartial.push(answerObj);
        //  }
      }
    );

    this.leadCount = dataPartial.length;
    this.allLeads = data;
  }

  getConvertRate(type?: string) {
    if (type === 'custom') {
      let returnVal = (
        (this.leadCount * 100) /
        this.answerSubmissions.length
      ).toFixed(2);

      if (returnVal === 'NaN') {
        this.convRate = 0;
      } else {
        this.convRate = parseInt(returnVal);
      }

      this.impressionCount = this.answerSubmissions.length;
    } else {
      if (
        typeof this.answerSubmissions !== 'undefined' ||
        this.impressions.leadCount
      ) {
        if (this.impressions.impressions !== 0) {
          let returnVal = (
            (this.impressions.leadCount * 100) /
            this.impressions.impressions
          ).toFixed(2);

          if (returnVal === 'NaN') {
            this.convRate = 0;
          } else {
            this.convRate = parseInt(returnVal);
          }
        }

        this.impressionCount = this.impressions.impressions;
        this.leadCount = this.impressions.leadCount;
      }
    }
  }

  async loadStatistics(
    answerSubmissions: AnswerSubmission[],
    isInitial: boolean = false
  ) {
    //this.statisticData = [];
    let statdata: any[] = [];
    this.leadCount = 0;
    /*  this.projectService.currentProject =
      await this.projectService.getProjectById(this.projectId); */

    if (!this.projectService.currentProject) return;
    this.projectService.currentProject.pages.forEach((p: Page) => {
      this.displayedColumnsPartial.push(p.name);
      p.blocks.forEach((block: Block) => {
        if (block.type === 'form') {
          this.form.form = block.content['inputs'];
          this.form.pageId = p.id.toString();
        }
      });

      statdata.push({
        name: p.name,
        id: p.id.toString(),
        value: 0,
      });
    });

    let data: any[] = [];

    answerSubmissions.forEach((as: AnswerSubmission) => {
      as.submissions.forEach((sub: PageAnswers) => {
        if (Array.isArray(sub.answer)) {
          let subArray = [];
          sub.answer.forEach((inp: InputField) => {
            if (inp.value !== null) {
              subArray.push({ [inp.text]: inp.value });
            }
          });

          if (subArray.length !== 0) {
            let object = {};
            subArray.push({ answerSubmission: as });
            subArray.forEach(function (element: any, index: number) {
              Object.assign(object, element);
            });
            // Object.assign(object, { nr: data.length + 1 })
            let dateString = this.langService.getWording(
              'funnelStatistics',
              'tableDate'
            );

            Object.assign(object, {
              [dateString.toString()]: as.date,
            });

            data.push(object);
          }
        }

        let index = statdata.findIndex((sd) => sd.id === sub.pageId);

        if (index !== -1) {
          statdata[index].value++;
        }
      });
    });

    if (isInitial) {
      this.allAnswerSubmissions = answerSubmissions;
    }

    this.impressionCount = answerSubmissions.length;

    this.leadCount = data.length;

    this.statisticData = statdata;

    let returnVal = ((this.leadCount * 100) / this.impressionCount).toFixed(2);

    if (returnVal === 'NaN') {
      this.convRate = 0;
    } else {
      this.convRate = parseInt(returnVal);
    }

    console.log(this.statisticData);
  }

  async resetStatistic() {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: {
        buttonText: 'Zurücksetzen',
        text: 'Wollen Sie die Statistik (Leads, Alle Einträge) wirklich zurücksetzen?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (!this.projectService.currentProject) return;
        await this.projectService.deleteAnswerSubmissions(this.projectId);

        const projectList = await this.projectService.getProjectListById(
          this.projectId
        );
        console.log(projectList);
        projectList.impressions = 0;
        projectList.leadCount = 0;
        await this.projectService.saveProjectList(this.projectId, projectList);
        this.answerSubmissions = [];
        this.allLeads = [];

        this.statisticData = [];
      }
    });
  }
}
