import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { Subject } from 'rxjs';

@Injectable()
export class AuthService {
  public userAuthSubject = new Subject<any>();
  userAuthObservable$ = this.userAuthSubject.asObservable();

  constructor(private router: Router, private auth: AngularFireAuth) {
    this.auth.authState.subscribe((user) => {
      if (user) {
        this.userAuthSubject.next(user);
      }
    });
  }

  isLoggedIn() {
    return new Promise<any>((resolve, reject) => {
      this.auth.onAuthStateChanged((user) => {
        if (typeof user === 'undefined' || user === null) {
          resolve(false);
        } else {
          resolve(user);
        }
      });
    });
  }

  signUp(credentials: any) {
    return this.auth.createUserWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  signInWithEmail(credentials: any) {
    return this.auth.signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  signInWithGoogle() {
    return this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  signOut(): Promise<void> {
    return this.auth.signOut();
  }

  async sendEmailVerification() {
    const user = await this.auth.currentUser;
    if (!user) return;
    user.sendEmailVerification();
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.auth.sendPasswordResetEmail(passwordResetEmail);
  }

  async deleteUser() {
    const user = await this.auth.currentUser;
    if (!user) return;
    user.delete();
  }
}
