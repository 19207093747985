import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { ProjectService } from 'src/app/services/project.service';
import { LanguageService } from 'src/app/services/lang.service';
import { Project } from 'src/app/models/project.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  pass: boolean = false;
  errors: boolean = false;
  errorText: string;
  success: boolean = false;
  successText: string;
  isEmailValid: boolean | null = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private projectService: ProjectService,
    public langService: LanguageService,
    private appService: AppService
  ) {
    this.projectService.currentProject = <Project>{};
  }

  ngOnInit() {
    this.appService.showHeaderSubject.next(false);
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  goToRegister() {
    this.router.navigate(['/register']);
  }

  validateEmail = (email: string) => {
    return !!email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  checkIsEmailValid() {
    this.isEmailValid = this.validateEmail(this.form.value.email);
  }

  async submit() {
    if (this.form.valid) {
      await this.authService.sendPasswordResetEmail(this.form.value.email);
      this.success = true;
      this.successText = 'Dein Passwort wurde an die E-Mail-Adresse gesendet.';
    } else {
      this.errors = true;
      this.errorText = 'Bitte fülle alle Felder korrekt aus';
    }
  }
}
