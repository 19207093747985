import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Block, InputField } from 'src/app/models/block.model';
import { Page } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { LanguageService } from 'src/app/services/lang.service';
import { ProjectService } from 'src/app/services/project.service';
import { TierService } from 'src/app/services/tier.service';
import { UserService } from 'src/app/services/user.service';
import { InfoModalComponent } from '../../modals/info-modal /info-modal .component';

@Component({
  selector: 'app-leads-table',
  templateUrl: './leads-table.component.html',
  styleUrls: ['./leads-table.component.scss'],
})
export class LeadsTableComponent implements OnInit {
  displayedColumns: string[] = [
    'Nr.',
    this.langService.getWording('funnelStatistics', 'tableDate'),
  ];
  displayedColumnsPartial: string[] = [
    'Nr.',
    this.langService.getWording('funnelStatistics', 'tableDate'),
  ];

  form: any = { pageId: null, form: null };
  @Input() allLeads: any[] = [];
  exportEnabled: boolean = false;
  user: User;
  sortDirections: any = { Datum: 'desc' };
  constructor(
    public langService: LanguageService,
    private projectService: ProjectService,
    private appService: AppService,
    private tierService: TierService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router
  ) {
    if (!this.projectService.currentProject) return;
    this.projectService.currentProject.pages.forEach((p: Page) => {
      this.displayedColumnsPartial.push(p.name);
      p.blocks.forEach((block: Block) => {
        if (block.type === 'form') {
          this.form.form = block.content['inputs'];
          this.form.pageId = p.id.toString();
        }
      });
    });

    if (this.form.form !== null) {
      this.form.form.forEach((inp: InputField) => {
        this.displayedColumns.push(inp.text);
      });
    }

    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });

    this.displayedColumns.push('Aktion');
  }

  ngOnInit(): void {}

  sortBy(key: string) {
    switch (key) {
      case 'Datum':
        if (this.sortDirections[key] === 'asc') {
          this.allLeads.sort((a: any, b: any) => {
            if (a['Datum'] < b['Datum']) {
              return -1;
            }
            if (a['Datum'] > b['Datum']) {
              return 1;
            }
            return 0;
          });
          this.sortDirections[key] = 'desc';
        } else {
          this.allLeads.sort((a: any, b: any) => {
            if (a['Datum'] > b['Datum']) {
              return -1;
            }
            if (a['Datum'] < b['Datum']) {
              return 1;
            }
            return 0;
          });
          this.sortDirections[key] = 'asc';
        }
        break;

      default:
        if (this.sortDirections[key] === 'asc') {
          this.allLeads.sort((a: any, b: any) => {
            if (a[key] < b[key]) {
              return -1;
            }
            if (a[key] > b[key]) {
              return 1;
            }
            return 0;
          });
          this.sortDirections[key] = 'desc';
        } else {
          this.allLeads.sort((a: any, b: any) => {
            if (a[key] > b[key]) {
              return -1;
            }
            if (a[key] < b[key]) {
              return 1;
            }
            return 0;
          });
          this.sortDirections[key] = 'asc';
        }
        break;
    }
  }

  openLead(lead: any) {
    this.appService.stateSubject.next({
      ...this.appService.state,
      funnelLeads: {
        ...this.appService.state.funnelLeads,
        showSlideOver: true,
        currentLead: lead,
      },
    });
  }

  exportToCsv() {
    const exportEnabled = this.tierService.getRights(
      'csvExportEnabled',
      this.user.tier!
    );
    if (!exportEnabled) {
      const dialogRef = this.dialog.open(InfoModalComponent, {
        width: '500px',
        data: {
          header: 'Höhere Preispaket benötigt',
          lockIcon: true,
          description: 'Du kannst in deiner Preispaket keine Leads exportieren',
          button: 'Jetzt upgraden',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigateByUrl('/user-settings?payment=true');
        }
      });
      return;
    }

    let filename = new Date().toDateString();

    if (!this.allLeads.length) {
      return;
    }

    var csvContent = 'data:text/csv;charset=utf-8,';
    let arrayData = JSON.parse(JSON.stringify(this.allLeads));
    arrayData.forEach(function (item: any) {
      delete item.answerSubmission;
    });
    // headers
    csvContent += this.objectToCSVRow(Object.keys(arrayData[0]));

    let that = this;
    arrayData.forEach(function (item: any) {
      csvContent += that.objectToCSVRow(item);
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'leads.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  objectToCSVRow(dataObject: any) {
    var dataArray = new Array();
    for (var o in dataObject) {
      if (Object.prototype.toString.call(dataObject[o]) === '[object Object]') {
        /* for (var m in dataObject[o].submissions) {

          var innerValue = dataObject[o].submissions[m] === null ? '' : dataObject[o].submissions[m].toString();
          var result = innerValue.replace(/"/g, '""');
          result = '"' + result + '"';
        } */
      } else {
        var innerValue = dataObject[o] === null ? '' : dataObject[o].toString();
        var result = innerValue.replace(/"/g, '""');
        result = '"' + result + '"';
      }
      dataArray.push(result);
    }
    return dataArray.join(' ') + '\r\n';
  }
}
