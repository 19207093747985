<div class="flex flex-col justify-between w-full p-4 space-y-4">
  <h1>
    {{ data.header }}
  </h1>
  <div class="flex flex-col w-full">
    <input
      type="text"
      [(ngModel)]="data.name"
      name="company-website"
      id="company-website"
      class="flex-1 min-w-0 block w-full px-3 py-2 rounded-lg focus:ring-kl-purple focus:border-kl-purple sm:text-sm border-gray-300"
      [placeholder]="data.label"
    />
  </div>

  <div class="flex w-full justify-between">
    <button (click)="onNoClick()" class="text-kl-purple text-sm font-bold">
      {{ langService.getWording("renameProject", "abort") }}
    </button>

    <button
      [mat-dialog-close]="data.name"
      cdkFocusInitial
      class="text-white bg-kl-purple hover:bg-purple-800 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3"
    >
      {{ langService.getWording("renameProject", "save") }}
    </button>
  </div>
</div>
