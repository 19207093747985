import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Block, BlockStyle } from 'src/app/models/block.model';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
})
export class PictureComponent implements OnInit, OnChanges {
  @Input() block: Block;
  @Input() style: BlockStyle;
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  ngOnChanges() {
    console.log('this.block', this.style);
  }

  public getSantizeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.block.content);
  }
}
