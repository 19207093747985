<div class="flex flex-col h-full justify-between">
  <div class="flex flex-col ">
    <h1>{{ data.headline }}</h1>
    <div>
      <div class="form-control">
        <div class="mb-6">
          <label
            for="name"
            class="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
            >{{ data.label }}</label
          >
          <input
            [(ngModel)]="data.name"
            type="text"
            id="name"
            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
            placeholder=" "
            required
          />
        </div>
      </div>

      <button
        *ngIf="data.showCategories"
        id="dropdownButton"
        data-dropdown-toggle="dropdown"
        class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center w-full"
        type="button"
        (click)="toggleDropdown()"
      >
        {{ selectedCategory ? selectedCategory.categoryName : "-" }}
        <svg
          class="ml-2 w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>

      <!-- Dropdown menu -->
      <div
        *ngIf="data.showCategories"
        id="dropdown"
        class="hidden z-10 w-full text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 mb-4"
      >
        <ul class="py-1" aria-labelledby="dropdownButton">
          <li *ngFor="let cat of categories">
            <div
              (click)="selectCategory(cat)"
              class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            >
              {{ cat.categoryName }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="flex w-full justify-between" mat-dialog-actions>
      <button (click)="onNoClick()" class="text-kl-purple text-sm font-bold">
        Abbrechen
      </button>

      <button
        *ngIf="!data.showCategories"
        [mat-dialog-close]="data.name"
        cdkFocusInitial
        class="text-white bg-kl-purple hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3"
      >
        Speichern
      </button>

      <button
        (click)="save()"
        *ngIf="data.showCategories"
        cdkFocusInitial
        class="text-white bg-kl-purple hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3"
      >
        Speichern
      </button>
    </div>
  </div>
</div>
