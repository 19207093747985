<div *ngIf="!showPaymentForm" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
  <div>
    <i
      class="bi bi-arrow-left text-2xl cursor-pointer pt-10"
      (click)="closePricingTable()"
    ></i>
  </div>
  <div class="sm:flex sm:flex-col sm:align-center">
    <h1
      class="text-5xl font-extrabold text-gray-900 sm:text-center"
      i18n="@@pricingTable_headline_label"
    >
      Preise
    </h1>
    <!--  <p class="mt-5 text-xl text-gray-500 sm:text-center">
      Start building for free, then add a site plan to go live. Account plans
      unlock additional features.
    </p> -->

    <div
      class="relative mt-12 flex justify-center sm:mt-16"
      *ngIf="paymentInterval === 'monthly'"
    >
      <div class="bg-gray-700 p-0.5 rounded-lg flex">
        <button
          type="button"
          class="relative bg-white py-2 px-6 border-gray-700 rounded-md shadow-sm text-sm font-medium text-gray-700 whitespace-nowrap hover:bg-gray-50 focus:z-10"
          i18n="@@pricingTable_monthlyPayment_label"
        >
          Monatliche Zahlung
        </button>
        <button
          (click)="togglePaymentInterval()"
          type="button"
          class="ml-0.5 relative py-2 px-6 border border-transparent rounded-md text-sm font-medium text-gray-200 whitespace-nowrap hover:bg-gray-800 focus:z-10"
          i18n="@@pricingTable_yearlyPayment_label"
        >
          Jährliche Zahlung
        </button>
      </div>
    </div>

    <div
      class="relative mt-12 flex justify-center sm:mt-16"
      *ngIf="paymentInterval === 'yearly'"
    >
      <div class="bg-gray-700 p-0.5 rounded-lg flex">
        <button
          (click)="togglePaymentInterval()"
          type="button"
          class="ml-0.5 relative py-2 px-6 border border-transparent rounded-md text-sm font-medium text-gray-200 whitespace-nowrap hover:bg-gray-800 focus:z-10"
          i18n="@@pricingTable_monthlyPayment_label"
        >
          Monatliche Zahlung
        </button>
        <button
          type="button"
          class="relative bg-white py-2 px-6 border-gray-700 rounded-md shadow-sm text-sm font-medium text-gray-700 whitespace-nowrap hover:bg-gray-50 focus:z-10"
          i18n="@@pricingTable_yearlyPayment_label"
        >
          Jährliche Zahlung
        </button>
      </div>
    </div>
  </div>
  <div class="flex justify-center w-full mt-2">
    <span
      class="text-gray-500 text-sm text-center"
      *ngIf="paymentInterval === 'yearly'"
      i18n="@@pricingTable_asterisksText_label"
    >
      * jährlich abgerechnet</span
    >
  </div>
  <div class="mt-8 flex space-x-4 justify-evenly">
    <div
      *ngFor="let pricingItem of pricingList"
      class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 w-full"
    >
      <div class="p-6">
        <h2
          *ngIf="paymentInterval === 'monthly'"
          class="flex justify-between text-lg leading-6 font-medium text-gray-900"
        >
          {{ pricingItem.name }}
          {{
            pricingItem.monthlyPaymentPriceId === user.priceId ? "(aktiv)" : ""
          }}
        </h2>
        <h2
          *ngIf="paymentInterval === 'yearly'"
          class="flex justify-between text-lg leading-6 font-medium text-gray-900"
        >
          {{ pricingItem.name }}
          {{
            pricingItem.yearlyPaymentPriceId === user.priceId ? "(aktiv)" : ""
          }}
          <span class="text-red-500">
            -{{ calulateYearlySaving(pricingItem) }}%</span
          >
        </h2>
        <p class="mt-4 text-sm text-gray-500">
          {{ pricingItem.description }}
        </p>
        <p class="mt-8 flex justify-between">
          <span class="text-4xl font-extrabold text-gray-900"
            >{{
              paymentInterval === "monthly"
                ? pricingItem.monthlyPrice
                : pricingItem.yearlyPrice
            }}
            {{ pricingItem.currency }}</span
          >

          <span
            class="text-base font-medium text-gray-500"
            i18n="@@pricingTable_perMonth_label"
            >/Monat</span
          >
        </p>
        <a
          *ngIf="
            paymentInterval === 'monthly' &&
            pricingItem.monthlyPaymentPriceId !== user.priceId
          "
          (click)="openPricingModal(pricingItem)"
          class="cursor-pointer mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
          i18n="@@pricingTable_subscribe_button"
        >
          {{ pricingItem.name }} abonnieren</a
        >
        <a
          *ngIf="
            paymentInterval === 'yearly' &&
            pricingItem.yearlyPaymentPriceId !== user.priceId
          "
          (click)="openPricingModal(pricingItem)"
          class="cursor-pointer mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
          i18n="@@pricingTable_subscribe_button"
        >
          {{ pricingItem.name }} abonnieren</a
        >
      </div>
      <div class="pt-6 pb-8 px-6">
        <h3
          class="text-xs font-medium text-gray-900 tracking-wide uppercase"
          i18n="@@pricingTable_featuresIncluded_label"
        >
          Deine Features
        </h3>
        <ul role="list" class="mt-6 space-y-4">
          <li
            class="flex space-x-3"
            *ngFor="let feature of pricingItem.features"
          >
            <!-- Heroicon name: solid/check -->
            <svg
              class="flex-shrink-0 h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="text-sm text-gray-500">{{ feature }} </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
