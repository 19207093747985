<div class="px-20 py-8 h-full overflow-auto pb-20">
  <div class="flex flex-col justify-center border-b px-10">
    <div class="flex flex-col md:flex-row justify-between my-6">
      <div class="flex flex-col items-center justify-center w-full md:w-1/3">
        <p *ngIf="!loading" class="text-5xl text-kl-gray my-2 font-bold">
          {{ getProjectLeadsOfAllProjects() }}
        </p>
        <div
          *ngIf="loading"
          class="bg-gray-200 rounded-lg h-20 w-32 animate-pulse"
        ></div>
        <p class="text-xl text-gray-600">Leads</p>
      </div>
      <div class="flex flex-col items-center justify-center w-full md:w-1/3">
        <p *ngIf="!loading" class="text-5xl text-kl-gray my-2 font-bold">
          {{ getConversionRateOfAllProjects() }}%
        </p>
        <div
          *ngIf="loading"
          class="bg-gray-200 rounded-lg h-20 w-32 animate-pulse"
        ></div>
        <p class="text-xl text-gray-600">Conversion</p>
      </div>
      <div class="flex flex-col items-center justify-center w-full md:w-1/3">
        <p
          *ngIf="!loading"
          class="text-5xl text-kl-gray my-2 font-bold break-normal text-center"
        >
          {{ getProjectNameMostLeads() }}
        </p>
        <div
          *ngIf="loading"
          class="bg-gray-200 rounded-lg h-20 w-32 animate-pulse"
        ></div>
        <p class="text-xl text-gray-600">Top-Funnel</p>
      </div>
    </div>
    <div class="flex justify-center my-4">
      <p class="text-sm text-gray-400">Ergebnis der letzten 7 Tage</p>
    </div>
  </div>

  <div class="flex justify-between my-4">
    <div class="flex space-x-4 items-center">
      <p class="text-kl-gray text-2xl">Meine Funnels</p>
      <svg
        (click)="openFunnelTemplatesModal()"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-plus-circle h-6 w-6 cursor-pointer hover:text-kl-purple"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
        />
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
        />
      </svg>
    </div>
    <div class="flex items-center space-x-4">
      <div class="flex items-center flex-col" *ngIf="!loading">
        <div class="flex items-center justify-between mb-2">
          <p class="text-gray-400 text-sm">
            {{ monthlyImpressions }} / {{ maxImpressions }} Besucher
          </p>
        </div>
        <div class="w-48 h-2 bg-kl-green rounded-full">
          <div
            class="h-full text-center text-xs text-white bg-red-300 rounded-full"
            [style]="{
              width: (monthlyImpressions / maxImpressions) * 100 + '%'
            }"
          ></div>
        </div>
      </div>
      <input
        type="text"
        placeholder="Suche"
        [(ngModel)]="searchString"
        (input)="filterFunnels()"
        class="bg-transparent border-gray-300 rounded-lg border focus:ring-0 w-full"
      />
      <!-- This example requires Tailwind CSS v2.0+ -->
      <span class="isolate inline-flex rounded-md shadow-sm">
        <button
          (click)="setViewMode('card')"
          type="button"
          class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-transparent focus:outline-none focus:ring-0 focus:ring-transparent"
          [ngClass]="{ 'bg-gray-200': viewMode === 'card' }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-border-all h-4 w-4"
            viewBox="0 0 16 16"
          >
            <path
              d="M0 0h16v16H0V0zm1 1v6.5h6.5V1H1zm7.5 0v6.5H15V1H8.5zM15 8.5H8.5V15H15V8.5zM7.5 15V8.5H1V15h6.5z"
            />
          </svg>
        </button>
        <button
          (click)="setViewMode('list')"
          type="button"
          class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-transparent focus:outline-none focus:ring-0 focus:ring-transparent"
          [ngClass]="{ 'bg-gray-200': viewMode === 'list' }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-list h-4 w-4"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>
      </span>
    </div>
  </div>

  <div *ngIf="viewMode === 'list'">
    <div class="bg-white shadow sm:rounded-md">
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="mt-8 flex flex-col">
          <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle">
              <div class="shadow-sm ring-1 ring-black ring-opacity-5">
                <table
                  class="min-w-full border-separate"
                  style="border-spacing: 0"
                >
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        (click)="sortBy('name')"
                        scope="col"
                        class="cursor-pointer group sticky -top-8 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        Name
                        <i
                          class="group-hover:opacity-100 bi bi-arrow-down-up opacity-0"
                        ></i>
                      </th>
                      <th
                        (click)="sortBy('status')"
                        scope="col"
                        class="cursor-pointer group sticky -top-8 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                      >
                        Status
                        <i
                          class="group-hover:opacity-100 bi bi-arrow-down-up opacity-0"
                        ></i>
                      </th>
                      <th
                        (click)="sortBy('impressions')"
                        scope="col"
                        class="cursor-pointer group sticky -top-8 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Aufrufe
                        <i
                          class="group-hover:opacity-100 bi bi-arrow-down-up opacity-0"
                        ></i>
                      </th>
                      <th
                        (click)="sortBy('leads')"
                        scope="col"
                        class="cursor-pointer group sticky -top-8 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Leads
                        <i
                          class="group-hover:opacity-100 bi bi-arrow-down-up opacity-0"
                        ></i>
                      </th>
                      <th
                        (click)="sortBy('conversion')"
                        scope="col"
                        class="cursor-pointer group text-right sticky -top-8 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Conversion
                        <i
                          class="group-hover:opacity-100 bi bi-arrow-down-up opacity-0"
                        ></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr
                      *ngFor="let funnel of filteredProjects"
                      class="relative group"
                    >
                      <div
                        class="group-hover:opacity-100 opacity-0 absolute -left-10 top-3"
                      >
                        <a
                          class="block text-kl-grey hover:underline cursor-pointer w-6"
                          [matMenuTriggerFor]="menu"
                          ><i class="bi bi-three-dots text-xl"></i
                        ></a>
                        <mat-menu #menu="matMenu">
                          <button
                            (click)="renameFunnel(funnel.id)"
                            mat-menu-item
                          >
                            <span i18n="@@funnelList_funnelActionRename_label"
                              >Umbenennen</span
                            >
                          </button>
                          <button (click)="copyFunnel(funnel)" mat-menu-item>
                            <span
                              i18n="@@funnelList_funnelActionDuplicate_label"
                              >Duplizieren</span
                            >
                          </button>
                          <button (click)="deleteFunnel(funnel)" mat-menu-item>
                            <span i18n="@@funnelList_funnelActionDelete_label"
                              >Entfernen</span
                            >
                          </button>
                        </mat-menu>
                      </div>

                      <td
                        [routerLink]="['/funnel-editor', funnel.id]"
                        class="flex items-center whitespace-nowrap border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 cursor-pointer"
                      >
                        {{ funnel.name }}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-chevron-right ml-2 w-3 h-3"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </td>
                      <td
                        class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                      >
                        <div
                          *ngIf="funnel.status"
                          class="w-3 h-3 rounded-full bg-green-400"
                        ></div>
                        <div
                          *ngIf="!funnel.status"
                          class="w-3 h-3 rounded-full bg-red-300"
                        ></div>
                      </td>
                      <td
                        class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                      >
                        {{ projectPageViews[funnel.id] }}
                      </td>
                      <td
                        class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500"
                      >
                        {{ projectLeads[funnel.id] }}
                      </td>
                      <td
                        class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                      >
                        {{ projectConvRates[funnel.id] }}%
                      </td>
                    </tr>

                    <!-- More people... -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="loading"
    class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-8 py-1 overflow-y-scroll mb-10"
  >
    <div
      *ngFor="let funnel of [1, 2, 3, 4]; let i = index"
      class="col-span-1 shadow-md px-6 py-6 group relative bg-gray-200 rounded-md h-52 w-full animate-pulse duration-75"
    ></div>
  </div>

  <div
    class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-8 py-1 overflow-y-scroll mb-10"
    *ngIf="viewMode === 'card'"
  >
    <div
      *ngFor="let funnel of filteredProjects; let i = index"
      class="col-span-1 bg-white shadow-md rounded-md px-6 py-6 group relative"
      [ngClass]="{ 'bg-gray-400 z-0 ': i + 1 > maxFunnels }"
    >
      <button
        *ngIf="i + 1 > maxFunnels"
        class="bg-kl-purple text-white rounded-lg p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
        (click)="openPaymentPage()"
        i18n="@@funnelList_upgradeNow_button"
      >
        <i class="bi bi-lock-fill text-white"></i> Jetzt upgraden
      </button>
      <div
        class="sm:flex sm:justify-between"
        [ngClass]="{ 'bg-gray-400 opacity-30 z-0 ': i + 1 > maxFunnels }"
      >
        <div class="flex items-start w-full">
          <div class="flex flex-col items-center w-full">
            <div class="flex justify-between items-center w-full">
              <div
                class="flex items-center group w-full"
                [title]="funnel.status ? 'Aktiviert' : 'Deaktiviert'"
              >
                <div *ngIf="funnel.status" class="mr-4">
                  <div class="w-3 h-3 rounded-full bg-green-400"></div>
                </div>
                <div *ngIf="!funnel.status" class="mr-4">
                  <div class="w-3 h-3 rounded-full bg-red-300"></div>
                </div>
                <p class="text-2xl text-kl-grey font-bold break-words">
                  {{ funnel.name }}
                </p>
              </div>
              <a
                class="block text-kl-grey hover:underline cursor-pointer w-6"
                [matMenuTriggerFor]="menu"
                ><i class="bi bi-three-dots text-xl"></i
              ></a>
              <mat-menu #menu="matMenu">
                <button (click)="renameFunnel(funnel.id)" mat-menu-item>
                  <span i18n="@@funnelList_funnelActionRename_label"
                    >Umbenennen</span
                  >
                </button>
                <button (click)="copyFunnel(funnel)" mat-menu-item>
                  <span i18n="@@funnelList_funnelActionDuplicate_label"
                    >Duplizieren</span
                  >
                </button>
                <button (click)="deleteFunnel(funnel)" mat-menu-item>
                  <span i18n="@@funnelList_funnelActionDelete_label"
                    >Entfernen</span
                  >
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex justify-between items-center mt-6"
        [ngClass]="{ 'bg-gray-400 opacity-30 z-0 ': i + 1 > maxFunnels }"
      >
        <div class="flex flex-col justify-center items-center">
          <h4
            class="text-gray-400 text-sm"
            i18n="@@funnelList_impressions_label"
          >
            Aufrufe
          </h4>
          <span class="text-lg font-medium text-kl-grey">{{
            projectPageViews[funnel.id]
          }}</span>
        </div>
        <div class="flex flex-col justify-center items-center">
          <h4 class="text-gray-400 text-sm" i18n="@@funnelList_leadCount_label">
            Leads
          </h4>
          <span class="text-lg font-medium text-kl-grey">{{
            projectLeads[funnel.id]
          }}</span>
        </div>
        <div class="flex flex-col justify-center items-center">
          <h4
            class="text-gray-400 text-sm"
            i18n="@@funnelList_conversion_label"
          >
            Conversion
          </h4>
          <span class="text-lg font-medium text-kl-grey"
            >{{ projectConvRates[funnel.id] }}%</span
          >
        </div>
      </div>
      <div class="flex justify-end" *ngIf="i + 1 <= maxFunnels">
        <a
          class="block mt-6 text-kl-green hover:text-kl-grey cursor-pointer"
          [routerLink]="['/funnel-editor', funnel.id]"
        >
          <i class="bi bi-arrow-right-circle-fill text-4xl"></i
        ></a>
      </div>
    </div>
  </div>
</div>
