import { Component, OnInit } from '@angular/core';
import { NotificationType } from 'src/app/enums/notification-type';
import { Project } from 'src/app/models/project.model';
import { AppService } from 'src/app/services/app.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-settings-privacy',
  templateUrl: './settings-privacy.component.html',
  styleUrls: ['./settings-privacy.component.scss'],
})
export class SettingsPrivacyComponent implements OnInit {
  currentProject: Project;

  constructor(
    private projectService: ProjectService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
      }
    });
  }

  setStatus(privacyEnabled: boolean) {
    this.currentProject.privacyEnabled = privacyEnabled;
    this.projectService.currentProjectSubject.next(this.currentProject);
  }

  setCookieConsent(cookieConsent: boolean) {
    this.currentProject.showCookieConsent = cookieConsent;
    this.projectService.currentProjectSubject.next(this.currentProject);
  }

  async saveProject() {
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: true,
        notificationType: NotificationType.SUCCESS,
        notificationMessage: 'Erfolgreich gespeichert',
        notificationDescription:
          'Die Änderungen wurden erfolgreich gespeichert.',
      },
    });
    await this.projectService.saveCurrentProject();

    await this.appService.delay(5000);

    this.appService.stateSubject.next({
      ...this.appService.state,
      notification: {
        ...this.appService.state.notification,
        showNotification: false,
      },
    });
  }
}
