<div class="relative h-screen flex overflow-auto">
  <div
    class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-white overflow-auto"
  >
    <div
      class="w-3/5 h-full hidden md:flex flex-auto items-center justify-center p-1 bg-kl-purple text-white bg-no-repeat bg-cover relative overflow-auto"
      style="
        background-image: url('./assets/collage_iphone-klicklead-funnels.png');
        background-position: center;
        background-size: cover;
      "
    >
      <div
        class="absolute bg-gradient-to-b from-kl-purple to-kl-purple opacity-95 inset-0 z-0"
      ></div>
      <div class="w-full max-w-md z-10">
        <div class="sm:text-4xl xl:text-5xl font-bold leading-tight mb-6"></div>
        <div class="sm:text-sm xl:text-md text-gray-200 font-normal"></div>
      </div>
      <!---remove custom style-->
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <div
      class="overflow-auto md:flex md:items-center md:justify-center h-full w-full md:w-2/5 p-8 md:p-10 lg:p-14 sm:rounded-lg md:rounded-none bg-white"
    >
      <div class="max-w-md w-full space-y-8">
        <div class="text-center">
          <div class="flex justify-center">
            <img src="./assets/klicklead-logo.svg" class="h-10 w-auto" />
          </div>
          <p
            class="text-3xl font-bold mt-12 text-kl-grey"
            i18n="@@register_registerHeadline_label"
          >
            Registrierung
          </p>
        </div>

        <form class="mt-8 space-y-6" [formGroup]="form" (ngSubmit)="submit()">
          <div class="relative">
            <label
              i18n="@@register_email_label"
              class="ml-3 text-sm font-bold text-gray-700 tracking-wide"
              >Unternehmen</label
            >
            <input
              class="w-full text-base px-4 py-2 border border-gray-300 focus:outline-none rounded-lg focus:border-kl-purple"
              type="text"
              placeholder="Meine Agentur GmbH"
              formControlName="companyName"
            />
          </div>
          <div class="relative">
            <div class="absolute right-3 mt-8">
              <svg
                *ngIf="isEmailValid"
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>

              <i
                *ngIf="!isEmailValid && isEmailValid !== null"
                class="bi bi-x-circle text-red-500"
              ></i>
            </div>
            <label
              i18n="@@register_email_label"
              class="ml-3 text-sm font-bold text-gray-700 tracking-wide"
              >E-Mail</label
            >
            <input
              class="w-full text-base px-4 py-2 border border-gray-300 focus:outline-none rounded-lg focus:border-kl-purple"
              type="email"
              (blur)="checkIsEmailValid()"
              placeholder="mail@gmail.com"
              formControlName="email"
            />
          </div>
          <div class="mt-8 content-center">
            <label
              i18n="@@register_password_label"
              class="ml-3 text-sm font-bold text-gray-700 tracking-wide"
            >
              Passwort (mind. 6 Zeichen)
            </label>
            <input
              class="w-full content-center text-base px-4 py-2 border rounded-lg border-gray-300 focus:outline-none focus:border-kl-purple"
              type="password"
              placeholder="********"
              formControlName="password"
            />
          </div>
          <div class="mt-8 content-center">
            <label
              i18n="@@register_passwordRepeat_label"
              class="ml-3 text-sm font-bold text-gray-700 tracking-wide"
            >
              Passwort wiederholen
            </label>
            <input
              class="w-full content-center text-base px-4 py-2 border rounded-xl border-gray-300 focus:outline-none focus:border-kl-purple"
              type="password"
              placeholder="********"
              formControlName="passwordSecond"
            />
          </div>

          <button
            i18n="@@register_register_button"
            type="submit"
            class="w-full flex justify-center bg-kl-purple hover:bg-purple-800 text-white p-4 rounded-lg tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in"
          >
            Kostenlos registrieren
          </button>
        </form>
        <p
          *ngIf="errors"
          class="mt-4 text-center rounded-lg border border-red-400 text-black p-2"
        >
          {{ errorText }}
        </p>
        <div>
          <!--      <div class="relative mt-6">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center">
              <span
                class="px-2 bg-white text-sm text-gray-500"
                i18n="@@register_or_label"
              >
                Oder
              </span>
            </div>
          </div> -->

          <!--      <button
            (click)="loginWithGoogle()"
            aria-label="Continue with google"
            role="button"
            class="p-3 border rounded-lg border-gray-700 flex justify-center items-center w-full mt-10 hover:bg-gray-100"
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.9892 10.1871C18.9892 9.36767 18.9246 8.76973 18.7847 8.14966H9.68848V11.848H15.0277C14.9201 12.767 14.3388 14.1512 13.047 15.0812L13.0289 15.205L15.905 17.4969L16.1042 17.5173C17.9342 15.7789 18.9892 13.221 18.9892 10.1871Z"
                fill="#4285F4"
              />
              <path
                d="M9.68813 19.9314C12.3039 19.9314 14.4999 19.0455 16.1039 17.5174L13.0467 15.0813C12.2286 15.6682 11.1306 16.0779 9.68813 16.0779C7.12612 16.0779 4.95165 14.3395 4.17651 11.9366L4.06289 11.9465L1.07231 14.3273L1.0332 14.4391C2.62638 17.6946 5.89889 19.9314 9.68813 19.9314Z"
                fill="#34A853"
              />
              <path
                d="M4.17667 11.9366C3.97215 11.3165 3.85378 10.6521 3.85378 9.96562C3.85378 9.27905 3.97215 8.6147 4.16591 7.99463L4.1605 7.86257L1.13246 5.44363L1.03339 5.49211C0.37677 6.84302 0 8.36005 0 9.96562C0 11.5712 0.37677 13.0881 1.03339 14.4391L4.17667 11.9366Z"
                fill="#FBBC05"
              />
              <path
                d="M9.68807 3.85336C11.5073 3.85336 12.7344 4.66168 13.4342 5.33718L16.1684 2.59107C14.4892 0.985496 12.3039 0 9.68807 0C5.89885 0 2.62637 2.23672 1.0332 5.49214L4.16573 7.99466C4.95162 5.59183 7.12608 3.85336 9.68807 3.85336Z"
                fill="#EB4335"
              />
            </svg>
            <p
              class="text-base text-center font-medium ml-4 text-gray-700"
              i18n="@@register_loginWithGoogle_label"
            >
              Mit Google anmelden
            </p>
          </button> -->
        </div>

        <p
          class="flex flex-col items-center justify-center mt-10 text-center text-md text-gray-500"
        >
          <span i18n="@@register_noAccountYet_label"
            >Du hast schon ein Account?</span
          >
          <a
            i18n="@@register_navigateToLogin_link"
            routerLink="/login"
            class="text-kl-purple hover:text-kl-purple no-underline hover:underline cursor-pointer transition ease-in duration-300"
            >Zum Login</a
          >
        </p>
      </div>
    </div>
  </div>
</div>
