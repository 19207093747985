import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Answer, Block } from 'src/app/models/block.model';

@Component({
  selector: 'app-margin',
  templateUrl: './margin.component.html',
  styleUrls: ['./margin.component.scss'],
})
export class MarginComponent implements OnInit {
  @Input() selectedElement: Block | Answer;
  @Input() title: string = 'Abstände';
  @Input() type: string = 'block';
  @Output() onMarginChange: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  setMargin(margin: number, side: string) {
    if (this.selectedElement.style) {
      switch (side) {
        case 'top':
          if (this.type === 'block') {
            this.selectedElement.style.marginTop = margin;
          }
          if (this.type === 'answer') {
            this.selectedElement.style.paddingTop = margin;
          }
          break;
        case 'right':
          if (this.type === 'block') {
            this.selectedElement.style.marginRight = margin;
          }
          if (this.type === 'answer') {
            this.selectedElement.style.paddingRight = margin;
          }
          break;
        case 'bottom':
          if (this.type === 'block') {
            this.selectedElement.style.marginBottom = margin;
          }
          if (this.type === 'answer') {
            this.selectedElement.style.paddingBottom = margin;
          }
          break;
        case 'left':
          if (this.type === 'block') {
            this.selectedElement.style.marginLeft = margin;
          }
          if (this.type === 'answer') {
            this.selectedElement.style.paddingLeft = margin;
          }
          break;
        default:
          break;
      }
      this.onMarginChange.emit(this.selectedElement);
    }
  }
}
