import { PricingItem, Tier } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';

export const pricingList: PricingItem[] = [
  {
    tier: Tier.BASIC,
    name: 'Basic',
    description: 'Für kleine Projekte',
    monthlyPrice: 59,
    yearlyPrice: 49,
    interval: 'month',
    currency: '€',
    monthlyPaymentPriceId: environment.priceIds.basic_monthly,
    yearlyPaymentPriceId: environment.priceIds.basic_yearly,
    features: [
      'Anzahl der Funnels: 5',
      'Anzahl der Besucher: 8.000',
      '1 Domain inklusive',
    ],
  },
  {
    tier: Tier.PROFESSIONAL,
    name: 'Professional',
    description: 'Für große Projekte',
    monthlyPrice: 119,
    yearlyPrice: 99,
    interval: 'month',
    currency: '€',
    monthlyPaymentPriceId: environment.priceIds.professional_monthly,
    yearlyPaymentPriceId: environment.priceIds.professional_yearly,
    features: [
      'Anzahl der Funnels: 10',
      'Anzahl der Besucher: 40.000',
      '5 Domains inklusive',
    ],
  },
  {
    tier: Tier.PREMIUM,
    name: 'Premium',
    description: 'Für Agenturen',
    monthlyPrice: 279,
    yearlyPrice: 249,
    interval: 'month',
    currency: '€',
    monthlyPaymentPriceId: environment.priceIds.premium_monthly,
    yearlyPaymentPriceId: environment.priceIds.premium_yearly,
    features: [
      'Anzahl der Funnels: 20',
      'Anzahl der Besucher: 100.000',
      '10 Domains inklusive',
    ],
  },
];
