import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationType } from 'src/app/enums/notification-type';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { ProjectService } from 'src/app/services/project.service';
import { TierService } from 'src/app/services/tier.service';
import { UserService } from 'src/app/services/user.service';
import { uuidv4 } from 'src/app/services/uuid';
import { environment } from 'src/environments/environment';
import { DialogModalComponent } from '../../modals/dialog-modal/dialog-modal.component';

@Component({
  selector: 'app-settings-invitation',
  templateUrl: './settings-invitation.component.html',
  styleUrls: ['./settings-invitation.component.scss'],
})
export class SettingsInvitationComponent implements OnInit {
  currentProject: Project;

  usersOfFunnel: User[] = [];
  projectId: string;
  currentUser: User;
  inviteUserMail: string;
  wrongInviteEmail: boolean = false;
  inviteUsers: number = 0;
  constructor(
    private projectService: ProjectService,
    private appService: AppService,
    private userService: UserService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private tierService: TierService
  ) {
    this.projectId = this.route.snapshot.params.id;
  }

  async ngOnInit() {
    this.userService.userDataSubject.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.getInviteUserRights();
        if (!user.funnelIds.includes(this.projectId)) {
          this.router.navigate(['/funnels']);
        }
      }
    });

    this.projectService.currentProjectSubject.subscribe((project: Project) => {
      if (project) {
        this.currentProject = project;
      }
    });

    this.usersOfFunnel = await this.userService.getUsersByFunnelId(
      this.projectId
    );
  }

  getInviteUserRights() {
    if (this.currentUser.tier) {
      this.inviteUsers = this.tierService.getRights(
        'googleTracking',
        this.currentUser.tier!
      );
      console.log(this.inviteUsers);
    }
  }

  openPaymentPage() {
    this.router.navigateByUrl('/user-settings?payment=true');
  }

  async saveProject() {
    this.projectService.currentProjectSubject.next(this.currentProject);

    this.appService.showNotification(
      'Erfolgreich gespeichert',
      'Die Änderungen wurden erfolgreich gespeichert.',
      NotificationType.SUCCESS,
      5000
    );
  }

  async removeInviteMail(user: User, index: number) {
    const name = user.displayName ? user.displayName : user.email;
    const dialogRef = this.dialog.open(DialogModalComponent, {
      width: '350px',
      data: {
        buttonText: 'Entfernen',
        text:
          'Wollen sie den Benutzer ' +
          name +
          ' wirklich aus dem Funnel entfernen?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.usersOfFunnel.splice(index, 1);
        user.funnelIds.splice(
          user.funnelIds.indexOf(this.currentProject.id),
          1
        );
        await this.userService.setUser(user, false);

        this.appService.showNotification(
          'Benutzer entfernt',
          'Der Benutzer wurde erfolgreich aus dem Funnel entfernt.',
          NotificationType.INFO,
          5000
        );

        if (user.email === this.currentUser.email) {
          this.router.navigate(['/']);
        }
      }
    });
  }

  async addUserEmail() {
    if (!this.validateEmail(this.inviteUserMail)) {
      this.appService.showNotification(
        'E-Mail Adresse ungültig',
        'Die E-Mail Adresse ist ungültig',
        NotificationType.WARNING,
        5000
      );
      return;
    }

    const user: User = await this.userService.getUserByEmail(
      this.inviteUserMail
    );

    const mail = {
      email: this.inviteUserMail,
      subject: 'Du wurdest zu einem Funnel eingeladen',
      text: `Hallo, <br> du wurdest zum Funnel "${this.currentProject.name}" eingeladen. 
        <br><br> 
        Klicke auf den Link um am Funnel mitzuarbeiten <a href="${environment.frontendUrl}/funnel-this.editor/${this.currentProject.id}">${environment.frontendUrl}/funnel-this.editor/${this.currentProject.id}</a> 
        <br><br> 
        Falls du noch nicht registriert bist, kannst du dich hier <a href="${environment.frontendUrl}/register?email=${this.inviteUserMail}">registrieren</a> bzw. hier <a href="${environment.frontendUrl}/login">einloggen</a>. <br>`,
    };

    if (user) {
      //user  registered
      if (!user.funnelIds.includes(this.currentProject.id)) {
        user.funnelIds.push(this.currentProject.id);
        this.usersOfFunnel.push(user);
        await this.userService.setUser(user, false);

        //send notification mail

        this.userService.sendInviteMail(mail);
      }
    } else {
      //user not registered
      const notRegisteredUser: User = {
        displayName: '',
        email: this.inviteUserMail,
        id: 'INVITED' + uuidv4(),
        funnelIds: [this.currentProject.id],
        language: 'ger',
        registerDate: new Date(),
        showOnboarding: true,
        receiptEmail: '',
      };

      await this.userService.setUser(notRegisteredUser, false);
      this.usersOfFunnel.push(notRegisteredUser);
      //send notification mail

      this.userService.sendInviteMail(mail);

      this.appService.showNotification(
        'Erfolgreich gespeichert',
        'Der Benutzer wurde erfolgreich hinzugefügt.',
        NotificationType.SUCCESS,
        5000
      );
    }

    this.inviteUserMail = '';
  }

  validateEmail(email: string) {
    let emailValidator =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.match(emailValidator)) {
      return false;
    } else {
      return true;
    }
  }
}
