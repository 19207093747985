import { Component, Input, OnInit } from '@angular/core';
import { Block, BlockStyle } from 'src/app/models/block.model';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  @Input() block: Block;
  @Input() style: BlockStyle;
  constructor() {}

  ngOnInit(): void {}
}
