import firebase from 'firebase/app';
import { Injectable } from '@angular/core';

import {
  Project,
  Page,
  Progressbar,
  AnswerSubmission,
} from 'src/app/models/project.model';

import { UserService } from './user.service';
import { LanguageService } from './lang.service';
import { Block, BlockStyle } from '../models/block.model';
import { BehaviorSubject, from, Subject, throwError } from 'rxjs';
import { uuidv4 } from './uuid';
import { starterBlock } from '../blocks/starterBlock';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BlockType } from '../enums/block-type';
import { catchError, retry } from 'rxjs/operators';
import { AppService } from './app.service';

@Injectable()
export class ProjectService {
  currentProject?: Project;
  projects: Project[] = [];
  db: any;
  isLoading: boolean = false;
  starterHtml: Block[] = starterBlock;

  currentProjectSubject = new BehaviorSubject<any>(null);

  constructor(
    private userService: UserService,
    private langService: LanguageService,
    private router: Router,
    private http: HttpClient
  ) {
    this.db = firebase.firestore();
  }

  initializeApp() {
    this.currentProjectSubject.subscribe((project: any) => {
      this.currentProject = project;
    });
  }

  async createProject(
    name?: string,
    pages?: Page[],
    style?: BlockStyle,
    progressbar?: Progressbar,
    user?: User,
    project?: Project
  ) {
    this.isLoading = true;
    this.currentProject = {
      id: uuidv4(),
      name: name ? name : 'Funnel ' + this.projects.length,
      pages: pages
        ? pages
        : [
            {
              id: uuidv4(),
              blocks: JSON.parse(JSON.stringify(this.starterHtml)),
              name: this.langService.getWording('funnelEditor', 'page') + ' 1',
              style: { containerBackgroundColor: '#F2F2F2' },
            },
          ],
      style: style ? style : { containerBackgroundColor: '#000000' },
      answerSubmissionsId: '',
      status: project ? project.status : false,
      showBadge: project ? project.showBadge : false,
      pageDescription: project ? project.pageDescription : '',
      pageTitle: project ? project.pageTitle : '',
      notification: project ? project.notification : false,
      notificationMails: project ? project.notificationMails : [],
      notificationText: project
        ? project.notificationText
          ? project.notificationText
          : `[LEAD] 
          [ANSWERS] `
        : `[LEAD] 
        [ANSWERS] `,
      progressbar: progressbar
        ? progressbar
        : { available: true, color: '#008000' },
      favicon: '',
      userId: user ? user.id : this.userService.user.id,
      leadNotification: {
        fromEmail: '',
        smtpHost: '',
        smtpPassword: '',
        smtpPort: 0,
        smtpUsername: '',
        subject: '',
        status: false,
        text: '',
        secure: false,
      },
      designPresets: {
        fontFamily: 'Arial',
        alignment: 'center',
        contentAlignment: 'center',
        backgroundColor: '#ffffff',
        fontColor: '#000000',
        fontSize: 16,
        quizAnswerBackgroundColor: '#ffffff',
        quizAnswerFontColor: '#000000',
      },
      createdAt: new Date(),
    };

    let id = this.db.collection('project').doc().id;
    this.currentProject.id = id;
    await this.createProjectList(
      this.currentProject.name,
      this.currentProject.id,
      this.currentProject.status
    );
    if (!user) return;

    user.funnelIds.push(id);
    await this.userService.setUser(user);
    await this.db.collection('leads').doc(id).set({ submissions: [] });

    return this.db
      .collection('project')
      .doc(id)
      .set({ ...this.currentProject })
      .then(() => (this.isLoading = false));
  }

  createProjectList(name: string, id: any, status: any) {
    this.isLoading = true;

    return this.db
      .collection('projectList')
      .doc(id)
      .set({ name: name, id: id, status: status })
      .then(() => (this.isLoading = false));
  }

  async saveCurrentProject(project?: Project) {
    this.isLoading = true;
    console.log('save');
    this.currentProject = this.currentProjectSubject.getValue();
    if (!this.currentProject) return;
    let id = project ? project.id : this.currentProject.id;

    if (project) {
      await this.db
        .collection('projectList')
        .doc(id)
        .set(
          {
            name: project ? project.name : this.currentProject.name,
            id: id,
            status: project.status ? project.status : false,
          },
          { merge: true }
        );
    } else {
      await this.db
        .collection('projectList')
        .doc(id)
        .set(
          {
            name: this.currentProject.name,
            id: this.currentProject.id,
            status: this.currentProject.status
              ? this.currentProject.status
              : false,
          },
          { merge: true }
        );
    }
    return this.db
      .collection('project')
      .doc(id)
      .set({ ...(project ? project : this.currentProject) })
      .then(() => (this.isLoading = false));
  }

  increaseImpressions(projectId: string, pageId: string) {
    const pageName = this.currentProjectSubject
      .getValue()
      .pages.find((page: any) => page.id === pageId).name;

    console.log('increaseImpressions', pageName, pageId);

    return this.db
      .collection('projectList')
      .doc(projectId)
      .set(
        {
          [pageId]: firebase.firestore.FieldValue.increment(1),
        },
        { merge: true }
      );
  }

  increaseInitialImpressions(projectId: string) {
    console.log('initialImpressions', projectId);

    return this.db
      .collection('projectList')
      .doc(projectId)
      .update({
        impressions: firebase.firestore.FieldValue.increment(1),
      });
  }

  /*   getImpressions(projectId: string) {
    this.isLoading = true;
    return this.db
      .collection('projectList')
      .doc(projectId)
      .get()
      .then((doc: any) => {
        this.isLoading = false;
        return doc.data();
      });
  } */

  async saveProjectList(id: string, projectListItem: any) {
    await this.db
      .collection('projectList')
      .doc(id)
      .set({ ...projectListItem });
  }

  async saveProject(project: Project) {
    this.isLoading = true;

    return this.db
      .collection('project')
      .doc(project.id)
      .set({ ...project })
      .then(() => (this.isLoading = false));
  }

  async getProjects() {
    this.isLoading = true;

    const snapshot = await this.db.collection('project').get();
    let documents: any = [];
    snapshot.forEach((doc: any) => {
      documents.push(doc.data());
    });
    this.projects = documents;
    this.isLoading = false;
    return documents;
  }

  async getProjectList(funnelIds: string[]) {
    this.isLoading = true;
    let projects: Project[] = [];
    for (let id of funnelIds) {
      projects.push(await this.getProjectListById(id));
    }
    this.isLoading = false;
    return projects;
  }

  async getLeads(projectId: string) {
    this.isLoading = true;

    const snapshot = await this.db
      .collection('leads')
      .where('projectId', '==', projectId)
      .get();
    let answerSubmissions: AnswerSubmission[] = [];
    snapshot.forEach((as: any) => {
      answerSubmissions.push(as.data());
    });
    this.isLoading = false;
    return answerSubmissions;
  }

  async getAllSubmissionAnswers(projectId: string) {
    let answerSubmissions: AnswerSubmission[] = [];
    return await this.db
      .collection('answerSubmission')
      .where('projectId', '==', projectId)

      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach((doc: any) => {
          answerSubmissions.push(doc.data());
        });

        return answerSubmissions;
      });
  }

  async getSubmissionAnswers(projectId: string) {
    /*   this.isLoading = true;

    const snapshot = await this.db
      .collection('answerSubmission')
      .where('projectId', '==', projectId)
      .orderBy('date', 'desc')
      .startAfter(start)
      .limit(limit)
      .get();
    let answerSubmissions: AnswerSubmission[] = [];
    snapshot.forEach((as: any) => {
      answerSubmissions.push(as.data());
    });
    this.isLoading = false;
    return answerSubmissions; */

    /* let first;
    if (lastDoc !== null) {
      first = this.db
        .collection('answerSubmission')
        .where('projectId', '==', projectId)
        .orderBy('date', 'desc')
        .startAfter(lastDoc.data())
        .limit(limit);
    } else {
      first = this.db
        .collection('answerSubmission')
        .where('projectId', '==', projectId)
        .orderBy('date', 'desc')
        .limit(limit);
    }

    const snapshot = await first.get();
    console.log(snapshot);
    // Get the last document
    const last = snapshot.docs[snapshot.docs.length - 1];
 
    const next = await this.db
      .collection('answerSubmission')
      .where('projectId', '==', projectId)
      .orderBy('date', 'desc')
      .startAt(last.data())
      .limit(limit)
      .get();
    let answerSubmissions: AnswerSubmission[] = [];
    next.forEach((as: any) => {
      answerSubmissions.push(as.data());
    });
    this.isLoading = false;
    return { answerSubmissions, last }; */
    /*  let answerSubmissions: AnswerSubmission[] = [];
    if (lastDoc === null) {
      return await this.db
        .collection('answerSubmission')
        .where('projectId', '==', projectId)
        .orderBy('date', 'desc')
        .limit(3)
        .get()
        .then((querySnapshot: any) => {
          console.log(
            'Initial query: ' + querySnapshot.docs.length + ' results'
          );
          querySnapshot.forEach((doc: any) => {
            answerSubmissions.push(doc.data());
          });
          let last = querySnapshot.docs[querySnapshot.docs.length - 1];
          return { answerSubmissions, last };
        });
    } else {
      return await this.db
        .collection('answerSubmission')
        .where('projectId', '==', projectId)
        .orderBy('date', 'desc')
        .startAfter(lastDoc)
        .limit(3)
        .get()
        .then((querySnapshot: any) => {
          console.log(
            'Second query: ' + querySnapshot.docs.length + ' results'
          );
          querySnapshot.forEach((doc: any) => {
            answerSubmissions.push(doc.data());
          });
          let last = querySnapshot.docs[querySnapshot.docs.length - 1];

          return { answerSubmissions, last };
        });
    } */

    let answerSubmissions: AnswerSubmission[] = [];

    const response: any = await this.http
      .get(
        environment.backendUrl +
          '/submission-answers/getSubmissionAnswers/' +
          projectId
      )
      .toPromise();

    response.forEach((element: any) => {
      answerSubmissions.push(element.submissionAnswer);
    });

    return answerSubmissions;
  }

  async deleteAnswerSubmissions(projectId: string) {
    const response: any = await this.http
      .get(
        environment.backendUrl +
          '/submission-answers/deleteSubmissionAnswers/' +
          projectId
      )
      .toPromise();
    return response;
  }

  // save answerSubmission in server
  async saveSubmissionAnswer(answerSubmission: AnswerSubmission) {
    this.isLoading = true;
    /*  return this.db
      .collection('answerSubmission')
      .doc(answerSubmission.id)
      .set({ ...answerSubmission })
      .then(() => (this.isLoading = false)); */

    const response = await this.http
      .post(
        environment.backendUrl + '/submission-answers/setSubmissionAnswers',
        answerSubmission
      )
      .toPromise();
    return response;
  }

  async getAllLeads() {
    this.isLoading = true;

    const snapshot = await this.db
      .collection('leads')
      .orderBy('date', 'desc')
      .get();
    let answerSubmissions: AnswerSubmission[] = [];
    snapshot.forEach((as: any) => {
      answerSubmissions.push(as.data());
    });
    this.isLoading = false;
    return answerSubmissions;
  }

  async getAllLeadsByDate(date: Date) {
    this.isLoading = true;

    date.setHours(0, 0, 0, 0);

    var end = new Date();
    end.setHours(23, 59, 59, 999);

    const snapshot = await this.db
      .collection('leads')
      .where('date', '>=', date)
      .where('date', '<=', end)
      .orderBy('date', 'desc')
      .get();
    let answerSubmissions: AnswerSubmission[] = [];
    snapshot.forEach((as: any) => {
      answerSubmissions.push(as.data());
    });
    this.isLoading = false;
    return answerSubmissions;
  }

  saveLead(answerSubmission: AnswerSubmission) {
    this.isLoading = true;

    this.db
      .collection('projectList')
      .doc(answerSubmission.projectId)
      .set(
        {
          leadCount: firebase.firestore.FieldValue.increment(1),
        },
        { merge: true }
      );

    return this.db
      .collection('leads')
      .doc(answerSubmission.id)
      .set({ ...answerSubmission })
      .then(() => (this.isLoading = false));
  }

  async getProjectById(projectId: string) {
    this.isLoading = true;

    try {
      const project = await this.db.collection('project').doc(projectId).get();
      this.isLoading = false;

      if (project.exists) {
        const projectData = project.data();
        this.currentProjectSubject.next(projectData);

        return projectData;
      } else {
        throw new Error('Project not found');
      }
    } catch (error) {
      this.isLoading = false;
      console.error('Error fetching project:', error);
      await this.router.navigate(['/not-found']);
      throw error;
    }
  }

  async getProjectListById(projectId: string) {
    this.isLoading = true;

    return await this.db
      .collection('projectList')
      .doc(projectId)
      .get()
      .then((project: any) => {
        return project.data();
      });
  }

  async getAllProjectList() {
    this.isLoading = true;

    return await this.db
      .collection('projectList')
      .get()
      .then((project: any) => {
        return project.data();
      });
  }

  async deleteProject(projectId: string) {
    this.isLoading = true;
    if (!this.userService.userData) return;
    const funnelIndex = this.userService.userData.funnelIds.findIndex(
      (fi: string) => fi === projectId
    );
    this.userService.userData.funnelIds.splice(funnelIndex, 1);
    await this.userService.setUser(this.userService.userData);
    await this.db.collection('projectList').doc(projectId).delete();
    await this.db.collection('leads').doc(projectId).delete();
    return this.db
      .collection('project')
      .doc(projectId)
      .delete()
      .then(() => (this.isLoading = false));
  }

  async setUrl(projectId: string, url: string) {
    this.isLoading = true;

    return this.db
      .collection('url')
      .doc(url)
      .set({ projectId: projectId })
      .then(() => (this.isLoading = false));
  }

  async getUrl(url: string) {
    this.isLoading = true;

    this.currentProject;
    return await this.db
      .collection('url')
      .doc(url)
      .get()
      .then((project: any) => {
        this.isLoading = false;
        return project.data();
      });
  }

  async deleteUrl(url: string) {
    this.isLoading = true;

    return this.db
      .collection('url')
      .doc(url)
      .delete()
      .then(() => (this.isLoading = false));
  }

  downloadObjectAsJson(project: Project) {
    var dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(project));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', project.name + '.json');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  async sendLeadNotification(mailObj: {}) {
    this.isLoading = true;
    const response = await this.http
      .post(
        `https://klicklead-api-prod.herokuapp.com/notification/send-test-mail`,
        mailObj
      )
      .toPromise();
    this.isLoading = false;
    return response;
  }

  async westnetzRequest(address: {
    street: string;
    houseNumber: string;
    zipCode: string;
    city: string;
  }) {
    const reqBody = {
      n: address.houseNumber,
      q: address.zipCode + ' ' + address.city,
      s: address.street,
      t: 'strom',
    };

    this.isLoading = true;
    const response = await this.http
      .post(environment.backendUrl + '/notification/westnetz', {
        address: reqBody,
      })
      .toPromise();
    this.isLoading = false;
    return response;
  }

  isBackButtonAllowed(block: Block) {
    this.currentProject = this.currentProjectSubject.getValue();
    if (!this.currentProject) return;

    if (this.currentProject.isBackButton === undefined) return false;

    if (this.currentProject.isBackButton) {
      switch (block.type) {
        case BlockType.BUTTON:
          return true;
        case BlockType.FORM:
          return true;
        case BlockType.ZW_WESTNETZ:
          return true;
        case BlockType.QUIZ:
          return true;
        default:
          return false;
          break;
      }
    } else {
      return false;
    }
  }
}
