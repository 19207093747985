<div class="my-6">
  <app-text-input
    [value]="
      appService.state.editor.currentProject.pages[
        appService.state.editor.layout.pageBar.currentPageIndex
      ].name
    "
    [title]="'Seitenname'"
    (valueChange)="setPageName($event)"
    [placeholder]="'Seite 1'"
  ></app-text-input>
</div>

<div class="my-6">
  <app-dropdown
    [headline]="'Facebook Pixel Event'"
    [items]="facebookEvents"
    [selectedItem]="
      appService.state.editor.currentProject.pages[
        appService.state.editor.layout.pageBar.currentPageIndex
      ].fbEvent
    "
    (onChange)="setFacebookEvent($event)"
  ></app-dropdown>
</div>

<div class="my-6">
  <app-dropdown
    [headline]="'Google Analytics Event'"
    [items]="googleEvents"
    [selectedItem]="
      appService.state.editor.currentProject.pages[
        appService.state.editor.layout.pageBar.currentPageIndex
      ].googleEvent
    "
    (onChange)="setGoogleEvent($event)"
  ></app-dropdown>
</div>

<div class="my-6">
  <app-color-picker
    [title]="'Hintergrundfarbe der Seite'"
    [color]="
      appService.state.editor.currentProject.pages[
        appService.state.editor.layout.pageBar.currentPageIndex
      ].style.containerBackgroundColor
    "
    (onColorChange)="setPageBackgroundcolor($event)"
  ></app-color-picker>
</div>
